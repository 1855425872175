import classnames from 'classnames';

import useBreakpointContext, { BreakpointContextValue } from 'bloko/blocks/breakpointProvider/BreakpointContext';
import { ComponentWithCustomElement } from 'bloko/common/helpers/types';
import { Breakpoint } from 'bloko/common/media';

import ColumnsRow from 'bloko/blocks/column/ColumnsRow';
import ColumnsWrapper from 'bloko/blocks/column/ColumnsWrapper';
import { AllowedElements } from 'bloko/blocks/column/common';

import styles from 'bloko/blocks/column/column.less';

type XSSizes = '0' | '1' | '2' | '3' | '4';
type SSizes = XSSizes | '5' | '6' | '7' | '8';
type MSizes = SSizes | '9' | '10' | '11' | '12';
type LSizes = MSizes | '13' | '14' | '15' | '16';

interface ColumnProps {
    /** Задает нулевые отступы у блока и позволяет использовать блок как контейнер для других колонок */
    container?: boolean;
    /** Количество колонок при ширине страницы до 699px строка содержит содержит до 4 колонок,
     * которые тянутся по ширине*/
    xs?: XSSizes;
    /** Количество колонок при ширине страницы от 700 до 1019px строка содержит до 8 колонок */
    s?: SSizes;
    /** Количество колонок при ширине от 1020px до 1339px строка содержит до 12 колонок */
    m?: MSizes;
    /** Количество колонок при ширине от 1340px содержит до 16 колонок */
    l?: LSizes;
    /** Кастомный компонент функция или [доступные теги](#invariants)*/
    Element?: AllowedElements;
    /** Указывает на строку с компонентом в исходном коде в режиме разработки. Генерируется babel-plugin-react-source */
    source?: string;
}

type VisibilityCheckProps = Pick<ColumnProps, Breakpoint>;

const isVisible = (
    sizeProps: VisibilityCheckProps,
    { breakpoint, renderRestriction }: BreakpointContextValue
): boolean => {
    if (!breakpoint || !renderRestriction) {
        return true;
    }

    const propName = [breakpoint, Breakpoint.M].find((breakpoint) => sizeProps[breakpoint] !== undefined);
    return propName ? sizeProps[propName] !== '0' : false;
};

const Column: ComponentWithCustomElement<ColumnProps, 'div'> = ({
    Element = 'div',
    xs,
    s,
    m,
    l,
    container,
    ...columnProps
}) => {
    const breakpointContextValue = useBreakpointContext();

    if (!isVisible({ xs, s, m, l }, breakpointContextValue)) {
        return null;
    }

    return (
        <Element
            className={classnames(styles['bloko-column'], {
                [styles['bloko-column_container']]: container,
                [styles[`bloko-column_xs-${xs as XSSizes}`]]: xs,
                [styles[`bloko-column_s-${s as SSizes}`]]: s,
                [styles[`bloko-column_m-${m as MSizes}`]]: m,
                [styles[`bloko-column_l-${l as LSizes}`]]: l,
            })}
            {...columnProps}
        />
    );
};

export default Column;
export { ColumnsRow, ColumnsWrapper };
