import dropBase, { clickBaseFabric } from 'bloko/blocks/drop/classic';
import Components from 'bloko/common/core/Components';

import template from 'bloko/blocks/drop/Menu/templateMenu.mustache';

import { defaultProps, MenuPlacement, BASE_CLASS_NAMES, BEHAVIOR, MenuLayer } from './common';

/**
 * @exports bloko/blocks/drop/Menu/menu
 *
 * Создает выпадающее меню.
 *
 * @param {Element} element DOM-элемент, активатор. Меню позиционируется относительно него.
 *
 * @param {Object} params параметры DropMenu
 *
 * @param {String} [params.placement='bottomStart'] Предпочтительное положение меню, варианты доступны в статическом свойстве [placements](../#/•%20Drop?id=menu-placements).
 * Если в указанном направлении недостаточно места для отображения, меню будет показан в направлении, которое
 * больше подходит.
 * @param {String} [params.layer='aboveContent'] Тип слоя z-index-а, варианты доступны в статическом свойстве [layers](../#/•%20Drop?id=menu-layers)
 * @param {Boolean} [params.onlySetPlacement=false] Выпадать только в заданном направлении, не обрабатывать другие кейсы
 * @param {String} [params.dataQa='bloko-drop-menu'] Data-qa контента
 * @param {String | Element} [params.host=document.body] DOM нода или селектор хоста в рамках которого
 * нужно рендерить DropMenu
 * @param {String | Element} params.html cодержимое DropMenu, поддерживает html разметку, и строки.
 * @param {String} [params.title=null] Заголовок меню, выводится только на XS.
 * @param {Boolean} [params.flexible] Метка, использовать ли тянущееся меню.
 * @constructor
 */
const Menu = Components.build({
    defaults: {
        ...defaultProps,
        host: 'body',
        onShow() {},
        onClose() {},
    },
    create(element, { host, layer, placement, html, onlySetPlacement, onShow, onClose, flexible, dataQa, title }) {
        return dropBase(element, {
            // params
            host,
            layer,
            placement,
            html,
            onlySetPlacement,
            onShow,
            onClose,
            flexible,
            dataQa,
            title,

            // specific
            baseClassNames: BASE_CLASS_NAMES,
            behavior: BEHAVIOR,
            template,
            selectors: {
                component: 'Bloko-DropMenu',
                content: 'Bloko-DropMenu-Content',
            },

            // override
            closeByClickOutside: true,
        });
    },
});

export const Click = clickBaseFabric(Menu);

export default Menu;
export { MenuLayer, MenuPlacement };
