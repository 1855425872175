import Backbone from 'backbone';
import _ from 'underscore';

/**
 * @mixin Events
 */
const Events = {
    /**
     * Проксирует аргументы для Backbone.Events.trigger,
     * cобытия на публичном экземпляре не должы вызываться вне области видимости компонента,
     * создавшего этот экземпляр
     */
    _trigger(...args) {
        Backbone.Events.trigger.call(this, ...args);
    },

    on: Backbone.Events.on,
    off: Backbone.Events.off,
    once: Backbone.Events.once,
};

export default {
    extend(instance) {
        return _.extend(instance, Events);
    },
};
