import LayerCssClass from 'bloko/common/constants/layersCssClasses';
import { Placement } from 'bloko/common/metrics';
import { ValueOf } from 'bloko/common/types';

import composedSelectors from 'bloko/blocks/drop/composed-selectors.less';
import styles from 'bloko/blocks/drop/drop.less';

export const DropPlacement = {
    TopStart: Placement.TopStart,
    TopEnd: Placement.TopEnd,
    RightStart: Placement.RightStart,
    RightEnd: Placement.RightEnd,
    BottomStart: Placement.BottomStart,
    BottomEnd: Placement.BottomEnd,
    LeftStart: Placement.LeftStart,
    LeftEnd: Placement.LeftEnd,
} as const;

export type DropPlacementValue = ValueOf<typeof DropPlacement>;

export const PLACEMENT_DOWN_SEQUENCE = Object.values(DropPlacement);

// Охранная область от границ view port
export const GUARD_SIZE = 15;

export const FLEXIBLE_CLASS = composedSelectors['bloko-drop_flexible'];
export const FULLSCREEN_ON_XS_CLASS = composedSelectors['bloko-drop_fullscreen-on-xs'];
export const STRETCH_ON_XS_CLASS = styles['bloko-drop_stretch-on-xs'];
export const CLICKABLE_CLASS = styles['bloko-drop_clickable'];
export const RESERVED_CSS_VALUES = ['auto'];

export const MIN_SIZE_TO_PLACE_DROP_AT_THE_START = 50;
export const OFFSET_TO_ARROW_X = 25;
export const OFFSET_TO_ARROW_Y = 21;
export const DIRECTION_MAPPING = {
    x: ['left', 'right'],
    y: ['top', 'bottom'],
};
export const OPPOSITE_DIRECTIONS = [DIRECTION_MAPPING.x, DIRECTION_MAPPING.y];

export const DISTANCE = 5;

export { LayerCssClass as DropLayer };
export const makeDropLayerClass = (layer: LayerCssClass): string => styles[`bloko-drop_layer-${layer}`];

export const DEBOUNCE_DELAY_MS = 100;
export const ANIMATION_TIMEOUT_MS = 32;
export const ENTER_ANIMATION_MS = 300;
export const ARROW_SIZE = 5;
