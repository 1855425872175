type ReplaceMap = Record<string, string>;

const escapeMap: ReplaceMap = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#x27;',
    '`': '&#x60;',
};

const unescapeMap: ReplaceMap = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#x27;': "'",
    '&#x60;': '`',
};

const createEscaper = (map: ReplaceMap) => {
    const escaper = (match: string) => map[match];
    const source = `(?:${Object.keys(map).join('|')})`;
    const testRegexp = RegExp(source);
    const replaceRegexp = RegExp(source, 'g');

    return function (string: string) {
        string = string == null ? '' : `${string}`;
        return testRegexp.test(string) ? string.replace(replaceRegexp, escaper) : string;
    };
};

const escape = createEscaper(escapeMap);
const unescape = createEscaper(unescapeMap);

export { escape, unescape };
