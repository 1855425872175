import { ComponentWithCustomElement } from 'bloko/common/helpers/types';

import styles from 'bloko/blocks/form/form.less';

interface FormSpacerProps {
    /** Кастомный компонент или ('div', 'span') */
    Element?: 'div' | 'span';
}

const FormSpacer: ComponentWithCustomElement<FormSpacerProps, 'div'> = ({
    Element = 'div',
    children,
    ...formSpacerProps
}) => {
    return (
        <Element {...formSpacerProps} className={styles['bloko-form-spacer']}>
            {children}
        </Element>
    );
};

export default FormSpacer;
