import { isValidElement, useRef, useLayoutEffect, useContext, ReactNode, ReactElement, Context } from 'react';

import SelectDropdownOption from 'bloko/blocks/selectDropdownOption';
import { Breakpoint, getBreakpoint } from 'bloko/common/media';

import { CustomSelectValue } from 'bloko/blocks/customSelect';
import { dataQa } from 'bloko/blocks/customSelect/constants';
import CustomSelectContext, { CustomSelectContextProps } from 'bloko/blocks/customSelect/context';

export interface CustomSelectOptionProps<T = CustomSelectValue> {
    /** Текст или своя верстка опции */
    children?: ReactNode;
    /** Значение опции */
    value: T;
    /** Флаг неактивности */
    disabled?: boolean;
    /** Текст для поиска по опциям, если не передан используется текстовое содержимое */
    searchText?: string;
}

const CustomSelectOption = <T extends CustomSelectValue>({
    children,
    value,
    disabled,
    searchText,
    ...customSelectOptionProps
}: CustomSelectOptionProps<T>): ReactElement | null => {
    const { selectedValue, selectValue, focusedValue, setFocusedValue, scrollTo } = useContext(
        CustomSelectContext as Context<CustomSelectContextProps<T>>
    );
    const optionRef = useRef<HTMLDivElement>(null);

    const selected = value === selectedValue;
    const focused = value === focusedValue;

    useLayoutEffect(() => {
        if (!focused || !optionRef.current) {
            return;
        }
        scrollTo(optionRef.current.offsetTop, optionRef.current.offsetHeight);
    }, [focused, scrollTo]);

    const optionClick = () => {
        if (disabled) {
            return;
        }
        selectValue(value);
    };

    const optionMouseEnter = () => {
        // На мобильных устройствах есть задержка срабатывания клика (300мс)
        // При изменении фокуса вызвается ререндер и хендлер клика протухает
        // Так как ховер на тач-устройствах не нужен - не выполняем функцию
        if (getBreakpoint() === Breakpoint.XS) {
            return;
        }
        setFocusedValue(disabled ? undefined : value);
    };

    return (
        <SelectDropdownOption
            data-qa={dataQa.option}
            {...customSelectOptionProps}
            aria-selected={selected}
            role="option"
            selected={selected}
            disabled={disabled}
            focused={focused}
            ref={optionRef}
            onClick={optionClick}
            onMouseEnter={optionMouseEnter}
        >
            {children}
        </SelectDropdownOption>
    );
};

export default CustomSelectOption;

export const isValidOptionElement = (child: ReactNode): child is ReactElement<CustomSelectOptionProps> =>
    isValidElement(child) && child.type === CustomSelectOption;
