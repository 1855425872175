import React, { useCallback, useMemo, useState } from 'react';

import {
    IconDynamic,
    IconLink,
    IconColor,
    EyeScaleSmallCrossedFalse,
    EyeScaleSmallCrossedTrue,
} from 'bloko/blocks/icon';

import InputText, { InputType, InputProps } from 'bloko/blocks/inputText';

type InputPasswordProps = Omit<InputProps, 'type' | 'icon'> & {
    /** Колбэк срабатывает при переключении видимости пароля */
    onToggle?: (v: boolean) => void;
};

/**
 * Текстовое поле для паролей. При клике на иконку переключается в режим показать/спрятать пароль.
 */
const InputPassword = React.forwardRef<HTMLInputElement, InputPasswordProps>(({ onToggle, ...inputProps }, ref) => {
    const [isPasswordVisible, setPasswordVisible] = useState(false);

    const type = isPasswordVisible ? InputType.Text : InputType.Password;

    const handleToggle = useCallback(() => {
        const isVisible = !isPasswordVisible;
        setPasswordVisible(isVisible);
        onToggle?.(isVisible);
    }, [isPasswordVisible, onToggle]);

    const iconProps = useMemo(
        () => ({
            initial: IconColor.Gray50,
            highlighted: IconColor.Gray60,
            'aria-label': 'Показать пароль',
        }),
        []
    );

    const icon = useMemo(
        () => (
            <IconDynamic data-qa="bloko-input-password-icon" onClick={handleToggle}>
                <IconLink Element="button">
                    {isPasswordVisible ? (
                        <EyeScaleSmallCrossedTrue {...iconProps} />
                    ) : (
                        <EyeScaleSmallCrossedFalse {...iconProps} />
                    )}
                </IconLink>
            </IconDynamic>
        ),
        [handleToggle, iconProps, isPasswordVisible]
    );

    return <InputText type={type} ref={ref} icon={icon} aria-label="Введите пароль" {...inputProps} />;
});

export default InputPassword;
