// Максимально возможный оффсет, при котором считаем, что был произведен тап\клик, а не свайп
export const CLICK_REGION_THRESHOLD = 10;
export const MAX_HORIZONTAL_SWIPE_ANGLE = 45;
export const ACTION_STATES = {
    NONE: null,
    TOUCH: 'TOUCH',
    MOUSE: 'MOUSE',
};

export enum SwipeDirection {
    Horizontal = 'horizontal',
    Vertical = 'vertical',
}

export interface UseSwipeDetailsType {
    type: (typeof ACTION_STATES)[keyof typeof ACTION_STATES];
    moveStarted: boolean;
    isSwipingHorizontally: boolean;
    clientX: number;
    clientY: number;
    swipeDistance: number;
    isQuickSwipe: boolean;
    quickSwipeTimeout: ReturnType<typeof setTimeout> | null;
    isScrolling: boolean;
    directionWay: SwipeDirection;
}
