import { ComponentPropsWithoutRef, FC } from 'react';

import { IconColor, LoaderScaleMedium, LoaderScaleSmall } from 'bloko/blocks/icon';

export enum LoadingScale {
    Small = 'Small',
    Medium = 'Medium',
}

interface LoadingProps extends ComponentPropsWithoutRef<'svg'> {
    initial?: IconColor;
    scale?: LoadingScale;
}

const Loading: FC<LoadingProps> = ({ scale = LoadingScale.Small, ...otherProps }) =>
    scale === LoadingScale.Small ? (
        <LoaderScaleSmall initial={IconColor.Blue60} scale={scale} {...otherProps} />
    ) : (
        <LoaderScaleMedium initial={IconColor.Blue60} scale={scale} {...otherProps} />
    );

export default Loading;
export { IconColor as LoadingColor };
