import _ from 'underscore';

import Events from 'bloko/common/events';

import simpleSetStrategy from 'bloko/common/collection/simpleSetStrategy';

/**
 * Описание изменений в наборе.
 * @typedef {Object} SetDiff
 * @property {Array} added Список добавленных элементов.
 * @property {Array} removed Список удалённых элементов.
 */

/**
 * @returns {SetDiff}
 * @private
 */
function _createEmptyDiff() {
    return {
        added: [],
        removed: [],
    };
}

/**
 * Вычисляет дифф между двумя наборами.
 * @param {Set} firstSet
 * @param {Set} secondSet
 * @returns {SetDiff}
 * @private
 */
function _getDiff(firstSet, secondSet) {
    const diff = _createEmptyDiff();
    firstSet.forEach((value) => {
        if (!secondSet.has(value)) {
            diff.removed.push(value);
        }
    });
    secondSet.forEach((value) => {
        if (!firstSet.has(value)) {
            diff.added.push(value);
        }
    });
    return diff;
}

/**
 * Событие изменения.
 * @event CollectionFlag#event:change
 * @property {SetDiff} diff
 */

/**
 * Флаг для коллекции.
 * @param {SetStrategy} externalStrategy Стратегия переключения.
 * @mixes Events
 * @constructor
 */
function CollectionFlag(externalStrategy) {
    // Именно `extend(this)`, чтобы `publicInstance` был инстансом,
    // а не простым объектом, и передавался бы при копировании параметров
    // по ссылке. См. https://github.com/hhru/bloko/pull/452
    const publicInterface = Events.extend(this);
    const strategy = externalStrategy || simpleSetStrategy;
    const items = new Set();

    function _triggerChangeIfNeeded(previousItems) {
        const diff = _getDiff(previousItems, items);
        if (diff.added.length || diff.removed.length) {
            publicInterface._trigger('change', diff);
        }
    }

    /**
     * Выбирает указанные ID.
     * @param {Array.<String>} ids
     * @fires CollectionFlag#event:change
     */
    function add(ids) {
        const previousItems = new Set(items.values());
        strategy.add(items, ids);
        _triggerChangeIfNeeded(previousItems);
    }

    /**
     * Сбрасывает указанные ID.
     * @param {Array.<String>} ids
     * @fires CollectionFlag#event:change
     */
    function remove(ids) {
        const previousItems = new Set(items.values());
        strategy.remove(items, ids);
        _triggerChangeIfNeeded(previousItems);
    }

    /**
     * Выбирает указанные ID, все остальные сбрасывает.
     * @param {Array.<String>} ids
     * @fires CollectionFlag#event:change
     */
    function set(ids) {
        const previousItems = new Set(items.values());
        strategy.set(items, ids);
        _triggerChangeIfNeeded(previousItems);
    }

    /**
     * Возвращает массив элементов в порядке добавления.
     * @returns {Array.<String>}
     */
    function get() {
        return [...items.values()];
    }

    /**
     * Возвращает признак присутствия элемента в наборе.
     * @param {String} item
     * @returns {Boolean}
     */
    function contains(item) {
        return items.has(item);
    }

    /**
     * @lends CollectionFlag#
     */
    return _.extend(publicInterface, {
        get,
        set,
        add,
        remove,
        contains,
    });
}

export default CollectionFlag;
