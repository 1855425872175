import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const ChevronScaleMediumKindUpDown = hoc(
    (props: IconProps) => (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M18 8L12 2L6 8M6 16L12 22L18 16"
                stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ),
    'Chevron'
);
export default ChevronScaleMediumKindUpDown;
