import { createRef, Component, FC, Fragment, ReactNode, RefObject, PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import { updatePosition, setInitialCSSMetrics, getThemeClass } from 'bloko/blocks/drop/common';
import { DISTANCE, FLEXIBLE_CLASS, makeDropLayerClass } from 'bloko/blocks/drop/constants';
import setClickable from 'bloko/blocks/drop/setClickable';

import defaultProps, {
    TipLayer,
    TipTheme,
    TipPlacement,
    TipPlacementType,
    PLACEMENT_TIP_SEQUENCE,
    BASE_CLASS_NAMES,
    ARROW_SIZE,
    ANIMATION_TIMEOUT_MS,
} from 'bloko/blocks/drop/Tip/constants';

import styles from 'bloko/blocks/drop/Tip/tip.less';
import composedSelectors from 'bloko/blocks/drop/composed-selectors.less';

import TipProvider, { Context, TipProviderInterface } from './Context';

const DropTip: FC<TipProps & PropsWithChildren> = (props) => (
    <Context.Consumer>{(value) => <TipComponent {...defaultProps} {...props} context={value} />}</Context.Consumer>
);

export interface TipProps {
    /** Предпочтительное положение дроптипа, доступны в статическом свойстве [placements](#tip-placements).
     * Если в указанном направлении недостаточно места для отображения, подсказка будет показана в
     * направлении, которое больше подходит. */
    placement?: TipPlacementType;
    /** Тип слоя z-index-а подсказки, доступны в статическом свойстве [layers](#tip-layers) */
    layer?: TipLayer;
    /** Возможные темы дроптипа доступны в статическом свойстве [themes](#tip-themes)*/
    theme?: TipTheme;
    /** DOM нода хоста в рамках которого нужно рендерить меню, по дефолту рендер будет в родителе компонента.*/
    host?: HTMLElement | null;
    /** Флаг отвечает за показ меню, `true` - показать, `false` - скрыть */
    show?: boolean;
    /** Метод-рендер контента меню */
    render: () => ReactNode | null;
    /** Элемент инициатор, относительно которого показывать всплывающую подсказку */
    children?: ReactNode;
    /** Колбек вызывается, когда показан другой дроптип. Это связано с тем что дроптип может быть показан только 1
     * в момент времени, и нужно корректно обрабатывать state родителя, который отвечает за показ дроптипа */
    onExternalClose: (show: boolean) => void;
    /* @param {String} dataQa='bloko-drop-tip' Data-qa активного info */
    dataQa?: string;
    /** Флаг, отключающий максимальную ширину */
    flexible?: boolean;
}

type InnerTipProps = TipProps & { context: TipProviderInterface };

interface TipState {
    uid: number;
    show: boolean;
}

let uid = 0;

class TipComponent extends Component<InnerTipProps, TipState> {
    static defaultProps = defaultProps;

    dropRef: RefObject<HTMLDivElement>;
    arrowRef: RefObject<HTMLDivElement>;
    activatorRef: HTMLElement | null = null;

    constructor(props: InnerTipProps) {
        super(props);
        uid += 1;

        this.dropRef = createRef();
        this.arrowRef = createRef();

        this.state = {
            uid,
            show: false,
        };
    }

    componentDidMount() {
        // eslint-disable-next-line react/no-find-dom-node
        this.activatorRef = ReactDOM.findDOMNode(this) as HTMLElement;
        window.addEventListener('resize', this.updateTipPosition);

        if (this.props.show) {
            this.setState({ show: true });
            this.props.context.setShow(this.state.uid);
        }
    }

    componentDidUpdate(prevProps: InnerTipProps, prevState: TipState) {
        this.dropRef.current && setClickable(this.dropRef.current);
        const { showedId, setShow } = this.props.context;
        if (this.state.show && !prevState.show && this.dropRef.current) {
            setInitialCSSMetrics(this.dropRef.current);
        }
        if (
            showedId === prevProps.context.showedId &&
            this.state.show === prevState.show &&
            this.props.show &&
            showedId !== this.state.uid
        ) {
            this.setState({
                show: true,
            });
            setShow(this.state.uid);
        }

        if (showedId !== prevProps.context.showedId && this.state.show && showedId !== this.state.uid) {
            this.props.onExternalClose(false);
        } else if (this.props.show !== prevProps.show) {
            this.setState({
                show: !!this.props.show,
            });
        }

        this.updateTipPosition();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateTipPosition);
    }

    getHost(): HTMLElement | null {
        return this.props.host || (this.activatorRef?.parentNode as HTMLElement | null);
    }

    updateTipPosition = () => {
        if (this.state.show && this.dropRef.current && this.props.placement && this.activatorRef) {
            const classList = this.props.layer
                ? [...BASE_CLASS_NAMES, makeDropLayerClass(this.props.layer)]
                : [...BASE_CLASS_NAMES];
            this.props.flexible && classList.push(FLEXIBLE_CLASS);

            updatePosition({
                placementSequence: PLACEMENT_TIP_SEQUENCE,
                activatorElement: this.activatorRef,
                dropElement: this.dropRef.current,
                host: this.getHost(),
                placement: this.props.placement,
                classNames: classList,
                arrowSize: ARROW_SIZE,
                arrow: this.arrowRef.current,
                behavior: {
                    placementOffset: DISTANCE + ARROW_SIZE,
                    alignToActivatorBorders: false,
                    fullScreenOnXS: false,
                    setupFullWidthOnXS: false,
                    arrowSize: 0,
                    showArrow: false,
                },
            });

            if (this.props.theme) {
                this.dropRef.current.classList.add(getThemeClass(this.props.theme));
            }
        }
    };

    renderTip() {
        const {
            children,
            placement,
            theme,
            context,
            host,
            show,
            layer,
            render,
            dataQa,
            onExternalClose,
            flexible,
            ...tooltipProps
        } = this.props;

        const classList = layer ? [...BASE_CLASS_NAMES, makeDropLayerClass(layer)] : [...BASE_CLASS_NAMES];
        flexible && classList.push(FLEXIBLE_CLASS);

        return (
            <CSSTransition
                in={this.state.show}
                timeout={{
                    enter: ANIMATION_TIMEOUT_MS,
                    exit: 0,
                }}
                unmountOnExit
                classNames={{
                    enterActive: styles['bloko-drop_active-enter'],
                    enterDone: styles['bloko-drop_done-enter'],
                }}
            >
                <div className={classList.join(' ')} ref={this.dropRef} {...tooltipProps}>
                    <div data-qa={dataQa}>{render()}</div>
                    <div className={composedSelectors['bloko-drop__arrow']} ref={this.arrowRef} />
                </div>
            </CSSTransition>
        );
    }

    render() {
        return (
            <Fragment>
                {this.props.children}
                {this.props.host ? ReactDOM.createPortal(this.renderTip(), this.props.host) : this.renderTip()}
            </Fragment>
        );
    }
}

export default DropTip;

export { TipTheme, TipLayer, TipPlacement, TipProvider };
