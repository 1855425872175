import { Children, isValidElement, ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';

import fuzzySearch from 'bloko/common/fuzzySearch';
import throttle from 'bloko/common/throttle';

interface SearchOption {
    /** Текст для поиска по опциям, если не передан используется текстовое содержимое */
    searchText?: string;
}

const getOptionText = (reactChild: ReactNode): string => {
    if (!isValidElement(reactChild) || !reactChild.props) {
        return '';
    }
    /**
     * Если дочерним элементом является CustomSelectOption — берем searchText из пропсов
     */
    const optionElement = reactChild as ReactElement<SearchOption>;
    if (optionElement.props.searchText) {
        return optionElement.props.searchText;
    }
    /**
     * Если у элемента нет дочерних элементов либо это boolean — ничего не берем
     */
    const someReactElement = reactChild as ReactElement<{ children: ReactNode }>;
    if (!someReactElement.props.children || typeof someReactElement.props.children === 'boolean') {
        return '';
    }
    /**
     * Если у единственного дочернего элемента есть свои дочки — рекурсивно вызываем функцию с этим элементом
     */
    if (isValidElement(someReactElement.props.children) && someReactElement.props.children.props) {
        return getOptionText(someReactElement.props.children);
    }
    /**
     * Если у элемента есть несколько дочек — объединяем их соержимое с помощью рекурсивного вызова функции
     */
    if (Array.isArray(someReactElement.props.children)) {
        return someReactElement.props.children
            .map((node: ReactNode) => (typeof node === 'string' ? node : getOptionText(node)))
            .join(' ');
    }
    /**
     * Если же children это примитив — приводим к строке
     */
    return someReactElement.props.children.toString();
};

const getFilteredOptions = <FOT>(
    children: ReactNode,
    filterQuery: string,
    validate: (child: ReactNode) => boolean
): FOT =>
    Children.toArray(children).filter((child) => {
        if (!validate(child)) {
            return false;
        }
        return !filterQuery || fuzzySearch.match(filterQuery, getOptionText(child));
    }) as FOT;

interface MenuOptionAsyncFilterFunctionType<FOT, CHT> {
    (children: CHT, filterQuery: string): Promise<FOT>;
}

export interface UseMenuOptionsType<FOT extends ReactNode> {
    filterQuery: string;
    setFilterQuery: (query: string) => void;
    filteredOptions: FOT;
}

const DEFAULT_THROTTLE_SEARCH_MS = 200;

const useMenuOptionsFiltered = <FOT extends ReactNode, CHT extends ReactNode>(
    children: CHT,
    validate: (child: ReactNode) => boolean,
    asyncFilterFunction?: MenuOptionAsyncFilterFunctionType<FOT, CHT>,
    filterThrottleMs = DEFAULT_THROTTLE_SEARCH_MS
): UseMenuOptionsType<FOT | []> => {
    const [filterQuery, setFilterQuery] = useState('');

    const [filteredOptions, _setFilteredOptions] = useState<FOT | []>(
        asyncFilterFunction ? [] : getFilteredOptions(children, filterQuery, validate)
    );

    const setFilteredOptions = useMemo(
        () =>
            throttle((children: CHT, filterQuery: string) => {
                if (asyncFilterFunction) {
                    void asyncFilterFunction(children, filterQuery).then(
                        (options) => options && _setFilteredOptions(options)
                    );
                } else {
                    _setFilteredOptions(getFilteredOptions(children, filterQuery, validate));
                }
            }, filterThrottleMs),
        [filterThrottleMs, asyncFilterFunction, validate]
    );

    useEffect(() => {
        setFilteredOptions(children, filterQuery);
    }, [children, filterQuery, setFilteredOptions]);
    return { filterQuery, setFilterQuery, filteredOptions };
};

export default useMenuOptionsFiltered;
