import { FC, ReactNode, useState, PropsWithChildren } from 'react';

import Modal, { ModalError } from 'bloko/blocks/modal';
import { isValidTreeSelectorElement, isValidTreeSelector } from 'bloko/blocks/treeSelector';

import 'bloko/blocks/treeSelectorPopup/treeSelectorPopup.less';

import PopupFooter from './PopupFooter';
import PopupHeader from './PopupHeader';
import PopupTreeSelector from './PopupTreeSelector';
import useHandlers from './hooks/useHandlers';

export interface TreeSelectorPopupProps {
    /** Показывать ли TreeSelectorPopup */
    visible?: boolean;
    /** Заголовок */
    title?: ReactNode;
    /** Отображать ли строку поиска */
    search?: boolean;
    /** Закрывать ли попап по кнопке подтверждения */
    hideOnSubmitClick?: boolean;
    /** Переводы:<br/>
     * `submit` - кнопка подтвержения<br/>
     * `cancel` - кнопка отмены<br/>
     * `searchPlaceholder` - плейсхолдер в строке поиска<br/>
     * `notFound` - сообщение о том, что по поисковому запросу ничего не найдено */
    trl: {
        submit: string;
        cancel: string;
        searchPlaceholder?: string;
        notFound?: string;
    };
    /** Подсказка над TreeSelector */
    hint?: ReactNode;
    /** Текст ошибки */
    error?: string;
    /** Заблокирована ли кнопка подтверждения */
    disabledSubmit?: boolean;
    /** Отображать ли иконку загрузки на кнопке подтверждения */
    showLoadingOnSubmit?: boolean;
    /** Обработчик изменения набора выбранных ID.
     * В аргументах получает список выбранных элементов`{Array} selected`. */
    onChange?: (selected: string[]) => void;
    /** Обработчик закрытия TreeSelectorPopup */
    onClose: () => void;
    /** Кастомный обработчик нажатия кнопки подтверждения
     * В аргументах получает список выбранных элементов `{Array} selected`. */
    onSubmit?: (selected: string[]) => void;
    /** Список выбранных id для контолируемого компонента */
    selected?: string[];
    /** Отображать ли крестик очистки строки поиска */
    searchWithClear?: boolean;
    /** Очищать ли поле поиска после выбора элемента */
    clearSearchOnChange?: boolean;
    /** Обработчик изменения строки contentFilterQuery
     * В аргументах получает список подходящих под запрос id и сам запрос*/
    onChangeFilterQuery?: (ids: string[], query: string) => void;
    /** Блок между заголовком попапа и поисковым инпутом */
    contentAfterTitle?: ReactNode;
    /** Дополнительный элемент в футере */
    footerExtra?: ReactNode;
    /** DOM нода хоста в рамках которого нужно рендерить модальное окно, по дефолту рендер будет в body.*/
    host?: HTMLElement;
}

const defaultArray: string[] = [];

const TreeSelectorPopup: FC<TreeSelectorPopupProps & PropsWithChildren> = ({
    selected: controlledSelected = defaultArray,
    children,
    visible,
    onClose,
    title,
    search = true,
    hint,
    trl,
    searchWithClear,
    disabledSubmit,
    showLoadingOnSubmit,
    onChange,
    onSubmit,
    error,
    hideOnSubmitClick = true,
    onChangeFilterQuery,
    clearSearchOnChange,
    contentAfterTitle,
    footerExtra,
    host,
}) => {
    if (!isValidTreeSelector(children)) {
        throw new Error('Children must be TreeSelector or TreeSelectorDummy');
    }

    const [contentFilterQuery, setContentFilterQuery] = useState('');
    const [expanded, setExpanded] = useState(children.props.initialExpanded || []);
    const [selected, setSelected] = useState(
        isValidTreeSelectorElement(children) ? children.props.initialSelected || [] : controlledSelected
    );

    const { handleSubmit, handleTreeSelectorChange, handleTreeSelectorExpand, handleSearch } = useHandlers({
        children,
        controlledSelected,
        onClose,
        setSelected,
        setContentFilterQuery,
        setExpanded,
        clearSearchOnChange,
        hideOnSubmitClick,
        onSubmit,
        onChange,
    });

    const headerProps = {
        hint,
        search,
        title,
        searchWithClear,
        contentFilterQuery,
        onSearch: handleSearch,
        trlPlaceholder: trl.searchPlaceholder,
        contentAfterTitle,
    };

    const treeSelectorProps = {
        selected,
        expanded,
        controlledSelected,
        contentFilterQuery,
        onChangeFilterQuery,
        onChange: handleTreeSelectorChange,
        onExpand: handleTreeSelectorExpand,
        trlNotFound: trl.notFound,
        children,
    };

    const footerProps = {
        disabledSubmit,
        showLoadingOnSubmit,
        onClose,
        footerExtra,
        onSubmit: handleSubmit,
        trlCancel: trl.cancel,
        trlSubmit: trl.submit,
    };

    return (
        <Modal visible={visible} onClose={onClose} host={host}>
            <PopupHeader {...headerProps} />
            <PopupTreeSelector {...treeSelectorProps} />
            <ModalError visible={!!error}>{error}</ModalError>
            <PopupFooter {...footerProps} />
        </Modal>
    );
};

export default TreeSelectorPopup;
