import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const EnvelopeScaleSmallKindDefault = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M1.3335 4.66666L6.77678 8.47695C7.21756 8.7855 7.43795 8.93977 7.67767 8.99953C7.88943 9.05231 8.1109 9.05231 8.32265 8.99953C8.56238 8.93977 8.78277 8.7855 9.22355 8.47695L14.6668 4.66666M4.5335 13.3333H11.4668C12.5869 13.3333 13.147 13.3333 13.5748 13.1153C13.9511 12.9236 14.2571 12.6176 14.4488 12.2413C14.6668 11.8135 14.6668 11.2534 14.6668 10.1333V5.86666C14.6668 4.74655 14.6668 4.1865 14.4488 3.75868C14.2571 3.38235 13.9511 3.07639 13.5748 2.88464C13.147 2.66666 12.5869 2.66666 11.4668 2.66666H4.5335C3.41339 2.66666 2.85334 2.66666 2.42552 2.88464C2.04919 3.07639 1.74323 3.38235 1.55148 3.75868C1.3335 4.1865 1.3335 4.74655 1.3335 5.86666V10.1333C1.3335 11.2534 1.3335 11.8135 1.55148 12.2413C1.74323 12.6176 2.04919 12.9236 2.42552 13.1153C2.85334 13.3333 3.41339 13.3333 4.5335 13.3333Z"
                stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                strokeWidth={1.33333}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ),
    'Envelope'
);
export default EnvelopeScaleSmallKindDefault;
