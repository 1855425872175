import { ChangeEvent, FC, ReactNode, Ref, useEffect, useRef, PropsWithChildren } from 'react';
import classnames from 'classnames';
import { Merge } from 'type-fest';

import styles from 'bloko/blocks/checkbox/checkbox.less';

interface CheckboxProps {
    /** Содержимое внутри лейбла checkbox*/
    children?: ReactNode;
    /** Открываемый контент при checked=true */
    openingContent?: ReactNode;
    /** Обработчик onChange; в качестве аргумента передает event */
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    /** Функция для получения рутового DOM элемента компонента Checkbox*/
    innerRef?: Ref<HTMLLabelElement>;
    /** Свойства лейбла checkbox */
    labelProps?: JSX.IntrinsicElements['span'];
    /** Свойства обертки checkbox */
    wrapperProps?: JSX.IntrinsicElements['label'];
    /** Флаг checked контрола по умолчанию для uncontrolled checkbox */
    defaultChecked?: boolean;
    /** Флаг checked контрола */
    checked?: boolean;
    /** Флаг indeterminate контрола */
    indeterminate?: boolean;
    /** Флаг disabled контрола */
    disabled?: boolean;
    /** Флаг не валидности контрола */
    invalid?: boolean;
}
const Checkbox: FC<Merge<JSX.IntrinsicElements['input'], CheckboxProps> & PropsWithChildren> = ({
    children,
    invalid,
    innerRef,
    openingContent,
    indeterminate = false,
    source,
    labelProps,
    wrapperProps,
    ...checkboxProps
}) => {
    const checkboxRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (checkboxRef.current) {
            checkboxRef.current.indeterminate = indeterminate;
        }
    }, [indeterminate]);

    return (
        <label
            className={classnames(styles['bloko-checkbox'], {
                [styles['bloko-checkbox_invalid']]: invalid,
            })}
            {...wrapperProps}
            ref={innerRef}
            source={source}
        >
            <input {...checkboxProps} ref={checkboxRef} className={styles['bloko-checkbox__input']} type="checkbox" />
            <span {...labelProps} suppressHydrationWarning className={styles['bloko-checkbox__text']}>
                {children}
                {openingContent ? (
                    <div className={styles['bloko-checkbox__opening-content']} suppressHydrationWarning>
                        {openingContent}
                    </div>
                ) : null}
            </span>
        </label>
    );
};

export default Checkbox;
