import { BoundingClientRect } from 'bloko/common/types';

import { Dimension, Alignment } from 'bloko/blocks/drop/types';

interface PlacementParams {
    elementMetrics: BoundingClientRect;
    dropElementMetrics: BoundingClientRect;
    offset: number;
}

interface PlacementOutput {
    metrics: Alignment;
    possibleToChange: Dimension;
}

export default {
    top({ elementMetrics, dropElementMetrics, offset }: PlacementParams): PlacementOutput {
        return {
            metrics: {
                top: elementMetrics.top - dropElementMetrics.height - offset,
            },
            possibleToChange: 'x',
        };
    },
    bottom({ elementMetrics, offset }: PlacementParams): PlacementOutput {
        return {
            metrics: {
                top: elementMetrics.bottom + offset,
            },
            possibleToChange: 'x',
        };
    },
    left({ elementMetrics, dropElementMetrics, offset }: PlacementParams): PlacementOutput {
        return {
            metrics: {
                left: elementMetrics.left - dropElementMetrics.width - offset,
            },
            possibleToChange: 'y',
        };
    },
    right({ elementMetrics, offset }: PlacementParams): PlacementOutput {
        return {
            metrics: {
                left: elementMetrics.right + offset,
            },
            possibleToChange: 'y',
        };
    },
};
