import { ANIMATION_TIMEOUT_MS, ENTER_ANIMATION_MS } from 'bloko/blocks/drop/constants';
import { IconColor, CrossScaleSmallEnclosedFalse } from 'bloko/blocks/icon';
import IconReactRenderer from 'bloko/blocks/icon/IconReactRenderer';
import Components from 'bloko/common/core/Components';

import template from 'bloko/blocks/drop/InfoInline/template.mustache';

/**
 * @exports bloko/blocks/drop/InfoInline/InfoInline
 *
 * Создает блок-подсказку для пользователя.
 *
 * @param {Element} element DOM-елемент, активатор. InfoInline позиционируется относительно него.
 *
 * @param {Object} params параметры InfoInline
 *
 * @param {Boolean} params.showCloseButton параметр для отображения иконки закрытия
 * @param {Boolean} params.withPointer параметр для отображения указателя
 * @param {Boolean} params.show параметр для отображения InfoInline по умолчанию
 * @param {String | Element} params.host=element.parentNode DOM нода или селектор хоста в рамках которого
 * нужно рендерить InfoInline
 * @param {String} params.html cодержимое InfoInline, поддерживает html разметку.
 * @param {String} params.dataQa применяются к div элементу InfoInline.
 *
 * @constructor
 */

const InfoInline = Components.build({
    defaults: {
        onClose: () => {},
        showCloseButton: true,
        dataQa: 'bloko-drop-info-inline',
    },
    create(element, params) {
        let options;
        let isVisible;

        const publicInterface = {
            /**
             * Отображает InfoInline
             */
            show(showDefault = false) {
                const { infoInlineNode, hostNode } = options;

                if (isVisible) {
                    return;
                }

                if (showDefault) {
                    isVisible = true;
                    hostNode.appendChild(infoInlineNode);
                    return;
                }

                isVisible = true;
                infoInlineNode.classList.add('bloko-drop_active-enter');
                hostNode.appendChild(infoInlineNode);

                setTimeout(() => infoInlineNode.classList.add('bloko-drop_done-enter'), ANIMATION_TIMEOUT_MS);

                setTimeout(() => {
                    infoInlineNode.classList.remove('bloko-drop_done-enter');
                    infoInlineNode.classList.remove('bloko-drop_active-enter');
                }, ANIMATION_TIMEOUT_MS + ENTER_ANIMATION_MS);
            },

            /**
             * Скрывает InfoInline
             */
            hide() {
                if (isVisible) {
                    options.hostNode.removeChild(options.infoInlineNode);
                    isVisible = false;
                }
            },

            /**
             * Изменяет состояние InfoInline (открывает, если скрыт, и наоборот)
             *
             * @param state {Boolean}
             */
            toggle(state) {
                if (arguments.length === 0 ? !isVisible : state) {
                    this.show();
                } else {
                    this.hide();
                }
            },

            /**
             * Изменяет параметры указаные при инициализации
             * @param params {Object} Параметры params InfoInline
             */
            changeOptions({
                host = params.host || element.parentNode,
                html = params.html,
                dataQa = params.dataQa,
                showCloseButton = params.showCloseButton,
                withPointer = params.withPointer,
                show = params.show,
            }) {
                const hostNode = typeof host === 'string' ? document.querySelector(host) : host;
                const infoInlineHTML = template.render({
                    withPointer: withPointer ? 'bloko-info-inline_with-pointer' : '',
                    closeButton: showCloseButton ? '' : 'bloko-info-inline__content_without-close',
                    showCloseButton,
                    dataQa,
                });

                const infoInlineWrapNode = document.createElement('div');
                infoInlineWrapNode.innerHTML = infoInlineHTML;
                const infoInlineNode = infoInlineWrapNode.firstChild;

                if (typeof html === 'string') {
                    infoInlineNode.querySelector('.Bloko-InfoInline-Content').innerHTML = html;
                } else if (html) {
                    infoInlineNode.querySelector('.Bloko-InfoInline-Content').appendChild(html);
                }

                function callOnClose(event) {
                    if (event.target.closest('.Bloko-Drop-Close')) {
                        publicInterface.toggle();
                    }
                }

                if (showCloseButton) {
                    Components.make(IconReactRenderer, infoInlineNode.querySelector('.Bloko-Drop-BodyIconClose'), {
                        CrossScaleSmallEnclosedFalse,
                        iconProps: {
                            initial: IconColor.Gray50,
                            highlighted: IconColor.Gray60,
                        },
                    });
                    infoInlineNode.addEventListener('click', callOnClose);
                }

                options = {
                    infoInlineNode,
                    hostNode,
                };

                if (show) {
                    this.show(show);
                }

                return this;
            },
        };

        publicInterface.changeOptions(params);

        return publicInterface;
    },
});

export default InfoInline;
