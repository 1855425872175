import styles from 'bloko/blocks/chips/chip.less';

import { ComponentWithCustomElement } from '../../common/helpers/types';

export interface LinkChipProps {
    /** data-qa используется для x-path в тестах */
    'data-qa'?: string;
    /** Указывает на строку с компонентом в исходном коде в режиме разработки. Генерируется babel-plugin-react-source */
    source?: string;
    /** Кастомный компонент */
    Element?: 'a' | 'span';
}

const LinkChip: ComponentWithCustomElement<LinkChipProps, 'a'> = ({ Element = 'a', ...linkProps }) => (
    <Element className={styles['bloko-chips-chip-link']} {...linkProps} />
);

export default LinkChip;
