import $ from 'jquery';

const getDeferred = function (element, block) {
    const $element = $(element).first();
    const blokoReady = `bloko-ready-${block}`;
    let deferred = $element.data(blokoReady);
    if (!deferred) {
        deferred = $.Deferred();
        $element.data(blokoReady, deferred);
    }
    return deferred;
};

/**
 * Компонент для отправки сообщения между двумя компонентами.
 * Оба компонента используют определенный  DOM элемент,
 * на который завязываются по дата-аттрибуту bloko-ready-{{name}}
 * читающий компонент получает промис, а резолвящий может передать кастомные данные с помощью функции resolve
 */
export default {
    /**
     * Резолвит промис с кастомными данными
     * @param {Element|jQuery} element Элемент, на котором резолвим промис.
     *                                 Если передается jQuery коллекция, то будет выбран первый элемент
     * @param {String} block           Имя компонента
     * @param data                     Данные, которыми будет зарезолвлен промис
     */
    resolve(element, block, data) {
        return getDeferred(element, block).resolve(data);
    },

    /**
     * Возвращает промис по выбранному селектору и имени
     * @param {Element|jQuery} element Элемент, на котором резолвим промис.
     *                                 Если передается jQuery коллекция, то будет выбран первый элемент
     * @param {String} block           Имя компонента
     */
    getPromise(element, block) {
        return getDeferred(element, block).promise();
    },
};
