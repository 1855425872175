import { useContext, useMemo } from 'react';

import { TranslationLangContext } from 'bloko/common/hooks/useTranslations';

import createRemoteDataProvider from 'bloko/blocks/suggest/createRemoteDataProvider';
import { DataProvider } from 'bloko/blocks/suggest/types';

interface UseRemoteDataProviderProps {
    remote: string;
    wildcard: string;
}

const useRemoteDataProvider = ({ remote, wildcard }: UseRemoteDataProviderProps): DataProvider => {
    const lang = useContext(TranslationLangContext);
    return useMemo(() => createRemoteDataProvider(remote.replace('{lang}', lang), wildcard), [lang, remote, wildcard]);
};

export default useRemoteDataProvider;
