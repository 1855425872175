import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const ArrowScaleSmallKindRight = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M3.3335 7.99998H12.6668M12.6668 7.99998L8.00016 3.33331M12.6668 7.99998L8.00016 12.6666"
                stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                strokeWidth={1.33333}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ),
    'Arrow'
);
export default ArrowScaleSmallKindRight;
