import { DropPlacement, DropLayer } from 'bloko/blocks/drop/constants';
import { ValueOf } from 'bloko/common/types';

import styles from 'bloko/blocks/drop/Menu/menu.less';

export const MenuPlacement = DropPlacement;
export type MenuPlacementValue = ValueOf<typeof MenuPlacement>;

export { DropLayer as MenuLayer };

export const defaultProps = {
    layer: DropLayer.AboveContent,
    onlySetPlacement: false,
    placement: MenuPlacement.BottomStart,
    dataQa: 'bloko-drop-menu',
};

export const BASE_CLASS_NAMES = [styles['bloko-drop_menu'], styles['bloko-drop_theme-light']];
export const PADDING_WRAPPER_CLASS_NAME = styles['bloko-drop__padding-wrapper_menu'];

export const BEHAVIOR = {
    showArrow: false,
    arrowSize: 0,
    setupFullWidthOnXS: true,
    placementOffset: 0,
    alignToActivatorBorders: true,
    fullScreenOnXS: true,
};
