import { memo, useCallback, useState, useMemo, FC } from 'react';

import {
    toDate,
    toISO,
    startOfMonth,
    DateString,
    YearMonthDayString,
    PickDate,
    OnArrowClick,
    ClickDate,
    DateConstructorType,
} from 'bloko/blocks/calendar/datesHelper';
import {
    translator,
    prepareCalendarDates,
    DisabledWeekdays,
    CalendarPickerAndSwitcherKind,
} from 'bloko/blocks/calendar/helper';
import { CalendarLanguage, CalendarTranslations } from 'bloko/blocks/calendar/translations';

import CalendarPicker from 'bloko/blocks/calendar/Base/CalendarPicker';
import CalendarSwitcher from 'bloko/blocks/calendar/Base/CalendarSwitcher';

import styles from 'bloko/blocks/calendar/calendar.less';

export interface CalendarProps {
    /** Дата инициализации календаря в формате 'YYYY-MM-DD' или ISO */
    initialDate: DateString;
    /** Выделенные даты */
    highlighted?: DateString[];
    /** Даты, которые нельзя выбрать */
    disabled?: DateString[];
    /** Дни недели, которые нельзя выбрать */
    disabledWeekdays?: DisabledWeekdays;
    /** Делает неактивными даты предыдущего и следующего месяцев */
    disablePartMonth?: boolean;
    /** Язык отображения текущего месяца и дней недели */
    language?: CalendarLanguage;
    /** Переводы, используются в случае, когда языков календаря недостаточно */
    translations?: CalendarTranslations;
    /** Обработчик смены месяца/года, принимает дату в 'YYYY-MM-DD' формате */
    onDateChange?: (dateString: YearMonthDayString) => void;
    /** Обработчик клика по дате, принимает дату в 'YYYY-MM-DD' формате */
    onDateClick?: ClickDate;
    /** Отключает возможность смены месяца и года */
    disableSwitchers?: boolean;
    /** Делает неактивными все дни до указанной даты. Принимает любое выражение, подходящие для конструктора класса Date */
    disableDaysBeforeDate?: DateConstructorType;
    /** Делает неактивными все дни после указанной даты. Принимает любое выражение, подходящие для конструктора класса Date */
    disableDaysAfterDate?: DateConstructorType;
    /** Флаг интервального отображения дат */
    showInterval?: boolean;
    /** Указывает на строку с компонентом в исходном коде в режиме разработки. Генерируется babel-plugin-react-source */
    source?: string;
}

const YEAR_MONTH_POSITION = 7;

const CalendarBase: FC<CalendarProps> = ({
    initialDate,
    highlighted = [],
    disabled = [],
    disabledWeekdays = [],
    disablePartMonth = true,
    language = CalendarLanguage.Ru,
    translations,
    onDateChange,
    onDateClick,
    disableSwitchers = false,
    disableDaysBeforeDate,
    disableDaysAfterDate,
    showInterval,
    source,
}) => {
    const translate = useMemo(() => translator(language, translations), [language, translations]);
    const [currentDate, setCurrentDate] = useState(startOfMonth(toDate(initialDate)));
    const [visiblePicker, setVisiblePicker] = useState(CalendarPickerAndSwitcherKind.Day);

    const initialCalendarDates = prepareCalendarDates(
        currentDate,
        highlighted.map((highlightedDate) => toDate(highlightedDate)),
        disabled,
        disabledWeekdays,
        disablePartMonth,
        disableDaysBeforeDate,
        disableDaysAfterDate,
        showInterval
    );

    const changeDate = useCallback<OnArrowClick>(
        (setDate) => {
            if (!disableSwitchers && visiblePicker === CalendarPickerAndSwitcherKind.Day) {
                const newCurrentDate = setDate(currentDate, 1);
                setCurrentDate(newCurrentDate);
                onDateChange?.(toISO(newCurrentDate));
            }
        },
        [currentDate, disableSwitchers, onDateChange, visiblePicker]
    );

    const pickDate = useCallback<PickDate>(
        (newDate, setDate) => {
            const newCurrentDate = setDate(currentDate, newDate);
            setCurrentDate(newCurrentDate);
            setVisiblePicker(CalendarPickerAndSwitcherKind.Day);
            onDateChange?.(toISO(newCurrentDate));
        },
        [currentDate, onDateChange]
    );

    const changeVisiblePicker = useCallback(
        (kind: CalendarPickerAndSwitcherKind) => {
            if (!disableSwitchers) {
                setVisiblePicker(kind);
            }
        },
        [disableSwitchers]
    );

    return (
        <div className={styles['bloko-calendar']} source={source}>
            <div
                className={styles['bloko-calendar__switcher-wrapper']}
                data-qa="bloko-calendar-current-month"
                data-year-month={toISO(currentDate).substring(0, YEAR_MONTH_POSITION)}
            >
                <CalendarSwitcher
                    kind={CalendarPickerAndSwitcherKind.Month}
                    onSwitch={changeVisiblePicker}
                    onArrowClick={changeDate}
                    visiblePicker={visiblePicker}
                    disabled={disableSwitchers}
                >
                    {translate.month[currentDate.getMonth()]}
                </CalendarSwitcher>
                <CalendarSwitcher
                    kind={CalendarPickerAndSwitcherKind.Year}
                    onSwitch={changeVisiblePicker}
                    onArrowClick={changeDate}
                    visiblePicker={visiblePicker}
                    disabled={disableSwitchers}
                >
                    {currentDate.getFullYear()}
                </CalendarSwitcher>
            </div>
            <div className={styles['bloko-calendar__base']}>
                <CalendarPicker
                    showInterval={showInterval}
                    kind={visiblePicker}
                    translate={translate}
                    date={currentDate}
                    initialDates={initialCalendarDates}
                    onClick={onDateClick}
                    onPick={pickDate}
                />
            </div>
        </div>
    );
};

export default memo(CalendarBase);
