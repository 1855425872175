import { FC, memo, PropsWithChildren } from 'react';

import dataQa from 'bloko/blocks/treeSelector/dataQa';
import { TreeModel } from 'bloko/common/tree/types';

import styles from 'bloko/blocks/treeSelector/treeSelector.less';

interface TextProps {
    /** ID элемента. */
    id: TreeModel['id'];
    /** Элемент с потомками. */
    hasChildren: boolean;
    onClick: () => void;
}

const Text: FC<TextProps & PropsWithChildren> = ({ id, hasChildren, onClick, children }) => {
    if (hasChildren) {
        return (
            <div
                className={styles['bloko-tree-selector-item__text']}
                data-qa={`${dataQa.text} ${dataQa.text}-${id}`}
                onClick={onClick}
            >
                {children}
            </div>
        );
    }
    return <>{children}</>;
};

export default memo(Text);
