import { FC, ReactElement, PropsWithChildren } from 'react';

import Hover, { HoverProps } from 'bloko/blocks/drop/Hover';

import Tip, { TipProps, TipLayer, TipTheme, TipPlacement } from './index';

import TipProvider from './Context';

interface HoverTipProps extends Partial<HoverProps> {
    /** Элемент инициатор, относительно которого показывать DropElement */
    children: ReactElement;
}

const HoverTip: FC<Omit<TipProps, 'onExternalClose'> & HoverTipProps & PropsWithChildren> = ({
    children,
    ...props
}) => (
    <Hover DropElement={Tip} {...props}>
        {children}
    </Hover>
);

export default HoverTip;
export { TipTheme, TipLayer, TipPlacement, TipProvider };
