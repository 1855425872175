import classnames from 'classnames';

import { ComponentWithCustomElement } from 'bloko/common/helpers/types';

import styles from 'bloko/blocks/gap/gap.less';

interface GapProps {
    /** @deprecated Добавить отступ сверху, используйте VSpacing */
    top?: boolean;
    /** Добавить отступ справа */
    right?: boolean;
    /** Добавить отступ слева */
    left?: boolean;
    /** @deprecated Добавить отступ снизу, используйте VSpacing */
    bottom?: boolean;
    /** @deprecated Добавить отступ сверху для m размера, этот вариант будет удален, используйте VSpacing */
    mTop?: boolean;
    /** Добавить отступ справа для m размера */
    mRight?: boolean;
    /** Добавить отступ слева для m размера */
    mLeft?: boolean;
    /** @deprecated Добавить отступ снизу для m размера, этот вариант будет удален, используйте VSpacing */
    mBottom?: boolean;
    /** @deprecated Добавить отступ сверху для s размера, этот вариант будет удален, используйте VSpacing */
    sTop?: boolean;
    /** Добавить отступ справа для s размера */
    sRight?: boolean;
    /** @deprecated Добавить отступ снизу для s размера, этот вариант будет удален, используйте VSpacing */
    sBottom?: boolean;
    /** Добавить отступ слева для s размера */
    sLeft?: boolean;
    /** @deprecated Добавить отступ сверху для xs размера, используйте VSpacing */
    xsTop?: boolean;
    /** Добавить отступ справа для xs размера */
    xsRight?: boolean;
    /** Добавить отступ слева для xs размера */
    xsLeft?: boolean;
    /** @deprecated Добавить отступ снизу для xs размера, используйте VSpacing */
    xsBottom?: boolean;
    /** @deprecated Добавить отступ сверху для l размера, этот вариант будет удален, используйте VSpacing */
    lTop?: boolean;
    /** Добавить отступ справа для l размера */
    lRight?: boolean;
    /** @deprecated Добавить отступ снизу для l размера, этот вариант будет удален, используйте VSpacing */
    lBottom?: boolean;
    /** Добавить отступ слева для l размера */
    lLeft?: boolean;
    /** Кастомный компонент функция или 'span', 'div' */
    Element?: 'span' | 'div';
    /** Указывает на строку с компонентом в исходном коде в режиме разработки. Генерируется babel-plugin-react-source */
    source?: string;
}

const Gap: ComponentWithCustomElement<GapProps, 'div'> = ({
    top,
    right,
    left,
    bottom,
    mTop,
    mRight,
    mLeft,
    mBottom,
    sTop,
    sRight,
    sBottom,
    sLeft,
    xsTop,
    xsRight,
    xsLeft,
    xsBottom,
    lTop,
    lRight,
    lBottom,
    lLeft,
    Element = 'div',
    ...gapProps
}) => (
    <Element
        className={classnames(styles['bloko-gap'], {
            [styles['bloko-gap_top']]: top,
            [styles['bloko-gap_right']]: right,
            [styles['bloko-gap_left']]: left,
            [styles['bloko-gap_bottom']]: bottom,

            [styles['bloko-gap_s-top']]: sTop,
            [styles['bloko-gap_s-right']]: sRight,
            [styles['bloko-gap_s-left']]: sLeft,
            [styles['bloko-gap_s-bottom']]: sBottom,

            [styles['bloko-gap_m-top']]: mTop,
            [styles['bloko-gap_m-right']]: mRight,
            [styles['bloko-gap_m-left']]: mLeft,
            [styles['bloko-gap_m-bottom']]: mBottom,

            [styles['bloko-gap_xs-top']]: xsTop,
            [styles['bloko-gap_xs-right']]: xsRight,
            [styles['bloko-gap_xs-left']]: xsLeft,
            [styles['bloko-gap_xs-bottom']]: xsBottom,

            [styles['bloko-gap_l-top']]: lTop,
            [styles['bloko-gap_l-right']]: lRight,
            [styles['bloko-gap_l-left']]: lLeft,
            [styles['bloko-gap_l-bottom']]: lBottom,
        })}
        {...gapProps}
    />
);

export default Gap;
