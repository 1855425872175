import $ from 'jquery';
import _ from 'underscore';

import { IconColor, ChevronScaleSmallKindDown, ChevronScaleSmallKindUp } from 'bloko/blocks/icon';
import IconReactRenderer from 'bloko/blocks/icon/IconReactRenderer';
import Components from 'bloko/common/core/Components';

import hiddenSection from 'bloko/blocks/tagList/hiddenSection.mustache';
import InlineTagListView from 'bloko/blocks/tagList/inlineTagListView';
import nestedTemplate from 'bloko/blocks/tagList/nested.mustache';
import nestedChildSection from 'bloko/blocks/tagList/nestedChildSection.mustache';
import removeSection from 'bloko/blocks/tagList/removeSection.mustache';
import textSection from 'bloko/blocks/tagList/textSection.mustache';
import textTemplate from 'bloko/blocks/tagList/textTemplate.mustache';

/**
 * Древовидный список тегов.
 *
 * @param {Element} element
 * @param {Object} options
 * @constructor
 */
export default InlineTagListView.extend({
    /**
     * Функция-шаблонизатор, которая управляет рендером шаблонов и partial'ов
     * @param  {Object} data Данные которые используются в шаблоне
     * @return {String}      Готовый к вставке в DOM шаблон с данными
     * @private
     */
    template(data) {
        return nestedTemplate.render(data, {
            textSection,
            textTemplate: this.options.textTemplate || textTemplate,
            hiddenSection,
            removeSection,
            nestedChildSection,
        });
    },

    childTemplate(data) {
        return nestedChildSection.render(data, {
            textSection,
            textTemplate: this.options.textTemplate || textTemplate,
            hiddenSection,
            removeSection,
        });
    },

    makeTemplateData(item) {
        return {
            items: [].concat(item.toJSON()),
            renderHiddenParentField: this.options.renderHiddenParentField || item.get('children').length === 0,
            hidden: this.options.hiddenFieldName,
            childHidden: this.options.hiddenChildFieldName,
        };
    },

    events: $.extend({}, InlineTagListView.prototype.events, {
        'click .Bloko-TagList-Expand': 'toggleExpandedOnClick',
    }),

    initialize(params) {
        this.parentsToRender = [];
        InlineTagListView.prototype.initialize.apply(this, [params]);
        this.listenTo(this.tags, 'update', this.renderTagUpdate);
    },

    initTags(expanded) {
        const existingTags = [];

        $('.Bloko-TagList-ParentTag', this.$el).each((index, element) => {
            const $tag = $(element);
            const id = $tag.attr('data-tag-id');

            existingTags.push({
                id,
                hiddenValue: id,
                text: $tag.find('.Bloko-TagList-Text').eq(0).text().trim(),
                expanded: expanded || false,
                items: [],
            });

            const cursor = existingTags[existingTags.length - 1].items;

            $tag.find('.Bloko-TagList-Children .Bloko-TagList-Tag').each((index, element) => {
                const $element = $(element);
                const id = $element.attr('data-tag-id');

                cursor.push({
                    id,
                    hiddenValue: id,
                    text: $element.text().trim(),
                });
            });
        });

        this.setTags(existingTags);
    },

    /**
     * Рендерит теги, которые были добавлены, после чего
     * для новых тегов добавляет css класс с анимацией
     * @private
     * @param {Object} item модель, которая была добавлена
     *
     * @fires Bloko-TagList-Added
     *
     */
    renderTagAdd(item) {
        if (!item.has('parent')) {
            InlineTagListView.prototype.renderTagAdd.apply(this, [item]);
        } else {
            // Записываем id предков которые предстоит обновить
            // обновление будет производиться при событии update
            // для минимизации расходов на рендер
            this.parentsToRender.push(item.get('parent'));

            //        this.renderIcons?.(item.get('id'));
            /**
             * Событие вызываемое при добавлении нового тега в DOM
             *
             * @event Bloko-TagList-Added
             * @type {Object}
             * @property {Object} item модель, которая была добавлена в коллекцию
             */
            this.trigger('Bloko-TagList-Added', item.toJSON());
        }

        return this;
    },

    /**
     * Используется для рендера вложенных тегов, в тех случаях когда
     * может быть добавлено несколько детей, что бы минимизировать
     * колличество рендеров
     * @private
     * @param  {Object} collection коллекция на которой вызвано событие upadate
     * @param  {Object} options    объект содержащий признак того добавление или удаление произошло
     */
    renderTagUpdate(collection, options) {
        if (options.add) {
            _.unique(this.parentsToRender).forEach((parentId) => {
                this.renderTagChange(collection.get(parentId));
            });
        }
        this.parentsToRender = [];
    },

    /**
     * Рендерит тег, в модели которого произошли изменения
     * @private
     * @param  {Object} item модель в которой произошли изменения
     * @return {Object}      this для возможности чейнинга
     *
     * @fires Bloko-TagList-Changed
     * @inheritdoc
     */
    renderTagChange(item) {
        const tag = this.$nodeByTagId(item.get('id'));

        if (!item.has('parent')) {
            tag.replaceWith(this.template(this.makeTemplateData(item)));
        } else {
            tag.replaceWith(this.childTemplate(this.makeTemplateData(item)));
        }

        this.renderIcons?.(item);

        /**
         * Событие вызываемое при изменении тега в DOM
         *
         * @event Bloko-TagList-Changed
         * @type {Object}
         * @property {Object} item модель, которая была изменена
         */
        this.trigger('Bloko-TagList-Changed', item.toJSON());
        return this;
    },

    /**
     * Находит тег по переданному tagId и удаляет его.
     * @private
     * @param {Number} tagId тега
     * @inheritdoc
     */
    removeTag(tagId) {
        const tag = this.tags.get(tagId);
        const children = tag.get('children');
        const parentId = tag.get('parent');

        if (children) {
            children.forEach((childId) => {
                this.tags.remove(childId);
            });

            tag.set('children', [], { silent: true });
        }

        if (parentId) {
            const parent = this.tags.get(parentId);

            // Удаляем тег из списка детей у родительского тега
            parent.attributes.children.splice(parent.attributes.children.indexOf(tagId), 1);

            // Если тег был последним у родителя, удаляем вместе с родителем.
            if (parent.get('children').length === 0) {
                this.tags.remove(parentId);
            } else {
                this.trigger('Bloko-TagList-Changed', parent.toJSON());
            }
        }

        this.tags.remove(tagId);
        return this;
    },

    renderIcons(item) {
        const tag = this.$nodeByTagId(item.get('id')).get(0);

        if (tag) {
            this.renderRemoveIcon(tag);
            this.renderExpandenIcon(tag, item.get('expanded'));
        }
    },

    renderExpandenIcon(element, expanded) {
        const iconElements = element.querySelectorAll('.Bloko-TagList-ExpandedIcon');

        for (const iconElement of [...iconElements]) {
            Components.make(IconReactRenderer, iconElement, {
                IconComponent: expanded ? ChevronScaleSmallKindUp : ChevronScaleSmallKindDown,
                iconProps: {
                    initial: IconColor.Gray50,
                    highlighted: IconColor.Gray60,
                    'data-qa': `bloko-tag__${expanded ? 'collapse' : 'expand'}`,
                },
            });
        }
    },

    /**
     * Переключает состояния вложенных тегов
     * @param  {Number} tagId id тега-родителя, для которого нужно переключить состояние
     * @param  {Boolean} state если передано true - раскрывает тег, если false - схлопывает
     * @return {Object} инстанс Backbone view (TagList)
     */
    toggleExpanded(tagId, state) {
        const tag = this.tags.get(tagId);

        tag.set('expanded', state !== undefined ? state : !tag.get('expanded'));

        return this;
    },

    /**
     * При клике на тег вызывает метод toggleExpanded
     * @private
     * @param  {Event} event событие клика мыши
     */
    toggleExpandedOnClick(event) {
        const tagId = this.getTagId(event.currentTarget);

        this.toggleExpanded(tagId);
    },
});
