import classnames from 'classnames';

import { ComponentWithCustomElement } from 'bloko/common/helpers/types';

import styles from 'bloko/blocks/form/form.less';

export enum FormLegendScale {
    Small = 'small',
    Large = 'large',
}

interface FormLegendProps {
    /** Кастомный компонент или ('div', 'span', 'label')*/
    Element?: 'div' | 'span' | 'label';
    /** Размер отступа baseline-deprecated */
    scale?: FormLegendScale;
}

const FormLegend: ComponentWithCustomElement<FormLegendProps, 'div'> = ({
    Element = 'div',
    children,
    scale,
    ...formLegendProps
}) => {
    return (
        <Element
            {...formLegendProps}
            className={classnames(styles['bloko-form-legend'], {
                [styles[`bloko-form-legend_for-${scale as FormLegendScale}`]]: scale,
            })}
        >
            {children}
        </Element>
    );
};

export default FormLegend;
