import Components from 'bloko/common/core/Components';

import modalErrorTemplate from 'bloko/blocks/modal/modalError.mustache';
/**
 * @param {Element} element DOM элемент
 *
 * @param {Object} params Параметры
 * @param {String} params.message Сообщение ошибки
 *
 * @constructor
 */
function ModalError(element, params) {
    let error;
    let errorNode;

    setMessage(params.message);

    /**
     * Установить новое сообщение
     * @param {message} Сообщение ошибки
     * @public
     */
    function setMessage(message) {
        error = modalErrorTemplate.render({ message });
        element.innerHTML = error;
        errorNode = element.querySelector('.Bloko-ModalError-Error');
    }

    /**
     * Показать ошибку
     * @public
     */
    function show() {
        errorNode.classList.remove('bloko-modal-error_hidden');
    }

    /**
     * Скрыть ошибку
     * @public
     */
    function hide() {
        errorNode.classList.add('bloko-modal-error_hidden');
    }

    return {
        show,
        hide,
        setMessage,
    };
}

export default Components.build({
    create: ModalError,
});
