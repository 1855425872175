import React, { ComponentPropsWithoutRef, MouseEventHandler, MouseEvent } from 'react';

import styles from 'bloko/blocks/externalLink/externalLink.less';

type AnchorElementProps = Omit<ComponentPropsWithoutRef<'a'>, 'href'> & {
    href: string;
};

const ExternalLink = (
    { children, onClick, target = '_blank', href, ...props }: AnchorElementProps,
    ref: React.Ref<HTMLButtonElement>
) => {
    const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
        onClick?.(event as unknown as MouseEvent<HTMLAnchorElement>);
        window.open(href, target, 'noopener');
    };

    return (
        <button
            className={styles['bloko-external-link']}
            {...(props as unknown as ComponentPropsWithoutRef<'button'>)}
            ref={ref}
            onClick={handleClick}
        >
            {children}
        </button>
    );
};

export default React.forwardRef(ExternalLink);
