import React, { MouseEventHandler, PureComponent, ReactElement, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';

import dropMenuItemStyles from 'bloko/blocks/drop/Menu/item.less';
import styles from 'bloko/blocks/tabs/tabs.less';

import { ComponentWithCustomElement } from '../../common/helpers/types';

export type TabIdType = string | number;
export type ActiveTabType = HTMLElement | null;

export interface TabProps {
    /** Флаг активности таба */
    active?: boolean;
    /** Активный таб по-умолчанию. Если передан - весь компонент tabs считается неуправляемым */
    defaultActive?: boolean;
    /** Идентификатор таба */
    id: TabIdType;
    /** Заголовок таба */
    children?: ReactNode;
    /** доп. текст по которому будет осуществляться поиск по табам (пользователю searchText не отображается) */
    searchText?: string;
    /** Кастомный компонент заголовка таба */
    Element: 'button' | 'a' | 'span';
    /** обработчик клика */
    onClick?: MouseEventHandler;
}

/**
 * Заголовок таба. Отдельно без обертки [Tabs](#tabs) не используется
 */
const Tab: ComponentWithCustomElement<TabProps, 'button'> = ({
    Element = 'button',
    children,
    active,
    defaultActive,
    id,
    searchText,
    onClick,
    ...props
}) => (
    <Element {...props} onClick={onClick} draggable={false}>
        {children}
    </Element>
);

export default Tab;

/**
 * Обертка-логика для табов в дропдауне
 */
export interface TabDropdownProps {
    /** Флаг активности таба */
    active?: boolean;
    /** обработчик клика */
    onClick?: MouseEventHandler;
    /** children */
    children: ReactElement;
}

export class TabDropdown extends PureComponent<TabDropdownProps> {
    private currentElement: ActiveTabType = null;

    componentDidMount(): void {
        // eslint-disable-next-line react/no-find-dom-node
        this.currentElement = ReactDOM.findDOMNode(this) as ActiveTabType;
    }

    onTabClick: MouseEventHandler = (event) => {
        this.props.onClick?.(event);
    };

    render(): ReturnType<typeof React.cloneElement> {
        const { children, active } = this.props;

        return React.cloneElement(children, {
            className: classnames(
                dropMenuItemStyles['bloko-drop-menu-item'],
                dropMenuItemStyles['bloko-drop-menu-item_selectable'],
                {
                    [dropMenuItemStyles['bloko-drop-menu-item_selected']]: this.props.active,
                }
            ),
            onClick: this.onTabClick,
            active,
        });
    }
}

/**
 * Обертка-логика для стандартных табов
 */
interface TabItemProps {
    /** Флаг активности таба */
    active?: boolean;
    /** обработчик клика */
    onClick?: MouseEventHandler;
    /** Колбэк для получения ссылки на DOM элемент */
    setElement?: (element: ActiveTabType) => void;
    /** children */
    children: ReactElement;
}

export class TabItem extends PureComponent<TabItemProps> {
    private currentElement: ActiveTabType = null;
    private preventScrollOnFocus = false;

    componentDidMount(): void {
        // eslint-disable-next-line react/no-find-dom-node
        this.currentElement = ReactDOM.findDOMNode(this) as ActiveTabType;
        this.setActiveTab();
    }

    setActiveTab = (): void => {
        if (this.props.active) {
            this.props.setElement?.(this.currentElement);
        }
    };

    onFocus = (): void => {
        if (!this.preventScrollOnFocus) {
            this.props.setElement?.(this.currentElement);
        }
        this.preventScrollOnFocus = false;
    };

    onMouseDown = (): void => {
        this.preventScrollOnFocus = true;
    };

    componentDidUpdate(prevProps: TabItemProps): void {
        if (this.props.active !== prevProps.active) {
            this.setActiveTab();
        }
    }

    onTabClick: MouseEventHandler = (event) => {
        this.props.onClick?.(event);
    };

    render(): ReturnType<typeof React.cloneElement> {
        const { children, active } = this.props;

        return React.cloneElement(children, {
            className: classnames(styles['bloko-tabs__item'], {
                [styles['bloko-tabs__item_active']]: active,
            }),
            onClick: this.onTabClick,
            onFocus: this.onFocus,
            onMouseDown: this.onMouseDown,
            tabIndex: 0,
            active,
        });
    }
}
