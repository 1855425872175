import { useState, useEffect, useCallback, useRef, useMemo } from 'react';

import Debug from 'bloko/common/core/Debug';
import ImmutableSelectionStrategy from 'bloko/common/tree/immutableSelectionStrategy';
import TreeCollection from 'bloko/common/tree/treeCollection';
import {
    extendExcludedFromParentsToChildren,
    filterTreeByIdsWithParents,
    getIdsWithNoParentsInSameList,
    removeExcludedFromSelected,
} from 'bloko/common/tree/treeCollectionHelper';
import { ModelData, AdditionalDefault } from 'bloko/common/tree/types';

/*
    Кастомный хук для обработки внутреннего массива выбранных элементов
    При первом рендере наполняет массив значениями из value
    Возвращает метод для записи в массив исключающий превышение лимита выбранных элементов
    Если в value значений больше лимита - выводит ошибку
*/

interface UseSelectedHookProps<A extends AdditionalDefault> {
    value: string[];
    excludedValue: string[];
    selectionStrategy: InstanceType<typeof ImmutableSelectionStrategy>;
    collection: TreeCollection<A>;
    maxItems?: number;
    setMaxCountError: (error: boolean) => void;
}

type UseSelectedHookReturn<A extends AdditionalDefault> = {
    selected: string[];
    excluded: string[];
    selectedTree: ModelData<A>[];
    setSelected: (items: string[], excluded: string[]) => void;
};

interface UseSelectedHook {
    <A extends AdditionalDefault>(props: UseSelectedHookProps<A>): UseSelectedHookReturn<A>;
}

const useSelected: UseSelectedHook = ({
    value,
    excludedValue,
    selectionStrategy,
    collection,
    maxItems,
    setMaxCountError,
}) => {
    const getSelectedFromValue = useCallback((): [string[], string[]] => {
        const isCollectionLoaded = !!collection.getTopLevel().length;
        if (selectionStrategy === undefined || !isCollectionLoaded) {
            return [[], []];
        }
        const ids = value.map((id) => String(id));
        if (maxItems && ids.length > maxItems) {
            Debug.log('out error', new Error('compositeSelection: Value items more than maxItems'));
            ids.length = maxItems;
        }

        return removeExcludedFromSelected(
            selectionStrategy.add([], ids),
            extendExcludedFromParentsToChildren(collection, ids, excludedValue)
        );
    }, [value, selectionStrategy, collection, maxItems, excludedValue]);

    const [values, setValues] = useState(getSelectedFromValue);
    const firstRender = useRef(true);

    const setSelected = useCallback(
        (items: string[], excluded: string[]) => {
            let itemsToSet = items;
            const valueItems = getIdsWithNoParentsInSameList(collection, items, excluded);
            setMaxCountError(false);
            if (maxItems && valueItems.length > maxItems) {
                valueItems.length = maxItems;
                itemsToSet = selectionStrategy.add([], valueItems);
                setMaxCountError(true);
            }

            setValues(
                removeExcludedFromSelected(
                    selectionStrategy.add([], valueItems),
                    extendExcludedFromParentsToChildren(collection, itemsToSet, excluded)
                )
            );
        },
        [collection, selectionStrategy, maxItems, setMaxCountError]
    );

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        const values = getSelectedFromValue();

        setSelected(values[0], values[1]);
    }, [getSelectedFromValue, setSelected, firstRender]);

    const selectedTree = useMemo(() => filterTreeByIdsWithParents(collection, values[0]), [collection, values]);

    return { selected: values[0], selectedTree, setSelected, excluded: values[1] };
};

export default useSelected;
