import memoize from 'memoize-one';

import escapeRegexp from 'bloko/common/escapeRegexp';

/**
 * Модуль для поиска одной строки в другой.
 * Учитывает раскладку клавиатуры и буквы Е/Ё.
 * Ищет совпадения только в начале слов.
 *
 * @exports bloko/common/fuzzySearch
 * @type {Object}
 */

const yoRegex = /[её]/g;
const spaceRegex = /\s+/g;

function trim(string: string) {
    return string.replace(spaceRegex, ' ').trim();
}

function prepareForRegExp(string: string) {
    return escapeRegexp(string).replace(yoRegex, '[её]');
}

const RUSSIAN = 'qwertyuiopasdfghjkl;\'zxcvbnm,QWERTYUIOP{}ASDFGHJKL:"ZXCVBNM<>`~[].'.split('');
const ENGLISH = 'йцукенгшщзфывапролджэячсмитьбЙЦУКЕНГШЩЗХЪФЫВАПРОЛДЖЭЯЧСМИТЬБЮёЁхъю'.split('');

function createHashMap(names: string[], values: string[]) {
    return names.reduce<Record<string, string>>((hashMap, item, index) => {
        hashMap[item] = values[index];
        return hashMap;
    }, {});
}

const LANG_MAP = {
    russian: createHashMap(RUSSIAN, ENGLISH),
    english: createHashMap(ENGLISH, RUSSIAN),
};

// 'москва city' => 'vjcrdf city'
// 'москва сity' => 'москва сшен'
function convert(substring: string, alphabet: Record<string, string>) {
    return substring
        .split('')
        .map((item) => alphabet[item] || item)
        .join('');
}

function getMultilanguageStrings(query: string) {
    return [
        query.toLowerCase(), // москва сity
        convert(query, LANG_MAP.russian).toLowerCase(), // "москва сшен"
        convert(query, LANG_MAP.english).toLowerCase(), // "vjcrdf city"
    ];
}

/**
 * Возвращает функцию, которая возвращает результат поиска `filterQuery`
 * в переданной строке. Нужно для оптимизации поиска одного и того же
 * фрагмента в большом наборе строк, чтобы не создавать и не хранить
 * много регэкспов.
 *
 * @param filterQuery {String} Что ищем.
 * @returns {Function}
 * @example
 *     const test = FuzzySearch.createMatcher('моск');
 *     test('Москва'); // true
 *     test('Подмосковье'); // false
 * @private
 */
const createMatcher = memoize((filterQuery: string) => {
    const filterQueryTrimmed = trim(filterQuery);
    if (filterQueryTrimmed.length) {
        const regexpStrings = getMultilanguageStrings(filterQueryTrimmed).map(prepareForRegExp);
        const re = new RegExp(`(^|[^a-zа-яё])(${regexpStrings.join('|')})`);
        return (searchString: string) => re.test(trim(searchString).toLowerCase());
    }
    return () => true;
});

/**
 * Возвращает результат поиска одной строки в другой.
 *
 * @param filterQuery {String} Что ищем.
 * @param searchString {String} Где ищем.
 * @returns {Boolean}
 * @example
 *     FuzzySearch.match('моск', 'Москва'); // true
 *     FuzzySearch.match('моск', 'Подмосковье'); // false
 */
function match(filterQuery: string, searchString: string): boolean {
    const test = createMatcher(filterQuery);
    return test(searchString);
}

export default { match };
