import { forwardRef, ReactNode } from 'react';
import classNames from 'classnames';

import { SpacingMultipliers, VSpacingBaseProps } from 'bloko/blocks/vSpacing';

import styles from 'bloko/blocks/vSpacing/vSpacingContainer.less';

interface VSpacingContainerProps extends VSpacingBaseProps {
    /** Элементы которые будут выведены с отступом */
    children: ReactNode;
}

const VSpacingContainer = forwardRef<HTMLDivElement, VSpacingContainerProps>(({ base, xs, children }, forwardRef) => {
    return (
        <div
            ref={forwardRef}
            className={classNames(
                styles['bloko-v-spacing-container'],
                styles[`bloko-v-spacing-container_base-${base}`],
                {
                    [styles[`bloko-v-spacing-container_xs-${xs as SpacingMultipliers}`]]: xs !== undefined,
                }
            )}
        >
            {children}
        </div>
    );
});

export default VSpacingContainer;
