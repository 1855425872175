import scrollTop from 'bloko/common/scrollTop';

import { Placement } from './metrics';

const DEFAULT_OPTIONS = {
    topOffset: 100,
    centered: true,
};

type ScrollToElementFunc = (
    /** Элемент */
    element: Element | null,
    /** Параметры */
    options?: {
        /** Вертикальный отступ */
        topOffset?: number;
        /** Центрировать ли экран на элементе */
        centered?: boolean;
        /** Скорость прокрутки */
        speed?: number;
        /** Колбек при начале анимации */
        animationStartCallback?: () => void;
        /** Колбек при достижении конечной точки */
        animationEndCallback?: () => void;
        placement?: Placement;
    }
) => void;

/** Функция плавной прокрутки к элементу */
const scrollToElement: ScrollToElementFunc = (element, options) => {
    if (!element) {
        return;
    }

    const params = { ...DEFAULT_OPTIONS, ...options };
    const elementTop = element.getBoundingClientRect().top - document.body.getBoundingClientRect().top;
    const top =
        elementTop -
        (params.centered ? window.innerHeight / 2 : 0) +
        (params.placement === Placement.Top ? -params.topOffset : params.topOffset);

    scrollTop({
        top,
        speed: params.speed,
        animationStartCallback: params.animationStartCallback,
        animationEndCallback: params.animationEndCallback,
    });
};

export default scrollToElement;
