import dropBase, { clickBaseFabric } from 'bloko/blocks/drop/classic';
import { getThemeClass } from 'bloko/blocks/drop/common';
import { IconColor } from 'bloko/blocks/icon';
import Components from 'bloko/common/core/Components';

import template from 'bloko/blocks/drop/Info/template.mustache';

import defaults, { BASE_CLASS_NAME, InfoPlacement, InfoTheme, BEHAVIOR, InfoLayer } from './constants';

const ICON_MAPPER = {
    [InfoTheme.Light]: IconColor.Gray80,
    [InfoTheme.Dark]: IconColor.White,
    [InfoTheme.Bright]: IconColor.White,
    [InfoTheme.Info]: IconColor.White,
    [InfoTheme.Neural]: IconColor.White,
};

/**
 * @exports bloko/blocks/drop/Info/info
 *
 * Всплывающий информационный блок
 *
 * @param {Element} element DOM-элемент, активатор. Info позиционируется относительно него.
 *
 * @param {Object} params параметры Info
 *
 * @param {String} [params.placement='bottomStart'] Предпочтительное положение, варианты  доступны в статическом свойстве [placements](../#/•%20Drop?id=Info-placements).
 * Если в указанном направлении недостаточно места для отображения, компонент будет показан в направлении, которое
 * больше подходит.
 * @param {String} [params.layer='aboveContent'] Тип слоя z-index-а, варианты доступны в статическом свойстве [layers](../#/•%20Drop?id=info-layers)
 * @param {String} [params.theme='dark']  Возможные темы доступны в статическом свойстве [themes](../#/•%20Drop?id=info-themes)
 * @param {String | Element} [params.host=document.body] DOM нода или селектор хоста в рамках которого
 * нужно рендерить info
 * @param {String} params.html cодержимое Info, поддерживает html разметку, может быть как разметкой, так и нодой.
 * @param {Function} [params.onClose] Колбек, вызывающийся при получении задачи на закрытие info
 * @param {Boolean} [params.showCloseButton=true] Показывает крестик
 * @param {Boolean} [params.closeByClickOutside=true] закрывать info при клике извне
 * @param {String} [params.dataQa='bloko-drop-info'] data-qa для контента
 * @param {Boolean} [params.flexible] Метка, использовать ли тянущийся info.
 * @constructor
 */
const Info = Components.build({
    defaults: {
        ...defaults,
        host: document.body,
        onShow: () => {},
    },
    create(
        element,
        { placement, layer, theme, html, host, closeByClickOutside, onClose, onShow, showCloseButton, dataQa, flexible }
    ) {
        return dropBase(element, {
            // params
            placement,
            layer,
            html,
            host,
            closeByClickOutside,
            onClose,
            onShow,
            dataQa,
            flexible,

            // specific
            baseClassNames: [BASE_CLASS_NAME, getThemeClass(theme)],
            behavior: BEHAVIOR,
            template,
            templateData: {
                showCloseButton,
                icon: ICON_MAPPER[theme],
            },
            selectors: {
                content: 'Bloko-Info-Content',
                component: 'Bloko-Info',
                arrow: 'Bloko-Info-Arrow',
            },

            // override
            onlySetPlacement: false,
        });
    },
});

export const Click = clickBaseFabric(Info);
export default Info;
export { InfoLayer, InfoPlacement, InfoTheme };
