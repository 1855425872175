import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const GiftScaleSmall = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g>
                <path
                    d="M8.00016 4.00001V14.6667M8.00016 4.00001H5.64302C5.29571 4.00001 4.96263 3.85953 4.71705 3.60949C4.47146 3.35944 4.3335 3.0203 4.3335 2.66668C4.3335 2.31305 4.47146 1.97392 4.71705 1.72387C4.96263 1.47382 5.29571 1.33334 5.64302 1.33334C7.47635 1.33334 8.00016 4.00001 8.00016 4.00001ZM8.00016 4.00001H10.3573C10.7046 4.00001 11.0377 3.85953 11.2833 3.60949C11.5289 3.35944 11.6668 3.0203 11.6668 2.66668C11.6668 2.31305 11.5289 1.97392 11.2833 1.72387C11.0377 1.47382 10.7046 1.33334 10.3573 1.33334C8.52397 1.33334 8.00016 4.00001 8.00016 4.00001ZM13.3335 7.33334V12.5333C13.3335 13.2801 13.3335 13.6534 13.1882 13.9387C13.0603 14.1895 12.8564 14.3935 12.6055 14.5214C12.3203 14.6667 11.9469 14.6667 11.2002 14.6667L4.80016 14.6667C4.05343 14.6667 3.68006 14.6667 3.39484 14.5214C3.14396 14.3935 2.93999 14.1895 2.81215 13.9387C2.66683 13.6534 2.66683 13.2801 2.66683 12.5333V7.33334M1.3335 5.06668L1.3335 6.26668C1.3335 6.64004 1.3335 6.82673 1.40616 6.96934C1.47007 7.09478 1.57206 7.19676 1.6975 7.26068C1.84011 7.33334 2.02679 7.33334 2.40016 7.33334L13.6002 7.33334C13.9735 7.33334 14.1602 7.33334 14.3028 7.26068C14.4283 7.19677 14.5303 7.09478 14.5942 6.96934C14.6668 6.82673 14.6668 6.64004 14.6668 6.26668V5.06668C14.6668 4.69331 14.6668 4.50662 14.5942 4.36402C14.5303 4.23858 14.4283 4.13659 14.3028 4.07267C14.1602 4.00001 13.9735 4.00001 13.6002 4.00001L2.40016 4.00001C2.02679 4.00001 1.84011 4.00001 1.6975 4.07267C1.57206 4.13659 1.47007 4.23857 1.40616 4.36402C1.3335 4.50662 1.3335 4.69331 1.3335 5.06668Z"
                    stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                    strokeWidth={1.33333}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    ),
    'Gift'
);
export default GiftScaleSmall;
