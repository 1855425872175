/* eslint-disable import/prefer-default-export */
export enum NotificationKind {
    Ok = 'ok',
    Error = 'error',
    Delete = 'delete',
    Message = 'message',
    Question = 'question',
    Rating = 'rating',
    Special = 'special',
}

export const NOTIFICATION_EXTRA_TYPES = [
    NotificationKind.Message,
    NotificationKind.Question,
    NotificationKind.Rating,
    NotificationKind.Special,
];
