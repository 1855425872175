export enum KeyCode {
    ArrowLeft = 37,
    ArrowUp = 38,
    ArrowRight = 39,
    ArrowDown = 40,
    Comma = 44,
    Period = 46,
    Enter = 13,
    ESC = 27,
    Tab = 9,
    Shift = 16,
    Space = 32,
    SpecialChar = 32,
    Delete = 46,
    BackSpace = 8,
}

// https://lists.w3.org/Archives/Public/www-dom/2010JulSep/att-0182/keyCode-spec.html#fixed-virtual-key-codes
// Для keydown для запятой - ',' (44) возвращается 188
// а для точки - '.' (46) возвращается 190
// для keypress возвращается коректный код, но его нельзя использовать
// т.к. он не работает в IEMobile https://jsfiddle.net/h6qb7mk1/2/
const VIRTUAL_CODES: Partial<Record<number, KeyCode>> = {
    188: KeyCode.Comma,
    190: KeyCode.Period,
};

/**
 * Утилита для работы с событиями клавиатуры
 *
 * @type {Object}
 * @exports bloko/common/constants/keyboard
 */
export default {
    /**
     * Получает значение символа нажатой клавиши
     *
     * @param {$.Event} event Событие сгенерированое нажатием клавиши
     *
     * @returns {String|Null} Символ или null в случае спецсимвола
     */
    getChar(event: KeyboardEvent): string | null {
        if (event.which) {
            if (event.which < KeyCode.SpecialChar) {
                return null;
            }

            return String.fromCharCode(VIRTUAL_CODES[event.which] || event.which);
        }

        return null;
    },
};
