import {
    isValidElement,
    Children,
    useState,
    useRef,
    useEffect,
    useMemo,
    ReactNode,
    RefObject,
    FC,
    ReactElement,
    PropsWithChildren,
} from 'react';
import classnames from 'classnames';

import { Menu } from 'bloko/blocks/drop';
import { getAdaptiveSettings, getActiveTabMarginLeft } from 'bloko/common/adaptiveTabsHelper';
import useResize from 'bloko/common/hooks/useResize';
import RequestAnimation from 'bloko/common/requestAnimation';
import throttle from 'bloko/common/throttle';

import useScroll from 'bloko/blocks/tabs/useScroll';

import styles from 'bloko/blocks/tabs/tabs.less';

import Tab, { TabItem, TabDropdown, TabIdType, ActiveTabType, TabProps } from './Tab';
import TabsDropdownController from './TabsDropdownController';

const RESIZE_THROTTLE_DELAY = 40;

interface CloneChildrenArgs {
    children: ReactNode;
    addProperties: {
        setElement?: (element: ActiveTabType) => void;
        inDropDown?: boolean;
    };
    setDropdownVisible: (isVisible: boolean) => void;
    onChange: (id: TabIdType) => void;
}

const isValidTabElement = (child: ReactNode): child is ReactElement<TabProps> =>
    isValidElement(child) && child.type === Tab;

const cloneChildren = ({ children, addProperties, setDropdownVisible, onChange }: CloneChildrenArgs) => {
    return Children.map(children, (tab) => {
        if (!isValidTabElement(tab)) {
            return undefined;
        }
        return addProperties.inDropDown ? (
            <TabDropdown
                {...tab.props}
                onClick={(event) => {
                    onChange(tab.props.id);
                    setDropdownVisible(false);
                    tab.props.onClick?.(event);
                }}
            >
                {tab}
            </TabDropdown>
        ) : (
            <TabItem
                {...tab.props}
                onClick={(event) => {
                    onChange(tab.props.id);
                    tab.props.onClick?.(event);
                }}
                {...addProperties}
            >
                {tab}
            </TabItem>
        );
    });
};

interface CombineTabsArgs {
    tabsWrapperRef: RefObject<HTMLElement>;
    tabsItemsRef: RefObject<HTMLElement>;
    setIsAdaptiveMode: (mode: boolean) => void;
    setDropdownVisible: (isVisible: boolean) => void;
    setShowedLeftGlare: (leftGlare: boolean) => void;
    setShowedRightGlare: (rightGlare: boolean) => void;
    tabsTrainRef: RefObject<HTMLElement>;
    activeTabElement?: ActiveTabType;
}

const combineTabs = ({
    tabsWrapperRef,
    tabsItemsRef,
    setIsAdaptiveMode,
    setDropdownVisible,
    setShowedLeftGlare,
    setShowedRightGlare,
    tabsTrainRef,
    activeTabElement,
}: CombineTabsArgs) => {
    if (!(tabsWrapperRef.current && tabsItemsRef.current && tabsTrainRef.current && activeTabElement)) {
        return;
    }

    const tabsMarginLeft = getActiveTabMarginLeft({
        tabsWrapperElement: tabsWrapperRef.current,
        tabsItemsElement: tabsItemsRef.current,
        activeTabElement,
    });

    const metrics = getAdaptiveSettings({
        tabsWrapperElement: tabsWrapperRef.current,
        tabsItemsElement: tabsItemsRef.current,
        tabsMarginLeft,
    });

    setIsAdaptiveMode(metrics.isAdaptiveMode);
    if (!metrics.isAdaptiveMode) {
        setDropdownVisible(false);
    }

    tabsTrainRef.current.style.marginLeft = `${metrics.tabsMarginLeft}px`;
    setShowedLeftGlare(metrics.showLeftGlare);
    setShowedRightGlare(metrics.showRightGlare);
};

/**
 * Хук для фильтрации переданных children элементов
 * @param children
 * @returns {React.ReactChild[]}
 */
const useFilteredChildren = (children: ReactNode) =>
    useMemo(() => Children.toArray(children).filter((element) => !!element), [children]);

/**
 * Компонент списка заголовков табов
 */
interface TabsProps {
    /** Колбэк, срабатывающий при изменении активного таба */
    onChange?: (id: TabIdType) => void;
    /** Параметры [Menu](#menu) */
    menuParams?: Partial<Parameters<typeof Menu>[0]>;
    /** Компонент заголовка табов [Tab](https://tech.hh.ru/bloko/?path=docs/components-tabs-react-tab--tab)*/
    children?: ReactNode;
    /** Скрывает нижний разделитель и левый отступ у первого элемента, уменьшает отступы между элементами */
    isNarrow?: boolean;
    /** Поиск по табам в дроп-меню */
    hasSearchSupport?: boolean;
    /** Плейсхолдер поиска */
    searchPlaceholder?: string;
    /** Сообщение когда поиск ничего не нашёл */
    emptySearchResultMessage?: string;
    /** Указывает на строку с компонентом в исходном коде в режиме разработки. Генерируется babel-plugin-react-source */
    source?: string;
}
const Tabs: FC<TabsProps & PropsWithChildren> = ({
    children: dirtyChildren,
    menuParams,
    onChange,
    isNarrow,
    source,
    hasSearchSupport,
    searchPlaceholder,
    emptySearchResultMessage,
    ...tabsProps
}) => {
    const tabsWrapperRef = useRef<HTMLDivElement>(null);
    const tabsItemsRef = useRef<HTMLDivElement>(null);
    const tabsTrainRef = useRef<HTMLDivElement>(null);

    const [activeTabElement, setActiveTabElement] = useState<ActiveTabType>();

    const [isAdaptiveMode, setIsAdaptiveMode] = useState(true);
    const [showedLeftGlare, setShowedLeftGlare] = useState(false);
    const [showedRightGlare, setShowedRightGlare] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [activeTabId, setActiveTabId] = useState<TabIdType>();

    const [tabsIsUncontrolled, setTabsIsUncontrolled] = useState<boolean>();
    const { isScrollOngoing, isScrollStarted, onScrollStart } = useScroll({
        tabsTrainRef,
        tabsWrapperRef,
        tabsItemsRef,
        setShowedLeftGlare,
        setShowedRightGlare,
    });

    const children = useFilteredChildren(dirtyChildren);

    const uncontrolledChildren = useMemo(() => {
        const result = Children.map(children, (tab) => {
            if (!isValidTabElement(tab)) {
                return undefined;
            }

            if (tab.props.active) {
                setTabsIsUncontrolled(false);
            } else if (tab.props.defaultActive) {
                setTabsIsUncontrolled(true);
            }

            const tabIsActive =
                activeTabId !== undefined ? tab.props.id === activeTabId : tab.props.defaultActive || tab.props.active;

            if (tabIsActive) {
                setActiveTabId(tab.props.id);
            }

            if (tabsIsUncontrolled && tab.props.active) {
                throw new Error('defaultActive is setted, Tabs is uncontrolled');
            } else if (tabsIsUncontrolled === false && tab.props.defaultActive) {
                throw new Error('active is setted, Tabs is controlled');
            }

            return <Tab {...tab.props} active={tabIsActive} />;
        });
        if (tabsIsUncontrolled) {
            return result;
        }
        return [];
    }, [activeTabId, children, tabsIsUncontrolled]);

    const onChangeWrapper = (id: TabIdType) => {
        if (tabsIsUncontrolled) {
            setActiveTabId(id);
        }
        onChange?.(id);
    };

    const tabsFromContainer = cloneChildren({
        children: tabsIsUncontrolled ? uncontrolledChildren : children,
        setDropdownVisible,
        onChange: onChangeWrapper,
        addProperties: { setElement: setActiveTabElement },
    });

    const tabsFromDropDown = cloneChildren({
        children: tabsIsUncontrolled ? uncontrolledChildren : children,
        setDropdownVisible,
        onChange: onChangeWrapper,
        addProperties: { inDropDown: true },
    });

    const combineTabsOnResize = useMemo(
        () =>
            throttle(
                RequestAnimation(() =>
                    combineTabs({
                        tabsWrapperRef,
                        tabsItemsRef,
                        setIsAdaptiveMode,
                        setDropdownVisible,
                        setShowedLeftGlare,
                        setShowedRightGlare,
                        tabsTrainRef,
                        activeTabElement,
                    })
                ),
                RESIZE_THROTTLE_DELAY
            ),
        [activeTabElement]
    );

    useResize(combineTabsOnResize);

    useEffect(() => {
        combineTabsOnResize();
    }, [combineTabsOnResize]);

    return (
        <div
            className={classnames(styles['bloko-tabs-wrapper'], {
                [styles['bloko-tabs-wrapper_narrow']]: isNarrow,
            })}
            {...tabsProps}
            ref={tabsWrapperRef}
            source={source}
        >
            <div
                className={classnames(styles['bloko-tabs'], {
                    [styles['bloko-tabs-scroll']]: isScrollStarted,
                    [styles['bloko-tabs-scroll_ongoing']]: isScrollOngoing,
                })}
                ref={tabsTrainRef}
            >
                <div
                    className={styles['bloko-tabs__items']}
                    ref={tabsItemsRef}
                    onTouchStart={onScrollStart}
                    onMouseDown={onScrollStart}
                >
                    {tabsFromContainer}
                </div>
                <div
                    className={classnames(styles['bloko-tabs-left-glare'], {
                        [styles['bloko-tabs-left-glare_showed']]: showedLeftGlare,
                    })}
                />
                <div
                    className={classnames(styles['bloko-tabs-right-glare'], {
                        [styles['bloko-tabs-right-glare_showed']]: showedRightGlare,
                    })}
                />
                {isAdaptiveMode && (
                    <TabsDropdownController
                        dropdownVisible={dropdownVisible}
                        setDropdownVisible={setDropdownVisible}
                        menuParams={menuParams}
                        hasSearchSupport={hasSearchSupport}
                        searchPlaceholder={searchPlaceholder}
                        emptySearchResultMessage={emptySearchResultMessage}
                    >
                        {tabsFromDropDown}
                    </TabsDropdownController>
                )}
            </div>
        </div>
    );
};

export default Tabs;

export { Tab };
