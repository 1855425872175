import { useEffect, useRef, FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

import { dataQa } from 'bloko/blocks/customSelect/constants';
import { LupaScaleSmallKindDefaultAppearanceOutlined } from 'bloko/blocks/icon';
import InputText, { InputChangeHandler } from 'bloko/blocks/inputText';
import { Breakpoint, getBreakpoint } from 'bloko/common/media';

import styles from 'bloko/blocks/customSelect/customSelect.less';

interface CustomSelectSearchFieldProps {
    placeholder?: string;
    value?: string;
    onChange?: InputChangeHandler;
    hasTitle?: boolean;
}

const CustomSelectSearchField: FC<CustomSelectSearchFieldProps & PropsWithChildren> = ({ hasTitle, ...props }) => {
    const searchInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (getBreakpoint() === Breakpoint.XS) {
            return;
        }
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, []);

    return (
        <div
            className={classnames(styles['bloko-custom-select__search'], {
                [styles['bloko-custom-select__search_with-title']]: hasTitle,
            })}
        >
            <InputText
                {...props}
                ref={searchInputRef}
                data-qa={dataQa.searchInput}
                icon={<LupaScaleSmallKindDefaultAppearanceOutlined />}
            />
        </div>
    );
};

export default CustomSelectSearchField;
