import React, { FC, ReactNode, useState, PropsWithChildren } from 'react';

import Button, { ButtonProps } from 'bloko/blocks/button';

import styles from 'bloko/blocks/button/button.less';

interface LabelComponentProps {
    /** Содержимое внутри строки*/
    children?: ReactNode;
    /** Уникальный индификатор Label */
    id: string;
}
const LabelComponent: FC<LabelComponentProps & PropsWithChildren> = ({ id, ...props }) => (
    <label htmlFor={id} {...props} />
);

interface ButtonCheckboxProps {
    /** Содержимое внутри строки*/
    children?: ReactNode;
    /** Обработчик onChange Value, в качестве аргумента передает event */
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    /** Атрибуты для checkbox, id обязательный*/
    inputAttributes: JSX.IntrinsicElements['input'] & { id: string };
}
const ButtonCheckbox: FC<ButtonCheckboxProps & Omit<ButtonProps, 'Element'> & PropsWithChildren> = ({
    onChange,
    inputAttributes,
    ...buttonCheckboxProps
}) => {
    const [checked, setChecked] = useState<boolean>(!!inputAttributes.checked);

    return (
        <>
            <input
                type="checkbox"
                checked={checked}
                onChange={(event) => {
                    setChecked(!checked);
                    onChange?.(event);
                }}
                {...inputAttributes}
                className={styles['bloko-button-check-helper']}
            />
            <Button Element={LabelComponent} id={inputAttributes.id} {...buttonCheckboxProps} />
        </>
    );
};

export default ButtonCheckbox;
