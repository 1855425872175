import { useState, FocusEvent, ChangeEvent } from 'react';

interface UseFocusInput {
    onFocus?: (e: FocusEvent<HTMLDivElement>) => void;
    onBlur?: (e: ChangeEvent<HTMLDivElement>) => void;
}

interface FocusInput {
    isFocused: boolean;
    setInputFocus: (event: FocusEvent<HTMLDivElement>) => void;
    setInputBlur: (event: ChangeEvent<HTMLDivElement>) => void;
}

const useFocusInput = ({ onFocus, onBlur }: UseFocusInput): FocusInput => {
    const [isFocused, setFocused] = useState(false);

    const setInputFocus = (event: FocusEvent<HTMLDivElement>) => {
        setFocused(true);
        onFocus?.(event);
    };

    const setInputBlur = (event: ChangeEvent<HTMLDivElement>) => {
        setFocused(false);
        onBlur?.(event);
    };

    return {
        isFocused,
        setInputFocus,
        setInputBlur,
    };
};

export default useFocusInput;
