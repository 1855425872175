import $ from 'jquery';

import Components from 'bloko/common/core/Components';
import Supports from 'bloko/common/supports';

import Tabs from 'bloko/blocks/tabs/adaptiveTabs';

function getRealHash(hash) {
    return typeof hash !== 'string' ? '' : hash;
}

/**
 * Срабатывает при инициализации табов
 *
 * @event Bloko-HashTabs-Init
 * @property {Number} index Индекс таба, который будет активирован
 */

/**
 * Срабатывает при смене таба
 *
 * @event Bloko-HashTabs-Changed
 * @property {Number} index Индекс активированного таба
 */

function HashTabs(element) {
    const $element = $(element);
    const $tabs = $('.Bloko-Tabs-Tab', element);
    const hashes = {};
    let activeTabIndex = 0;

    $tabs.each((index, tab) => {
        const tabHash = getRealHash(tab.hash);
        if (tabHash) {
            hashes[tabHash] = index;
            const windowHash = getRealHash(window.location.hash);
            if (tabHash === windowHash) {
                activeTabIndex = index;
            }
        }
    });

    const tabs = Components.make(Tabs, element, {
        activeTabIndex,
    }).tabs;

    function setActiveTabIndex(index) {
        if (index !== activeTabIndex) {
            activeTabIndex = index;
            tabs.setActiveTab(activeTabIndex);
            $element.trigger('Bloko-HashTabs-Changed', { index: activeTabIndex });
        }
    }

    element.addEventListener('Bloko-Tabs-Changed', ({ detail }) => {
        const tabHash = getRealHash($tabs.eq(detail.index).get(0).hash);
        // Когда хеш сбрасывается, в урле он остаётся пустым (только символ `#`),
        // и страница проматывается на самый верх. Чтобы избежать этого, меняем урл в истории.
        if (tabHash === '') {
            const activeTabHash = getRealHash($tabs.eq(activeTabIndex).get(0).hash);
            if (activeTabHash !== '') {
                if (Supports.historyApi()) {
                    window.history.pushState('', document.title, window.location.href.split('#')[0]);
                } else {
                    window.location.hash = '';
                }
            }
        } else {
            window.location.hash = tabHash;
        }
        setActiveTabIndex(detail.index);
    });

    $(window).on('hashchange', () => {
        let newTabIndex = activeTabIndex;
        const windowHash = getRealHash(window.location.hash);
        if (windowHash === '') {
            const activeTabHash = getRealHash($tabs.eq(activeTabIndex).get(0).hash);
            // Если хеш сбросили скриптом или через историю браузера, выбираем первый таб
            // (как при инициализации). Но если был выбран таб без хеша, менять его не нужно.
            if (activeTabHash !== '') {
                newTabIndex = 0;
            }
        } else if (windowHash in hashes) {
            newTabIndex = hashes[windowHash];
        }
        setActiveTabIndex(newTabIndex);
    });

    $element.trigger('Bloko-HashTabs-Init', { index: activeTabIndex });

    return {
        tabs,
    };
}

export default Components.build({
    create: HashTabs,
});
