import _ from 'underscore';

import { IconColor, BarsScaleSmall } from 'bloko/blocks/icon';
import IconReactRenderer from 'bloko/blocks/icon/IconReactRenderer';
import CollectionFlag from 'bloko/common/collection/collectionFlag';
import Components from 'bloko/common/core/Components';
import SelectionStrategy from 'bloko/common/tree/selectionStrategy';
import { fromTree } from 'bloko/common/tree/treeCollectionHelper';

import compositeSelectionTemplate from 'bloko/blocks/compositeSelection/compositeSelection.mustache';
import nestedTagListTemplate from 'bloko/blocks/compositeSelection/nestedTagList.mustache';
import NestedTagListProxy from 'bloko/blocks/compositeSelection/nestedTagListProxy';
import suggestTemplate from 'bloko/blocks/compositeSelection/suggest.mustache';
import SuggestProxy from 'bloko/blocks/compositeSelection/suggestProxy';
import tagListTemplate from 'bloko/blocks/compositeSelection/tagList.mustache';
import TagListProxy from 'bloko/blocks/compositeSelection/tagListProxy';
import treeSelectorPopupTemplate from 'bloko/blocks/compositeSelection/treeSelectorPopup.mustache';
import TreeSelectorPopupProxy from 'bloko/blocks/compositeSelection/treeSelectorPopupProxy';

/**
 * Параметры дочернего компонента `CompositeSelection`.
 * @typedef {Object} CompositeSelectionComponentParams
 * @property {TreeCollection} collection Дерево элементов
 * @property {CollectionFlag} selected Список выбранных элементов
 */

/**
 * @exports bloko/blocks/compositeSelection/compositeSelection
 *
 * @param {Element} element Элемент, на котором будет инициализирован компонент.
 * @param {Object} params Параметры компонента.
 * @param {Array} params.dataJSON Данные в древовидной структуре. [Формат данных](#/•%20CompositeSelection?id=format-).
 * @param {Boolean} [params.singleChoice=false] Если true, то можно выбрать только один элемент.<br/>
 * Принудительно установит в treeSelector <code>params.type = 'radio'</code>, если равен true.
 * @param {Boolean} [params.leavesOnly=false] Если true, то можно выбрать только элементы без потомков.<br/>
 * Переопределит одноименный параметр treeSelector.
 * @param {CollectionFlag} [params.selected] Набор выбранных элементов.
 * @param {Mustache} [params.template=compositeSelectionTemplate] Шаблон для отрисовки элементов
 * @param {Object} [params.templateData] Данные, используемые при рендера шаблона.
 * @param {Object} [params.tagListParams] Если указан, то инициализируется [tagList](classic/tagList.html)
 * с переданными параметрами. Если tagList нужен, а параметры для него передавать не надо,
 * то указать пустой объект <code>{}</code>.
 * @param {Object} [params.nestedTagListParams=undefined] Если указан, то инициализируется
 * [nestedTagList](classic/nestedTagList.html) с переданными параметрами. Если nestedTagList нужен, а параметры для него
 * передавать не надо, то указать пустой объект <code>{}</code>.
 * @param {Object} [params.treeSelectorPopupParams=undefined] Если указан, то инициализируется
 * [treeSelectorPopup](#/•%20TreeSelectorPopup?id=classic-treeselectorpopup) с переданными параметрами. Если treeSelectorPopup нужен, а параметры
 * для него передавать не надо, то указать пустой объект <code>{}</code>.
 * @param {Object} [params.suggestParams=undefined] Если указан, то инициализируется [suggest](classic/suggest.html)
 * с переданными параметрами. Если suggest нужен, а параметры для него передавать не надо,
 * то указать пустой объект <code>{}</code>.
 * @param {Object} [params.trl] Переводы.
 * @param {String} [params.trl.treeSelectorPopupOpener] Переводы кнопки открытия treeSelectorPopup.
 *
 * @constructor
 */
function CompositeSelection(element, params) {
    const instances = {};
    const template = params.template;
    const childComponents = [
        {
            name: 'suggest',
            component: SuggestProxy,
            params: {
                selfParams: params.suggestParams,
                singleChoice: params.singleChoice,
                leavesOnly: params.leavesOnly,
            },
        },
        {
            name: 'tagList',
            component: TagListProxy,
            params: {
                selfParams: params.tagListParams,
            },
        },
        {
            name: 'nestedTagList',
            component: NestedTagListProxy,
            params: {
                selfParams: params.nestedTagListParams,
            },
        },
        {
            name: 'treeSelectorPopup',
            component: TreeSelectorPopupProxy,
            params: {
                selfParams: params.treeSelectorPopupParams,
                singleChoice: params.singleChoice,
                leavesOnly: params.leavesOnly,
            },
        },
    ];

    const selectedIds = [];
    const collection = fromTree(params.dataJSON, (item) => {
        if (item.selected) {
            selectedIds.push(item.id);
        }
    });

    let selected = params.selected;
    if (!selected) {
        const selectionStrategy = new SelectionStrategy(collection, {
            singleChoice: params.singleChoice,
            leavesOnly: params.leavesOnly,
        });
        selected = new CollectionFlag(selectionStrategy);
    }
    selected.set(selectedIds);

    // Если нет отрисованной вёрстки, рендерим шаблон
    if (!element.querySelector('.Bloko-CompositeSelection')) {
        const components = childComponents.reduce((result, item) => {
            if (item.params.selfParams) {
                result[item.name] = true;
            }
            return result;
        }, {});

        element.innerHTML = template.render(
            {
                ...params.templateData,
                trl: params.trl,
                components,
            },
            {
                tagListTemplate: components.tagList ? tagListTemplate : null,
                nestedTagListTemplate: components.nestedTagList ? nestedTagListTemplate : null,
                treeSelectorPopupTemplate: components.treeSelectorPopup ? treeSelectorPopupTemplate : null,
                suggestTemplate: components.suggest ? suggestTemplate : null,
            }
        );

        if (components.suggest) {
            Components.make(IconReactRenderer, element.querySelector('.Bloko-CompositeSelection-SuggestIcon'), {
                IconComponent: BarsScaleSmall,
                iconProps: {
                    initial: IconColor.Gray50,
                    highlight: IconColor.Gray60,
                },
            });
        }
    }

    childComponents.forEach((item) => {
        if (item.params && item.params.selfParams) {
            const name = item.name;
            const childElement = element.querySelector(
                `.Bloko-CompositeSelection-${name.charAt(0).toUpperCase()}${name.slice(1)}`
            );
            if (childElement) {
                instances[name] = Components.make(
                    item.component,
                    childElement,
                    _.extend(item.params, {
                        collection,
                        selected,
                    })
                );
            }
        }
    });

    return {
        selected,
        collection,
        components: instances,
    };
}

export default Components.build({
    defaults: {
        singleChoice: false,
        leavesOnly: false,
        template: compositeSelectionTemplate,
    },
    create: CompositeSelection,
});
