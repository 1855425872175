import { Ref, memo } from 'react';

import { ButtonType } from 'bloko/blocks/button';
import { ComponentWithCustomElement } from 'bloko/common/helpers/types';

import styles from 'bloko/blocks/icon/icon.less';

export interface IconLinkProps {
    /** Задизейбленная иконка ссылка  */
    disabled?: boolean;
    /** Тип элемента для IconLink 'span', 'button', 'a', 'input' или компонент */
    Element?: 'span' | 'button' | 'a' | 'input';
    /** Функция для получения DOM элемента кнопки */
    innerRef?: Ref<HTMLElement>;
    /** Указывает на строку с компонентом в исходном коде в режиме разработки. Генерируется babel-plugin-react-source */
    source?: string;
}

const IconLink: ComponentWithCustomElement<IconLinkProps, 'button'> = ({
    Element = 'button',
    disabled = false,
    children,
    innerRef,
    source,
    ...iconLinkProps
}) => {
    const additionalAttributes: { type?: ButtonType; [x: string]: unknown } = {};

    if (Element === 'button' || Element === 'input') {
        additionalAttributes.type = ButtonType.Button;
    }

    if (innerRef) {
        /**
         * Element может быть простой функцией по этому напрямую
         * нельзя назначать ref
         * А может быть forwardRef, ClassComponent и простой тег,
         * по этому в обход типизации назначаем ref как unknown
         */
        additionalAttributes.ref = innerRef;
    }

    return (
        <Element
            {...additionalAttributes}
            {...iconLinkProps}
            className={styles['bloko-icon-link']}
            disabled={disabled}
            source={source}
        >
            {children}
        </Element>
    );
};

const MemoizedIconLink = memo(IconLink);

export default MemoizedIconLink;
