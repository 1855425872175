import React, { ChangeEvent, ChangeEventHandler, ReactNode, Ref, useCallback, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import { Merge } from 'type-fest';

import { IconColor, IconDynamic, IconLink, CrossScaleSmallEnclosedFalse } from 'bloko/blocks/icon';
import Loading from 'bloko/blocks/loading';
import useServerEnv from 'bloko/common/hooks/useServerEnv';

import styles from 'bloko/blocks/inputText/inputText.less';

export enum InputType {
    Text = 'text',
    Password = 'password',
    Number = 'number',
    Search = 'search',
    Date = 'date',
    Time = 'time',
    Month = 'month',
    Email = 'email',
    Tel = 'tel',
    Url = 'url',
}

export enum InputScale {
    Small = 'small',
    Normal = 'normal',
    Large = 'large',
}

export interface InputChangeHandlerProps {
    element: HTMLInputElement;
    event?: ChangeEvent<HTMLInputElement>;
}

export interface InputChangeHandler {
    (value: string, props: InputChangeHandlerProps): void;
}

export enum InputIconPosition {
    Left = 'left',
    Right = 'right',
}

export type InputProps = Merge<
    React.ComponentPropsWithoutRef<'input'>,
    {
        /** Тип контрола */
        type?: InputType;
        /** Дефолтное значение для uncontrolled-варианта */
        defaultValue?: string;
        /** Значение контрола для controlled-варианта */
        value?: string;
        /** Обработчик onChange */
        onChange?: InputChangeHandler;
        /** Значение placeholder контрола */
        placeholder?: string;
        /** Размеры контрола */
        scale?: InputScale;
        /** Ширина контрола. Допустимые значения от "2" до "40" */
        size?: number;
        /** Флаг капитализации текста */
        capitalize?: boolean;
        /** Флаг недоступности контрола */
        disabled?: boolean;
        /** Флаг невалидности контрола */
        invalid?: boolean;
        /** Указывает на строку с компонентом в исходном коде в режиме разработки. Генерируется babel-plugin-react-source */
        source?: string;
        /** Иконка */
        icon?: ReactNode;
        /** Положение иконки */
        iconPosition?: InputIconPosition;
        /** Текст префикса внутри инпута */
        prefix?: ReactNode;
        /** Текст постфикса внутри инпута */
        postfix?: ReactNode;
        /** Показывать кнопку очистки, когда инпут не пуст и нет иконки */
        showClearButton?: boolean;
        /** Показывать иконку загрузки */
        showLoading?: boolean;
        /** Флаг для отключения рамок у контрола */
        light?: boolean;
        /** Внутренний элемент справа, на который не накладывается текст инпута */
        innerRightElement?: ReactNode;
    }
>;

const updateForwardedRef = (ref: HTMLInputElement | null, forwardedRef: React.ForwardedRef<HTMLInputElement>) => {
    if (forwardedRef === null) {
        return;
    }

    if (typeof forwardedRef === 'function') {
        forwardedRef(ref);
    }

    if ('current' in forwardedRef) {
        forwardedRef.current = ref;
    }
};

const InputText = React.forwardRef<HTMLInputElement, InputProps>((props, forwardedRef) => {
    const {
        scale,
        size,
        capitalize,
        invalid,
        source,
        type = InputType.Text,
        defaultValue = '',
        value,
        onChange,
        icon = null,
        iconPosition = InputIconPosition.Right,
        prefix = null,
        postfix = null,
        showClearButton = false,
        showLoading = false,
        disabled = false,
        light = false,
        innerRightElement,
        ...inputProps
    } = props;

    const isControlled = typeof value !== 'undefined';
    const [internalValue, setInternalValue] = useState(defaultValue);
    const actualValue = isControlled ? value : internalValue;

    const changeHandler = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (event): void => {
            isControlled || setInternalValue(event.target.value);
            onChange?.(event.target.value, { element: event.target, event });
        },
        [isControlled, onChange]
    );

    const inputRef = useRef<HTMLInputElement>();
    const refFunc = useCallback(
        (ref: HTMLInputElement) => {
            inputRef.current = ref;
            updateForwardedRef(ref, forwardedRef);
        },
        [forwardedRef]
    );

    const [innerRightElementWidth, setInnerRightElementWidth] = useState(0);
    const innerRightElementRef = useCallback(
        (wrappedInnerRightElement: HTMLDivElement): Ref<HTMLDivElement> | undefined => {
            if (!wrappedInnerRightElement || !innerRightElement) {
                setInnerRightElementWidth(0);
                return;
            }
            setInnerRightElementWidth(wrappedInnerRightElement.offsetWidth);
        },
        [innerRightElement]
    );

    const isServerEnv = useServerEnv();
    const [prefixWidth, setPrefixWidth] = useState(0);
    const prefixRef = useCallback(
        (prefixElement: HTMLLabelElement): Ref<HTMLLabelElement> | undefined => {
            if (!prefixElement || !prefix) {
                setPrefixWidth(0);
                return;
            }
            setPrefixWidth(prefixElement.offsetWidth);
        },
        [prefix]
    );

    const clearInput = useCallback(() => {
        if (inputRef.current) {
            inputRef.current.focus();
            isControlled || setInternalValue('');
            onChange?.('', { element: inputRef.current });
        }
    }, [isControlled, onChange]);

    const iconClear = useMemo(
        () => (
            <IconDynamic>
                <IconLink Element="button" title="Clear" onClick={clearInput} data-qa="bloko-input-clear-icon">
                    <CrossScaleSmallEnclosedFalse initial={IconColor.Gray50} highlighted={IconColor.Gray60} />
                </IconLink>
            </IconDynamic>
        ),
        [clearInput]
    );

    const loadingIcon = showLoading ? <Loading /> : null;
    const clearIcon = showClearButton && actualValue.trim().length > 0 ? iconClear : null;
    const actualIcon = loadingIcon || icon || clearIcon;

    const wrappedInnerRightElement = !isServerEnv && innerRightElement && (
        <div ref={innerRightElementRef} className={classnames(styles['bloko-input-text__inner-wrapper'])}>
            {innerRightElement}
        </div>
    );

    const calculatedStyle = {
        paddingLeft: prefixWidth || undefined,
        paddingRight: innerRightElementWidth || undefined,
    };

    const onPrefixClick = useCallback(() => {
        if (inputRef.current) {
            inputRef.current.select();
        }
    }, []);

    return (
        <fieldset
            className={classnames(styles['bloko-input-text-wrapper'], {
                [styles['bloko-input-text-wrapper_icon-left']]: actualIcon && iconPosition === InputIconPosition.Left,
                [styles['bloko-input-text-wrapper_icon-right']]: actualIcon && iconPosition === InputIconPosition.Right,
                [styles['bloko-input-text-wrapper_sized']]: size,
            })}
            source={source}
            disabled={disabled}
        >
            {!isServerEnv && prefix && (
                <label
                    className={classnames(styles['bloko-input-text__prefix'], {
                        [styles[`bloko-input-text__prefix_${scale as InputScale}`]]: scale,
                        [styles['bloko-input-text__prefix_with-icon-left']]:
                            icon && iconPosition === InputIconPosition.Left,
                    })}
                    ref={prefixRef}
                    onClick={onPrefixClick}
                    data-qa="bloko-input-prefix"
                >
                    {prefix}
                </label>
            )}
            <input
                {...inputProps}
                ref={refFunc}
                type={type}
                size={size}
                style={calculatedStyle}
                suppressHydrationWarning
                className={classnames(styles['bloko-input-text'], {
                    [styles[`bloko-input-text_scale-${scale as InputScale}`]]: scale,
                    [styles['bloko-input-text_sized']]: size,
                    [styles['bloko-input-text_name']]: capitalize,
                    [styles['bloko-input-text_invalid']]: invalid,
                    [styles['bloko-input-text_light']]: light,
                    'focus-visible': inputRef.current?.classList.contains('focus-visible'),
                })}
                value={actualValue}
                onChange={changeHandler}
                disabled={disabled}
                source={source}
            />
            {!isServerEnv && actualValue && postfix && (
                <div
                    className={classnames(styles['bloko-input__postfix-container'], {
                        [styles[`bloko-input__postfix-container_with-icon-${iconPosition}`]]: !!icon,
                        [styles[`bloko-input__postfix-container_${scale as InputScale}`]]: scale,
                    })}
                    style={calculatedStyle}
                >
                    <div className={styles['bloko-input__postfix-text']}>
                        <span className={styles['bloko-input__value-ghost']}>{actualValue}</span>
                        {'\u00A0'}
                        {postfix}
                    </div>
                </div>
            )}
            {actualIcon || wrappedInnerRightElement}
        </fieldset>
    );
});

export default InputText;
