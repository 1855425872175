import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const ChartScaleSmallKindBar = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M4.6665 7.33337V12M7.99984 3.33337V12M11.3332 5.33337V12"
                stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                strokeWidth={1.33333}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ),
    'Chart'
);
export default ChartScaleSmallKindBar;
