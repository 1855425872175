import { FC, ReactElement, ReactNode, useCallback, PropsWithChildren } from 'react';

import DropBase from 'bloko/blocks/drop/Base';
import { IconLink, IconColor, CrossScaleMediumEnclosedFalse, CrossScaleSmallEnclosedFalse } from 'bloko/blocks/icon';
import { Breakpoint, getBreakpoint } from 'bloko/common/media';

import {
    BASE_CLASS_NAMES,
    PADDING_WRAPPER_CLASS_NAME,
    BEHAVIOR,
    defaultProps,
    DownPlacement,
    DownPlacementValue,
    DownLayer,
} from 'bloko/blocks/drop/Down/common';

import styles from 'bloko/blocks/drop/Down/down.less';

export interface DownProps {
    /** Предпочтительное положение down, доступны в статическом свойстве [placements](#down-placements). */
    placement?: DownPlacementValue;
    /** Выпадать только в заданном направлении, не обрабатывать другие кейсы */
    onlySetPlacement?: boolean;
    /** Class z-index-а, варианты доступны в статическом свойстве [layers](#down-layers) */
    layer?: DownLayer;
    /** DOM нода хоста в рамках которого нужно рендерить Down, по дефолту рендер будет в родителе компонента.*/
    host?: HTMLElement | null;
    /** Флаг отвечает за показ Down, `true` - показать, `false` - скрыть */
    show?: boolean;
    /** Элемент инициатор, относительно которого показывать компонент */
    children: ReactElement;
    /** Метод-рендер контента Down */
    render: () => ReactNode;
    /** dataQa='bloko-drop-down' Data-qa активного компонента */
    dataQa?: string;
    /** Колбек вызываемый при закрытии компонента. Срабатывает в случае клика вне компонента или клика на крестик */
    onClose?: () => void;
    /** Метка, использовать ли тянущийся down.
     * Тянущийся down будет растягивать по контенту, ограничиваясь только размерами экрана */
    flexible?: boolean;
    /** Метка, показывать ли крестик для закрытия */
    showCloseButton?: boolean;
    /** Заголовок для XS. Отображается если передан */
    title?: ReactNode;
}

const DropDown: FC<DownProps & PropsWithChildren> = (componentProps) => {
    const { render, showCloseButton, onClose, ...props } = {
        host: null,
        ...defaultProps,
        ...componentProps,
    };
    const renderFunc = useCallback(() => {
        if (!showCloseButton || props.title) {
            return render();
        }

        const isXs = getBreakpoint() === Breakpoint.XS;

        return (
            <div className={styles['bloko-drop__content-wrapper']}>
                <div className={styles['bloko-drop__content']}>{render()}</div>
                <div className={styles['bloko-drop__close']}>
                    <IconLink onClick={onClose} data-qa="bloko-drop-down-close-button">
                        {isXs ? (
                            <CrossScaleMediumEnclosedFalse initial={IconColor.Gray80} highlighted={IconColor.Gray60} />
                        ) : (
                            <CrossScaleSmallEnclosedFalse initial={IconColor.Gray80} highlighted={IconColor.Gray60} />
                        )}
                    </IconLink>
                </div>
            </div>
        );
    }, [showCloseButton, props.title, render, onClose]);

    return (
        <DropBase
            {...props}
            render={renderFunc}
            onClose={onClose}
            baseClassNames={BASE_CLASS_NAMES}
            paddingWrapperClassName={PADDING_WRAPPER_CLASS_NAME}
            behavior={BEHAVIOR}
            closeByClickOutside
        >
            {props.children}
        </DropBase>
    );
};

export default DropDown;
export { DownLayer, DownPlacement };
