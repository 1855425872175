import React, { useState, useCallback, useEffect, useRef, FC, ReactNode, PropsWithChildren } from 'react';

import { DropBaseProps } from './Base';

const useHoverToggler = (toggle: (arg: boolean) => void) => {
    const isTouchTap = useRef(true);

    const handleTouchStart = useCallback(() => {
        isTouchTap.current = true;
    }, []);

    const handleTouchCancel = useCallback(() => {
        isTouchTap.current = false;
    }, []);

    const handleTouchMove = useCallback(() => {
        isTouchTap.current = false;
    }, []);

    const handleTouchEnd = useCallback(() => {
        toggle(isTouchTap.current);
        isTouchTap.current = false;
    }, [toggle]);

    return {
        onMouseEnter: () => toggle(true),
        onMouseLeave: () => toggle(false),
        onTouchStart: handleTouchStart,
        onTouchCancel: handleTouchCancel,
        onTouchMove: handleTouchMove,
        onTouchEnd: handleTouchEnd,
    };
};

export interface HoverProps {
    onHover?: (show: boolean | null) => void;
    DropElement: React.ElementType;
    show?: boolean;
}

const Hover: FC<HoverProps & Partial<DropBaseProps> & PropsWithChildren> = ({
    children,
    onHover,
    DropElement,
    show,
    ...props
}) => {
    const [showDrop, toggleShow] = useState<boolean | null>(null);

    const toggle = useCallback((show: boolean) => {
        toggleShow(show);
    }, []);

    const onExternalClose = useCallback((show: boolean) => {
        toggleShow(show);
    }, []);

    const childrenProps = useHoverToggler(toggle);

    useEffect(() => {
        if (showDrop !== null) {
            onHover && onHover(showDrop);
        }
    }, [showDrop, onHover]);

    if (!children) {
        return null;
    }

    const wrappedChildren: ReactNode = typeof children === 'string' ? <span>{children}</span> : children;

    return (
        <DropElement {...props} onExternalClose={onExternalClose} show={show === false ? show : showDrop}>
            {React.isValidElement(wrappedChildren) && React.cloneElement(wrappedChildren, childrenProps)}
        </DropElement>
    );
};

export default Hover;
