import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const LivejournalScaleSmall = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.587 1L6.1067 2.5217C6.97311 2.14585 7.90758 1.95227 8.852 1.953C12.672 1.953 15.769 5.061 15.769 8.893C15.769 12.727 12.672 15.835 8.852 15.835C5.032 15.835 1.935 12.728 1.935 8.894C1.935 7.91183 2.15263 6.99156 2.52106 6.14416L8.1466 11.7837L8.146 11.7851L8.147 11.7841L8.1466 11.7837C8.84103 10.1681 10.1213 8.8753 11.73 8.16509L6.107 2.52509L6.104 2.52409C4.49544 3.23372 3.21552 4.52645 2.52192 6.14192L1 4.616C1.69476 3.00001 2.97666 1.70775 4.587 1ZM2.52192 6.14192L2.522 6.142L2.52106 6.14416L2.521 6.14409L2.52192 6.14192ZM10.051 12.1938C10.4548 11.2554 11.1985 10.5045 12.133 10.0918L12.678 12.7398L10.051 12.1938Z"
                fill="var(--bloko-icon-color, var(--bloko-icon-color-default))"
            />
        </svg>
    ),
    'Livejournal'
);
export default LivejournalScaleSmall;
