import { ComponentPropsWithoutRef, forwardRef, memo, useCallback, useState } from 'react';

import Tip, { TipTheme, TipPlacement } from 'bloko/blocks/drop/Tip';
import InputText, { InputChangeHandler, InputProps, InputType } from 'bloko/blocks/inputText';
import NumberValidator, { NumberValidatorError } from 'bloko/common/numberValidator';
import requestAnimation from 'bloko/common/requestAnimation';

const HIDE_TOOLTIP_TIMEOUT = 2000;

export interface NumericInputProps extends InputProps {
    /** Объект текстов ошибок */
    errors?: {
        /** Тест ошибки при превышении лимита чисел в дробной части */
        [NumberValidatorError.DecimalLength]?: string;
        /** Текст ошибки при вводе нечислового значения */
        [NumberValidatorError.NotNumber]?: string;
    };
    /** Количество символов в дробной части */
    decimalLength?: number;
    /** Символ разделителя целой и дробной части */
    decimalMark?: string;
    /** Символ разделителя групп */
    groupSeparator?: string;
    /** Разрешить отрицательные числа */
    allowNegative?: boolean;
    /** Обработчик изменений input (обязателен) */
    onChange: InputChangeHandler;
    /** props для компонента drop/Tip */
    tipProps?: Partial<ComponentPropsWithoutRef<typeof Tip>>;
    useParentNodeAsTooltipHost?: boolean;
}

const defaultErrors: Partial<Record<NumberValidatorError, string>> = {};

export const NumericInput = forwardRef<HTMLInputElement, NumericInputProps>(
    (
        {
            decimalLength = 2,
            decimalMark = ',',
            groupSeparator = '',
            allowNegative = false,
            errors = defaultErrors,
            useParentNodeAsTooltipHost,
            onChange,
            tipProps,
            source,
            ...inputProps
        },
        ref
    ) => {
        const [tipError, setTipError] = useState<NumberValidatorError | null>(null);
        const [errorTimer, setErrorTimer] = useState<ReturnType<typeof setTimeout> | null>(null);
        const setCaretPosition = useCallback((target: HTMLInputElement, caretPosition: number) => {
            target.selectionStart = caretPosition;
            target.selectionEnd = caretPosition;
        }, []);
        const setSelection = useCallback(
            (target: HTMLInputElement) => {
                requestAnimation(setCaretPosition)(target, target.selectionStart ? target.selectionStart - 1 : 0);
            },
            [setCaretPosition]
        );
        const errorToRender = useCallback(() => tipError && errors[tipError], [errors, tipError]);
        const clearErrorData = useCallback(() => {
            setTipError(null);
            setErrorTimer(null);
        }, []);
        const clearTipError = useCallback(() => {
            clearErrorData();
            errorTimer && clearTimeout(errorTimer);
        }, [errorTimer, clearErrorData]);
        const handleChange = useCallback<InputChangeHandler>(
            (value, props) => {
                const [error] = NumberValidator.validate(value, {
                    decimalLength,
                    decimalMark,
                    groupSeparator,
                    allowNegative,
                });

                if (!error) {
                    clearTipError();
                    onChange(value, props);

                    return;
                }

                setSelection(props.element);
                setTipError(error);

                if (errorTimer) {
                    clearTimeout(errorTimer);
                }

                setErrorTimer(setTimeout(clearErrorData, HIDE_TOOLTIP_TIMEOUT));
            },
            [
                decimalLength,
                decimalMark,
                groupSeparator,
                setSelection,
                errorTimer,
                clearErrorData,
                clearTipError,
                onChange,
                allowNegative,
            ]
        );

        return (
            <Tip
                show={!!tipError}
                theme={TipTheme.Attention}
                placement={TipPlacement.Top}
                render={errorToRender}
                onExternalClose={clearTipError}
                {...tipProps}
            >
                <InputText ref={ref} type={InputType.Text} onChange={handleChange} source={source} {...inputProps} />
            </Tip>
        );
    }
);

export default memo(NumericInput);
