import { createContext, RefObject } from 'react';

export const BOTTOM_SHEET_TOP_SPACE = 12;

export interface BottomSheetContextProps {
    isBottomSheet: boolean;
    modalContainerRef: RefObject<HTMLDivElement> | null;
}

export const BottomSheetContextDefaultValue = {
    isBottomSheet: false,
    modalContainerRef: null,
};

export const BottomSheetContext = createContext<BottomSheetContextProps>(BottomSheetContextDefaultValue);

const setMetrics = (metrics: { clientHeight: number; top: number }, modalElement: HTMLDivElement): void => {
    const clientHeight = window.innerHeight;
    metrics.clientHeight = clientHeight;
    const modalHeight = modalElement.scrollHeight;
    metrics.top = clientHeight - modalHeight;
};

const setModalTop = (modalElement: HTMLDivElement, topValue: string) => {
    if (modalElement) {
        modalElement.style.top = topValue;
    }
};

type AnimationHelper = (modalElement: HTMLDivElement | null) => void;

interface AnimationHelperFactory {
    beforeOpen: AnimationHelper;
    open: AnimationHelper;
    afterOpen: AnimationHelper;
    beforeClose: AnimationHelper;
    close: AnimationHelper;
    afterClose: AnimationHelper;
}

export const animationHelperFactory = (isBottomSheet: boolean): AnimationHelperFactory => {
    const metrics = { clientHeight: 0, top: 0 };
    return {
        beforeOpen: (modalElement) => {
            if (!modalElement || !isBottomSheet) {
                return;
            }
            setModalTop(modalElement, 'auto');
            setMetrics(metrics, modalElement);
            // прячем вниз, чтобы воспроизвести анимацию, как bottomSheet выезжает снизу
            setModalTop(modalElement, `${metrics.clientHeight}px`);
        },
        open: (modalElement) => {
            if (!modalElement || !isBottomSheet) {
                return;
            }
            setModalTop(modalElement, metrics.top > 0 ? `${metrics.top}px` : `${BOTTOM_SHEET_TOP_SPACE}px`);
        },
        afterOpen: (modalElement) => {
            if (!modalElement || !isBottomSheet) {
                return;
            }
            // после показа анимации ставим top: auto, чтобы высота модала менялась, если меняется высота контента
            setModalTop(modalElement, 'auto');
        },
        beforeClose: (modalElement) => {
            if (!modalElement || !isBottomSheet) {
                return;
            }
            setMetrics(metrics, modalElement);
            setModalTop(modalElement, metrics.top > 0 ? `${metrics.top}px` : `${BOTTOM_SHEET_TOP_SPACE}px`);
        },
        close: (modalElement) => {
            if (!modalElement || !isBottomSheet) {
                return;
            }
            setModalTop(modalElement, `${metrics.clientHeight}px`);
        },
        afterClose: (modalElement) => {
            if (!modalElement || !isBottomSheet) {
                return;
            }
            modalElement.style.removeProperty('top');
        },
    };
};
