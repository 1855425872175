export default (function () {
    if (typeof window === 'undefined' || (window.bloko && window.bloko.isTest)) {
        return false;
    }

    const fakeElement = document.createElement('fakeElement');

    const animations = {
        /* Первым идет WebkitAnimation, потому что в Android 4.x все проверки проходят */
        WebkitAnimation: 'webkitAnimationEnd',
        animation: 'animationend',
        OAnimation: 'oAnimationEnd',
        MsAnimation: 'msAnimationEnd',
        MozAnimation: 'animationend',
    };

    for (const animation of Object.keys(animations)) {
        if (fakeElement.style[animation as keyof CSSStyleDeclaration] !== undefined) {
            return animations[animation];
        }
    }

    return false;
})();
