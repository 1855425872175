/**
 * Проверяет является значение ключом объекта. Кроме того является тайпгардом позволяющим безопасно использовать
 * переменную для получения значения по ключу.
 * Если тип keyof T и объявленный тип переменной совпадают, то в ветке else тип переменной key будет never,
 * а присвоение never чему угодно не является ошибкой. Также стоит помнить, что функция на самом деле не гарантирует,
 * что значение key имеет тип keyof T. Её использование безопасно для получения значения по ключу, но не для
 * присваивания значения key переменной имеющей тип keyof T.
 *
 * @param obj {Object} объект
 * @param key {PropertyKey} ключ
 * @returns {Boolean}
 */
export default <T extends object>(obj: T, key: PropertyKey): key is keyof T => obj.hasOwnProperty(key);
