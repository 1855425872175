import { useState, useCallback, FC } from 'react';

import Calendar, { CalendarProps } from 'bloko/blocks/calendar/Base';
import { DateString, YearMonthDayString, ClickDate } from 'bloko/blocks/calendar/datesHelper';

export interface SingleCalendarPickerProps extends Omit<CalendarProps, 'highlighted' | 'onDateClick'> {
    /** Дата инициализации календаря в формате 'YYYY-MM-DD' или ISO */
    initialDate: DateString;
    /** Выделенная дата в формате 'YYYY-MM-DD' */
    highlighted?: YearMonthDayString;
    /** Обработчик клика по дате, который принимает аргумент строку вида 'YYYY-MM-DD' */
    onDateClick: (date?: YearMonthDayString) => void;
}

const SingleCalendarPicker: FC<SingleCalendarPickerProps> = ({ initialDate, highlighted, onDateClick, ...props }) => {
    const [currentHighlighted, setCurrentHighlightedDate] = useState(highlighted);

    const onDateClickHandler: ClickDate = useCallback(
        (selectedDate) => {
            if (currentHighlighted === selectedDate) {
                onDateClick();
                setCurrentHighlightedDate(undefined);
            } else {
                onDateClick(selectedDate);
                setCurrentHighlightedDate(selectedDate);
            }
        },
        [currentHighlighted, onDateClick]
    );

    return (
        <Calendar
            {...props}
            initialDate={initialDate}
            highlighted={currentHighlighted ? [currentHighlighted] : []}
            onDateClick={onDateClickHandler}
        />
    );
};

export default SingleCalendarPicker;
