import { useState, useMemo, useEffect, isValidElement, ReactElement } from 'react';

import { CustomSelectOptionProps } from 'bloko/blocks/customSelect/CustomSelectOption';

interface UseFocusedValueProps<T> {
    filteredOptions: ReactElement<CustomSelectOptionProps<T>>[];
}

interface FocusedValue<T> {
    focusedValue?: T;
    setFocusedValue: (value: T) => void;
    moveFocusedValue: (amount: number) => void;
    optionValues: T[];
}

const useFocusedValue = <T>({ filteredOptions }: UseFocusedValueProps<T>): FocusedValue<T> => {
    const optionValues = useMemo(
        () =>
            filteredOptions
                .filter((option) => isValidElement(option))
                .filter((option) => !option.props.disabled)
                .map((option) => option.props.value),
        [filteredOptions]
    );
    const [focusedValue, setFocusedValue] = useState(optionValues[0]);

    // Если опции были перефильтрованы
    // перемещаем фокус на первый элемент
    useEffect(() => {
        setFocusedValue(optionValues[0]);
    }, [optionValues]);

    // Упрощает управление фокусом с клавиатуры
    const moveFocusedValue = (amount: number) => {
        const currentIndex = focusedValue ? optionValues.indexOf(focusedValue) : -1;
        const maxIndex = optionValues.length - 1;
        const index = Math.max(Math.min(currentIndex + amount, maxIndex), 0);
        setFocusedValue(optionValues[index]);
    };

    return {
        focusedValue,
        setFocusedValue,
        moveFocusedValue,
        optionValues,
    };
};

export default useFocusedValue;
