import React from 'react';

const ICON_WIDTH_PX = 34;
const SCROLL_START_THRESHOLD = 10;

interface GetActiveTabMarginLeftArgs {
    tabsWrapperElement: HTMLElement;
    tabsItemsElement: HTMLElement;
    activeTabElement: HTMLElement;
}

export function getActiveTabMarginLeft({
    tabsWrapperElement,
    tabsItemsElement,
    activeTabElement,
}: GetActiveTabMarginLeftArgs): number {
    const containerWidth = tabsWrapperElement.getBoundingClientRect().width;
    const activeTabLeftAbsolute = activeTabElement.getBoundingClientRect().left;
    const activeTabWidth = activeTabElement.getBoundingClientRect().width;
    const activeTabLeft = activeTabLeftAbsolute - tabsItemsElement.getBoundingClientRect().left;

    return (containerWidth - activeTabWidth) / 2 - activeTabLeft;
}

interface GetAdaptiveSettingsArgs {
    tabsWrapperElement: HTMLElement;
    tabsItemsElement: HTMLElement;
    tabsMarginLeft: number;
}

interface AdaptiveSettings {
    isAdaptiveMode: boolean;
    tabsMarginLeft: number;
    showLeftGlare: boolean;
    showRightGlare: boolean;
}

export function getAdaptiveSettings({
    tabsWrapperElement,
    tabsItemsElement,
    tabsMarginLeft,
}: GetAdaptiveSettingsArgs): AdaptiveSettings {
    const containerWidth = tabsWrapperElement.getBoundingClientRect().width;
    const tabsWidth = tabsItemsElement.getBoundingClientRect().width;
    const maxTabsWidth = ICON_WIDTH_PX * 1.5 + tabsWidth;

    const isAdaptiveMode = maxTabsWidth > containerWidth;

    if (tabsMarginLeft > 0) {
        tabsMarginLeft = 0;
    }
    if (tabsMarginLeft + maxTabsWidth < containerWidth) {
        tabsMarginLeft = containerWidth - maxTabsWidth;
    }

    let showLeftGlare = tabsMarginLeft < 0;
    let showRightGlare = tabsMarginLeft + maxTabsWidth > containerWidth;

    if (!isAdaptiveMode) {
        tabsMarginLeft = 0;
        showLeftGlare = false;
        showRightGlare = false;
    }

    return {
        isAdaptiveMode,
        tabsMarginLeft,
        showLeftGlare,
        showRightGlare,
    };
}

export function isScrollExceedsThreshold(startX: number, event: TouchEvent | MouseEvent): boolean {
    const currentX = getClientX(event);
    return Math.abs(startX - currentX) > SCROLL_START_THRESHOLD;
}

const isTouchEvent = (event: UIEvent | React.UIEvent): event is TouchEvent | React.TouchEvent =>
    Boolean((event as TouchEvent).touches);

export function getClientX(event: TouchEvent | MouseEvent | React.TouchEvent | React.MouseEvent): number {
    if (isTouchEvent(event)) {
        return event.touches[0].clientX;
    }

    return event.clientX;
}
