const EQUATORIAL_EARTH_RADIUS = 6378.1;
const POLAR_EARTH_RADIUS = 6356.8;

function degreesToRadians(angleDegrees) {
    return angleDegrees * (Math.PI / 180);
}

function radiansToDegrees(angleRadians) {
    return angleRadians * (180 / Math.PI);
}

function getEarthRadius(lat) {
    return ((EQUATORIAL_EARTH_RADIUS - (EQUATORIAL_EARTH_RADIUS - POLAR_EARTH_RADIUS)) * Math.abs(lat)) / 90;
}

function getEquatorialSectionRadius(lat) {
    return getEarthRadius(lat) * Math.cos(degreesToRadians(lat));
}

/**
 * Возвращает массив из двух точек, задающих область карты земли в виде прямоугольника
 * описанного вокруг криволинейной окружности с радиусом distanceToBoundaryKm и центром в точке center.
 * @param {Array} center [lat, lng] центр окружности.
 * @param {Number} distanceToBoundaryKm радиус окружности в километрах.
 * @returns {Array} левая нижняя и правая верхняя точки прямоугольника.
 */
function getBoundPoints(center, distanceToBoundaryKm) {
    const centerLat = center[0];
    const centerLng = center[1];

    const deltaLat = radiansToDegrees(distanceToBoundaryKm / getEarthRadius(centerLat));
    const deltaLng = radiansToDegrees(distanceToBoundaryKm / getEquatorialSectionRadius(centerLat));

    const leftBottomPoint = [Math.max(centerLat - deltaLat, -90), Math.max(centerLng - deltaLng, -180)];
    const rightTopPoint = [Math.min(centerLat + deltaLat, 90), Math.min(centerLng + deltaLng, 180)];
    return [leftBottomPoint, rightTopPoint];
}

export default {
    getBoundPoints,
};
