/**
 * Ошибка: браузер не поддерживают необходимую функциональность.
 * @typedef {Error} TextSelectionError
 * @property {String} name `'TextSelectionError'`
 */
function TextSelectionError(message) {
    this.name = 'TextSelectionError';
    this.message = message;
}
TextSelectionError.prototype = Error.prototype;

/**
 * Ошибка: переданы не все обязательные аргументы.
 * @typedef {Error} TextSelectionArgumentsError
 * @property {String} name `'TextSelectionArgumentsError'`
 */
function TextSelectionArgumentsError(message) {
    this.name = 'TextSelectionArgumentsError';
    this.message = message;
}
TextSelectionArgumentsError.prototype = Error.prototype;

const throwError = function () {
    throw new TextSelectionError('Selection is not supported');
};
let getSelection = throwError;
let setSelection = throwError;
const document = typeof window !== 'undefined' && 'document' in window ? window.document : null;

if (document && 'selectionStart' in document.createElement('input')) {
    getSelection = function (element) {
        return {
            start: element.selectionStart,
            end: element.selectionEnd,
            direction: element.selectionDirection || 'none',
        };
    };

    setSelection = function (element, start, end, direction) {
        // Uncaught DOMException: Failed to execute 'setSelectionRange' on 'HTMLInputElement':
        // The input element's type ('number') does not support selection.
        if (element.tagName === 'INPUT' && element.type === 'number') {
            element.type = 'text';
            element.setSelectionRange(start, end, direction);
            element.type = 'number';
        } else {
            element.setSelectionRange(start, end, direction);
        }
    };
} else if (document && document.selection) {
    getSelection = function (element) {
        const range = document.selection.createRange();
        const textRange = element.createTextRange();
        const startRange = textRange.duplicate();

        textRange.moveToBookmark(range.getBookmark());
        startRange.setEndPoint('EndToStart', textRange);

        return {
            start: startRange.text.length,
            end: startRange.text.length + range.text.length,
            direction: 'none',
        };
    };

    setSelection = function (element, start, end) {
        const range = element.createTextRange();
        range.collapse(true);
        range.moveStart('character', start);
        range.moveEnd('character', end - start);
        range.select();
    };
}

/**
 * Утилита для работы с выделением текста.
 *
 * @type {Object}
 * @exports bloko/common/textSelection
 */
const selection = {
    /**
     * Возвращает информацию о выделенном тексте в переданом элементе.
     * @throws {TextSelectionError} Выкинет ошибку, если браузер не поддерживают необходимую функциональность.
     * @param {Element} element textarea или input
     * @returns {Object} Объект со свойствами start, end, direction
     */
    get(element) {
        return getSelection(element);
    },

    /**
     * Возвращает положение каретки в элементе.
     * @throws {TextSelectionError} Выкинет ошибку, если браузер не поддерживают необходимую функциональность.
     * @param {Element} element textarea или input
     * @returns {Number} Позиция каретки
     */
    getCaretPosition(element) {
        const selection = this.get(element);

        if (selection.direction === 'forward') {
            return selection.end;
        }

        return selection.start;
    },

    /**
     * Устанавливает селект на элементе.
     * @throws {TextSelectionError} Выкинет ошибку, если браузер не поддерживают необходимую функциональность.
     * @throws {TextSelectionArgumentsError} Выкинет ошибку, если переданы не все обязательные аргументы.
     *
     * @param {Element} element     textarea или input
     * @param {Number}  start       Позиция начала текста для выделения
     * @param {Number}  [end]       Позиция конца текста для выделения
     * @param {String}  [direction] Направление выделения текста
     */
    set(element, start, end, direction) {
        if (typeof start !== 'number') {
            throw new TextSelectionArgumentsError('First argument of textSelection.set() must be a number');
        }
        if (typeof end !== 'number') {
            end = start;
        }
        if (['forward', 'backward', 'none'].indexOf(direction) === -1) {
            direction = 'none';
        }

        element.focus();
        setSelection(element, start, end, direction);
    },

    /**
     * Устанавливает позицию каретки на элементе.
     * @throws {TextSelectionError} Выкинет ошибку, если браузер не поддерживают необходимую функциональность.
     * @throws {TextSelectionArgumentsError} Выкинет ошибку, если переданы не все обязательные аргументы.
     * @param {Element} element  textarea или input элемент
     * @param {Number}  position Позиция каретки
     */
    setCaretPosition(element, position) {
        this.set(element, position, position);
    },
};

export default selection;
