// eslint-disable-next-line @typescript-eslint/no-explicit-any
const requestAnimation = <T extends (...args: any[]) => void>(callback: T): T => {
    let animationRequested = false;

    return function (...args: Parameters<T>) {
        if (!animationRequested) {
            window.requestAnimationFrame(() => {
                animationRequested = false;
                callback(...args);
            });
        }
        animationRequested = true;
    } as T;
};

export default requestAnimation;
