/**
 * Создаёт и возвращает событие браузера `CustomEvent`
 *
 * @param {String} eventType тип события
 * @param {Object} [eventData] данные события (будут доступны в событии через поле detail)
 * @returns {CustomEvent} созданное событие
 *
 * @exports bloko/common/event
 * @function
 */
export default <T>(eventType: string, eventData?: T): CustomEvent<T> =>
    new CustomEvent(eventType, { bubbles: true, cancelable: true, detail: eventData });
