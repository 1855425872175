import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const ArrowsScaleSmallKindDefault = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g>
                <path
                    d="M3.3335 6.00001L1.3335 8.00001M1.3335 8.00001L3.3335 10M1.3335 8.00001H14.6668M6.00016 3.33334L8.00016 1.33334M8.00016 1.33334L10.0002 3.33334M8.00016 1.33334V14.6667M10.0002 12.6667L8.00016 14.6667M8.00016 14.6667L6.00016 12.6667M12.6668 6.00001L14.6668 8.00001M14.6668 8.00001L12.6668 10"
                    stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                    strokeWidth={1.33333}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    ),
    'Arrows'
);
export default ArrowsScaleSmallKindDefault;
