import { FC } from 'react';
import classnames from 'classnames';

import styles from 'bloko/blocks/badge/badge.less';

export enum BadgeAppearance {
    Primary = 'primary',
    Warning = 'warning',
    Default = 'default',
    Success = 'success',
    Tertiary = 'tertiary',
    Inversed = 'inversed',
}

interface BadgeProps {
    /** Значение счетчика */
    value: number;

    /** Внешний вид */
    appearance?: BadgeAppearance;

    /** Флаг скрытия счетчика */
    hidden?: boolean;
}

const getReadbleValue = (value: number): string => {
    value = Math.trunc(value);

    if (value >= 1000) {
        return `${Math.floor(value / 1000)}K`;
    } else if (value > 99) {
        return '99+';
    }

    return String(value);
};

const Badge: FC<BadgeProps> = ({ value, hidden, appearance = BadgeAppearance.Default }) => {
    return hidden ? null : (
        <span
            className={classnames(styles['bloko-badge'], {
                [styles[`bloko-badge_${appearance}`]]: appearance !== BadgeAppearance.Default,
            })}
        >
            {getReadbleValue(value)}
        </span>
    );
};

export default Badge;
