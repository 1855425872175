export default (
    maxLength: number | string,
    groupSeparator: string,
    groupSize: number,
    formatterDisabled: boolean
): number | undefined => {
    const maxLengthNumber = parseInt(`${maxLength}`, 10);
    if (!maxLengthNumber) {
        return undefined;
    }
    return formatterDisabled
        ? maxLengthNumber
        : Math.floor(maxLengthNumber + (maxLengthNumber - groupSeparator.length) / groupSize);
};
