const defaults = {
    groupSeparator: '\u2009',
    decimalMark: ',',
    decimalLength: 2,
    allowNegative: false,
};

export enum NumberValidatorError {
    NotNumber = 'notNumber',
    DecimalLength = 'decimalLength',
}

interface NumberValidator {
    validate: (
        text: string,
        passedParams: { decimalLength?: number; decimalMark?: string; groupSeparator?: string; allowNegative?: boolean }
    ) => NumberValidatorError[];
}

const numberValidator: NumberValidator = {
    /**
     * Валидирует переданный текст в соответствии с переданными параметрами
     *
     * @param {String}      text                            Текст для валидации
     * @param {Object}      passedParams                    Параметры.
     *                                                      Если параметры не переданы, то будет осуществлена
     *                                                      проверка только на то, что передано число
     * @param {Number|null} [passedParams.decimalLength=2]  Ограничение количества знаков после запятой.
     *                                                      `null` — без ограничения
     * @param {String}      [passedParams.decimalMark=","]  Разделитель дробной части
     * @param {Boolean}     [passedParams.allowNegative=false]  разрешить отрицательные числа
     */
    validate: function validate(text, passedParams) {
        const params = { ...defaults, ...passedParams };
        const errors: NumberValidatorError[] = [];
        if (text === '') {
            return errors;
        }
        const numberValidationRegex =
            params.decimalLength === 0
                ? /^[\d]*$/
                : new RegExp(`^${params.allowNegative ? '-?' : ''}\\d+${params.decimalMark}?\\d*$`);
        if (!numberValidationRegex.test(text.replace('\u2212', '-').split(params.groupSeparator).join(''))) {
            errors.push(NumberValidatorError.NotNumber);
        }
        if (params.decimalLength && (text.split(params.decimalMark)[1] || []).length > params.decimalLength) {
            errors.push(NumberValidatorError.DecimalLength);
        }
        return errors;
    },
};

export default numberValidator;
