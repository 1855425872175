import { IconColor, CrossScaleMediumEnclosedFalse, CrossScaleSmallEnclosedFalse } from 'bloko/blocks/icon';
import IconReactRenderer from 'bloko/blocks/icon/IconReactRenderer';
import Components from 'bloko/common/core/Components';
import requestAnimation from 'bloko/common/requestAnimation';

import { updatePosition, setInitialCSSMetrics, setupWidth } from './common';
import {
    FLEXIBLE_CLASS,
    FULLSCREEN_ON_XS_CLASS,
    ANIMATION_TIMEOUT_MS,
    ENTER_ANIMATION_MS,
    PLACEMENT_DOWN_SEQUENCE,
    makeDropLayerClass,
} from './constants';
import setClickable from './setClickable';

/**
 * @exports bloko/blocks/drop/base
 *
 * Всплывающий блок
 *
 * @param {Element} element DOM-елемент, активатор. Дроп-компонент позиционируется относительно него.
 *
 * @param {Object} params параметры компонента
 *
 * @param {String} params.placement Предпочтительное положение компонента
 * Если в указанном направлении недостаточно места для отображения, дроп-компонент будет показан в направлении, которое
 * больше подходит.
 * @param {String} params.layer Тип слоя z-index-а компонента, доступны в статическом свойстве
 * @param {String} params.theme  Возможные темы компонента
 * @param {String | Element} params.host DOM нода или селектор хоста в рамках которого
 * нужно рендерить компонент
 * @param {String} params.html cодержимое компонента, поддерживает html разметку, может быть как разметкой, так и нодой.
 * @param {Boolean} params.closeByClickOutside Определяет, должен ли дроп-элемент скрываться при клике извне
 * @param {Function} params.onClose Колбек, вызывающийся при получении задачи на закрытие дроп-компонента
 * @param {String} params.dataQa data-qa для компонента
 * @param {Boolean} params.flexible Метка, использовать ли тянущийся контент
 * @param {Boolean} params.onlySetPlacement флаг для выпадения в строго заданном направлении
 *
 * @param {Object} params.template mustache темплейт компонента
 * @param {Array} params.baseClassNames базовые классы для компонента
 * @param {Object} params.selectors селекторы для компонента
 * @param {Object} [params.templateData={}] дополнительные служебные данные для рендера компонентов
 * @param {String} params.title заголовок для отображения на XS
 *
 * @param {Boolean} [params.templateData.showCloseButton] Показывает крестик (если поддерживает основной компонент)
 * @param {String} [params.templateData.icon] стиль для иконки закрытия
 *
 * @param {Object} params.behavior поведение компонента
 *
 * @param {Boolean} params.behavior.showArrow — рисовать ли стрелочку
 * @param {Number} params.behavior.arrowSize — размер стрелочки
 * @param {Boolean} params.behavior.setupFullWidthOnXS — устанавливать ли ширину во весь экран на xs
 * @param {Number} params.behavior.placementOffset — выбор направления выпадашки
 * @param {Boolean} params.behavior.alignToActivatorBorders — выравнивать компонент по границам активатора
 * @param {Boolean} params.behavior.fullScreenOnXS — отображать меню во весь экран на XS
 *
 * @constructor
 */

const dropBase = function (
    element,
    {
        placement,
        layer,
        onlySetPlacement,
        html,
        host,
        closeByClickOutside,
        onClose,
        onShow,
        dataQa,
        flexible,
        template,
        baseClassNames,
        selectors,
        behavior,
        templateData = {},
        title,
    }
) {
    const hostNode = typeof host === 'string' ? document.querySelector(host) : host;
    let isVisible = false;
    let clickByOutside = false;
    let dropRef;
    let arrowRef;
    let contentRef;
    let currentElement = element;
    let currentPlacement = placement;

    const updateDropPosition = requestAnimation(() => {
        setInitialCSSMetrics(dropRef);

        if (behavior.setupFullWidthOnXS) {
            setupWidth(dropRef, hostNode);
        }
        if (!isVisible) {
            return;
        }

        let placementSequence = PLACEMENT_DOWN_SEQUENCE;

        if (onlySetPlacement) {
            placementSequence = [currentPlacement];
        }

        const position = updatePosition({
            behavior,
            placementSequence,
            activatorElement: currentElement,
            dropElement: dropRef,
            host: hostNode,
            placement: currentPlacement,
            classNames: [
                'bloko-drop',
                ...baseClassNames,
                selectors.component,
                'bloko-drop_active-enter',
                'bloko-drop_done-enter',
                makeDropLayerClass(layer),
                ...FLEXIBLE_CLASS.split(' '),
                ...FULLSCREEN_ON_XS_CLASS.split(' '),
            ],
            arrowSize: behavior.arrowSize,
            arrow: arrowRef,
            useDynamic: true,
        });

        currentPlacement = position.placement;
        setClickable(dropRef);
    });

    const resizeObserver =
        hostNode === document.body &&
        window?.ResizeObserver &&
        new window.ResizeObserver(() => {
            if (!isVisible) {
                return;
            }

            updateDropPosition();
        });

    const publicInterface = {
        show() {
            if (isVisible) {
                return;
            }

            isVisible = true;
            dropRef.classList.add('bloko-drop_active-enter');

            hostNode.appendChild(dropRef);
            updateDropPosition();
            if (resizeObserver) {
                resizeObserver.observe(hostNode);
            } else {
                window.addEventListener('resize', updateDropPosition);
            }

            setTimeout(() => {
                dropRef.classList.add('bloko-drop_done-enter');
                onShow();
            }, ANIMATION_TIMEOUT_MS);

            setTimeout(() => {
                dropRef.classList.remove('bloko-drop_done-enter');
                dropRef.classList.remove('bloko-drop_active-enter');
            }, ANIMATION_TIMEOUT_MS + ENTER_ANIMATION_MS);
        },

        getContent() {
            return contentRef;
        },

        hide() {
            if (isVisible) {
                onClose();
                hostNode.removeChild(dropRef);
                isVisible = false;

                !resizeObserver && window.removeEventListener('resize', updateDropPosition);
                resizeObserver && resizeObserver.unobserve(hostNode);
            }
        },

        /**
         * Изменяет состояние выпадашки (открывает, если скрыт, и наоборот)
         *
         * @param {Boolean} state
         */
        toggle(state) {
            if (arguments.length === 0 ? !isVisible : state) {
                publicInterface.show();
            } else {
                publicInterface.hide();
            }
        },

        /**
         * Позволяет менять элемент, относительного которого выпадает дроп
         *
         @param {Element} element Элемент, относительно которого выпадает дроп
         @param {String} [preferPlacement] Предпочтительное положение компонента
         */
        setPosition(element, preferPlacement = placement) {
            publicInterface.hide();
            currentElement = element;
            currentPlacement = preferPlacement;
        },
    };

    function close() {
        publicInterface.hide();
        clickByOutside = true;
    }

    function callOnClose(event) {
        if (!isVisible) {
            clickByOutside = true;
            return;
        }

        // почему-то иногда в качестве event.target приходит window.document или undefined, у которого нет closest
        if (!event.target.closest) {
            if (closeByClickOutside && clickByOutside) {
                close();
            }
            clickByOutside = true;
            return;
        }

        if (closeByClickOutside && clickByOutside && event.target.closest(`.${selectors.component}`) === null) {
            close();
        }

        if (event.target.closest('.Bloko-Drop-Close')) {
            close();
        }

        clickByOutside = true;
    }

    const infoHtml = template.render({
        layer: makeDropLayerClass(layer),
        cssClasses: baseClassNames.join(' '),
        templateData,
        flexible: flexible ? FLEXIBLE_CLASS : '',
        dataQa,
        title,
    });

    const infoWrapNode = document.createElement('div');
    infoWrapNode.innerHTML = infoHtml;
    dropRef = infoWrapNode.firstChild;

    if (templateData.showCloseButton) {
        Components.make(IconReactRenderer, infoWrapNode.querySelector('.Bloko-Drop-BodyIconClose'), {
            IconComponent: CrossScaleSmallEnclosedFalse,
            iconProps: {
                initial: templateData.icon ?? IconColor.Gray80,
                highlighted: IconColor.Gray60,
            },
        });
    }

    const titleClose = infoWrapNode.querySelector('.Bloko-Drop-TitleIconClose');

    if (titleClose) {
        Components.make(IconReactRenderer, titleClose, {
            IconComponent: CrossScaleMediumEnclosedFalse,
            iconProps: {
                initial: templateData.icon ?? IconColor.Gray80,
                highlighted: IconColor.Gray60,
            },
        });
    }

    contentRef = selectors.content ? dropRef.querySelector(`.${selectors.content}`) : dropRef;

    if (selectors.arrow) {
        arrowRef = dropRef.querySelector(`.${selectors.arrow}`);
    }

    if (typeof html === 'string') {
        contentRef.innerHTML = html;
    } else if (html) {
        contentRef.appendChild(html);
    }

    window.addEventListener('click', callOnClose);

    if (closeByClickOutside) {
        currentElement.addEventListener('click', () => {
            clickByOutside = false;
        });
    }

    return publicInterface;
};

function clickBaseFabric(component) {
    return Components.build({
        create(element, params) {
            const instance = Components.make(component, element, params);
            element.addEventListener('click', () => instance.toggle());

            return instance;
        },
    });
}

export { clickBaseFabric };

export default dropBase;
