import { useState, useRef, useCallback, useEffect, RefObject } from 'react';

import { CustomSelectValue } from 'bloko/blocks/customSelect';

interface UseToggleExpandedProps<T extends CustomSelectValue> {
    expanded?: boolean;
    disabled?: boolean;
    optionValues: T[];
    setFocusedValue: (value: T) => void;
    value?: T;
    onOpen?: () => void;
    onClose?: () => void;
    selectInputRef: RefObject<HTMLDivElement>;
}

interface UseToggleExpanded {
    isExpanded: boolean;
    toggleExpanded: (value?: boolean) => void;
}

const useToggleExpanded = <T extends CustomSelectValue>({
    expanded,
    disabled,
    optionValues,
    setFocusedValue,
    value,
    onOpen,
    onClose,
    selectInputRef,
}: UseToggleExpandedProps<T>): UseToggleExpanded => {
    const [isExpanded, setExpanded] = useState(false);
    const prevExpanded = useRef(false);

    const toggleExpanded = useCallback(
        (expand = !isExpanded) => {
            if (expand) {
                if (disabled) {
                    return;
                }
                setExpanded(true);
                setFocusedValue(value && optionValues.includes(value) ? value : optionValues[0]);
                onOpen?.();
                // На мобильных устройствах первый клик снимает фокус
                // с элемента в фокусе (в данном случае - сам инпут селекта)
                // и клик по опции не срабатывает. Снимаем фокус руками
                // при открытии выпадающего списка
                selectInputRef?.current?.blur();
            } else {
                setExpanded(false);
                onClose?.();
                // Аналогично возвращаем фокус при закрытии списка
                selectInputRef?.current?.focus();
            }
        },
        [isExpanded, disabled, setFocusedValue, optionValues, value, onOpen, selectInputRef, onClose]
    );

    useEffect(() => {
        if (typeof expanded === 'boolean' && prevExpanded.current !== expanded) {
            toggleExpanded(expanded);
            prevExpanded.current = expanded;
        }
    }, [expanded, toggleExpanded]);

    return { isExpanded, toggleExpanded };
};

export default useToggleExpanded;
