import RateLimitedAction from './helpers/rateLimitedAction';

/**
 * Утилитарный модуль, который вызвает переданную функцию не чащее, чем раз в wait ms.
 *
 * @param func функция вызов которой, нужно отложить
 * @param waitMS количество ms, на которое нужно отложить вызов функции
 */
const debounce: RateLimitedAction = (func, waitMS) => {
    let timeout: null | ReturnType<typeof setTimeout>;

    const debounced = function (this: unknown, ...args: Parameters<typeof func>) {
        if (timeout) {
            clearTimeout(timeout);
        }

        timeout = setTimeout(() => {
            timeout = null;
            func.apply(this, args);
        }, waitMS);
    };

    /**
     * Отмена выполнения вызова
     */
    debounced.cancel = () => {
        timeout && clearTimeout(timeout);
        timeout = null;
    };

    return debounced;
};

export default debounce;
