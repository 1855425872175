import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const HeartScaleSmallAppearanceFilled = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.99547 3.42388C6.66257 1.8656 4.43987 1.44643 2.76984 2.87334C1.0998 4.30026 0.864686 6.68598 2.17617 8.3736C3.26659 9.77674 6.56656 12.7361 7.64811 13.6939C7.76911 13.801 7.82961 13.8546 7.90018 13.8757C7.96178 13.8941 8.02917 13.8941 8.09077 13.8757C8.16134 13.8546 8.22184 13.801 8.34284 13.6939C9.42439 12.7361 12.7244 9.77674 13.8148 8.3736C15.1263 6.68598 14.9199 4.28525 13.2211 2.87334C11.5224 1.46144 9.32838 1.8656 7.99547 3.42388Z"
                fill="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                strokeWidth={1.33333}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ),
    'Heart'
);
export default HeartScaleSmallAppearanceFilled;
