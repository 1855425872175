import { ReactNode } from 'react';
import classnames from 'classnames';

import { ComponentWithCustomElement } from 'bloko/common/helpers/types';

import styles from 'bloko/blocks/share/share.less';

interface ShareProps {
    /** Иконка */
    icon: ReactNode;
    /** Число на счетчике */
    count?: number;
    /** Флаг инверсии цвета счетчика */
    inverted?: boolean;
    /** Тип элемента: 'a', 'span', 'button', 'input', 'div' */
    Element?: 'a' | 'span' | 'button' | 'input' | 'div';
}

/**
 * Кнопки шаринга в социальных сетях и мессенджерах.
 */
const Share: ComponentWithCustomElement<ShareProps, 'a'> = ({
    Element = 'a',
    icon,
    count,
    inverted,
    ...shareProps
}) => {
    return (
        <Element {...shareProps} className={styles['bloko-share']}>
            <span className={styles['bloko-share__content']}>
                {icon}
                {count !== undefined && (
                    <span
                        className={classnames(styles['bloko-share__counter'], {
                            [styles['bloko-share__counter_inverted']]: inverted,
                        })}
                    >
                        {count}
                    </span>
                )}
            </span>
        </Element>
    );
};

export default Share;
