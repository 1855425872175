import $ from 'jquery';

import Tip, { TipTheme, TipPlacement } from 'bloko/blocks/drop/Tip/tip';
import Keyboard from 'bloko/common/constants/keyboard';
import Components from 'bloko/common/core/Components';
import NumberFormatter from 'bloko/common/numberFormatter';
import NumberValidator from 'bloko/common/numberValidator';
import Supports from 'bloko/common/supports';
import TextSelection from 'bloko/common/textSelection';

import calculateDelimiters from 'bloko/blocks/numericInput/calculateDelimiters';

const HIDE_TOOLTIP_TIMEOUT = 2000;

/**
 * Компонент, который позволяет вводить в инпут только цифры, при вводе неверного символа
 * показывает тултип с ошибкой
 *
 * @param {HTMLElement} element                     Инпут на котором инициализируется компонент
 * @param {Object} params                           Параметры
 * @param {Object} [params.trls]                    Объект содержащий тексты для различных ошибок, которые могут
 *                                                  быть показаны в [dropTip](classic/dropTip.html)
 * @param {String} [params.trls.notNumber]          Сообщение если введено не число
 * @param {String} [params.trls.decimalLength]      Сообщение если превышено кол-во знаков после запятой
 * @param {Number} [params.decimalLength=2]         Количество знаков в дробной части
 * @param {String} [params.decimalMark=',']         Разделитель целой и дробной части
 * @param {Boolean} [params.allowNegative=false]      Разрешить отрицательные числа
 * @param {Boolean} [params.useParentNodeAsTooltipHost=false] Флаг указывает должен ли хоститься тултип в текущем
 * контейнере или в body
 *
 * @constructor
 */

function numericInput(element, params) {
    // При использовании с input[type=number] появляются трудноуловимые баги, например, можно ввести в инпут
    // два минуса (только два) и «e», при этом в value будет пусто, и проверки в компоненте не сработают.
    if (element.tagName === 'INPUT' && element.type === 'number') {
        throw new Error("NumericInput can't work with input[type=number]. Use inputmode=decimal|numeric instead.");
    }
    const $element = $(element);
    let timeout;
    const validSymbol = new RegExp(
        `${params.decimalLength === 0 ? '' : `\\${params.allowNegative ? '-|' : ''}${params.decimalMark}|`}\\d`
    );
    const tooltip = Components.make(Tip, element, {
        theme: TipTheme.Attention,
        placement: TipPlacement.Top,
        host: params.useParentNodeAsTooltipHost ? element.parentNode : 'body',
    });

    function showTooltip(type) {
        if (params.trls[type] === undefined) {
            return;
        }

        tooltip
            .changeOptions({
                html: params.trls[type],
            })
            .show();

        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = window.setTimeout(() => {
            tooltip.hide();
            timeout = null;
        }, HIDE_TOOLTIP_TIMEOUT);
    }

    function checkKey(event) {
        if (event.altKey || event.ctrlKey || event.metaKey) {
            return;
        }

        const char = Keyboard.getChar(event);
        if (char === null) {
            return;
        }

        if (!validSymbol.test(char)) {
            event.preventDefault();
            showTooltip('notNumber');
            return;
        }

        const position = TextSelection.getCaretPosition(element);
        const rawText = element.value;
        const fullText = (rawText.substr(0, position) + char + rawText.substr(position)).replace(
            params.groupSeparator,
            ''
        );
        const errors = NumberValidator.validate(fullText, params);

        if (errors.length > 0) {
            event.preventDefault();
            showTooltip(errors[0]);
        } else if (tooltip) {
            tooltip.hide();
        }
    }

    function formatInput(doNotSetCaret) {
        const rawText = element.value;
        let formatted = NumberFormatter.format(rawText, params);

        if (rawText === formatted) {
            return;
        }
        const lastChar = rawText.charAt(rawText.length - 1);
        const decimalMarkCount = formatted.split(params.decimalMark).length - 1;
        if (params.decimalLength !== 0 && decimalMarkCount === 0 && lastChar === params.decimalMark) {
            formatted += lastChar;
        }
        if (doNotSetCaret) {
            $element.val(formatted);
        } else {
            const caretPosition = TextSelection.getCaretPosition(element);
            $element.val(formatted);
            const delimitersToAdd = calculateDelimiters(rawText, formatted, params.groupSeparator);
            TextSelection.setCaretPosition(element, caretPosition + delimitersToAdd);
        }
    }

    formatInput(true);

    // Андроиды не могут нормально возвращать код клавиши, и возвращают 229
    // приходится изменять инпут после ввода в него символа
    if (Supports.android()) {
        let previousValue = '';
        $element.on('change input', () => {
            const text = element.value;
            const errors = NumberValidator.validate(text, params);

            if (errors.length === 0) {
                previousValue = text;
                if (tooltip) {
                    tooltip.hide();
                }
                formatInput(false);
            } else {
                element.value = previousValue;
                showTooltip(errors[0]);
            }
        });
    } else {
        $element.on(Supports.IEMobile() ? 'keydown' : 'keypress', checkKey).on('input change', () => {
            formatInput(false);
        });
    }
}

export default Components.build({
    defaults: {
        groupSeparator: '',
        decimalMark: ',',
        decimalLength: 2,
        allowNegative: false,
        trls: {},
        useParentNodeAsTooltipHost: false,
    },
    create: numericInput,
});
