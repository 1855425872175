(function () {
    const supports = {
        ios: /(ipad|ipod|iphone)/i.test(navigator.userAgent.toLowerCase()),
    };

    const supportClassNames = [];

    if (supports.ios) {
        supportClassNames.push('ios');
    }

    if (supportClassNames.length) {
        document.getElementsByTagName('html')[0].className += ` ${supportClassNames.join(' ')}`;
    }
})();
