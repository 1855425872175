import { PLACEMENT_DOWN_SEQUENCE, DropPlacement, DISTANCE, DropLayer } from 'bloko/blocks/drop/constants';
import { ValueOf } from 'bloko/common/types';

import styles from 'bloko/blocks/drop/Info/info.less';

export const ANIMATION_TIMEOUT_MS = 32;
export const ARROW_SIZE = 5;

export const PLACEMENT_INFO_SEQUENCE = PLACEMENT_DOWN_SEQUENCE;

export const InfoPlacement = DropPlacement;
export type InfoPlacementValue = ValueOf<typeof InfoPlacement>;

export { DropLayer as InfoLayer };

export enum InfoTheme {
    Light = 'light',
    Dark = 'dark',
    Bright = 'bright',
    Info = 'info',
    Neural = 'neural',
}

export default {
    theme: InfoTheme.Dark,
    layer: DropLayer.AboveContent,
    placement: InfoPlacement.BottomStart,
    showCloseButton: true,
    closeByClickOutside: true,
    dataQa: 'bloko-drop-info',
    show: false,
};

export const BASE_CLASS_NAME = styles['bloko-drop_info'];
export const PADDING_WRAPPER_CLASS_NAME = styles['bloko-drop__padding-wrapper_info'];

export const BEHAVIOR = {
    showArrow: true,
    arrowSize: ARROW_SIZE,
    setupFullWidthOnXS: false,
    placementOffset: DISTANCE + ARROW_SIZE,
    alignToActivatorBorders: false,
    fullScreenOnXS: false,
};
