import { useCallback, useEffect } from 'react';

import { KeyCode } from 'bloko/common/constants/keyboard';

interface UseKeyboardControlsProps<T> {
    focusedValue?: T;
    moveFocusedValue: (amount: number) => void;
    isExpanded?: boolean;
    toggleExpanded: (expand: boolean) => void;
    isFocused?: boolean;
    selectValue: (value: T) => void;
}

const useKeyboardControls = <T>({
    focusedValue,
    moveFocusedValue,
    isExpanded,
    toggleExpanded,
    isFocused,
    selectValue,
}: UseKeyboardControlsProps<T>): void => {
    const keyboardControlHandler = useCallback(
        (event: KeyboardEvent) => {
            if (!isExpanded) {
                if (isFocused && event.keyCode === KeyCode.Enter) {
                    toggleExpanded(true);
                }
                return;
            }
            switch (event.keyCode) {
                case KeyCode.ArrowUp:
                    moveFocusedValue(-1);
                    break;
                case KeyCode.ArrowDown:
                    moveFocusedValue(1);
                    break;
                case KeyCode.ESC:
                    toggleExpanded(false);
                    break;
                case KeyCode.Enter:
                    focusedValue && selectValue(focusedValue);
                    break;
                default:
                    return;
            }
            event.preventDefault();
        },
        [focusedValue, isExpanded, isFocused, moveFocusedValue, selectValue, toggleExpanded]
    );

    useEffect(() => {
        document.addEventListener('keydown', keyboardControlHandler);
        return () => {
            document.removeEventListener('keydown', keyboardControlHandler);
        };
    }, [keyboardControlHandler]);
};

export default useKeyboardControls;
