import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const GoogleScaleSmallAppearanceColor = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.13618 3.91109C9.41358 3.91109 10.2753 4.45183 10.7666 4.90368L12.6864 3.06665C11.5073 1.99257 9.97291 1.33331 8.13618 1.33331C5.47556 1.33331 3.17775 2.82961 2.05908 5.00739L4.25863 6.68146C4.8104 5.07405 6.33724 3.91109 8.13618 3.91109Z"
                    fill="#EA4335"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.6668 8.14818C14.6668 7.60003 14.6215 7.20003 14.5232 6.78522H8.13623V9.25929H11.8853C11.8097 9.87411 11.4015 10.8 10.4945 11.4223L12.6411 13.0519C13.9261 11.8889 14.6668 10.1778 14.6668 8.14818Z"
                    fill="#4285F4"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.26622 9.31862C4.12261 8.9038 4.03947 8.45936 4.03947 8.0001C4.03947 7.54084 4.12261 7.0964 4.25867 6.68158L2.05912 5.00751C1.59805 5.91121 1.3335 6.92603 1.3335 8.0001C1.3335 9.07417 1.59805 10.089 2.05912 10.9927L4.26622 9.31862Z"
                    fill="#FBBC05"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.13619 14.6667C9.97292 14.6667 11.5149 14.0741 12.6411 13.0519L10.4945 11.4222C9.92001 11.8148 9.14904 12.0889 8.13619 12.0889C6.33725 12.0889 4.81041 10.926 4.2662 9.31854L2.06665 10.9926C3.18532 13.1704 5.47557 14.6667 8.13619 14.6667Z"
                    fill="#34A853"
                />
            </g>
        </svg>
    ),
    'Google'
);
export default GoogleScaleSmallAppearanceColor;
