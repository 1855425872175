import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const ViberScaleSmallAppearanceColor = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M13.3136 2.01369C12.9592 1.69166 11.4293 0.730381 8.23708 0.716457C8.23708 0.716457 4.45702 0.468099 2.62206 2.13015C1.60077 3.13852 1.25672 4.63804 1.219 6.46794C1.18127 8.29784 0.985827 11.7936 4.33297 12.7235C4.33297 12.7235 4.3198 15.3187 4.31829 15.5468C4.31829 15.7063 4.3436 15.8154 4.43474 15.838C4.50031 15.8539 4.59854 15.82 4.68209 15.7367C5.21729 15.1992 6.93073 13.1321 6.93073 13.1321C9.22976 13.2822 11.0589 12.8283 11.2556 12.764C11.7194 12.6149 14.2281 12.3994 14.6526 8.97001C15.0914 5.43501 14.4914 3.02206 13.3136 2.01369Z"
                fill="#7360F2"
            />
            <path
                d="M9.86018 6.31753C9.81866 5.48056 9.39351 5.04097 8.58472 4.99878"
                stroke="white"
                strokeWidth={0.426842}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.9551 6.68464C10.972 5.90488 10.741 5.25339 10.2622 4.73018C9.7812 4.20511 9.11537 3.91599 8.26093 3.85345"
                stroke="white"
                strokeWidth={0.426842}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.0762 7.12312C12.0659 5.77001 11.6616 4.70434 10.8632 3.9261C10.0649 3.14786 9.07207 2.75461 7.8847 2.74634"
                stroke="white"
                strokeWidth={0.426842}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.62697 9.17611C8.62697 9.17611 8.92698 9.20143 9.0885 9.00243L9.40344 8.60623C9.55534 8.40977 9.92193 8.28445 10.2809 8.48445C10.5499 8.63788 10.8111 8.80467 11.0635 8.98421C11.3017 9.1594 11.7896 9.56649 11.7911 9.56649C12.0235 9.7627 12.0772 10.0508 11.9189 10.3546C11.9189 10.3564 11.9177 10.3594 11.9177 10.3609C11.7434 10.663 11.5221 10.9355 11.2622 11.168C11.2592 11.1696 11.2592 11.1711 11.2564 11.1726C11.0306 11.3613 10.8087 11.4685 10.5908 11.4944C10.5587 11.5 10.5261 11.502 10.4936 11.5004C10.3975 11.5014 10.3019 11.4867 10.2105 11.4569L10.2035 11.4465C9.86775 11.3518 9.30724 11.1149 8.3738 10.5999C7.83364 10.3054 7.31939 9.96575 6.83656 9.58447C6.59456 9.39347 6.36371 9.18874 6.14516 8.97129L6.12187 8.948L6.09858 8.92471L6.07529 8.90142C6.06744 8.89383 6.05984 8.88598 6.05199 8.87813C5.83455 8.65958 5.62982 8.42873 5.43882 8.18673C5.0576 7.70395 4.7179 7.18979 4.42336 6.64974C3.90842 5.71605 3.67145 5.15604 3.57677 4.81984L3.56639 4.81275C3.53671 4.72137 3.52208 4.62578 3.52309 4.52971C3.52132 4.49718 3.52328 4.46457 3.52892 4.43249C3.55609 4.21493 3.66352 3.9929 3.8512 3.7664C3.85272 3.76362 3.85424 3.76362 3.85576 3.76058C4.08824 3.50065 4.3607 3.27948 4.66286 3.10538C4.66438 3.10538 4.66742 3.10386 4.66919 3.10386C4.97299 2.94563 5.2611 2.9993 5.45705 3.23044C5.45857 3.23196 5.8649 3.71982 6.03934 3.95805C6.21889 4.21065 6.38569 4.47207 6.53909 4.74135C6.73909 5.10009 6.61378 5.46744 6.41732 5.61884L6.02111 5.93378C5.8211 6.0953 5.84743 6.3953 5.84743 6.3953C5.84743 6.3953 6.43428 8.61635 8.62697 9.17611Z"
                fill="white"
            />
        </svg>
    ),
    'Viber'
);
export default ViberScaleSmallAppearanceColor;
