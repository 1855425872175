import { MouseEventHandler, MutableRefObject, useCallback } from 'react';

import { ACTION_STATES, UseSwipeDetailsType } from './constants';
import { UseSwipeUtilsResult } from './useSwipeUtils';

export interface UseSwipeEventsInterface {
    setSwipeDetails: (details: Partial<UseSwipeDetailsType>) => void;
    swipeDetails: MutableRefObject<UseSwipeDetailsType>;
    swipeMove: UseSwipeUtilsResult['swipeMove'];
    swipeStart: UseSwipeUtilsResult['swipeStart'];
    swipeEnd: UseSwipeUtilsResult['swipeEnd'];
}

type DOMTouchEventHandler = (e: TouchEvent) => void;
interface UseSwipeEventsResult {
    onTouchStart: DOMTouchEventHandler;
    onTouchMove: DOMTouchEventHandler;
    onTouchEnd: DOMTouchEventHandler;
    onTouchCancel: DOMTouchEventHandler;
    onMouseDown: MouseEventHandler;
    onMouseMove: MouseEventHandler;
    onMouseUp: MouseEventHandler;
    onMouseLeave: MouseEventHandler;
}

const useSwipeEvents = ({
    setSwipeDetails,
    swipeDetails,
    swipeMove,
    swipeStart,
    swipeEnd,
}: UseSwipeEventsInterface): UseSwipeEventsResult => {
    const swipeStartMouse: MouseEventHandler = useCallback(
        (event) => {
            if (swipeDetails.current.type === ACTION_STATES.TOUCH) {
                return;
            }
            setSwipeDetails({
                type: ACTION_STATES.MOUSE,
            });
            swipeStart(event.pageX, event.pageY);
        },
        [swipeDetails, setSwipeDetails, swipeStart]
    );

    const swipeMoveMouse: MouseEventHandler = useCallback(
        (event) => {
            if (swipeDetails.current.type === ACTION_STATES.MOUSE) {
                swipeMove(event, event.pageX, event.pageY);
            }
        },
        [swipeDetails, swipeMove]
    );

    const swipeEndMouse: MouseEventHandler = useCallback(() => {
        if (swipeDetails.current.type === ACTION_STATES.MOUSE) {
            swipeEnd();
            setSwipeDetails({
                type: ACTION_STATES.NONE,
            });
        }
    }, [swipeDetails, swipeEnd, setSwipeDetails]);

    const swipeStartTouch: DOMTouchEventHandler = useCallback(
        (event) => {
            setSwipeDetails({
                type: ACTION_STATES.TOUCH,
            });
            const touch = event.changedTouches[0];

            swipeStart(touch.pageX, touch.pageY);
        },
        [setSwipeDetails, swipeStart]
    );

    const swipeMoveTouch: DOMTouchEventHandler = useCallback(
        (event) => {
            if (swipeDetails.current.type === ACTION_STATES.TOUCH) {
                const touch = event.changedTouches[0];

                swipeMove(event, touch.pageX, touch.pageY);
                return;
            }

            event.preventDefault();
        },
        [swipeDetails, swipeMove]
    );

    const swipeEndTouch: DOMTouchEventHandler = useCallback(() => {
        if (swipeDetails.current.type === ACTION_STATES.TOUCH) {
            swipeEnd();
            setSwipeDetails({
                type: ACTION_STATES.NONE,
            });
        }
    }, [swipeDetails, swipeEnd, setSwipeDetails]);

    return {
        onTouchStart: swipeStartTouch,
        onTouchMove: swipeMoveTouch,
        onTouchEnd: swipeEndTouch,
        onTouchCancel: swipeEndTouch,
        onMouseDown: swipeStartMouse,
        onMouseMove: swipeMoveMouse,
        onMouseUp: swipeEndMouse,
        onMouseLeave: swipeEndMouse,
    };
};

export default useSwipeEvents;
