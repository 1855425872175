import { useState, useEffect, useCallback } from 'react';

import useBreakpointContext from 'bloko/blocks/breakpointProvider/BreakpointContext';
import { getBreakpoint, Breakpoint, DEFAULT_BREAKPOINT } from 'bloko/common/media';

import useResize from 'bloko/common/hooks/useResize';

export default (initial?: Breakpoint): Breakpoint => {
    // Если в контексте передан брейкпоинт для рендеринга - испльзуем его как дефолтный
    const { breakpoint } = useBreakpointContext();
    const initialBreakpoint = breakpoint ?? (initial || DEFAULT_BREAKPOINT);
    const [currentBreakpoint, setCurrentBreakpoint] = useState(initialBreakpoint);

    const updateBreakpoint = useCallback(() => {
        const newBreakpoint = getBreakpoint();
        setCurrentBreakpoint(newBreakpoint);
    }, []);

    useEffect(updateBreakpoint);
    useResize(updateBreakpoint);

    return currentBreakpoint;
};

export { Breakpoint };
