enum LayerCssClass {
    AboveContent = 'above-content',
    Floating = 'floating',
    Overlay = 'overlay',
    OverlayContent = 'overlay-content',
    AboveOverlayContent = 'above-overlay-content',
    Topmost = 'topmost',
}

export default LayerCssClass;
