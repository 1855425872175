import React, { useCallback, useRef, RefObject, FC } from 'react';

import Swipe, { SwipeDirection } from 'bloko/blocks/swipe';

interface SwipeDistance {
    distance: number;
}

const BottomSheetSwipe: FC<{
    containerRef: RefObject<HTMLDivElement>;
    handleClose: () => void;
    handleClick: (event: React.MouseEvent | React.TouchEvent) => void;
}> = ({ containerRef, handleClose, handleClick }) => {
    const modalHeight = useRef(0);
    const clientHeight = window.innerHeight;

    const onSwipeMove = useCallback(
        ({ distance }: SwipeDistance) => {
            if (!containerRef.current || distance < 0) {
                return;
            }
            if (!modalHeight.current) {
                modalHeight.current = containerRef.current.offsetHeight;
            }
            containerRef.current.style.transform = `translate3d(0, ${distance}px, 0)`;
        },
        [containerRef]
    );

    const onSwipePrevent = useCallback(() => {
        if (!containerRef.current) {
            return;
        }
        containerRef.current.style.transition = 'all 0.2s';
        containerRef.current.style.transform = 'translate3d(0, 0, 0)';

        setTimeout(() => {
            if (!containerRef.current) {
                return;
            }
            containerRef.current.style.transition = '';
            modalHeight.current = 0;
        }, 200);
    }, [containerRef]);

    const onSwipeEnd = useCallback(
        ({ distance }: SwipeDistance) => {
            if (!containerRef.current || distance < 0) {
                return;
            }
            const top = clientHeight - modalHeight.current;
            modalHeight.current = 0;
            containerRef.current.style.transform = 'translate3d(0, 0, 0)';
            containerRef.current.style.top = `${top + distance}px`;
            handleClose();
        },
        [clientHeight, containerRef, handleClose]
    );

    return (
        <Swipe
            direction={SwipeDirection.Vertical}
            onSwipeMove={onSwipeMove}
            onSwipePrevent={onSwipePrevent}
            onSwipeEnd={onSwipeEnd}
            minSwipePercent={70}
        >
            {({ swipeContainerProps }) => (
                <div className="bloko-modal-swipe" onClick={handleClick} {...swipeContainerProps} />
            )}
        </Swipe>
    );
};

export default BottomSheetSwipe;
