import React, { FC, ReactNode, PropsWithChildren } from 'react';
import classNames from 'classnames';

import styles from 'bloko/blocks/suggest/suggest.less';

import cssClasses from './cssClasses';

type SuggestPickerItemProps = {
    /** Индекс элемента списка */
    index: number;
    /** Содержимое элемента списка */
    children: ReactNode;
    /** Является ли элемент подсвеченным */
    highlighted: boolean;
    /** Обработчики событий мыши */
    handleMouseEnter?: (index: number) => void;
    handleMouseMove?: (index: number) => void;
    handleMouseDown: (event: React.MouseEvent, index: number) => void;
};

const SuggestPickerItem: FC<SuggestPickerItemProps & PropsWithChildren> = ({
    index,
    highlighted,
    children,
    handleMouseEnter,
    handleMouseDown,
    handleMouseMove,
}) => {
    const onMouseDown = (event: React.MouseEvent) => {
        handleMouseDown(event, index);
    };

    const onMouseMove = () => {
        handleMouseMove?.(index);
    };

    const onMouseEnter = () => {
        handleMouseEnter?.(index);
    };

    return (
        <li
            className={classNames(styles.suggest__item, {
                [cssClasses.state.highlighted]: highlighted,
            })}
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMove}
            onMouseEnter={onMouseEnter}
        >
            {children}
        </li>
    );
};

SuggestPickerItem.defaultProps = {
    highlighted: false,
};

export default SuggestPickerItem;
