import Backbone from 'backbone';

import { toISO } from 'bloko/blocks/calendar/datesHelper';
import { prepareCalendarDates } from 'bloko/blocks/calendar/helper';

export default Backbone.Model.extend({
    constructor(options) {
        this.options = options;
        Backbone.Model.call(this, {
            currentOpenedDate: options.initialDate,
            selectedDate: null,
            disabledDates: options.disabledDates,
            month: this.options.translations.months[options.initialDate.getMonth()],
            year: options.initialDate.getFullYear(),
            weekdays: this.options.translations.weekdays,
            days: this.prepareCalendarDates(options.initialDate),
            isPreviousMonthDisabled: false,
            isNextMonthDisabled: false,
            previousMonthArrowKind: 'action',
            nextMonthArrowKind: 'action',
            isPreviousYearDisabled: false,
            isNextYearDisabled: false,
            previousYearArrowKind: 'action',
            nextYearArrowKind: 'action',
        });
    },

    initialize() {
        this.on('change:currentOpenedDate', function (event, date) {
            this.set({
                month: this.options.translations.months[date.getMonth()],
                year: date.getFullYear(),
                days: this.prepareCalendarDates(date),
            });
        });
    },

    prepareCalendarDates(date) {
        const { disabledDates, disabledWeekDays, disableDaysBeforeDate, disableDaysAfterDate } = this.options;
        const disabledDatesISO = disabledDates.map((date) => toISO(date));

        return prepareCalendarDates(
            date,
            [],
            disabledDatesISO,
            disabledWeekDays.map((weekday) => (weekday === 7 ? 0 : weekday)),
            true,
            disableDaysBeforeDate,
            disableDaysAfterDate
        ).map(({ date, isDisabled, isWeekend, isToday }) => ({
            dayNumber: date.getDate(),
            month: date.getMonth(),
            year: date.getFullYear(),
            iso: toISO(date),
            disabled: isDisabled,
            weekend: isWeekend,
            today: isToday,
        }));
    },
});
