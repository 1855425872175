const isEqualArrays = (arr1: string[], arr2: string[]): boolean => {
    if (arr1.length !== arr2.length) return false;
    const sortArr2 = arr2.slice().sort();
    return arr1
        .slice()
        .sort()
        .every((item, index) => item === sortArr2[index]);
};

// eslint-disable-next-line import/prefer-default-export
export { isEqualArrays };
