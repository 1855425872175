function flatten(metaData) {
    return Object.keys(metaData).reduce((result, item) => {
        if (typeof metaData[item] === 'object') {
            const child = flatten(metaData[item]);
            Object.keys(child).forEach((key) => {
                if (result[key]) {
                    result[key] += `, ${child[key]}`;
                } else {
                    result[key] = child[key];
                }
            });
        } else {
            result[item] = metaData[item];
        }
        return result;
    }, {});
}

function getMetaData(geoObject) {
    return geoObject.properties.get('metaDataProperty.GeocoderMetaData');
}

function convertGeoObject(object) {
    return flatten(getMetaData(object));
}

function getCountry(converted) {
    return converted.CountryName;
}

function getCountryCode(converted) {
    return converted.CountryNameCode;
}

function getCity(converted) {
    return converted.LocalityName || converted.SubAdministrativeAreaName || converted.AdministrativeAreaName;
}

function buildPartAddressString(array) {
    return array
        .filter((item) => {
            return item;
        })
        .join(', ');
}

function getStreet(converted) {
    return buildPartAddressString([
        converted.DependentLocalityName,
        converted.ThoroughfareName,
        converted.ThoroughfareNumber,
        converted.DependentThoroughfareName,
        converted.DependentThoroughfareNumber,
    ]);
}

function getBuilding(converted) {
    return buildPartAddressString([
        converted.PremiseName,
        converted.PremiseNumber,
        converted.SubPremiseName,
        converted.SubPremiseNumber,
    ]);
}

export function convertGeoCodeResult(result) {
    const geoCodeResult = result.geoObjects.get(0);
    const dividedAddress = getDividedAddress(geoCodeResult);
    return {
        fullAddress: geoCodeResult.getAddressLine() || geoCodeResult.getCountry(),
        coordinates: geoCodeResult.geometry.getCoordinates(),
        country: dividedAddress.country || '',
        countyCode: dividedAddress.countyCode || '',
        city: dividedAddress.city || '',
        street: dividedAddress.street || '',
        building: dividedAddress.building || '',
    };
}

export function getDividedAddress(geoObject) {
    const converted = convertGeoObject(geoObject);
    const isMetroOnlyAddress = converted.kind === 'metro';

    return {
        country: getCountry(converted),
        countyCode: getCountryCode(converted),
        city: getCity(converted),
        street: getStreet(converted),
        building: isMetroOnlyAddress ? '' : getBuilding(converted),
    };
}
