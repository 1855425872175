import { Fragment, useState, useCallback, useMemo, ReactNode } from 'react';

import TreeSelectorDummy from 'bloko/blocks/treeSelector/Dummy';
import { LabelComponentType, IconComponentType } from 'bloko/blocks/treeSelector/Dummy/types';
import TreeSelectorPopup from 'bloko/blocks/treeSelectorPopup';
import ImmutableSelectionStrategy from 'bloko/common/tree/immutableSelectionStrategy';
import TreeCollection from 'bloko/common/tree/treeCollection';
import {
    getIdsWithNoParentsInSameList,
    narrowDownExcludedFromChildrenToParents,
    removeExcludedFromSelected,
} from 'bloko/common/tree/treeCollectionHelper';
import { IdCollectionPredicate, TreeFilter, AdditionalDefault } from 'bloko/common/tree/types';

import TagList from 'bloko/blocks/compositeSelection/TagList';
import useDisabled from 'bloko/blocks/compositeSelection/hooks/useDisabled';
import useExpanded from 'bloko/blocks/compositeSelection/hooks/useExpanded';
import useRenderInput from 'bloko/blocks/compositeSelection/hooks/useRenderInput';
import useSelected from 'bloko/blocks/compositeSelection/hooks/useSelected';
import useTreeSelectorHandlers from 'bloko/blocks/compositeSelection/hooks/useTreeSelectorHandlers';
import { Children, CompositeSelectionTagListProps, FooterExtra } from 'bloko/blocks/compositeSelection/types';

import { EMPTY_ARRAY } from './const';

interface PopupTrls {
    submit: string;
    cancel: string;
    searchPlaceholder: string;
    notFound?: string;
    maxCountError?: string;
}

type WithExcluded = {
    /** Можно исключать значения **/
    withExcluded: true;
    /** Исключенные значения */
    excludedValue: string[];
    exclusionWithTwoLines?: boolean;
    singleChoice?: false;
    leavesOnly?: false;
    singleCategory?: false;
};

type WithoutExcluded = {
    withExcluded?: false;
    excludedValue?: never;
    exclusionWithTwoLines?: never;
};

type ExcludedFunctionality = WithExcluded | WithoutExcluded;

interface CompositeSelectionProps<A extends AdditionalDefault> {
    /** Метод которому передаётся объект с необходимыми функциями для вывода частей компонента */
    children: Children<A>;
    /** Древовидная коллекция */
    collection: TreeCollection<A>;
    /** Текущее значение */
    value: string[];
    /** Отображать только листья дерева */
    leavesOnly?: boolean;
    /** Можно выбрать только один элемент */
    singleChoice?: boolean;
    /** Способ фильтрации дерева при поиске */
    treeFilter?: TreeFilter;
    /** Ограничение максимального количества выбранных элементов */
    maxItems?: number;
    /** Выбираемые элементы ограничены одной категорией */
    singleCategory?: boolean;
    /** Компонент для вывода метки у элемента дерева */
    LabelComponent?: LabelComponentType<A>;
    /** Компонент для вывода иконки у элемента дерева */
    IconComponent?: IconComponentType<A>;
    /** Колбэк при изменении выбора */
    onChange?: (items: string[], excludedItems: string[]) => void;
    /** Колбэк при снятии фокуса */
    onBlur?: () => void;
    /** Колбэк при фокусе */
    onFocus?: () => void;
    /** Заголовок попапа */
    title?: string;
    /** Блок между заголовком попапа и поисковым инпутом */
    contentAfterTitle?: ReactNode;
    /** Показывать ли список выбранных */
    showSelectedList?: boolean;
    /** Отображать ли крестик очистки строки поиска */
    searchWithClear?: boolean;
    /** Очищать ли поле поиска после выбора элемента */
    clearSearchOnChange?: boolean;
    /** ID моделей, которые всегда будут дополнительно предлагаться для выбора, если в поиске что-то нашлось */
    constantlySuggested?: string[];
    /** ID моделей, которые предлагаются для выбора, если в поиске ничего не найдено */
    suggestedNotFound?: string[];
    /** Подсказка под полем поиска */
    searchHint?: ReactNode;
    /** Раскрывать ли категории с выбранными элементами в дереве при открытии попапа */
    expandTreeOnShowPopup?: boolean;
    /** Переводы для попапа */
    trl: PopupTrls;
    /** Обработчик изменения строки contentFilterQuery.
     * В аргументах получает список подходящих под запрос id и сам запрос */
    onChangeFilterQuery?: (ids: string[], query: string) => void;
    /** Рендер-метод дополнительной информации в футере, получает функции закрытия и сабмита попапа */
    footerExtra?: FooterExtra;
    /** DOM нода хоста в рамках которого нужно рендерить модальное окно, по дефолту рендер будет в body.*/
    host?: HTMLElement;
    /** Функция возвращает true для элементов, которые можно выбрать. При использовании
     * вместе с параметром leavesOnly применяется только к элементам без потомков. */
    checkSelectable?: IdCollectionPredicate;
}

const CompositeSelection = <A extends AdditionalDefault>({
    children,
    collection,
    title,
    contentAfterTitle,
    value,
    excludedValue = EMPTY_ARRAY,
    leavesOnly,
    singleChoice,
    treeFilter,
    maxItems,
    singleCategory,
    LabelComponent,
    IconComponent,
    showSelectedList,
    searchWithClear,
    clearSearchOnChange,
    constantlySuggested,
    suggestedNotFound,
    searchHint,
    expandTreeOnShowPopup = true,
    onChange,
    onBlur,
    onFocus,
    onChangeFilterQuery,
    trl,
    footerExtra,
    host,
    checkSelectable,
    withExcluded,
    exclusionWithTwoLines,
}: CompositeSelectionProps<A> & ExcludedFunctionality): JSX.Element => {
    const [visible, setVisible] = useState(false);
    const [withTagList, setWithTagList] = useState(false);

    const [maxCountError, setMaxCountError] = useState(false);

    const selectionStrategy = useMemo(
        () =>
            new ImmutableSelectionStrategy(collection, {
                singleChoice,
                singleCategory,
                leavesOnly,
                checkSelectable,
                withExcluded,
            }),
        [collection, singleChoice, leavesOnly, checkSelectable, withExcluded, singleCategory]
    );

    const { selected, selectedTree, setSelected, excluded } = useSelected({
        value,
        excludedValue,
        selectionStrategy,
        collection,
        maxItems,
        setMaxCountError,
    });

    const disabled = useDisabled({ selected, collection, singleCategory, maxItems });
    const [expanded] = useExpanded({ value, collection, excludedValue, expandTreeOnShowPopup });

    const {
        handleTreeSelectorPopupClose,
        handleTreeSelectorPopupSubmit,
        handleTreeSelectorChange,
        showTreeSelectorPopup,
    } = useTreeSelectorHandlers({
        collection,
        value,
        excludedValue,
        selected,
        excluded,
        selectionStrategy,
        onChange,
        onFocus,
        onBlur,
        setVisible,
        setSelected,
    });

    const renderInput = useRenderInput({
        collection,
        selectionStrategy,
        value,
        selected,
        excluded,
        withTagList,
        singleChoice,
        maxItems,
        showTreeSelectorPopup,
        onBlur,
        onChange,
        onFocus,
    });

    const removeItems = useCallback(
        (ids: string[]) => {
            const [items, excluded] = ids.reduce<[string[], string[]]>(
                (result, id) => {
                    const processedIds = ids.length > 1 ? [id] : ids;

                    result[0] = selectionStrategy.remove(result[0], processedIds);
                    result[1] = selectionStrategy.exclude(result[0], result[1], processedIds);

                    return result;
                },
                [value, excludedValue]
            );

            onChange?.(
                ...removeExcludedFromSelected(
                    getIdsWithNoParentsInSameList(collection, items, excluded),
                    narrowDownExcludedFromChildrenToParents(collection, excluded)
                )
            );
            onBlur?.();
        },
        [value, onChange, onBlur, excludedValue, selectionStrategy, collection]
    );

    const crossedOutValue = useMemo(
        () => (excludedValue.length ? narrowDownExcludedFromChildrenToParents(collection, excludedValue) : EMPTY_ARRAY),
        [collection, excludedValue]
    );

    const renderTagList = useCallback(
        (
            tagListProps: Partial<
                typeof collection extends TreeCollection<infer A> ? CompositeSelectionTagListProps<A> : never
            > = {}
        ) => {
            if (!withTagList) {
                setWithTagList(true);
            }
            return (
                <TagList
                    value={value}
                    crossedOutValue={exclusionWithTwoLines ? EMPTY_ARRAY : crossedOutValue}
                    collection={collection}
                    removeItems={removeItems}
                    {...tagListProps}
                />
            );
        },
        [value, collection, removeItems, withTagList, crossedOutValue, exclusionWithTwoLines]
    );

    const renderExcludedTagList = useCallback(
        (
            tagListProps: Partial<
                typeof collection extends TreeCollection<infer A> ? CompositeSelectionTagListProps<A> : never
            > = {}
        ) => {
            if (!exclusionWithTwoLines) return null;
            if (!withTagList) {
                setWithTagList(true);
            }
            return (
                <TagList value={crossedOutValue} collection={collection} removeItems={removeItems} {...tagListProps} />
            );
        },
        [collection, removeItems, withTagList, crossedOutValue, exclusionWithTwoLines]
    );

    const renderFooterExtra = useCallback(() => {
        return footerExtra?.({
            hideTreeSelectorPopup: handleTreeSelectorPopupClose,
            submitTreeSelectorPopup: handleTreeSelectorPopupSubmit,
        });
    }, [footerExtra, handleTreeSelectorPopupSubmit, handleTreeSelectorPopupClose]);

    return (
        <Fragment>
            <TreeSelectorPopup
                visible={visible}
                selected={selected}
                onClose={handleTreeSelectorPopupClose}
                onSubmit={handleTreeSelectorPopupSubmit}
                onChangeFilterQuery={onChangeFilterQuery}
                title={title}
                contentAfterTitle={contentAfterTitle}
                trl={trl}
                searchWithClear={searchWithClear}
                clearSearchOnChange={clearSearchOnChange}
                error={maxCountError && trl.maxCountError ? trl.maxCountError : undefined}
                footerExtra={renderFooterExtra()}
                hint={searchHint || undefined}
                host={host}
            >
                <TreeSelectorDummy
                    checkSelectable={checkSelectable}
                    collection={collection}
                    expanded={expanded}
                    indeterminateWithParents={!withExcluded}
                    onChange={handleTreeSelectorChange}
                    leavesOnly={leavesOnly}
                    singleChoice={singleChoice}
                    disabled={disabled}
                    LabelComponent={LabelComponent}
                    IconComponent={IconComponent}
                    showSelectedList={showSelectedList}
                    suggestedNotFound={suggestedNotFound}
                    constantlySuggested={constantlySuggested}
                    treeFilter={treeFilter}
                />
            </TreeSelectorPopup>
            {children({ renderTagList, renderExcludedTagList, renderInput, showTreeSelectorPopup, selectedTree })}
        </Fragment>
    );
};

export default CompositeSelection;
