import RateLimitedAction from './helpers/rateLimitedAction';

/**
 * Утилитарный модуль, который вызвает переданную функцию не чащее, чем раз в wait ms. Если игнорируемый вызов оказался последним, т.е. после него до окончания задержки ничего нет – то он выполнится.
 *
 * @param func функция вызов которой, нужно отложить
 * @param waitMS количество ms, на которое нужно отложить вызов функции
 */
const throttle: RateLimitedAction = (func, waitMS) => {
    let timeout: null | ReturnType<typeof setTimeout>;
    let savedArgs: null | Parameters<typeof func>;
    let self: unknown;

    const throttled = function throttled(this: unknown, ...args: Parameters<typeof func>) {
        if (timeout) {
            savedArgs = args;
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            self = this;
            return;
        }

        func.apply(this, args);

        timeout = setTimeout(() => {
            timeout = null;
            if (savedArgs) {
                throttled.apply(self, savedArgs);
                savedArgs = null;
            }
        }, waitMS);
    };

    /**
     * Отмена выполнения вызова
     */
    throttled.cancel = () => {
        timeout && clearTimeout(timeout);
        savedArgs = null;
        timeout = null;
    };

    return throttled;
};

export default throttle;
