import { useEffect, useState } from 'react';

import { isEqualArrays } from 'bloko/blocks/treeSelector/utils';
import TreeCollection from 'bloko/common/tree/treeCollection';
import { AdditionalDefault } from 'bloko/common/tree/types';

/**
 * Возвращает массив ID элементов дерева, у которых есть и выбранные, и невыбранные потомки.
 */
export function getIndeterminateParentIds<A extends AdditionalDefault>(
    collection: TreeCollection<A>,
    selected: string[]
): string[] {
    const result = selected.reduce((currentResult, id) => {
        collection.getParentIds(id).forEach((parentId) => {
            if (!selected.includes(parentId)) {
                currentResult.add(parentId);
            }
        });
        return currentResult;
    }, new Set<string>());
    return [...result];
}

interface UseIndeterminateHookProps<A extends AdditionalDefault> {
    initialCollection: TreeCollection<A>;
    selected: string[];
}
interface UseIndeterminateHook {
    <A extends AdditionalDefault>(props: UseIndeterminateHookProps<A>): [string[], (indeterminate: string[]) => void];
}

const useIndeterminate: UseIndeterminateHook = ({ initialCollection, selected }) => {
    const [indeterminate, setIndeterminate] = useState<string[]>([]);

    useEffect(() => {
        const updatedIndeterminateIds = getIndeterminateParentIds(initialCollection, selected);
        setIndeterminate((current) =>
            isEqualArrays(current, updatedIndeterminateIds) ? current : updatedIndeterminateIds
        );
    }, [selected, initialCollection]);
    return [indeterminate, setIndeterminate];
};

export default useIndeterminate;
