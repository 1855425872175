import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const CheckmarkScaleMediumKindSingleAppearanceFilledEnclosedTrue = hoc(
    (props: IconProps) => (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM17.2071 8.29289C17.5976 8.68342 17.5976 9.31658 17.2071 9.70711L11.2071 15.7071C10.8166 16.0976 10.1834 16.0976 9.79289 15.7071L6.79289 12.7071C6.40237 12.3166 6.40237 11.6834 6.79289 11.2929C7.18342 10.9024 7.81658 10.9024 8.20711 11.2929L10.5 13.5858L15.7929 8.29289C16.1834 7.90237 16.8166 7.90237 17.2071 8.29289Z"
                fill="var(--bloko-icon-color, var(--bloko-icon-color-default))"
            />
        </svg>
    ),
    'Checkmark'
);
export default CheckmarkScaleMediumKindSingleAppearanceFilledEnclosedTrue;
