import Backbone from 'backbone';
import $ from 'jquery';

import TreeCollection from 'bloko/common/tree/collection';
import TreeModel from 'bloko/common/tree/model';

import InlineTagListView from 'bloko/blocks/tagList/inlineTagListView';

/**
 * Публичный интерфейс для работы со списком тегов, возвращаемый {@link module:TagList}.
 *
 * @interface TagList/TagListInterface
 * @see {@link TagList/TagListInterface}
 */

/**
 * Задаёт список тегов.
 * Метод удаляет теги, которые не встречаются в переданном списке,
 * или обновляет существующие, если они уже есть в списке.
 * [Формат](https://tech.hh.ru/bloko/?path=/docs/components-taglist-classic-taglist--page#format) данных списка тегов.
 * @method TagList/TagListInterface#set
 * @param {Array} tags список тегов
 *
 * @fires Bloko-TagList-Added
 * @fires Bloko-TagList-Changed
 */
/**
 * Добавляет тег(и).
 * [Формат](https://tech.hh.ru/bloko/?path=/docs/components-taglist-classic-taglist--page#format) данных списка тегов.
 * @method TagList/TagListInterface#add
 * @param {Array} tags список тегов
 *
 * @fires Bloko-TagList-Added
 * @fires Bloko-TagList-Changed
 */
/**
 * Находит тег по переданному tagId и удаляет его
 * @method TagList/TagListInterface#remove
 * @param {String} tagId тега
 *
 * @fires Bloko-TagList-Removed
 * @fires Bloko-TagList-Changed
 */

/**
 * Модуль создающий список тегов.
 * @module TagList
 */
export default {
    /**
     * Метод-конструктор, вызывается, чтобы получить инстанс компонента.
     * @param {Element} element jQuery объект - элемент на котором будет инициализирован компонент
     * @param {Object} options набор опций
     * @param {String} options.hiddenFieldName название скрытого поля для тегов, `value = id` тега
     * @param {Boolean} [options.defaultSelected=true] флаг возможно ли выбрать selectable тег по клику
     * @param {Boolean} [options.removable=false] возможность удаления тегов по умолчанию
     * @param {Boolean} [options.stretched=false] отображения тегов на всю ширину
     * @param {Boolean} [options.selectable=false] возможность выбора тегов
     * @param {Boolean} [options.editable=false] возможность редактирования тегов
     * @param {Boolean} [options.animateNew=true] анимировать ли появление новых тегов
     * @param {Mustache} [options.textTemplate] кастомный шаблон текста тега
     * @constructor
     * @return {Object} инстанс компонента
     */
    create(element, options) {
        const tagListView = this.createView(element, options);
        const publicInterface = this.createPublicInterface(tagListView);

        return publicInterface;
    },

    /**
     * Создает view списка тегов.
     * @param  {Element} element строка либо jQuery селектор - элемент на котором будет инициализирован
     *                                  компонент
     * @param  {Object} options набор опций
     * @private
     * @returns {Object}
     */
    createView(element, options) {
        return new InlineTagListView({
            collection: new TreeCollection(null, {
                model: this.createTagModel(options),
            }),
            el: element,
            options,
        });
    },

    /**
     * Создает модель списка тегов.
     * @param  {Object} options набор опций
     * @private
     * @returns {Object}
     */
    createTagModel(options) {
        return TreeModel.extend(this.getOptions(options));
    },

    getOptions(options) {
        if (options) {
            return {
                removable: !!options.removable,
                editable: !!options.editable,
                selectable: !!options.selectable,
                newTag: typeof options.animateNew === 'undefined' ? true : !!options.animateNew,
            };
        }
        return {
            editable: false,
            removable: false,
            selectable: false,
            newTag: true,
        };
    },

    /**
     * Создание публичного интерфейса.
     * @param tagListView
     * @private
     * @returns {TagList/TagListInterface}
     */
    createPublicInterface(tagListView) {
        const publicInterface = $.extend(
            {
                /**
                 * Возращает JSON представление запрошенного тега
                 * @method TagList/TagListInterface#get
                 * @param {String} id id тега
                 */
                get: tagListView.getTag.bind(tagListView),
                /**
                 * Возращает JSON представление коллекции тегов
                 * @method TagList/TagListInterface#getAll
                 */
                getAll: tagListView.getTags.bind(tagListView),
                set: tagListView.setTags.bind(tagListView),
                add: tagListView.addTag.bind(tagListView),
                remove: tagListView.removeTag.bind(tagListView),
                /**
                 * Устанавливает текст тега
                 * @method TagList/TagListInterface#setTagText
                 * @param {String} tagId id тега
                 * @param {String} text текст тега
                 */
                setTagText: tagListView.setTagText.bind(tagListView),
                /**
                 * Возвращает jQuery объект с тегом с соответствующим id
                 *
                 * @method TagList/TagListInterface#getTagElement
                 * @param  {Number} tagId id тега, который надо найти
                 * @return {Object}       jQuery Object для тега с переданным id
                 */
                getTagElement: tagListView.$nodeByTagId.bind(tagListView),
                /**
                 * Выделяет или снимает выделение у тега
                 * @method
                 * @param {String} tagId id тега
                 * @param {Boolean} selected выбран ли тег
                 */
                toggleSelect: tagListView.toggleSelect.bind(tagListView),
                /**
                 * Переключает disabled состояние тега
                 * @method
                 * @param {Number} tagId id тега
                 * @param {Boolean} disabled задизейблен ли тег
                 */
                toggleDisabled: tagListView.toggleDisabled.bind(tagListView),
                /**
                 * Устанавливает значение скрытого инпута
                 * @method TagList/TagListInterface#setHiddenValue
                 * @param {String} id
                 * @param {String} value
                 */
                setHiddenValue: tagListView.setHiddenValue.bind(tagListView),
                /**
                 * Инициализирует теги, которые уже были заданы в верстке на момент инициализации компонента
                 * @method TagList/TagListInterface#initTags
                 */
                initTags: tagListView.initTags.bind(tagListView),
            },
            Backbone.Events
        );

        tagListView.on('Bloko-TagList-Added', (tag) => {
            publicInterface.trigger('Bloko-TagList-Added', tag);
        });
        tagListView.on('Bloko-TagList-Changed', (tag) => {
            publicInterface.trigger('Bloko-TagList-Changed', tag);
        });
        tagListView.on('Bloko-TagList-Removed', (tag) => {
            publicInterface.trigger('Bloko-TagList-Removed', tag);
        });
        tagListView.on('Bloko-TagList-ToggleSelected', (tag) => {
            publicInterface.trigger('Bloko-TagList-ToggleSelected', tag);
        });

        return publicInterface;
    },
};
