const MILLISECONDS_IN_HOUR = 3600000;

interface CookieValue {
    name: string;
    value: string;
}
/**
 * Утилита по работе с куками.
 *
 * @type {Object}
 * @exports bloko/common/Cookies
 */
export default {
    /**
     * Устанавливает куку
     *
     * @param {String} name  Имя куки
     * @param {String} value Значение куки
     * @param {Number} time  Время в часах
     */
    set(name: string, value?: string | number, time?: number): void {
        if (name === undefined) {
            return;
        }
        let cookie = `${name}=${value || ''};path=/`;

        if (time !== undefined) {
            const expire = new Date();

            expire.setTime(expire.getTime() + time * MILLISECONDS_IN_HOUR);
            cookie += `;expires=${expire.toUTCString()}`;
        }

        document.cookie = cookie;
    },

    /**
     * Получить значение куки.
     *
     * @param {String} name Имя куки
     *
     * @returns {String} Значение куки
     */
    get(name: string): string | null {
        let result = null;

        this.getAll()
            .reverse()
            .some((cookie) => {
                if (cookie.name === name) {
                    result = cookie.value;
                    return true;
                }
                return false;
            });

        return result;
    },

    /**
     * Получить все куки.
     *
     * @returns {Array} Массив объектов с значениями кук
     */
    getAll(): CookieValue[] {
        const cookies = document.cookie ? document.cookie.split('; ') : [];
        return cookies.map((cookie) => {
            const parts = cookie.split('=');
            let value;

            try {
                value = decodeURIComponent(parts[1]);
            } catch (e) {
                value = parts[1];
            }

            return {
                name: parts[0],
                value,
            };
        });
    },

    /**
     * Удалить куку.
     *
     * @param {String} name Имя куки
     */
    remove(name: string): void {
        this.set(name, '', -1);
    },
};
