import { ComponentWithCustomElement } from 'bloko/common/helpers/types';

import { AllowedElements } from 'bloko/blocks/column/common';

import styles from 'bloko/blocks/column/column.less';

interface ColumnsWrapperProps {
    /** Кастомный компонент функция или [доступные теги](#invariants)*/
    Element?: AllowedElements;
    /** Указывает на строку с компонентом в исходном коде в режиме разработки. Генерируется babel-plugin-react-source */
    source?: string;
}

const ColumnsWrapper: ComponentWithCustomElement<ColumnsWrapperProps, 'div'> = ({
    Element = 'div',
    ...columnRowProps
}) => <Element className={styles['bloko-columns-wrapper']} {...columnRowProps} />;

export default ColumnsWrapper;
