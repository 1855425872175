import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const BubbleScaleSmallKindSingleAppearanceFilledShapeSquare = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.8275 1.33334H5.17239H5.17238C4.63574 1.33334 4.19284 1.33333 3.83204 1.36281C3.45731 1.39342 3.11284 1.45913 2.78928 1.62399C2.28752 1.87966 1.87957 2.2876 1.62391 2.78937C1.45905 3.11293 1.39334 3.4574 1.36272 3.83213C1.33324 4.19292 1.33325 4.63583 1.33326 5.17248L1.33325 13.5806C1.33322 13.7375 1.33319 13.8973 1.34509 14.0284C1.35647 14.1537 1.38661 14.3901 1.55172 14.597C1.7417 14.835 2.0298 14.9735 2.33434 14.9731C2.59899 14.9728 2.80249 14.8486 2.90744 14.7793C3.01717 14.7067 3.14199 14.6068 3.26444 14.5088L4.8732 13.2218C5.21892 12.9452 5.32156 12.8671 5.42822 12.8127C5.5352 12.758 5.64908 12.7181 5.76676 12.6939C5.88409 12.6698 6.01302 12.6667 6.45576 12.6667H10.8275C11.3641 12.6667 11.807 12.6667 12.1678 12.6372C12.5425 12.6066 12.887 12.5409 13.2106 12.376C13.7123 12.1204 14.1203 11.7124 14.3759 11.2107C14.5408 10.8871 14.6065 10.5426 14.6371 10.1679C14.6666 9.8071 14.6666 9.3642 14.6666 8.82756V5.17246C14.6666 4.63582 14.6666 4.19292 14.6371 3.83213C14.6065 3.4574 14.5408 3.11293 14.3759 2.78937C14.1203 2.2876 13.7123 1.87966 13.2106 1.62399C12.887 1.45913 12.5425 1.39342 12.1678 1.36281C11.807 1.33333 11.3641 1.33334 10.8275 1.33334H10.8275ZM4.66667 4.66667C4.29848 4.66667 4 4.96514 4 5.33333C4 5.70152 4.29848 6 4.66667 6H8C8.36819 6 8.66667 5.70152 8.66667 5.33333C8.66667 4.96514 8.36819 4.66667 8 4.66667H4.66667ZM4.66667 7.33333C4.29848 7.33333 4 7.63181 4 8C4 8.36819 4.29848 8.66667 4.66667 8.66667H10C10.3682 8.66667 10.6667 8.36819 10.6667 8C10.6667 7.63181 10.3682 7.33333 10 7.33333H4.66667Z"
                fill="var(--bloko-icon-color, var(--bloko-icon-color-default))"
            />
        </svg>
    ),
    'Bubble'
);
export default BubbleScaleSmallKindSingleAppearanceFilledShapeSquare;
