import { getThemeClass, getPlacementClass, setInitialCSSMetrics, updatePosition } from 'bloko/blocks/drop/common';
import { makeDropLayerClass, ENTER_ANIMATION_MS, DISTANCE, FLEXIBLE_CLASS } from 'bloko/blocks/drop/constants';
import setClickable from 'bloko/blocks/drop/setClickable';
import Components from 'bloko/common/core/Components';
import requestAnimation from 'bloko/common/requestAnimation';

import defaults, {
    PLACEMENT_TIP_SEQUENCE,
    BASE_CLASS_NAMES,
    ARROW_SIZE,
    ANIMATION_TIMEOUT_MS,
    TipPlacement,
    TipTheme,
    TipLayer,
} from 'bloko/blocks/drop/Tip/constants';
import template from 'bloko/blocks/drop/Tip/templateTip.mustache';

let activeTip;

/**
 * @exports bloko/blocks/drop/Tip/tip
 *
 * Создает блок-подсказку для пользователя.
 *
 * @param {Element} element DOM-елемент, активатор. Тултип позиционируется относительно него.
 *
 * @param {Object} params параметры DropTip
 *
 * @param {String} params.placement='bottom' Предпочтительное положение дроптипа, доступны в статическом свойстве [placements](/#/•%20Drop?id=tip-placements).
 * Если в указанном направлении недостаточно места для отображения, подсказка будет показана в направлении, которое
 * больше подходит.
 * @param {String} params.layer='aboveContent' Тип слоя z-index-а подсказки, доступны в статическом свойстве
 * [layers](/#/•%20Drop?id=tip-layers)
 * @param {String} params.theme='dark'  Возможные темы дроптипа доступны в статическом свойстве
 * [themes](/#/•%20Drop?id=tip-themes)
 * @param {String | Element} params.host=element.parentNode DOM нода или селектор хоста в рамках которого
 * нужно рендерить дроптип
 * @param {String} params.сontent cодержимое DropTip. Поддерживает только текст.
 * @param {String} params.html cодержимое DropTip, поддерживает html разметку, имеет приоритет перед `сontent`.
 * @param {Function} params.onExternalClose=() => {} Колбек вызывается, когда показан другой дроптип. Это связано с тем что дроптип может быть показан только 1
 * @param {Boolean} params.flexible Флаг, отключающий макусимальную ширину
 * @constructor
 */

const Tip = Components.build({
    defaults: {
        ...defaults,
        host: undefined,
        onExternalClose: () => {},
    },
    create(element, params) {
        let options;
        let isVisible;

        const updateTipPosition = requestAnimation((event) => {
            const { tipNode, hostNode, placement, theme, layer, arrowRef, flexible } = options;

            updatePosition({
                activatorElement: element,
                placement,
                host: hostNode,
                placementSequence: PLACEMENT_TIP_SEQUENCE,
                classNames: [
                    ...BASE_CLASS_NAMES,
                    makeDropLayerClass(layer),
                    flexible ? FLEXIBLE_CLASS : '',
                    event ? '' : 'bloko-drop_active-enter',
                    'Bloko-Tip',
                ],
                dropElement: tipNode,
                arrow: arrowRef,
                arrowSize: ARROW_SIZE,
                behavior: {
                    placementOffset: DISTANCE + ARROW_SIZE,
                    alignToActivatorBorders: false,
                },
            });

            tipNode.classList.add(getThemeClass(theme));
        });

        const publicInterface = {
            /**
             * Отображает DropTip
             */
            show() {
                const { tipNode, hostNode, onExternalClose } = options;

                if (isVisible) {
                    return;
                }

                if (activeTip) {
                    activeTip.hide();
                    onExternalClose();
                }

                activeTip = publicInterface;
                isVisible = true;
                tipNode.classList.add('bloko-drop_active-enter');

                setClickable(tipNode);
                hostNode.appendChild(tipNode);
                setInitialCSSMetrics(tipNode);
                updateTipPosition();
                window.addEventListener('resize', updateTipPosition);

                setTimeout(() => tipNode.classList.add('bloko-drop_done-enter'), ANIMATION_TIMEOUT_MS);

                setTimeout(() => {
                    tipNode.classList.remove('bloko-drop_done-enter');
                    tipNode.classList.remove('bloko-drop_active-enter');
                }, ANIMATION_TIMEOUT_MS + ENTER_ANIMATION_MS);
            },

            /**
             * Скрывает DropTip
             */
            hide() {
                if (isVisible) {
                    options.hostNode.removeChild(options.tipNode);
                    activeTip = null;
                    isVisible = false;

                    window.removeEventListener('resize', updateTipPosition);
                }
            },

            /**
             * Изменяет состояние DropTip (открывает, если скрыт, и наоборот)
             *
             * @param state {Boolean}
             */
            toggle(state) {
                if (arguments.length === 0 ? !isVisible : state) {
                    this.show();
                } else {
                    this.hide();
                }
            },

            /**
             * Изменяет параметры указаные при инициализации
             * @param params {Object} Параметры params [DropTip](/dropTip.html#Параметры)
             */
            changeOptions({
                host = params.host || element.parentNode,
                html = params.html,
                placement = params.placement,
                theme = params.theme,
                layer = params.layer,
                dataQa = params.dataQa,
                onExternalClose = params.onExternalClose,
                flexible = params.flexible,
            }) {
                const visibleState = isVisible;

                if (isVisible) {
                    this.hide();
                }

                const hostNode = typeof host === 'string' ? document.querySelector(host) : host;

                const tipHTML = template.render({
                    layer: makeDropLayerClass(layer),
                    placement: getPlacementClass(placement).join(' '),
                    theme: getThemeClass(theme),
                    dataQa,
                });

                const tipWrapNode = document.createElement('div');
                tipWrapNode.innerHTML = tipHTML;
                const tipNode = tipWrapNode.firstChild;

                if (typeof html === 'string') {
                    tipNode.querySelector('.Bloko-Tip-Content').innerHTML = html;
                } else if (html) {
                    tipNode.querySelector('.Bloko-Tip-Content').appendChild(html);
                }

                const arrowRef = tipNode.querySelector('.Bloko-Tip-Arrow');

                options = {
                    tipNode,
                    hostNode,
                    placement,
                    theme,
                    layer,
                    arrowRef,
                    onExternalClose,
                    flexible,
                };

                if (visibleState) {
                    this.show();
                }

                return this;
            },
        };

        publicInterface.changeOptions(params);

        return publicInterface;
    },
});

export default Tip;
export { TipLayer, TipPlacement, TipTheme };
