import { cloneElement, FC, ReactElement, ReactNode, useCallback, useState, PropsWithChildren, MouseEvent } from 'react';

import { DropPlacementValue, DropLayer } from './constants';

interface BaseDropProps {
    placement?: DropPlacementValue;
    layer?: DropLayer;
    host?: HTMLElement | null;
    show?: boolean;
    children: ReactElement;
    render: () => ReactNode;
    onClose?: () => void;
    flexible?: boolean;
    dataQa?: string;
}

export interface DropClickPropsCommon {
    /** Колбек, вызывающийся, когда дроповер закрылся */
    onClose?: () => void;
    /** Элемент инициатор, относительно которого показывать DropOver */
    children: ReactElement<{ onClick?: (e: MouseEvent) => void }>;
    /** Метод-рендер контента */
    render: (closeDrop?: () => void) => ReactNode;
}

interface DropClickProps extends DropClickPropsCommon {
    /** Любой компонент Drop */
    DropElement: FC<BaseDropProps & PropsWithChildren>;
}

const Click: FC<DropClickProps> = ({ DropElement, children, onClose, render, ...props }) => {
    const [show, setShow] = useState(false);

    const closeHandler = useCallback(() => {
        setShow(false);
        onClose?.();
    }, [onClose]);

    const clickHandler = useCallback(
        (e: MouseEvent) => {
            children.props.onClick?.(e);
            setShow(!show);
            if (show) {
                onClose?.();
            }
        },
        [show, onClose, children.props]
    );

    const renderFunc = useCallback(() => render(closeHandler), [render, closeHandler]);

    return (
        <DropElement {...props} show={show} onClose={closeHandler} render={renderFunc}>
            {cloneElement(children, { onClick: clickHandler })}
        </DropElement>
    );
};

export default Click;
