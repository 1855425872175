import $ from 'jquery';

import capitalize from 'bloko/common/capitalize';
import Components from 'bloko/common/core/Components';

export default Components.build({
    /**
     * @exports bloko/blocks/nameCapitalize/nameCapitalize
     *
     * @param element DOM элемент
     * @constructor
     */
    create(element) {
        const $input = $('.Bloko-Name-Capitalize-Input', element);
        const check = function () {
            $input.val((index, value) => {
                return capitalize(value);
            });
        };

        $input.addClass('bloko-input-text_name');
        $input.on('change', check);
        check();
    },
});
