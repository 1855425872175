import { KeyCode } from 'bloko/common/constants/keyboard';
import { Breakpoint, getBreakpoint } from 'bloko/common/media';
import Metrics from 'bloko/common/metrics';
import { BoundingClientRect, TypeElementMetrics } from 'bloko/common/types';

import cssClasses from 'bloko/blocks/suggest/cssClasses';

const MIN_BOTTOM_PADDING = 10;
const MIN_DROPDOWN_HEIGHT = 105;

const normalizeText = (text: string): string => text.trim().toUpperCase();

const isObjectsEqual = (a: Record<string, unknown>, b: Record<string, unknown>): boolean => {
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    if (aProps.length !== bProps.length) {
        return false;
    }

    for (let i = 0; i < aProps.length; i++) {
        const propName = aProps[i];
        if (a[propName] !== b[propName]) {
            return false;
        }
    }

    return true;
};

const createKeyboardHandler = (
    highlight: (index: number) => void,
    hide: () => void,
    show: () => void,
    selectHighlighted: () => void
): ((isHidden: boolean, event: KeyboardEvent, index: number) => void) => {
    return (isHidden, event, index) => {
        if (isHidden) {
            if (event.keyCode === KeyCode.ArrowDown) {
                event.preventDefault();
                show();
            }
            return;
        }

        switch (event.keyCode) {
            case KeyCode.ESC:
                event.preventDefault();
                hide();
                break;
            case KeyCode.ArrowUp:
                event.preventDefault();
                highlight(index - 1);
                break;
            case KeyCode.ArrowDown:
                event.preventDefault();
                highlight(index + 1);
                break;
            case KeyCode.Enter:
                if (index === -1) {
                    break;
                }
                event.preventDefault();
                selectHighlighted();
                break;
        }
    };
};

export interface TransitionStylesType {
    stateStyles: Record<'entering' | 'entered', React.CSSProperties>;
    defaultStyle: React.CSSProperties;
}

const getTransitionStyles = (fadeTime: number): TransitionStylesType => {
    return {
        stateStyles: {
            entering: { opacity: 0 },
            entered: { opacity: 1 },
        },
        defaultStyle: {
            transition: `opacity ${fadeTime}ms ease-in-out`,
            opacity: 0,
        },
    };
};

const setMetrics = (
    elem: TypeElementMetrics,
    dropdown: TypeElementMetrics,
    rightPadding = 0,
    onScreen = false
): BoundingClientRect => {
    const dropdownElem = Metrics.getElement(dropdown);
    const metrics = Metrics.getMetrics(elem);
    const { left, top, height, width } = metrics;

    // Для правильного расчёта максимальной высоты дропдауна
    // он не должен влиять на высоту страницы
    dropdownElem.style.maxHeight = '';
    dropdownElem.style.left = '-9999px';
    dropdownElem.style.top = '-9999px';

    let dropdownMetrics;
    let windowScrollHeight;
    let availableHeight;

    if (onScreen) {
        const metricsOnScreen = Metrics.getBoundingClientRect(elem);
        dropdownMetrics = Metrics.getBoundingClientRect(dropdownElem);
        windowScrollHeight = window.innerHeight;
        availableHeight = windowScrollHeight - metricsOnScreen.top - metricsOnScreen.height - MIN_BOTTOM_PADDING;
    } else {
        dropdownMetrics = Metrics.getMetrics(dropdownElem);
        windowScrollHeight = Metrics.getWindowScrollHeight();
        availableHeight = windowScrollHeight - top - height - MIN_BOTTOM_PADDING;
    }

    // В тестах высота `window` равна 0.
    const windowHasSomeSpace = windowScrollHeight > 0;
    const maxDropdownHeight = Math.max(availableHeight, MIN_DROPDOWN_HEIGHT);

    const maxHeight = windowHasSomeSpace && dropdownMetrics.height > maxDropdownHeight ? maxDropdownHeight : '';

    let css: {
        left: number;
        top: number;
        width: number | string;
        height?: number | string;
        maxHeight?: number | string;
        right?: number | string;
    } = {
        left,
        top: top + height,
        width: width + rightPadding,
        right: '',
        maxHeight,
    };

    if (getBreakpoint() === Breakpoint.XS) {
        css = {
            left: 0,
            top: css.top,
            width: 'auto',
            right: 0,
            maxHeight: onScreen ? maxHeight : '',
        };
    }

    Object.keys(css).forEach((key) => {
        const value = css[key] as string | number;
        const isNumeric = typeof value === 'number' && !isNaN(value);
        dropdownElem.style[key] = isNumeric ? `${value}px` : String(value);
    });

    return metrics;
};

const updateHighlight = (suggest: HTMLElement, index: number): number => {
    const list = [...suggest.querySelectorAll(`.${cssClasses.element}`)] as Array<HTMLElement>;
    const highlightedItem = list.find((el) => el.classList.contains(cssClasses.state.highlighted));

    const MIN = -1;
    const MAX = list.length - 1;
    let newIndex;

    if (index < MIN) {
        newIndex = MAX;
    } else if (index > MAX) {
        newIndex = MIN;
    } else {
        newIndex = index;
    }

    if (highlightedItem && (newIndex === -1 || highlightedItem !== list[newIndex])) {
        highlightedItem.classList.remove(cssClasses.state.highlighted);
    }

    if (newIndex === -1) {
        return -1;
    }

    list[newIndex].classList.add(cssClasses.state.highlighted);

    const dropdownMetrics = Metrics.getMetrics(suggest);
    const itemMetrics = Metrics.getMetrics(list[newIndex]);

    if (!Metrics.isRectangleInRectangle(itemMetrics, dropdownMetrics)) {
        const itemOnTop = list[newIndex].offsetTop < suggest.scrollTop;
        const itemOffsetTop = list[newIndex].offsetTop;
        suggest.scrollTop = itemOnTop
            ? itemOffsetTop
            : itemOffsetTop + list[newIndex].offsetHeight - suggest.offsetHeight;
    }

    return newIndex;
};

export { normalizeText, isObjectsEqual, createKeyboardHandler, getTransitionStyles, setMetrics, updateHighlight };
