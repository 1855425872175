import { IconColor, CrossScaleSmallEnclosedFalse } from 'bloko/blocks/icon';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import IconReactRenderer from 'bloko/blocks/icon/IconReactRenderer';
import Timers from 'bloko/common/Timers';
import animationEventName from 'bloko/common/animationEventName';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Components from 'bloko/common/core/Components';
import Metrics from 'bloko/common/metrics';

import { NotificationKind, NOTIFICATION_EXTRA_TYPES } from 'bloko/blocks/notificationManager/constants';
import notificationTemplate from 'bloko/blocks/notificationManager/notification.mustache';

type Callback = (e?: Event) => void;

const addEventListenerOnce = (element: Element, event: string, callback: Callback) => {
    element.addEventListener(event, function cb(e) {
        element.removeEventListener(event, cb);
        callback(e);
    });
};

const onAnimationEnd = animationEventName
    ? (element: Element, callback: Callback) => {
          animationEventName && addEventListenerOnce(element, animationEventName, callback);
      }
    : (_: Element, callback: Callback) => {
          callback();
      };

const timers = Timers();

const NOTIFICATION_AUTO_CLOSE_TIME_MS = !window.bloko?.isTest ? 5000 : 10;

interface RenderNotificationParams {
    type?: NotificationKind;
    content: string | HTMLElement;
}
const renderNotification = ({ type, content }: RenderNotificationParams) => {
    const fragment = document.createElement('div');
    fragment.innerHTML = notificationTemplate.render({
        type,
        inverted: NOTIFICATION_EXTRA_TYPES.includes(type),
    });
    const notification = fragment.firstElementChild as HTMLElement;

    const contentElement = notification.querySelector('.Bloko-Notification-Content') as HTMLElement;
    if (typeof content === 'string') {
        contentElement.innerHTML = content;
    } else {
        contentElement.appendChild(Metrics.getElement(content));
    }

    return notification;
};

const createNotificationManagerElement = () => {
    const element = document.createElement('div');
    element.className = 'bloko-notification-manager';
    return element;
};

const initNotificationManagerElement = () =>
    document.querySelector('.Bloko-Notification-Manager') ||
    document.body.appendChild(createNotificationManagerElement());

const notificationManagerElement = initNotificationManagerElement();

notificationManagerElement.addEventListener('mouseenter', () => {
    timers.pause();
});

notificationManagerElement.addEventListener('mouseleave', () => {
    timers.resume();
});

type CreateNotification = (
    /** Параметры нотификации */
    params: {
        /** Содержимое, которое будет отображено в теле нотификации */
        content: string | HTMLElement;
        /** Тип нотификации, которую нужно показать: error - ошибка,
         * ok - успех, delete - для удаления, message - действие или
         * сообщение, question - вопрос, rating - оценка, по умолчанию info. */
        type?: NotificationKind;
        /** Функция, которая будет вызвана при закрытии нотификации */
        onClose?: () => void;
        /** Флаг автоматического закрытия нотификации,
         * по умолчанию через 5 секунд */
        autoClose?: boolean;
        /** Время в миллисекундах до автоматического закрытия нотификации */
        autoCloseDelay?: number;
    }
) => { close: () => void };

/**
 * Компонент, который должен быть модулем. Возвращает объект c методом `close`, который закрывает нотификацию и
 * удаляет ее из DOM.
 */
const create: CreateNotification = ({
    content,
    type,
    onClose,
    autoClose,
    autoCloseDelay = NOTIFICATION_AUTO_CLOSE_TIME_MS,
}) => {
    const notificationElement = renderNotification({ content, type });

    let timeout: number;
    if (autoClose) {
        timeout = timers.setTimeout(close, autoCloseDelay);
    }

    const notificationClose = notificationElement.querySelector('.Bloko-Notification-Close');

    if (notificationElement) {
        /* eslint-disable-next-line */
        Components.make(IconReactRenderer, notificationClose, {
            IconComponent: CrossScaleSmallEnclosedFalse,
            iconProps: {
                initial: NOTIFICATION_EXTRA_TYPES.includes(type) ? IconColor.White : IconColor.Gray80,
            },
        });
    }

    notificationElement.addEventListener('click', (event) => {
        if (event.target instanceof Element && event.target.closest('.Bloko-Notification-Close')) {
            timers.clearTimeout(timeout);
            close();
        }
    });

    notificationManagerElement.insertBefore(notificationElement, notificationManagerElement.firstElementChild);

    function close() {
        if (notificationElement.classList.contains('bloko-notification_inactive')) {
            return;
        }

        notificationElement.classList.add('bloko-notification_inactive');
        onAnimationEnd(notificationElement, () => {
            notificationElement.remove();
            onClose?.();
        });
    }

    return { close };
};

/**
 * @exports bloko/blocks/notificationManager/notificationManager
 */
export default { create };
export { NotificationKind };
