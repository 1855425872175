const FALLBACK_DELAY_MULTIPLIER = 1.5;

type ClearFunction = () => void;

/**
 * Парсим значение <time> в миллисекундах
 * @param value {String} значение CSS-свойства, например '-.25ms'
 * @returns {Number}
 */
const duration2ms = (value: string) => {
    const multiplier = value.indexOf('ms') > -1 ? 1 : 1000;
    return parseFloat(value.trim()) * multiplier;
};

/**
 * Из значения CSS-свойства получаем максимальное значение в миллисекундах
 * @param values {String} значение CSS-свойства, например '0.1s, 25ms'
 * @returns {Number}
 */
const getMaxDurationValue = (values: string) => (values ? Math.max(...values.split(',').map(duration2ms)) : 0);

/**
 * Функция для выполнения действия после окончания transition.
 *
 * @param element {Element} элемент
 * @param callback {Function} обработчик который нужно вызвать после завершения анимации
 *
 * @name HH/Transition
 * @constructor
 */
export default (element: HTMLElement, callback: () => void): ClearFunction => {
    const handleEvent = (event: TransitionEvent) => {
        if (event.target !== element) {
            return;
        }
        callbackWrapper();
    };

    element.addEventListener('transitionend', handleEvent);

    let timeout = 0;
    function callbackWrapper() {
        clear();
        callback();
    }

    function clear() {
        window.clearTimeout(timeout);
        element.removeEventListener('transitionend', handleEvent);
    }

    /*
            Получаем значение transition-duration и transition-delay,
            если не отработает transitionEnd — вызовем callback
            через это время + дополнительная задержка.
        */

    const styles = window.getComputedStyle(element);
    const duration = getMaxDurationValue(styles.getPropertyValue('transition-duration'));
    const delay = getMaxDurationValue(styles.getPropertyValue('transition-delay'));

    timeout = window.setTimeout(callbackWrapper, (delay + duration) * FALLBACK_DELAY_MULTIPLIER);

    return clear;
};
