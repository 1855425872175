import React from 'react';
import classnames from 'classnames';
import { Merge } from 'type-fest';

import styles from 'bloko/blocks/radio/radio.less';

export interface RadioProps {
    /** Обработчик onChange, в качестве аргумента передает event */
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    /** Функция для получения рутового DOM элемента компонента Radio*/
    innerRef?: React.Ref<HTMLLabelElement>;
    /** Свойства лейбла radio */
    labelProps?: JSX.IntrinsicElements['span'];
    /** Свойства обертки radio */
    wrapperProps?: JSX.IntrinsicElements['label'];
    /** Флаг checked контрола по умолчанию для uncontrolled radio */
    defaultChecked?: boolean;
    /** Флаг checked контрола */
    checked?: boolean;
    /** Значение по умолчанию для uncontrolled */
    defaultValue?: string;
    /** Значение по умолчанию */
    value?: string;
    /** Флаг disabled контрола */
    disabled?: boolean;
    /** Флаг не валидности контрола */
    invalid?: boolean;
    /** Указывает на строку с компонентом в исходном коде в режиме разработки. Генерируется babel-plugin-react-source */
    source?: string;
}

export type RadioType = React.FC<Merge<JSX.IntrinsicElements['input'], RadioProps> & React.PropsWithChildren>;

const RadioControl: RadioType = (props) => {
    const { children, invalid, innerRef, labelProps, wrapperProps, source, ...radioProps } = props;

    return (
        <label
            className={classnames(styles['bloko-radio'], {
                [styles['bloko-radio_invalid']]: invalid,
            })}
            {...wrapperProps}
            ref={innerRef}
            source={source}
        >
            <input {...radioProps} type="radio" className={styles['bloko-radio__input']} />
            <span {...labelProps} className={styles['bloko-radio__text']}>
                {children}
            </span>
        </label>
    );
};

export default RadioControl;
