import $ from 'jquery';

import { KeyCode } from 'bloko/common/constants/keyboard';
import Mouse from 'bloko/common/constants/mouse';
import Metrics from 'bloko/common/metrics';

import template from 'bloko/blocks/slider/slider.mustache';

const TOP_PIN_Z_INDEX = 4;
const BOTTOM_PIN_Z_INDEX = 3;
let currentMovement = null;

const moveEventHandler = function (event) {
    event.preventDefault();
    currentMovement.process(event, false);
};

// обработка начала pinch to zoom
$(document).on('touchstart', (event) => {
    if (event.originalEvent.touches.length > 1) {
        $(document).off('touchmove', moveEventHandler);
        if (currentMovement) {
            currentMovement.finish();
            currentMovement = null;
        }
    }
});

$(document).on('mouseup touchend', (event) => {
    if (currentMovement) {
        event.preventDefault();
        currentMovement.process(event, true);
        currentMovement = null;
        $(document).off('touchmove mousemove', moveEventHandler);
    }
});

// функция приведения событий к единому виду
const fixEvent = function (customEvent) {
    const e = customEvent.originalEvent;

    const touch = e.type.indexOf('touch') === 0;
    const mouse = e.type.indexOf('mouse') === 0;
    let pointer = e.type.indexOf('pointer') === 0;
    let x;
    let y;
    const event = e;

    if (e.type.indexOf('MSPointer') === 0) {
        pointer = true;
    }

    if (touch) {
        x = e.changedTouches[0].pageX;
        y = e.changedTouches[0].pageY;
    }

    if (mouse || pointer) {
        x = e.clientX;
        y = e.clientY;
    }

    event.points = [x, y];
    return event;
};

// объект инкапсулирующий в себе операцию перемещения одного из пинов
const Movement = function (params) {
    this.params = params;
    this.lastX = Metrics.getRelativeMetrics(params.element).left;
    this.offsetX = params.event.points[0] - this.lastX;
};

Movement.prototype = {
    // обработка перемещения мыши
    process(customEvent, isFinished) {
        const event = fixEvent(customEvent);

        let x = event.points[0] - this.offsetX;
        x = Math.min(
            Math.max(x, this.params.percentsMin, this.params.availablePercentsMin),
            this.params.percentsMax,
            this.params.availablePercentsMax
        );
        this.lastX = x;
        this.params.onChange(x, isFinished);
    },

    finish() {
        this.params.onChange(this.lastX, true);
    },
};

const SliderAbstract = {
    template,

    bindings: {
        leftPin: '.Bloko-Slider-LeftPin',
        rightPin: '.Bloko-Slider-RightPin',
        scaleLine: '.Bloko-Slider-ScaleLine',
        rangeMark: '.Bloko-Slider-RangeMark',
        availableMark: '.Bloko-Slider-AvailableMark',
        labels: '.Bloko-Slider-Label',
    },

    cssClasses: {
        disabled: 'bloko-slider_disabled',
    },

    _init(element, options) {
        this.$element = $(element);
        this.options = $.extend({}, options);

        if (!this.options.available) {
            this.options.available = {
                from: this.options.from,
                to: this.options.to,
            };
        }

        this.cachedUnitMin = null;
        this.cachedUnitMax = null;

        const $markup = $(
            this.template.render({
                marks: this._createMarks(),
                labels: this._createLabels(),
                isRange: this.options.type === 'range',
                hasLabels: this.options.labels && this.options.labels.length,
                isSecondary: this.options.style === 'secondary',
            })
        );

        this.$element.append($markup);

        this.$markup = $markup;

        this._initBindings($markup);
        this._initMetrics();
        this._initEventListeners();

        if (this.options.onChange) {
            this.$markup.on('Bloko-Slider-Change', this.options.onChange);
        }

        if (this.options.onInput) {
            this.$markup.on('Bloko-Slider-Input', this.options.onInput);
        }

        this.unitMin = this.options.available.from;
        this.unitMax = this.options.available.to;

        this.set(this.options.value);
        this.toggleDisabled(this.options.disabled);
    },

    /**
     * Добавляет обработчик события Bloko-Slider-Input
     * @param {function} listener
     */
    onInput(listener) {
        this.$markup.on('Bloko-Slider-Input', listener);
    },

    /**
     * Добавляет обработчик события Bloko-Slider-Change
     * @param {function} listener
     */
    onChange(listener) {
        this.$markup.on('Bloko-Slider-Change', listener);
    },

    /**
     * Метод, размещающий текстовые метки на слайдере
     * @private
     */
    _createLabels() {
        if (!this.options.labels) {
            return null;
        }
        const width = this.options.to - this.options.from;
        const from = this.options.from;
        const available = this.options.available;

        const labels = this.options.labels.map((label) => {
            return {
                offset: ((label[0] - from) / width) * 100,
                text: label[1],
                available: label[0] >= available.from && label[0] <= available.to,
            };
        });
        return labels;
    },

    /**
     * Задает выбранное значение слайдера
     * @param {Array | integer} values выбранное значение или диапазон
     * @param {boolean} isChange флаг, указывающий нужно ли генерировать событие change
     * @private
     */
    _set(values, isChange) {
        values = this._ensureValuesInRange(values);

        this.unitMin = values[0];
        this.unitMax = values[1];

        if (this.unitMin !== this.options.available.from) {
            this.unitMin = Math.round(this.unitMin / this.options.stepSize) * this.options.stepSize;
        }

        if (this.unitMax !== this.options.available.to) {
            this.unitMax = Math.round(this.unitMax / this.options.stepSize) * this.options.stepSize;
        }

        this.unitMin = this._inRange(this.unitMin, [this.options.available.from, this.unitMax]);
        this.unitMax = this._inRange(this.unitMax, [this.unitMin, this.options.available.to]);

        this.percentsMin = this._convertUnitsToPercents(this.unitMin);
        this.percentsMax = this._convertUnitsToPercents(this.unitMax);

        this.availablePercentsMin = this._convertUnitsToPercents(this.options.available.from);
        this.availablePercentsMax = this._convertUnitsToPercents(this.options.available.to);

        this._render();

        /**
         * Вызывается на элементе во время изменения значения слайдера
         * @event Bloko-Slider-Input
         */
        this.$markup.trigger('Bloko-Slider-Input', this.get());

        if (isChange && (this.cachedUnitMin !== this.unitMin || this.unitMax !== this.cachedUnitMax)) {
            /**
             * Вызывается на элементе после завершения изменения значения слайдера
             * @event Bloko-Slider-Change
             */
            this.$markup.trigger('Bloko-Slider-Change', this.get());
            this.cachedUnitMin = this.unitMin;
            this.cachedUnitMax = this.unitMax;
        }
        // Если левый пин в крайнем правом значении, позиционируем его над правым для перетаскивания
        if (this.percentsMin === 100) {
            this.$leftPin.css('z-index', TOP_PIN_Z_INDEX);
        }
    },

    /**
     * Устанавливает значение слайдера
     * @param {Number|Array} values Целое число либо массив из двух целых чисел в зависимости от состояния
     *                              флага инициализации range. Если в качестве одного из значений массива
     *                              передан `null`, то соответствующее значение останется без изменений.
     *                              Если в метод передано недопустимое значение, то оно будет приведено к
     *                              ближайшему к нему значению из доступного диапазона значений
     */
    set(values) {
        if (this.disabled) {
            return;
        }
        this._set(this._ensureValuesValid(values), true);
    },

    /**
     * Устанавливает диапазон доступных для выбора значений слайдера
     * @param {SliderRange} available Новый диапазон. Значения, выходящие за границы
     *                                `params.from`–`params.to`, заменяются значениями границ.
     */
    setAvailable(available) {
        this.options.available.from = this._inRange(available.from, [this.options.from, this.options.to]);
        this.options.available.to = this._inRange(available.to, [this.options.from, this.options.to]);

        this._set(this.get(), true);
    },

    /**
     * Устанавливает состояние компонента – активен/неактивен
     * @param {boolean} disabled состояние активности компонента
     */
    toggleDisabled(disabled) {
        this.disabled = typeof disabled === 'undefined' ? !this.disabled : disabled;
        this.$scaleLine.toggleClass(this.cssClasses.disabled, this.disabled);
        this.$leftPin.prop('disabled', this.disabled);
        this.$rightPin.prop('disabled', this.disabled);
    },

    /**
     * Вычисляет новую позицию пина, при сдвиге с помощью клавиатуры
     * @param event событие нажатия клавиши
     * @param startValue начальное положение пина
     * @returns новое положение пина
     * @private
     */
    _moveByKeyboard(event, startValue) {
        let step = this.options.stepSize;

        // если нажат shift увеличиваем размер шага в 10 раз,
        // но он не должен быть более 10% от всей длины шкалы
        if (event.shiftKey) {
            step *= 10;
            step = Math.min((this.options.to - this.options.from) / 10, step);
            step = Math.round(step / this.options.stepSize) * this.options.stepSize;
            step = Math.max(step, this.options.stepSize);
        }

        let value = startValue;
        switch (event.keyCode) {
            case KeyCode.ArrowDown:
            case KeyCode.ArrowLeft:
                value -= step;
                event.preventDefault();
                break;
            case KeyCode.ArrowUp:
            case KeyCode.ArrowRight:
                value += step;
                event.preventDefault();
                break;
        }

        return value;
    },

    /**
     * Возвращает число, ближайшее к переданному, находящееся в заданном диапазоне
     * @param {number} value - число, ближайшее к которому необходимо вернуть
     * @param {array} range - допустимый диапазон результата
     * @returns {number}
     * @private
     */
    _inRange(value, range) {
        return Math.max(Math.min(value, range[1]), range[0]);
    },

    /**
     * Инициализация обработчиков событий
     * @private
     */
    _initEventListeners() {
        const $pins = this.$leftPin.add(this.$rightPin);
        $pins.on('focus touchstart mousedown', (event) => {
            $pins.css('z-index', BOTTOM_PIN_Z_INDEX);
            $(event.target).css('z-index', TOP_PIN_Z_INDEX);
        });

        this.$scaleLine.on('click', this._onClick.bind(this));

        this.$leftPin.on('touchstart mousedown', this._createMovement.bind(this, true));
        this.$rightPin.on('touchstart mousedown', this._createMovement.bind(this, false));

        this.$leftPin.on('keydown', this._keyboardControl.bind(this, true));
        this.$rightPin.on('keydown', this._keyboardControl.bind(this, false));
    },

    /**
     * Инициализация переменных разметки
     * @param {jQuery} $markup - разметка компонента
     * @private
     */
    _initBindings($markup) {
        this.$leftPin = $(this.bindings.leftPin, $markup);
        this.$rightPin = $(this.bindings.rightPin, $markup);
        this.$scaleLine = $markup;
        this.$rangeMark = $(this.bindings.rangeMark, $markup);
        this.$availableMark = $(this.bindings.availableMark, $markup);
    },

    /**
     * Отрисовка компонента
     * @private
     */
    _render() {
        this.$leftPin.css('left', `${this.percentsMin}%`);
        this.$rightPin.css('left', `${this.percentsMax}%`);
        this.$rangeMark.css({
            left: `${this.percentsMin}%`,
            right: `${100 - this.percentsMax}%`,
        });
        this.$availableMark.css({
            left: `${this.availablePercentsMin}%`,
            right: `${100 - this.availablePercentsMax}%`,
        });
    },

    /**
     * Инициализация переменных метрик элементов компонента
     * @private
     */
    _initMetrics() {
        if (this.options.type === 'range') {
            this.leftPinMetrics = Metrics.getRelativeMetrics(this.$leftPin.get(0));
        }
        this.rightPinMetrics = Metrics.getRelativeMetrics(this.$rightPin.get(0));
        const scaleLineMetrics = Metrics.getMetrics(this.$scaleLine.get(0));
        this.scaleLineSize = scaleLineMetrics.width;
        this.scaleLinePosition = scaleLineMetrics.left;
        this.unitsInPixel = (this.options.to - this.options.from) / this.scaleLineSize;
    },

    /**
     * Обработка начала перетаскивания пина
     * @param {boolean} isForLeft - флаг, указывающий для какого из пинов начато перетаскивание
     * @param {Event} event - событие, сгенерированное при нажатии мыши на пине
     * @private
     */
    _createMovement(isForLeft, customEvent) {
        if (this.disabled || customEvent.button === Mouse.BUTTON_RIGHT) {
            return;
        }

        $(document).on('touchmove mousemove', moveEventHandler);
        this._initMetrics();
        const event = fixEvent(customEvent);

        const options = this._createMovementOptions(isForLeft);
        options.onChange = this._pinMoved.bind(this, isForLeft);
        options.event = event;
        options.availablePercentsMin = (this.options.available.from - this.options.from) / this.unitsInPixel;
        options.availablePercentsMax = (this.options.available.to - this.options.from) / this.unitsInPixel;

        currentMovement = new Movement(options);
    },

    /**
     * Добавляет метки шагов на слайдер
     * @private
     */
    _createMarks() {
        if (!this.options.showMarks) {
            return null;
        }

        const marks = [];
        for (let i = this.options.from + this.options.stepSize; i < this.options.to; i += this.options.stepSize) {
            marks.push({ offset: this._convertUnitsToPercents(i) });
        }
        return marks;
    },

    /**
     * Обработчик клика мыши по компоненту
     * @param {Event} event
     * @private
     */
    _onClick(event) {
        if (this.disabled || event.target === this.$leftPin[0] || event.target === this.$rightPin[0]) {
            return;
        }

        this._initMetrics();

        const pageXOffset =
            typeof window.pageXOffset === 'undefined' ? document.documentElement.scrollLeft : window.pageXOffset;
        const clickPosition = event.clientX + pageXOffset - this.scaleLinePosition;
        const unitPos = this._convertPxToUnits(clickPosition);

        this._setPositionByClick(clickPosition, unitPos);
    },

    /**
     * Конвертирует пиксельную координату сдвига относительно левого края компонента в значение из заданного
     * для компенента диапазона допустимых значений
     * @param {number} px
     * @returns {number}
     * @private
     */
    _convertPxToUnits(px) {
        return px * this.unitsInPixel + this.options.from;
    },

    /**
     * Конвертирует значение из заданного для компонента диапазона допустимых значений в значение сдвига
     * относительно левого края компонента в процентах
     * @param units
     * @returns {number}
     * @private
     */
    _convertUnitsToPercents(units) {
        return ((units - this.options.from) / (this.options.to - this.options.from)) * 100;
    },

    /**
     * Возвращает текущее выбранное значение слайдера.
     * В зависимости от флага инициализации `range` возвращает
     * либо массив из двух целых чисел, либо целое число.
     * @function get
     */
};

export default SliderAbstract;
