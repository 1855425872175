import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const CheckmarkScaleSmallKindSingleAppearanceOutlinedEnclosedTrue = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g>
                <path
                    d="M4.99967 7.99998L6.99967 9.99998L10.9997 5.99998M14.6663 7.99998C14.6663 11.6819 11.6816 14.6666 7.99967 14.6666C4.31778 14.6666 1.33301 11.6819 1.33301 7.99998C1.33301 4.31808 4.31778 1.33331 7.99967 1.33331C11.6816 1.33331 14.6663 4.31808 14.6663 7.99998Z"
                    stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                    strokeWidth={1.33333}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    ),
    'Checkmark'
);
export default CheckmarkScaleSmallKindSingleAppearanceOutlinedEnclosedTrue;
