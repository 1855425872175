import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const CameraScaleSmall = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M15.3332 12.6667C15.3332 13.0203 15.1927 13.3594 14.9426 13.6095C14.6926 13.8595 14.3535 14 13.9998 14H1.99984C1.64622 14 1.30708 13.8595 1.05703 13.6095C0.80698 13.3594 0.666504 13.0203 0.666504 12.6667V5.33333C0.666504 4.97971 0.80698 4.64057 1.05703 4.39052C1.30708 4.14048 1.64622 4 1.99984 4H4.6665L5.99984 2H9.99984L11.3332 4H13.9998C14.3535 4 14.6926 4.14048 14.9426 4.39052C15.1927 4.64057 15.3332 4.97971 15.3332 5.33333V12.6667Z"
                stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                strokeWidth={1.33333}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.99984 11.3333C9.4726 11.3333 10.6665 10.1394 10.6665 8.66667C10.6665 7.19391 9.4726 6 7.99984 6C6.52708 6 5.33317 7.19391 5.33317 8.66667C5.33317 10.1394 6.52708 11.3333 7.99984 11.3333Z"
                stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                strokeWidth={1.33333}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ),
    'Camera'
);
export default CameraScaleSmall;
