import { ComponentWithCustomElement } from 'bloko/common/helpers/types';

import { AllowedElements } from 'bloko/blocks/column/common';

import styles from 'bloko/blocks/column/column.less';

interface ColumnsRowProps {
    /** Кастомный компонент функция или [доступные теги](#invariants)*/
    Element?: AllowedElements;
    /** Указывает на строку с компонентом в исходном коде в режиме разработки. Генерируется babel-plugin-react-source */
    source?: string;
}

const ColumnsRow: ComponentWithCustomElement<ColumnsRowProps, 'div'> = ({ Element = 'div', ...columnRowProps }) => (
    <Element className={styles['bloko-columns-row']} {...columnRowProps} />
);

export default ColumnsRow;
