/**
 * Возвращает функцию для создания объекта-обертки
 * для работы с (local|session)Storage,
 * где каждый метод оборачивается в try-catch
 * из-за возможных ошибок:
 *
 * — cookies отключены — Security error<br/>
 * — браузер старый и не поддерживает (local|session)Storage<br/>
 * — превышен лимит 5мб (в том числе в private mode в Safari)<br/>
 * — файл sqlite поврежден (NS_ERROR_FILE_CORRUPTED в Firefox)<br/>
 *
 * @param {Object} storage (local|session)Storage
 *
 * @name bloko/common/storage/Wrapper
 * @type {Function}
 *
 * @returns {Object}
 */
const wrapper = function (storage) {
    return {
        /**
         * Возвращает имя i-го ключа в (local|session)Storage
         * @param   {Number} index Порядковый номер ключа
         * @returns {String|Null}  Имя ключа или null в случае отсутствия такового или ошибки
         *
         * @member
         * @method
         */
        key(index) {
            try {
                return storage.key(index);
            } catch (e) {
                return null;
            }
        },

        /**
         * Возвращает значение по ключу
         * @param   {String} key  Имя ключа
         * @returns {String|Null} Значение
         *
         * @member
         * @method
         */
        getItem(key) {
            try {
                return storage.getItem(key);
            } catch (e) {
                return null;
            }
        },

        /**
         * Добавляет ключ в storage или обновляет его значение, если такой уже существует
         * @param {String} key   Имя ключа
         * @param {String} value Значение
         *
         * @member
         * @method
         */
        setItem(key, value) {
            try {
                storage.setItem(key, value);
            } catch (e) {
                // iPad and QUOTA_EXCEEDED_ERR - try to remove this item before
                // http://stackoverflow.com/questions/2603682/
                try {
                    storage.removeItem(key);
                    storage.setItem(key, value);
                } catch (ex) {
                    // storage is unavailable by some reason
                }
            }
        },

        /**
         * Удаляет ключ из (local|session)Storage
         * @param {String} key Имя ключа
         *
         * @member
         * @method
         */
        removeItem(key) {
            try {
                storage.removeItem(key);
            } catch (e) {
                // storage is unavailable by some reason
            }
        },

        /**
         * Удаляет все ключи из (local|session)Storage
         *
         * @member
         * @method
         */
        clear() {
            try {
                storage.clear();
            } catch (e) {
                // storage is unavailable by some reason
            }
        },

        /**
         * Возвращает число ключей в (local|session)Storage
         * @returns {Number}
         *
         * @member
         * @method
         */
        getLength() {
            try {
                return storage.length;
            } catch (e) {
                return 0;
            }
        },
    };
};

export default wrapper;
