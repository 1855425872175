import React, { useRef, useState, RefObject } from 'react';

import { getAdaptiveSettings, getClientX, isScrollExceedsThreshold } from 'bloko/common/adaptiveTabsHelper';
import RequestAnimation from 'bloko/common/requestAnimation';

interface UseScrollArgs {
    tabsTrainRef: RefObject<HTMLElement>;
    tabsItemsRef: RefObject<HTMLElement>;
    tabsWrapperRef: RefObject<HTMLElement>;
    setShowedLeftGlare: (showGlare: boolean) => void;
    setShowedRightGlare: (showGlare: boolean) => void;
}

interface UseScrollResult {
    isScrollStarted: boolean;
    isScrollOngoing: boolean;
    onScrollStart: (event: React.MouseEvent | React.TouchEvent) => void;
}

export default ({
    tabsTrainRef,
    tabsItemsRef,
    tabsWrapperRef,
    setShowedLeftGlare,
    setShowedRightGlare,
}: UseScrollArgs): UseScrollResult => {
    const scrollOnThreshold = useRef(false);
    const startMarginLeft = useRef(0);
    const startScrollX = useRef(0);
    const [isScrollStarted, setIsScrollStarted] = useState(false);
    const [isScrollOngoing, setIsScrollOngoing] = useState(false);

    const scrollTabs = RequestAnimation((event: MouseEvent | TouchEvent) => {
        if (!tabsTrainRef.current || !tabsWrapperRef.current || !tabsItemsRef.current || !startScrollX.current) {
            return;
        }
        const endScrollX = getClientX(event);

        const scrollMetrics = getAdaptiveSettings({
            tabsWrapperElement: tabsWrapperRef.current,
            tabsItemsElement: tabsItemsRef.current,
            tabsMarginLeft: startMarginLeft.current + endScrollX - startScrollX.current,
        });

        tabsTrainRef.current.style.marginLeft = `${scrollMetrics.tabsMarginLeft}px`;
        setShowedLeftGlare(scrollMetrics.showLeftGlare);
        setShowedRightGlare(scrollMetrics.showRightGlare);
    });

    const onScroll = (event: MouseEvent | TouchEvent) => {
        if (!scrollOnThreshold.current) {
            scrollTabs(event);
            return;
        }

        setIsScrollStarted(true);
        if (isScrollExceedsThreshold(startScrollX.current, event)) {
            scrollOnThreshold.current = false;
            setIsScrollOngoing(true);
        }
    };

    return {
        isScrollStarted,
        isScrollOngoing,
        onScrollStart: (event) => {
            scrollOnThreshold.current = true;
            setIsScrollStarted(false);
            setIsScrollOngoing(false);
            startScrollX.current = getClientX(event);
            if (tabsTrainRef.current?.style.marginLeft) {
                startMarginLeft.current = parseInt(tabsTrainRef.current.style.marginLeft, 10);
            }

            const onScrollEnd = () => {
                setIsScrollStarted(false);
                setIsScrollOngoing(false);
                document.removeEventListener('mousemove', onScroll);
                document.removeEventListener('touchmove', onScroll);
                document.removeEventListener('mouseup', onScrollEnd);
                document.removeEventListener('touchend', onScrollEnd);
            };

            document.addEventListener('mousemove', onScroll);
            document.addEventListener('touchmove', onScroll);
            document.addEventListener('mouseup', onScrollEnd);
            document.addEventListener('touchend', onScrollEnd);
        },
    };
};
