import { BoundingClientRect } from 'bloko/common/types';

import { MIN_SIZE_TO_PLACE_DROP_AT_THE_START, OFFSET_TO_ARROW_X, OFFSET_TO_ARROW_Y } from 'bloko/blocks/drop/constants';
import { Alignment } from 'bloko/blocks/drop/types';

interface AlignParams {
    elementMetrics: BoundingClientRect;
    dropElementMetrics: BoundingClientRect;
    alignToActivatorBorders: boolean;
}

export default {
    x: {
        start({ elementMetrics, alignToActivatorBorders }: AlignParams): Alignment {
            if (alignToActivatorBorders) {
                return { left: elementMetrics.left };
            }

            if (elementMetrics.width >= MIN_SIZE_TO_PLACE_DROP_AT_THE_START) {
                return { left: elementMetrics.left };
            }

            return { left: elementMetrics.left + elementMetrics.width / 2 - OFFSET_TO_ARROW_X };
        },
        center({ elementMetrics, dropElementMetrics }: AlignParams): Alignment {
            return {
                left: elementMetrics.left + (elementMetrics.width - dropElementMetrics.width) / 2,
            };
        },
        end({ elementMetrics, dropElementMetrics, alignToActivatorBorders }: AlignParams): Alignment {
            if (alignToActivatorBorders) {
                return { left: elementMetrics.right - dropElementMetrics.width };
            }

            if (elementMetrics.width >= MIN_SIZE_TO_PLACE_DROP_AT_THE_START) {
                return { left: elementMetrics.right - dropElementMetrics.width };
            }

            return {
                left: elementMetrics.left + elementMetrics.width / 2 - dropElementMetrics.width + OFFSET_TO_ARROW_X,
            };
        },
    },
    y: {
        start({ elementMetrics, alignToActivatorBorders }: AlignParams): Alignment {
            if (alignToActivatorBorders) {
                return { top: elementMetrics.top };
            }

            if (elementMetrics.height >= MIN_SIZE_TO_PLACE_DROP_AT_THE_START) {
                return { top: elementMetrics.top };
            }

            return { top: elementMetrics.top + elementMetrics.height / 2 - OFFSET_TO_ARROW_Y };
        },
        center({ elementMetrics, dropElementMetrics }: AlignParams): Alignment {
            return {
                top: elementMetrics.top + (elementMetrics.height - dropElementMetrics.height) / 2,
            };
        },
        end({ elementMetrics, dropElementMetrics, alignToActivatorBorders }: AlignParams): Alignment {
            if (alignToActivatorBorders) {
                return { top: elementMetrics.bottom - dropElementMetrics.height };
            }

            if (elementMetrics.height >= MIN_SIZE_TO_PLACE_DROP_AT_THE_START) {
                return { top: elementMetrics.bottom - dropElementMetrics.height };
            }

            return {
                top: elementMetrics.top + elementMetrics.height / 2 - dropElementMetrics.height + OFFSET_TO_ARROW_Y,
            };
        },
    },
};
