import { useCallback, useEffect, useRef, useState } from 'react';

import { isEqualArrays } from 'bloko/blocks/treeSelector/utils';

interface UseExpandedHookProps {
    initialValue: string[];
    controlledExpanded?: string[];
    onExpand?: (expanded: string[]) => void;
}
interface UseExpandedHook {
    (props: UseExpandedHookProps): [string[], (expanded: string[]) => void, (id: string) => void];
}

const useExpanded: UseExpandedHook = ({ initialValue, controlledExpanded, onExpand }) => {
    const [expanded, setExpanded] = useState(initialValue);
    const expandedRef = useRef(initialValue);
    const handlerRef = useRef(onExpand);

    const handleSetExpanded = useCallback((updatedExpanded: string[]) => {
        handlerRef.current?.(updatedExpanded.slice());

        if (!isEqualArrays(expandedRef.current, updatedExpanded)) {
            setExpanded(updatedExpanded);
        }
    }, []);

    const handleExpansion = useCallback(
        (id: string): void => {
            let updatedExpanded;
            if (expandedRef.current.includes(id)) {
                updatedExpanded = expandedRef.current.filter((itemId) => itemId !== id);
            } else {
                updatedExpanded = expandedRef.current.slice();
                updatedExpanded.push(id);
            }
            handleSetExpanded(updatedExpanded);
        },
        [handleSetExpanded]
    );

    useEffect(() => {
        handlerRef.current = onExpand;
    }, [onExpand]);

    useEffect(() => {
        expandedRef.current = expanded;
    }, [expanded]);

    useEffect(() => {
        if (controlledExpanded) {
            handleSetExpanded(controlledExpanded.slice());
        }
    }, [controlledExpanded, handleSetExpanded]);

    return [expanded, handleSetExpanded, handleExpansion];
};

export default useExpanded;
