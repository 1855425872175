import { forwardRef, useCallback } from 'react';
import { Merge } from 'type-fest';

import { InputChangeHandler } from 'bloko/blocks/inputText';
import NumericInput, { NumericInputProps } from 'bloko/blocks/numericInput';
import calculateDelimiters from 'bloko/blocks/numericInput/calculateDelimiters';
import NumberFormatter from 'bloko/common/numberFormatter';
import requestAnimation from 'bloko/common/requestAnimation';
import Supports from 'bloko/common/supports';
import TextSelection from 'bloko/common/textSelection';

import getInputMaxLength from 'bloko/blocks/formattedNumericInput/getInputMaxLength';

// В андроиде и винфонах много проблем:
// * При форматировании с пробелами при наборе клавиатура меняется на буквенную в хроме (Sony xperia D5803)
// * При наборе каретка перескакивает по совершенно иному алгоритму (Samsung SM-T230, Samsung GT-N7100)
// * В винфонах каретка перескакивает в конец при редактировании числа со сложным форматированием
// Для этих устройств делаем фолбек на numericInput
const formatterDisabled = typeof window !== 'undefined' && (Supports.android() || Supports.IEMobile());

type FormattedNumericInputProps = Merge<
    NumericInputProps,
    {
        /** Значение input без форматирования */
        value?: string | number;
        /** Количество символов в дробной части */
        decimalLength?: number;
        /** Символ разделителя целой и дробной части */
        decimalMark?: string;
        /** Обработчик изменений input */
        onChange: InputChangeHandler;
        /** Разделитель групп чисел */
        groupSeparator?: string;
        /** Количество цифр в группе */
        groupSize?: number;
    }
>;

const FormattedNumericInput = forwardRef<HTMLInputElement, FormattedNumericInputProps>(
    (
        {
            value,
            onChange,
            groupSeparator = '\u2009',
            groupSize = 3,
            decimalMark = ',',
            decimalLength,
            allowNegative,
            ...inputProps
        },
        ref
    ) => {
        const formatToNumber = useCallback(
            (value: string) =>
                NumberFormatter.format(value, {
                    groupSeparator: '',
                    decimalMark: '.',
                    decimalLength,
                    allowNegative,
                }),
            [allowNegative, decimalLength]
        );

        const formatValue = useCallback(
            (inputValue: string | number | undefined) => {
                const stringInputValue = String(inputValue);
                let formattedValue = NumberFormatter.format(stringInputValue, {
                    groupSeparator: formatterDisabled ? '' : groupSeparator,
                    groupSize,
                    decimalMark,
                    allowNegative,
                    decimalLength,
                });

                const lastChar = stringInputValue.charAt(stringInputValue.length - 1);

                if (lastChar === decimalMark) {
                    formattedValue += lastChar;
                }
                return formattedValue;
            },
            [groupSeparator, groupSize, decimalMark, allowNegative, decimalLength]
        );

        const setCaretPosition = useCallback(
            (input: HTMLInputElement, formattedValue: string) => {
                let caretPosition =
                    TextSelection.getCaretPosition(input) +
                    calculateDelimiters(input.value, formattedValue, groupSeparator);
                if (formatValue(value) === formattedValue) {
                    caretPosition -= 1;
                }
                requestAnimation(() => TextSelection.setCaretPosition(input, caretPosition))();
            },
            [value, formatValue, groupSeparator]
        );

        const handleChange = useCallback<InputChangeHandler>(
            (value, { element }) => {
                if (!formatterDisabled) {
                    setCaretPosition(element, formatValue(value));
                }

                const lastChar = value.charAt(value.length - 1);
                const outputValue = formatToNumber(value) + (lastChar === decimalMark ? lastChar : '');

                onChange(outputValue, { element });
            },
            [decimalMark, setCaretPosition, formatValue, formatToNumber, onChange]
        );

        if (inputProps.maxLength) {
            inputProps.maxLength = getInputMaxLength(
                inputProps.maxLength,
                groupSeparator,
                groupSize,
                formatterDisabled
            );
        }

        return (
            <NumericInput
                ref={ref}
                value={formatValue(value)}
                decimalMark={decimalMark}
                decimalLength={decimalLength}
                onChange={handleChange}
                groupSeparator={groupSeparator}
                allowNegative={allowNegative}
                {...inputProps}
            />
        );
    }
);

export default FormattedNumericInput;
