import { ComponentPropsWithoutRef, FC } from 'react';
import { Merge } from 'type-fest';

import RadioControl from 'bloko/blocks/radio/RadioControl';

interface RadioReversedControlProps {
    /** Располагает radio-контрол справа от контента */
    reversed?: boolean;
}

const Radio: FC<Merge<ComponentPropsWithoutRef<typeof RadioControl>, RadioReversedControlProps>> = ({
    children,
    reversed,
    ...props
}) => {
    if (!reversed) {
        return <RadioControl {...props}>{children}</RadioControl>;
    }

    return (
        <label className="bloko-radio-reversed-wrapper">
            <div suppressHydrationWarning>{children}</div>
            <div className="bloko-radio-reversed-wrapper__control">
                <RadioControl {...props} />
            </div>
        </label>
    );
};

export default Radio;
