import { ComponentWithCustomElement } from 'bloko/common/helpers/types';

import styles from 'bloko/blocks/form/form.less';

interface FormSeparatorProps {
    /** Кастомный компонент или ('div', 'span') */
    Element?: 'div' | 'span';
}

const FormSeparator: ComponentWithCustomElement<FormSeparatorProps, 'div'> = ({
    Element = 'div',
    children,
    ...formGroupProps
}) => {
    return (
        <Element {...formGroupProps} className={styles['bloko-form-separator']}>
            {children}
        </Element>
    );
};

export default FormSeparator;
