import $ from 'jquery';
import _ from 'underscore';

import Modal from 'bloko/blocks/modal/modal';
import ModalError from 'bloko/blocks/modal/modalError';
import TreeSelector from 'bloko/blocks/treeSelector/treeSelector';
import TreeSelectorHelper from 'bloko/blocks/treeSelector/treeSelectorHelper';
import Components from 'bloko/common/core/Components';
import Events from 'bloko/common/events';
import LoadingSetter from 'bloko/common/loadingSetter';
import { Breakpoint, getBreakpoint } from 'bloko/common/media';

import ResultList from 'bloko/blocks/treeSelectorPopup/treeSelectorPopupResultList';
import treeSelectorPopupSearchTemplate from 'bloko/blocks/treeSelectorPopup/treeSelectorPopupSearchTemplate.mustache';
import treeSelectorPopupTemplate from 'bloko/blocks/treeSelectorPopup/treeSelectorPopupTemplate.mustache';

const THROTTLED_TIMEOUT = 150;

/**
 * Триггерится при подтверждении изменений в TreeSelector.
 * Аргументом передается объект с JSON представлением модели узлов или элементов, которые были изменены.
 * В [формате](treeSelector.html#Контракт-для-JSON) TreeSelector.
 *
 * @event Bloko-TreeSelectorPopup-Changed
 * @event Bloko-TreeSelectorPopup-SelectedChanged
 */

/**
 * @exports bloko/blocks/treeSelectorPopup/treeSelectorPopup
 *
 * @param {Object} options
 * @param {Object} [options.treeSelector]              Параметры компонента TreeSelector.
 *                                                     [Документация](treeSelector.html#Параметры)
 * @param {Boolean} [options.search=true]              Флаг, включен ли поиск по TreeSelector
 * @param {Object} [options.searchTreeSelector=null]   Параметры компонента TreeSelector для поисковой выдачи.
 *                                                     Используется, если представление дерева для поиска отличается от дерева в `options.treeSelector`
 * @param {Boolean} [options.autoHide=true]            Флаг, разрешено ли закрывать попап по кнопке подтверждения
 * @param {String} options.trl.submit                  Перевод кнопки подтверждения
 * @param {String} options.trl.cancel                  Перевод кнопки отмены
 * @param {String} [options.trl.searchPlaceholder]     Перевод плейсхолдера для поиска
 * @param {String} [options.trl.notFound]              Перевод подсказки «не найдено»
 * @param {String} [options.trl.errorMessage]          Перевод сообщения об ошибке
 * @param {String} [options.title]                     Заголовок
 * @constructor
 */
const TreeSelectorPopup = function (options) {
    const $el = $(options.el);
    const isSearch = options.search === undefined ? true : !!options.search;
    const searchTreeSelectorOptions = options.searchTreeSelector || null;
    const isAutoHide = options.autoHide === undefined ? true : !!options.autoHide;
    const showResultList = !!options.showResultList;
    let saveChanges = false;

    $el.append(
        treeSelectorPopupTemplate.render($.extend(options.trl, { title: options.title }), {
            search: isSearch ? treeSelectorPopupSearchTemplate : null,
        })
    );

    const $treeSelectorElement = $('.Bloko-TreeSelectorPopup', $el);
    const $searchTreeSelectorElement = $('.Bloko-TreeSelectorPopup-SearchTreeSelector', $el);
    const $resultElement = $('.Bloko-TreeSelectorPopup-Result', $el);
    const $resultContainer = $('.Bloko-TreeSelectorPopup-ResultContainer', $el);
    const $resultContainerXS = $('.Bloko-TreeSelectorPopup-ResultContainerXS', $el);
    const $submit = $('.Bloko-TreeSelectorPopup-Submit', $el);
    $('.Bloko-TreeSelectorPopup-Cancel', $el).on('click', onCancel);

    const modalInstance = Components.make(Modal, $el.get(0));

    const treeSelector = Components.make(TreeSelector, $treeSelectorElement.get(0), options.treeSelector);
    const searchTreeSelector = searchTreeSelectorOptions
        ? Components.make(TreeSelector, $searchTreeSelectorElement.get(0), {
              ...options.treeSelector,
              ...searchTreeSelectorOptions,
          })
        : null;
    let visibleTreeSelector = treeSelector;
    let invisibleTreeSelector = searchTreeSelector;

    const resultList = initResultList();
    let isSearching = false;

    const loadingSetter = new LoadingSetter($submit);

    const publicInterface = Events.extend({
        treeSelector,
        searchTreeSelector,
        modal: modalInstance,
        loadingSetter,
        setErrorMessage,
        showError,
        hideError,
        disableSubmit,
        enableSubmit,
        submitPopup,
        setHintElement,
        setSelected,
        change,
        toggleDisabled,
    });

    function setHintElement(node) {
        $('.Bloko-TreeSelectorPopup-Hint', $el).empty().append(node);
    }

    const $treeSelectorItems = $('.Bloko-TreeSelector-Element', $treeSelectorElement);
    const $searchTreeSelectorItems = $('.Bloko-TreeSelector-Element', $searchTreeSelectorElement);
    const $notFoundHint = $('.Bloko-TreeSelectorPopup-NotFoundHint', $el);
    const $error = $('.Bloko-TreeSelector-Error', $el);
    let changedItems = [];
    let selectedItems;

    const modalErrorInstance = Components.make(ModalError, $error.get(0), {
        message: options.trl && options.trl.errorMessage ? options.trl.errorMessage : '',
    });

    if (isSearch) {
        $('.Bloko-TreeSelectorPopup-Search', $el).on(
            'input change',
            _.throttle((event) => {
                if (shouldToggleTreeSelector(event.target.value)) {
                    toggleTreeSelector();
                }

                visibleTreeSelector.filterByContent(event.target.value);
                $notFoundHint.toggleClass('g-hidden', isFound());
                isSearching = event.target.value?.trim();
                resultList?.toggle(!isSearching && visibleTreeSelector.getSelected().length > 0);
            }, THROTTLED_TIMEOUT)
        );
    }

    modalInstance.on('showed', showPopup);
    modalInstance.on('hid', hidPopup);

    visibleTreeSelector.on('Bloko-TreeSelector-Changed', changedTreeSelector);
    $submit.on('click', onSubmit);

    function onCancel() {
        modalInstance.hide();
    }

    function onSubmit() {
        if (isAutoHide) {
            saveChanges = true;
            modalInstance.hide();
        }

        publicInterface._trigger('Bloko-TreeSelectorPopup-Changed', changedItems);
    }

    function shouldToggleTreeSelector(searchValue) {
        if (!searchTreeSelectorOptions) {
            return false;
        }

        const trimmedValue = searchValue?.trim();
        return (
            (trimmedValue && visibleTreeSelector !== searchTreeSelector) ||
            (!trimmedValue && visibleTreeSelector === searchTreeSelector)
        );
    }

    function isFound() {
        const itemsElement = visibleTreeSelector === searchTreeSelector ? $searchTreeSelectorItems : $treeSelectorItems;
        return !!itemsElement.not('.g-hidden').length;
    }

    function toggleTreeSelector() {
        visibleTreeSelector.off('Bloko-TreeSelector-Changed', changedTreeSelector);

        const tempTreeSelector = visibleTreeSelector;
        visibleTreeSelector = invisibleTreeSelector;
        invisibleTreeSelector = tempTreeSelector;

        visibleTreeSelector.on('Bloko-TreeSelector-Changed', changedTreeSelector);

        $treeSelectorElement.toggleClass('g-hidden');
        $searchTreeSelectorElement.toggleClass('g-hidden');
    }

    /**
     * Сабмитит изменения в popup
     *
     * @param {Boolean} fireEvent нужно ли триггерить событие `Bloko-TreeSelectorPopup-Changed`
     * @fires Bloko-TreeSelectorPopup-Changed
     */
    function submitPopup(fireEvent) {
        if (fireEvent) {
            publicInterface._trigger('Bloko-TreeSelectorPopup-Changed', changedItems);
        }
        saveChanges = true;
        modalInstance.hide();
    }

    function changedTreeSelector(items) {
        changedItems = changedItems.concat(items);
        selectedItems = TreeSelectorHelper.getUniqueItems(visibleTreeSelector.getSelected());

        invisibleTreeSelector?.setSelected(selectedItems.map((item) => item.id));
        setResultListValue(selectedItems);
        publicInterface._trigger('Bloko-TreeSelectorPopup-SelectedChanged', items);
    }

    function showPopup() {
        saveChanges = false;
        changedItems = [];
        selectedItems = TreeSelectorHelper.getUniqueItems(visibleTreeSelector.getSelected());
    }

    function hidPopup() {
        if (!saveChanges) {
            selectedItems = TreeSelectorHelper.getSelectedItemsWithoutSelectedParent(selectedItems);
            visibleTreeSelector.setSelected(
                selectedItems.map((item) => {
                    return item.id;
                })
            );
        }
    }

    function initResultList() {
        if (!showResultList) {
            return null;
        }

        const resultList = Components.make(ResultList, $resultElement.get(0), {
            items: TreeSelectorHelper.getUniqueItems(visibleTreeSelector.getSelected()),
            treeSelectorType: options.treeSelector.type,
            onChange: (items) => {
                setSelected(items.map((item) => item.id));
            },
        });

        placeResultList();
        $(window).on('resize', _.debounce(placeResultList, THROTTLED_TIMEOUT));

        return resultList;
    }

    function setResultListValue(selectedItems) {
        if (!resultList) {
            return;
        }

        resultList.setValue(selectedItems);
        resultList.toggle(!isSearching && selectedItems.length > 0);
    }

    function placeResultList() {
        if (getBreakpoint() === Breakpoint.XS) {
            $resultContainerXS.append($resultElement);
            return;
        }

        $resultContainer.append($resultElement);
    }

    /**
     * Устанавливает новый перевод в сообщение об ошибке
     *
     * @param {String} message
     */
    function setErrorMessage(message) {
        modalErrorInstance.setMessage(message);
    }

    /**
     * Показывает ошибку
     */
    function showError() {
        modalErrorInstance.show();
    }

    /**
     * Скрывает ошибку
     */
    function hideError() {
        modalErrorInstance.hide();
    }

    /**
     * Устанавливает disabled состояние кнопки подтверждения
     */
    function disableSubmit() {
        $submit.prop('disabled', true);
    }

    /**
     * Снимает disabled состояние кнопки подтверждения
     */
    function enableSubmit() {
        $submit.prop('disabled', false);
    }

    /**
     * Прокидывает `selectedItems` в аналогичный метод [TreeSelector](#/[Classic]%20TreeSelector). Выбирает указанные в списке id
     * @param {Array} selectedItems Список строковых id
     */
    function setSelected(selectedItems) {
        visibleTreeSelector.setSelected(selectedItems);
        // Отдельно обрабатываем случай с пустым массивом selectedItems, потому что в этом случае TreeSelector не шлёт никаких событий наружу
        if (!selectedItems?.length) {
            invisibleTreeSelector?.setSelected([]);
            setResultListValue([]);
        }
    }

    /**
     * Прокидывает `changedIds` в аналогичный метод [TreeSelector](#/[Classic]%20TreeSelector). Изменяет selected состояние на противоположное
     * @param {Array} changedIds Список строковых id
     */
    function change(changedIds) {
        visibleTreeSelector.change(changedIds);
    }

    /**
     * Прокидывает `filter` в аналогичный метод [TreeSelector](#/[Classic]%20TreeSelector)
     * @param {Function} filter Callback функция. Вызывается с одним аргументом — Object модели
     */
    function toggleDisabled(filter) {
        visibleTreeSelector.toggleDisabled(filter);
        invisibleTreeSelector?.toggleDisabled(filter);
    }

    return publicInterface;
};

export default Components.build({
    create(element, params) {
        return new TreeSelectorPopup({
            el: element,
            treeSelector: params.treeSelector,
            autoHide: params.autoHide,
            search: params.search,
            searchTreeSelector: params.searchTreeSelector,
            showResultList: params.showResultList,
            trl: params.trl,
            title: params.title,
        });
    },
});
