import {
    ChangeEvent,
    Children,
    cloneElement,
    FC,
    isValidElement,
    ReactElement,
    ReactNode,
    useCallback,
    useRef,
    useState,
    PropsWithChildren,
} from 'react';

import CheckboxChip from './CheckboxChip';
import { ChipProps } from './RadioChip';

const RESET_CHIP_INDEX = 0;

const generateCheckedValues = (chipIndex: number, isSelected: boolean, checkedValues: boolean[]): boolean[] => {
    let generatedValues: boolean[];
    const isResetChip = chipIndex === RESET_CHIP_INDEX;
    generatedValues = [...checkedValues];
    generatedValues[chipIndex] = isSelected;

    if (isResetChip) {
        generatedValues = new Array<boolean>(checkedValues.length).fill(false);
        generatedValues[RESET_CHIP_INDEX] = true;
        return generatedValues;
    }

    const [, ...otherChips] = generatedValues;
    generatedValues[RESET_CHIP_INDEX] = !otherChips.some(Boolean);
    return generatedValues;
};

const generateDefaultSelected = (elements: ReactElement<ChipProps>[]): boolean[] => {
    const checked = elements.map((element) => !!element.props.checked);
    if (checked[RESET_CHIP_INDEX] || !checked.some(Boolean)) {
        return checked.map((_, index) => index === RESET_CHIP_INDEX);
    }
    return checked;
};

const isValidChipElement = (child: ReactNode): child is ReactElement<ChipProps> =>
    isValidElement(child) && child.type === CheckboxChip;

const ResetChipsWrapper: FC<PropsWithChildren> = ({ children }) => {
    const elements = useRef(Children.toArray(children).filter(isValidChipElement));
    const [checkedValues, setCheckedValues] = useState(generateDefaultSelected(elements.current));
    const onSelectCallback = useCallback(
        (chipIndex: number, event: ChangeEvent<HTMLInputElement>) => {
            const checkedAfterChange = generateCheckedValues(chipIndex, event.target.checked, checkedValues);
            setCheckedValues(checkedAfterChange);
        },
        [checkedValues]
    );

    return (
        <>
            {elements.current.map((element, index) =>
                cloneElement(element, {
                    checked: checkedValues[index],
                    onChange: (event: ChangeEvent<HTMLInputElement>) => {
                        const originalCallback = element.props.onChange;
                        originalCallback && originalCallback(event);
                        onSelectCallback(index, event);
                    },
                })
            )}
        </>
    );
};

export default ResetChipsWrapper;
