import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const LinkedinScaleSmallAppearanceDefault = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M13.6003 1.33331H2.47244C1.86464 1.33331 1.33325 1.77093 1.33325 2.37178V13.524C1.33325 14.1283 1.86464 14.6666 2.47244 14.6666H13.5968C14.2081 14.6666 14.6666 14.1248 14.6666 13.524V2.37178C14.67 1.77093 14.2081 1.33331 13.6003 1.33331ZM5.46627 12.4473H3.55605V6.50826H5.46627V12.4473ZM4.57715 5.60525H4.56326C3.95199 5.60525 3.55605 5.15027 3.55605 4.58068C3.55605 4.00067 3.9624 3.55611 4.58757 3.55611C5.21273 3.55611 5.59477 3.9972 5.60866 4.58068C5.60866 5.15027 5.21273 5.60525 4.57715 5.60525ZM12.4472 12.4473H10.537V9.19993C10.537 8.42195 10.2592 7.89057 9.56802 7.89057C9.04011 7.89057 8.72753 8.2483 8.5886 8.59561C8.53651 8.72064 8.52261 8.89083 8.52261 9.06448V12.4473H6.6124V6.50826H8.52261V7.33487C8.80046 6.93893 9.2346 6.36934 10.2453 6.36934C11.4991 6.36934 12.4472 7.19594 12.4472 8.97765V12.4473Z"
                fill="var(--bloko-icon-color, var(--bloko-icon-color-default))"
            />
        </svg>
    ),
    'Linkedin'
);
export default LinkedinScaleSmallAppearanceDefault;
