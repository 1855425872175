import _ from 'underscore';

import Components from 'bloko/common/core/Components';
import blokoEvent from 'bloko/common/event';
import Metrics from 'bloko/common/metrics';

import Bindings from 'bloko/blocks/tabs/bindings';

const DEBOUNCE_DELAY_MS = window.bloko && window.bloko.isTest ? 1 : 100;

/**
 * Срабатывает перед сменой таба, если прервать с помощью `event.preventDefault()`, то переключение таба отменяется
 *
 * @event Bloko-Tabs-Will-Change
 * @property {Number} index Индекс активированного таба
 */

/**
 * Срабатывает при инициализации табов
 *
 * @event Bloko-Tabs-Init
 * @property {Number} index Индекс таба, который будет активирован
 */

/**
 * Срабатывает при смене таба
 *
 * @event Bloko-Tabs-Changed
 * @property {Number} index Индекс активированного таба
 */

/**
 * Управляет переключением и стилями табов
 * @param {Element} element            Элемент, на котором создается компонент
 * @param {Object} params              Параметры компонента
 * @param {Object} [params.cssClasses] Объект с CSS классами, динамически
 *                                     добавляемыми компонентом.
 *                                     Используется в ситуациях, когда требуется логика переключения табов,
 *                                     но не подходит их внешний вид.
 *                                     Следите за тем, чтобы в результате использования параметра на одном
 *                                     HTML-элементе не смешивались CSS классы от разных блоков
 * @param {String} [params.cssClasses.activeTab='bloko-tabs__item_active']
 *                                     CSS класс, добавляемый к заголовку активного таба
 * @param {String} [params.activeTabIndex] Индекс выбранного таба. Если не указан,
 *                                     ищется таб с классом `cssClasses.activeTab`.
 *                                     Если не нашёлся таб с классом, активируется первый таб
 * @constructor
 * @fires Bloko-Tabs-Will-Change    событие перед имзенением таба, можно прервать
 * @fires Bloko-Tabs-Changed        событие при изменении таба
 * @fires Bloko-Tabs-Init           событие при инициализации таба
 */
function Tabs(element, params) {
    const tabsContainer = element.querySelector(Bindings.container);
    const tabs = [...tabsContainer.querySelectorAll(Bindings.tab)];
    const bodies = [...element.querySelectorAll(Bindings.tabBody)];
    let activeTab;
    const lazyInitializeState = false;

    tabsContainer.addEventListener('click', ({ target }) => {
        const tab = target.closest(Bindings.tab);
        if (tab) {
            handleChange(tab);
        }
    });

    function isValidActiveTabIndex(index) {
        return typeof index === 'number' && index !== activeTab && index >= 0 && index < tabs.length;
    }

    function handleChange(tab) {
        if (tab.tagName === 'A') {
            return;
        }
        const index = tabs.indexOf(tab);
        if (isValidActiveTabIndex(index)) {
            const tabWillChangeEvent = blokoEvent('Bloko-Tabs-Will-Change');
            tabsContainer.dispatchEvent(tabWillChangeEvent, { index });
            if (!tabWillChangeEvent.defaultPrevented) {
                activateNewTab(index);
            }
        }
    }

    function activateElements() {
        tabs.forEach((element, index) => element.classList.toggle(params.cssClasses.activeTab, index === activeTab));
        const activeTabBody = bodies[activeTab];
        bodies.forEach((body) => body.classList.add('g-hidden'));
        if (activeTabBody) {
            activeTabBody.classList.remove('g-hidden');
        }
    }

    function activateNewTab(index) {
        activeTab = index;
        activateElements();
        element.dispatchEvent(blokoEvent('Bloko-Tabs-Changed', { index: activeTab }));
    }

    function initialize() {
        tabs.forEach((tab, index) => {
            tab.setAttribute('offset', -tab.offsetLeft);
            tab.setAttribute('width', Metrics.getOuterWidth(tab));
            if (typeof activeTab === 'undefined' && tab.classList.contains(params.cssClasses.activeTab)) {
                activeTab = index;
            }
        });
        activeTab = activeTab || 0;
        element.dispatchEvent(blokoEvent('Bloko-Tabs-Init', { index: activeTab }));
        activateElements();
    }

    /**
     * Устанавливает активный таб
     *
     * @param {Number} index Индекс таба для активации
     */
    function setActiveTab(index) {
        if (isValidActiveTabIndex(index)) {
            if (typeof activeTab !== 'undefined') {
                tabs[activeTab].blur();
            }
            activateNewTab(index);
        }
    }

    if (isValidActiveTabIndex(params.activeTabIndex)) {
        activeTab = params.activeTabIndex;
    }

    if (Metrics.isVisible(tabsContainer)) {
        initialize();
    } else {
        const onResize = _.debounce(() => {
            // Инициализация может происходить только если табы видны.
            // Табы статические, их метрики не изменяются.
            if (!lazyInitializeState && Metrics.isVisible(tabsContainer)) {
                initialize();
                window.removeEventListener('resize', onResize);
            }
        }, DEBOUNCE_DELAY_MS);
        window.addEventListener('resize', onResize);
    }

    return {
        setActiveTab,
    };
}

export default Components.build({
    defaults: {
        cssClasses: {
            activeTab: 'bloko-tabs__item_active',
        },
    },
    create: Tabs,
});
