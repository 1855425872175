import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const CrossScaleSmallEnclosedTrue = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M10.1 5.9L5.9 10.1M5.9 5.9L10.1 10.1M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z"
                stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                strokeWidth={1.33333}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ),
    'Cross'
);
export default CrossScaleSmallEnclosedTrue;
