import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const EyeScaleMediumSmallCrossedTrue = hoc(
    (props: IconProps) => (
        <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g>
                <path
                    d="M14.95 14.95C13.5255 16.0358 11.7909 16.6374 10 16.6667C4.16668 16.6667 0.833344 10 0.833344 10C1.86992 8.06826 3.30762 6.38052 5.05001 5.05001M8.25001 3.53334C8.82362 3.39908 9.4109 3.33196 10 3.33334C15.8333 3.33334 19.1667 10 19.1667 10C18.6608 10.9464 18.0576 11.8373 17.3667 12.6583M11.7667 11.7667C11.5378 12.0123 11.2618 12.2093 10.9551 12.3459C10.6485 12.4826 10.3174 12.5561 9.98175 12.562C9.64608 12.5679 9.31265 12.5062 9.00135 12.3804C8.69006 12.2547 8.40728 12.0675 8.16988 11.8301C7.93249 11.5927 7.74534 11.31 7.6196 10.9987C7.49386 10.6874 7.43212 10.3539 7.43804 10.0183C7.44396 9.68259 7.51743 9.35155 7.65407 9.04488C7.79071 8.73822 7.98772 8.46222 8.23334 8.23334M0.833344 0.833344L19.1667 19.1667"
                    stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                    strokeWidth={1.66667}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    ),
    'Eye'
);
export default EyeScaleMediumSmallCrossedTrue;
