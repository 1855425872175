import { ComponentPropsWithoutRef, FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

import { DocumentedPropsWithChildren } from 'bloko/common/helpers/types';

import styles from 'bloko/blocks/modal/modal.less';

interface ModalFooterProps extends ComponentPropsWithoutRef<'div'> {
    /** Выравнивание контента футера по левому краю */
    alignmentLeft?: boolean;
    /** Указывает на строку с компонентом в исходном коде в режиме разработки. Генерируется babel-plugin-react-source */
    source?: string;
}

/**
 * Подвал модального окна
 */
const ModalFooter: FC<DocumentedPropsWithChildren<ModalFooterProps> & PropsWithChildren> = ({
    alignmentLeft,
    source,
    ...modalFooterProps
}) => {
    return (
        <div
            {...modalFooterProps}
            className={classnames(styles['bloko-modal-footer'], { [styles['bloko-modal-footer_left']]: alignmentLeft })}
            source={source}
        />
    );
};

export default ModalFooter;
