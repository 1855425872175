import {
    createContext,
    ComponentType,
    ErrorInfo,
    PureComponent,
    PropsWithChildren,
    Ref,
    useContext,
    useMemo,
} from 'react';

import Debug from 'bloko/common/core/Debug';

export const TranslationLangContext = createContext<string>('');

export interface LangTrls {
    [x: string]: string;
}
export interface Trls {
    [x: string]: LangTrls;
}

export interface TranslationHOCProps {
    trls: LangTrls;
}
export type TranslatedComponent<T = Record<string, unknown>> = ComponentType<
    TranslationHOCProps & PropsWithChildren<T>
>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const inComponentName = (Component?: TranslatedComponent<any>) =>
    Component ? ` in "${Component.displayName || ''}"` : '';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const memoizeProxy = (obj: Trls, lang: string, isServer: boolean, Component?: TranslatedComponent<any>): LangTrls => {
    if (isServer || !('Proxy' in window)) {
        return obj[lang];
    }

    /* eslint-disable-next-line no-restricted-syntax, no-loop-func */
    return new Proxy(obj[lang], {
        get(target, name) {
            // @@toStringTag — это Symbol.toStringTag, который вызывается реакто-рендером для валидации
            // $$typeof + constructor — это обращение react-dev-tools prop-types для валидации элементов типа JSXElement
            // Symbol.iterator — вызов react-dev-tools для прохода по свойствам объектов и их валидации
            if (typeof name !== 'string' || name === 'constructor' || name === '@@toStringTag' || name === '$$typeof') {
                return null;
            }

            if (name === 'undefined') {
                const trls = JSON.stringify(target);
                const error = `The translation key is undefined${inComponentName(Component)}"`;
                Debug.log('out error', new Error(error), {
                    trls,
                    sentryFingerprint: [Component ? Component.displayName : false, 'translation-key'].filter(Boolean),
                });
            } else if (!target[name]) {
                const trls = JSON.stringify(target);
                const error = `The translation key ${name} does not exist${inComponentName(Component)}"`;
                Debug.log('out error', new Error(error), {
                    trls,
                    sentryFingerprint: [Component ? Component.displayName : false, 'translation-key'].filter(Boolean),
                });
            }

            return target[name];
        },
    });
};

interface TProps<U> {
    forwardRef?: Ref<HTMLElement>;
    Element: TranslatedComponent<U>;
    trls: LangTrls;
}

interface TState {
    failed: boolean;
}

export class T<U extends Record<string, unknown>> extends PureComponent<TProps<U>, TState> {
    state: TState = { failed: false };

    /**
     * Хотим, чтобы при падении компонента, не падал весь сайт, а только та часть, где произошла ошибка,
     * чтобы остальной частью сайта можно было пользоваться
     * Здесь есть несколько ограничений:
     * 1) Error Bounding можно делать только внутри компонентов-классов,
     *    либо оборачивать return в try-catch блоки. Что возможно делать только руками и затратно,
     *    поэтому это решение не рассматривается далее.
     *
     * 2) У нас есть 2 инструмента: ComponentDidCatch — где можно писать отправку ошибок, setState и прочее и
     *    getDerivedStateFromError — статический метод "что добавить в state". Других инструментов у нас нет.
     *
     * 3) Если не использовать ComponentDidCatch\getDerivedStateFromError приложение просто упадет и
     *    пользователь увидит пустую страницу (в нашем случае только обвязку)
     *
     * 4) Если поставить ComponentDidCatch, но продолжать рендерить страницу, она после N попыток перестает
     *    рендериться или уходит в бесконечный цикл. Поэтому проблемный компонент не должен быть отрендерен после
     *    ошибки
     *
     * В качестве решения добавляем обработчик ошибок в translation, так как
     * 1) Он используется в большинстве компонентов
     * 2) О нем разработчик не забудет. В других случаях, про error bounding обычно забывают
     * 3) Мы не увеличиваем количество компонентов — за счет чего выигрываем по performance и по дереву компонентов
     * (проще отлаживать)
     *
     **/
    static getDerivedStateFromError(): TState {
        return { failed: true };
    }

    componentDidCatch(error: Error, { componentStack }: ErrorInfo): void {
        // В режиме разработки реакт обрабатывает ошибки, кидая ивент,
        // который будет залогирован, даже если мы перехватим ошибку в componentDidCatch
        // https://reactjs.org/docs/cross-origin-errors.html
        // https://github.com/facebook/react/blob/v16.10.0/packages/shared/invokeGuardedCallbackImpl.js#L32-L49
        // Поэтому не логируем ошибку самостоятельно, чтобы избежать двойного логирования
        if (process.env.NODE_ENV !== 'development') {
            Debug.log('out error', error, {
                componentStack,
            });
        }
    }

    render(): JSX.Element | null {
        if (this.state.failed) {
            return null;
        }
        const { Element, forwardRef, trls, ...props } = this.props;
        return <Element {...(props as U)} trls={trls} ref={forwardRef} />;
    }
}

const useTranslations = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Component: TranslatedComponent<any> | undefined,
    trls: Trls,
    defaultLang: string,
    isServer: boolean
): LangTrls => {
    const lang = useContext(TranslationLangContext) || defaultLang;

    if (!trls[lang]) {
        const error = `The translation lang "${lang}" is undefined${inComponentName(Component)}`;
        Debug.log('out error', new Error(error), { trls });
    }

    return useMemo(() => memoizeProxy(trls, lang, isServer, Component), [Component, isServer, lang, trls]);
};

export default useTranslations;
