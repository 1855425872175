import requestAnimation from 'bloko/common/requestAnimation';

// Используется easeInOutCubic: https://gist.github.com/gre/1650294
const scrollEasing = (time: number) => {
    if (time < 0.5) {
        return 4 * time * time * time;
    }
    return (time - 1) * (2 * time - 2) * (2 * time - 2) + 1;
};

interface ScrollTopParams {
    top: number;
    speed?: number;
    animationStartCallback?: () => void;
    animationEndCallback?: () => void;
}
/**
 * Функция плавной прокрутки к координате
 * @param {Object} params Параметры
 * @param {Number} [params.top=0] Координата, до которой требуется прокрутить
 * @param {Number} [params.speed=500] Скорость прокрутки
 * @param {Function} [params.animationStartCallback] Колбек при начале анимации
 * @param {Function} [params.animationEndCallback] Колбек при достижении конечной точки
 */
export default ({ top, speed = 500, animationStartCallback, animationEndCallback }: ScrollTopParams): void => {
    const startLocation = window.pageYOffset;
    const endLocation = Math.round(top);
    const distance = endLocation - startLocation;
    const scrollSpeed = Math.abs((distance / 1000) * speed);
    const documentHeight = document.body.scrollHeight;
    const start = performance.now();
    const endLocationReached = (position: number) => {
        const currentLocation = window.pageYOffset;
        return (
            position === endLocation ||
            currentLocation === endLocation ||
            (startLocation < endLocation && window.innerHeight + currentLocation) >= documentHeight
        );
    };

    const scrollAnimationLoop = requestAnimation(() => {
        const timeLapsed = performance.now() - start;
        const percentage = Math.min(timeLapsed / scrollSpeed, 1);
        const position = Math.round(startLocation + distance * scrollEasing(percentage));
        window.scrollTo(0, position);

        animationStartCallback && animationStartCallback();
        const stopLoop = endLocationReached(position);
        if (stopLoop) {
            animationEndCallback && animationEndCallback();
            return;
        }
        scrollAnimationLoop();
    });

    scrollAnimationLoop();
};
