import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const BoltScaleSmall = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M9.49968 1.33334H5.66264C5.54298 1.33334 5.48316 1.33334 5.43034 1.35156C5.38363 1.36767 5.34109 1.39397 5.30579 1.42854C5.26587 1.46763 5.23912 1.52114 5.18561 1.62816L2.38561 7.22816C2.25782 7.48374 2.19393 7.61152 2.20927 7.7154C2.22268 7.8061 2.27285 7.88728 2.34798 7.93983C2.43403 8.00001 2.5769 8.00001 2.86264 8.00001H6.99968L4.99968 14.6667L13.1284 6.23688C13.4027 5.95248 13.5398 5.81028 13.5478 5.6886C13.5548 5.58298 13.5112 5.48033 13.4303 5.41203C13.3371 5.33334 13.1396 5.33334 12.7445 5.33334H7.99968L9.49968 1.33334Z"
                stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                strokeWidth={1.33333}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ),
    'Bolt'
);
export default BoltScaleSmall;
