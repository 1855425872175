import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const ThumbScaleSmallKindUpReducedFalse = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g>
                <path
                    d="M4.66671 14.6667V7.33334M1.33337 8.66668V13.3333C1.33337 14.0697 1.93033 14.6667 2.66671 14.6667H11.6175C12.6047 14.6667 13.4442 13.9465 13.5943 12.9708L14.3122 8.30412C14.4986 7.09261 13.5613 6.00001 12.3355 6.00001H10C9.63185 6.00001 9.33337 5.70153 9.33337 5.33334V2.97724C9.33337 2.06934 8.59738 1.33334 7.68948 1.33334C7.47293 1.33334 7.27669 1.46087 7.18875 1.65876L4.84267 6.93744C4.73567 7.17819 4.49692 7.33334 4.23346 7.33334H2.66671C1.93033 7.33334 1.33337 7.9303 1.33337 8.66668Z"
                    stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                    strokeWidth={1.33333}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    ),
    'Thumb'
);
export default ThumbScaleSmallKindUpReducedFalse;
