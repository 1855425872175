import { FC } from 'react';

import { PickDate, ClickDate } from 'bloko/blocks/calendar/datesHelper';
import { EnrichIfPresentWithOptionName, CalendarPickerAndSwitcherKind } from 'bloko/blocks/calendar/helper';
import { CalendarTranslator } from 'bloko/blocks/calendar/translations';

import DayPicker from 'bloko/blocks/calendar/Base/DayPicker';
import MonthPicker from 'bloko/blocks/calendar/Base/MonthPicker';
import YearPicker from 'bloko/blocks/calendar/Base/YearPicker';

export interface CalendarPickerProps {
    kind: CalendarPickerAndSwitcherKind;
    translate: CalendarTranslator;
    date: Date;
    initialDates: EnrichIfPresentWithOptionName;
    onClick?: ClickDate;
    onPick: PickDate;
    showInterval?: boolean;
}

const CalendarPicker: FC<CalendarPickerProps> = ({
    kind,
    translate,
    date,
    initialDates,
    onClick,
    onPick,
    showInterval,
}) => {
    switch (kind) {
        case CalendarPickerAndSwitcherKind.Month:
            return <MonthPicker translate={translate} date={date} onPick={onPick} />;
        case CalendarPickerAndSwitcherKind.Year:
            return <YearPicker date={date} onPick={onPick} />;
        default:
            return (
                <DayPicker
                    initialDates={initialDates}
                    translate={translate}
                    onClick={onClick}
                    showInterval={showInterval}
                />
            );
    }
};

export default CalendarPicker;
