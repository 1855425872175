import { ComponentPropsWithoutRef, FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

import styles from 'bloko/blocks/modal/modal.less';

interface ModalHeaderProps extends ComponentPropsWithoutRef<'div'> {
    /** Добавлять ли подчеркивание к заголовку */
    outlined?: boolean;
    /** Указывает на строку с компонентом в исходном коде в режиме разработки. Генерируется babel-plugin-react-source */
    source?: string;
}

/**
 * Заголовок модального окна
 */
const ModalHeader: FC<ModalHeaderProps & PropsWithChildren> = ({ outlined = false, source, ...modalHeaderProps }) => {
    return (
        <div
            {...modalHeaderProps}
            className={classnames(styles['bloko-modal-header'], {
                [styles['bloko-modal-header_outlined']]: outlined,
            })}
            source={source}
        />
    );
};

export default ModalHeader;
