import { createContext, useContext } from 'react';

import { Breakpoint } from 'bloko/common/media';

export interface BreakpointContextValue {
    breakpoint?: Breakpoint;
    renderRestriction: boolean;
}

export const BreakpointContext = createContext<BreakpointContextValue>({
    renderRestriction: false,
});

const useBreakpointContext: () => BreakpointContextValue = () => useContext(BreakpointContext);

export default useBreakpointContext;
