import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const ItAccreditationScaleMedium = hoc(
    (props: IconProps) => (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.5 12C22.5 17.799 17.799 22.5 12 22.5C6.20101 22.5 1.5 17.799 1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12ZM24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM6.9229 6.724C6.50868 6.724 6.1729 7.05979 6.1729 7.474C6.1729 7.88821 6.50868 8.224 6.9229 8.224H7.52798V15.776L6.92299 15.7759C6.50878 15.7759 6.17295 16.1116 6.1729 16.5258C6.17285 16.94 6.50859 17.2759 6.92281 17.2759L8.26253 17.2761L8.27798 17.2762L8.29324 17.2761L9.53952 17.2762C9.95373 17.2763 10.2896 16.9406 10.2896 16.5263C10.2897 16.1121 9.95391 15.7763 9.5397 15.7762L9.02798 15.7762V8.224H9.53961C9.95382 8.224 10.2896 7.88821 10.2896 7.474C10.2896 7.05979 9.95382 6.724 9.53961 6.724H6.9229ZM14.2482 16.5259V8.22432H11.6565C11.2423 8.22432 10.9065 7.88853 10.9065 7.47432C10.9065 7.0601 11.2423 6.72432 11.6565 6.72432H14.9761C14.9835 6.72411 14.9908 6.724 14.9982 6.724C15.0056 6.724 15.013 6.72411 15.0203 6.72432H18.3399C18.7542 6.72432 19.0899 7.0601 19.0899 7.47432C19.0899 7.88853 18.7542 8.22432 18.3399 8.22432H15.7482V16.5259C15.7482 16.9401 15.4125 17.2759 14.9982 17.2759C14.584 17.2759 14.2482 16.9401 14.2482 16.5259Z"
                fill="var(--bloko-icon-color, var(--bloko-icon-color-default))"
            />
        </svg>
    ),
    'It'
);
export default ItAccreditationScaleMedium;
