import createSingleValueToggler from 'bloko/common/collection/createSingleValueToggler';
import createTreeCollectionToggler from 'bloko/common/collection/createTreeCollectionToggler';
import createTreeCollectionExcluder from 'bloko/common/collection/createTreeSelectionExcluder';
import dummyToggle from 'bloko/common/collection/dummyToggle';
import Debug from 'bloko/common/core/Debug';

import { filterMissingIds, filterParents, filterSingleCategory } from 'bloko/common/tree/treeCollectionHelper';

/**
 * Оставляет в массиве только последний ID и логирует ошибку, если их было больше.
 * @param {Array.<String>} ids
 * @returns {Array.<String>}
 * @private
 */
function _filterMultipleIds(ids) {
    if (ids.length > 1) {
        Debug.log('out error', new Error('Collection accepts only one ID'));
        return [ids[ids.length - 1]];
    }
    return ids;
}

/**
 * Оставляет в массиве только последний ID и логирует ошибку, если их было больше.
 * @param {TreeCollection} collection
 * @param {Array.<String>} ids
 * @param {Function} checkSelectable
 * @returns {Array.<String>}
 * @private
 */
function _filterSelectable(collection, ids, checkSelectable) {
    return ids.filter((id) => checkSelectable(id, collection));
}

const defaultCheckSelectable = () => true;

/**
 * Стратегия переключения флага `selected` для иерархической коллекции.
 * @param {TreeCollection} collection
 * @param {Object} options
 * @param {Function} options.checkSelectable
 * @param {Boolean} options.singleChoice
 * @param {Boolean} options.singleCategory
 * @param {Boolean} options.withExcluded
 * @param {Boolean} options.leavesOnly
 * @implements SetStrategy
 * @constructor
 */
function SelectionStrategy(collection, options) {
    const checkSelectable = options.checkSelectable || defaultCheckSelectable;
    const toggle = options.singleChoice
        ? createSingleValueToggler()
        : createTreeCollectionToggler(collection, checkSelectable, { withExcluded: options.withExcluded });
    const runExcluder = createTreeCollectionExcluder(collection, checkSelectable);

    function add(items, ids) {
        let filteredIds = filterMissingIds(collection, ids);

        if (options.singleCategory) {
            filteredIds = filterSingleCategory(collection, items, filteredIds);
        }

        if (options.singleChoice) {
            filteredIds = _filterMultipleIds(filteredIds);
        }
        if (options.leavesOnly) {
            filteredIds = filterParents(collection, filteredIds);
        }
        if (options.checkSelectable) {
            filteredIds = _filterSelectable(collection, filteredIds, options.checkSelectable);
        }

        if (options.leavesOnly && !options.singleChoice) {
            // Тут не нужно выбирать или сбрасывать родителей
            filteredIds.forEach((id) => {
                dummyToggle(items, id, true);
            });
        } else {
            filteredIds.forEach((id) => {
                toggle(items, id, true);
            });
        }
    }

    function remove(items, ids) {
        const filteredIds = filterMissingIds(collection, ids);

        filteredIds.forEach((id) => {
            toggle(items, id, false);
        });
    }

    function exclude(items, excluded, ids) {
        const filteredIds = options.checkSelectable
            ? _filterSelectable(collection, filterMissingIds(collection, ids), options.checkSelectable)
            : filterMissingIds(collection, ids);

        runExcluder(items, excluded, filteredIds);
    }

    function set(items, ids) {
        items.clear();
        add(items, ids);
    }

    return {
        add,
        remove,
        set,
        exclude,
    };
}

export default SelectionStrategy;
