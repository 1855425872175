import { memo, ReactNode, FC } from 'react';

import Button, { ButtonKind } from 'bloko/blocks/button';
import { FormSpacer } from 'bloko/blocks/form';
import Loading, { LoadingColor } from 'bloko/blocks/loading';
import { ModalFooter } from 'bloko/blocks/modal';

import dataQa from 'bloko/blocks/treeSelectorPopup/dataQa';

import styles from 'bloko/blocks/treeSelectorPopup/treeSelectorPopup.less';

interface PopupFooterProps {
    /** Обработчик закрытия TreeSelectorPopup */
    onClose: () => void;
    onSubmit: () => void;
    trlSubmit: string;
    trlCancel: string;
    /** Отображать ли иконку загрузки на кнопке подтверждения */
    showLoadingOnSubmit?: boolean;
    /** Заблокирована ли кнопка подтверждения */
    disabledSubmit?: boolean;
    /** Дополнительный элемент в футере */
    footerExtra?: ReactNode;
}

const PopupFooter: FC<PopupFooterProps> = ({
    showLoadingOnSubmit,
    disabledSubmit,
    trlCancel,
    trlSubmit,
    onSubmit,
    onClose,
    footerExtra,
}) => {
    return (
        <ModalFooter>
            <div className={styles['bloko-tree-selector-popup-footer']}>
                {footerExtra && <div className={styles['bloko-tree-selector-popup-footer-extra']}>{footerExtra}</div>}
                <FormSpacer>
                    <Button data-qa={dataQa.cancel} onClick={onClose}>
                        {trlCancel}
                    </Button>
                </FormSpacer>
                <FormSpacer>
                    <Button
                        kind={ButtonKind.Primary}
                        disabled={disabledSubmit}
                        onClick={onSubmit}
                        data-qa={dataQa.submit}
                        loading={showLoadingOnSubmit ? <Loading initial={LoadingColor.White} /> : undefined}
                    >
                        {trlSubmit}
                    </Button>
                </FormSpacer>
            </div>
        </ModalFooter>
    );
};

export default memo(PopupFooter);
