import { useState, FC, Fragment, PropsWithChildren } from 'react';

import VersionChecker from 'bloko/blocks/compatibleVersion/VersionCheker';

const CompatibleVersion: FC<PropsWithChildren> = ({ children }) => {
    const [needReload, forceReload] = useState(false);

    return (
        <Fragment key={needReload ? 'client-version' : 'host-version'}>
            {children}
            <VersionChecker forceReload={forceReload} />
        </Fragment>
    );
};

export default CompatibleVersion;
