import {
    ComponentPropsWithoutRef,
    FC,
    useCallback,
    useRef,
    useState,
    useEffect,
    useContext,
    PropsWithChildren,
} from 'react';
import classnames from 'classnames';

import { ChevronScaleSmallKindDown } from 'bloko/blocks/icon';
import { DocumentedPropsWithChildren } from 'bloko/common/helpers/types';
import useResize from 'bloko/common/hooks/useResize';

import {
    BOTTOM_SHEET_TOP_SPACE,
    BottomSheetContext,
    BottomSheetContextProps,
} from 'bloko/blocks/modal/bottomSheetUtils';

import styles from 'bloko/blocks/modal/modal.less';

/**
 * Обертка для контента модального окна
 */

const ModalContent: FC<DocumentedPropsWithChildren<ComponentPropsWithoutRef<'div'>> & PropsWithChildren> = ({
    children,
    ...props
}) => {
    const [showFader, setShowFader] = useState(false);
    const scrollableElement = useRef<HTMLDivElement>(null);
    const { isBottomSheet, modalContainerRef } = useContext<BottomSheetContextProps>(BottomSheetContext);

    const runWithFixedTop = useCallback(
        (callback: () => void, fixedTop: string) => {
            if (!isBottomSheet || !modalContainerRef?.current) {
                callback();
                return;
            }

            const top = modalContainerRef.current.style.top;
            modalContainerRef.current.style.top = fixedTop;
            callback();
            modalContainerRef.current.style.top = top;
        },
        [isBottomSheet, modalContainerRef]
    );

    const scrollHandler = useCallback(
        () =>
            runWithFixedTop(() => {
                if (!scrollableElement.current) {
                    return;
                }
                const showFader =
                    Math.abs(
                        scrollableElement.current.scrollTop +
                            scrollableElement.current.offsetHeight -
                            scrollableElement.current.scrollHeight
                    ) >= 1;
                setShowFader(showFader);
            }, '0px'),
        [runWithFixedTop]
    );

    useEffect(() => {
        scrollHandler();
    }, [scrollHandler]);

    useResize(scrollHandler);

    useEffect(() => {
        runWithFixedTop(() => {
            if (isBottomSheet && scrollableElement.current && modalContainerRef?.current) {
                scrollableElement.current.style.display = 'none';
                const clientHeight = window.innerHeight;
                const modalHeight = modalContainerRef.current.offsetHeight;
                const maxContentHeight = clientHeight - BOTTOM_SHEET_TOP_SPACE - modalHeight;
                scrollableElement.current.style.removeProperty('display');
                scrollableElement.current.style.maxHeight = `${maxContentHeight}px`;
            }
        }, 'auto');
    }, [modalContainerRef, isBottomSheet, runWithFixedTop]);

    return (
        <>
            <div
                {...props}
                className={classnames(styles['bloko-modal-content'], styles['bloko-modal-content_no-scroll'])}
            >
                <div
                    className={styles['bloko-modal-content-scrollable']}
                    ref={scrollableElement}
                    onScroll={scrollHandler}
                >
                    {/* обертка для правильного расчета признака скролла */}
                    <div className={styles['bloko-modal-content-scrollable-children']}>{children}</div>
                </div>
                {!isBottomSheet && (
                    <div
                        className={classnames(styles['bloko-modal-content-fader'], {
                            [styles['bloko-modal-content-fader_visible']]: showFader,
                        })}
                    />
                )}
            </div>
            <div
                className={classnames(styles['bloko-modal-content-fader-bottom'], {
                    [styles['bloko-modal-content-fader-bottom_visible']]: showFader,
                })}
            >
                {isBottomSheet ? (
                    <div
                        className={classnames(styles['bloko-modal-bottom-sheet-fader'], {
                            [styles['bloko-modal-bottom-sheet-fader_visible']]: showFader,
                        })}
                    />
                ) : (
                    <ChevronScaleSmallKindDown />
                )}
            </div>
        </>
    );
};

export default ModalContent;
