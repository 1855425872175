/**
 * Возвращает конструктор для создания полифила (local|session)Storage
 *
 * @name bloko/common/storage/Polyfill
 * @constructor
 */
const StoragePolyfill = function () {
    let data = {};

    /**
     * Количество пар ключ-значение
     * @member
     *
     * @type {Number}
     */
    this.length = 0;

    /**
     * Возвращает имя i-го ключа в объекте
     * @param   {Number} index Порядковый номер ключа
     * @returns {String|Null}  Имя ключа или null в случае отсутствия такового
     *
     * @member
     * @method
     */
    this.key = function (index) {
        const keys = Object.keys(data);
        return index < keys.length ? keys[index] : null;
    };

    /**
     * Добавляет ключ в localStorage или обновляет его значение, если такой уже существует
     * @param {String} key   Имя ключа
     * @param {String} value Значение
     *
     * @member
     * @method
     */
    this.setItem = function (key, value) {
        if (!data.hasOwnProperty(key)) {
            this.length += 1;
        }
        data[key] = String(value);
    };

    /**
     * Возвращает значение по ключу
     * @param   {String} key  Имя ключа
     * @returns {String|Null} Значение
     *
     * @member
     * @method
     */
    this.getItem = function (key) {
        return data.hasOwnProperty(key) ? data[key] : null;
    };

    /**
     * Удаляет ключ из объекта
     * @param {String} key Имя ключа
     *
     * @member
     * @method
     */
    this.removeItem = function (key) {
        this.length -= 1;
        delete data[key];
    };

    /**
     * Удаляет все ключи из объекта
     *
     * @member
     * @method
     */
    this.clear = function () {
        this.length = 0;
        data = {};
    };

    /**
     * Возвращает число ключей в объекте
     * @returns {Number}
     *
     * @member
     * @method
     */
    this.getLength = function () {
        return this.length;
    };
};

export default StoragePolyfill;
