(function () {
    const requestIdleCallbackShim = (cb) => {
        return setTimeout(() => {
            const start = Date.now();
            cb({
                didTimeout: false,
                timeRemaining() {
                    return Math.max(0, 50 - (Date.now() - start));
                },
            });
        }, 1);
    };

    return (window.requestIdleCallback = window.requestIdleCallback || requestIdleCallbackShim);
})();
