import { FC, useEffect, useMemo, PropsWithChildren } from 'react';

import Cookies from 'bloko/common/Cookies';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import { BreakpointContext } from 'bloko/blocks/breakpointProvider/BreakpointContext';

interface BreakpointProviderProps extends PropsWithChildren {
    /** Cохраненное значение брекпоинта, которое будет использоваться начальным */
    storedBreakpointValue?: Breakpoint;
    /** Флаг, который позволяет сохранять значение брекпоинта в куку */
    storeActualBreakpointValueInCookie?: boolean;
    /** Флаг, который позволяет использовать только сохраненное значение брекпоинта
     * работает если есть сохраненное значение */
    forceUseStoredBreakpoint?: boolean;
    /** Флаг, который включает ограничение рендера, позволяет отключить SSR
     * для колонок, значение брекпоинта которых не совпадает с значением
     * в контексте, работает если есть сохраненное значение */
    renderRestriction?: boolean;
}

const BreakpointProvider: FC<BreakpointProviderProps> = ({
    storedBreakpointValue,
    storeActualBreakpointValueInCookie = true,
    forceUseStoredBreakpoint = false,
    renderRestriction = false,
    children,
}) => {
    const actualBreakpointValue = useBreakpoint(storedBreakpointValue);
    const useOnlyStoredValue = !!(forceUseStoredBreakpoint && storedBreakpointValue);

    useEffect(() => {
        if (storeActualBreakpointValueInCookie) {
            Cookies.set('device_breakpoint', actualBreakpointValue, 24 * 14);
        }
    }, [actualBreakpointValue, storeActualBreakpointValueInCookie]);

    const contextValue = useMemo(
        () => ({
            breakpoint: useOnlyStoredValue ? storedBreakpointValue : actualBreakpointValue,
            renderRestriction: !!(storedBreakpointValue && renderRestriction),
        }),
        [actualBreakpointValue, useOnlyStoredValue, storedBreakpointValue, renderRestriction]
    );

    return <BreakpointContext.Provider value={contextValue}>{children}</BreakpointContext.Provider>;
};

export default BreakpointProvider;
