import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const MScaleSmall = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M8 6.66667L10.6512 2H14V13.9259H10.8605V7.11111L8.62791 11.1111H7.3721L5.13954 7.11111V14H2V2H5.34884L8 6.66667Z"
                fill="var(--bloko-icon-color, var(--bloko-icon-color-default))"
            />
        </svg>
    ),
    'M'
);
export default MScaleSmall;
