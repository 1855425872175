import geoCalc from 'bloko/common/geoCalculations';

const ymapsSuggestDataProviderBuilder = (ymapsPromise, params) => {
    const resultsLimit = params.suggestResultsLimit;
    let suggestSearchStartArea;
    if (params.suggestSearchStartPoint) {
        suggestSearchStartArea = geoCalc.getBoundPoints(
            params.suggestSearchStartPoint,
            params.suggestSearchStartRadiusKm
        );
    }

    const ymapsSuggestDataProvider = (inputValue) =>
        ymapsPromise
            .then((ymaps) =>
                ymaps.suggest(`${params.prefix || ''}${inputValue}`, {
                    results: resultsLimit,
                    boundedBy: suggestSearchStartArea,
                })
            )
            .then((items) => ({
                items: items.map((item, i) => ({
                    id: i,
                    text: item.displayName,
                    fullAddress: item.value,
                })),
            }));

    return ymapsSuggestDataProvider;
};

export default ymapsSuggestDataProviderBuilder;
