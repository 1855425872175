import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const WhatsappScaleSmallAppearanceColor = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M8.00006 1.33331C4.32413 1.33331 1.33342 4.32404 1.33342 7.99998C1.33342 9.14781 1.62994 10.2765 2.19225 11.2724L1.34414 14.2985C1.31632 14.398 1.34327 14.5046 1.41516 14.5785C1.47052 14.6356 1.54588 14.6666 1.62327 14.6666C1.64646 14.6666 1.66994 14.6637 1.69284 14.6582L4.85109 13.8759C5.81543 14.3936 6.90151 14.6666 8.00006 14.6666C11.676 14.6666 14.6667 11.6759 14.6667 7.99998C14.6667 4.32404 11.676 1.33331 8.00006 1.33331ZM11.3537 10.3524C11.2111 10.7472 10.527 11.1075 10.1983 11.1559C9.90324 11.1991 9.52991 11.2177 9.12006 11.089C8.87165 11.0107 8.55281 10.9069 8.14441 10.7327C6.4276 10.0006 5.30645 8.2936 5.22065 8.18085C5.13514 8.0681 4.52181 7.26462 4.52181 6.43302C4.52181 5.60143 4.96384 5.19244 5.12094 5.02317C5.27804 4.85389 5.46326 4.81157 5.57746 4.81157C5.69166 4.81157 5.80558 4.81302 5.90558 4.81766C6.01079 4.82288 6.15195 4.77795 6.29079 5.10781C6.4334 5.44636 6.77572 6.27795 6.81804 6.36288C6.86094 6.44752 6.88934 6.54636 6.83253 6.65911C6.77572 6.77186 6.74731 6.8423 6.66152 6.94114C6.57572 7.03998 6.48181 7.16143 6.4047 7.23737C6.31891 7.32172 6.22992 7.41302 6.32963 7.5823C6.42934 7.75157 6.77282 8.30491 7.2818 8.75302C7.93542 9.32868 8.48702 9.50723 8.65803 9.59186C8.82904 9.6765 8.92904 9.6623 9.02875 9.54955C9.12846 9.4365 9.45658 9.05592 9.57049 8.88694C9.6844 8.71795 9.79861 8.74578 9.95571 8.8023C10.1128 8.85853 10.9543 9.26723 11.1253 9.35186C11.2963 9.4365 11.4105 9.47882 11.4534 9.54926C11.4963 9.6194 11.4963 9.95795 11.3537 10.3524Z"
                fill="#66D072"
            />
        </svg>
    ),
    'Whatsapp'
);
export default WhatsappScaleSmallAppearanceColor;
