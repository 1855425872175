import $ from 'jquery';

import TreeModel from 'bloko/common/tree/model';

export default TreeModel.extend({
    defaults: $.extend({}, TreeModel.defaults, {
        changeable: true,
        voted: false,
        count: 0,
    }),
});
