type DetectFunction = () => boolean;

const lazyDetector = function (detector: DetectFunction): DetectFunction {
    let result: boolean;
    return function () {
        if (typeof result === 'undefined') {
            result = detector();
        }
        return result;
    };
};

type StorageKey = keyof WindowSessionStorage | keyof WindowLocalStorage;

const detectStorageSupport = function (storageName: StorageKey, key: string): DetectFunction {
    return function () {
        try {
            window[storageName].setItem(key, key);
            window[storageName].removeItem(key);
            return true;
        } catch (ignore) {
            return false;
        }
    };
};

let userAgent: string;

const getUserAgent = () => {
    if (typeof userAgent === 'undefined') {
        userAgent = navigator.userAgent.toLowerCase();
    }

    return userAgent;
};

/**
 * Проверка поддержки браузером разных фич.
 *
 * @type {Object}
 * @name BlokoSupports
 */
const Supports = {
    /**
     * Поддержка localStorage.
     * @returns {Boolean}
     *
     * @member
     * @method
     */
    localStorage: lazyDetector(detectStorageSupport('localStorage', '_bloko_hh_test_local_storage')),

    /**
     * Поддержка sessionStorage.
     * @returns {Boolean}
     *
     * @member
     * @method
     */
    sessionStorage: lazyDetector(detectStorageSupport('sessionStorage', '_bloko_hh_test_session_storage')),

    /**
     * Поддержка Hystory API.
     * @returns {Boolean}
     *
     * @member
     * @method
     */
    historyApi: lazyDetector(() => !!(window.history && window.history.pushState)),

    /**
     * У пользователя Android.
     * @returns {Boolean}
     *
     * @member
     * @method
     */
    android: lazyDetector(
        () =>
            /android/i.test(getUserAgent()) &&
            !/IEMobile/i.test(getUserAgent()) &&
            !/(ipad|ipod|iphone)/i.test(getUserAgent())
    ),

    /**
     * У пользователя IE Mobile.
     * @returns {Boolean}
     *
     * @member
     * @method
     */
    IEMobile: lazyDetector(() => /IEMobile/i.test(getUserAgent())),

    /**
     * У пользователя Internet Explorer.
     * @returns {Boolean}
     *
     * @member
     * @method
     */
    IE: lazyDetector(
        () =>
            /MSIE/i.test(getUserAgent()) ||
            (/trident/.test(getUserAgent()) && /rv:11/.test(getUserAgent())) ||
            /edge/.test(getUserAgent())
    ),

    /**
     * У пользователя мобильный девайс.
     * @returns {Boolean}
     *
     * @member
     * @method
     */
    mobile: lazyDetector(() => /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(getUserAgent())),

    /**
     * У пользователя iOS.
     * @returns {Boolean}
     *
     * @member
     * @method
     */
    ios: lazyDetector(() => /(ipad|ipod|iphone)/i.test(getUserAgent())),

    /**
     * У пользователя WebKit.
     * @returns {Boolean}
     *
     * @member
     * @method
     */
    webkit: lazyDetector(() => /webkit/i.test(getUserAgent())),

    /**
     * Поддержка тач-событий.
     * @returns {Boolean}
     *
     * @member
     * @method
     */
    touch: lazyDetector(() => 'ontouchstart' in window),

    /**
     * Поддержка <input type="time />.
     * @returns {Boolean}
     *
     * @member
     * @method
     */
    inputTypeTime: lazyDetector(() => {
        const input = document.createElement('input');
        input.setAttribute('type', 'time');

        return input.type === 'time';
    }),
};

export default Supports;
