import React from 'react';
import { Merge } from 'type-fest';

import 'bloko/blocks/select/select.less';

interface OptionProps {
    /** Атрибут value */
    value?: string;
    /** Флаг disabled */
    disabled?: boolean;
}

export type MergedOptionProps = Merge<JSX.IntrinsicElements['option'], OptionProps>;

const Option: React.FC<MergedOptionProps & React.PropsWithChildren> = (props) => (
    <option {...props} suppressHydrationWarning />
);

export default Option;
