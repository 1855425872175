import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const SquaresScaleSmallIntersectFalse = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g>
                <path
                    d="M3.3335 5.99999C2.71224 5.99999 2.40161 5.99999 2.15658 6.10148C1.82988 6.23681 1.57032 6.49638 1.43499 6.82308C1.3335 7.06811 1.3335 7.37874 1.3335 7.99999V12.5333C1.3335 13.2801 1.3335 13.6534 1.47882 13.9386C1.60665 14.1895 1.81063 14.3935 2.06151 14.5213C2.34672 14.6667 2.72009 14.6667 3.46683 14.6667H8.00016C8.62142 14.6667 8.93205 14.6667 9.17707 14.5652C9.50378 14.4298 9.76334 14.1703 9.89867 13.8436C10.0002 13.5985 10.0002 13.2879 10.0002 12.6667M8.1335 1.33332H12.5335C13.2802 1.33332 13.6536 1.33332 13.9388 1.47865C14.1897 1.60648 14.3937 1.81045 14.5215 2.06134C14.6668 2.34655 14.6668 2.71992 14.6668 3.46666V7.86666C14.6668 8.61339 14.6668 8.98676 14.5215 9.27198C14.3937 9.52286 14.1897 9.72683 13.9388 9.85467C13.6536 9.99999 13.2802 9.99999 12.5335 9.99999H8.1335C7.38676 9.99999 7.01339 9.99999 6.72818 9.85467C6.47729 9.72683 6.27332 9.52286 6.14549 9.27198C6.00016 8.98676 6.00016 8.61339 6.00016 7.86666V3.46666C6.00016 2.71992 6.00016 2.34655 6.14549 2.06134C6.27332 1.81045 6.47729 1.60648 6.72818 1.47865C7.01339 1.33332 7.38676 1.33332 8.1335 1.33332Z"
                    stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                    strokeWidth={1.33333}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    ),
    'Squares'
);
export default SquaresScaleSmallIntersectFalse;
