import { convertGeoCodeResult } from 'bloko/blocks/address/geoObjectHelper';

export default (ymapsPromise) => {
    const resolveLocationByAddress = (address) =>
        ymapsPromise.then((ymaps) => ymaps.geocode(address)).then(convertGeoCodeResult);

    const resolveCurrentLocation = () =>
        ymapsPromise
            .then((ymaps) =>
                ymaps.geolocation.get({
                    mapStateAutoApply: true,
                    autoReverseGeocode: true,
                })
            )
            .then(convertGeoCodeResult);

    return {
        resolveLocationByAddress,
        resolveCurrentLocation,
    };
};
