import { ChangeEvent, useCallback, FC, KeyboardEvent, PropsWithChildren } from 'react';

import Checkbox from 'bloko/blocks/checkbox';
import Radio from 'bloko/blocks/radio';
import dataQa from 'bloko/blocks/treeSelector/dataQa';
import { TreeModel } from 'bloko/common/tree/types';

interface ActionProps {
    /** ID элемента. */
    id: TreeModel['id'];
    /** Имя элемента. */
    name: string;
    /** ID родительского элемента. */
    parentId?: TreeModel['id'];
    /** Разрешает выбор только одного элемента. */
    singleChoice: boolean;
    /** Элемент выбран. */
    selected: boolean;
    /** Элемент запрещён. */
    disabled: boolean;
    /** Значение инпута не определено. */
    indeterminate: boolean;
    onChange?: (id: string, isSelected: boolean) => void;
    onKeyDown: (e: KeyboardEvent) => void;
}

const Action: FC<ActionProps & PropsWithChildren> = ({
    id,
    name,
    parentId,
    singleChoice,
    selected,
    disabled,
    indeterminate,
    onChange,
    onKeyDown,
    children,
}) => {
    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            onChange && onChange(id, event.target.checked);
        },
        [id, onChange]
    );

    const inputProps = {
        disabled,
        checked: selected,
        onChange: handleChange,
        onKeyDown,
        'data-qa': `${dataQa.input} ${dataQa.input}-${id}`,
        labelProps: {
            'data-qa': `${dataQa.text} ${dataQa.text}-${id}`,
        },
        children,
    };

    return singleChoice ? (
        <Radio {...inputProps} name={`${name}-${id}${parentId ? `-${parentId}` : ''}`} />
    ) : (
        <Checkbox {...inputProps} name={name} indeterminate={indeterminate} />
    );
};

export default Action;
