import Layer from 'bloko/common/constants/layersCssClasses';

export default {
    AUTOSELECT: false,
    AUTO_SELECT_FIRST_SUGGEST: true,
    ALLOW_PICKER: true,
    FIELD: 'text',
    WILDCARD: '%QUERY%',
    LIMIT: 10,
    SUGGEST_START_INPUT_LENGTH: 2,
    SELECTION_ON_BLUR: true,
    THROTTLE: 300,
    TEST_THROTTLE: 0,
    SELECT_EXACT_MATCH_ON_BLUR: false,
    DELIMITER: false,
    LAYER: Layer.AboveContent,
    DROPDOWN_FADE_TIME: 120,
    SEARCH_ON_FOCUS: false,
};
