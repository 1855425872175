import { FC, useMemo, useCallback, ReactElement, ReactNode, PropsWithChildren } from 'react';

import DropBase from 'bloko/blocks/drop/Base';
import { getThemeClass } from 'bloko/blocks/drop/common';
import { IconLink, IconColor, CrossScaleSmallEnclosedFalse } from 'bloko/blocks/icon';

import styles from 'bloko/blocks/drop/Info/info.less';

import defaultsProps, {
    InfoPlacement,
    InfoTheme,
    BASE_CLASS_NAME,
    PADDING_WRAPPER_CLASS_NAME,
    BEHAVIOR,
    InfoPlacementValue,
    InfoLayer,
} from './constants';

const ICON_MAPPER = {
    [InfoTheme.Light]: IconColor.Gray80,
    [InfoTheme.Dark]: IconColor.White,
    [InfoTheme.Bright]: IconColor.White,
    [InfoTheme.Info]: IconColor.White,
    [InfoTheme.Neural]: IconColor.White,
};

export interface InfoProps {
    /** Предпочтительное положение info, доступны в статическом свойстве [placements](#info-placements). */
    placement?: InfoPlacementValue;
    /** Class z-index-а, варианты доступны в статическом свойстве [layers](#info-layers) */
    layer?: InfoLayer;
    /** Возможные темы info доступны в статическом свойстве [themes](#info-themes)*/
    theme?: InfoTheme;
    /** DOM нода хоста в рамках которого нужно рендерить info, по дефолту рендер будет в document.body */
    host?: HTMLElement | null;
    /** Флаг отвечает за показ info, `true` - показать, `false` - скрыть */
    show?: boolean;
    /** Элемент инициатор, относительно которого показывать всплывающую подсказку */
    children: ReactElement;
    /** Метод-рендер контента info */
    render: () => ReactNode;
    /** Колбек вызываемый при закрытии компонента. Срабатывает в случае клика на крестик или клика вне компонента */
    onClose?: () => void;
    /** Флаг — рисовать ли крестик */
    showCloseButton?: boolean;
    /** Флаг — закрывать ли info по клику извне */
    closeByClickOutside?: boolean;
    /** Метка, использовать ли тянущийся info.
     * Тянущийся info будет растягивать по контенту, ограничиваясь только размерами экрана */
    flexible?: boolean;
    /** dataQa='bloko-drop-info' Data-qa активного info */
    dataQa?: string;
}

export type DropInfoComponent = FC<InfoProps & PropsWithChildren>;

const DropInfo: DropInfoComponent = (componentProps) => {
    const { theme, showCloseButton, render, onClose, ...props } = {
        host: null,
        ...defaultsProps,
        ...componentProps,
    };
    const classNames = useMemo(() => [BASE_CLASS_NAME, getThemeClass(theme)], [theme]);

    const renderFunc = useCallback(() => {
        if (!showCloseButton) {
            return render();
        }

        return (
            <div className={styles['bloko-drop__content-wrapper']}>
                <div className={styles['bloko-drop__content']}>{render()}</div>
                <div className={styles['bloko-drop__close']}>
                    <IconLink onClick={onClose} data-qa="bloko-drop-info-close-button">
                        <CrossScaleSmallEnclosedFalse initial={ICON_MAPPER[theme]} />
                    </IconLink>
                </div>
            </div>
        );
    }, [showCloseButton, render, theme, onClose]);

    return (
        <DropBase
            {...props}
            onClose={onClose}
            onlySetPlacement={false}
            behavior={BEHAVIOR}
            baseClassNames={classNames}
            paddingWrapperClassName={PADDING_WRAPPER_CLASS_NAME}
            render={renderFunc}
        />
    );
};

export default DropInfo;
export { InfoLayer, InfoPlacement, InfoTheme };
