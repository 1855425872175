import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const TelegramScaleSmallAppearanceOutlined = hoc(
    (props: IconProps) => (
        <svg width={17} height={16} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M0.538371 7.90055C1.92335 7.10481 3.46944 6.44043 4.914 5.7733C7.39898 4.67975 9.89411 3.60684 12.4143 2.60614C12.9051 2.43558 13.7857 2.26914 13.8724 3.02705C13.825 4.09997 13.6307 5.166 13.4967 6.23204C13.1582 8.57731 12.7663 10.9136 12.3845 13.2514C12.2532 14.0299 11.3177 14.4329 10.7193 13.9343C9.2815 12.9212 7.83221 11.9185 6.4127 10.882C5.94698 10.3889 6.37886 9.68117 6.79381 9.32972C7.97842 8.11238 9.23479 7.07798 10.3571 5.79806C10.6604 5.03533 9.7655 5.67839 9.47036 5.87509C7.84845 7.04016 6.26649 8.27676 4.55726 9.30015C3.68403 9.80222 2.66594 9.37374 1.79271 9.09382C1.01019 8.75613 -0.13652 8.41569 0.538371 7.90055V7.90055Z"
                stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                strokeWidth={0.666667}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ),
    'Telegram'
);
export default TelegramScaleSmallAppearanceOutlined;
