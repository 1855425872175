import Backbone from 'backbone';
import _ from 'underscore';

/**
 * Plain-model с возможностью указания связей между моделями, для реализации псевдо-многоуровневой модели
 */
export default Backbone.Model.extend({
    /**
     * Поля модели по умолчанию, могут быть переданы в виде опций при инициализации модели
     * Поле children является внутренним и снаружи следует передавать список опций в виде массива items
     *
     * @type {Object}
     * @property {Number|String} id     id модели, который будет использоваться для поиска
     *                                  или при формировании связей
     * @property {String} text          текст, который будет отображатсья на тегах
     * @property {Boolean} expanded     признак того, что тег-предок при наличии потомков будет представлен
     *                                  в развернутом состоянии
     * @property {Boolean} selectable   признак возможности кликнуть на текст
     * @property {String|Number} parent id родителя, если явно передан в параметрах модели, то тег считается
     *                                  тегом-потомком и будет добавлен к соответствующему родителю
     * @property {Array}                массив объектов, представляющих вложенные объекты
     */
    defaults: {
        id: null,
        text: '',
        expanded: false,
        children: [],
        parent: null,
        items: null,
        hiddenValue: null,
        animated: true,
        selectable: false,
        disabled: false,
        editable: false,
        edited: false,
    },

    initialize() {
        this.set('id', this.get('id').toString(), { silent: true });
    },

    /**
     * Возвращает модель в виде иерархического JS Object, где потомки находятся в массиве items
     * @return {Object} клон атрибутов модели
     */
    toJSON(fromParent) {
        const jsonItem = _.clone(this.attributes);
        // _.clone сохраняет вложенные объекты и массивы как ссылки поэтому переопределяем
        jsonItem.items = [];
        jsonItem.removable = this.removable || false;
        jsonItem.editable = this.editable || false;
        jsonItem.selectable = this.selectable || false;
        jsonItem.selected = this.get('selected') || false;
        jsonItem.expanded = this.get('expanded') || false;
        jsonItem.disabled = this.get('disabled') || false;
        jsonItem.isNew = this.get('animated') && (this.hasChanged('children') || this.newTag);
        jsonItem.hiddenValue = this.get('hiddenValue') || this.get('text');

        if (fromParent) {
            this.newTag = false;
        }

        if (this.get('parent') === null) {
            this.newTag = false;

            if (jsonItem.children) {
                jsonItem.children.forEach((childId) => {
                    if (this.collection.get(childId)) {
                        jsonItem.items.push(this.collection.get(childId).toJSON(true));
                    }
                });
            }
            jsonItem.children = [];
        }

        return jsonItem;
    },
});
