import {
    Fragment,
    useCallback,
    useRef,
    useEffect,
    useMemo,
    ReactNode,
    MutableRefObject,
    MouseEventHandler,
    FC,
} from 'react';

import { ACTION_STATES, UseSwipeDetailsType, SwipeDirection } from './constants';
import { SwipeEndHandler, SwipeMoveHandler, SwipePreventHandler, SwipeStartHandler } from './swipeUtils';
import useSwipeEvents from './useSwipeEvents';
import useSwipeUtils, { SetSwipeDetails } from './useSwipeUtils';

export interface SwipeProps {
    /**
     Рендер функция, при вызове которой передается свойство swipeContainerProps
     с необходимыми пропсами для swipe элемента
     */
    children?: (props: {
        swipeContainerProps: {
            ref: MutableRefObject<HTMLDivElement | null>;
            onMouseDown: MouseEventHandler;
            onMouseMove: MouseEventHandler;
            onMouseUp: MouseEventHandler;
            onMouseLeave: MouseEventHandler;
        };
    }) => ReactNode;
    /** Расстояние для QuickSwipe в процентах от размера контейнера */
    quickSwipePercent?: number;
    /** Минимальное расстояние для свайпа в процентах от размера контейнера */
    minSwipePercent?: number;
    /**
     Расстояние от границ контейнера (в процентах от ширины),
     при клике или quick-tap на которых необходимо осуществлять свайп
     */
    maxBorderOffsetPercent?: number;
    /** Колбек, вызывающийся, когда свайп не был закончен, необходимо откатить состояние к начальному */
    onSwipePrevent?: SwipePreventHandler;
    /** Колбек, вызывающийся, когда свайп закончился корректно */
    onSwipeEnd?: SwipeEndHandler;
    /** Колбек, вызывающийся, когда свайп начался */
    onSwipeStart?: SwipeStartHandler;
    /** Колбек, вызывающийся, когда с пользователь двигает пальцем или мышкой */
    onSwipeMove?: SwipeMoveHandler;
    /** Направление свайпа (по горизонтали/вертикали) */
    direction?: SwipeDirection;
}

const defaultProps = {
    quickSwipePercent: 6,
    minSwipePercent: 20,
    maxBorderOffsetPercent: 20,
    direction: SwipeDirection.Horizontal,
};

const Swipe: FC<SwipeProps> = ({
    children,
    quickSwipePercent = defaultProps.quickSwipePercent,
    minSwipePercent = defaultProps.minSwipePercent,
    maxBorderOffsetPercent = defaultProps.maxBorderOffsetPercent,
    onSwipePrevent,
    onSwipeEnd,
    onSwipeStart,
    onSwipeMove,
    direction = defaultProps.direction,
    ...restProps
}) => {
    const containerEl = useRef<HTMLDivElement | null>(null);
    const swipeDetails = useRef<UseSwipeDetailsType>({
        type: ACTION_STATES.NONE,
        moveStarted: false,
        isSwipingHorizontally: false,
        clientX: 0,
        clientY: 0,
        swipeDistance: 0,
        isQuickSwipe: false,
        quickSwipeTimeout: null,
        isScrolling: false,
        directionWay: direction,
    });
    const setSwipeDetails: SetSwipeDetails = useCallback((values) => {
        Object.assign(swipeDetails.current, values);
    }, []);

    const { swipeMove, swipeStart, swipeEnd } = useSwipeUtils({
        quickSwipePercent,
        minSwipePercent,
        maxBorderOffsetPercent,
        containerEl,
        setSwipeDetails,
        swipeDetails,
        onSwipePrevent,
        onSwipeEnd,
        onSwipeStart,
        onSwipeMove,
        direction,
    });

    const { onTouchStart, onTouchMove, onTouchEnd, onTouchCancel, onMouseDown, onMouseMove, onMouseUp, onMouseLeave } =
        useSwipeEvents({
            setSwipeDetails,
            swipeDetails,
            swipeMove,
            swipeStart,
            swipeEnd,
        });

    useEffect(() => {
        containerEl?.current?.addEventListener('touchstart', onTouchStart, { passive: false });
        containerEl?.current?.addEventListener('touchmove', onTouchMove, { passive: false });
        containerEl?.current?.addEventListener('touchend', onTouchEnd, { passive: false });
        containerEl?.current?.addEventListener('touchcancel', onTouchCancel, { passive: false });

        const removeEvents = () => {
            containerEl?.current?.removeEventListener('touchstart', onTouchStart);
            containerEl?.current?.removeEventListener('touchmove', onTouchMove);
            containerEl?.current?.removeEventListener('touchend', onTouchEnd);
            containerEl?.current?.removeEventListener('touchcancel', onTouchCancel);
        };

        return removeEvents;
    }, [onTouchCancel, onTouchEnd, onTouchMove, onTouchStart]);

    const swipeContainerProps = useMemo(
        () => ({
            ref: containerEl,
            onMouseDown,
            onMouseMove,
            onMouseUp,
            onMouseLeave,
        }),
        [onMouseDown, onMouseLeave, onMouseMove, onMouseUp]
    );

    return <Fragment>{children && children({ swipeContainerProps, ...restProps })}</Fragment>;
};

export { SwipeDirection };
export default Swipe;
