import { useState, useCallback, ChangeEvent, ChangeEventHandler } from 'react';

interface UseInputStateProps<V, OC> {
    controlledValue?: V;
    defaultValue?: V;
    onChangeProp?: OC;
}

interface UseInputState<V, OC> {
    value: V;
    onChange: OC;
}

type SimpleOnChange<T> = (value: T) => void;
interface UseInputStateHook {
    <V>(params: UseInputStateProps<V, SimpleOnChange<V>>): UseInputState<V, SimpleOnChange<V>>;
    <V>(params: UseInputStateProps<V, ChangeEventHandler>): UseInputState<V, ChangeEventHandler>;
}

const isEvent = <T>(value: T | ChangeEvent): value is ChangeEvent<HTMLInputElement | HTMLTextAreaElement> =>
    !!(value as ChangeEvent)?.target;

const useInputState: UseInputStateHook = ({ controlledValue, defaultValue, onChangeProp }) => {
    const [internalValue, setInternalValue] = useState(defaultValue);

    // Универсальный onChange для controlled/uncontrolled components
    const onChange = useCallback(
        (newValueOrEvent: typeof controlledValue & ChangeEvent<Element>): void =>
            onChangeProp
                ? onChangeProp(newValueOrEvent)
                : setInternalValue(
                      isEvent(newValueOrEvent)
                          ? (newValueOrEvent.target.value as typeof controlledValue)
                          : newValueOrEvent
                  ),
        [onChangeProp]
    );

    return {
        value: onChangeProp || controlledValue ? controlledValue : internalValue,
        onChange,
    };
};

export default useInputState;
