import { forwardRef } from 'react';
import classNames from 'classnames';

import styles from 'bloko/blocks/vSpacing/vSpacing.less';

export const spacingMultipliers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14] as const;
export type SpacingMultipliers = (typeof spacingMultipliers)[number];

export interface VSpacingBaseProps {
    /** Базовый множитель отступа */
    base: SpacingMultipliers;
    /** Переопределение отступа для xs */
    xs?: SpacingMultipliers;
    /** Указывает на строку с компонентом в исходном коде в режиме разработки. Генерируется babel-plugin-react-source */
    source?: string;
}

const VSpacing = forwardRef<HTMLDivElement, VSpacingBaseProps>(({ base, xs, source }, forwardRef) => (
    <div
        ref={forwardRef}
        className={classNames(styles['bloko-v-spacing'], styles[`bloko-v-spacing_base-${base}`], {
            [styles[`bloko-v-spacing_xs-${xs as SpacingMultipliers}`]]: xs !== undefined,
        })}
        source={source}
    />
));

export default VSpacing;
