import { useRef, useEffect, useState } from 'react';
import classnames from 'classnames';

import { ComponentWithCustomElement } from 'bloko/common/helpers/types';
import transition from 'bloko/common/transition';

import styles from 'bloko/blocks/form/form.less';

const getHeight = (element: HTMLElement) => {
    element.classList.remove(styles['bloko-form-error_hidden']);
    element.style.height = 'auto';
    const height = element.clientHeight - parseInt(getComputedStyle(element).paddingBottom, 10);

    return `${height}px`;
};

interface FormErrorProps {
    /** Кастомный компонент или ('div', 'span')*/
    Element?: 'span' | 'div';
    /** Флаг показываем ошибку или нет */
    show?: boolean;
    /** Указывает на строку с компонентом в исходном коде в режиме разработки. Генерируется babel-plugin-react-source */
    source?: string;
}

const FormError: ComponentWithCustomElement<FormErrorProps, 'div'> = ({
    Element = 'div',
    children,
    show = false,
    source,
    ...props
}) => {
    const errorElement = useRef<HTMLInputElement>(null);
    const [forceShow, setForceShow] = useState(show);

    useEffect(() => {
        if (show) {
            setForceShow(true);
            if (forceShow && errorElement.current) {
                const height = getHeight(errorElement.current);
                errorElement.current.classList.add(styles['bloko-form-error_entering']);
                errorElement.current.style.height = '0';
                requestAnimationFrame(() => {
                    if (errorElement.current) {
                        errorElement.current.style.height = height;
                    }
                });
            }
        } else if (errorElement.current) {
            errorElement.current.classList.add(styles['bloko-form-error_exiting']);
            errorElement.current.style.height = '0';
            return transition(errorElement.current, () => {
                setForceShow(false);
            });
        }

        return undefined;
    }, [show, forceShow, children]);

    if (!forceShow) {
        return null;
    }

    return (
        <Element
            {...props}
            className={classnames(styles['bloko-form-error'], styles['bloko-form-error_hidden'])}
            ref={errorElement}
            source={source}
        >
            {children}
        </Element>
    );
};

export default FormError;
