import React, { FC, PropsWithChildren } from 'react';

import Badge, { BadgeAppearance } from 'bloko/blocks/badge';

import Button, {
    ButtonAppearance,
    ButtonIconPosition,
    ButtonKind as ButtonKindBase,
    ButtonProps,
} from 'bloko/blocks/button';

import styles from 'bloko/blocks/button/button.less';

export enum ButtonKind {
    Primary = ButtonKindBase.Primary,
    Warning = ButtonKindBase.Warning,
    Success = ButtonKindBase.Success,
    Inversed = ButtonKindBase.Inversed,
}

interface ButtonBadgeProps extends Omit<ButtonProps, 'iconPosition' | 'kind'> {
    /** Значение бейджа */
    badgeValue: number;
    /** Тип компонента */
    kind?: ButtonKind;
}

const BADGE_APPEARANCE_MAP: Record<ButtonKind, BadgeAppearance> = {
    [ButtonKind.Primary]: BadgeAppearance.Primary,
    [ButtonKind.Warning]: BadgeAppearance.Warning,
    [ButtonKind.Success]: BadgeAppearance.Success,
    [ButtonKind.Inversed]: BadgeAppearance.Inversed,
};

const getBadgeAppearance = (kind?: ButtonKind, appearance: ButtonAppearance = ButtonAppearance.Filled) => {
    if (kind === ButtonKind.Inversed && appearance === ButtonAppearance.Filled) {
        return BadgeAppearance.Default;
    }

    return kind ? BADGE_APPEARANCE_MAP[kind] : BadgeAppearance.Default;
};

const ButtonBadge: FC<ButtonBadgeProps & PropsWithChildren> = ({ badgeValue, kind, children, ...buttonProps }) => {
    const badge = badgeValue ? (
        <span className={styles['bloko-button__badge']}>
            <Badge value={badgeValue} appearance={getBadgeAppearance(kind, buttonProps.appearance)} />
        </span>
    ) : null;

    return (
        <Button
            {...buttonProps}
            kind={kind as unknown as ButtonKindBase}
            iconPosition={buttonProps.icon ? ButtonIconPosition.Left : undefined}
        >
            {children}
            {badge}
        </Button>
    );
};

export default ButtonBadge;
