import Metrics from 'bloko/common/metrics';
import { BoundingClientRect } from 'bloko/common/types';

import { GUARD_SIZE, OFFSET_TO_ARROW_X, OFFSET_TO_ARROW_Y } from 'bloko/blocks/drop/constants';
import { getIntersectionY, getIntersectionX } from 'bloko/blocks/drop/getIntersection';

interface PositionParams {
    metrics: BoundingClientRect;
    viewport: BoundingClientRect;
    elementMetrics: BoundingClientRect;
}

interface PositionOutput {
    success: boolean;
    metrics: BoundingClientRect;
}

export default {
    x({ metrics, viewport, elementMetrics }: PositionParams): PositionOutput {
        let intersection = getIntersectionX(metrics, viewport);
        if (!intersection) {
            return {
                success: false,
                metrics,
            };
        }

        const { start, end } = intersection;
        const changedMetrics = { ...metrics };
        if (metrics.left !== start) {
            changedMetrics.left = start;
            changedMetrics.right = changedMetrics.left + changedMetrics.width;
        }
        if (metrics.right !== end) {
            changedMetrics.right = end;
            changedMetrics.left = changedMetrics.right - changedMetrics.width;
        }

        intersection = getIntersectionX(
            changedMetrics,
            elementMetrics,
            Math.min(OFFSET_TO_ARROW_X + GUARD_SIZE, elementMetrics.width)
        );
        if (!intersection) {
            return {
                success: false,
                metrics,
            };
        }

        return {
            success: Metrics.isRectangleInRectangle(changedMetrics, viewport),
            metrics: changedMetrics,
        };
    },
    y({ metrics, viewport, elementMetrics }: PositionParams): PositionOutput {
        let intersection = getIntersectionY(metrics, viewport);
        if (!intersection) {
            return {
                success: false,
                metrics,
            };
        }

        const { start, end } = intersection;
        const changedMetrics = { ...metrics };
        if (metrics.top !== start) {
            changedMetrics.top = start;
            changedMetrics.bottom = changedMetrics.top + changedMetrics.height;
        }
        if (metrics.bottom !== end) {
            changedMetrics.bottom = end;
            changedMetrics.top = changedMetrics.bottom - changedMetrics.height;
        }

        intersection = getIntersectionY(
            changedMetrics,
            elementMetrics,
            Math.min(OFFSET_TO_ARROW_Y + GUARD_SIZE, elementMetrics.height)
        );
        if (!intersection) {
            return {
                success: false,
                metrics,
            };
        }

        return {
            success: Metrics.isRectangleInRectangle(changedMetrics, viewport),
            metrics: changedMetrics,
        };
    },
};
