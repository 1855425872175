import Debug from 'bloko/common/core/Debug';
import urlParser from 'bloko/common/urlParser';

/**
 * Yandex.Maps JS API NS
 * @typedef {Object} YMapsAPI
 */

/**
 * @desc Функция для получения API Яндекс.Карт.
 * @param {Object} params - параметры инициализации для API Яндекс.Карт
 * @param {String} [params.version='2.1.59'] - подключаемая версия API.
 *      По умолчанию — 2.1.59, последняя с поддержкой IE < 11.
 *      На странице возможно использовать только одну версию API.
 * @param {String} [params.lang='ru_RU'] - локаль, которая будет использоваться
 *      при отрисовке карт, геокодинге и при запросах к геосаджесту.
 * @param {String} [params.apiKey='9579efd9-168f-4cdd-8940-2fbd5548bc5b'] - ключ для доступа к картам
 * @param {String} [params.suggestApiKey='ab255225-48b2-4cc9-84e7-25979be3fee2'] - ключ для доступа к Геосаджесту карт
 * @param {Boolean} [params.rejectOnError=false] — реджектить ли промис карт при ошибке.
 * @returns {Promise<YMapsAPI>}
 */
const getYmapsPromise = ({
    version = '2.1.79',
    lang = 'ru_RU',
    apiKey = '9579efd9-168f-4cdd-8940-2fbd5548bc5b',
    suggestApiKey = 'ab255225-48b2-4cc9-84e7-25979be3fee2',
    rejectOnError = false,
} = {}) => {
    if (!window.blokoYmapsPromise) {
        window.blokoYmapsPromise = new Promise((resolve, reject) => {
            window.blokoYmapsPromiseResolve = resolve;
            window.blokoYmapsPromiseReject = (reason) => {
                if (rejectOnError) {
                    reject(reason);
                } else {
                    Debug.log('error', `Ymaps error: ${reason}`);
                }
            };

            const ymapsUrl = urlParser(`https://api-maps.yandex.ru/${version}/`);
            ymapsUrl.params = {
                onload: ['blokoYmapsPromiseResolve'],
                onerror: ['blokoYmapsPromiseReject'],
                lang: [lang],
                apikey: [apiKey],
                /* eslint-disable-next-line camelcase */
                suggest_apikey: [suggestApiKey],
            };

            const script = document.createElement('script');
            script.onerror = () => {
                const message = `Ymaps loading error: ${ymapsUrl.href}`;
                if (rejectOnError) {
                    reject(new Error(message));
                } else {
                    Debug.log('error', message);
                }
            };
            script.src = ymapsUrl.href;
            document.head.appendChild(script);
        });
    }
    return window.blokoYmapsPromise;
};

export default getYmapsPromise;
