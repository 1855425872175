import { DropLayer } from 'bloko/blocks/drop/constants';
import { Placement } from 'bloko/common/metrics';

import styles from 'bloko/blocks/drop/Tip/tip.less';

export { DropLayer as TipLayer };

export const ANIMATION_TIMEOUT_MS = 32;
export const ARROW_SIZE = 5;
export const BASE_CLASS_NAMES = [styles['bloko-drop'], styles['bloko-drop_tip']] as const;

export const TipPlacement = {
    Top: Placement.Top,
    Right: Placement.Right,
    Bottom: Placement.Bottom,
    Left: Placement.Left,
} as const;
type ValueOf<T> = T[keyof T];
export const PLACEMENT_TIP_SEQUENCE = Object.values(TipPlacement);
export type TipPlacementType = ValueOf<typeof TipPlacement>;

export enum TipTheme {
    Dark = 'dark',
    Attention = 'attention',
}

export default {
    theme: TipTheme.Dark,
    layer: DropLayer.AboveContent,
    placement: TipPlacement.Top,
    dataQa: 'bloko-drop-tip',
    show: false,
    host: null,
    flexible: false,
};
