import Components from 'bloko/common/core/Components';

import treeSelectorPopupResultListTemplate from 'bloko/blocks/treeSelectorPopup/treeSelectorPopupResultListTemplate.mustache';

import TreeSelectorHelper from '../treeSelector/treeSelectorHelper';

/**
 * Отображает списком выбранные элементы дерева
 *
 */

/**
 * @exports bloko/blocks/treeSelectorPopup/treeSelectorPopupResultList
 *
 * @param {Element} element DOM элемент
 * @param {Object} options
 * @param {Array} [options.items=[]]         Массив выбранных узлов дерева
 * @param {String} options.treeSelectorType  Тип дерева
 * @param {Function} options.onChange        Колбэк срабатывает при изменении состояния элемента списка
 * @constructor
 */
const TreeSelectorPopupResultList = function (element, options) {
    const onChange = options.onChange;

    const listElementWrapper = document.createElement('div');
    let listElement = null;

    setValue(options.items || []);

    function render(items) {
        listElementWrapper.innerHTML = treeSelectorPopupResultListTemplate.render({
            hidden: !items.length,
            elements: items,
        });
        element.innerHTML = '';

        listElement = listElementWrapper.firstChild;
        listElement.addEventListener('click', (e) => {
            onChange(items.filter((item) => item.id !== e.target.value));
        });

        element.append(listElement);
    }

    /**
     * Задать значение
     * @param {Array} selectedItems список выбранных узлов
     */
    function setValue(selectedItems) {
        const resultListValues =
            options.treeSelectorType === 'checkbox'
                ? TreeSelectorHelper.getSelectedItemsLeavesOnly(selectedItems)
                : selectedItems;

        render(resultListValues);
    }

    /**
     * Переключить видимость компонента
     * @param {Boolean} show показать/спрятать
     */
    function toggle(show) {
        listElement?.classList.toggle('g-hidden', !show);
    }

    return { setValue, toggle };
};

export default Components.build({
    create: TreeSelectorPopupResultList,
});
