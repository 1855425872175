/**
 * Создаёт переключатель для набора с возможностью выбора только одного элемента.
 * @returns {SetToggler}
 * @constructor
 */
function createSingleValueToggler() {
    /**
     * Переключает элемент в наборе, при необходимости сбрасывая предыдущий выбранный.
     * @param {Set} set Исходный набор
     * @param {String} id Переключаемый элемент
     * @param {Boolean} state Новое состояние переключаемого элемента (true — в наборе, false — нет)
     * @returns {Boolean} Признак того, что набор изменился.
     */
    function toggle(set, id, state) {
        /**
         * Всего возможно 6 случаев:
         * 1. toggle([2], 2, true)  => [2], return false
         * 2. toggle([1], 2, true)  => [2], return true
         * 3. toggle([ ], 2, true)  => [2], return true
         * 4. toggle([2], 2, false) => [ ], return true
         * 5. toggle([1], 2, false) => [1], return false
         * 6. toggle([ ], 2, false) => [ ], return false
         */
        if (state) {
            if (set.has(id)) {
                // 1
                return false;
            }
            if (set.size) {
                // 2
                set.clear();
            }
            // 3
            set.add(id);
            return true;
        } else if (set.has(id)) {
            // 4
            set.delete(id);
            return true;
        }
        // 5, 6
        return false;
    }

    return toggle;
}

export default createSingleValueToggler;
