import { ComponentPropsWithoutRef, FC, PropsWithChildren } from 'react';

import styles from 'bloko/blocks/header/header.less';

const SubHeader: FC<ComponentPropsWithoutRef<'div'> & PropsWithChildren> = ({ children, ...rest }) => {
    return (
        <div {...rest} className={styles['bloko-header-subheader']}>
            {children}
        </div>
    );
};

export default SubHeader;
