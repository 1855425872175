import $ from 'jquery';

import { MinusScaleSmallEnclosedFalse, PlusScaleSmallEnclosedFalse } from 'bloko/blocks/icon';
import IconReactRenderer from 'bloko/blocks/icon/IconReactRenderer';
import NumericInput from 'bloko/blocks/numericInput/numericInput';
import { KeyCode } from 'bloko/common/constants/keyboard';
import Mouse from 'bloko/common/constants/mouse';
import Components from 'bloko/common/core/Components';

import incrementalNumericTemplate from 'bloko/blocks/incrementalNumericInput/incrementalNumericInput.mustache';

const bindings = {
    minus: '.Bloko-IncrementalNumericInput-Minus',
    plus: '.Bloko-IncrementalNumericInput-Plus',
    inputContainer: '.Bloko-IncrementalNumericInput-InputContainer',
};

// Периодичность с которой будут изменяться значения при зажатой кнопке +/-
const ITERATION_PERIOD = 100;
// Время удержания кнопки для запуска периодических изменений
const PERIODICAL_CHANGE_TIMEOUT = 300;
// Таймаут от начала события touchStart до начала переодических изменений значения, нужен для
// предотвращения срабатываний при свайпе
const TOUCHSTART_TIMEOUT = 200;
const TOUCHSTART_MAX_DELTA = 5;
const DOUBLE_TAP_MAX_DELTA = 300;

export default Components.build({
    defaults: {
        min: 0,
        max: null,
        step: 1,
    },
    /**
     * Генерируется после валидации значения в результате его изменения, содержит поля:
     * * `isValid` - флаг успешности прохождения валидации
     * * `min` - минимальное значение, заданное параметрами инициализации компонента
     * * `max` - максимальное значение, заданное параметрами инициализации компонента
     * * `value` - текущее значение
     * @event Bloko-IncrementalNumericInput-Validation
     */

    /**
     * Генерируется после изменения на элементе.
     * @event change
     */

    /**
     * Компонент для разбиения числа на группы цифр в текстовом инпуте.
     * Позволяет вводить только числа и вставлять в инпут форматированное значение.
     * Хранит отформатированные без пробелов данные в скрытом инпуте.
     *
     * @param {Element} element
     * @param {Object} params           параметры
     * @param {Number} [params.min=0]     Минимальное значение. Если указан `null` ограничение отсутствует.
     * @param {Number} [params.max=null]     Максимальное значение. Если указан null ограничение отсутствует.
     * @param {Number} [params.step=1]    Шаг изменения значений, по-умолчанию `1`.
     *
     * @exports bloko/blocks/incrementalNumericInput/incrementalNumericInput
     * @constructor
     */
    create(element, options) {
        let changePeriodTimer;
        let periodicalChangeTimeout;

        const $element = $(element);
        const $markup = $(incrementalNumericTemplate.render());
        const $minus = $markup.find(bindings.minus);
        const $plus = $markup.find(bindings.plus);

        let currentValue = parseInt($element.val(), 10) || 0;
        let lastTouch = 0;

        Components.make(IconReactRenderer, $('.Bloko-IncrementalNumericInput-MinusIcon', $minus).get(0), {
            IconComponent: MinusScaleSmallEnclosedFalse,
            iconProps: {},
        });
        Components.make(IconReactRenderer, $('.Bloko-IncrementalNumericInput-PlusIcon', $plus).get(0), {
            IconComponent: PlusScaleSmallEnclosedFalse,
            iconProps: {},
        });

        // добавляем $markup в документ рядом с $element, после чего перемещаем $element внутрь $markup
        $markup.insertAfter($element);
        $element.appendTo($markup.find(bindings.inputContainer));

        Components.make(NumericInput, $element[0], { decimalLength: 0 });

        $element.on('input change', () => {
            const value = parseInt($element.val(), 10);
            if (value !== currentValue) {
                currentValue = value;
                checkValidity();
                updateButtonsState();
            }
        });

        $element.on('keydown', (event) => {
            if ([KeyCode.ArrowUp, KeyCode.ArrowDown].indexOf(event.keyCode) !== -1) {
                event.preventDefault();
                changeValue(event.keyCode === KeyCode.ArrowUp ? 1 : -1);
            }
        });

        $minus.on('mousedown', startPeriodicalChange.bind(null, -1));
        $minus.on('click', changeValue.bind(null, -1));
        $plus.on('mousedown', startPeriodicalChange.bind(null, 1));
        $plus.on('click', changeValue.bind(null, 1));
        $minus.on('touchstart', startPeriodicalChangeOnTouch.bind(null, -1));
        $plus.on('touchstart', startPeriodicalChangeOnTouch.bind(null, 1));

        updateButtonsState();

        function changeValue(multiplier) {
            const changeBy = multiplier * options.step;
            const value = currentValue || 0;
            let newValue = value + changeBy;

            if (options.min !== null) {
                newValue = Math.max(options.min, newValue);
            }

            if (options.max !== null) {
                newValue = Math.min(options.max, newValue);
            }

            setValue(newValue);
        }

        function setValue(value) {
            if (value !== currentValue) {
                currentValue = value;
                $element.val(currentValue);
                updateButtonsState();
                checkValidity();
                $element.trigger('change');
            }
        }

        function preventZoomOnDoubleTap(event) {
            const delta = event.timeStamp - lastTouch;
            lastTouch = event.timeStamp;

            if (delta < DOUBLE_TAP_MAX_DELTA && event.originalEvent.touches.length === 1) {
                event.preventDefault();
            }
        }

        function startPeriodicalChangeOnTouch(multiplier, event) {
            preventZoomOnDoubleTap(event);

            let isMoved = false;
            const oldPosition = {
                x: event.originalEvent.touches[0].screenX,
                y: event.originalEvent.touches[0].screenY,
            };

            function getEventsDistance(moveEvent) {
                const newPosition = moveEvent.originalEvent.touches[0];

                const delta = Math.sqrt(
                    Math.pow(newPosition.screenX - oldPosition.x, 2) + Math.pow(newPosition.screenY - oldPosition.y, 2)
                );
                isMoved = delta > TOUCHSTART_MAX_DELTA;
            }

            $(document).one('touchend', () => {
                $(document).off('touchmove', getEventsDistance);
                isMoved = true;
            });
            $(document).on('touchmove', getEventsDistance);

            setTimeout(() => {
                if (!isMoved) {
                    startPeriodicalChange(multiplier, event);
                }
                $(document).off('touchmove', getEventsDistance);
            }, TOUCHSTART_TIMEOUT);
        }

        function startPeriodicalChange(multiplier, event) {
            if (event.button === Mouse.BUTTON_LEFT || event.type === 'touchstart') {
                clearTimeout(changePeriodTimer);
                clearTimeout(periodicalChangeTimeout);
                $(document).one('mouseup touchend', () => {
                    clearTimeout(changePeriodTimer);
                    clearTimeout(periodicalChangeTimeout);
                });
                periodicalChangeTimeout = setTimeout(() => {
                    changePeriodTimer = setInterval(changeValue.bind(null, multiplier), ITERATION_PERIOD);
                }, PERIODICAL_CHANGE_TIMEOUT);
            }
        }

        function updateButtonsState() {
            $minus.prop('disabled', options.min !== null && currentValue <= options.min);
            $plus.prop('disabled', options.max !== null && currentValue >= options.max);
        }

        function checkValidity() {
            const value = $element.val();
            const isValid =
                (options.min === null || value >= options.min) && (options.max === null || value <= options.max);

            $element.trigger('Bloko-IncrementalNumericInput-Validation', {
                isValid,
                min: options.min,
                max: options.max,
                value,
            });
        }
    },
});
