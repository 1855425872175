import $ from 'jquery';

import Suggest from 'bloko/blocks/suggest/suggest';
import Components from 'bloko/common/core/Components';
/**
 * @param {Element} element
 * @param {CompositeSelectionComponentParams} params
 * @param {TreeCollection} params.collection Дерево элементов
 * @param {CollectionFlag} params.selected Список выбранных элементов
 * @param {Boolean} [params.singleChoice=false] Если true, то можно выбрать только один элемент
 * @param {Boolean} [params.leavesOnly=false] Если true, то можно выбрать только элементы без потомков
 * @param {Object} params.selfParams
 * @constructor
 */
function SuggestProxy(element, params) {
    const collection = params.collection;
    const selected = params.selected;

    const $element = $(element);

    if (!params.selfParams.data && !params.selfParams.remote) {
        let suggestData = collection.toList();

        if (params.leavesOnly) {
            suggestData = suggestData.filter((item) => {
                return collection.getChildrenIds(item.id).length === 0;
            });
        }
        params.selfParams.data = {
            items: suggestData,
        };
    }

    const suggestInstance = Components.make(Suggest, element, params.selfParams);

    $element.on('selected.suggest autoselected.suggest', (event, item) => {
        selected.add([item.id]);
        if (!params.singleChoice) {
            suggestInstance.clear();
        }
    });

    $element.on('unselected.suggest', () => {
        // при вводе в инпут невалидного значения, очищаем selected-коллекцию,
        // но не очищаем сам инпут
        if (params.singleChoice) {
            selected.set([]);
        }
    });

    function update() {
        const selectedIds = selected.get();
        if (params.singleChoice) {
            if (selectedIds.length) {
                suggestInstance.selectItemByData(collection.getModel(selectedIds[selectedIds.length - 1]));
                // если suggestInstance.getSelected() не null,
                // значит update вызвался не саджестом и его нужно очистить.
            } else if (suggestInstance.getSelected()) {
                suggestInstance.clear();
            }
        }
    }

    selected.on('change', update);
    update();

    return suggestInstance;
}

export default Components.build({
    create: SuggestProxy,
});
