import React from 'react';
import classnames from 'classnames';
import { Merge } from 'type-fest';

import { DocumentedPropsWithChildren } from 'bloko/common/helpers/types';

import styles from 'bloko/blocks/controlGroup/controlGroup.less';

import ControlGroupItem, { ControlGroupItemProps } from './ControlGroupItem';

const isCountrolGroupItem = (child: React.ReactNode): child is React.ReactElement<ControlGroupItemProps> => {
    return typeof child === 'object' && child !== null && 'type' in child && child.type === ControlGroupItem;
};

interface ControlGroupProps {
    /** Должна ли группа иметь ширину по контенту */
    autosize?: boolean;
    /** Должна ли группа быть вертикально ориентированной */
    vertical?: boolean;
}

const ControlGroup: React.FC<
    Merge<JSX.IntrinsicElements['div'], DocumentedPropsWithChildren<ControlGroupProps>> & React.PropsWithChildren
> = ({ children, autosize, vertical, ...props }) => {
    const count = React.Children.toArray(children).filter(
        (child) => isCountrolGroupItem(child) && child.props.main
    ).length;
    const childProps = { autosize, vertical, count };
    return (
        <div
            {...props}
            className={classnames(styles['bloko-control-group'], {
                [styles['bloko-control-group_auto-size']]: autosize,
                [styles['bloko-control-group_vertical']]: vertical,
            })}
        >
            {React.Children.map(children, (child) =>
                isCountrolGroupItem(child) ? React.cloneElement(child, childProps) : child
            )}
        </div>
    );
};

export default ControlGroup;
export { ControlGroupItem };
