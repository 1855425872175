import { useCallback } from 'react';

import ImmutableSelectionStrategy from 'bloko/common/tree/immutableSelectionStrategy';
import TreeCollection from 'bloko/common/tree/treeCollection';
import {
    getIdsWithNoParentsInSameList,
    narrowDownExcludedFromChildrenToParents,
} from 'bloko/common/tree/treeCollectionHelper';
import { AdditionalDefault } from 'bloko/common/tree/types';

/*
    Кастомный хук возвращающий хендлеры для работы с treeSelectorPopup
*/

interface UseTreeSelectorHandlersHookProps<A extends AdditionalDefault> {
    collection: TreeCollection<A>;
    value: string[];
    excludedValue: string[];
    selected: string[];
    excluded: string[];
    selectionStrategy: InstanceType<typeof ImmutableSelectionStrategy>;
    onChange?: (ids: string[], excludedIds: string[]) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    setVisible: (visible: boolean) => void;
    setSelected: (selected: string[], excluded: string[]) => void;
}

interface UseTreeSelectorHandlersHookResult {
    handleTreeSelectorPopupClose: () => void;
    handleTreeSelectorPopupSubmit: (selected: string[]) => void;
    handleTreeSelectorChange: (id: string, isSelected: boolean) => void;
    showTreeSelectorPopup: () => void;
}

interface UseTreeSelectorHandlersHook {
    <A extends AdditionalDefault>(props: UseTreeSelectorHandlersHookProps<A>): UseTreeSelectorHandlersHookResult;
}

const useTreeSelectorHandlers: UseTreeSelectorHandlersHook = ({
    collection,
    value,
    excludedValue,
    selected,
    excluded,
    selectionStrategy,
    onChange,
    onFocus,
    onBlur,
    setVisible,
    setSelected,
}) => {
    const handleTreeSelectorPopupClose = useCallback((): void => {
        setVisible(false);
        setSelected(value, excludedValue);
        onBlur?.();
    }, [setVisible, onBlur, setSelected, value, excludedValue]);

    const handleTreeSelectorPopupSubmit = useCallback(
        (selected: string[]): void => {
            onChange?.(
                getIdsWithNoParentsInSameList(collection, selected, excluded),
                narrowDownExcludedFromChildrenToParents(collection, excluded)
            );
            onBlur?.();
        },
        [collection, onBlur, onChange, excluded]
    );

    const handleTreeSelectorChange = useCallback(
        (id: string, isSelected: boolean): void => {
            const ids = [id];
            const result = isSelected ? selectionStrategy.add(selected, ids) : selectionStrategy.remove(selected, ids);

            setSelected(result, selectionStrategy.exclude(result, excluded, ids));
        },
        [selected, selectionStrategy, setSelected, excluded]
    );

    const showTreeSelectorPopup = useCallback((): void => {
        setVisible(true);
        onFocus?.();
    }, [onFocus, setVisible]);

    return {
        handleTreeSelectorPopupClose,
        handleTreeSelectorPopupSubmit,
        handleTreeSelectorChange,
        showTreeSelectorPopup,
    };
};

export default useTreeSelectorHandlers;
