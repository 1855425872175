import $ from 'jquery';

import Components from 'bloko/common/core/Components';
import Debug from 'bloko/common/core/Debug';
import RequestAnimation from 'bloko/common/requestAnimation';

import autogrowCommon from 'bloko/blocks/autogrow/common';

/**
 * @param {Element} element DOM элемент
 *
 * @param {Object} params Параметры
 *
 * @param {Number} params.maxHeight Задает максимальную высоту для textarea
 *
 * @param {Number} params.minHeight Задает минимальную высоту для textarea. Если не установлено, то минимальным
 * значением будет то, что было при инициализации компонента.
 *
 * @constructor
 */
const Autogrow = function (element, params) {
    let $textarea;
    let defaultMinimumHeight;

    if (element.nodeName !== 'TEXTAREA') {
        Debug.log('out error', new Error('Autogrow: Given element is not textarea'));
        return;
    }

    function updateHeight() {
        RequestAnimation(() => {
            autogrowCommon($textarea[0], defaultMinimumHeight, params.maxHeight);
        })();
    }

    $textarea = $(element);
    defaultMinimumHeight = params.minHeight || $textarea.outerHeight();

    $textarea.css({
        overflow: 'hidden',
    });

    // Исправляем баг рендера хрома
    // Ему нужен дополнительный пропин для рендера textarea без учета скроллбара
    // Только 'overflow: hidden' не достаточно
    // Баг зависит от времени загрузки страницы, встречается не всегда
    $textarea[0].value += ' ';
    $textarea[0].value = $textarea[0].value.slice(0, -1);

    $(window).on('resize', updateHeight);
    $(document).on('possible-resize', updateHeight);
    $textarea.on('input change', updateHeight);

    updateHeight();
};

export default Components.build({
    create: Autogrow,
});
