import { TupleOf } from 'bloko/common/helpers/types';

export enum WeekdayRu {
    Mon = 'пн',
    Tue = 'вт',
    Wed = 'ср',
    Thu = 'чт',
    Fri = 'пт',
    Sat = 'сб',
    Sun = 'вс',
}

export enum WeekdayEn {
    Mon = 'mon',
    Tue = 'tue',
    Wed = 'wed',
    Thu = 'thu',
    Fri = 'fri',
    Sat = 'sat',
    Sun = 'sun',
}

export enum MonthRu {
    Jan = 'Январь',
    Feb = 'Февраль',
    Mar = 'Март',
    Apr = 'Апрель',
    May = 'Май',
    Jun = 'Июнь',
    Jul = 'Июль',
    Aug = 'Август',
    Sep = 'Сентябрь',
    Oct = 'Октябрь',
    Nov = 'Ноябрь',
    Dec = 'Декабрь',
}

export enum MonthEn {
    Jan = 'January',
    Feb = 'February',
    Mar = 'March',
    Apr = 'April',
    May = 'May',
    Jun = 'June',
    Jul = 'July',
    Aug = 'August',
    Sep = 'September',
    Oct = 'October',
    Nov = 'November',
    Dec = 'December',
}

export type Months = TupleOf<string, 12>;
export type Weekdays = TupleOf<string, 7>;
export enum CalendarLanguage {
    En = 'en',
    Ru = 'ru',
}

export interface CalendarTranslator {
    month: Months;
    weekday: Weekdays;
}

export interface CalendarTranslations {
    months: Months;
    weekdays: Weekdays;
}

export default {
    weekdays: Object.values(WeekdayRu),
    months: Object.values(MonthRu),
};
