import { ReactElement, ComponentPropsWithoutRef, FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

import StepwiseStep from 'bloko/blocks/stepwise/StepwiseStep';

import styles from 'bloko/blocks/stepwise/stepwise.less';

type OneOrArray<T> = T | T[];

interface StepwiseProps extends ComponentPropsWithoutRef<'div'> {
    /** Список [StepwiseStep](/#stepwisestep) */
    children?: OneOrArray<ReactElement<ComponentPropsWithoutRef<typeof StepwiseStep>>>;
    /** Шаги без границ */
    embeddable?: boolean;
}

/**
 * Предназначен для отображения списка шагов при поэтапном заполнении информации.
 */
const Stepwise: FC<StepwiseProps & PropsWithChildren> = ({ children, embeddable, ...stepwiseProps }) => (
    <div
        className={classnames(styles['bloko-stepwise'], {
            [styles['bloko-stepwise_embeddable']]: embeddable,
        })}
        {...stepwiseProps}
    >
        <div className={styles['bloko-stepwise__steps']}>{children}</div>
    </div>
);

export default Stepwise;
export { StepwiseStep };
