import { useEffect, useRef } from 'react';

import RateLimitedAction from '../helpers/rateLimitedAction';

const useDebounce: RateLimitedAction = (callback, waitMS) => {
    const callbackRef = useRef(callback);
    const timeoutIdRef = useRef<null | ReturnType<typeof setTimeout>>(null);

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    const debounced = function (this: unknown, ...args: Parameters<typeof callback>) {
        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
        }

        timeoutIdRef.current = setTimeout(() => {
            callbackRef.current?.apply(this, args);
        }, waitMS);
    };

    debounced.cancel = () => {
        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
            timeoutIdRef.current = null;
        }
    };

    return debounced;
};

export default useDebounce;
