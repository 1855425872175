import { ReactNode, ReactElement, FC } from 'react';

import DropBase from 'bloko/blocks/drop/Base';

import MenuItem from 'bloko/blocks/drop/Menu/Item';
import {
    defaultProps,
    MenuPlacement,
    BASE_CLASS_NAMES,
    PADDING_WRAPPER_CLASS_NAME,
    BEHAVIOR,
    MenuPlacementValue,
    MenuLayer,
} from 'bloko/blocks/drop/Menu/common';

import './menu.less';

export interface MenuProps {
    /** Предпочтительное положение меню, доступны в статическом свойстве [placements](#menu-placements). */
    placement?: MenuPlacementValue;
    /** Выпадать только в заданном направлении, не обрабатывать другие кейсы */
    onlySetPlacement?: boolean;
    /** Class z-index-а меню, доступны в статическом свойстве [layers](#menu-layers) */
    layer?: MenuLayer;
    /** DOM нода хоста в рамках которого нужно рендерить меню, по дефолту рендер будет в родителе компонента.*/
    host?: HTMLElement | null;
    /** Флаг отвечает за показ меню, `true` - показать, `false` - скрыть */
    show?: boolean;
    /** Элемент инициатор, относительно которого показывать меню */
    children: ReactElement;
    /** Метод-рендер контента меню */
    render: () => ReactNode;
    /** dataQa='bloko-drop-menu' Data-qa активного меню */
    dataQa?: string;
    /** Колбек вызываемый при закрытии компонента. Срабатывает в случае клика вне компонента */
    onClose?: () => void;
    /** Метка, использовать ли тянущееся Menu.
     * тянущееся меню будет растягивать по контенту, ограничиваясь только размерами экрана */
    flexible?: boolean;
    /** Заголовок меню для XS. Отображается если передан */
    title?: ReactNode;
}

const DropMenu: FC<MenuProps> = (ownProps) => {
    const props = { ...defaultProps, host: null, ...ownProps };
    return (
        <DropBase
            {...props}
            behavior={BEHAVIOR}
            baseClassNames={BASE_CLASS_NAMES}
            paddingWrapperClassName={PADDING_WRAPPER_CLASS_NAME}
            closeByClickOutside={true}
        />
    );
};

export default DropMenu;
export { MenuPlacement, MenuLayer, MenuItem };
