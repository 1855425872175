import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const StarScaleSmallAppearanceOutlinedEnclosedFalse = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M7.99998 1.33334L10.06 5.50668L14.6666 6.18001L11.3333 9.42668L12.12 14.0133L7.99998 11.8467L3.87998 14.0133L4.66665 9.42668L1.33331 6.18001L5.93998 5.50668L7.99998 1.33334Z"
                stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                strokeWidth={1.33333}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ),
    'Star'
);
export default StarScaleSmallAppearanceOutlinedEnclosedFalse;
