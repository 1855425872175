import { FC, PropsWithChildren } from 'react';
import { Merge } from 'type-fest';

import DropClick, { DropClickPropsCommon } from 'bloko/blocks/drop/Click';

import Menu, { MenuProps, MenuItem } from 'bloko/blocks/drop/Menu';
import { MenuPlacement, MenuLayer } from 'bloko/blocks/drop/Menu/common';

const Click: FC<Merge<MenuProps, DropClickPropsCommon> & PropsWithChildren> = (props) => {
    return <DropClick {...props} DropElement={Menu} />;
};

export default Click;
export { MenuPlacement, MenuLayer, MenuItem };
