import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const PenSquareScaleSmall = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g>
                <path
                    d="M7.33331 2.66669H4.53331C3.41321 2.66669 2.85316 2.66669 2.42533 2.88467C2.04901 3.07642 1.74305 3.38238 1.5513 3.75871C1.33331 4.18653 1.33331 4.74658 1.33331 5.86669V11.4667C1.33331 12.5868 1.33331 13.1468 1.5513 13.5747C1.74305 13.951 2.04901 14.257 2.42533 14.4487C2.85316 14.6667 3.41321 14.6667 4.53331 14.6667H10.1333C11.2534 14.6667 11.8135 14.6667 12.2413 14.4487C12.6176 14.257 12.9236 13.951 13.1153 13.5747C13.3333 13.1468 13.3333 12.5868 13.3333 11.4667V8.66669M5.3333 10.6667H6.44966C6.77578 10.6667 6.93884 10.6667 7.09229 10.6298C7.22834 10.5972 7.3584 10.5433 7.47769 10.4702C7.61225 10.3878 7.72755 10.2724 7.95815 10.0418L14.3333 3.66669C14.8856 3.1144 14.8856 2.21897 14.3333 1.66669C13.781 1.1144 12.8856 1.1144 12.3333 1.66668L5.95814 8.04185C5.72753 8.27245 5.61223 8.38775 5.52978 8.52231C5.45667 8.6416 5.4028 8.77166 5.37014 8.90771C5.3333 9.06116 5.3333 9.22422 5.3333 9.55034V10.6667Z"
                    stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                    strokeWidth={1.33333}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    ),
    'Pen'
);
export default PenSquareScaleSmall;
