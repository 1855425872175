import { useState, useEffect } from 'react';

const useServerEnv = (): boolean => {
    const [isServerENV, setServerENV] = useState(true);

    useEffect(() => {
        setServerENV(false);
    }, []);

    return isServerENV;
};

export default useServerEnv;
