import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const ChevronScaleSmallKindUpDown = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M12 5.33331L8 1.33331L4 5.33331M4 10.6666L8 14.6666L12 10.6666"
                stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                strokeWidth={1.33333}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ),
    'Chevron'
);
export default ChevronScaleSmallKindUpDown;
