import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const EyeScaleSmallCrossedFalse = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M0.666656 7.99999C0.666656 7.99999 3.33332 2.66666 7.99999 2.66666C12.6667 2.66666 15.3333 7.99999 15.3333 7.99999C15.3333 7.99999 12.6667 13.3333 7.99999 13.3333C3.33332 13.3333 0.666656 7.99999 0.666656 7.99999Z"
                stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                strokeWidth={1.33333}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.99999 9.99999C9.10456 9.99999 9.99999 9.10456 9.99999 7.99999C9.99999 6.89542 9.10456 5.99999 7.99999 5.99999C6.89542 5.99999 5.99999 6.89542 5.99999 7.99999C5.99999 9.10456 6.89542 9.99999 7.99999 9.99999Z"
                stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                strokeWidth={1.33333}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ),
    'Eye'
);
export default EyeScaleSmallCrossedFalse;
