import { FC, PropsWithChildren } from 'react';
import { Merge } from 'type-fest';

import DropClick, { DropClickPropsCommon } from 'bloko/blocks/drop/Click';

import Info, { InfoProps } from 'bloko/blocks/drop/Info';

export { InfoPlacement, InfoTheme, InfoLayer } from 'bloko/blocks/drop/Info/constants';

const Click: FC<Merge<InfoProps, DropClickPropsCommon> & PropsWithChildren> = (props) => {
    return (
        <DropClick {...props} DropElement={Info}>
            {props.children}
        </DropClick>
    );
};

export default Click;
