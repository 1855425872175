import $ from 'jquery';

import CommonCssClasses from 'bloko/common/constants/commonCssClasses';
import Components from 'bloko/common/core/Components';

const BLOCK_NAME = 'stateChanger';

const actionEvent = `Bloko-StateChanger-Action.${BLOCK_NAME}`;
const resetEvent = `Bloko-StateChanger-Reset.${BLOCK_NAME}`;
const stateChangeEvent = 'Bloko-StateChanger-StateChange';

/**
 * Объект состояния компонента stateChanger.
 * @typedef {Object} StateChangerState
 * @property {String|Array} action    Действие или набор действий, которые нужно выполнить.
 *                                    Возможные значения:
 * * `show`/`hide` — показать/скрыть
 * * `disable`/`enable` — задизейблить/раздизейблить
 * * `swap` — показать, скрыв другие группы (логика радиобаттона).
 * @property {Boolean} state          Направление действия. Если `action === 'hide'`,
 *                                    значение `true` скрывает группу, а `false` показывает.
 * @property {String} target          Имя группы блоков, с которой работаем. Группа определяется
 *                                    по селектору `[data-change-group~={target}]`.
 * @example
 * Показать элемент с группой `lost-password`, скрыв все элементы с другими группами:
 *     {
 *         action: 'swap',
 *         state: true,
 *         target: 'lost-password'
 *     }
 *
 * @example
 * Задизейблить и скрыть элементы с группой `optional-inputs`, если массив `items` пуст,
 * в противном случае раздизейблить и показать их:
 *     {
 *         action: ['hide', 'disable'],
 *         state: (items.length === 0),
 *         target: 'optional-inputs'
 *     }
 */

/**
 * Используется для устнановки состояния. Триггерится также самим компонентом при установке исходного значения
 *
 * @event Bloko-StateChanger-Action.stateChanger
 * @property {StateChangerState} state Состояние компонента
 */

/**
 * Используется для установки состояния в исходное (`initial`)
 *
 * @event Bloko-StateChanger-Reset.stateChanger
 */

/**
 * Триггерится на конкретном элементе после того как stateChanger изменил его состояние
 * (изменил видимость или доступность)
 *
 * @event Bloko-StateChanger-StateChange
 * @property {String} action Тип изменения
 */

/**
 * Отвечает за смену состояний блока или группы блоков (показывает или скрывает их,
 * делает активными или неактивными).
 *
 * @listens Bloko-StateChanger-Action.stateChanger
 * @listens Bloko-StateChanger-Reset.stateChanger
 * @fires   Bloko-StateChanger-Action.stateChanger
 * @fires   Bloko-StateChanger-StateChange.stateChanger
 *
 * @param {String|jQuery} element Элемент, на котором будет инициализирован компонент
 * @param {Object} params Параметры
 * @param {StateChangerState} [params.initial=null] Начальное состояние. Если указано, при инициализации
 *                                                  компонента выстрелит событие с такими же параметрами
 * @param {String} [params.className=''] CSS-класс для визуального дизейблинга не-инпутов
 * @param {String} [params.selectorToHide='[data-state-changer-hide]' CSS-селектор для скрытия/показа элементов
 * @param {String} [params.selectorToDisable='[data-state-changer-disable]'] CSS-селектор для дизейблинга элементов
 *
 * @constructor
 */
function StateChanger(element, params) {
    const $element = $(element);

    /**
     * Выполняет действие, тип и целевая группа которого задаются параметром `initial`
     * при инициализации компонента.
     * @private
     */
    function _reset() {
        $element.trigger(actionEvent, params.initial);
    }

    /**
     * Показывает или прячет указанную группу блоков, одновременно выполняя обратную операцию со всеми
     * остальными группами внутри указанного блока.
     *
     * @param {jQuery} $currentElement
     * @param {Boolean} state
     */
    function swap($currentElement, state) {
        show($currentElement, state);
        hide($('[data-change-group]', $element).not($currentElement), state);
    }

    function _getGroup($currentElement, groupSelector) {
        const $group = $currentElement.filter(groupSelector);

        return $group.length ? $group : $currentElement;
    }

    /**
     * Делает неактивными все элементы управления с указанным классом в параметрах компонента
     * внутри указанной группы.
     *
     * @param {jQuery} $currentElement
     * @param {Boolean} state
     */
    function disable($currentElement, state) {
        const $group = _getGroup($currentElement, params.selectorToDisable);

        const inputs = $group.filter(':input');
        const nonInputs = $group.filter(':not(:input)');

        nonInputs.toggleClass(params.className, state);
        inputs.prop('disabled', state);

        $group.trigger(stateChangeEvent, {
            action: state ? 'disable' : 'enable',
        });
    }

    /**
     * Скрывает все блоки с классом selectorToHide внутри указанной группы.
     *
     * @param {jQuery} $currentElement
     * @param {Boolean} state
     */
    function hide($currentElement, state) {
        _getGroup($currentElement, params.selectorToHide)
            .toggleClass(CommonCssClasses.Hidden, state)
            .trigger(stateChangeEvent, { action: state ? 'hide' : 'show' });
    }

    /**
     * Показывает все блоки с селектором selectorToHide внутри указанной группы.
     *
     * @param {jQuery} $currentElement
     * @param {Boolean} state
     */
    function show($currentElement, state) {
        hide($currentElement, !state);
    }

    /**
     * Делает активными все элементы управления с селектором selectorToDisable внутри указанной группы.
     *
     * @param {jQuery} $currentElement
     * @param {Boolean} state
     */
    function enable($currentElement, state) {
        disable($currentElement, !state);
    }

    const publicInterface = {
        show,
        hide,
        enable,
        disable,
        swap,
    };

    $element.on(actionEvent, (event, params) => {
        [].concat(params.action).forEach((value) => {
            publicInterface[value](
                $element.find(`[data-change-group~=${params.target}]`),
                params.state === undefined ? true : params.state
            );
        }, null);
    });

    if (params.initial) {
        _reset();
        $element.on(resetEvent, _reset);
    }

    return publicInterface;
}

export default Components.build({
    defaults: {
        initial: null,
        className: '',
        selectorToHide: '[data-state-changer-hide]',
        selectorToDisable: '[data-state-changer-disable]',
    },
    create: StateChanger,
});
