import escapeRegexp from 'bloko/common/escapeRegexp';

/**
 * Substitute substrings
 * @param  {String} trlValue        Template
 * @param  {Object} formatValues    Data
 * @returns {String}                Formatted string
 */
const format = (trlValue: string, formatValues: Record<string, string | number>): string => {
    const keyRegexp = new RegExp(
        Object.keys(formatValues)
            .map((key) => escapeRegexp(key))
            .join('|'),
        'g'
    );
    return trlValue.replace(keyRegexp, (key) => `${formatValues[key]}`);
};

export default format;
