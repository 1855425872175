import React, { PureComponent, ReactPortal } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';

import { IconColor, CrossScaleSmallEnclosedFalse } from 'bloko/blocks/icon';
import Timers from 'bloko/common/Timers';

import {
    NOTIFICATION_ANIMATION_TIMEOUT_MS,
    NOTIFICATION_MANAGER_CLASS_JS,
    NOTIFICATION_MANAGER_CLASS_CSS,
    NotificationKind,
} from 'bloko/blocks/notificationManager/Notification';
import { NOTIFICATION_EXTRA_TYPES } from 'bloko/blocks/notificationManager/constants';
import NotificationContext, {
    CommonNotificationProps,
    ContextNotification,
    ContextNotificationProps,
    NotificationContextValue,
} from 'bloko/blocks/notificationManager/context';

import styles from 'bloko/blocks/notificationManager/notification.less';

export const idCounter = {
    value: 0,
};

const timers = Timers();

interface NotificationProps extends CommonNotificationProps {
    id: number;
    onClose?: (id: number) => void;
    context: NotificationContextValue;
    source?: string;
}

interface NotificationState {
    visible: boolean;
}

class Notification extends PureComponent<NotificationProps, NotificationState> {
    static defaultProps = {
        dataQa: 'bloko-notification',
    };

    state = {
        visible: true,
    };

    timerId: number | null = null;

    componentDidMount() {
        this.props.context.closeFuncRefs[this.props.id] = this.hide;
        if (this.props.autoClose) {
            this.setupAutoclose();
        }
    }

    componentWillUnmount() {
        this.props.context.closeFuncRefs[this.props.id] = null;
        if (this.timerId) {
            timers.clearTimeout(this.timerId);
        }
    }

    setupAutoclose() {
        if (this.timerId || !this.props.autoCloseDelay) {
            return;
        }
        this.timerId = timers.setTimeout(() => {
            this.timerId = null;
            this.hide();
        }, this.props.autoCloseDelay);
    }

    hide = () => {
        if (this.timerId) {
            timers.clearTimeout(this.timerId);
        }
        this.timerId = null;
        this.setState({ visible: false });
    };

    onMouseEnter = () => {
        timers.pause();
    };

    onMouseLeave = () => {
        timers.resume();
    };

    render() {
        const { children, id, onClose, kind, autoClose, autoCloseDelay, dataQa, ...notificationProps } = this.props;
        return (
            <CSSTransition
                in={this.state.visible}
                timeout={{ exit: NOTIFICATION_ANIMATION_TIMEOUT_MS }}
                enter={false}
                unmountOnExit
                onExited={() => onClose?.(id)}
                classNames={{
                    exit: `${styles['bloko-notification']} ${styles['bloko-notification_inactive']}`,
                    exitActive: `${styles['bloko-notification']} ${styles['bloko-notification_inactive']}`,
                }}
                source={this.props.source}
            >
                <div
                    {...notificationProps}
                    className={classnames(styles['bloko-notification'], {
                        [styles[`bloko-notification_${kind as NotificationKind}`]]: kind,
                    })}
                    data-qa={dataQa}
                    onMouseEnter={this.onMouseEnter}
                    onMouseLeave={this.onMouseLeave}
                >
                    <div className={styles['bloko-notification__wrapper']}>
                        <div className={styles['bloko-notification__plate']}>
                            <div className={styles['bloko-notification__icon']} />
                            <div className={styles['bloko-notification__body']}>
                                <div className={styles['bloko-notification__content']}>{children}</div>
                                <div
                                    className={styles['bloko-notification__close']}
                                    data-qa="notification-close-button"
                                    onClick={this.hide}
                                >
                                    <CrossScaleSmallEnclosedFalse
                                        initial={
                                            kind && NOTIFICATION_EXTRA_TYPES.includes(kind)
                                                ? IconColor.White
                                                : IconColor.Gray80
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CSSTransition>
        );
    }
}

interface NotificationManagerConsumerProps {
    /** Класс, на котором инициализировать NotificationManager */
    notificationManagerClass?: string;
}

export class NotificationManagerConsumer extends PureComponent<NotificationManagerConsumerProps> {
    state = {
        isServerENV: true,
    };
    portalRoot: Element | null = null;

    componentDidMount(): void {
        const notificationManagerClass = this.props.notificationManagerClass ?? NOTIFICATION_MANAGER_CLASS_JS;
        this.portalRoot = document.querySelector(`.${notificationManagerClass}`);
        if (!this.portalRoot) {
            this.portalRoot = document.createElement('div');
            this.portalRoot.className = `${notificationManagerClass} ${NOTIFICATION_MANAGER_CLASS_CSS}`;
            document.body.appendChild(this.portalRoot);
        }
        this.setState({ isServerENV: false });
    }

    render(): ReactPortal | null {
        if (this.state.isServerENV) {
            return null;
        }

        return (
            this.portalRoot &&
            ReactDOM.createPortal(
                <NotificationContext.Consumer>
                    {(value) => {
                        return value.notifications.map((notification) => (
                            <Notification
                                key={notification.id}
                                id={notification.id}
                                context={value}
                                onClose={value.removeNotification}
                                {...notification.props}
                            />
                        ));
                    }}
                </NotificationContext.Consumer>,
                this.portalRoot
            )
        );
    }
}

export interface NotificationManagerProps extends NotificationManagerConsumerProps {
    /** Контент */
    children?: React.ReactNode;
    /** Рендерить сразу с NotificationManagerConsumer */
    withConsumer?: boolean;
}

interface NotificationManagerState {
    notifications: ContextNotification[];
}
export default class NotificationManager extends PureComponent<NotificationManagerProps, NotificationManagerState> {
    state = {
        notifications: [],
    };

    static defaultProps = {
        withConsumer: true,
    };

    closeFuncRefs: Record<number, VoidFunction> = {};

    addNotification = (props: ContextNotificationProps): number => {
        const id = (idCounter.value += 1);
        this.setState((state) => ({
            notifications: [
                {
                    id,
                    props,
                },
                ...state.notifications,
            ],
        }));
        return id;
    };

    removeNotification = (id: number): void => {
        this.setState((state) => ({
            notifications: state.notifications.filter((notification) => notification.id !== id),
        }));
    };

    closeNotification = (id: number): void => {
        this.closeFuncRefs?.[id]?.();
    };

    updateProps = (id: number, props: ContextNotificationProps): void => {
        this.setState((state) => {
            const index = state.notifications.findIndex(({ id: _id }) => id === _id);
            return {
                notifications: [
                    ...state.notifications.slice(0, index),
                    { id, props: { ...state.notifications[index].props, ...props } },
                    ...state.notifications.slice(index + 1),
                ],
            };
        });
    };

    render(): JSX.Element {
        const value = {
            notifications: this.state.notifications,
            addNotification: this.addNotification,
            removeNotification: this.removeNotification,
            closeFuncRefs: this.closeFuncRefs,
            closeNotification: this.closeNotification,
            updateProps: this.updateProps,
        };

        return (
            <NotificationContext.Provider value={value}>
                {this.props.withConsumer && (
                    <NotificationManagerConsumer notificationManagerClass={this.props.notificationManagerClass} />
                )}
                {this.props.children}
            </NotificationContext.Provider>
        );
    }
}
