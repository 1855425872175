import CommonCssClasses from 'bloko/common/constants/commonCssClasses';
import { BoundingClientRect, Directions, TypeElementMetrics } from 'bloko/common/types';

export enum PlacementDirection {
    Top = 'top',
    Bottom = 'bottom',
    Right = 'right',
    Left = 'left',
}

export enum Placement {
    Top = 'top',
    Right = 'right',
    Bottom = 'bottom',
    Left = 'left',
    TopStart = 'top-start',
    TopEnd = 'top-end',
    RightStart = 'right-start',
    RightEnd = 'right-end',
    BottomStart = 'bottom-start',
    BottomEnd = 'bottom-end',
    LeftStart = 'left-start',
    LeftEnd = 'left-end',
}

const defaultBoundingClientRect = {
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    right: 0,
    bottom: 0,
};

const getElement = (el: TypeElementMetrics | JQuery): TypeElementMetrics => {
    if (el && 'get' in el && typeof el.get === 'function' && el.get(0)) {
        return el.get(0) as TypeElementMetrics;
    }
    return el as TypeElementMetrics;
};

function isPointInRectangle(x: number, y: number, rectangle: BoundingClientRect): Directions {
    const errorDirections: Directions = [];

    if (x < rectangle.left) {
        errorDirections.push(Placement.Left);
    }

    if (x > rectangle.right) {
        errorDirections.push(Placement.Right);
    }

    if (y < rectangle.top) {
        errorDirections.push(Placement.Top);
    }

    if (y > rectangle.bottom) {
        errorDirections.push(Placement.Bottom);
    }

    return errorDirections;
}

const findFirstRelativeElement = (element: TypeElementMetrics): TypeElementMetrics => {
    if (
        element === document.body ||
        getComputedStyle(element).position === 'relative' ||
        getComputedStyle(element).position === 'sticky'
    ) {
        return element;
    }

    const parentElement = element.parentElement;

    if (parentElement === null) {
        return element;
    }

    return findFirstRelativeElement(parentElement);
};

export default {
    getElement,
    getBoundingClientRect(element: TypeElementMetrics): BoundingClientRect {
        try {
            return element.getBoundingClientRect();
        } catch (empty) {
            return { ...defaultBoundingClientRect };
        }
    },
    getMetrics(el: TypeElementMetrics): BoundingClientRect {
        const element = getElement(el);
        const resultBoundingRects = { ...defaultBoundingClientRect };
        if (!element) {
            return resultBoundingRects;
        }

        const boundingClientRect = this.getBoundingClientRect(element);
        resultBoundingRects.width = boundingClientRect.width;
        resultBoundingRects.height = boundingClientRect.height;
        resultBoundingRects.left = boundingClientRect.left + window.pageXOffset;
        resultBoundingRects.top = boundingClientRect.top + window.pageYOffset;
        resultBoundingRects.right = resultBoundingRects.left + resultBoundingRects.width;
        resultBoundingRects.bottom = resultBoundingRects.top + resultBoundingRects.height;

        return resultBoundingRects;
    },
    getRelativeMetrics(el: TypeElementMetrics): BoundingClientRect {
        const element = getElement(el);
        const result = { ...defaultBoundingClientRect };
        if (!element) {
            return result;
        }

        const boundingClientRect = this.getBoundingClientRect(element);

        if (window.SVGElement && element instanceof window.SVGElement) {
            const firstRelativeParentClientRect = this.getBoundingClientRect(findFirstRelativeElement(element));
            result.top = boundingClientRect.top - firstRelativeParentClientRect.top;
            result.left = boundingClientRect.left - firstRelativeParentClientRect.left;
        } else if ('offsetTop' in element && 'offsetLeft' in element) {
            result.top = element.offsetTop;
            result.left = element.offsetLeft;
        }

        result.width = boundingClientRect.width;
        result.height = boundingClientRect.height;
        result.right = result.left + result.width;
        result.bottom = result.top + result.height;

        return result;
    },
    getDocumentMetrics(): BoundingClientRect {
        return {
            width: document.body.scrollWidth,
            height: document.body.scrollHeight,
            left: 0,
            top: 0,
            right: document.body.scrollWidth,
            bottom: document.body.scrollHeight,
        };
    },
    getViewportMetrics(): BoundingClientRect {
        return {
            width: window.innerWidth,
            height: window.innerHeight,
            left: window.pageXOffset,
            top: window.pageYOffset,
            right: window.pageXOffset + window.innerWidth,
            bottom: window.pageYOffset + window.innerHeight,
        };
    },

    isPointInRectangle(x: number, y: number, rectangle: BoundingClientRect): boolean {
        return isPointInRectangle(x, y, rectangle).length === 0;
    },
    isRectangleInRectangle(rectangle1: BoundingClientRect, rectangle2: BoundingClientRect): boolean {
        return (
            this.isPointInRectangle(rectangle1.left, rectangle1.top, rectangle2) &&
            this.isPointInRectangle(rectangle1.right, rectangle1.bottom, rectangle2)
        );
    },
    checkIfRectangleInRectangle(rectangle1: BoundingClientRect, rectangle2: BoundingClientRect): Directions {
        return [
            ...new Set([
                ...isPointInRectangle(rectangle1.left, rectangle1.top, rectangle2),
                ...isPointInRectangle(rectangle1.right, rectangle1.bottom, rectangle2),
            ]),
        ];
    },
    getScrollbarWidth(): number {
        const div = document.createElement('div');
        div.classList.add(CommonCssClasses.ScrollMeasure);
        document.body.appendChild(div);
        const scrollbarWidth = div.offsetWidth - div.clientWidth;
        document.body.removeChild(div);

        return scrollbarWidth;
    },
    getWindowScrollHeight(): number {
        return Math.max(
            document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.body.clientHeight,
            document.documentElement.clientHeight
        );
    },
    getOuterWidth(element: HTMLElement): number {
        const style = getComputedStyle(element);
        let width = element.offsetWidth;

        width += parseInt(style.marginLeft, 10) + parseInt(style.marginRight, 10);

        return width;
    },
    isVisible(element: HTMLElement): boolean {
        return !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length);
    },
};
