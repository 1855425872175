import Metrics from 'bloko/common/metrics';
import Supports from 'bloko/common/supports';

export enum NoscrollCssClasses {
    General = 'bloko-modal-no-scroll',
    Ios = 'bloko-modal-no-scroll_ios',
}

let lastScrollPosition: number | undefined;
let openedModalsCounter = 0;

export default {
    enableScroll: (): void => {
        openedModalsCounter -= 1;
        if (openedModalsCounter > 0) {
            return;
        }
        document.body.style.paddingRight = '';
        document.body.classList.remove(NoscrollCssClasses.General);
        document.body.classList.remove(NoscrollCssClasses.Ios);
        document.documentElement.classList.remove(NoscrollCssClasses.General);
        document.documentElement.classList.remove(NoscrollCssClasses.Ios);
        if (typeof lastScrollPosition !== 'undefined') {
            window.scrollTo(0, lastScrollPosition);
            lastScrollPosition = undefined;
        }
    },
    disableScroll: (): void => {
        openedModalsCounter += 1;
        if (openedModalsCounter > 1) {
            return;
        }
        lastScrollPosition = window.pageYOffset;
        const bodyRightPadding = parseInt(document.body.style.paddingRight || '0', 10);
        document.body.style.paddingRight = `${bodyRightPadding + Metrics.getScrollbarWidth()}px`;
        document.body.classList.add(NoscrollCssClasses.General);
        if (Supports.ios()) {
            document.body.classList.add(NoscrollCssClasses.Ios);
            document.documentElement.classList.add(NoscrollCssClasses.General);
            document.documentElement.classList.add(NoscrollCssClasses.Ios);
        }
    },
};
