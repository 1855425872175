// Я ограничен технологиями своего времени.
// Тайпскрипт не может обработать тип, состоящий из 10^8 пересечений
// (Expression produces a union type that is too complex to represent).
// Поэтому как в тайпскрипте выйдет возможность задавать типы регексом
// https://github.com/microsoft/TypeScript/issues/41160
// или если будет добавлен специальный тип для строковых дат,
// то надо будет заменить типы YearMonthDayString и DateIsoString,
// а пока приходится использовать тип string

// должен быть вида 'YYYY-MM-DD'
export type YearMonthDayString = string;
// должен быть вида 'YYYY-MM-DDTHH:mm:ss.sssZ'
export type DateIsoString = string;
export type DateString = YearMonthDayString | DateIsoString;
// любое выражение, подходящие для конструктора класса Date
export type DateConstructorType = string | number | Date;

export interface PickDate {
    (year: number, setTime: SetYear | SetMonth): void;
}

export interface ClickDate {
    (date: YearMonthDayString): void;
}

export interface AddOrSubDate {
    (date: Date, amount: number): Date;
}

export interface OnArrowClick {
    (addOrSubDate: AddOrSubDate): void;
}

const splitDateString = (dateString: DateString) => dateString.split(/[T ]/)[0];

const parseYear = (dateString: YearMonthDayString) => {
    const token = /^(\d{4})/.exec(dateString) as string[];
    const yearString = token[1];
    return {
        year: parseInt(yearString, 10),
        restDateString: dateString.slice(yearString.length),
    };
};

const getTimezoneOffsetInMilliseconds = (date: Date) => new Date(date.getTime()).getTimezoneOffset() * 60 * 1000;

export const lastDayOfMonth = (dirtyDate: Date): Date => {
    const date = new Date(dirtyDate);
    date.setFullYear(date.getFullYear(), date.getMonth() + 1, 0);
    return date;
};

export const getDaysInMonth = (date: Date): number => lastDayOfMonth(date).getDate();

const parse = (dateString: DateString) => {
    const parseYearResult = parseYear(splitDateString(dateString));
    const token = /^-?(\d{2})-?(\d{2})$/.exec(parseYearResult.restDateString) as string[];
    const date = new Date(0);
    const month = parseInt(token[1], 10) - 1;
    const day = parseInt(token[2], 10);
    date.setUTCFullYear(parseYearResult.year, month, day);
    return date;
};

export const addDays: AddOrSubDate = (dirtyDate, amount) => {
    const date = new Date(dirtyDate);
    date.setDate(date.getDate() + amount);
    return date;
};

export const toISO = (date: Date): YearMonthDayString =>
    splitDateString(new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString());

export const toDate = (dateString: DateString): Date => {
    const date = parse(dateString);
    return new Date(date.getTime() + getTimezoneOffsetInMilliseconds(date));
};

export const startOfMonth = (dirtyDate: Date): Date => {
    const date = new Date(dirtyDate);
    date.setDate(1);
    return date;
};

export const subDays: AddOrSubDate = (date, amount) => addDays(date, -amount);

export const eachDay = (startDate: Date, endDate: Date): Date[] => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const dates = [];

    while (start.getTime() <= end.getTime()) {
        const date = new Date(start);
        dates.push(date);
        start.setDate(start.getDate() + 1);
    }

    return dates;
};

export interface SetMonth {
    (dirtyDate: Date, dirtyMonth: number): Date;
}

export const setMonth: SetMonth = (dirtyDate, dirtyMonth) => {
    const date = toDate(toISO(dirtyDate));
    const month = dirtyMonth;
    const year = date.getFullYear();
    const day = date.getDate();

    const dateWithDesiredMonth = new Date(0);
    dateWithDesiredMonth.setFullYear(year, month);
    dateWithDesiredMonth.setHours(0, 0, 0, 0);
    const daysInMonth = getDaysInMonth(dateWithDesiredMonth);
    // Set the last day of the new month
    // if the original date was the last day of the longer month
    date.setMonth(month, Math.min(day, daysInMonth));
    return date;
};

export const addMonths: AddOrSubDate = (dirtyDate, amount) => {
    const date = toDate(toISO(dirtyDate));
    const desiredMonth = date.getMonth() + amount;
    const dateWithDesiredMonth = setMonth(date, desiredMonth);
    return dateWithDesiredMonth;
};

export const subMonths: AddOrSubDate = (date, amount) => addMonths(date, -amount);

export const isSameMonth = ({ date, inDate }: { date?: Date; inDate: Date }): boolean => {
    if (!date) {
        return false;
    }
    return date.getFullYear() === inDate.getFullYear() && date.getMonth() === inDate.getMonth();
};

export interface SetYear {
    (dirtyDate: Date, dirtyYear: number): Date;
}

export const setYear: SetYear = (dirtyDate, dirtyYear) => {
    const date = toDate(toISO(dirtyDate));
    const year = dirtyYear;

    date.setFullYear(year);
    return date;
};

export const addYears: AddOrSubDate = (dirtyDate, amount) => {
    const date = toDate(toISO(dirtyDate));
    const desiredYear = date.getFullYear() + amount;
    const dateWithDesiredYear = setYear(date, desiredYear);
    return dateWithDesiredYear;
};

export const subYears: AddOrSubDate = (date, amount) => addYears(date, -amount);

export const differenceInCalendarMonths = (dirtyDateLeft: Date, dirtyDateRight: Date): number => {
    const dateLeft = toDate(toISO(dirtyDateLeft));
    const dateRight = toDate(toISO(dirtyDateRight));

    const yearDiff = dateLeft.getFullYear() - dateRight.getFullYear();
    const monthDiff = dateLeft.getMonth() - dateRight.getMonth();

    return yearDiff * 12 + monthDiff;
};
