import { useCallback, memo, FC, KeyboardEvent, PropsWithChildren } from 'react';

import { KeyCode } from 'bloko/common/constants/keyboard';
import { TreeModel } from 'bloko/common/tree/types';

import Action from 'bloko/blocks/treeSelector/Element/Action';
import ElementIcon from 'bloko/blocks/treeSelector/Element/ElementIcon';
import Text from 'bloko/blocks/treeSelector/Element/Text';

import styles from 'bloko/blocks/treeSelector/treeSelector.less';

interface TreeSelectorElementProps {
    /** ID элемента. */
    id: TreeModel['id'];
    /** Имя элемента. */
    name: string;
    /** ID родительского элемента. */
    parentId?: TreeModel['id'];
    /** Элемент с инпутом. */
    hasAction?: boolean;
    /** Разрешает выбор только одного элемента. */
    singleChoice?: boolean;
    /** Элемент с потомками. */
    hasChildren?: boolean;
    /** Элемент выбран. */
    selected?: boolean;
    /** Элемент открыт. */
    expanded?: boolean;
    /** Элемент запрещён. */
    disabled?: boolean;
    /** Значение инпута не определено. */
    indeterminate?: boolean;
    /** Обработчик изменения. */
    onChange?: (id: string, isSelected: boolean) => void;
    /** Обработчик открытия/закрытия элемента. */
    onExpansion?: (id: string) => void;
}

const TreeSelectorElement: FC<TreeSelectorElementProps & PropsWithChildren> = ({
    id,
    name,
    parentId,
    hasAction = false,
    singleChoice = false,
    hasChildren = false,
    selected = false,
    expanded = false,
    disabled = false,
    indeterminate = false,
    onChange,
    onExpansion,
    children,
}) => {
    const handleExpandableKeyDown = useCallback(
        (event: KeyboardEvent) => {
            switch (event.keyCode) {
                case KeyCode.ArrowLeft:
                    expanded && onExpansion && onExpansion(id);
                    event.preventDefault();
                    break;
                case KeyCode.ArrowRight:
                    !expanded && onExpansion && onExpansion(id);
                    event.preventDefault();
                    break;
            }
        },
        [expanded, id, onExpansion]
    );

    const handleExpandableClick = useCallback(() => onExpansion && onExpansion(id), [id, onExpansion]);

    return (
        <div className={styles['bloko-tree-selector-content']}>
            {hasChildren && (
                <ElementIcon
                    id={id}
                    hasAction={hasAction}
                    onKeyDown={handleExpandableKeyDown}
                    onClick={handleExpandableClick}
                    expanded={expanded}
                />
            )}
            {hasAction && (
                <Action
                    id={id}
                    parentId={parentId}
                    onKeyDown={handleExpandableKeyDown}
                    onChange={onChange}
                    singleChoice={singleChoice}
                    indeterminate={indeterminate}
                    selected={selected}
                    disabled={disabled}
                    name={name}
                >
                    {children}
                </Action>
            )}
            {!hasAction && (
                <Text id={id} hasChildren={hasChildren} onClick={handleExpandableClick}>
                    {children}
                </Text>
            )}
        </div>
    );
};

export default memo(TreeSelectorElement);
