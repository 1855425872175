const ESCAPE_REGEX = /[|![\]^$(){}+=?.*\\]/g;

/**
 * Escapes RegExp symbols
 *
 * @param {String} string String
 *
 * @returns {String} Escaped string
 */
const escapeRegexp = (string: string): string => string.replace(ESCAPE_REGEX, '\\$&');

export default escapeRegexp;
