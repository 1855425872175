import $ from 'jquery';

const INVALID_CLASS_MAPPING = {
    'bloko-input-text': 'bloko-input-text_invalid',
    'bloko-select': 'bloko-select_invalid',
    'bloko-textarea': 'bloko-textarea_invalid',
    'bloko-custom-select': 'bloko-custom-select_invalid',
    'bloko-checkbox': 'bloko-checkbox_invalid',
    'bloko-radio': 'bloko-radio_invalid',
    'bloko-button': 'bloko-button_invalid',
};

/**
 * Слушает на переданном элементе, при срабатывании делает элемент не валидным
 * @event setInvalid
 */

/**
 * Слушает на переданном элементе, при срабатывании делает элемент валидным
 * @event setValid
 */

/**
 * Инициализирует на DOM-элементе методы управления валидностью объекта
 * @param {Element} element DOM-элемент, на котором инициализируется validitySetter
 * @name bloko/common/validitySetter
 * @type {Object}
 */
export default function (element) {
    const $element = $(element);

    if ($element.data('validity-setter')) {
        return;
    }

    let isInvalid = false;

    const invalidClass = (function () {
        for (const blokoClass in INVALID_CLASS_MAPPING) {
            if ($element.hasClass(blokoClass)) {
                return INVALID_CLASS_MAPPING[blokoClass];
            }
        }

        return null;
    })();

    const setInvalid = function () {
        isInvalid = true;
        if (invalidClass) {
            $element.addClass(invalidClass);
        }
    };

    const setValid = function () {
        if (isInvalid) {
            isInvalid = false;
            if (invalidClass) {
                $element.removeClass(invalidClass);
            }
        }
    };

    $element.on('setInvalid', setInvalid.bind(null));
    $element.on('setValid', setValid.bind(null));
    $element.on('focusin', () => {
        if (invalidClass) {
            $element.removeClass(invalidClass);
        }
    });

    $element.on('focusout', () => {
        if (isInvalid && invalidClass) {
            $element.addClass(invalidClass);
        }
    });
    $element.data('validity-setter', true);
}
