import { memo } from 'react';
import classnames from 'classnames';

import styles from 'bloko/blocks/socialIcon/socialIcon.less';

import { ComponentWithCustomElement } from '../../common/helpers/types';

export enum SocialIconView {
    Mail = 'mail',
    OK = 'ok',
    FB = 'fb',
    VK = 'vk',
    GPlus = 'gplus',
    Twitter = 'twitter',
    Telegram = 'telegram',
    Instagram = 'instagram',
    Dnr = 'dnr',
    ESIA = 'esia',
    More = 'more',
    Viber = 'viber',
    Dzen = 'dzen',
}

interface SocialIconProps {
    /** Социальная сеть */
    view: SocialIconView;
    /** Флаг поведения иконки как кнопки */
    button?: boolean;
    /** Флаг отображения иконки - контур логотипа на прозрачном фоне*/
    outlined?: boolean;
    /** Тип элемента */
    Element?: 'span' | 'button' | 'a' | 'div';
}

/**
 * Иконки и кнопки социальных сетей.
 */
const SocialIcon: ComponentWithCustomElement<SocialIconProps, 'button'> = ({
    Element = 'span',
    view,
    button,
    outlined,
    ...socialIconProps
}) => {
    const className = classnames(styles['bloko-social-icon'], {
        [styles['bloko-social-icon_button']]: button,
        [styles['bloko-social-icon_outlined']]: outlined,
        [styles[`bloko-social-icon_${view}`]]: view,
    });
    return <Element {...socialIconProps} className={className} />;
};

export default memo(SocialIcon);
