import { render, unmountComponentAtNode } from 'react-dom';

import Components from 'bloko/common/core/Components';

export default Components.build({
    create(element, { Element, IconComponent, iconProps }) {
        const rootNode = document.createElement(Element ?? 'span');

        if (!Element) {
            rootNode.classList.add('bloko-icon-react-root');
        }

        element.appendChild(rootNode);
        render(<IconComponent {...iconProps} />, rootNode);

        return {
            change: (iconProps) => {
                unmountComponentAtNode(rootNode);
                render(<IconComponent {...iconProps} />, rootNode);
            },
            destroy: () => {
                unmountComponentAtNode(rootNode);
                rootNode.remove();
            },
        };
    },
});
