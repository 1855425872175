/**
 * @interface SetStrategy
 */
/**
 * @method SetStrategy#add
 * @param {Set} items Текущий набор.
 * @param {Array.<String>} ids Добавляемые элементы.
 * @private
 */
/**
 * @method SetStrategy#set
 * @param {Set} items Текущий набор.
 * @param {Array.<String>} ids Устанавливаемые элементы.
 * @private
 */
/**
 * @method SetStrategy#remove
 * @param {Set} items Текущий набор.
 * @param {Array.<String>} ids Удаляемые элементы.
 * @private
 */

function _add(set, items) {
    items.forEach((item) => {
        set.add(item);
    });
}

function _remove(set, items) {
    items.forEach((item) => {
        set.delete(item);
    });
}

/**
 * Примитивная стратегия переключения набора.
 * Транслирует свои методы в методы набора без дополнительной логики.
 * @implements SetStrategy
 */
export default {
    add: _add,
    remove: _remove,
    set(set, items) {
        set.clear();
        _add(set, items);
    },
};
