import { forwardRef } from 'react';
import classNames from 'classnames';

import styles from 'bloko/blocks/hSpacing/hSpacing.less';

export const spacingMultipliers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14] as const;
export type SpacingMultipliers = (typeof spacingMultipliers)[number];

export interface HSpacingBaseProps {
    /** Базовый множитель отступа */
    base: SpacingMultipliers;
    /** Указывает на строку с компонентом в исходном коде в режиме разработки. Генерируется babel-plugin-react-source */
    source?: string;
}

const HSpacing = forwardRef<HTMLDivElement, HSpacingBaseProps>(({ base, source }, forwardRef) => (
    <div
        ref={forwardRef}
        className={classNames(styles['bloko-h-spacing'], styles[`bloko-h-spacing_base-${base}`])}
        source={source}
    />
));

export default HSpacing;
