import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const CheckmarkScaleSmallKindSingleAppearanceFilledEnclosedTrue = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.666992 7.99999C0.666992 3.9499 3.95024 0.666656 8.00033 0.666656C12.0504 0.666656 15.3337 3.9499 15.3337 7.99999C15.3337 12.0501 12.0504 15.3333 8.00033 15.3333C3.95024 15.3333 0.666992 12.0501 0.666992 7.99999ZM11.4716 5.5286C11.732 5.78895 11.732 6.21105 11.4716 6.4714L7.47165 10.4714C7.2113 10.7318 6.78919 10.7318 6.52884 10.4714L4.52884 8.4714C4.26849 8.21105 4.26849 7.78895 4.52884 7.5286C4.78919 7.26825 5.2113 7.26825 5.47165 7.5286L7.00024 9.05719L10.5288 5.5286C10.7892 5.26825 11.2113 5.26825 11.4716 5.5286Z"
                    fill="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                />
            </g>
        </svg>
    ),
    'Checkmark'
);
export default CheckmarkScaleSmallKindSingleAppearanceFilledEnclosedTrue;
