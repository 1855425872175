import React from 'react';

import { ComponentWithCustomElement } from 'bloko/common/helpers/types';

import styles from 'bloko/blocks/suggest/suggest.less';

interface SuggestTextHintProps {
    /** Кастомный компонент или ('div', 'span')*/
    Element?: 'span' | 'div';
    /** Контент */
    children?: React.ReactNode;
}

const SuggestTextHint: ComponentWithCustomElement<SuggestTextHintProps, 'span'> = ({ Element = 'span', children }) => (
    <Element className={styles['suggest__text-hint']}>{children}</Element>
);

export default SuggestTextHint;
