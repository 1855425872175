import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const ArrowPathScaleSmallKindDefault = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M15.3333 2.66668V6.66668M15.3333 6.66668H11.3333M15.3333 6.66668L12.24 3.76001C11.5235 3.04315 10.637 2.51948 9.66342 2.23786C8.6898 1.95624 7.6607 1.92584 6.67215 2.14952C5.6836 2.37319 4.76783 2.84363 4.01027 3.51696C3.25271 4.19028 2.67807 5.04453 2.33996 6.00001M0.666626 13.3333V9.33334M0.666626 9.33334H4.66663M0.666626 9.33334L3.75996 12.24C4.47646 12.9569 5.36287 13.4805 6.3365 13.7622C7.31012 14.0438 8.33922 14.0742 9.32777 13.8505C10.3163 13.6268 11.2321 13.1564 11.9896 12.4831C12.7472 11.8097 13.3218 10.9555 13.66 10"
                stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                strokeWidth={1.33333}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ),
    'Arrow'
);
export default ArrowPathScaleSmallKindDefault;
