import { ComponentPropsWithoutRef, FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

import { DocumentedPropsWithChildren } from 'bloko/common/helpers/types';

import styles from 'bloko/blocks/icon/icon.less';

interface IconDynamicProps extends ComponentPropsWithoutRef<'span'> {
    /** На всю ширину */
    stretched?: boolean;
}

const IconDynamic: FC<DocumentedPropsWithChildren<IconDynamicProps> & PropsWithChildren> = ({
    stretched,
    ...iconDynamicProps
}) => (
    <span
        className={classnames(styles['bloko-icon-dynamic'], {
            [styles['bloko-icon-dynamic_full-width']]: stretched,
        })}
        {...iconDynamicProps}
    />
);

export default IconDynamic;
