import {
    ACTION_STATES,
    CLICK_REGION_THRESHOLD,
    MAX_HORIZONTAL_SWIPE_ANGLE,
    SwipeDirection,
    UseSwipeDetailsType,
} from './constants';

interface MakeSwipeEndEventPropsResult {
    type: UseSwipeDetailsType['type'];
    clientX: UseSwipeDetailsType['clientX'];
    clientY: UseSwipeDetailsType['clientY'];
    distance: UseSwipeDetailsType['swipeDistance'];
    isQuickSwipe: UseSwipeDetailsType['isQuickSwipe'];
    direction: number;
}

const makeSwipeEndEventProps = (
    getSwipeDetails: <T extends keyof UseSwipeDetailsType>(key: T) => UseSwipeDetailsType[T],
    direction: number
): MakeSwipeEndEventPropsResult => ({
    type: getSwipeDetails('type'),
    clientX: getSwipeDetails('clientX'),
    clientY: getSwipeDetails('clientY'),
    distance: getSwipeDetails('swipeDistance'),
    isQuickSwipe: getSwipeDetails('isQuickSwipe'),
    direction,
});

export interface SwipeMoveHandler {
    (args: {
        type: UseSwipeDetailsType['type'];
        clientX: UseSwipeDetailsType['clientX'];
        clientY: UseSwipeDetailsType['clientY'];
        distance: UseSwipeDetailsType['swipeDistance'];
    }): void;
}

export interface SwipeStartHandler {
    (payload: { type: UseSwipeDetailsType['type'] }): void;
}

export interface SwipeEndHandler {
    (payload: MakeSwipeEndEventPropsResult): void;
}

export interface SwipePreventHandler {
    (payload: { type: UseSwipeDetailsType['type']; distance: UseSwipeDetailsType['swipeDistance'] }): void;
}

const swipeMove = (
    event: React.MouseEvent | TouchEvent,
    clientX: number,
    clientY: number,
    {
        directionWay = SwipeDirection.Horizontal,
        onSwipeMove,
        onSwipeStart,
        getSwipeDetails,
        setSwipeDetails,
    }: {
        directionWay: SwipeDirection;
        onSwipeMove?: SwipeMoveHandler;
        onSwipeStart?: SwipeStartHandler;
        getSwipeDetails: <T extends keyof UseSwipeDetailsType>(key: T) => UseSwipeDetailsType[T];
        setSwipeDetails: (details: Partial<UseSwipeDetailsType>) => void;
    }
): void => {
    if (!getSwipeDetails('moveStarted') || getSwipeDetails('isScrolling')) {
        return;
    }

    const diffX = clientX - getSwipeDetails('clientX');
    const diffY = clientY - getSwipeDetails('clientY');

    setSwipeDetails({
        swipeDistance: getSwipeDetails('swipeDistance') + (directionWay === SwipeDirection.Horizontal ? diffX : diffY),
        clientX,
        clientY,
    });

    if (!getSwipeDetails('isSwipingHorizontally')) {
        const swipeAngle = (Math.atan2(Math.abs(diffY), Math.abs(diffX)) * 180) / Math.PI;
        if (swipeAngle > MAX_HORIZONTAL_SWIPE_ANGLE && directionWay === SwipeDirection.Horizontal) {
            setSwipeDetails({
                isScrolling: true,
            });
            return;
        }

        setSwipeDetails({
            isSwipingHorizontally: true,
        });
        onSwipeStart?.({
            type: getSwipeDetails('type'),
        });

        setSwipeDetails({
            isQuickSwipe: true,
        });
        setSwipeDetails({
            quickSwipeTimeout: setTimeout(() => {
                setSwipeDetails({
                    isQuickSwipe: false,
                });
            }, 500),
        });
    }

    if (getSwipeDetails('type') === ACTION_STATES.TOUCH) {
        // не позволяем скроллить страницу во время свайпа
        event.preventDefault();
    }
    onSwipeMove?.({
        type: getSwipeDetails('type'),
        clientX: getSwipeDetails('clientX'),
        clientY: getSwipeDetails('clientY'),
        distance: getSwipeDetails('swipeDistance'),
    });
};

// Свайп считаем совершенным, если в течение малого промежутка времени было преодолено
// quickSwipePercent расстояение или, если палец находится на блоке долго, то блок был
// перенесен больше, чем на расстояние minSwipePercent
const swipeEnd = (
    quickSwipePercent: number,
    minSwipePercent: number,
    maxBorderOffsetPercent: number,
    {
        directionWay = SwipeDirection.Horizontal,
        getContainerBoundingClientRect,
        onSwipePrevent,
        onSwipeEnd,
        getSwipeDetails,
        setSwipeDetails,
    }: {
        directionWay: SwipeDirection;
        getSwipeDetails: <T extends keyof UseSwipeDetailsType>(key: T) => UseSwipeDetailsType[T];
        setSwipeDetails: (details: Partial<UseSwipeDetailsType>) => void;
        getContainerBoundingClientRect: () => ClientRect | undefined;
        onSwipePrevent?: SwipePreventHandler;
        onSwipeEnd?: SwipeEndHandler;
    }
): void => {
    if (!getSwipeDetails('moveStarted')) {
        return;
    }
    const clientRect = getContainerBoundingClientRect();

    if (!clientRect) {
        return;
    }

    setSwipeDetails({
        moveStarted: false,
        isScrolling: false,
    });

    const layout = clientRect[SwipeDirection.Horizontal ? 'width' : 'height'];
    const offset = clientRect[SwipeDirection.Horizontal ? 'left' : 'top'];

    const quickSwipeTimeout = getSwipeDetails('quickSwipeTimeout');

    if (getSwipeDetails('isQuickSwipe') && Math.abs(getSwipeDetails('swipeDistance')) < CLICK_REGION_THRESHOLD) {
        const target = directionWay === SwipeDirection.Horizontal ? 'clientX' : 'clientY';
        if (
            getSwipeDetails(target) >= offset &&
            getSwipeDetails(target) < offset + (layout * maxBorderOffsetPercent) / 100
        ) {
            onSwipeEnd?.(makeSwipeEndEventProps(getSwipeDetails, -1));

            quickSwipeTimeout && clearTimeout(quickSwipeTimeout);
            return;
        }

        if (
            getSwipeDetails(target) <= offset + layout &&
            getSwipeDetails(target) > offset + layout * (1 - maxBorderOffsetPercent / 100)
        ) {
            onSwipeEnd?.(makeSwipeEndEventProps(getSwipeDetails, 1));
            quickSwipeTimeout && clearTimeout(quickSwipeTimeout);
            return;
        }
    }

    if (
        getSwipeDetails('isQuickSwipe') &&
        Math.abs(getSwipeDetails('swipeDistance')) > (layout * quickSwipePercent) / 100
    ) {
        onSwipeEnd?.(makeSwipeEndEventProps(getSwipeDetails, getSwipeDetails('swipeDistance') > 0 ? -1 : 1));

        quickSwipeTimeout && clearTimeout(quickSwipeTimeout);
        return;
    }

    if (Math.abs(getSwipeDetails('swipeDistance')) > (layout * minSwipePercent) / 100) {
        onSwipeEnd?.(makeSwipeEndEventProps(getSwipeDetails, getSwipeDetails('swipeDistance') > 0 ? -1 : 1));
        return;
    }

    onSwipePrevent?.({
        type: getSwipeDetails('type'),
        distance: getSwipeDetails('swipeDistance'),
    });
};

const swipeStart = (
    clientX: number,
    clientY: number,
    {
        setSwipeDetails,
        directionWay = SwipeDirection.Horizontal,
    }: { setSwipeDetails: (details: Partial<UseSwipeDetailsType>) => void; directionWay: SwipeDirection }
): void => {
    setSwipeDetails({
        moveStarted: true,
        isSwipingHorizontally: false,
        clientX,
        clientY,
        swipeDistance: 0,
        directionWay,
    });
};

export { swipeMove, swipeEnd, swipeStart };
