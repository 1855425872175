import styles from 'bloko/blocks/suggest/suggest.less';

export default {
    element: styles.suggest__item,
    state: {
        highlighted: styles.suggest__item_state_highlighted,
    },
    hover: {
        enable: styles.suggest__items_hover_enabled,
    },
    layer: {
        floating: styles['suggest_layer-floating'],
        overlay: styles['suggest_layer-overlay'],
        topmost: styles['suggest_layer-topmost'],
        'above-content': styles['suggest_layer-above-content'],
        'overlay-content': styles['suggest_layer-overlay-content'],
        'above-overlay-content': styles['suggest_layer-above-overlay-content'],
    },
    highlightSelector: 'Bloko-Suggest-Highlight',
};
