import { FC, PropsWithChildren } from 'react';

import styles from 'bloko/blocks/suggest/suggest.less';

const SuggestPickerItems: FC<PropsWithChildren> = ({ children }) => (
    <ul className={styles.suggest__items} data-qa="bloko-suggest-list">
        {children}
    </ul>
);

export default SuggestPickerItems;
