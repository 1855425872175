interface PauseableTimers {
    setTimeout: (fn: () => void, delay: number) => number;
    clearTimeout: (handle: number) => void;
    pause: () => void;
    resume: () => void;
}
interface StoredTimer {
    cb: () => void;
    delay: number;
    scheduledTime: number;
}

const Timers = (): PauseableTimers => {
    const timers: Record<number, StoredTimer> = {};

    /**
     * Устанавливает таймер, который выполняет функцию по истечении таймера
     *
     * @method PauseableTimers.setTimeout
     * @param {function} fn - функция для выполнения
     * @param {number} delay - задержка исполнения
     * @returns {number} id таймера
     */
    const setTimeout = (fn: () => void, delay: number): number => {
        const handle = window.setTimeout(cb, delay);
        const scheduledTime = Date.now() + delay;

        timers[handle] = { cb, delay, scheduledTime };

        return handle;

        function cb() {
            delete timers[handle];
            fn();
        }
    };

    /**
     * Очищает ранее созданный таймер
     *
     * @method PauseableTimers.clearTimeout
     * @param {number} handle - id таймера для отмены
     * @returns {void}
     */
    const clearTimeout = (handle: number): void => {
        window.clearTimeout(handle);
        delete timers[handle];
    };

    /**
     * Приостанавливает таймер, поэтому тайм-ауты, поставленные в очередь, не срабатывают, пока таймер не будет
     * возобновлен
     *
     * @method PauseableTimers.pause
     * @returns {void}
     */
    const pause = (): void => {
        Object.entries(timers).forEach(([handle, timer]) => {
            window.clearTimeout(Number(handle));
            timer.delay = timer.scheduledTime - Date.now();
        });
    };

    /**
     * Возобновляет таймер
     *
     * @method PauseableTimers.resume
     * @returns {void}
     */
    const resume = (): void => {
        Object.values(timers).forEach((timer) => {
            window.setTimeout(timer.cb, timer.delay);
            timer.scheduledTime = Date.now() + timer.delay;
        });
    };

    return { setTimeout, clearTimeout, pause, resume };
};

export default Timers;
