import SelectionStrategy from 'bloko/common/tree/selectionStrategy';

export default function ImmutableSelectionStrategy(collection, options) {
    const strategy = new SelectionStrategy(collection, options);

    return {
        /**
         * Возвращает новый набор, в котором к `items` добавлены `addedItems`.
         * @param {Iterable.<String>} items Текущий набор.
         * @param {Array.<String>} addedItems Добавляемые элементы.
         * @returns {Array}
         */
        add(items, addedItems) {
            const result = new Set(items);
            strategy.add(result, addedItems);
            return [...result];
        },

        exclude(items, excludedItems, processedItems) {
            const excluded = new Set(excludedItems);
            strategy.exclude(items, excluded, processedItems);
            return [...excluded];
        },

        /**
         * Возвращает новый набор, в котором из `items` удалены `removedItems`.
         * @param {Iterable.<String>} items Текущий набор.
         * @param {Array.<String>} removedItems Удаляемые элементы.
         * @returns {Array}
         */
        remove(items, removedItems) {
            const result = new Set(items);
            strategy.remove(result, removedItems);
            return [...result];
        },
    };
}
