import { createRef, Fragment, PureComponent, ReactElement, ReactNode, RefObject } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';

import { IconLink, CrossScaleSmallEnclosedFalse } from 'bloko/blocks/icon';
import { Placement } from 'bloko/common/metrics';

import composedSelectors from 'bloko/blocks/drop/composed-selectors.less';
import styles from 'bloko/blocks/drop/drop.less';

import { Breakpoint, getBreakpoint } from '../../common/media';
import requestAnimation from '../../common/requestAnimation';
import { updatePosition, setInitialCSSMetrics, Behavior, setupWidth } from './common';
import {
    FLEXIBLE_CLASS,
    FULLSCREEN_ON_XS_CLASS,
    STRETCH_ON_XS_CLASS,
    CLICKABLE_CLASS,
    ANIMATION_TIMEOUT_MS,
    PLACEMENT_DOWN_SEQUENCE,
    makeDropLayerClass,
    DropLayer,
} from './constants';
import setClickable from './setClickable';

export interface DropBaseProps {
    /** Базовые класс для down / menu / info */
    baseClassNames: string[];
    /** Если у компонента должны быть определены padding'и, то они должны задаваться в классе передаваемом в этот проп */
    paddingWrapperClassName?: string;
    /** Тип компонента */
    behavior: Behavior;
    /** Флаг — закрывать ли компонент по клику извне */
    closeByClickOutside?: boolean;
    /** Предпочтительное положение компонента */
    placement: Placement;
    /** Выпадать только в заданном направлении, не обрабатывать другие кейсы */
    onlySetPlacement?: boolean;
    /** Class z-index-а компонента */
    layer: DropLayer;
    /** DOM нода хоста в рамках которого нужно рендерить компонент, по дефолту рендер будет в body */
    host?: HTMLElement | null;
    /** Флаг отвечает за показ компонента, `true` - показать, `false` - скрыть */
    show?: boolean;
    /** Элемент инициатор, относительно которого показывать всплывающую подсказку */
    children: ReactElement;
    /** Метод-рендер контента */
    render: (onClose?: () => void) => ReactNode;
    /** Data-qa компонента */
    dataQa?: string;
    /** Колбек вызываемый при закрытии компонента. Срабатывает в случае клика на крестик или клика вне компонента */
    onClose?: () => void;
    /** Метка, использовать ли тянущийся контент.
     * Тянущийся компоенент будет растягивать по контенту, ограничиваясь только размерами экрана */
    flexible?: boolean;
    /** Заголовок дропа. Отображается если передан */
    title?: ReactNode;
    /** Тянет содержимое на всю высоту страницы не зависимо от размера содержимого на XS */
    stretchOnXS?: boolean;
}
interface DropBaseState {
    animate: boolean;
    isFirstRender: boolean;
    show?: boolean;
}

export default class DropBase extends PureComponent<DropBaseProps, DropBaseState> {
    state = { show: this.props.show, animate: false, isFirstRender: true };
    dropRef: RefObject<HTMLDivElement> = createRef();
    sizeBoxRef: RefObject<HTMLDivElement> = createRef();
    arrowRef: RefObject<HTMLDivElement> = createRef();
    activatorRef: HTMLElement | null = null;
    resizeObserver: ResizeObserver | undefined = undefined;
    baseResizeObserver: ResizeObserver | undefined = undefined;
    placement: Placement | null = null;
    clickedInsideTheDrop = false;

    initializeObserver(): void {
        this.resizeObserver =
            window?.ResizeObserver &&
            new window.ResizeObserver(() => {
                if (!this.state.show || !this.dropRef.current) {
                    return;
                }
                this.handleResize();
            });
    }

    componentDidMount(): void {
        const host = this.getHost();
        // eslint-disable-next-line react/no-find-dom-node
        this.activatorRef = ReactDOM.findDOMNode(this) as HTMLElement;

        if (host === document.body) {
            this.initializeObserver();
        }

        if (window?.ResizeObserver) {
            this.baseResizeObserver = new window.ResizeObserver(this.updateDropPosition);
        }

        this.setState({
            isFirstRender: false,
        });

        if (this.state.show) {
            this.setupDropElement();
        }

        // Слушаем клик на активаторе явно. Делаем это по следующим причинам:
        // 1) Установка флаг для превента при смене show приводит к тому,
        // что если компонент появляется программно, то клик будет проигнорирован.
        // 2) Если ставить window.addEL('click') после того, как компонент отрисуется, то событие клика на элемент будет
        // всплывать и в итоге дроп компонент закроется. Это происходит потому что:
        // 2.1 кликнули на элемент активатор
        // 2.2 появилось событие click
        // 2.3 поймали событие клика, поменяли show в true
        // 2.4 дроп компонент отрендерился, вызвались все нужные layout, подписались на window.addEL('click')
        // 2.5 событие пошло всплывать, наша подписка скрыла компонент
        this.activatorRef?.addEventListener?.('click', this.preventCloseCallback);
        if (this.resizeObserver) {
            this.resizeObserver.observe(host);
        } else {
            window.addEventListener('resize', this.handleResize);
        }
        if (this.props.closeByClickOutside) {
            document.addEventListener('click', this.callOnClose);
        }
    }

    componentDidUpdate(prevProps: DropBaseProps): void {
        this.dropRef.current && setClickable(this.dropRef.current);
        if (this.props.show && !prevProps.show) {
            this.setState({ show: true }, () => {
                this.setupDropElement();
            });
        }
        if (!this.props.show && prevProps.show) {
            this.setState({ animate: true, show: false }, () => {
                this.placement = null;
            });
        }

        if (this.baseResizeObserver && this.sizeBoxRef?.current) {
            this.baseResizeObserver.observe(this.sizeBoxRef.current);
        }

        this.updateDropPosition();
    }

    componentWillUnmount(): void {
        !this.resizeObserver && window.removeEventListener('resize', this.handleResize);
        this.resizeObserver && this.resizeObserver.disconnect();
        this.baseResizeObserver && this.baseResizeObserver.disconnect();
        document.removeEventListener('click', this.callOnClose);
        this.activatorRef?.removeEventListener?.('click', this.preventCloseCallback);
    }

    handleResize = requestAnimation(() => {
        this.placement = null;
        this.setupDropElement();
    });

    setupDropElement(): void {
        this.dropRef.current && setInitialCSSMetrics(this.dropRef.current);
        this.setupWidth();
        this.updateDropPosition();
    }

    setupWidth = (): void => {
        if (!this.props.behavior.setupFullWidthOnXS) {
            return;
        }

        if (!this.state.show || !this.dropRef.current) {
            return;
        }

        setupWidth(this.dropRef.current, this.getHost());
    };

    getHost(): HTMLElement {
        return this.props.host || document.body;
    }

    callOnClose = (): void => {
        if (!this.props.show) {
            this.clickedInsideTheDrop = false;
            return;
        }

        if (this.state.show && this.props.show) {
            if (this.clickedInsideTheDrop) {
                this.clickedInsideTheDrop = false;
                return;
            }
            this.props?.onClose?.();
        }
    };

    preventCloseCallback = (): void => {
        this.clickedInsideTheDrop = true;
    };

    updateDropPosition = (): void => {
        if (!this.state.show || !this.dropRef.current || !this.activatorRef) {
            return;
        }

        let placementSequence: Placement[] = PLACEMENT_DOWN_SEQUENCE;
        if (this.props.onlySetPlacement) {
            placementSequence = [this.props.placement];
        }

        const position = updatePosition({
            behavior: this.props.behavior,
            placementSequence,
            activatorElement: this.activatorRef,
            dropElement: this.dropRef.current,
            sizeBox: this.sizeBoxRef.current,
            host: this.getHost(),
            placement: this.placement || this.props.placement,
            classNames: [
                styles['bloko-drop'],
                ...this.props.baseClassNames,
                makeDropLayerClass(this.props.layer),
                ...FLEXIBLE_CLASS.split(' '),
                ...FULLSCREEN_ON_XS_CLASS.split(' '),
                STRETCH_ON_XS_CLASS,
                CLICKABLE_CLASS,
            ],
            arrow: this.arrowRef.current,
            arrowSize: this.props.behavior.arrowSize,
        });

        this.placement = position.placement;
        this.dropRef.current && setClickable(this.dropRef.current);
    };

    renderTitle(): ReactNode {
        if (!this.props.title) {
            return null;
        }

        return (
            <div className={styles['bloko-drop__title']}>
                <div className={styles['bloko-drop__title-text']}>{this.props.title}</div>
                <div className={styles['bloko-drop__close']}>
                    <IconLink onClick={this.props.onClose} data-qa="bloko-drop-down-close-button">
                        <CrossScaleSmallEnclosedFalse />
                    </IconLink>
                </div>
            </div>
        );
    }

    renderBody(): ReactNode {
        if (!this.state.show && !this.state.animate) {
            return null;
        }

        return (
            <Fragment>
                {this.props.render()}
                {this.props.behavior.showArrow && (
                    <div className={composedSelectors['bloko-drop__arrow']} ref={this.arrowRef} />
                )}
            </Fragment>
        );
    }

    renderDrop(): ReactNode {
        const {
            children,
            placement,
            host,
            show,
            layer,
            render,
            onClose,
            onlySetPlacement,
            closeByClickOutside,
            dataQa,
            flexible,
            baseClassNames,
            behavior,
            title,
            stretchOnXS,
            paddingWrapperClassName,
            ...other
        } = this.props;

        const cssClasses = classnames([styles['bloko-drop'], ...baseClassNames, makeDropLayerClass(layer)], {
            [FLEXIBLE_CLASS]: flexible,
            [FULLSCREEN_ON_XS_CLASS]: behavior.fullScreenOnXS,
            [STRETCH_ON_XS_CLASS]: behavior.fullScreenOnXS && stretchOnXS,
        });

        return (
            <CSSTransition
                in={this.state.show}
                timeout={{
                    enter: ANIMATION_TIMEOUT_MS,
                    exit: 0,
                }}
                onExited={() => {
                    this.setState({ animate: false });
                }}
                unmountOnExit
                classNames={{
                    enterActive: styles['bloko-drop_active-enter'],
                    enterDone: styles['bloko-drop_done-enter'],
                }}
            >
                <div
                    {...other}
                    data-qa={dataQa}
                    ref={this.dropRef}
                    onClick={this.preventCloseCallback}
                    className={cssClasses}
                >
                    <div
                        ref={this.sizeBoxRef}
                        className={classnames('bloko-drop__padding-wrapper', {
                            [paddingWrapperClassName || '']: paddingWrapperClassName,
                        })}
                    >
                        {this.renderTitle()}
                        {this.renderBody()}
                    </div>
                </div>
            </CSSTransition>
        );
    }

    renderOverlay(): ReactNode {
        return (
            this.props.behavior.fullScreenOnXS &&
            getBreakpoint() === Breakpoint.XS &&
            this.state.show && <div className="bloko-drop__overlay" onClick={this.callOnClose} />
        );
    }

    render(): ReactNode {
        if (this.state.isFirstRender) {
            return this.props.children;
        }

        return (
            <>
                {this.props.children}
                {ReactDOM.createPortal(this.renderOverlay(), document.body)}
                {ReactDOM.createPortal(this.renderDrop(), this.getHost())}
            </>
        );
    }
}
