/**
 * Переключатель элемента в наборе.
 * @typedef {Function} SetToggler
 * @param {Set} set Текущий набор флагов.
 * @param {String} item Переключаемый элемент.
 * @param {Boolean} state Новое состояние.
 */

/**
 * Переключает элемент в наборе.
 * @type {SetToggler} state
 * @returns {Boolean} Признак того, что переключение действительно произошло.
 */
export default function toggle(set, item, state) {
    if (state && !set.has(item)) {
        set.add(item);
        return true;
    } else if (!state && set.has(item)) {
        set.delete(item);
        return true;
    }
    return false;
}
