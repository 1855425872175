import {
    Children,
    isValidElement,
    cloneElement,
    useCallback,
    useRef,
    useState,
    useEffect,
    FC,
    ReactNode,
    PropsWithChildren,
    ReactElement,
} from 'react';

import Swipe, { SwipeProps } from 'bloko/blocks/swipe';
import useResize from 'bloko/common/hooks/useResize';

import Item from 'bloko/blocks/slide/Item';
import useSwipeHandlers from 'bloko/blocks/slide/useSwipeHandlers';

import styles from 'bloko/blocks/slide/styles.less';

const isSlide = (
    child: ReactNode
): child is ReactElement<
    {
        width?: number;
    } & PropsWithChildren
> => isValidElement(child) && child.type === Item;

interface SlideProps {
    /** Элементы слайда bloko/blocks/slide/Item */
    children?: ReactNode;
    /** Индекс текущего активного item слайда */
    activeItem: number;
    /** Колбек, который вызывается когда меняется активный слайд */
    onChange?: (slide: number) => void;
    /** Опции для компонента свайпа bloko/blocks/swipe */
    swipeOptions?: SwipeProps;
    /** Скорость анимации слайда */
    transitionTiming?: number;
}

const defaultProps = {
    swipeOptions: {},
    transitionTiming: 500,
};

const Slide: FC<SlideProps & PropsWithChildren> = ({
    children,
    activeItem,
    swipeOptions = defaultProps.swipeOptions,
    transitionTiming = defaultProps.transitionTiming,
    onChange,
}) => {
    const containerElRef = useRef<HTMLDivElement | null>();
    const slideContainerElRef = useRef<HTMLDivElement>(null);

    const [containerWidth, setContainerWidth] = useState(0);
    const [slideOffset, setSlideOffset] = useState(0);

    const resizeHandlerCallback = useCallback(() => {
        if (containerElRef.current) {
            setContainerWidth(containerElRef.current.getBoundingClientRect().width);
        }
    }, []);
    useEffect(() => {
        resizeHandlerCallback();
    }, [resizeHandlerCallback]);
    useResize(resizeHandlerCallback);

    const { onSwipeMove, onSwipeEnd, onSwipePrevent } = useSwipeHandlers({
        lastItemIndex: Children.count(children) - 1,
        activeItemIndex: activeItem,
        setSlideOffset,
        slideOnChange: onChange,
        slideContainerElRef,
    });

    return (
        <Swipe onSwipeMove={onSwipeMove} onSwipeEnd={onSwipeEnd} onSwipePrevent={onSwipePrevent} {...swipeOptions}>
            {({ swipeContainerProps: { ref, ...restContainerProps } }) => (
                <div
                    {...restContainerProps}
                    ref={(el) => {
                        ref.current = el;
                        containerElRef.current = el;
                    }}
                    className={styles['bloko-slide']}
                >
                    {!!containerWidth && (
                        <div
                            className={styles['bloko-slide__container']}
                            style={{
                                transitionDuration: `${transitionTiming}ms`,
                                transform: `translateX(-${containerWidth * activeItem - slideOffset}px)`,
                            }}
                            ref={slideContainerElRef}
                        >
                            {Children.map(children, (child) => {
                                if (isSlide(child)) {
                                    return cloneElement(child, {
                                        width: containerWidth,
                                    });
                                }
                                throw new Error('Children must be slide');
                            })}
                        </div>
                    )}
                </div>
            )}
        </Swipe>
    );
};

export default Slide;
