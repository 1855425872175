import { ComponentWithCustomElement } from 'bloko/common/helpers/types';

import styles from 'bloko/blocks/notificationManager/notification.less';

interface NotificationHeadingProps {
    /** Кастомный компонент или ('div', 'span', 'fieldset', 'label')*/
    Element?: 'div' | 'span' | 'fieldset' | 'label';
    /** Указывает на строку с компонентом в исходном коде в режиме разработки. Генерируется babel-plugin-react-source */
    source?: string;
}

const NotificationHeading: ComponentWithCustomElement<NotificationHeadingProps, 'div'> = ({
    Element = 'div',
    ...elementProps
}) => <Element className={styles['bloko-notification__heading']} {...elementProps} />;

export default NotificationHeading;
