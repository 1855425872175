import { createRef, cloneElement, Fragment, PureComponent, ReactNode, RefObject } from 'react';
import classnames from 'classnames';

import Tip from 'bloko/blocks/drop/Tip';
import {
    IconColor,
    CheckmarkScaleSmallKindSingleAppearanceOutlinedEnclosedFalse,
    CrossScaleSmallEnclosedFalse,
    PenSquareScaleSmall,
    PlusScaleSmallEnclosedFalse,
    TrashScaleSmall,
    ChevronScaleSmallKindDown,
    ChevronScaleSmallKindUp,
} from 'bloko/blocks/icon';
import IconDynamic from 'bloko/blocks/icon/IconDynamic';
import InputText, { InputScale } from 'bloko/blocks/inputText';
import TextSelection from 'bloko/common/textSelection';

import styles from 'bloko/blocks/tagList/tag.less';

import renderTransitionGroup from './transitionGroup';
import { TagProps, RenderTagFunc } from './types';

interface TagState {
    editing: boolean;
    showTooltip: boolean;
    prevItemsLength: number;
    highlight: boolean;
}

/**
 * Тег для использования внутри TagList
 */
class Tag extends PureComponent<TagProps, TagState> {
    static defaultProps = {
        text: '',
        removable: false,
        stretched: false,
        selected: false,
        editable: false,
        isNew: false,
        selectable: false,
        selectableByClick: true,
        disabled: false,
        countable: false,
        changeable: true,
        voted: false,
        animateNew: true,
        nested: false,
        child: false,
        additionalDataQa: '',
        items: [],
        icon: null,
        kind: undefined,
    };

    static getDerivedStateFromProps(props: TagProps, state: TagState): Partial<TagState> | null {
        if (!props.animateNew) {
            return null;
        }

        if (props.items && props.items.length > state.prevItemsLength) {
            return {
                prevItemsLength: props.items.length,
                highlight: true,
            };
        }

        return {
            highlight: props.isNew,
        };
    }

    state = {
        editing: false,
        showTooltip: false,
        prevItemsLength: this.props.items ? this.props.items.length : 0,
        highlight: false,
    };

    elementRef: RefObject<HTMLDivElement> = createRef();
    inputRef: RefObject<HTMLInputElement> = createRef();

    select = (): false | void => this.props.selectableByClick && this.props.onSelect && this.props.onSelect();

    remove = (): void => this.props.onRemove && this.props.onRemove();

    increaseCount = (): void => this.props.onCountIncrease && this.props.onCountIncrease();

    decreaseCount = (): void => this.props.onCountDecrease && this.props.onCountDecrease();

    toggle = (): void => this.props.onToggle && this.props.onToggle();

    startEditing = (): void => {
        if (this.elementRef.current) {
            this.elementRef.current.style.width = `${this.elementRef.current.offsetWidth}px`;
        }
        this.setState({ editing: true }, () => {
            if (this.inputRef.current) {
                TextSelection.setCaretPosition(this.inputRef.current, this.inputRef.current.value.length);
            }
        });
    };

    cancelEditing = (): void => {
        if (this.elementRef.current) {
            this.elementRef.current.style.width = '';
        }
        this.setState({ editing: false });
    };

    confirmEditing = (): void => {
        const value = this.inputRef.current?.value || '';
        this.setState({ editing: false }, () => {
            if (this.props.onEdit) {
                this.props.onEdit(value);
            }
        });
    };

    componentDidUpdate(prevProps: TagProps): void {
        if (this.elementRef.current && this.props.text !== prevProps.text) {
            this.elementRef.current.style.width = '';
        }
    }

    renderTextSection(): ReactNode {
        const { children, selectable, disabled, nested, items, expanded } = this.props;
        const { editing } = this.state;

        if (editing) {
            return null;
        }

        if (selectable) {
            return (
                <button
                    type="button"
                    className={classnames(styles['bloko-tag__section'], styles['bloko-tag__section_text'], {
                        [styles['bloko-tag__section_active']]: !disabled,
                    })}
                    disabled={disabled}
                    onClick={this.select}
                >
                    <span data-qa="bloko-tag__text bloko-tag__selectable">{children}</span>
                </button>
            );
        }

        const iconProps = {
            initial: IconColor.Gray50,
            'data-qa': classnames({
                'bloko-tag__collapse': expanded,
                'bloko-tag__expand': !expanded,
            }),
        };

        if (nested && items && items.length > 0) {
            return (
                <button
                    type="button"
                    className={classnames(styles['bloko-tag__section'], styles['bloko-tag__section_text'], {
                        [styles['bloko-tag__section_active']]: !disabled,
                    })}
                    disabled={disabled}
                    onClick={this.toggle}
                >
                    <span data-qa="bloko-tag__text">{children}</span>
                    {expanded ? (
                        <ChevronScaleSmallKindUp {...iconProps} />
                    ) : (
                        <ChevronScaleSmallKindDown {...iconProps} />
                    )}
                </button>
            );
        }

        return (
            <span
                className={classnames(styles['bloko-tag__section'], styles['bloko-tag__section_text'])}
                data-qa="bloko-tag__text"
            >
                {children}
            </span>
        );
    }

    renderEditSection(): ReactNode {
        const { text, editable, disabled } = this.props;
        const { editing } = this.state;
        const highlightedIcon = disabled ? undefined : IconColor.Gray60;

        if (!editable) {
            return null;
        }

        if (editing) {
            return (
                <Fragment>
                    <span className={classnames(styles['bloko-tag__section'], styles['bloko-tag__section_edit'])}>
                        <InputText
                            ref={this.inputRef}
                            scale={InputScale.Small}
                            data-qa="bloko-tag-edit-input"
                            defaultValue={text}
                        />
                    </span>
                    <button
                        type="button"
                        className={styles['bloko-tag-button']}
                        data-qa="bloko-tag-edit-cancel"
                        disabled={disabled}
                        onClick={this.cancelEditing}
                    >
                        <IconDynamic>
                            <CrossScaleSmallEnclosedFalse initial={IconColor.Gray50} highlighted={highlightedIcon} />
                        </IconDynamic>
                    </button>
                    <button
                        type="button"
                        className={styles['bloko-tag-button']}
                        data-qa="bloko-tag-edit-done"
                        disabled={disabled}
                        onClick={this.confirmEditing}
                    >
                        <IconDynamic>
                            <CheckmarkScaleSmallKindSingleAppearanceOutlinedEnclosedFalse
                                initial={IconColor.Green60}
                                highlighted={highlightedIcon}
                            />
                        </IconDynamic>
                    </button>
                </Fragment>
            );
        }

        return (
            <button
                type="button"
                className={styles['bloko-tag-button']}
                data-qa="bloko-tag-edit-action"
                disabled={disabled}
                onClick={this.startEditing}
            >
                <IconDynamic>
                    <PenSquareScaleSmall initial={IconColor.Gray50} highlighted={highlightedIcon} />
                </IconDynamic>
            </button>
        );
    }

    showTooltip = (): void => this.setState({ showTooltip: true });

    hideTooltip = (): void => this.setState({ showTooltip: false });

    renderCountValue(): ReactNode {
        const { count, tooltipParams } = this.props;
        const { showTooltip } = this.state;

        if (tooltipParams) {
            const { content, ...other } = tooltipParams;
            return (
                <Tip {...other} onExternalClose={this.hideTooltip} show={showTooltip} render={() => content}>
                    <div
                        className={classnames(styles['bloko-tag__section'], styles['bloko-tag__section_count'])}
                        data-qa="bloko-tag__count"
                        onMouseOver={this.showTooltip}
                        onMouseLeave={this.hideTooltip}
                    >
                        {count}
                    </div>
                </Tip>
            );
        }

        return (
            <div
                className={classnames(styles['bloko-tag__section'], styles['bloko-tag__section_count'])}
                data-qa="bloko-tag__count"
            >
                {count}
            </div>
        );
    }

    renderCountControls(): ReactNode {
        const { disabled, voted } = this.props;

        if (!voted) {
            return (
                <button
                    type="button"
                    className={classnames(styles['bloko-tag-button'], styles['bloko-tag-button_increase'])}
                    data-qa="bloko-tag__increase"
                    disabled={disabled}
                    onClick={this.increaseCount}
                >
                    <IconDynamic>
                        <PlusScaleSmallEnclosedFalse initial={IconColor.White} />
                    </IconDynamic>
                </button>
            );
        }

        const highlightedIcon = disabled ? undefined : IconColor.Gray60;
        return (
            <button
                type="button"
                className={styles['bloko-tag-button']}
                data-qa="bloko-tag__decrease"
                disabled={disabled}
                onClick={this.decreaseCount}
            >
                <IconDynamic>
                    <CrossScaleSmallEnclosedFalse initial={IconColor.Gray50} highlighted={highlightedIcon} />
                </IconDynamic>
            </button>
        );
    }

    renderCountSection(): ReactNode {
        const { count = 0, countable, changeable } = this.props;

        if (!countable) {
            return null;
        }

        return (
            <Fragment>
                {count > 0 && this.renderCountValue()}
                {changeable && this.renderCountControls()}
            </Fragment>
        );
    }

    renderRemoveSection(): ReactNode {
        const { removable, disabled } = this.props;
        const { editing } = this.state;

        if (!removable || editing) {
            return null;
        }

        const highlightedIcon = disabled ? undefined : IconColor.Gray60;

        return (
            <button
                className={styles['bloko-tag-button']}
                data-qa="bloko-tag__cross"
                type="button"
                disabled={disabled}
                onClick={this.remove}
            >
                <IconDynamic>
                    <TrashScaleSmall initial={IconColor.Gray50} highlighted={highlightedIcon} />
                </IconDynamic>
            </button>
        );
    }

    renderIconSection(): ReactNode {
        const { icon } = this.props;

        if (!icon) {
            return null;
        }

        return (
            <div
                className={classnames(styles['bloko-tag__section'], styles['bloko-tag__section_icon'])}
                data-qa="bloko-tag__icon"
            >
                <span>{icon}</span>
            </div>
        );
    }

    renderInlineTag(): ReactNode {
        const { stretched, selectable, isNew, selected, disabled, countable, child, additionalDataQa, kind } =
            this.props;

        return (
            <div
                ref={this.elementRef}
                className={classnames(styles['bloko-tag'], {
                    [styles['bloko-tag_inline']]: !stretched && !child,
                    [styles['bloko-tag_animated']]: isNew,
                    [styles['bloko-tag_selectable']]: selectable,
                    [styles['bloko-tag_selected']]: selected,
                    [styles['bloko-tag_disabled']]: disabled,
                    [styles['bloko-tag_countable']]: countable,
                    [styles['bloko-tag_child']]: child,
                    [styles[`bloko-tag_${kind}`]]: Boolean(kind),
                })}
                data-qa={classnames(styles['bloko-tag'], styles['bloko-tag_inline'], {
                    [styles['bloko-tag_selected']]: selected,
                    [styles['bloko-tag_disabled']]: disabled,
                    [styles['bloko-tag_child']]: child,
                    [styles[`bloko-tag_${kind}`]]: Boolean(kind),
                    [additionalDataQa]: additionalDataQa !== '',
                })}
            >
                {this.renderIconSection()}
                {this.renderTextSection()}
                {this.renderEditSection()}
                {this.renderCountSection()}
                {this.renderRemoveSection()}
            </div>
        );
    }

    renderNestedTag(): ReactNode {
        const { disabled, expanded, items, additionalDataQa } = this.props;
        const { highlight } = this.state;

        return (
            <div
                className={styles['bloko-nested-tag']}
                data-qa={classnames('bloko-tag bloko-tag_parent', {
                    [additionalDataQa]: additionalDataQa !== '',
                })}
            >
                <div
                    className={classnames(styles['bloko-tag'], styles['bloko-tag_parent'], {
                        [styles['bloko-tag_animated']]: highlight,
                        [styles['bloko-tag_disabled']]: disabled,
                    })}
                >
                    {this.renderTextSection()}
                    {this.renderRemoveSection()}
                </div>
                <div
                    className={classnames(styles['bloko-nested-tag__children'], {
                        [styles['bloko-nested-tag__children_visible']]: expanded && items && items.length,
                    })}
                >
                    {this.renderChildren()}
                </div>
            </div>
        );
    }

    renderChildren = (): ReactNode =>
        renderTransitionGroup(this.props.animateNew, this.props.items, this.renderChild, this.onChildAnimationComplete);

    renderChild: RenderTagFunc = (item, props) => {
        const { removable } = this.props;

        return cloneElement(item, {
            ...props,
            child: true,
            removable,
        });
    };

    onChildAnimationComplete = (): void => {
        this.setState({ highlight: false });
    };

    render = (): ReactNode => (this.props.nested ? this.renderNestedTag() : this.renderInlineTag());
}

export default Tag;
