import { forwardRef, ReactNode } from 'react';
import classNames from 'classnames';

import { HSpacingBaseProps } from 'bloko/blocks/hSpacing';

import styles from 'bloko/blocks/hSpacing/hSpacingContainer.less';

type HSpacingContainerProps = {
    /** Элементы которые будут выведены с отступом */
    children: ReactNode;
} & Partial<HSpacingBaseProps>;

const HSpacingContainer = forwardRef<HTMLDivElement, HSpacingContainerProps>(({ base = 0, children }, forwardRef) => {
    return (
        <div
            ref={forwardRef}
            className={classNames(
                styles['bloko-h-spacing-container'],
                styles[`bloko-h-spacing-container_base-${base}`]
            )}
        >
            {children}
        </div>
    );
});

export default HSpacingContainer;
