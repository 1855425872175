import { FC, PropsWithChildren } from 'react';

import DropClick, { DropClickPropsCommon } from 'bloko/blocks/drop/Click';

import Down, { DownProps } from 'bloko/blocks/drop/Down';

export { DownPlacement, DownLayer } from 'bloko/blocks/drop/Down/common';

const Click: FC<Omit<DownProps, 'render'> & DropClickPropsCommon & PropsWithChildren> = (props) => {
    return (
        <DropClick {...props} DropElement={Down}>
            {props.children}
        </DropClick>
    );
};

export default Click;
