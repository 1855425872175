import { Fragment } from 'react';

import getPlaintextFormattedValue from 'bloko/common/format';
import NumberFormatter, { FormatParams } from 'bloko/common/numberFormatter';

const newLineToBR = (trlValue: string): JSX.Element => {
    const splitValues = trlValue.split(/\n/gi);

    const lastIndex = splitValues.length - 1;
    return (
        <>
            {splitValues.map((row, index) => (
                <Fragment key={index}>
                    {row}
                    {index < lastIndex && <br />}
                </Fragment>
            ))}
        </>
    );
};

const getReactFormattedValue = (
    trlValue: string,
    formatValues: Record<string, JSX.Element | string | number>
): JSX.Element => {
    const originalTrlValue = trlValue;

    Object.keys(formatValues).forEach((value, index) => {
        trlValue = trlValue.replace(value, `#${index}#`);
    });

    const splitByDivider = trlValue.split(
        new RegExp(
            `${Object.keys(formatValues)
                .map((item, index) => `#${index}#`)
                .join('|')}`,
            'g'
        )
    );

    const sortedFormatValues = Object.keys(formatValues)
        .map((value) => ({
            index: originalTrlValue.indexOf(value),
            value: formatValues[value],
        }))
        .filter((item) => item.index !== -1)
        .sort((a, b) => a.index - b.index);

    return (
        <>
            {splitByDivider.map((value, index) => (
                <Fragment key={index}>
                    {value}
                    {index !== splitByDivider.length - 1 && sortedFormatValues[index].value}
                </Fragment>
            ))}
        </>
    );
};

const getFromToFormattedValue = (
    { from, to }: { from?: number; to?: number },
    { trlFrom, trlTo, trlFromTo }: { trlFrom: string; trlTo: string; trlFromTo: string },
    formatterParams?: FormatParams
): string | null => {
    if (from && to) {
        return getPlaintextFormattedValue(trlFromTo, {
            '{0}': NumberFormatter.format(from.toString(), formatterParams),
            '{1}': NumberFormatter.format(to.toString(), formatterParams),
        });
    }
    if (to) {
        return getPlaintextFormattedValue(trlTo, {
            '{0}': NumberFormatter.format(to.toString(), formatterParams),
        });
    }
    if (from) {
        return getPlaintextFormattedValue(trlFrom, {
            '{0}': NumberFormatter.format(from.toString(), formatterParams),
        });
    }
    return null;
};

export const format = getPlaintextFormattedValue;
export const formatToReactComponent = getReactFormattedValue;
export const formatNewLine = newLineToBR;
export const formatFromTo = getFromToFormattedValue;
