enum IconColor {
    White = 'white',
    Black = 'black',
    SpecialRedBranded = 'special-red-branded',
    SpecialBlackJack = 'special-black-jack',
    Gray10 = 'gray-10',
    Gray20 = 'gray-20',
    Gray30 = 'gray-30',
    Gray40 = 'gray-40',
    Gray50 = 'gray-50',
    Gray60 = 'gray-60',
    Gray70 = 'gray-70',
    Gray80 = 'gray-80',
    Red10 = 'red-10',
    Red20 = 'red-20',
    Red30 = 'red-30',
    Red40 = 'red-40',
    Red50 = 'red-50',
    Red60 = 'red-60',
    Red70 = 'red-70',
    Red80 = 'red-80',
    Yellow10 = 'yellow-10',
    Yellow20 = 'yellow-20',
    Yellow30 = 'yellow-30',
    Yellow40 = 'yellow-40',
    Yellow50 = 'yellow-50',
    Yellow60 = 'yellow-60',
    Yellow70 = 'yellow-70',
    Yellow80 = 'yellow-80',
    Orange10 = 'orange-10',
    Orange20 = 'orange-20',
    Orange30 = 'orange-30',
    Orange40 = 'orange-40',
    Orange50 = 'orange-50',
    Orange60 = 'orange-60',
    Orange70 = 'orange-70',
    Orange80 = 'orange-80',
    Blue10 = 'blue-10',
    Blue20 = 'blue-20',
    Blue30 = 'blue-30',
    Blue40 = 'blue-40',
    Blue50 = 'blue-50',
    Blue60 = 'blue-60',
    Blue70 = 'blue-70',
    Blue80 = 'blue-80',
    Violet10 = 'violet-10',
    Violet20 = 'violet-20',
    Violet30 = 'violet-30',
    Violet40 = 'violet-40',
    Violet50 = 'violet-50',
    Violet60 = 'violet-60',
    Violet70 = 'violet-70',
    Violet80 = 'violet-80',
    Green10 = 'green-10',
    Green20 = 'green-20',
    Green30 = 'green-30',
    Green40 = 'green-40',
    Green50 = 'green-50',
    Green60 = 'green-60',
    Green70 = 'green-70',
    Green80 = 'green-80',
}

export default IconColor;
