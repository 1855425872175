/**
 * Пробегается по дереву с переданным callback и возвращает изменное дерево по callback
 * @param {Array} items
 * @param {Function} callback
 * @returns {Array}
 */
function walker(items, callback) {
    items.forEach((item) => {
        callback(item);
        if (item.items && item.items.length) {
            walker(item.items, callback);
        }
    });
    return items;
}

/**
 * Возвращает узлы родители которых не выбраны
 * @param {Array} selectedItems
 * @returns {Array}
 */
function getSelectedItemsWithoutSelectedParent(selectedItems) {
    const parentSelectedMap = selectedItems.reduce((map, item) => {
        if (item.items && item.items.length) {
            map[item.id] = true;
        }

        return map;
    }, {});

    return selectedItems.filter((item) => {
        return !parentSelectedMap[item.parent];
    });
}

/**
 * Возвращает только листовые узлы
 * @param {Array} selectedItems
 * @returns {Array}
 */
function getSelectedItemsLeavesOnly(selectedItems) {
    return selectedItems.reduce((acc, item) => {
        return item.items?.length ? acc : acc.concat(item);
    }, []);
}

/**
 * Возвращает уникальные узлы дерева. Два узла item1 и item2 считаются уникальными, если keyFunction(item1) !== keyFunction(item2).
 * Для двух одинаковых элементов функция вернёт тот, что встретится последним в массиве items
 * @param {Array} items
 * @param {Function} [keyFunction=(item)=>item.id] функция для определения ключа элемента. На вход функции подаются элементы из массива items
 * @returns {Array}
 */
function getUniqueItems(items, keyFunction = (item) => item.id) {
    const itemsMap = new Map();
    items.forEach((item) => {
        const key = keyFunction(item);
        itemsMap.set(key, item);
    });

    return [...itemsMap.values()];
}

/**
 * Сортирует дерево возвращает отсортированное дерево
 * @param {Array} items
 * @param {Function} compareFunction
 * @returns {Array}
 */
function sort(items, compareFunction) {
    items.sort(compareFunction);
    return walker(items, (item) => {
        if (item.items && item.items.length) {
            item.items.sort(compareFunction);
        }
    });
}

export default {
    walker,
    getSelectedItemsWithoutSelectedParent,
    getSelectedItemsLeavesOnly,
    getUniqueItems,
    sort,
};
