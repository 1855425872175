import { useMemo } from 'react';

import TreeCollection from 'bloko/common/tree/treeCollection';
import { getParentsHashMap, getIdsWithNoParentsInSameList } from 'bloko/common/tree/treeCollectionHelper';
import { AdditionalDefault } from 'bloko/common/tree/types';
/*
    Кастомный хук для получения списка недоступных опций в treeSelector
    Если присутствует и достигнут лимит - дизейблит все оставшиеся элементы
    Если выбор ограничен одной категорией и что-то выбрано - дизеблит элементы всех остальных категорий
*/

interface UseDisabledHookProps<A extends AdditionalDefault> {
    selected: string[];
    collection: TreeCollection<A>;
    singleCategory?: boolean;
    maxItems?: number;
}

interface UseDisabledHook {
    <A extends AdditionalDefault>(props: UseDisabledHookProps<A>): string[];
}

const useDisabled: UseDisabledHook = ({ selected, collection, singleCategory, maxItems }) => {
    return useMemo(() => {
        const valueIds = getIdsWithNoParentsInSameList(collection, selected);
        if (maxItems && valueIds.length >= maxItems) {
            return collection
                .toList()
                .map(({ id }) => id)
                .filter((id) => !selected.includes(id));
        }
        if (singleCategory && (!maxItems || valueIds.length < maxItems)) {
            const parents = getParentsHashMap(collection, selected);
            if (Object.keys(parents).length) {
                return collection
                    .toList()
                    .map(({ id }) => id)
                    .filter((id) => {
                        const parentId = collection.getParentId(id);
                        return parentId ? !parents[parentId] : true;
                    });
            }
        }
        return [];
    }, [selected, collection, singleCategory, maxItems]);
};

export default useDisabled;
