import { cloneElement, ReactElement, ReactNode } from 'react';

import styles from 'bloko/blocks/tagList/tag.less';

import renderTransitionGroup from './transitionGroup';
import { ToolTipProps, RenderTagFunc } from './types';

type CountableOverrides<C> = C extends true
    ? { selectable?: never; editable?: never; stretched?: never }
    : { countable?: never };

type NestedOverrides<N> = N extends true
    ? { selectable?: never; editable?: never; stretched?: never }
    : { nested?: never };

interface TagListProps<C extends boolean, N extends boolean> {
    /** Список тегов */
    items?: ReactElement[];
    /** Показывать ли кнопку удаления тегов */
    removable?: boolean;
    /** Растягивать ли теги на всю ширину контейнера */
    stretched?: boolean;
    /** Отображать ли теги как выбранные */
    selectable?: boolean;
    /** Выбирать ли теги по клику */
    selectableByClick?: boolean;
    /** Показывать ли кнопку редактирования тегов */
    editable?: boolean;
    /** Анимировать ли новые теги */
    animateNew?: boolean;
    /** Рисовать ли счетчики в тегах. Несовместим с selectable, editable, stretched */
    countable?: C;
    /** Параметры тултипа для countable тегов */
    tooltipParams?: ToolTipProps;
    /** Является ли список тегов вложенным. Несовместим с selectable, editable, stretched */
    nested?: N;
    /** Указывает на строку с компонентом в исходном коде в режиме разработки. Генерируется babel-plugin-react-source */
    source?: string;
    /** Позволяет срендерить кастомный контент после тегов (в одном родителе) */
    renderAfterTags?: () => ReactNode;
}

interface TagListComponent {
    <C extends boolean, N extends boolean>(
        props: TagListProps<C, N> & CountableOverrides<C> & NestedOverrides<N>
    ): JSX.Element | null;
}

/**
 * Список тегов
 */
const TagList: TagListComponent = ({
    items = [],
    animateNew = true,
    removable = false,
    stretched = false,
    selectable = false,
    selectableByClick = true,
    editable = false,
    countable = false,
    tooltipParams,
    nested = false,
    renderAfterTags,
    source,
}) => {
    const renderTag: RenderTagFunc = (item, props) =>
        cloneElement(item, {
            ...props,
            animateNew,
            removable,
            stretched,
            selectable,
            selectableByClick,
            editable,
            countable,
            tooltipParams,
            nested,
        });

    return items.length > 0 ? (
        <div className={styles['bloko-tag-list']} source={source}>
            {renderTransitionGroup(animateNew, items, renderTag, undefined, renderAfterTags)}
        </div>
    ) : null;
};

export default TagList;
