import $ from 'jquery';

import { IconColor, ChevronScaleSmallKindDown, ChevronScaleSmallKindRight } from 'bloko/blocks/icon';
import IconReactRenderer from 'bloko/blocks/icon/IconReactRenderer';
import Components from 'bloko/common/core/Components';
import Events from 'bloko/common/events';
import FuzzySearch from 'bloko/common/fuzzySearch';

import TreeSelectorTemplate from 'bloko/blocks/treeSelector/treeSelector.mustache';
import AfterLabelTemplate from 'bloko/blocks/treeSelector/treeSelectorAfterLabel.mustache';
import AfterLabelLeavesOnlyTemplate from 'bloko/blocks/treeSelector/treeSelectorAfterLabelLeavesOnly.mustache';
import TreeSelectorCollection from 'bloko/blocks/treeSelector/treeSelectorCollection';
import CheckboxTemplate from 'bloko/blocks/treeSelector/treeSelectorElementTypeCheckbox.mustache';
import RadioTemplate from 'bloko/blocks/treeSelector/treeSelectorElementTypeRadio.mustache';
import TreeSelectorError from 'bloko/blocks/treeSelector/treeSelectorError';
import IconsTemplate from 'bloko/blocks/treeSelector/treeSelectorIcons.mustache';
import TreeSelectorLabelTemplate from 'bloko/blocks/treeSelector/treeSelectorLabel.mustache';
import ListLeavesOnlyTemplate from 'bloko/blocks/treeSelector/treeSelectorListLeavesOnly.mustache';
import ListCheckboxTemplate from 'bloko/blocks/treeSelector/treeSelectorListTypeCheckbox.mustache';
import ListRadioTemplate from 'bloko/blocks/treeSelector/treeSelectorListTypeRadio.mustache';
import TreeSelectorModelsHandler from 'bloko/blocks/treeSelector/treeSelectorModelsHandler';
import TreeSelectorView from 'bloko/blocks/treeSelector/treeSelectorView';
import TreeSelectorViewItem from 'bloko/blocks/treeSelector/treeSelectorViewItem';

/**
 * Триггерится при изменении selected состояния узла или элемента дерева.
 * Аргументом передается объект с JSON представлением модели узлов или элементов,
 * которые выбраны/добавлены/удалены.
 *
 * @event Bloko-TreeSelector-Changed
 */

/**
 * @exports bloko/blocks/treeSelector/treeSelector
 *
 * @param {Object} options
 * @param {String} options.type                   Тип дерева, возможные варианты:
 * * `checkbox` — checkbox шаблоны
 * * `radio` — radio шаблоны
 * @param {Boolean} [options.leavesOnly=false]    Разрешает выбор только бездетных элементов
 * @param {Mustache} [options.labelTemplate]      Задает кастомный шаблон лейбла, по умолчанию выводится text
 * @param {Mustache} [options.afterLabelTemplate] Задает кастомный шаблон под лейблом, по умолчанию не используется
 * @constructor
 */
const TreeSelector = function (options) {
    const $el = $(options.el);
    const collection = options.collection;
    const currentCollection = collection.get();
    const modelsHandler = options.modelsHandler;
    new TreeSelectorViewItem(modelsHandler);
    const labelTemplate = options.labelTemplate || TreeSelectorLabelTemplate;
    const logger = TreeSelectorError;
    let elementTemplate;
    let listTemplate;
    let afterLabelTemplate;
    let previousSelectedRadioModels = [];
    /* Атрибут name должен быть уникальный для каждого инстанса, использование свойх name запрещается из-за
        медленной работы hidden radio в ie10,11,edge*/
    const inputName = `bloko-tree-selector-default-name-${Math.random()}`;
    const publicInterface = Events.extend({
        setSelected,
        getSelected,
        addSelected,
        toggleDisabled,
        filterByContent,
        toggleExpanded,
        change,
        getItem,
        getAllItems,
    });

    switch (options.type) {
        case 'checkbox': {
            elementTemplate = CheckboxTemplate;
            listTemplate = options.leavesOnly ? ListLeavesOnlyTemplate : ListCheckboxTemplate;
            afterLabelTemplate = options.leavesOnly ? AfterLabelTemplate : AfterLabelLeavesOnlyTemplate;
            break;
        }
        case 'radio': {
            elementTemplate = RadioTemplate;
            listTemplate = options.leavesOnly ? ListLeavesOnlyTemplate : ListRadioTemplate;
            afterLabelTemplate = options.leavesOnly ? AfterLabelTemplate : AfterLabelLeavesOnlyTemplate;
            break;
        }
        default: {
            throw new Error(`BlokoTreeSelector: the wrong type: "${options.type}", can be "checkbox" or "radio"`);
        }
    }

    const afterLabelWrapperTemplate = options.afterLabelTemplate ? afterLabelTemplate : null;

    if (options.dataJSON) {
        const treeDOM = createCollectionAndTree(options.dataJSON);
        let $renderTemplate = $(TreeSelectorTemplate.render()).append(treeDOM);

        $renderTemplate.find('.Bloko-TreeSelector-Element').each((index, item) => {
            currentCollection[index].view.el = item;
            currentCollection[index].view.action = item.querySelector('.Bloko-TreeSelector-Action');
            currentCollection[index].view.input =
                currentCollection[index].view.action.querySelector('.Bloko-TreeSelector-Input');
            currentCollection[index].view.list = item.querySelector('.Bloko-TreeSelector-List');
            currentCollection[index].view.icon = item.querySelector('.Bloko-TreeSelector-Icon');

            if (currentCollection[index].view.icon) {
                currentCollection[index].view.iconComponent = Components.make(
                    IconReactRenderer,
                    currentCollection[index].view.icon,
                    {
                        IconComponent: currentCollection[index].attributes.expanded
                            ? ChevronScaleSmallKindDown
                            : ChevronScaleSmallKindRight,
                        iconProps: {
                            initial: IconColor.Gray60,
                            highlighted: IconColor.Gray50,
                        },
                    }
                );
            }

            currentCollection[index].attributes.searchText = $(
                item.querySelector('.Bloko-TreeSelector-SearchContent')
            ).text();
            $(currentCollection[index].view.list).detach();
        });

        if (!options.leavesOnly && options.type === 'checkbox') {
            collection.getByAttributes({ indeterminate: true }).forEach((item) => {
                modelsHandler.set(item, {
                    indeterminate: false,
                });
                setIndeterminate(item);
            });
        }

        if (options.type === 'radio') {
            const selectedModels = collection.getByAttributes({ selected: true });
            const selectedModelsLength = selectedModels.length;

            if (selectedModelsLength) {
                for (let i = 0; i < selectedModelsLength - 1; i++) {
                    selectedModels[i].attributes.selected = false;
                }

                const selectedModel = selectedModels[selectedModelsLength - 1];
                previousSelectedRadioModels = collection.getById(selectedModel.attributes.id);
                // Выше сбросили selected состояние для всех моделей, кроме последней.
                // Возвращаем selected = true, если по id нашлось больше 1 модели
                previousSelectedRadioModels.forEach((model) => {
                    model.attributes.selected = true;
                });
            }
        }

        $el.append($renderTemplate);
        $renderTemplate = null;
    }

    function setIndeterminate(model) {
        modelsHandler.set(model, {
            indeterminate: true,
        });

        if (model.attributes.parent) {
            setIndeterminate(model.attributes.parent);
        }
    }

    function createCollectionAndTree(items, parentModel) {
        let itemsContent = '';
        const itemsModels = [];
        let atLeastOneSelected = false;
        let atLeastOneUnSelected = false;

        for (let i = 0, itemsLength = items.length; i < itemsLength; i++) {
            let element;
            const model = {
                attributes: {
                    id: items[i].id.toString() || null,
                    text: items[i].text || '',
                    name: inputName,
                    parent: null,
                    items: [],
                    expanded: !!items[i].expanded || false,
                    selected: !!items[i].selected || false,
                    disabled: !!items[i].disabled || false,
                    changed: false,
                    indeterminate: false,
                    additional: items[i].additional ? $.extend(true, {}, items[i].additional) : null,
                },
                view: {},
            };

            if (model.id === null) {
                logger.error(logger.ID_IS_NULL);
            }

            if (parentModel) {
                model.attributes.parent = parentModel;

                if (!options.leavesOnly && options.type === 'checkbox') {
                    if (parentModel.attributes.selected) {
                        model.attributes.selected = true;
                    }
                }

                if (model.attributes.selected) {
                    atLeastOneSelected = true;
                } else {
                    atLeastOneUnSelected = true;
                }

                itemsModels.push(model);

                if (i === itemsLength - 1) {
                    if (!options.leavesOnly && options.type === 'checkbox') {
                        parentModel.attributes.indeterminate = atLeastOneSelected && atLeastOneUnSelected;
                        parentModel.attributes.selected = !atLeastOneUnSelected;
                    }

                    if (options.leavesOnly) {
                        parentModel.attributes.selected = false;
                    }

                    parentModel.attributes.items = itemsModels;
                }
            }

            collection.add(model);

            if (items[i].items && items[i].items.length) {
                itemsContent += createCollectionAndTree(items[i].items, model);
            } else {
                element = elementTemplate.render(model.attributes, {
                    labelTemplate,
                    afterLabelWrapperTemplate,
                    afterLabelTemplate: options.afterLabelTemplate,
                });

                itemsContent += element;
            }
        }

        if (parentModel) {
            return listTemplate.render($.extend({ itemsTemplate: itemsContent }, parentModel.attributes), {
                labelTemplate,
                elementTemplate,
                afterLabelWrapperTemplate,
                afterLabelTemplate: options.afterLabelTemplate,
                iconsTemplate: IconsTemplate,
            });
        }

        return itemsContent;
    }

    function selectCheckbox(selectedItems, isAddSelected) {
        let isUnSelected = isAddSelected || false;

        if (selectedItems && !selectedItems.length) {
            unSelectAll();
            return;
        }

        selectedItems.forEach((id) => {
            const sameModels = collection.getById(id);
            if (!sameModels.length) {
                logger.error(logger.ITEM_NOT_FOUND);
                return;
            }

            if (!isUnSelected) {
                unSelectAll();
                isUnSelected = true;
            }

            sameModels.forEach((currentModel) => {
                changeByModel(currentModel, true);
            });
        });

        triggerChanged();
    }

    function unSelectAll() {
        collection.get().forEach((model) => {
            modelsHandler.set(model, {
                selected: false,
                indeterminate: false,
            });
        });
    }

    function changeByModel(currentModel, state) {
        if (currentModel.attributes.items.length && options.leavesOnly) {
            logger.error(logger.ELEMENT_NOT_SELECTABLE);
            return;
        }

        modelsHandler.set(currentModel, {
            selected: state,
            indeterminate: false,
        });

        if (currentModel.attributes.items && !options.leavesOnly) {
            changeItems(currentModel.attributes.items, currentModel.attributes.selected);
        }

        if (currentModel.attributes.parent && !options.leavesOnly) {
            changeParents(currentModel.attributes.parent, currentModel.attributes.selected);
        }
    }

    function triggerChanged() {
        const changedItems = collection.getByAttributes({
            changed: true,
        });

        const changedItemsToJSON = [];

        changedItems.forEach((model) => {
            changedItemsToJSON.push(modelsHandler.toJSON(model));
            modelsHandler.set(model, {
                changed: false,
            });
        });

        if (changedItemsToJSON.length) {
            publicInterface._trigger('Bloko-TreeSelector-Changed', changedItemsToJSON);
        }
    }

    function changeRadio(selectedItems, state) {
        if (!selectedItems.length) {
            unSelectAll();
            triggerChanged();
            return;
        }

        const id = selectedItems[selectedItems.length - 1];
        const sameModels = collection.getById(id);

        if (!sameModels.length) {
            logger.error(logger.ITEM_NOT_FOUND);
            return;
        }

        previousSelectedRadioModels.forEach((model) => {
            modelsHandler.set(model, {
                selected: false,
                indeterminate: false,
            });
        });
        previousSelectedRadioModels = sameModels;

        sameModels.forEach((currentModel) => {
            if (currentModel.attributes.items.length && options.leavesOnly) {
                logger.error(logger.ELEMENT_NOT_SELECTABLE);
                return;
            }

            modelsHandler.set(currentModel, {
                selected: state !== undefined ? state : !currentModel.attributes.selected,
            });
        });

        triggerChanged();
    }

    function toggleAttribute(attribute, filter) {
        collection.get().forEach((item) => {
            const attributes = {};
            attributes[attribute] = !!filter(modelsHandler.toJSON(item));
            modelsHandler.set(item, attributes);
        });
    }

    function changeItems(itemsModels, state) {
        itemsModels.forEach((modelItem) => {
            const sameModels = collection.getById(modelItem.attributes.id);
            sameModels.forEach((item) => {
                modelsHandler.set(item, {
                    selected: state,
                    indeterminate: false,
                });

                if (item.attributes.items.length) {
                    changeItems(item.attributes.items, state);
                }

                if (item.attributes.parent && !options.leavesOnly) {
                    changeParents(item.attributes.parent, state);
                }
            });
        });
    }

    function changeParents(parent, state) {
        let sameState = true;
        let isIndeterminate = false;

        parent.attributes.items.forEach((item) => {
            if (item.attributes.selected !== state) {
                sameState = false;
            }

            if (item.attributes.indeterminate) {
                isIndeterminate = true;
            }
        });

        if (parent.attributes.selected || sameState) {
            modelsHandler.set(parent, {
                selected: state,
            });
        }

        modelsHandler.set(parent, {
            indeterminate: false,
        });

        if (!sameState || isIndeterminate) {
            modelsHandler.set(parent, {
                indeterminate: true,
            });
        } else {
            modelsHandler.set(parent, {
                indeterminate: false,
            });
        }

        if (parent.attributes.parent) {
            changeParents(parent.attributes.parent, state);
        }
    }

    function setConsistFilterQueryChildren(dataJSON) {
        dataJSON.forEach((itemModel) => {
            modelsHandler.set(itemModel, {
                consistFilterQuery: true,
                hidden: false,
            });

            if (itemModel.attributes.items) {
                setConsistFilterQueryChildren(itemModel.attributes.items);
            }
        });
    }

    function setConsistFilterQueryParent(parent) {
        modelsHandler.set(parent, {
            consistFilterQuery: true,
            hidden: false,
        });

        if (parent.attributes.parent) {
            setConsistFilterQueryParent(parent.attributes.parent);
        }
    }

    function changeExpandedItems(items, state) {
        items.forEach((item) => {
            const items = item.attributes.items;
            if (items.length) {
                modelsHandler.set(item, {
                    expanded: state,
                });

                changeExpandedItems(items, state);
            }
        });
    }

    function changeExpandedParent(model, state) {
        modelsHandler.set(model, {
            expanded: state,
        });

        if (model.attributes.parent) {
            changeExpandedParent(model.attributes.parent, state);
        }
    }

    /**
     * Метод вызывает переданную функцию callback один раз для каждого элемента дерева и переключает
     * expanded состояние, в зависимости от того что вернула функция callback true/false или значение, становящееся
     * true/false при приведении в boolean
     * @param {Function} filter Callback функция. Вызывается с одним аргументом — Object модели
     */
    function toggleExpanded(filter) {
        if (typeof filter === 'function') {
            toggleAttribute('expanded', filter);
        } else {
            logger.error(logger.METHOD_ARGUMENT_NOT_FUNCTION);
        }
    }

    /**
     * Метод вызывает переданную функцию callback один раз для каждого элемента дерева и переключает
     * disabled состояние, в зависимости от того что вернула функция callback true/false или значение, становящееся
     * true/false при приведении в boolean
     * @param {Function} filter Callback функция. Вызывается с одним аргументом — Object модели
     */
    function toggleDisabled(filter) {
        if (typeof filter === 'function') {
            toggleAttribute('disabled', filter);
        } else {
            logger.error(logger.METHOD_ARGUMENT_NOT_FUNCTION);
        }
    }

    /**
     * Изменяет selected состояние на противоположное
     * @param {Array} selectedItems Список строковых id
     * @fires Bloko-TreeSelector-Changed
     */
    function change(selectedItems) {
        switch (options.type) {
            case 'checkbox':
                selectedItems.forEach((id) => {
                    const sameModels = collection.getById(id);
                    if (!sameModels.length) {
                        logger.error(logger.ITEM_NOT_FOUND);
                        return;
                    }

                    sameModels.forEach((currentModel) => {
                        changeByModel(currentModel, !currentModel.attributes.selected);
                    });
                });

                triggerChanged();
                break;
            case 'radio':
                changeRadio(selectedItems);
                break;
        }
    }

    /**
     * Выбирает указанные в списке id
     * @param {Array} selectedItems Список строковых id
     * @fires Bloko-TreeSelector-Changed
     */
    function setSelected(selectedItems) {
        switch (options.type) {
            case 'checkbox':
                selectCheckbox(selectedItems, false);
                break;
            case 'radio':
                changeRadio(selectedItems, true);
                break;
        }
    }

    /**
     * Добавляет к выбранным указанные в списке id.
     * @param {Array} selectedItems Список строковых id
     * @fires Bloko-TreeSelector-Changed
     */
    function addSelected(selectedItems) {
        if (options.type === 'radio') {
            logger.error(logger.WRONG_TYPE_RADIO);
            return;
        }

        selectCheckbox(selectedItems, true);
    }

    /**
     * Возвращает массив JSON представлений моделей узлов, которые выбраны.
     * [Описание](#getItemGetSelectedObjectModel)
     * @returns {Array}
     */
    function getSelected() {
        return collection.getByAttributes({ selected: true, disabled: false }).map((model) => {
            return modelsHandler.toJSON(model);
        });
    }

    /**
     * Метод фильтрует дерево по переданному аргументу. Вхождения проверяются по тексту контента узла
     * @param {String} filterQuery Подстрока поиска
     */
    function filterByContent(filterQuery) {
        const filterQueryTrimmed = filterQuery.trim();
        collection.get().forEach((item) => {
            const isIndexOf = FuzzySearch.match(filterQueryTrimmed, item.attributes.searchText);

            if (isIndexOf) {
                if (item.attributes.parent) {
                    setConsistFilterQueryParent(item.attributes.parent);

                    if (filterQueryTrimmed) {
                        changeExpandedParent(item.attributes.parent, true);
                    } else {
                        changeExpandedParent(item.attributes.parent, false);
                    }
                }
            }

            modelsHandler.set(item, {
                consistFilterQuery: isIndexOf,
            });
        });

        const consistFilterQueryElements = collection.getByAttributes({
            consistFilterQuery: true,
        });

        const parentsConsistFilterQueryMap = consistFilterQueryElements.reduce((map, item) => {
            const attributes = item.attributes;
            let parent = item.attributes.parent;

            if (attributes && attributes.items && attributes.items.length) {
                map[attributes.id] = true;
            }

            while (parent) {
                map[parent.attributes.id] = false;
                parent = parent.attributes.parent;
            }

            return map;
        }, {});

        const parentWithoutItemsConsistFilterQuery = consistFilterQueryElements.filter((item) => {
            return parentsConsistFilterQueryMap[item.attributes.id];
        });

        parentWithoutItemsConsistFilterQuery.forEach((item) => {
            setConsistFilterQueryChildren(item.attributes.items);
            modelsHandler.set(item, {
                expanded: false,
            });

            if (item.attributes.items.length) {
                changeExpandedItems(item.attributes.items, false);
            }
        });

        collection.get().forEach((item) => {
            modelsHandler.set(item, {
                hidden: !item.attributes.consistFilterQuery,
            });
        });
    }

    /**
     * Возвращает первое JSON представление элемента по id.
     * [Описание](#getItemGetSelectedObjectModel)
     * @param {String} id
     * @returns {Object}
     */
    function getItem(id) {
        const sameModels = collection.getById(id);
        const model = sameModels.length ? sameModels[0] : null;
        return modelsHandler.toJSON(model);
    }

    /**
     * Возвращает все JSON представления элементов по id.
     * [Описание](#getItemGetSelectedObjectModel)
     * @param {String} id
     * @returns {Array}
     */
    function getAllItems(id) {
        return collection.getById(id).map((model) => modelsHandler.toJSON(model));
    }

    return publicInterface;
};

export default Components.build({
    create(element, params) {
        const modelsHandler = new TreeSelectorModelsHandler();
        const collection = new TreeSelectorCollection([]);
        const treeSelectorInterface = new TreeSelector({
            el: element,
            modelsHandler,
            collection,
            type: params.type,
            leavesOnly: params.leavesOnly,
            labelTemplate: params.labelTemplate,
            afterLabelTemplate: params.afterLabelTemplate,
            dataJSON: params.dataJSON,
        });

        new TreeSelectorView({
            el: element,
            treeSelectorInterface,
            modelsHandler,
            collection,
            type: params.type,
        });

        return treeSelectorInterface;
    },
});
