import { forwardRef, Ref, ReactElement } from 'react';
import classnames from 'classnames';

import Textarea, { TextareaProps } from 'bloko/blocks/textarea';

import styles from 'bloko/blocks/textarea/textarea.less';

export enum TextareaIconPosition {
    Top = 'top',
    Bottom = 'bottom',
}

interface TextareaIconProps extends TextareaProps {
    /** Иконка справа */
    icon: ReactElement;
    /** Положение иконки */
    iconPosition?: TextareaIconPosition;
    /** Функция для получения рутового DOM элемента */
    innerRef?: Ref<HTMLSpanElement>;
    /** Указывает на строку с компонентом в исходном коде в режиме разработки. Генерируется babel-plugin-react-source */
    source?: string;
}

/**
 * Текстовое поле с иконкой.
 * Принимает те же props, что и [Textarea](#textarea) плюс дополнительные для иконки.
 */
const TextareaIcon = forwardRef<HTMLTextAreaElement, TextareaIconProps>((props, ref) => {
    const { icon, innerRef, iconPosition = TextareaIconPosition.Top, source, ...textareaProps } = props;
    return (
        <span ref={innerRef} className={styles['bloko-textarea-wrapper']} source={source}>
            <Textarea {...textareaProps} ref={ref} />
            <span
                className={classnames(styles['bloko-textarea-icon'], {
                    [styles['bloko-textarea-icon_bottom']]: iconPosition === TextareaIconPosition.Bottom,
                })}
            >
                {icon}
            </span>
        </span>
    );
});

export default TextareaIcon;
