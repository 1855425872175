import { Children } from 'react';
import { isValidElementType } from 'react-is';

const componentInvariants = (tags) => (props, propName, componentName) => {
    if (isValidElementType(props[propName]) || tags.includes(props[propName])) {
        return undefined;
    }

    return new TypeError(
        `Invalid prop ${propName} supplied to ${componentName}. Expected one of tags: ${tags} or function`
    );
};

const childrenInstanceOf = (allowedTypes) => (props, propName, componentName) => {
    const isValid = Children.toArray(props[propName]).every((child) => allowedTypes.includes(child.type));

    if (isValid) {
        return undefined;
    }

    return new TypeError(
        `Invalid prop ${propName} supplied to ${componentName}. Allowed types ${allowedTypes.map(({ name }) => name)}`
    );
};

const instanceOfDOMNodeOrNull = () => (props, propName, componentName) => {
    const isValid =
        props[propName] === null ||
        (typeof window !== 'undefined' && 'Node' in window && props[propName] instanceof window.Node);

    if (isValid) {
        return undefined;
    }

    return new TypeError(
        `Invalid prop ${propName} supplied to ${componentName}. Allowed types instanceof window.Node or null`
    );
};

const propTypes = {
    componentInvariants,
    childrenInstanceOf,
    instanceOfDOMNodeOrNull,
};

export default propTypes;
export { componentInvariants, childrenInstanceOf, instanceOfDOMNodeOrNull };
