import Backbone from 'backbone';
import $ from 'jquery';

import { IconColor, ChevronScaleSmallKindLeft, ChevronScaleSmallKindRight } from 'bloko/blocks/icon';
import IconReactRenderer from 'bloko/blocks/icon/IconReactRenderer';
import Components from 'bloko/common/core/Components';
import Events from 'bloko/common/events';
import Supports from 'bloko/common/supports';

import CalendarTemplate from 'bloko/blocks/calendar/calendar.mustache';
import CalendarModel from 'bloko/blocks/calendar/calendarModel';
import {
    addMonths,
    subMonths,
    isSameMonth,
    addYears,
    subYears,
    differenceInCalendarMonths,
} from 'bloko/blocks/calendar/datesHelper';

const cssClasses = {
    selected: 'bloko-calendar__day_selected',
    disabled: 'bloko-calendar__day_disabled',
    today: 'bloko-calendar__day_today',
    weekend: 'bloko-calendar__day_weekend',
    switcherArrowDisabled: 'bloko-calendar__switcher-arrow_disabled',
};

const bindings = {
    previousMonth: '.Bloko-Calendar-Previous-Month',
    nextMonth: '.Bloko-Calendar-Next-Month',
    previousYear: '.Bloko-Calendar-Previous-Year',
    nextYear: '.Bloko-Calendar-Next-Year',
    weekday: '.Bloko-Calendar-Weekday',
    dates: '.Bloko-Calendar-Dates',
    currentMonthDay: '.Bloko-Calendar-CurrentMonthDay',
};

const events = {
    dateSelected: 'Bloko-Calendar-DateSelected',
};

const CalendarView = Backbone.View.extend({
    events: {
        'click .Bloko-Calendar-Previous-Month': 'previousMonth',
        'click .Bloko-Calendar-Next-Month': 'nextMonth',
        'click .Bloko-Calendar-Previous-Year': 'previousYear',
        'click .Bloko-Calendar-Next-Year': 'nextYear',
        'click .Bloko-Calendar-Dates': 'select',
    },

    initialize() {
        this.listenTo(this.model, 'change:disabledDates', this.disableDays);
        this.listenTo(this.model, 'change:month', this.render);
        this.listenTo(this.model, 'change:year', this.render);
        this.listenTo(this.model, 'change:days', this.render);
        this.listenTo(this.model, 'change:selectedDate', function () {
            this.highlightSelectedDay();
            this.trigger(events.dateSelected, this.model.get('selectedDate'));
        });
        this.listenTo(this.model, 'change:isPreviousMonthDisabled', this.render);
        this.listenTo(this.model, 'change:isPreviousYearDisabled', this.render);
        this.listenTo(this.model, 'change:isNextMonthDisabled', this.render);
        this.listenTo(this.model, 'change:isNextYearDisabled', this.render);

        this.render();

        if (this.model.options.selectedDate) {
            this.selectDate(this.model.options.selectedDate);
        }
    },

    renderIcons() {
        for (const iconInstance of [
            this.previousMonthIcon,
            this.nextMonthIcon,
            this.nextYearIcon,
            this.previousYearIcon,
        ]) {
            if (iconInstance) {
                iconInstance.destroy();
            }
        }

        this.previousMonthIcon = Components.make(IconReactRenderer, $(bindings.previousMonth, this.$calendar).get(0), {
            IconComponent: ChevronScaleSmallKindLeft,
            iconProps: {
                initial: this.model.get('isPreviousMonthDisabled') ? IconColor.Gray50 : IconColor.Gray60,
            },
        });

        this.previousYearIcon = Components.make(IconReactRenderer, $(bindings.previousYear, this.$calendar).get(0), {
            IconComponent: ChevronScaleSmallKindLeft,
            iconProps: {
                initial: this.model.get('isPreviousYearDisabled') ? IconColor.Gray50 : IconColor.Gray60,
            },
        });

        this.nextMonthIcon = Components.make(IconReactRenderer, $(bindings.nextMonth, this.$calendar).get(0), {
            IconComponent: ChevronScaleSmallKindRight,
            iconProps: {
                initial: this.model.get('isNextMonthDisabled') ? IconColor.Gray50 : IconColor.Gray60,
            },
        });

        this.nextYearIcon = Components.make(IconReactRenderer, $(bindings.nextYear, this.$calendar).get(0), {
            IconComponent: ChevronScaleSmallKindRight,
            iconProps: {
                initial: this.model.get('isNextYearDisabled') ? IconColor.Gray50 : IconColor.Gray60,
            },
        });
    },

    render() {
        this.$calendar = $(this.template(this.model.toJSON()));

        this.renderIcons();
        this.disableDays();

        this.highlightToday();

        this.highlightWeekend();

        if (this.model.get('selectedDate')) {
            this.highlightSelectedDay();
        }

        if (this.model.options.autoSelectFirstAvailableDate && !this.model.get('selectedDate')) {
            this.autoSelectFirstAvailableDate();
        }

        if (this.model.options.range) {
            this.setMonthSwitchers();
            this.setYearSwitchers();
        }

        this.$el.html(this.$calendar);
    },

    setMonthSwitchers() {
        const currentOpenedDateTime = new Date(
            this.model.get('currentOpenedDate').getFullYear(),
            this.model.get('currentOpenedDate').getMonth()
        ).getTime();

        this.model.set(
            'isPreviousMonthDisabled',
            new Date(
                this.model.options.range.from.getFullYear(),
                this.model.options.range.from.getMonth()
            ).getTime() === currentOpenedDateTime
        );

        this.model.set(
            'isNextMonthDisabled',
            new Date(this.model.options.range.to.getFullYear(), this.model.options.range.to.getMonth()).getTime() ===
                currentOpenedDateTime
        );
    },

    setYearSwitchers() {
        this.model.set(
            'isPreviousYearDisabled',
            differenceInCalendarMonths(this.model.get('currentOpenedDate'), this.model.options.range.from) < 12
        );

        this.model.set(
            'isNextYearDisabled',
            differenceInCalendarMonths(this.model.options.range.to, this.model.get('currentOpenedDate')) < 12
        );
    },

    template(templateJson) {
        return CalendarTemplate.render(templateJson);
    },

    previousMonth(event) {
        event.stopPropagation();
        if (!this.model.get('isPreviousMonthDisabled')) {
            this.model.set('currentOpenedDate', subMonths(this.model.get('currentOpenedDate'), 1));
        }
    },

    nextMonth(event) {
        if (event) {
            event.stopPropagation();
        }

        if (!this.model.get('isNextMonthDisabled')) {
            this.model.set('currentOpenedDate', addMonths(this.model.get('currentOpenedDate'), 1));
        }
    },

    previousYear(event) {
        event.stopPropagation();
        if (!this.model.get('isPreviousYearDisabled')) {
            this.model.set('currentOpenedDate', subYears(this.model.get('currentOpenedDate'), 1));
        }
    },

    nextYear(event) {
        if (event) {
            event.stopPropagation();
        }

        if (!this.model.get('isNextYearDisabled')) {
            this.model.set('currentOpenedDate', addYears(this.model.get('currentOpenedDate'), 1));
        }
    },

    select(event) {
        const $currentMonthDay = $(event.target).closest(bindings.currentMonthDay);
        const selectedDay = $currentMonthDay.data('date');

        if (selectedDay && !$currentMonthDay.hasClass(cssClasses.disabled)) {
            const selectedDate = new Date(this.model.get('currentOpenedDate'));
            selectedDate.setDate(selectedDay);
            this.selectDate(selectedDate);
        }
    },

    selectDate(selectedDate) {
        if (!isSameMonth({ date: selectedDate, inDate: this.model.get('currentOpenedDate') })) {
            this.model.set('currentOpenedDate', new Date(selectedDate.getFullYear(), selectedDate.getMonth()));
        }

        this.model.set('selectedDate', selectedDate);
    },

    getDayElement(day, month, year) {
        return $(
            `${bindings.currentMonthDay}[data-date='${day}'][data-month='${month}'][data-year='${year}']`,
            this.$calendar
        );
    },

    highlightSelectedDay() {
        const selectedDate = this.model.get('selectedDate');
        if (isSameMonth({ date: selectedDate, inDate: this.model.get('currentOpenedDate') })) {
            $(bindings.currentMonthDay, this.$calendar).removeClass(cssClasses.selected);
            const day = selectedDate.getDate();
            const month = selectedDate.getMonth();
            const year = selectedDate.getFullYear();
            this.getDayElement(day, month, year).addClass(cssClasses.selected);
        }
    },

    highlightToday() {
        const currentDate = new Date();
        if (isSameMonth({ date: currentDate, inDate: this.model.get('currentOpenedDate') })) {
            const today = this.model.get('days').filter(({ today }) => today === true);
            const { dayNumber, month, year } = today[0];
            this.getDayElement(dayNumber, month, year).addClass(cssClasses.today);
        }
    },

    highlightWeekend() {
        const weekends = this.model.get('days').filter(({ weekend }) => weekend === true);
        weekends.forEach(({ dayNumber, month, year }) =>
            this.getDayElement(dayNumber, month, year).addClass(cssClasses.weekend)
        );
    },

    autoSelectFirstAvailableDate() {
        let isDateAutoSelected = false;
        $(bindings.currentMonthDay, this.$calendar).each((index, date) => {
            const $date = $(date);
            if (!$date.hasClass(cssClasses.disabled)) {
                isDateAutoSelected = true;
                this.selectDate(
                    new Date(
                        this.model.get('currentOpenedDate').getFullYear(),
                        this.model.get('currentOpenedDate').getMonth(),
                        $date.data('date')
                    )
                );
                return false;
            }

            return true;
        });

        if (!isDateAutoSelected) {
            this.nextMonth();
        }
    },

    disableDays() {
        const disabledDays = this.model.get('days').filter(({ disabled }) => disabled === true);
        disabledDays.forEach(({ dayNumber, month, year }) =>
            this.getDayElement(dayNumber, month, year).addClass(cssClasses.disabled)
        );
    },

    setDisabledDates(disabledDates) {
        this.model.set('disabledDates', disabledDates);
        if (this.model.options.autoSelectFirstAvailableDate) {
            this.autoSelectFirstAvailableDate();
        }
    },

    getSelectedDate() {
        return this.model.get('selectedDate');
    },
});

function makeDateWithZeroTime(date) {
    const zeroTimeDate = date ? new Date(date) : new Date();
    zeroTimeDate.setHours(0, 0, 0, 0);
    return zeroTimeDate;
}

export default Components.build({
    defaults: {
        range: null,
        initialDate: new Date(),
        selectedDate: null,
        disabledDates: [],
        disabledWeekDays: [],
        autoSelectFirstAvailableDate: false,
        disableDaysBeforeDate: null,
        disableDaysAfterDate: null,
        disableInitialDate: false,
    },
    /**
     * Триггерится при изменении даты в календаре
     *
     * @event Bloko-Calendar-DateSelected
     */

    /**
     * @param {Element} element DOM элемент
     *
     * @param {Object} params Параметры
     *
     * @param {Object} params.translations Обязательный параметр translations должен содержать массивы weekdays и
     * months, иначе календарь не инициализируется
     * <pre>weekdays: ['пн', 'вт', ..., 'вс'], months: ['Январь', 'Февраль', ..., 'Декабрь']</pre>
     *
     * @param {Object} [params.range=null] Показать только те месяца, которые входят в диапазон, объект состоит из
     * свойств дат `from: 'дата', to: 'дата'`
     *
     * @param {String} [params.initialDate=new Date()] Месяц, который будет отрисован в календаре при его
     * инициалиции.
     *
     * @param {String} [params.selectedDate=null] Выбранная дата, возбуждается событие `Bloko-Calendar-DateSelected`
     *
     * @param {Array} params.disabledDates Неактивные даты
     *
     * @param {Array} params.disabledWeekDays Неактивные даты по дням недели
     *
     * @param {Boolean} [params.autoSelectFirstAvailableDate=false] Автовыбор первой активной даты. Возбуждается
     * событие `Bloko-Calendar-DateSelected` Если в открытом месяце нет активной даты, то календарь пытается
     * переключить на следующий месяц, где продолжит поиск первой доступной даты
     *
     * @param {String} [params.disableDaysBeforeDate=null] Все даты до указанной даты неактивны.
     * Принимает любое выражение, подходящие для конструктора класса Date
     *
     * @param {String} [params.disableDaysAfterDate=null] Все даты после указанной даты неактивны.
     * Принимает любое выражение, подходящие для конструктора класса Date
     *
     * @constructor
     */
    create(element, params) {
        if (
            !params.translations ||
            !Array.isArray(params.translations.weekdays) ||
            !Array.isArray(params.translations.months)
        ) {
            throw new Error('Bloko/Calendar: No or incorrect required param: translations');
        }

        params.range = params.range
            ? {
                  from: makeDateWithZeroTime(params.range.from),
                  to: makeDateWithZeroTime(params.range.to),
              }
            : params.range;

        params.disabledDates = params.disabledDates.map((date) => {
            return makeDateWithZeroTime(date);
        });

        params.initialDate = makeDateWithZeroTime(params.initialDate);
        params.selectedDate = params.selectedDate ? makeDateWithZeroTime(params.selectedDate) : null;

        const calendarView = new CalendarView({ model: new CalendarModel(params), el: element });

        // В ios, если на element слушается click, то при tap вся область выделяется,
        // чтобы этого не было добавляем класс, который отменяет выделение.
        if (Supports.mobile()) {
            $(element).addClass('bloko-calendar-reset-tap-highlighting');
        }

        const publicInterface = Events.extend({
            /**
             * Выбирает дату
             * @param {String} date dateString
             * @fires Bloko-Calendar-DateSelected
             */
            selectDate(date) {
                calendarView.selectDate(makeDateWithZeroTime(date));
            },
            /**
             * Принимает массив дат, например ['2017-01-23'], которые надо сделать неактивными.
             * Неактивные даты, которые были переданы параметрами компонента перезаписываются.
             * @param {Array} disabledDates [dateString, dateString]
             */
            setDisabledDates(disabledDates) {
                calendarView.setDisabledDates(
                    disabledDates.map((date) => {
                        return makeDateWithZeroTime(date);
                    })
                );
            },
            /**
             * Возвращает экземпляр объекта Date выбранной даты в календаре.
             * @return Date
             */
            getSelectedDate() {
                return calendarView.getSelectedDate();
            },
        });

        calendarView.on(events.dateSelected, (date) => {
            publicInterface._trigger(events.dateSelected, date);
        });

        return publicInterface;
    },
});
