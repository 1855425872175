import NestedTagList from 'bloko/blocks/tagList/nestedTagList';
import Components from 'bloko/common/core/Components';
import { filterTreeByIdsWithParents } from 'bloko/common/tree/treeCollectionHelper';

/**
 * @param {Element} element
 * @param {CompositeSelectionComponentParams} params
 * @param {TreeCollection} params.collection Дерево элементов
 * @param {CollectionFlag} params.selected Список выбранных элементов
 * @param {Object} params.selfParams
 * @constructor
 */

function NestedTagListProxy(element, params) {
    const collection = params.collection;
    const selected = params.selected;

    const nestedTagListInstance = NestedTagList.create(element, params.selfParams);
    let isLocked = false;

    nestedTagListInstance.on('Bloko-TagList-Removed', (item) => {
        // Чтобы не вызывались лишние selected.remove, когда удаляем элемент не из самих тегов
        if (!isLocked) {
            selected.remove([item.id]);
        }
        /**
            // Оставляем этот комментарий для понимания какой есть путь для отказа от локера.
            // В CompositeSelection можно засетить другой родительский узел, что вызовет удаление всех старых тегов,
            // и для каждого вызовется событие Removed.
            // Придется для каждого удаленного элемента обходить дерево и проверять надо ли удалять узел
            // из selected-коллекции. Это затратная операция.
            //
            // У nestedTagList особенная логика. Если выбран элемент, то рисуется и его родителя.
            // соответсвенно, если удаляем родительский элемент, которого нет в selected, не удаляем его из selected
            var id = item.id;
            if (selected.contains(id)) {
                selected.remove([id]);
            }*/
    });

    const update = function () {
        isLocked = true;
        nestedTagListInstance.set(filterTreeByIdsWithParents(collection, selected.get()));
        isLocked = false;
    };

    selected.on('change', update);
    update();

    return nestedTagListInstance;
}

export default Components.build({
    defaults: {
        removable: true,
    },
    create: NestedTagListProxy,
});
