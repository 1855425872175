import TreeSelectorPopup from 'bloko/blocks/treeSelectorPopup/treeSelectorPopup';
import Components from 'bloko/common/core/Components';
/**
 * @param {Element} element
 * @param {CompositeSelectionComponentParams} params
 * @param {TreeCollection} params.collection Дерево элементов
 * @param {CollectionFlag} params.selected Список выбранных элементов
 * @param {Boolean} [params.singleChoice=false] Если true, то можно выбрать только один элемент
 * @param {Boolean} [params.leavesOnly=false] Если true, то можно выбрать только элементы без потомков
 * @param {Object} params.selfParams
 * @constructor
 */
function TreeSelectorPopupProxy(element, params) {
    const collection = params.collection;
    const selected = params.selected;
    const treeSelectorPopupParams = params.selfParams;

    const container = document.createElement('div');
    container.className = 'g-hidden';
    document.body.appendChild(container);

    treeSelectorPopupParams.treeSelector.dataJSON = collection.toTree();

    if (params.singleChoice) {
        treeSelectorPopupParams.treeSelector.type = 'radio';
    }
    if (params.leavesOnly) {
        treeSelectorPopupParams.treeSelector.leavesOnly = true;
    }

    const treeSelectorPopupInstance = Components.make(TreeSelectorPopup, container, treeSelectorPopupParams);
    const treeSelectorInstance = treeSelectorPopupInstance.treeSelector;

    element.addEventListener('click', () => {
        treeSelectorPopupInstance.modal.show();
    });

    treeSelectorPopupInstance.on('Bloko-TreeSelectorPopup-Changed', () => {
        const selectedIds = treeSelectorInstance.getSelected().map((item) => {
            return item.id;
        });
        selected.set(selectedIds);
    });

    function update() {
        // FIXME: Исправить в HH-70773. При закрытии попапа крестиком или кнопкой отмены три-селектор-попап
        // восстанавливает предыдущее состояние три-селектора в обход CS. Если в то время,
        // когда попап был открыт, состояние CS меняется через API или другим компонентом,
        // в три-селекторе после отмены останется предыдущее состояние.
        treeSelectorInstance.setSelected(selected.get());
    }

    selected.on('change', update);
    update();

    return treeSelectorPopupInstance;
}

export default Components.build({
    defaults: {
        treeSelector: {
            type: 'checkbox',
        },
    },
    create: TreeSelectorPopupProxy,
});
