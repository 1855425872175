import React, { useCallback, useState, useEffect, ReactElement, HTMLAttributes } from 'react';

import { IconDynamic, IconLink, IconColor, BarsScaleSmall } from 'bloko/blocks/icon';
import { IconLinkProps } from 'bloko/blocks/icon/IconLink';
import InputText, { InputProps } from 'bloko/blocks/inputText';
import Suggest, { SuggestProps } from 'bloko/blocks/suggest';
import { DataProviderItem } from 'bloko/blocks/suggest/types';
import ImmutableSelectionStrategy from 'bloko/common/tree/immutableSelectionStrategy';
import TreeCollection from 'bloko/common/tree/treeCollection';
import {
    getIdsWithNoParentsInSameList,
    narrowDownExcludedFromChildrenToParents,
    removeExcludedFromSelected,
} from 'bloko/common/tree/treeCollectionHelper';
import { AdditionalDefault } from 'bloko/common/tree/types';

/*
    Кастомный хук для создания параметризированной функции вывода поля ввода с саджестом и без
*/

interface UseRenderInputProps<A extends AdditionalDefault> {
    collection: TreeCollection<A>;
    selectionStrategy: InstanceType<typeof ImmutableSelectionStrategy>;
    value: string[];
    selected: string[];
    excluded: string[];
    withTagList: boolean;
    singleChoice?: boolean;
    maxItems?: number;
    showTreeSelectorPopup: React.MouseEventHandler<HTMLButtonElement>;
    onBlur?: () => void;
    onFocus?: () => void;
    onChange?: (items: string[], excludedItems: string[]) => void;
}

type SuggestPropsType = Pick<SuggestProps, 'dataProvider'> & Partial<SuggestProps> & { suggest?: boolean };
type IconLinkPropsType = Partial<IconLinkProps> & HTMLAttributes<HTMLElement>;

interface UseRenderFunc {
    (
        props: {
            suggest?: boolean;
            iconLinkProps?: IconLinkPropsType;
            suggestProps?: SuggestPropsType;
        } & Partial<InputProps>
    ): ReactElement | null;
}

export interface UseRenderInputHook {
    <A extends AdditionalDefault>(props: UseRenderInputProps<A>): UseRenderFunc;
}

const useRenderInput: UseRenderInputHook = ({
    collection,
    selectionStrategy,
    value,
    selected,
    excluded,
    withTagList,
    singleChoice,
    maxItems,
    showTreeSelectorPopup,
    onBlur,
    onChange,
    onFocus,
}) => {
    const isCollectionLoaded = !!collection.getTopLevel().length;
    let model: ReturnType<typeof collection.getModel>;
    if (!withTagList && singleChoice && value[0]) {
        model = collection.getModel(value[0]);
    }

    const [text, setText] = useState(model?.text || '');

    useEffect(() => {
        if (!withTagList && singleChoice && value[0]) {
            const collectionModel = collection.getModel(value[0]);
            setText(collectionModel ? collectionModel.text : '');
        } else {
            setText('');
        }
    }, [value, collection, withTagList, singleChoice]);

    const renderInputIcon = useCallback(
        (iconLinkProps: IconLinkPropsType | undefined): ReactElement => (
            <IconDynamic>
                {/**
                 * Тип свойств вытаскиваемых из IconLinkProps не содержит html-атрибуты вроде title, data-qa
                 *
                 * Для их поддержки к типу iconLinkProps примешивается HTMLAttributes<HTMLElement>
                 *
                 * Но результирующий тип не проходит проверку для ComponentWithCustomElement так как атрибуты
                 * не привязаны к выбранному элементу
                 *
                 * Приведение к object решает проблему
                 */}
                <IconLink {...(iconLinkProps as object)} onClick={showTreeSelectorPopup}>
                    <BarsScaleSmall initial={IconColor.Gray50} highlighted={IconColor.Gray60} />
                </IconLink>
            </IconDynamic>
        ),
        [showTreeSelectorPopup]
    );

    const renderRawInput = useCallback(
        (inputProps: InputProps, iconLinkProps: IconLinkPropsType | undefined): ReactElement => (
            <InputText
                {...inputProps}
                onChange={(value, props) => {
                    setText(value);
                    inputProps.onChange?.(value, props);
                }}
                disabled={!isCollectionLoaded || inputProps.disabled}
                onBlur={onBlur}
                onFocus={onFocus}
                showLoading={!isCollectionLoaded}
                icon={renderInputIcon(iconLinkProps)}
            />
        ),
        [isCollectionLoaded, onBlur, onFocus, renderInputIcon]
    );

    const handleSuggestChange = useCallback(
        (item: DataProviderItem, search: string): void => {
            if (item && item.id) {
                const processedItems = [String(item.id)];
                const selectedItems = selectionStrategy.add(selected, processedItems);
                const result = removeExcludedFromSelected(
                    getIdsWithNoParentsInSameList(collection, selectedItems),
                    selectionStrategy.exclude(selectedItems, excluded, processedItems)
                );

                onChange?.(result[0], narrowDownExcludedFromChildrenToParents(collection, result[1]));
                onBlur?.();
            } else if (singleChoice && !search) {
                onChange?.([], []);
                onBlur?.();
            }
        },
        [selectionStrategy, selected, collection, singleChoice, onBlur, onChange, excluded]
    );

    const renderSuggestedInput = useCallback(
        (
            inputProps: InputProps,
            iconLinkProps: IconLinkPropsType | undefined,
            suggestProps: SuggestPropsType
        ): ReactElement => (
            <Suggest autoSelect {...suggestProps} value={{ text }} onChange={handleSuggestChange}>
                {renderRawInput(inputProps, iconLinkProps)}
            </Suggest>
        ),
        [renderRawInput, text, handleSuggestChange]
    );

    const hideInput = withTagList && maxItems && selected.length === maxItems;

    return useCallback(
        ({ suggest, iconLinkProps, suggestProps, ...inputProps } = {}) => {
            if (hideInput) {
                return null;
            }

            return suggest
                ? renderSuggestedInput(inputProps, iconLinkProps, suggestProps as SuggestPropsType)
                : renderRawInput(inputProps, iconLinkProps);
        },
        [renderSuggestedInput, renderRawInput, hideInput]
    );
};

export default useRenderInput;
