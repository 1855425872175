const MINUS_SIGN = '\u2212';
const MINUS_VARIANTS = ['-', MINUS_SIGN];

export interface FormatParams {
    groupSeparator?: string;
    decimalMark?: string;
    groupSize?: number;
    decimalLength?: number;
    allowNegative?: boolean;
}
const defaults = {
    groupSeparator: '\u2009',
    decimalMark: ',',
    groupSize: 3,
    decimalLength: 2,
    allowNegative: true,
};

/**
 * Форматирует строку с учетом параметров
 *
 * @param {String}      text                                    текст для валидации
 * @param {Object}      passedParams                            параметры
 * @param {Number}      [passedParams.groupSize=3]              количество цифр в группе
 * @param {Number|null} [passedParams.decimalLength=2]          ограничение количества знаков после запятой.
 *                                                              `null` — без ограничения
 * @param {String}      [passedParams.groupSeparator="\u2009"]  разделитель групп
 * @param {String}      [passedParams.decimalMark=","]          разделитель дробной части
 * @param {Boolean}     [passedParams.allowNegative=true]       разрешить отрицательные числа
 */
function format(text: string, passedParams?: FormatParams): string {
    const params = { ...defaults, ...passedParams };
    const integerGroupsSplit = new RegExp(`\\B(?=(\\d{${params.groupSize}})+(?!\\d))`, 'g');
    const splittedText = clean(text, params).split('.');
    splittedText[0] = splittedText[0].replace(integerGroupsSplit, params.groupSeparator).replace(/^-/, MINUS_SIGN);
    if (splittedText[0] === '' && splittedText.length === 1) {
        return '';
    }
    if (splittedText[1] === '') {
        return splittedText[0];
    }
    if (splittedText[0] === '') {
        splittedText[0] = '0';
    }
    return splittedText.join(params.decimalMark);
}

function clean(text: string, params: FormatParams): string {
    const trimmedText = text.trim();
    const minus = params.allowNegative && MINUS_VARIANTS.includes(trimmedText[0]) ? '-' : '';
    const cleanedText = trimmedText
        .replace(/[^\d.,]+/g, '') // Удаляем всё, кроме разделителей
        .replace(/[.,]/, '#') // Первый разделитель меняем на #
        .replace(/[.,]/g, ''); // Удаляем все остальные разделители
    const splittedText = `${minus}${cleanedText}`.split('#'); // Разрезаем по первому разделителю

    if (params.decimalLength === null) {
        return splittedText.join('.');
    } else if (params.decimalLength === 0 || splittedText.length === 1) {
        return splittedText[0];
    }

    return `${splittedText[0]}.${splittedText[1].slice(0, params.decimalLength)}`;
}

export default {
    format,
};
