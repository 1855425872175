import { useState, useCallback, FC } from 'react';

import Calendar, { CalendarProps } from 'bloko/blocks/calendar/Base';
import { DateString, YearMonthDayString } from 'bloko/blocks/calendar/datesHelper';
import { makeHighlightedInterval } from 'bloko/blocks/calendar/helper';

export interface IntervalCalendarPickerProps extends Omit<CalendarProps, 'onDateClick'> {
    /** Дата инициализации календаря в формате 'YYYY-MM-DD' или ISO */
    initialDate: DateString;
    /** Дата начала интервала в формате 'YYYY-MM-DD' или ISO */
    start?: DateString;
    /** Дата конца интервала в формате 'YYYY-MM-DD' или ISO */
    end?: DateString;
    /** Обработчик клика по дате принимает объект вида { start: 'YYYY-MM-DD', end: 'YYYY-MM-DD' } и будет вызван
     * после выбора даты конца интервала. */
    onDateClick: (props: { start: YearMonthDayString; end: YearMonthDayString }) => void;
}

const IntervalCalendarPicker: FC<IntervalCalendarPickerProps> = ({
    initialDate,
    start,
    end,
    onDateClick,
    ...props
}) => {
    const [currentHighlighted, setCurrentHighlightedDate] = useState(
        makeHighlightedInterval({ startString: start, endString: end })
    );

    const onDateClickHandler = useCallback(
        (selectedDate: YearMonthDayString) => {
            if (currentHighlighted.length === 0 || currentHighlighted.length > 1) {
                setCurrentHighlightedDate([selectedDate]);
            } else {
                const highlighted = makeHighlightedInterval({
                    startString: currentHighlighted[0],
                    endString: selectedDate,
                });
                onDateClick({ start: highlighted[0], end: highlighted[highlighted.length - 1] });
                setCurrentHighlightedDate(highlighted);
            }
        },
        [currentHighlighted, onDateClick]
    );

    return (
        <Calendar
            {...props}
            initialDate={initialDate}
            highlighted={currentHighlighted}
            onDateClick={onDateClickHandler}
            showInterval={currentHighlighted.length > 1}
        />
    );
};

export default IntervalCalendarPicker;
