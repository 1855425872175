import $ from 'jquery';

const LOADER_CLASS_MAPPING = {
    'bloko-button': 'bloko-button_loading',
    'bloko-loading': 'bloko-loading_visible',
};

/**
 * Создает объект управления состоянием загрузки у элемента
 * @param {Element} element DOM-элемент, на котором создается объект
 * @name bloko/blocks/LoadingSetter/LoadingSetter
 * @constructor
 */
export default function (element) {
    const $element = $(element);

    const loadingClass = (function () {
        for (const blokoClass in LOADER_CLASS_MAPPING) {
            if ($element.hasClass(blokoClass)) {
                return LOADER_CLASS_MAPPING[blokoClass];
            }
        }
        return null;
    })();

    /**
     * Начало загрузки
     */
    this.start = function () {
        if (loadingClass) {
            $element.addClass(loadingClass);
        }
    };

    /**
     * Окончание загрузки
     */
    this.stop = function () {
        if (loadingClass) {
            $element.removeClass(loadingClass);
        }
    };
}
