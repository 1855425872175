import { useEffect, useRef } from 'react';

export default function useResize(handler: (event: UIEvent) => void): void {
    const handlerRef = useRef(handler);
    handlerRef.current = handler;

    useEffect(() => {
        const resizeHandler = (event: UIEvent) => handlerRef.current(event);
        window.addEventListener('resize', resizeHandler);
        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, []);
}
