import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const ThumbScaleSmallKindDownReducedFalse = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M11.3333 1.33334V8.66668M14.6666 6.53334V3.46668C14.6666 2.71994 14.6666 2.34657 14.5213 2.06136C14.3935 1.81047 14.1895 1.6065 13.9386 1.47867C13.6534 1.33334 13.28 1.33334 12.5333 1.33334H5.41196C4.43764 1.33334 3.95048 1.33334 3.55701 1.51163C3.21022 1.66877 2.91549 1.92162 2.70745 2.24049C2.4714 2.60227 2.39732 3.08377 2.24917 4.04676L1.90045 6.31343C1.70505 7.58355 1.60735 8.21861 1.79582 8.71276C1.96125 9.14647 2.27239 9.50914 2.6759 9.73859C3.13564 10 3.77818 10 5.06324 10H5.59995C5.97332 10 6.16001 10 6.30261 10.0727C6.42806 10.1366 6.53004 10.2386 6.59396 10.364C6.66662 10.5066 6.66662 10.6933 6.66662 11.0667V13.0228C6.66662 13.9307 7.40262 14.6667 8.31051 14.6667C8.52706 14.6667 8.7233 14.5391 8.81125 14.3413L11.0518 9.30013C11.1537 9.07084 11.2046 8.9562 11.2852 8.87213C11.3563 8.79783 11.4438 8.74101 11.5406 8.70613C11.6501 8.66668 11.7756 8.66668 12.0265 8.66668H12.5333C13.28 8.66668 13.6534 8.66668 13.9386 8.52135C14.1895 8.39352 14.3935 8.18955 14.5213 7.93866C14.6666 7.65345 14.6666 7.28008 14.6666 6.53334Z"
                stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                strokeWidth={1.33333}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ),
    'Thumb'
);
export default ThumbScaleSmallKindDownReducedFalse;
