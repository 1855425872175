import $ from 'jquery';

import NumericInput from 'bloko/blocks/numericInput/numericInput';
import Components from 'bloko/common/core/Components';
import blokoEvent from 'bloko/common/event';
import NumberFormatter from 'bloko/common/numberFormatter';
import Supports from 'bloko/common/supports';

import getInputMaxLength from 'bloko/blocks/formattedNumericInput/getInputMaxLength';

// В андроиде и винфонах много проблем:
// * При форматировании с пробелами при наборе клавиатура меняется на буквенную в хроме (Sony xperia D5803)
// * При наборе каретка перескакивает по совершенно иному алгоритму (Samsung SM-T230, Samsung GT-N7100)
// * В винфонах каретка перескакивает в конец при редактировании числа со сложным форматированием
// Для этих устройств делаем фолбек на numericInput
const formatterDisabled = Supports.android() || Supports.IEMobile();

const bindings = {
    input: '.Bloko-FormattedNumericInput-Visible',
    hidden: '.Bloko-FormattedNumericInput-Hidden',
};

/**
 * Компонент для разбиения числа на группы цифр в текстовом инпуте.
 * Позволяет вводить только числа и вставлять в инпут форматированное значение.
 * Хранит отформатированные без пробелов данные в скрытом инпуте.
 *
 * @param {Element} element
 * @param params {Object} Параметры [numericInput](#/[Classic]%20NumericInput)
 *
 * @exports bloko/blocks/formattedNumericInput/formattedNumericInput
 * @constructor
 */
function formattedNumericInput(element, params) {
    const $element = $(element);
    const $hiddenInput = $element.find(bindings.hidden);
    const $input = $element.find(bindings.input);

    if (formatterDisabled) {
        params.groupSeparator = '';
    }
    Components.make(NumericInput, $input[0], params);

    $input.on('input change', () => {
        $hiddenInput.val(
            NumberFormatter.format($input.val(), {
                groupSeparator: '',
                decimalMark: '.',
                decimalLength: params.decimalLength,
                allowNegative: params.allowNegative,
            })
        );
        $hiddenInput[0].dispatchEvent(blokoEvent('change'));
    });

    if (params.maxLength) {
        const inputLength = getInputMaxLength(
            params.maxLength,
            params.groupSeparator,
            params.groupSize,
            formatterDisabled
        );
        $input.attr('maxLength', inputLength);
    }
}

export default Components.build({
    defaults: {
        groupSeparator: '\u2009',
        groupSize: 3,
    },
    create: formattedNumericInput,
});
