import { useRef, useCallback, RefObject } from 'react';

interface ScrollOptionList {
    optionsListRef: RefObject<HTMLDivElement>;
    scrollTo: (offsetTop: number, offsetHeight: number) => void;
}

const useScrollOptionList = (): ScrollOptionList => {
    const optionsListRef = useRef<HTMLInputElement>(null);

    const scrollTo = useCallback((optionOffsetTop: number, optionOffsetHeight: number) => {
        if (!optionsListRef.current) {
            return;
        }
        const listWrapper = optionsListRef.current;

        if (listWrapper.scrollTop > optionOffsetTop) {
            listWrapper.scrollTop = optionOffsetTop;
        } else if (optionOffsetTop + optionOffsetHeight > listWrapper.scrollTop + listWrapper.clientHeight) {
            listWrapper.scrollTop = optionOffsetTop + optionOffsetHeight - listWrapper.clientHeight;
        }
    }, []);

    return { optionsListRef, scrollTo };
};

export default useScrollOptionList;
