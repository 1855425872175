import { memo } from 'react';
import classnames from 'classnames';

import TreeSelectorElement from 'bloko/blocks/treeSelector/Element';
import dataQa from 'bloko/blocks/treeSelector/dataQa';
import TreeCollection from 'bloko/common/tree/treeCollection';
import { IdPredicate, TreeModel, AdditionalDefault } from 'bloko/common/tree/types';

import { LabelComponentType, IconComponentType } from 'bloko/blocks/treeSelector/Dummy/types';

import styles from 'bloko/blocks/treeSelector/treeSelector.less';

const getClassName = (hasChildren: boolean, hasAction: boolean): string => {
    return classnames(styles['bloko-tree-selector-item'], {
        [styles['bloko-tree-selector-item_has-children-has-action']]: hasChildren && hasAction,
        [styles['bloko-tree-selector-item_no-children-no-action']]: !hasChildren && !hasAction,
        [styles['bloko-tree-selector-item_no-children']]: !hasChildren,
    });
};

interface ItemsListProps<A extends AdditionalDefault> {
    /** Имя инпута. */
    inputName: string;
    /** Дерево элементов. */
    collection: TreeCollection<A>;
    /** Разрешает выбор только элементов без потомков. */
    leavesOnly?: boolean;
    /** Разрешает выбор только одного элемента. */
    singleChoice?: boolean;
    /** Выбранные ID. */
    selected: string[];
    /** Изначально открытые ID, которые можно динамически изменять извне. */
    expanded: string[];
    /** Запрещённые ID. */
    disabled: string[];
    indeterminate: string[];
    /** Компонент для вывода метки у элемента дерева */
    LabelComponent?: LabelComponentType<A>;
    /** Компонент для вывода иконки у элемента дерева */
    IconComponent?: IconComponentType<A>;
    /** Обработчик изменения набора выбранных ID.
     * В аргументах получает `{String} id`, `{Boolean} isSelected`. */
    onChange?: (id: string, isSelected: boolean) => void;
    /** Обработчик изменения состояния раскрытия элементов
     * В аргументах получает список id раскрытых элементов `{Array} expanded` */
    onExpansion: (id: string) => void;
    items?: TreeModel<A>[];
    parentId?: string;
    /** Функция возвращает true для ID элементов, которые можно выбрать. При использовании
     * вместе с параметром leavesOnly применяется только к элементам без потомков. */
    checkSelectableId: IdPredicate;
}

interface ItemsListComponent {
    <A extends AdditionalDefault>(props: ItemsListProps<A>): JSX.Element;
}

const ItemsList: ItemsListComponent = (props) => {
    const {
        collection,
        expanded = [],
        inputName,
        indeterminate,
        parentId,
        items: initialItems,
        singleChoice,
        leavesOnly,
        selected,
        disabled,
        LabelComponent,
        IconComponent,
        onChange,
        onExpansion,
        checkSelectableId,
    } = props;
    const items = initialItems || collection.getTopLevel();

    return (
        <>
            {items.map((item) => {
                const isExpanded = expanded.includes(item.id);
                const hasChildren = collection.hasChildren(item.id);
                const hasAction = !(leavesOnly && hasChildren) && checkSelectableId(item.id);
                const className = getClassName(hasChildren, hasAction);

                return (
                    <div
                        key={item.id}
                        className={className}
                        data-qa={classnames(dataQa.item, `${dataQa.item}-${item.id}`, {
                            [dataQa.expanded]: isExpanded,
                        })}
                    >
                        <div className={styles['bloko-tree-selector-item-content']}>
                            {IconComponent && (
                                <div className={styles['bloko-tree-selector-item-icon']}>
                                    <IconComponent
                                        id={item.id}
                                        text={item.text}
                                        additional={item.additional}
                                        collection={collection}
                                    />
                                </div>
                            )}
                            <TreeSelectorElement
                                parentId={parentId}
                                id={item.id}
                                name={inputName}
                                hasAction={hasAction}
                                hasChildren={hasChildren}
                                disabled={disabled.includes(item.id)}
                                selected={selected.includes(item.id)}
                                expanded={isExpanded}
                                indeterminate={indeterminate.includes(item.id)}
                                onExpansion={onExpansion}
                                onChange={onChange}
                                singleChoice={singleChoice}
                            >
                                {LabelComponent ? (
                                    <LabelComponent
                                        id={item.id}
                                        text={item.text}
                                        additional={item.additional}
                                        collection={collection}
                                    />
                                ) : (
                                    item.text
                                )}
                            </TreeSelectorElement>
                        </div>
                        {hasChildren && isExpanded && (
                            <div
                                className={styles['bloko-tree-selector__items']}
                                data-qa={`${dataQa.items} ${dataQa.items}-${item.id}`}
                            >
                                <ItemsList {...props} items={collection.getChildren(item.id)} parentId={item.id} />
                            </div>
                        )}
                    </div>
                );
            })}
        </>
    );
};

export default memo(ItemsList);
