import $ from 'jquery';

import Components from 'bloko/common/core/Components';
import blokoEvent from 'bloko/common/event';
import Ready from 'bloko/common/ready';

const BLOCK_NAME = 'toggle';

/**
 * Активирует или деактивирует toggle
 * @event click.toggle
 */

/**
 * Событие для сообщения, необходимо ли деактивировать toggle при клике вне его контента
 * @event Bloko-Toggle-ConfigureClose.toggle
 * @property {Boolean} flag При наличии флага `closeByClick` при передаче `true` повесит на документ `onclick`
 *                          для закрытия toggle'а, а в случае `false` удалит.
 *                          После единичного срабатывания поведение возвращается на изначальное
 */

/**
 * Ручное управление активацией\деактивацией toggle'a.
 * Данное событие всегда слушает элемент, на котором компонент был проинициилизирован
 * @event Bloko-Toggle-Click.toggle
 * @property {String} action При передаче `collapse` сворачивает, при `expand` разворачивает,
 *                           без параметров меняет состояние toggle'а
 */

/**
 * Событие срабатывания toggle. Информация о текущем состоянии toggle находится в event.detail.isClosed
 * @event Bloko-Toggle-Switch
 */

/**
 * Событие срабатывания toggle.
 * Подписываться на это событие необходимо, если поведение компонента зависит от размера контента.
 * Данное событие сообщает, что размер контента мог измениться
 * @event possible-resize
 */

/**
 * @exports bloko/blocks/toggle/toggle
 *
 * Отвечает за смену состояния на определенном элементе при помощи навешивания ранее заданных классов
 * в параметрах collapseClass и expandClass
 *
 * @listens click.toggle
 * @listens Bloko-Toggle-ConfigureClose.toggle
 * @listens Bloko-Toggle-Click.toggle
 * @fires Bloko-Toggle-Switch
 * @fires possible-resize
 *
 * @param {Element} element
 * @param {Object} params                       Параметры тоггла
 * @param {String} [params.collapseClass]       CSS-класс, который будет навешен на элемент в состоянии collapse
 * @param {String} [params.expandClass='bloko-toggle_expand']
 *                                              CSS-класс, который будет навешен на элемент в состоянии expand
 * @param {String} [params.name='']             Имя заданного toggle'а. Является значением для элементов-
 *                                              активаторов с дата аттрибутом `data-toggle="{name}"`.
 *                                              Если имя не задано, используется `data-toggle=""`
 * @param {Boolean} [params.closeByClick=false] Флаг закрытия toggle при клике вне его контента
 * @param {Boolean} [params.active=false]       Определяет, должен ли toggle быть по умолчанию в состоянии
 *                                              expand или нет
 * @param {String} [params.showOnHash=null]     Уникальный идентификатор toggle'а на странице.
 *                                              Используется для ссылок вида http://url#hash, для того, чтобы
 *                                              по переходу по ней был открыт нужный контент
 * @constructor
 */
function Toggle(element, params) {
    const $element = $(element);

    function closeByClick(event) {
        const $dataContainer = $(`[data-toggle-container="${params.name}"]`);
        const selector = $dataContainer.length > 0 ? $dataContainer : $element;
        if ($(event.target).closest(selector).length > 0) {
            return;
        }
        toggle(false);
    }

    function isClosed() {
        let closed;
        if (params.expandClass) {
            closed = !$element.hasClass(params.expandClass);
        } else if (params.collapseClass) {
            closed = $element.hasClass(params.collapseClass);
        }
        return closed;
    }

    function closeToggleByDocumentClick(isOn) {
        if (isOn) {
            $(document).on(`click.${BLOCK_NAME}`, closeByClick);
        } else {
            $(document).off(`click.${BLOCK_NAME}`, closeByClick);
        }
    }

    function fireEvents() {
        element.dispatchEvent(blokoEvent('Bloko-Toggle-Switch', { isClosed: isClosed() }));
        $element.trigger('possible-resize');
    }

    function toggle(isExpand) {
        if (params.collapseClass) {
            $element.toggleClass(params.collapseClass, !isExpand);
        }
        if (params.expandClass) {
            $element.toggleClass(params.expandClass, isExpand);
        }
        if (params.closeByClick) {
            closeToggleByDocumentClick(isExpand);
        }
        fireEvents();
    }

    function switchBlockHandler(event, action) {
        event.preventDefault();
        toggle((action || (isClosed() ? 'expand' : 'collapse')) === 'expand');
    }

    function showToggleOnHash() {
        if (window.location.hash.substr(1) === params.showOnHash) {
            toggle(true);
        }
    }

    $element.on(`Bloko-Toggle-Click.${BLOCK_NAME}`, switchBlockHandler);

    $element.on(`click.${BLOCK_NAME}`, `[data-toggle="${params.name}"]`, switchBlockHandler);

    if (params.closeByClick) {
        $element.on(`Bloko-Toggle-ConfigureClose.${BLOCK_NAME}`, (event, flag) => {
            closeToggleByDocumentClick(flag);
        });

        if (!isClosed()) {
            closeToggleByDocumentClick(true);
        }
    }

    if (params.active) {
        toggle(true);
    }

    if (params.showOnHash) {
        showToggleOnHash();
        $(window).on('hashchange', showToggleOnHash);
    }

    Ready.resolve(element, BLOCK_NAME, this);

    return {
        /**
         * Показать toggle
         */
        expand() {
            toggle(true);
        },
        /**
         * Скрыть toggle
         */
        collapse() {
            toggle(false);
        },
        /**
         * Переключить toggle в другое состояние
         */
        toggle() {
            toggle(!isClosed());
        },
        /**
         * Получить состояние toggle'а - в состоянии ли он collapse на данный момент
         */
        isClosed() {
            return isClosed();
        },
    };
}

const ToggleComponent = Components.build({
    defaults: {
        name: '',
        expandClass: 'bloko-toggle_expand',
    },
    create: Toggle,
});

export default {
    create(element, params) {
        return Components.make(ToggleComponent, $(element).get(0), params);
    },
};
