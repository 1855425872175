import Components from 'bloko/common/core/Components';
import ready from 'bloko/common/ready';

import SliderRange from 'bloko/blocks/slider/sliderRange';
import SliderSingle from 'bloko/blocks/slider/sliderSingle';

export default Components.build({
    defaults: {
        from: 0,
        to: 100,
        stepSize: 1,
        value: null,
        type: 'slider',
        style: 'primary',
        disabled: false,
        onChange: null,
        onInput: null,
        showMarks: false,
        labels: null,
        fromInput: null,
        toInput: null,
        valueInput: null,
    },
    /**
     * Диапазон значений.
     * Например: `{from: 0, to: 100}`
     * @typedef {Object} SliderRange
     * @property {Number} from Начальное значение.
     * @property {Number} to Конечное значение.
     */
    /**
     * @param {Element} element DOM элемент
     * @param {Object} params Параметры
     * @param {Number} [params.from=0]           Начало диапазона допустимых значений
     * @param {Number} [params.to=100]           Конец диапазона допустимых значений
     * @param {SliderRange} [params.available={from: params.from, to: params.to}]
     *                                           Диапазон доступных для выбора значений
     * @param {Number} [params.stepSize=1]       Размер минимального шага изменения
     * @param {String|Array} [params.value=[from, to] | to] Начальное выбранное значение/диапазон значений
     * @param {Boolean} [params.style='primary'] Флаг, определяющий тип слайдера – основной/вторичный.
     *                                            Допустимые значения – `'primary'` и `'secondary'`
     * @param {Boolean} [params.type='slider']   Флаг, определяющий тип слайдера – обычный/диапазон.
     *                                            Допустимые значения – `'slider'` и `'range'`
     * @param {Boolean} [params.disabled=false]  Состояние слайдера – активен/неактивен
     * @param {function} [params.onChange=null]  Функция обработчик события change
     * @param {function} [params.onInput=null]   Функция обработчик события input
     * @param {Boolean} [params.showMarks=false] Флаг отображения делений
     * @param {Array} [params.labels=[]]         Массив текстовых меток, где каждая метка это массив
     *                                            из двух элементов, первый элемент – значение которому
     *                                            соответствует данная метка, второй элемент – текст метки
     * @constructor
     */
    create(element, params) {
        const slider =
            params && params.type === 'range' ? new SliderRange(element, params) : new SliderSingle(element, params);

        const publicInterface = {
            set(values) {
                slider.set(values);
            },
            get() {
                return slider.get();
            },
            toggleDisabled(disabled) {
                slider.toggleDisabled(disabled);
            },
            setAvailable(available) {
                slider.setAvailable(available);
            },
            onChange(listener) {
                slider.onChange(listener);
            },
            onInput(listener) {
                slider.onInput(listener);
            },
        };

        window.setTimeout(() => {
            ready.resolve(element, 'slider', publicInterface);
        }, 0);

        return publicInterface;
    },
});
