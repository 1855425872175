import Backbone from 'backbone';
/**
 * Plain-collection, в которой у моделей могут быть задан опциональный массив потомков, или id предка
 * Таким образом реализуется структура типа "дерево", при этом коллекция не требует дополнительных усложнений
 * для поддержки поиска моделей по id,
 * как в случае с коллекциями которые поддерживают по-настоящему вложенные модели
 */
export default Backbone.Collection.extend({
    /**
     * Рекурсивный конвертер для переданного иерархического JSON в плоскую коллекцию
     * @private
     * @param  {Array} items  массив моделей для добавления
     * @param  {Object} parent ссылка на модель родителя
     * @param  {Array} data   Массив моделей
     */
    converter(items, parent, data) {
        if (this.sortFucntion) {
            items.sort(this.sortFucntion.bind(this));
        }

        items.forEach((item) => {
            const itemData = this.parseItem(item, parent);

            this.setupParent(itemData);
            if (item.items && item.items.length > 0) {
                this.converter(item.items, itemData, data);
            }
            data.push(itemData);
            if (parent.children) {
                parent.children.push(itemData.id);
            } else {
                parent.children = [itemData.id];
            }
        });
    },

    parseItem(item, parent) {
        return {
            id: item.id,
            text: item.text,
            children: [],
            parent: parent.id,
            removable: item.removable,
            editable: item.editable,
            selectable: item.selectable,
            disabled: item.disabled,
            hiddenValue: item.hiddenValue,
            additional: item.additional,
        };
    },

    parse(response) {
        const data = [];

        response.forEach((item) => {
            item.id = item.id.toString();
            if (item.parent) {
                item.parent = item.parent.toString();
            }
            if (item.items) {
                this.converter(item.items, item, data);
            }

            this.setupParent(item);

            item.items = null;
            data.push(item);
        });
        return data;
    },

    /**
     * Вызывается для установления связей в коллекции между родителями и потомками
     * Если для переданного item указан parent, то ему в свойтсво children будет добавлен
     * id item'а
     * @private
     * @param  {Object} item   модель которую нужно связать с родителем
     * @return {Boolean}       признак того был ли найден предок или нет для текущей модели
     */
    setupParent(item) {
        const parentModel = this.get(item.parent);
        if (parentModel) {
            const children = parentModel.get('children');
            if (children.indexOf(item.id) === -1) {
                parentModel.set('children', children.concat([item.id]), { silent: true });
            }
            return true;
        }
        return false;
    },

    toJSON() {
        const json = [];

        this.models.forEach((model) => {
            if (!model.has('parent')) {
                json.push(model.toJSON());
            }
        });
        return json;
    },
});
