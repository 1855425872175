import Debug from 'bloko/common/core/Debug';

export default {
    ID_IS_NULL: 'BlokoTreeSelector: ID not passed',
    ITEM_NOT_FOUND: 'BlokoTreeSelector: the selected item can not be found',
    WRONG_TYPE_RADIO: 'BlokoTreeSelector: add to selected items can not be, because type="radio"',
    METHOD_ARGUMENT_NOT_FUNCTION: 'BlokoTreeSelector: method got no argument function',
    ELEMENT_NOT_SELECTABLE: 'BlokoTreeSelector: element not selectable',

    error(errorCode, message) {
        Debug.log('log error', new Error(errorCode.replace('{{#message}}', message)));
    },
};
