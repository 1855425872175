import TreeCollection from 'bloko/common/tree/treeCollection';
import type { AdditionalDefault, IdCollectionPredicate } from 'bloko/common/tree/types';

/**
 * Проверяет был ли выбран родитель переданного элемента или находится в списке исключенных
 * @param {TreeCollection} collection
 * @param {Array.<String>} selectedIds
 * @param {string} childId
 * @param {Array<string>} excluded
 * @returns {Object}
 */
const isParentSelectedOrExcluded = <A extends AdditionalDefault = never>(
    collection: TreeCollection<A>,
    selectedIds: string[],
    childId: string,
    excluded: string[] = []
): { isParentSelected: boolean; isParentExcluded: boolean } => {
    const parentId = collection.getParentId(childId);

    return {
        isParentSelected: parentId ? selectedIds.includes(parentId) : collection.getTopLevel().includes(childId),
        isParentExcluded: Boolean(parentId && excluded.includes(parentId)),
    };
};

/**
 * Создаёт исключатель элементов в наборе, учитывая набор выбранных элементов (вызывать с готовыми выбранными элементами):
 * — если элемент был выбран, то мы либо удаляем его из "исключенных", либо ничего не делаем;
 * — во всех случаях, когда меняется статус родителя, все его дети исключаются из "исключенных";
 * — если элемент "отключили", то переводим его в "исключенные", но только в том случае, если его родитель выбран
 * @returns {exclude}
 * @constructor
 */
function createTreeCollectionExcluder<A extends AdditionalDefault>(
    _collection: TreeCollection<A>,
    _checkSelectable: IdCollectionPredicate
): (items: string[], excluded: Set<string>, ids: string[]) => void {
    function exclude(items: string[], excluded: Set<string>, ids: string[]) {
        if (!items.length) {
            excluded.clear();
            return;
        }

        ids.forEach((id) => {
            if (!_checkSelectable(id, _collection)) {
                return;
            }

            if (excluded.has(id)) {
                excluded.delete(id);

                _collection.walkChildren(id, ({ id: childId }) =>
                    _excludeOperationWithChild({ excluded, ids, items, childId, isAdding: false })
                );
            } else {
                const { isParentExcluded, isParentSelected } = isParentSelectedOrExcluded(_collection, items, id, [
                    ...excluded,
                ]);

                const isAdding = isParentSelected || isParentExcluded;

                if (isAdding) excluded.add(id);

                _collection.walkChildren(id, ({ id: childId }) =>
                    _excludeOperationWithChild({ excluded, ids, items, childId, isAdding })
                );
            }
        });
    }

    function _excludeOperationWithChild({
        excluded,
        ids,
        items,
        childId,
        isAdding,
    }: {
        excluded: Set<string>;
        ids: string[];
        items: string[];
        childId: string;
        isAdding: boolean;
    }) {
        return (
            !ids.includes(childId) &&
            (isAdding ? !excluded.has(childId) : excluded.has(childId)) &&
            exclude(items, excluded, [childId])
        );
    }

    return exclude;
}

export default createTreeCollectionExcluder;
