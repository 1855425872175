import axios from 'axios';

import { unescape } from 'bloko/common/helpers/html';

import { DataProvider, RemoteDataProviderResponse } from 'bloko/blocks/suggest/types';

const createRemoteDataProvider =
    (remote: string, wildcard: string): DataProvider =>
    (query: string) => {
        // remote может содержать html-сущности
        const urlTemplate = unescape(remote);
        const url = urlTemplate.replace(wildcard, encodeURIComponent(query));
        return axios.get<RemoteDataProviderResponse>(url).then((response) => response.data);
    };

export default createRemoteDataProvider;
