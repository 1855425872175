import React, { ChangeEventHandler, ComponentPropsWithoutRef, useRef, useLayoutEffect } from 'react';
import classnames from 'classnames';

import autogrowCommon from 'bloko/blocks/autogrow/common';
import useInputState from 'bloko/common/hooks/useInputState';

import styles from 'bloko/blocks/textarea/textarea.less';

export interface TextareaProps extends ComponentPropsWithoutRef<'textarea'> {
    /** Значение контрола по умолчанию для uncontrolled поля */
    defaultValue?: string;
    /** Флаг невалидности контрола */
    invalid?: boolean;
    /** Обработчик onChange, в качестве аргумента передает объект event */
    onChange?: ChangeEventHandler<HTMLTextAreaElement>;
    /** Запрет ресайза */
    noresize?: boolean;
    /** Автоматическое увеличение высоты */
    autogrow?: boolean;
    /** Минимальная высота для autogrow. Если не передана используется min-height из css */
    minHeight?: number;
    /** Максимальная выста для autogrow. Если не передана - без ограничения по высоте */
    maxHeight?: number;
    /** Указывает на строку с компонентом в исходном коде в режиме разработки. Генерируется babel-plugin-react-source */
    source?: string;
    /** Указывает высоту по количеству строк видимых без скрола */
    rows?: number;
    /** Указывает ширину по количеству символов в одной строке */
    cols?: number;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>((props, forwardedRef) => {
    const {
        rows,
        cols,
        invalid = false,
        noresize = false,
        source,
        autogrow,
        minHeight,
        maxHeight,
        defaultValue,
        value: controlledValue,
        onChange: onChangeProp,
        ...textareaProps
    } = props;

    const innerRef = useRef<HTMLTextAreaElement>(null) as React.MutableRefObject<HTMLTextAreaElement | null>;
    const { value, onChange } = useInputState({ controlledValue, defaultValue, onChangeProp });
    useLayoutEffect(() => {
        if (!autogrow || !innerRef.current) {
            return;
        }
        autogrowCommon(innerRef.current, minHeight, maxHeight);
    }, [autogrow, minHeight, maxHeight, value, innerRef]);

    return (
        <textarea
            {...textareaProps}
            value={value}
            onChange={onChange}
            ref={(node) => {
                innerRef.current = node;
                if (typeof forwardedRef === 'function') {
                    forwardedRef(node);
                } else if (forwardedRef) {
                    forwardedRef.current = node;
                }
            }}
            className={classnames(styles['bloko-textarea'], {
                [styles['bloko-textarea_sized-rows']]: rows,
                [styles['bloko-textarea_sized-cols']]: cols,
                [styles['bloko-textarea_invalid']]: invalid,
                [styles['bloko-textarea_noresize']]: noresize || autogrow,
            })}
            rows={autogrow ? undefined : rows}
            cols={cols}
            source={source}
        />
    );
});

export default Textarea;
