import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const TelegramScaleSmallAppearanceColor = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M0.204448 7.90049C1.58943 7.10475 3.13552 6.44037 4.58007 5.77324C7.06505 4.67969 9.56019 3.60678 12.0804 2.60608C12.5711 2.43552 13.4518 2.26908 13.5385 3.02699C13.4911 4.0999 13.2968 5.16594 13.1628 6.23197C12.8243 8.57725 12.4324 10.9136 12.0506 13.2513C11.9193 14.0298 10.9838 14.4329 10.3854 13.9342C8.94757 12.9212 7.49828 11.9184 6.07878 10.8819C5.61306 10.3888 6.04493 9.68111 6.45989 9.32966C7.6445 8.11232 8.90087 7.07792 10.0232 5.79799C10.3265 5.03526 9.43157 5.67832 9.13644 5.87502C7.51453 7.0401 5.93256 8.2767 4.22334 9.30009C3.35011 9.80216 2.33201 9.37368 1.45878 9.09376C0.676263 8.75607 -0.470443 8.41563 0.204448 7.90049Z"
                fill="#27A5E4"
            />
        </svg>
    ),
    'Telegram'
);
export default TelegramScaleSmallAppearanceColor;
