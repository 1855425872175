import {
    ModelData,
    TreeModel,
    ModelPredicate,
    WalkCallback,
    AdditionalDefault,
    CoreField,
} from 'bloko/common/tree/types';

/**
 * Создание модели для коллекции из набора атрибутов.
 * Отсекает лишние поля.
 */
export const createModel = <A extends AdditionalDefault>(attrs: TreeModel<A>): TreeModel<A> => {
    const id = attrs[CoreField.Id];
    if (typeof id !== 'string') {
        throw new Error(`Invalid ID: "${JSON.stringify(id)}"`);
    }
    const model: TreeModel<A> = {
        [CoreField.Id]: attrs[CoreField.Id],
        [CoreField.Text]: attrs[CoreField.Text],
        ...(CoreField.Additional in attrs ? { [CoreField.Additional]: attrs[CoreField.Additional] } : {}),
    };
    return model;
};

/**
 * Многоцелевая иерархическая коллекция,
 * свободная от специфического поведения компонентов
 */
class TreeCollection<A extends AdditionalDefault = never> {
    protected models: TreeModel<A>[] = [];
    protected topLevelModels: TreeModel<A>[] = [];

    protected modelsById: Record<string, TreeModel<A>> = {};
    protected childrenById: Record<string, string[]> = {};

    protected parentsById: Record<string, string[]> = {};

    /**
     * Создаёт из объекта модель и добавляет в коллекцию.
     * Не добавляет модель в коллекцию, если модель с таким id уже существует
     */
    addModel(attrs: TreeModel<A>, parentId?: string): void {
        const model = createModel(attrs);
        const id = model[CoreField.Id];
        if (typeof this.getModel(id) === 'undefined') {
            this.models.push(model);
            this.modelsById[id] = model;
        }
        if (parentId) {
            this.parentsById[id] = this.parentsById[id] || [];
            this.parentsById[id].push(parentId);
            this.childrenById[parentId] = this.childrenById[parentId] || [];
            this.childrenById[parentId].push(id);
        } else {
            this.topLevelModels.push(model);
        }
    }

    toList(): TreeModel<A>[] {
        return this.models.slice();
    }

    getTopLevel(): TreeModel<A>[] {
        return this.topLevelModels.slice();
    }

    /** Возвращает модель по ID или `undefined` */
    getModel(id: string): TreeModel<A> | undefined {
        return this.modelsById.hasOwnProperty(id) ? this.modelsById[id] : undefined;
    }

    /** Возвращает существующие в коллекции модели по IDs  */
    getExistModels(ids: string[]): TreeModel<A>[] {
        return ids.reduce((result: TreeModel<A>[], id) => {
            const model = this.getModel(id);
            if (model) {
                result.push(model);
            }
            return result;
        }, []);
    }

    /** Возвращает ID родителя по ID модели или `undefined` */
    getParentId(id: string): string | undefined {
        return this.parentsById.hasOwnProperty(id) ? this.parentsById[id][0] : undefined;
    }

    /**
     * Возвращает модель первого родителя по ID или `undefined`.
     */
    getParent(id: string): TreeModel<A> | undefined {
        const parentId = this.getParentId(id);
        return parentId ? this.getModel(parentId) : undefined;
    }

    /** Возвращает массив IDs ближайших родителей по ID модели */
    getParentIdsDuplicates(id: string): string[] {
        return this.parentsById.hasOwnProperty(id) ? this.parentsById[id] : [];
    }

    /** Возвращает массив ID родителей от ближних к дальним */
    getParentIds(id: string, resultIds: string[] = []): string[] {
        const parentId = this.getParentIdsDuplicates(id);
        if (parentId.length) {
            resultIds.push(...parentId);
            parentId.forEach((id) => this.getParentIds(id, resultIds));
        }
        return resultIds;
    }

    /** Возвращает массив моделей родителей от ближнего к дальнему */
    getParents(id: string): TreeModel<A>[] {
        const parendIds = this.getParentIds(id);
        return this.getExistModels(parendIds);
    }

    /** Возвращает наличие дочерних моделей по ID родителя */
    hasChildren(id: string): boolean {
        return this.childrenById.hasOwnProperty(id);
    }

    /** Возвращает список ID дочерних по ID родителя */
    getChildrenIds(id: string): string[] {
        return this.hasChildren(id) ? this.childrenById[id].slice() : [];
    }

    /** Возвращает список дочерних моделей по ID родителя */
    getChildren(id: string): TreeModel<A>[] {
        const childrenIds = this.getChildrenIds(id);
        return this.getExistModels(childrenIds);
    }

    /**
     * Рекурсивно проходит по списку моделей, применяет к каждой модели переданную функцию.
     *  items Список моделей для обработки.
     *  callback Вызываемая функция.
     *  [parents] Массив моделей родителей от ближнего к дальнему.
     *     Используется в случаях, когда на вход поступают модели из середины дерева, имеющие своих родителей.
     */
    _walk(items: TreeModel<A>[], callback: WalkCallback<A>, parents?: TreeModel<A>[]): void {
        const currentParents = parents ? parents.slice() : [];
        items.forEach((item) => {
            callback(item, currentParents);
            const children = this.getChildren(item.id);
            if (children && children.length) {
                this._walk(children, callback, [item].concat(currentParents));
            }
        });
    }

    /** Рекурсивно проходит по дереву, применяет к каждой модели переданную функцию */
    walk(callback: WalkCallback<A>): void {
        this._walk(this.getTopLevel(), callback);
    }

    /**
     * Рекурсивно проходит по дочерним элементам указанной модели, применяет к каждому переданную функцию.
     * id ID модели, с которой начинать обход.
     * callback Вызываемая функция.
     */
    walkChildren(id: string, callback: WalkCallback<A>): void {
        const children = this.getChildren(id);
        if (children.length) {
            const parents = this.getExistModels([id]).concat(this.getParents(id));
            this._walk(children, callback, parents);
        }
    }

    /**
     * Проходит по родителям модели до самого верха, применяет к каждому указанную функцию.
     * id ID текущей модели.
     * callback Вызываемая функция.
     */
    walkParents(id: string, callback: WalkCallback<A>): void {
        const parents = this.getParents(id);
        while (parents.length) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const firstParent = parents.shift()!;
            callback(firstParent, parents.slice());
        }
    }

    /**
     * Возвращает коллекцию в виде дерева.
     * filter Функция-фильтр.
     *     Если указана, оставляет в дереве только те элементы, для которых вернулось `true`.
     */
    toTree(filter?: ModelPredicate): ModelData<A>[] {
        const filteredTree: ModelData<A>[] = [];
        const modelsById: Record<string, ModelData<A>> = {};
        this.walk((model, parents) => {
            if (!filter || filter(model)) {
                const treeItem = { ...model };
                modelsById[model.id] = treeItem;
                if (parents.length === 0) {
                    filteredTree.push(treeItem);
                } else {
                    const parent = modelsById[parents[0].id];
                    if (!parent.items) {
                        parent.items = [];
                    }
                    parent.items.push(treeItem);
                }
            }
        });
        return filteredTree;
    }
}

export default TreeCollection;
