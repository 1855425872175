import { BoundingClientRect } from 'bloko/common/types';

interface Intersection {
    start: number;
    end: number;
}

export function getIntersectionX(
    a: BoundingClientRect,
    b: BoundingClientRect,
    minIntersectionLength = 0
): Intersection | null {
    const intersection = {
        start: 0,
        end: 0,
    };

    if (a.left > b.right - minIntersectionLength || b.left > a.right - minIntersectionLength) {
        return null;
    }

    if (a.left > b.left) {
        intersection.start = a.left;
    } else {
        intersection.start = b.left;
    }
    if (a.right > b.right) {
        intersection.end = b.right;
    } else {
        intersection.end = a.right;
    }

    return intersection;
}

export function getIntersectionY(
    a: BoundingClientRect,
    b: BoundingClientRect,
    minIntersectionLength = 0
): Intersection | null {
    const intersection = {
        start: 0,
        end: 0,
    };

    if (a.top > b.bottom - minIntersectionLength || b.top > a.bottom - minIntersectionLength) {
        return null;
    }

    if (a.top > b.top) {
        intersection.start = a.top;
    } else {
        intersection.start = b.top;
    }
    if (a.bottom > b.bottom) {
        intersection.end = b.bottom;
    } else {
        intersection.end = a.bottom;
    }

    return intersection;
}
