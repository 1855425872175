import Metrics from 'bloko/common/metrics';

export enum Bound {
    Top = 'top',
    Bottom = 'bottom',
    Left = 'left',
    Right = 'right',
}

type ElementSpyParams = {
    onShow?: () => void;
    onHide?: () => void;
    rootElement?: Element | Document;
    rootMargin?: string;
    trackingBounds?: Bound[];
    elementLockTime?: number;
    entryPercent?: number;
};

export interface ElementSpyInstance {
    stopSpying: () => void;
    startSpying: () => void;
}

type Rect = Pick<DOMRectReadOnly, 'width' | 'height' | 'top' | 'left' | 'right' | 'bottom'>;

const getWindowSize = (entry?: IntersectionObserverEntry): { windowWidth: number; windowHeight: number } => {
    return {
        windowWidth: entry?.rootBounds?.width || window.innerWidth || document.documentElement.clientWidth,
        windowHeight: entry?.rootBounds?.height || window.innerHeight || document.documentElement.clientHeight,
    };
};

const percentTracker = (rect: Rect, trackingElementPercent: number, windowHeight: number) =>
    rect.top + rect.height / (1 / trackingElementPercent) <= windowHeight && rect.top + rect.height > 0;

const contains = ({
    entry,
    rect,
    trackingBounds = [Bound.Bottom],
    entryPercent = 0,
}: {
    entry?: IntersectionObserverEntry;
    rect: Rect;
    trackingBounds?: Bound[];
    entryPercent?: number;
}): boolean => {
    const { windowWidth, windowHeight } = getWindowSize(entry);

    const trackers = {
        [Bound.Top]: () => rect.top <= windowHeight && rect.top >= 0,
        [Bound.Bottom]: () => rect.top + rect.height <= windowHeight && rect.top + rect.height > 0,
        [Bound.Left]: () => rect.left <= windowWidth && rect.left > 0,
        [Bound.Right]: () => rect.left + rect.width <= windowWidth && rect.left + rect.width > 0,
    };

    let overallResult = true;

    if (entryPercent) return percentTracker(rect, entryPercent, windowHeight);

    trackingBounds.some((bound: Bound) => {
        const boundResult = trackers[bound]();

        overallResult = overallResult && boundResult;

        return !boundResult;
    });

    return overallResult;
};

const onElementStateInViewportChange = (element: HTMLElement, options: ElementSpyParams) => {
    let viewportTimeoutId: ReturnType<typeof setTimeout> | null = null;

    return (currentlyInViewport: boolean, entry?: IntersectionObserverEntry) => {
        if (currentlyInViewport) {
            if (!options.onShow) {
                return viewportTimeoutId;
            }

            if (options.elementLockTime) {
                viewportTimeoutId = setTimeout(() => {
                    viewportTimeoutId = null;

                    const delayedInViewport = contains({
                        entry,
                        rect: Metrics.getBoundingClientRect(element),
                        ...options,
                    });

                    if (delayedInViewport) {
                        options.onShow?.();
                    }
                }, options.elementLockTime);
            } else {
                options.onShow();
            }
        } else if (viewportTimeoutId) {
            clearTimeout(viewportTimeoutId);
            viewportTimeoutId = null;
        } else {
            options.onHide?.();
        }

        return viewportTimeoutId;
    };
};

const spy = (element: HTMLElement, params: ElementSpyParams): ElementSpyInstance => {
    const onStateViewportChange = onElementStateInViewportChange(element, params);

    let lastInViewport: boolean;

    let viewportTimeoutId: ReturnType<typeof setTimeout> | null;

    const trackElement: IntersectionObserverCallback = (entries) => {
        const entry = entries[0];

        const currentlyInViewport = contains({
            entry,
            rect: entry.boundingClientRect,
            ...params,
        });

        if (currentlyInViewport === lastInViewport) {
            return;
        }

        viewportTimeoutId = onStateViewportChange(currentlyInViewport, entry);

        lastInViewport = currentlyInViewport;
    };

    let observer: IntersectionObserver;

    const threshold = [0, 1];

    if (params.entryPercent) threshold.push(params.entryPercent);

    /**
     * В старых версиях браузеров выбрасывается исключение, если в качестве rootElement передавать document.
     * https://bugzilla.mozilla.org/show_bug.cgi?id=1617154
     * Это влечет за собой краш страницы.
     *
     * Если rootElement не указывать, то берется область видимости (viewport)
     */
    try {
        observer = new IntersectionObserver(trackElement, {
            threshold,
            root: params.rootElement,
            rootMargin: params.rootMargin,
        });
    } catch (error) {
        observer = new IntersectionObserver(trackElement, { threshold });
    }

    const startSpying = () => {
        lastInViewport = false;
        observer.observe(element);
    };

    const stopSpying = () => {
        viewportTimeoutId && clearTimeout(viewportTimeoutId);
        observer.disconnect();
    };

    startSpying();

    return {
        stopSpying,
        startSpying,
    };
};

const spyFallback = (element: HTMLElement, params: ElementSpyParams): ElementSpyInstance => {
    const defaults = { trackingInterval: 500 };

    const options = { ...defaults, ...params };
    const onStateViewportChange = onElementStateInViewportChange(element, options);

    let lastInViewport: boolean;

    let trackerInterval: ReturnType<typeof setTimeout>;
    let viewportTimeoutId: ReturnType<typeof setTimeout> | null;

    const trackElement = () => {
        const currentlyInViewport = contains({ rect: Metrics.getBoundingClientRect(element), ...options });

        if (currentlyInViewport === lastInViewport) {
            return;
        }

        viewportTimeoutId = onStateViewportChange(currentlyInViewport);

        lastInViewport = currentlyInViewport;
    };

    const startSpying = () => {
        lastInViewport = false;
        trackerInterval = setInterval(trackElement, options.trackingInterval);
    };

    const stopSpying = () => {
        viewportTimeoutId && clearTimeout(viewportTimeoutId);
        clearInterval(trackerInterval);
    };

    startSpying();

    return {
        stopSpying,
        startSpying,
    };
};

/**
 * Позволяет отследить появление/исчезновение элемента во вьюпорте
 *
 * @param {Element} element                         элемент для инициалиазации
 * @param {Object} params                           параметры компонента
 * @param {Function} [params.onShow]                колбэк, вызываемый, когда элемент попал во вьюпорт
 * @param {Number} [params.entryPercent]            процент вхождения элемента во вьюпорт (1 - 100%; например: 0.5 - 50% элемента)
 * @param {Number} [params.elementLockTime]         минимальное время нахождения элемента во вьюпорте для срабатывания onShow (в миллисекундах)
 * @param {Function} [params.onHide]                колбэк, вызываемый, когда элемент вышел из вьюпорта
 * @param {Array} [params.trackingBounds]           список сторон элемента, которые будут проверяться (top, left, right,
 *                                                  bottom). Элемент считается видимым, если все перечисленные стороны в
 *                                                  пределах вьюпорта
 * @param {Element | Document} [params.rootElement] IntersectionObserver root
 * @param {string} [params.rootMargin]              IntersectionObserver rootMargin
 */
const elementSpy = (element: HTMLElement, params: ElementSpyParams): ElementSpyInstance => {
    const intersectionObserverSupported = 'IntersectionObserver' in window;

    if (intersectionObserverSupported) {
        return spy(element, params);
    }

    return spyFallback(element, params);
};

export default elementSpy;
