import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const ItAccreditationScaleSmall = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM4.61526 4.48267C4.33912 4.48267 4.11526 4.70652 4.11526 4.98267C4.11526 5.25881 4.33912 5.48267 4.61526 5.48267H5.01865V10.5173L4.61533 10.5173C4.33918 10.5173 4.1153 10.7411 4.11526 11.0172C4.11523 11.2934 4.33906 11.5173 4.6152 11.5173L5.50835 11.5174L5.51865 11.5175L5.52883 11.5174L6.35968 11.5175C6.63582 11.5175 6.8597 11.2937 6.85974 11.0176C6.85977 10.7414 6.63594 10.5175 6.3598 10.5175L6.01865 10.5175V5.48267H6.35974C6.63588 5.48267 6.85974 5.25881 6.85974 4.98267C6.85974 4.70652 6.63588 4.48267 6.35974 4.48267H4.61526ZM9.49883 11.0173V5.48288H7.77102C7.49488 5.48288 7.27102 5.25902 7.27102 4.98288C7.27102 4.70674 7.49488 4.48288 7.77102 4.48288H9.98409L9.99883 4.48267L10.0136 4.48288H12.2266C12.5028 4.48288 12.7266 4.70674 12.7266 4.98288C12.7266 5.25902 12.5028 5.48288 12.2266 5.48288H10.4988V11.0173C10.4988 11.2934 10.275 11.5173 9.99883 11.5173C9.72268 11.5173 9.49883 11.2934 9.49883 11.0173Z"
                fill="var(--bloko-icon-color, var(--bloko-icon-color-default))"
            />
        </svg>
    ),
    'It'
);
export default ItAccreditationScaleSmall;
