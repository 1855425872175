import { useEffect, useState, useRef, ReactElement, useCallback, ReactNode, isValidElement } from 'react';

import ImmutableSelectionStrategy from 'bloko/common/tree/immutableSelectionStrategy';
import { fromTree } from 'bloko/common/tree/treeCollectionHelper';
import { IdCollectionPredicate, ModelData, TreeFilter, AdditionalDefault } from 'bloko/common/tree/types';

import TreeSelectorDummy, { TreeSelectorDummyProps } from 'bloko/blocks/treeSelector/Dummy';
import { LabelComponentType, IconComponentType } from 'bloko/blocks/treeSelector/Dummy/types';

export { TreeSelectorDummy };

export interface TreeSelectorProps<A extends AdditionalDefault> {
    /** Дерево элементов. */
    data: ModelData<A>[];
    /** Разрешает выбор только одного элемента. */
    singleChoice?: boolean;
    /** Разрешает выбор только элементов без потомков. */
    leavesOnly?: boolean;
    /** Компонент для вывода метки у элемента дерева */
    LabelComponent?: LabelComponentType<A>;
    /** Компонент для вывода иконки у элемента дерева */
    IconComponent?: IconComponentType<A>;
    /** Запрещённые ID. */
    disabled?: string[];
    /** Изначально выбранные ID. */
    initialSelected?: string[];
    /** Изначально открытые ID. */
    initialExpanded?: string[];
    /** Строка-фильтр для дерева, остаются только элементы с вхождением указанной строки. */
    contentFilterQuery?: string;
    /** Способ фильтрации дерева при поиске */
    treeFilter?: TreeFilter;
    /** Обработчик изменения состояния раскрытия элементов
     * В аргументах получает список id раскрытых элементов `{Array} expanded` */
    onExpand?: (expanded: string[]) => void;
    /** Обработчик изменения набора выбранных ID.
     * В аргументах получает `{String} id`, `{Boolean} isSelected`, `{Array} allSelected`. */
    onChange?: (id: string, isSelected: boolean, allSelected: string[]) => void;
    /** Обработчик изменения строки contentFilterQuery
     * В аргументах получает список подходящих под запрос id */
    onChangeFilterQuery?: (ids: string[], query: string) => void;
    /** Показывать ли список выбранных */
    showSelectedList?: boolean;
    /** Свойства обертки дерева */
    wrapperTreeProps?: JSX.IntrinsicElements['div'];
    /** ID моделей которые предлагаются для выбора, если в поиске ничего не найдено */
    suggestedNotFound?: string[];
    /** Функция возвращает true для элементов, которые можно выбрать. При использовании
     * вместе с параметром leavesOnly применяется только к элементам без потомков. */
    checkSelectable?: IdCollectionPredicate;
}

const defaultArray: string[] = [];

interface TreeSelectorComponent {
    <A extends AdditionalDefault>(props: TreeSelectorProps<A>): JSX.Element;
}

/**
 * Компонент для работы с древовидной структурой данных.
 * Получает дерево в виде JSON и изначально выбранные и открытые элементы,
 * далее управляет ими сам. Если нужно ручное управление `selected`,
 * см. компонент <a href="#treeselectordummy">TreeSelectorDummy</a>.
 */
const TreeSelector: TreeSelectorComponent = ({
    data,
    leavesOnly,
    singleChoice,
    initialSelected = defaultArray,
    initialExpanded = defaultArray,
    disabled = defaultArray,
    LabelComponent,
    IconComponent,
    onChange,
    onExpand,
    contentFilterQuery = '',
    treeFilter,
    onChangeFilterQuery,
    showSelectedList,
    wrapperTreeProps,
    suggestedNotFound,
    checkSelectable,
}) => {
    const [selected, setSelected] = useState(initialSelected);
    const collection = useRef(fromTree(data));
    const selectionStrategy = useRef<InstanceType<typeof ImmutableSelectionStrategy> | null>(null);

    useEffect(() => {
        selectionStrategy.current = new ImmutableSelectionStrategy(collection.current, {
            singleChoice,
            leavesOnly,
            checkSelectable,
        });
    }, [checkSelectable, singleChoice, leavesOnly]);

    const handleChange = useCallback(
        (id: string, isSelected: boolean): void => {
            if (selectionStrategy.current) {
                const updatedSelected = isSelected
                    ? selectionStrategy.current.add(selected, [id])
                    : selectionStrategy.current.remove(selected, [id]);
                setSelected(updatedSelected);
                onChange?.(id, isSelected, updatedSelected);
            }
        },
        [selected, onChange]
    );

    return (
        <TreeSelectorDummy
            collection={collection.current}
            singleChoice={singleChoice}
            leavesOnly={leavesOnly}
            selected={selected}
            disabled={disabled}
            initialExpanded={initialExpanded}
            LabelComponent={LabelComponent}
            IconComponent={IconComponent}
            onChange={handleChange}
            onExpand={onExpand}
            contentFilterQuery={contentFilterQuery}
            onChangeFilterQuery={onChangeFilterQuery}
            treeFilter={treeFilter}
            showSelectedList={showSelectedList}
            wrapperTreeProps={wrapperTreeProps}
            suggestedNotFound={suggestedNotFound}
            checkSelectable={checkSelectable}
        />
    );
};

export const isValidTreeSelectorElement = (child: ReactNode): child is ReactElement<TreeSelectorProps<never>> =>
    isValidElement(child) && child.type === TreeSelector;

export const isValidTreeSelector = (
    child: ReactNode
): child is ReactElement<TreeSelectorDummyProps<never> | TreeSelectorProps<never>> =>
    isValidElement(child) && (child.type === TreeSelectorDummy || child.type === TreeSelector);

export default TreeSelector;
