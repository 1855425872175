import { createContext } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface CustomSelectContextProps<T = any> {
    focusedValue?: T;
    selectedValue?: T;
    selectValue: (value: T) => void;
    setFocusedValue: (value?: T) => void;
    scrollTo: (offsetTop: number, offsetHeight: number) => void;
}

export default createContext<CustomSelectContextProps>({
    focusedValue: undefined,
    selectedValue: undefined,
    selectValue: () => undefined,
    setFocusedValue: () => undefined,
    scrollTo: () => undefined,
});
