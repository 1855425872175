import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const BarsScaleMedium = hoc(
    (props: IconProps) => (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M5 12H19M5 6H19M5 18H19"
                stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ),
    'Bars'
);
export default BarsScaleMedium;
