import { IconColor, EyeScaleSmallCrossedFalse, EyeScaleSmallCrossedTrue } from 'bloko/blocks/icon';
import IconReactRenderer from 'bloko/blocks/icon/IconReactRenderer';
import Components from 'bloko/common/core/Components';

/**
 * Создает компонент для управления видимостью пароля
 * @param {Element} element DOM-элемент, на котором создается объект
 * @name bloko/blocks/inputText/InputPasswordClassic
 * @constructor
 */

function InputPasswordClassic(element) {
    const input = element.querySelector('.Bloko-InputPassword-Input');
    const toggle = element.querySelector('.Bloko-InputPassword-Toggle');
    const toggleIcon = element.querySelector('.Bloko-InputPassword-Icon');

    let isPasswordVisible = false;

    const iconProps = {
        initial: IconColor.Gray50,
        highlighted: IconColor.Gray60,
    };

    const iconEye = Components.make(IconReactRenderer, toggleIcon, {
        IconComponent: isPasswordVisible ? EyeScaleSmallCrossedTrue : EyeScaleSmallCrossedFalse,
        iconProps,
    });

    changeState(isPasswordVisible, true);
    toggle.addEventListener('click', () => {
        isPasswordVisible = !isPasswordVisible;
        changeState(isPasswordVisible);
    });

    function changeState(isVisible, isFirstRender) {
        const type = isVisible ? 'text' : 'password';

        if (!isFirstRender) {
            iconEye.change({ iconProps: { ...iconProps, crossed: isVisible } });
        }

        input.setAttribute('type', type);
    }
}

export default Components.build({
    create: InputPasswordClassic,
});
