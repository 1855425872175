import transition from 'bloko/common/transition';

import { CLICKABLE_CLASS } from 'bloko/blocks/drop/constants';

export default (dropElement: HTMLElement): void => {
    transition(dropElement, () => {
        requestAnimationFrame(() => {
            dropElement.classList.add(CLICKABLE_CLASS);
        });
    });
};
