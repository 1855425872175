import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const FacebookScaleSmallAppearanceColor = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M8.8889 8.83331H10.9259L11.3333 6.33331H8.8889V5.08331C8.8889 4.24998 9.14964 3.83331 10.1111 3.83331H11.3333V1.41665C11.3333 1.41665 10.8029 1.33331 9.43808 1.33331C8.07408 1.33331 6.44445 2.63831 6.44445 4.66665V6.33331H4V8.83331H6.44445V14.6666H8.8889V8.83331Z"
                fill="#177AE6"
            />
        </svg>
    ),
    'Facebook'
);
export default FacebookScaleSmallAppearanceColor;
