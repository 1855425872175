import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const ChevronScaleSmallKindUp = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M12 10L8 6L4 10"
                stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                strokeWidth={1.33333}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ),
    'Chevron'
);
export default ChevronScaleSmallKindUp;
