import { ComponentPropsWithoutRef, FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

import styles from 'bloko/blocks/information/information.less';

interface InformationProps {
    kind?: InformationKind;
}

export enum InformationKind {
    Success = 'success',
    Warning = 'warning',
}

const Information: FC<ComponentPropsWithoutRef<'div'> & InformationProps & PropsWithChildren> = ({
    kind,
    ...props
}) => (
    <div
        data-qa="bloko-information"
        {...props}
        className={classnames(styles['bloko-information'], {
            [styles['bloko-information_success']]: kind === InformationKind.Success,
            [styles['bloko-information_warning']]: kind === InformationKind.Warning,
        })}
    />
);

export default Information;
