import TagList from 'bloko/blocks/tagList/tagList';
import Components from 'bloko/common/core/Components';
import { getIdsWithNoParentsInSameList } from 'bloko/common/tree/treeCollectionHelper';
/**
 * @param {Element} element
 * @param {CompositeSelectionComponentParams} params
 * @param {TreeCollection} params.collection Дерево элементов
 * @param {CollectionFlag} params.selected Список выбранных элементов
 * @param {Object} params.selfParams
 * @constructor
 */
function TagListProxy(element, params) {
    const collection = params.collection;
    const selected = params.selected;

    const tagListInstance = TagList.create(element, params.selfParams);
    let isLocked = false;

    tagListInstance.on('Bloko-TagList-Removed', (item) => {
        // Чтобы не вызывались лишние selected.remove, когда удаляем элемент не из самих тегов
        if (!isLocked) {
            selected.remove([item.id]);
        }
        /**
            // Оставляем этот комментарий для понимания какой есть путь для отказа от локера.
            // В CompositeSelection можно выбрать родительский узел, который вызовет удаление всех дочерних тегов
            // этого узла, и для каждого вызовется событие Removed.
            // Придется для каждого удаленного элемента обходить дерево и проверять надо ли удалять узел
            // из selected-коллекции. Это затратная операция.
            var id = item.id;
            var isContainsSomeParent = collection.getParentIds(id).some(function(id) {
                return selected.contains(id);
            });
            // У тегов особенная логика. Если у текущего элемента есть выбранный родитель,
            // этот ребенок не рисуется
            // соответсвенно, если удаляемый элемент имеет выбранного родителя, не удаляем его из selected
            if (selected.contains(id) && !isContainsSomeParent) {
                selected.remove([id]);
            }*/
    });

    function update() {
        isLocked = true;
        const dataJSON = getIdsWithNoParentsInSameList(collection, selected.get()).map((id) => {
            const item = collection.getModel(id);
            return {
                id,
                hiddenValue: id,
                text: item.text,
                additional: item.additional,
            };
        });
        tagListInstance.set(dataJSON);
        isLocked = false;
    }

    selected.on('change', update);
    update();

    return tagListInstance;
}

export default Components.build({
    defaults: {
        removable: true,
    },
    create: TagListProxy,
});
