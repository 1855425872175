import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const EyeScaleSmallCrossedTrue = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g>
                <path
                    d="M11.96 11.96C10.8204 12.8287 9.43273 13.3099 7.99999 13.3333C3.33332 13.3333 0.666656 7.99999 0.666656 7.99999C1.49592 6.45459 2.64608 5.10439 4.03999 4.03999M6.59999 2.82666C7.05888 2.71924 7.5287 2.66555 7.99999 2.66666C12.6667 2.66666 15.3333 7.99999 15.3333 7.99999C14.9286 8.75706 14.446 9.46981 13.8933 10.1267M9.41332 9.41332C9.23022 9.60982 9.00942 9.76743 8.76409 9.87674C8.51876 9.98605 8.25392 10.0448 7.98538 10.0496C7.71684 10.0543 7.4501 10.0049 7.20106 9.90432C6.95203 9.80373 6.7258 9.65401 6.53589 9.46409C6.34597 9.27418 6.19625 9.04795 6.09566 8.79892C5.99507 8.54988 5.94567 8.28314 5.95041 8.0146C5.95515 7.74606 6.01393 7.48122 6.12324 7.23589C6.23255 6.99056 6.39016 6.76976 6.58666 6.58666M0.666656 0.666656L15.3333 15.3333"
                    stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                    strokeWidth={1.33333}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    ),
    'Eye'
);
export default EyeScaleSmallCrossedTrue;
