import { ReactNode, memo, FC } from 'react';

import InputText, { InputProps, InputType } from 'bloko/blocks/inputText';
import { ModalHeader, ModalTitle } from 'bloko/blocks/modal';

import dataQa from 'bloko/blocks/treeSelectorPopup/dataQa';

import styles from 'bloko/blocks/treeSelectorPopup/treeSelectorPopup.less';

interface PopupHeaderProps {
    /** Отображать ли строку поиска */
    search: boolean;
    /** Текущее значение поисковой строки */
    contentFilterQuery: string;
    onSearch: (value: string) => void;
    /** Заголовок */
    title?: ReactNode;
    /** Подсказка под инпутом для поиска */
    hint?: ReactNode;
    /** Отображать ли крестик очистки строки поиска */
    searchWithClear?: boolean;
    /** Перевод - плейсхолдер в строке поиска*/
    trlPlaceholder?: string;
    /** Элемент между заголовком попапа и поисковым инпутом */
    contentAfterTitle?: ReactNode;
}

const PopupHeader: FC<PopupHeaderProps> = ({
    contentFilterQuery,
    searchWithClear,
    title,
    search,
    hint,
    trlPlaceholder = '',
    onSearch,
    contentAfterTitle,
}) => {
    const inputProps: InputProps = {
        type: InputType.Search,
        'data-qa': dataQa.search,
        placeholder: trlPlaceholder,
        value: contentFilterQuery,
        onChange: onSearch,
        showClearButton: searchWithClear,
    };

    if (!title && !search && !hint) {
        return null;
    }

    return (
        <ModalHeader>
            <ModalTitle>{title}</ModalTitle>
            {contentAfterTitle}
            {search && (
                <div className={styles['bloko-tree-selector-popup-search']}>
                    <InputText {...inputProps} />
                </div>
            )}
            {hint && <div className={styles['bloko-tree-selector-popup-hint']}>{hint}</div>}
        </ModalHeader>
    );
};

export default memo(PopupHeader);
