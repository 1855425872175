import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const PrinterScaleSmall = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g>
                <path
                    d="M4.00016 6.00001V1.33334H12.0002V6.00001M4.00016 12H2.66683C2.31321 12 1.97407 11.8595 1.72402 11.6095C1.47397 11.3594 1.3335 11.0203 1.3335 10.6667V7.33334C1.3335 6.97972 1.47397 6.64058 1.72402 6.39053C1.97407 6.14049 2.31321 6.00001 2.66683 6.00001H13.3335C13.6871 6.00001 14.0263 6.14049 14.2763 6.39053C14.5264 6.64058 14.6668 6.97972 14.6668 7.33334V10.6667C14.6668 11.0203 14.5264 11.3594 14.2763 11.6095C14.0263 11.8595 13.6871 12 13.3335 12H12.0002M4.00016 9.33334H12.0002V14.6667H4.00016V9.33334Z"
                    stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                    strokeWidth={1.33333}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    ),
    'Printer'
);
export default PrinterScaleSmall;
