import React from 'react';

import { NotificationKind } from 'bloko/blocks/notificationManager/constants';

export interface CommonNotificationProps {
    /** Флаг автоматического закрытия нотификации */
    autoClose?: boolean;
    /** Число миллисекунд до автоматического закрытия */
    autoCloseDelay?: number;
    /** Тип нотификации */
    kind?: NotificationKind;
    /** Контент */
    children?: React.ReactNode;
    /** data-qa для автотестов */
    dataQa?: string;
}
export interface ContextNotificationProps extends CommonNotificationProps {
    /** Обработчик закрытия нотификации */
    onClose?: (id: number) => void;
}

export interface ContextNotification {
    id: number;
    props: ContextNotificationProps;
}
export interface NotificationContextValue {
    notifications: ContextNotification[];
    addNotification: (props: ContextNotificationProps) => number;
    removeNotification: (id: number) => void;
    closeNotification: (id: number) => void;
    closeFuncRefs: Record<number, VoidFunction | null>;
    updateProps: (id: number, props: ContextNotificationProps) => void;
}
export default React.createContext<NotificationContextValue>({
    notifications: [],
    addNotification: () => 0,
    removeNotification: () => undefined,
    closeNotification: () => undefined,
    closeFuncRefs: {},
    updateProps: () => undefined,
});
