import React, { useState } from 'react';

export interface TipProviderInterface {
    setShow: (showedId: number) => void;
    showedId: number | null;
}

const Context = React.createContext<TipProviderInterface>({
    setShow: () => {
        throw new Error('Add TipProvider in root App');
    },
    showedId: null,
});

/**
 * Провайдер DropTip, реализует показ одного тулитпа в рамках провайдера
 * @param children
 * @return {*}
 * @constructor
 */
const TipProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [showedId, setShow] = useState<number | null>(null);

    return <Context.Provider value={{ setShow, showedId }}>{children}</Context.Provider>;
};

export { Context };
export default TipProvider;
