/**
 * Утилита для получения шаблона.
 *
 * @exports bloko/common/template
 * @type {Object}
 */
export default {
    fromString(template) {
        return template.replace(/^\s*<!--|-->\s*$/g, '').replace(/^\s*\n|\n\s*$/g, '');
    },

    /**
     * Возвращает шаблон-строку из переданного элемента
     * @param  {HTMLElement} element элемент, в котором находится шаблон
     * @return {String} строковое представление шаблона
     */
    fromElement(element) {
        return element instanceof Element ? this.fromString(element.innerHTML) : '';
    },
};
