import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { Merge } from 'type-fest';

import styles from 'bloko/blocks/select/select.less';

import Option from './Option';

export enum SelectScale {
    Small = 'small',
    Large = 'large',
}

type SelectProps = Merge<
    React.ComponentPropsWithoutRef<'select'>,
    {
        /** Список [Option](/?path=/docs/components-select-option--option) */
        children?: React.ReactNode;
        /** Размер контрола */
        scale?: SelectScale;
        /** Флаг не валидности */
        invalid?: boolean;
        /** Флаг размера контрола, размер по контенту */
        flexible?: boolean;
        /** Флаг для отключения рамок у селекта */
        light?: boolean;
        /** Флаг disabled контрола */
        disabled?: boolean;
        /** Значение выбранного option для controlled компонента*/
        value?: string;
        /** Значение выбранного option для uncontrolled компонента*/
        defaultValue?: string;
        /** Обработчик onChange, в качестве аргумента передает event */
        onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    }
>;

export class SelectRaw extends PureComponent<SelectProps> {
    static defaultProps = {
        invalid: false,
        flexible: false,
        light: false,
        disabled: false,
    };

    render(): JSX.Element {
        const { children, flexible, light, invalid, scale, ...selectProps } = this.props;

        return (
            <select
                className={classnames(styles['bloko-select'], {
                    [styles['bloko-select_flexible']]: flexible,
                    [styles['bloko-select_light']]: light,
                    [styles['bloko-select_invalid']]: invalid,
                    [styles[`bloko-select_scale-${scale as SelectScale}`]]: scale,
                })}
                suppressHydrationWarning
                {...selectProps}
            >
                {children}
            </select>
        );
    }
}

const Select = React.forwardRef<SelectRaw, SelectProps>((props, ref) => <SelectRaw {...props} ref={ref} />);

export default Select;
export type SelectPropsWithRef = Parameters<typeof Select>[0];

export { Option };
