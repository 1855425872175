import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const MailScaleSmallAppearanceColor = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M10.0078 7.99795C10.0078 9.10523 9.10941 10.0036 8.00213 10.0036C6.89485 10.0036 5.99649 9.10523 5.99649 7.99795C5.99649 6.89067 6.89485 5.99231 8.00213 5.99231C9.10941 5.99231 10.0078 6.89485 10.0078 7.99795ZM8.00213 1.33337C4.32512 1.33337 1.33337 4.32512 1.33337 8.00213C1.33337 11.6791 4.32512 14.6667 8.00213 14.6667C9.34758 14.6667 10.6471 14.2656 11.7585 13.5051L11.7794 13.4926L10.8811 12.448L10.8643 12.4563C10.0078 13.0037 9.02167 13.2962 8.00213 13.2962C5.08142 13.2962 2.7039 10.9187 2.7039 7.99795C2.7039 5.07724 5.08142 2.69972 8.00213 2.69972C10.9228 2.69972 13.3004 5.07724 13.3004 7.99795C13.3004 8.37819 13.2586 8.75842 13.175 9.13866C13.0079 9.8281 12.5274 10.037 12.1638 10.0078C11.8003 9.97852 11.3741 9.71946 11.3699 9.08434V7.99795C11.3699 6.13855 9.85735 4.62597 7.99795 4.62597C6.13856 4.62597 4.62597 6.13855 4.62597 7.99795C4.62597 9.85735 6.13856 11.3699 7.99795 11.3699C8.90049 11.3699 9.74871 11.0148 10.388 10.3755C10.7599 10.9521 11.3658 11.3156 12.0552 11.3699C12.1137 11.3741 12.1764 11.3783 12.2349 11.3783C12.7196 11.3783 13.2001 11.2153 13.5887 10.9228C13.9898 10.6178 14.2865 10.1791 14.4494 9.65261C14.4745 9.56904 14.5246 9.37265 14.5246 9.37265V9.36429C14.6207 8.94227 14.6667 8.52025 14.6667 7.99795C14.6709 4.32512 11.6791 1.33337 8.00213 1.33337Z"
                fill="#FF9E00"
            />
        </svg>
    ),
    'Mail'
);
export default MailScaleSmallAppearanceColor;
