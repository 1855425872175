import React from 'react';
import classnames from 'classnames';
import { Merge } from 'type-fest';

import { DocumentedPropsWithChildren } from 'bloko/common/helpers/types';

import styles from 'bloko/blocks/controlGroup/controlGroup.less';

export interface ControlGroupItemProps {
    /** Является ли элемент главным в группе */
    main?: boolean;
    /** Количество главных элементов в группе. Передается родительским компонентом */
    count?: number;
    /** Передается родительским компонентом */
    autosize?: boolean;
    /** Передается родительским компонентом */
    vertical?: boolean;
}

const ControlGroupItem: React.FC<
    Merge<JSX.IntrinsicElements['div'], DocumentedPropsWithChildren<ControlGroupItemProps>> & React.PropsWithChildren
> = ({ children, main, count, autosize, vertical, ...props }) => (
    <div
        {...props}
        className={classnames({
            [styles['bloko-control-group__minor']]: !main && !autosize && !vertical,
            [styles['bloko-control-group__main']]: main,
            [styles['bloko-control-group__main_col-1-2']]: main && count === 2,
            [styles['bloko-control-group__main_col-1-3']]: main && count === 3,
            [styles['bloko-control-group__content-sized']]: autosize,
            [styles['bloko-control-group__vertical-item']]: vertical,
        })}
    >
        {children}
    </div>
);

export default ControlGroupItem;
