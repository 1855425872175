import { useRef, useCallback, SetStateAction, Dispatch, ReactNode } from 'react';

import { isValidTreeSelectorElement, isValidTreeSelector } from 'bloko/blocks/treeSelector';
import { isValidTreeSelectorDummyElement } from 'bloko/blocks/treeSelector/Dummy';
import { isEqualArrays } from 'bloko/blocks/treeSelector/utils';

interface UseHandlerHookProps {
    clearSearchOnChange?: boolean;
    hideOnSubmitClick: boolean;
    controlledSelected: string[];
    setContentFilterQuery: (value: string) => void;
    setSelected: Dispatch<SetStateAction<string[]>>;
    setExpanded: Dispatch<SetStateAction<string[]>>;
    onSubmit?: (selected: string[]) => void;
    onClose: () => void;
    onChange?: (selected: string[]) => void;
    children: ReactNode;
}

interface UseHandlerHook {
    (props: UseHandlerHookProps): {
        handleTreeSelectorExpand: (expanded: string[]) => void;
        handleTreeSelectorChange: (id: string, isSelected: boolean, selected?: string[]) => void;
        handleSubmit: () => void;
        handleSearch: (value: string) => void;
    };
}

const useHandlers: UseHandlerHook = ({
    children,
    clearSearchOnChange,
    setContentFilterQuery,
    controlledSelected,
    onSubmit,
    setSelected,
    setExpanded,
    hideOnSubmitClick,
    onClose,
    onChange,
}) => {
    const currentSelected = useRef<string[]>(
        isValidTreeSelectorElement(children) ? children.props.initialSelected || [] : []
    );
    const currentExpanded = useRef<string[]>(isValidTreeSelector(children) ? children.props.initialExpanded || [] : []);

    const handleTreeSelectorChange = useCallback(
        (id: string, isSelected: boolean, selected: string[] = []) => {
            if (isValidTreeSelectorDummyElement(children)) {
                children.props.onChange?.(id, isSelected);
            } else {
                currentSelected.current = selected;
            }
            if (clearSearchOnChange) {
                setContentFilterQuery('');
            }
        },
        [children, clearSearchOnChange, setContentFilterQuery]
    );

    const handleTreeSelectorExpand = useCallback((expanded: string[]) => {
        currentExpanded.current = expanded;
    }, []);

    const handleSubmit = useCallback(() => {
        const selected = isValidTreeSelectorDummyElement(children) ? controlledSelected : currentSelected.current;
        const expanded = currentExpanded.current;

        if (onSubmit) {
            onSubmit(selected.slice());
        } else {
            setSelected((currentSelected: string[]) => {
                if (!isEqualArrays(currentSelected, selected)) {
                    onChange?.(selected.slice());
                    return selected;
                }
                return currentSelected;
            });
        }

        setExpanded((current: string[]) => (!isEqualArrays(current, expanded) ? expanded : current));

        if (hideOnSubmitClick) {
            setContentFilterQuery('');
            onClose();
        }
    }, [
        children,
        controlledSelected,
        hideOnSubmitClick,
        onChange,
        onClose,
        onSubmit,
        setContentFilterQuery,
        setExpanded,
        setSelected,
    ]);

    const handleSearch = useCallback(
        (value: string | undefined) => {
            setContentFilterQuery(value || '');
        },
        [setContentFilterQuery]
    );

    return { handleTreeSelectorExpand, handleTreeSelectorChange, handleSubmit, handleSearch };
};

export default useHandlers;
