import { FC } from 'react';
import classnames from 'classnames';

import { IconColor, ChevronScaleSmallKindLeft, ChevronScaleSmallKindRight } from 'bloko/blocks/icon';

import styles from 'bloko/blocks/calendar/calendar.less';

export interface CalendarArrowProps {
    kind: 'left' | 'right';
    disabled: boolean;
    onClick: () => void;
}

const CalendarArrow: FC<CalendarArrowProps> = ({ kind, disabled, onClick, ...props }) => {
    const initial = disabled ? IconColor.Gray50 : IconColor.Gray60;
    return (
        <span
            {...props}
            onClick={onClick}
            className={classnames(styles['bloko-calendar__switcher-arrow'], {
                [styles['bloko-calendar__switcher-arrow_back']]: kind === 'left',
                [styles['bloko-calendar__switcher-arrow_next']]: kind === 'right',
                [styles['bloko-calendar__switcher-arrow_disabled']]: disabled,
            })}
        >
            {kind === 'left' ? (
                <ChevronScaleSmallKindLeft initial={initial} />
            ) : (
                <ChevronScaleSmallKindRight initial={initial} />
            )}
        </span>
    );
};

export default CalendarArrow;
