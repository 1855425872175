import { memo } from 'react';
import classnames from 'classnames';

import { ComponentWithCustomElement } from 'bloko/common/helpers/types';

import styles from 'bloko/blocks/drop/Menu/item.less';

interface ItemProps {
    /** Кастомный компонент функция или 'a', 'button' */
    Element?: 'a' | 'button';
    /** Можно ли выбирать элемент (добавляет место для галочки) */
    selectable?: boolean;
    /** Флаг что элемент выбран - отображается галочка */
    selected?: boolean;
}

const Item: ComponentWithCustomElement<ItemProps, 'a'> = ({ Element = 'a', selected, selectable, ...elementProps }) => (
    <Element
        {...elementProps}
        className={classnames(styles['bloko-drop-menu-item'], {
            [styles['bloko-drop-menu-item_selected']]: selected,
            [styles['bloko-drop-menu-item_selectable']]: selectable,
        })}
    />
);

export default memo(Item);
