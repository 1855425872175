import {
    WeekdayRu,
    WeekdayEn,
    MonthRu,
    MonthEn,
    Months,
    Weekdays,
    CalendarLanguage,
} from 'bloko/blocks/calendar/translations';

export const MAX_DATES_IN_CALENDAR = 42;
export const WEEKENDS = [0, 6];
export const WEEKDAYS_ORDER = [1, 2, 3, 4, 5, 6, 0];
export const WEEKDAYS_AMOUNT = 7;
export const UPPER_YEAR_LISTS_LIMITS = [2039, 2019, 1999, 1979, 1959, 1939, 1919];
export const YEARS_IN_LIST = 20;
export const MONTHS = {
    [CalendarLanguage.Ru]: Object.values(MonthRu) as Months,
    [CalendarLanguage.En]: Object.values(MonthEn) as Months,
};

export const WEEKDAYS: Record<CalendarLanguage, Weekdays> = {
    [CalendarLanguage.Ru]: [
        WeekdayRu.Sun,
        WeekdayRu.Mon,
        WeekdayRu.Tue,
        WeekdayRu.Wed,
        WeekdayRu.Thu,
        WeekdayRu.Fri,
        WeekdayRu.Sat,
    ],
    [CalendarLanguage.En]: [
        WeekdayEn.Sun,
        WeekdayEn.Mon,
        WeekdayEn.Tue,
        WeekdayEn.Wed,
        WeekdayEn.Thu,
        WeekdayEn.Fri,
        WeekdayEn.Sat,
    ],
};
