/* eslint import/no-dynamic-require: 0 */
import $ from 'jquery';

import Debug from 'bloko/common/core/Debug';
/**
 * Модуль, реализующий компоненты
 *
 * Больше информации по компонентам тут {@link https://github.com/hhru/frontend/blob/master/docs/components.md}
 *
 * @type {Object}
 * @exports bloko/common/core/Components
 */
const Components = {
    /**
     * Метод по созданию компонента.
     *
     * @param {Object} component Объект со свойствами `create` и `defaults`
     * @param {Function} component.create Функция, которая инициализирует компонент. Принимает аргументы:
     * * `element` - DOM-Элемент
     * * `params` - параметры инициализации компонента
     * @param {Object} component.defaults Объект с параметрами по умолчанию, которые будут расширены переданными
     *
     * @returns {Function} Конструктор компонента
     *
     * @member
     * @method
     */
    build(component) {
        /**
         * Возвращает экземпляр компонента.
         *
         * @param {Element} element Элемент, на котором будет проинициализирован компонент
         * @param {Object}  params  Параметры компонента
         */
        return function (element, params, fromMake) {
            if (fromMake !== true) {
                Debug.log(
                    'out error',
                    new Error(`[${component.componentName}] Direct call is prohibited (use Components.make)`)
                );
            }

            if (!(element && 'nodeName' in element)) {
                throw new TypeError(`[${component.componentName}] First argument is not of type Element`);
            }

            const extendedParams = $.extend(true, {}, component.defaults || {}, params);

            // Явный контекст null, для исключения присвоения свойств через this
            return component.create.call(null, element, extendedParams);
        };
    },

    /**
     * Ищет и инициализирует компоненты внутри переданного DOM-элемента. Метод асинхронный.
     *
     * @param {Element} element Element, внутри которого необходимо производить поиск компонентов
     * @param {Function} importFunc Функция импорта компонента.
     *                              Возвращает промис, который резолвится полученным объектом.
     *
     * @returns {Promise} Промис отвечающий за окончание инициализации компонентов
     *
     * @member
     * @method
     */
    init(element, importFunc) {
        if (!(element && 'nodeName' in element)) {
            throw new TypeError('First argument is not of type Element');
        }

        if (!importFunc) {
            throw new TypeError('Import Function as a second argument is required');
        }

        const self = this;
        const promises = [];

        $('script[data-name]', element).each((index, componentNode) => {
            const $componentNode = $(componentNode);
            const componentName = $componentNode.data('name');
            let paramsString = $componentNode.attr('data-params');
            let element;

            // Костыль для input, textarea тегов
            if ($componentNode.data('for') === 'prev') {
                element = $componentNode.prev().get(0);
            } else {
                element = componentNode.parentNode;
            }

            let params = {};

            if (paramsString && paramsString.length) {
                // JSON.parse взрывается, когда в значении свойства json'а есть строка с переносом.
                paramsString = paramsString.trim().replace(/(\n|\r)/g, '');

                try {
                    params = JSON.parse(paramsString);
                } catch (error) {
                    Debug.log('out error', new Error(`Syntax Error in JSON params in component ${componentName}`), {
                        paramsString: paramsString.replace(/\s{2,}/g, ' '),
                    });

                    return;
                }
            }

            const deferred = $.Deferred();

            importFunc(componentName).then(
                (component) => {
                    let instance;

                    try {
                        instance = self.make(component, element, params, componentName);
                    } catch (error) {
                        Debug.log('out error', error, { explain: `Error while creating ${componentName}` });
                        deferred.reject();
                    }

                    deferred.resolve(instance);
                },
                (error) => {
                    Debug.log('out error', error, { explain: `Error while loading ${componentName}` });
                    deferred.reject();
                }
            );

            promises.push(deferred.promise());
        });

        return $.when(...promises);
    },

    /**
     * Вешает на элемент компонент с параметрами.
     *
     * @param {Function} component Конструктор компонента для инициализации
     * @param {Element}  element   Элемент, на котором проинициализировать компонент
     * @param {Object}   params    Параметры, с которыми проинициализировать компонент
     *
     * @returns {сomponentInstance} Интерфейс компонента
     *
     * @member
     * @method
     */
    make(component, element, params) {
        return component(element, params, true);
    },
};

export default Components;
