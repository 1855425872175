import dummyToggle from 'bloko/common/collection/dummyToggle';
/**
 * Создаёт переключатель элементов в наборе с учётом иерархии элементов:
 * — если выбран или сброшен родитель, выбираются или сбрасываются все его потомки;
 * — если выбраны все потомки родителя, выбирается и родитель;
 * — если сброшен хотя бы один потомок выбранного родителя, сбрасывается и родитель.
 * @returns {SetToggler}
 * @constructor
 */
function createTreeCollectionToggler(collection, checkSelectable, options) {
    /**
     * Определение состояния по дочерним элементам.
     * @param {Set} set
     * @param {String} id
     * @returns {Boolean}
     * @private
     */
    function _getCumulativeParentState(set, id) {
        let hasSelectableChild = false;
        const allSelectableChildrenAreSelected = collection.getChildren(id).every((model) => {
            const isSelectable = checkSelectable(model.id, collection);
            hasSelectableChild = hasSelectableChild || isSelectable;
            return set.has(model.id) || !isSelectable;
        });
        return hasSelectableChild ? allSelectableChildrenAreSelected : set.has(id);
    }

    /**
     * Простановка состояния всем дочерним элементам.
     * @param {Set} set
     * @param {String} id
     * @param {Boolean} state
     * @private
     */
    function _setStateForAllChildren(set, id, state) {
        collection.walkChildren(id, (model) => {
            if (checkSelectable(model.id, collection)) {
                dummyToggle(set, model.id, state);
            }
        });
    }

    function _toggleOtherParents(set, id) {
        // когда добавляем-убираем родительскую модель надо проверить и проставить статус другим родителям,
        // у которых есть общие модели-потомки с первоночальной родительской моделью
        if (collection.hasChildren(id)) {
            const childrenIds = collection.getChildrenIds(id);
            const otherParentsIds = childrenIds.reduce((result, childrenId) => {
                const parentIds = collection
                    .getParentIdsDuplicates(childrenId)
                    .filter(
                        (parentId) =>
                            parentId !== id && !result.includes(parentId) && checkSelectable(parentId, collection)
                    );
                return result.concat(parentIds);
            }, []);
            otherParentsIds.forEach((parentId) => dummyToggle(set, parentId, _getCumulativeParentState(set, parentId)));
        }
    }

    /**
     * Переключение состояния модели, её родителей и потомков.
     * @type {SetToggler}
     */
    function toggle(set, id, state) {
        const toggleResult = dummyToggle(set, id, state);

        if (toggleResult && !options.withExcluded) {
            collection.walkParents(id, (parent) => {
                if (checkSelectable(parent.id, collection)) {
                    dummyToggle(set, parent.id, _getCumulativeParentState(set, parent.id));
                }
            });
        }
        _setStateForAllChildren(set, id, state);
        _toggleOtherParents(set, id);
    }

    return toggle;
}

export default createTreeCollectionToggler;
