import dropBase, { clickBaseFabric } from 'bloko/blocks/drop/classic';
import Components from 'bloko/common/core/Components';

import template from 'bloko/blocks/drop/Down/template.mustache';

import { defaultProps, BASE_CLASS_NAMES, BEHAVIOR, DownPlacement, DownLayer } from './common';

/**
 * @exports bloko/blocks/drop/Down/down
 *
 * Создает выпадающий контент. Исопользуется, когда Menu и Info не подходят.
 *
 * @param {Element} element DOM-элемент, активатор. Down позиционируется относительно него.
 *
 * @param {Object} params параметры DropDown
 *
 * @param {String} [params.placement='bottomStart'] Предпочтительное положение компонента, доступны в статическом свойстве [placements](../#/•%20Drop?id=down-placements).
 * Если в указанном направлении недостаточно места для отображения, подсказка будет показана в направлении, которое
 * больше подходит.
 * @param {Boolean} [params.onlySetPlacement=false] Выпадать только в заданном направлении, не обрабатывать другие кейсы
 * @param {String} [params.dataQa='bloko-drop-down'] Data-qa активного DropDown
 * @param {String} [params.layer='aboveContent'] Тип слоя z-index-а, варианты доступны в статическом свойстве [layers](../#/•%20Drop?id=down-layers)
 * @param {String | Element} [params.host=document.body] DOM нода или селектор хоста в рамках которого
 * нужно рендерить DropDown
 * @param {String | Element} params.html cодержимое DropDown, поддерживает html разметку, и строки.
 * @param {String} [params.title=null] Заголовок меню, выводится только на XS.
 * @param {Boolean} [params.flexible=false] Метка, использовать ли тянущийся down.
 * @param {Boolean} [params.showCloseButton=false] Метка, показывать ли крестик для закрытия.
 * @param {Function} [params.onShow=() => {}] вызывается при открытии компонента
 * @param {Function} [params.onClose=() => {}] вызывается при закрытии компонента
 * @constructor
 */
const Down = Components.build({
    defaults: {
        ...defaultProps,
        host: 'body',
        onShow() {},
        onClose() {},
    },
    create(
        element,
        { host, showCloseButton, layer, placement, html, onlySetPlacement, onShow, onClose, flexible, dataQa, title }
    ) {
        return dropBase(element, {
            // params
            host,
            layer,
            placement,
            html,
            onlySetPlacement,
            onShow,
            onClose,
            flexible,
            dataQa,
            title,

            // specific
            baseClassNames: BASE_CLASS_NAMES,
            behavior: BEHAVIOR,
            template,
            templateData: {
                showCloseButton: !title && showCloseButton,
            },
            selectors: {
                component: 'Bloko-Drop-Down',
                content: 'Bloko-Drop-Down-Content',
            },

            // override
            closeByClickOutside: true,
        });
    },
});

export const Click = clickBaseFabric(Down);

export default Down;
export { DownLayer, DownPlacement };
