import { useEffect, useState, useRef, useCallback, ReactElement, isValidElement, ReactNode } from 'react';

import fuzzySearch from 'bloko/common/fuzzySearch';
import useServerEnv from 'bloko/common/hooks/useServerEnv';
import TreeCollection from 'bloko/common/tree/treeCollection';
import { filterWithParents } from 'bloko/common/tree/treeCollectionHelper';
import { IdCollectionPredicate, TreeFilter, AdditionalDefault } from 'bloko/common/tree/types';

import ItemsList from 'bloko/blocks/treeSelector/Dummy/ItemsList';
import SelectedList from 'bloko/blocks/treeSelector/Dummy/SelectedList';
import useExpanded from 'bloko/blocks/treeSelector/Dummy/hooks/useExpanded';
import useIndeterminateWithParents from 'bloko/blocks/treeSelector/Dummy/hooks/useIndeterminateWithParents';
import { LabelComponentType, IconComponentType } from 'bloko/blocks/treeSelector/Dummy/types';

import 'bloko/blocks/treeSelector/treeSelector.less';

let inputNameCounter = 0;
function getUniqueInputName() {
    const name = `bloko-tree-selector-default-name-${inputNameCounter}`;
    inputNameCounter += 1;
    return name;
}

function needToApply(query: string): boolean {
    return !!(query && query.length);
}

export interface TreeSelectorDummyProps<A extends AdditionalDefault> {
    /** Выбранные ID. */
    selected?: string[];
    /** ID моделей, которые всегда будут дополнительно предлагаться для выбора, если в поиске что-то нашлось */
    constantlySuggested?: string[];
    /** Запрещённые ID. */
    disabled?: string[];
    /** Изначально открытые ID. Применяются только при инициализации. */
    initialExpanded?: string[];
    /** Строка-фильтр для дерева, остаются только элементы с вхождением указанной строки. */
    contentFilterQuery?: string;
    /** Способ фильтрации дерева при поиске */
    treeFilter?: TreeFilter;
    /** Обработчик изменения набора выбранных ID.
     * В аргументах получает `{String} id`, `{Boolean} isSelected`. */
    onChange?: (id: string, isSelected: boolean) => void;
    /** ID моделей которые предлагаются для выбора, если в поиске ничего не найдено */
    suggestedNotFound?: string[];
    /** Разрешает выбор только элементов без потомков. */
    leavesOnly?: boolean;
    /** Разрешает выбор только одного элемента. */
    singleChoice?: boolean;
    /** Показывать ли список выбранных */
    showSelectedList?: boolean;
    /** Имя инпута. */
    inputName?: string;
    /** Дерево элементов. */
    collection: TreeCollection<A>;
    /** Изначально открытые ID, которые можно динамически изменять извне. */
    expanded?: string[];
    /** Компонент для вывода метки у элемента дерева */
    LabelComponent?: LabelComponentType<A>;
    /** Компонент для вывода иконки у элемента дерева */
    IconComponent?: IconComponentType<A>;
    /** Обработчик изменения состояния раскрытия элементов
     * В аргументах получает список id раскрытых элементов `{Array} expanded` */
    onExpand?: (expanded: string[]) => void;
    /** Обработчик изменения строки contentFilterQuery
     * В аргументах получает список подходящих под запрос id и сам запрос */
    onChangeFilterQuery?: (ids: string[], query: string) => void;
    /** Свойства обертки дерева */
    wrapperTreeProps?: JSX.IntrinsicElements['div'];
    /** Функция возвращает true для элементов, которые можно выбрать. При использовании
     * вместе с параметром leavesOnly применяется только к элементам без потомков. */
    checkSelectable?: IdCollectionPredicate;
    indeterminateWithParents?: boolean;
}

const defaultArray: string[] = [];
const defaultCheckSelectable = () => true;

interface TreeSelectorDummyComponent {
    <A extends AdditionalDefault>(props: TreeSelectorDummyProps<A>): JSX.Element | null;
}

const TreeSelectorDummy: TreeSelectorDummyComponent = ({
    leavesOnly,
    singleChoice,
    showSelectedList,
    selected = defaultArray,
    initialExpanded = defaultArray,
    constantlySuggested = defaultArray,
    disabled = defaultArray,
    onChange,
    inputName: initialInputName,
    collection: initialCollection,
    expanded,
    LabelComponent,
    IconComponent,
    onExpand,
    contentFilterQuery = '',
    treeFilter,
    onChangeFilterQuery,
    suggestedNotFound = defaultArray,
    wrapperTreeProps,
    checkSelectable = defaultCheckSelectable,
    indeterminateWithParents = true,
}) => {
    const [collection, setCollection] = useState(initialCollection);
    const inputName = useRef(initialInputName || getUniqueInputName());
    const suggestedNotFoundModels = useRef(initialCollection.getExistModels(suggestedNotFound));
    const constantlySuggestedModels = useRef(initialCollection.getExistModels(constantlySuggested));
    const currentQuery = useRef('');

    const currentTreeFilter = treeFilter || filterWithParents;

    const [indeterminate] = useIndeterminateWithParents({
        initialCollection,
        selected: indeterminateWithParents ? selected : defaultArray,
    });
    const [currentExpanded, setExpanded, handleExpansion] = useExpanded({
        initialValue: expanded ? expanded.slice() : initialExpanded.slice(),
        controlledExpanded: expanded,
        onExpand,
    });

    const handleChangeFilterQuery = useCallback(
        (filteredIds: string[], contentFilterQuery: string) => {
            onChangeFilterQuery?.(filteredIds.slice(), contentFilterQuery);
        },
        [onChangeFilterQuery]
    );

    const isServerEnv = useServerEnv();

    useEffect(() => {
        const contentFilterQueryTrimmed = contentFilterQuery.trim();
        const queryWasChanged = contentFilterQueryTrimmed !== currentQuery.current.trim();

        if (queryWasChanged && needToApply(contentFilterQueryTrimmed)) {
            const newExpanded: string[] = [];
            const newCollection = currentTreeFilter(initialCollection, (item) =>
                fuzzySearch.match(contentFilterQueryTrimmed, item.text)
            );
            newCollection.toList().forEach((item) => {
                if (newCollection.hasChildren(item.id)) {
                    // Если есть в отфильтрованной коллекции есть дочерние элементы,
                    // значит они заматчились, и нужно открыть родителя.
                    if (queryWasChanged) {
                        newExpanded.push(item.id);
                    }
                } else {
                    // Если заматчился только сам родитель, показываем его
                    // схлопнутым => нужно добавить его ветку в коллекцию.
                    initialCollection.walkChildren(item.id, (child, parents) => {
                        newCollection.addModel({ ...child }, parents[0].id);
                    });
                }
            });

            const filteredIds = newCollection.toList().map((model) => model.id);

            // Если в отфильтрованной коллекции нет моделей, но заданы предложенные,
            // то показываем их
            // Если в отфильтрованной коллекции модели есть, то пробуем добавить к ним
            // всегда показывающиеся модели, если их ещё нет в коллекции
            if (!filteredIds.length && suggestedNotFoundModels.current.length) {
                suggestedNotFoundModels.current.forEach((model) => newCollection.addModel({ ...model }));
            } else {
                constantlySuggestedModels.current.forEach((model) => {
                    if (!filteredIds.includes(model.id)) {
                        newCollection.addModel({ ...model });
                    }
                });
            }

            setCollection(newCollection);
            currentQuery.current = contentFilterQuery;

            handleChangeFilterQuery(filteredIds, contentFilterQueryTrimmed);
            setExpanded(newExpanded);
        } else if (queryWasChanged) {
            // Запрос не годится для поиска.
            const newExpanded = initialExpanded.slice();
            setCollection(initialCollection);

            handleChangeFilterQuery(
                initialCollection.toList().map((model) => model.id),
                contentFilterQueryTrimmed
            );
            currentQuery.current = contentFilterQuery;
            setExpanded(newExpanded);
        }
    }, [
        initialCollection,
        contentFilterQuery,
        currentTreeFilter,
        handleChangeFilterQuery,
        initialExpanded,
        selected,
        setExpanded,
    ]);

    const checkSelectableByInitialCollection = useCallback(
        (id: string) => checkSelectable(id, initialCollection),
        [checkSelectable, initialCollection]
    );

    const renderSelectedList = useCallback(() => {
        const filteredIds = singleChoice ? selected : selected.filter((id) => !collection.hasChildren(id));
        const models = collection.getExistModels(filteredIds);

        return <SelectedList items={models} onClick={onChange} />;
    }, [collection, onChange, selected, singleChoice]);

    if (isServerEnv) {
        return null;
    }

    const withSelectedList = showSelectedList && !contentFilterQuery;

    return (
        <>
            {withSelectedList && renderSelectedList()}
            <div {...wrapperTreeProps}>
                <ItemsList
                    inputName={inputName.current}
                    collection={collection}
                    leavesOnly={leavesOnly}
                    singleChoice={singleChoice}
                    selected={selected}
                    expanded={currentExpanded}
                    disabled={disabled}
                    indeterminate={indeterminate}
                    LabelComponent={LabelComponent}
                    IconComponent={IconComponent}
                    onChange={onChange}
                    onExpansion={handleExpansion}
                    // Передаём сюда функцию, замкнутую на initialCollection, потому что collection,
                    // который подставит ItemsList, будет не полный, а модифицированный поиском.
                    checkSelectableId={checkSelectableByInitialCollection}
                />
            </div>
        </>
    );
};

export default TreeSelectorDummy;

export const isValidTreeSelectorDummyElement = (
    child: ReactNode
): child is ReactElement<TreeSelectorDummyProps<never>> => isValidElement(child) && child.type === TreeSelectorDummy;
