import { useCallback, MutableRefObject, RefObject } from 'react';

import { UseSwipeDetailsType, SwipeDirection } from './constants';
import {
    swipeMove,
    swipeStart,
    swipeEnd,
    SwipeStartHandler,
    SwipeMoveHandler,
    SwipeEndHandler,
    SwipePreventHandler,
} from './swipeUtils';

type SetSwipeDetails = (details: Partial<UseSwipeDetailsType>) => void;
export type { SetSwipeDetails };

export interface UseSwipeUtilsInterface {
    quickSwipePercent: number;
    minSwipePercent: number;
    maxBorderOffsetPercent: number;
    containerEl: RefObject<HTMLElement>;
    swipeDetails: MutableRefObject<UseSwipeDetailsType>;
    setSwipeDetails: SetSwipeDetails;
    onSwipePrevent?: SwipePreventHandler;
    onSwipeEnd?: SwipeEndHandler;
    onSwipeStart?: SwipeStartHandler;
    onSwipeMove?: SwipeMoveHandler;
    direction: SwipeDirection;
}

type SwipeMove = (event: React.MouseEvent | TouchEvent, clientX: number, clientY: number) => void;
type SwipeStart = (clientX: number, clientY: number) => void;

export interface UseSwipeUtilsResult {
    swipeMove: SwipeMove;
    swipeStart: SwipeStart;
    swipeEnd: () => void;
}

const useSwipeUtils = ({
    quickSwipePercent,
    minSwipePercent,
    maxBorderOffsetPercent,
    containerEl,
    swipeDetails,
    setSwipeDetails,
    onSwipePrevent,
    onSwipeEnd,
    onSwipeStart,
    onSwipeMove,
    direction,
}: UseSwipeUtilsInterface): UseSwipeUtilsResult => {
    const _swipeMove: SwipeMove = useCallback(
        (event, clientX, clientY) => {
            swipeMove(event, clientX, clientY, {
                directionWay: direction,
                onSwipeMove,
                onSwipeStart,
                getSwipeDetails: (key) => swipeDetails.current[key],
                setSwipeDetails,
            });
        },
        [direction, onSwipeMove, onSwipeStart, setSwipeDetails, swipeDetails]
    );

    const _swipeStart: SwipeStart = useCallback(
        (clientX, clientY) => {
            swipeStart(clientX, clientY, {
                directionWay: direction,
                setSwipeDetails,
            });
        },
        [direction, setSwipeDetails]
    );

    const _swipeEnd = useCallback(() => {
        swipeEnd(quickSwipePercent, minSwipePercent, maxBorderOffsetPercent, {
            directionWay: direction,
            onSwipePrevent,
            onSwipeEnd,
            getSwipeDetails: (key) => swipeDetails.current[key],
            setSwipeDetails,
            getContainerBoundingClientRect: () => containerEl?.current?.getBoundingClientRect?.(),
        });
    }, [
        direction,
        containerEl,
        maxBorderOffsetPercent,
        minSwipePercent,
        onSwipeEnd,
        onSwipePrevent,
        quickSwipePercent,
        setSwipeDetails,
        swipeDetails,
    ]);

    return {
        swipeMove: _swipeMove,
        swipeStart: _swipeStart,
        swipeEnd: _swipeEnd,
    };
};

export default useSwipeUtils;
