import FuzzySearch from 'bloko/common/fuzzySearch';

import { DataProvider, DataProviderItem } from 'bloko/blocks/suggest/types';

const createStaticDataProvider =
    (array: DataProviderItem[]): DataProvider =>
    (query) => {
        const filterItems = ({ text }: DataProviderItem) => FuzzySearch.match(query, text);
        const items = array.filter(filterItems);

        return new Promise((resolve) => {
            setTimeout(() => resolve({ items }), 0);
        });
    };

export default createStaticDataProvider;
