import Backbone from 'backbone';
import $ from 'jquery';

export default function (collection) {
    const hashCollection = {};
    function get() {
        return collection;
    }

    function getById(id) {
        return hashCollection[id] || [];
    }

    function getByAttributes(attributes) {
        return collection.filter((item) => {
            const modelAttributes = item.attributes;
            let isMatch = true;
            for (const key in attributes) {
                if (modelAttributes[key] !== attributes[key]) {
                    isMatch = false;
                }
            }

            return isMatch;
        });
    }

    function add(model) {
        collection.push(model);
        hashCollection[model.attributes.id] = hashCollection[model.attributes.id] || [];
        hashCollection[model.attributes.id].push(model);
    }

    return $.extend(
        {
            get,
            add,
            getById,
            getByAttributes,
        },
        Backbone.Events
    );
}
