import $ from 'jquery';

import TreeCollection from 'bloko/common/tree/collection';

import NestedTagListView from 'bloko/blocks/tagList/nestedTagListView';
import TagList from 'bloko/blocks/tagList/tagList';

/**
 * Публичный интерфейс для работы со списком тегов, возвращаемый {@link module:NestedTagList}.
 *
 * @interface NestedTagList/TagListInterface
 * @events NestedTagList/TagListInterface
 * @see {@link NestedTagList/TagListInterface}
 * @augments TagList/TagListInterface
 */
/**
 * Переключает состояния вложенных тегов
 * @method NestedTagList/TagListInterface#toggleExpanded
 * @param  {Number} tagId id тега-родителя, для которого нужно переключить состояние
 * @param  {Boolean} state если передано true - раскрывает тег, если false - схлопывает
 * @return {Object} инстанс Backbone view (TagList)
 */
/**
 * Модуль создающий древовидный список тегов.
 * @module NestedTagList
 * @augments module:TagList
 */
export default $.extend({}, TagList, {
    /**
     * Создание публичного интерфейса.
     * @param tagListView
     * @returns {NestedTagList/TagListInterface}
     * @private
     */
    createPublicInterface(tagListView) {
        const publicInterface = TagList.createPublicInterface.call(this, tagListView);

        $.extend(publicInterface, {
            toggleExpanded: tagListView.toggleExpanded.bind(tagListView),
        });

        return publicInterface;
    },
    /**
     * Список древовидных тегов.
     *
     * @param {Element} element DOM-элемент или css селектор, на котором будет инициализирован компонент
     * @param {Object} options Принимает все параметры [tagList](https://tech.hh.ru/bloko/?path=/docs/components-taglist-classic-taglist--page#params). Древовидные теги не могут
     * быть `selectable, editable, stretched`(они всегда занимают всю доступную ширину).
     * @param {String} options.hiddenFieldName Отвечает за имя родительского скрытого инпута. Если он не передан,
     * то инпут родительского элемента не будет создан, `value = id` тега.
     * @param {String} options.hiddenChildFieldName Отвечает за имя дочернего скрытого инпута. Если он не передан,
     * то инпут дочернего элемента не будет создан, `value = id` тега.
     * @param {Boolean} [options.renderHiddenParentField=true] Рендерить ли скрытый инпут родительского тега,
     * если у него есть дочерние теги.
     * @constructor
     */
    createView(element, options) {
        return new NestedTagListView({
            collection: new TreeCollection(null, {
                model: this.createTagModel(options),
            }),
            el: element,
            options: $.extend(
                {
                    renderHiddenParentField: true,
                },
                options
            ),
        });
    },
});
