import $ from 'jquery';

import Suggest from 'bloko/blocks/suggest/suggest';
import Components from 'bloko/common/core/Components';
import Events from 'bloko/common/events';

import Location from 'bloko/blocks/address/location';
import ymapsSuggestDataProviderBuilder from 'bloko/blocks/address/ymapsSuggestDataProviderBuilder';

/**
 * Поставщик данных, функция, возвращающая Promise, который разрешается массивом данных для саджеста.
 * Встроенный DataProvider использует саджест яндексКарт
 *
 * @typedef {Function} DataProvider
 *
 * @param {String} inputValue
 *
 * @returns {Promise<Array>}
 */

/**
 * Функция-конструктор дата провайдера.
 *
 * @typedef {Function} DataProviderBuilder
 *
 * @returns {DataProvider}
 */

/**
 * Гео-локация, объект, в котором хранятся гео-данные для выбранного адреса
 *
 * @typedef {Object} Location
 *
 * @param {String} fullAddress                      Строка адреса, например: Москва, улица Годовикова, 9с10
 * @param {Array} coordinates                       координаты, широта и долгота
 * @param {String} [city]                           Город
 * @param {String} [street]                         Улица
 * @param {String} [building]                       Дом
 *
 */

/**
 * Адрес изменен
 *
 * @event change
 */

/**
 * Установлен фокус на поле ввода адреса
 *
 * @event focus
 */

/**
 * Использован геокодер.
 * Может использоваться для подсчета обращений к яндексКартам
 * чтобы понимать что мы не выходим за рамки бесплатного API
 * @event geocode.address
 */

/**
 * Использована геолокация.
 * Может используется для подсчета обращений к яндексКартам
 * чтобы понимать что мы не выходим за рамки бесплатного API
 *
 * @event geolocation.address
 */

/**
 * @param {Element} element         Элемент, на котором будет инициализирован компонент
 *
 * @param {Object} params           Параметры компонента
 *
 * @param {Promise} [params.ymapsPromise]  Промис, возвращающий Javascript API Yandex.Maps
 *
 * @param {Array} [params.suggestSearchStartPoint]  Координаты точки начала поиска [lat, lng].
 *                                                  Вместе с suggestSearchStartRadiusKm формируют
 *                                                  область, в которой, предположительно,
 *                                                  находится вводимый адрес.
 *                                                  Поиск начинается в заданной области,
 *                                                  но не ограничивается ею.
 *
 * @param {Number} [params.suggestSearchStartRadiusKm=5]  Радиус области начала поиска.
 *                                                        Не имеет смысла без
 *                                                        suggestSearchStartPoint
 *
 * @param {Number} [params.suggestResultsLimit=10]  Радиус области начала поиска.
 *                                                  Не имеет смысла без suggestSearchStartPoint
 *
 *
 * @param {DataProviderBuilder} [params.dataProviderBuilder]  Конструктор датапровайдера, функция принимающая
 *                                                            объект с ключами {suggestSearchStartPoint,
 *                                                            suggestSearchStartRadiusKm, suggestResultsLimit}
 *                                                            и возвращающая провайдер данных - функцию,
 *                                                            возвращающая промис, который разрешается массивом
 *                                                            данных для саджеста.
 *
 * @param {String} [params.prefix]  Префикс для {inputValue}, который будет передан в suggest
 *
 * @constructor
 */
function Address(element, params) {
    const $element = $(element);

    const dataProviderBuilder =
        params.dataProviderBuilder || ymapsSuggestDataProviderBuilder.bind(null, params.ymapsPromise);
    const dataProvider = dataProviderBuilder({
        prefix: params.prefix,
        suggestResultsLimit: params.suggestResultsLimit,
        suggestSearchStartPoint: params.suggestSearchStartPoint,
        suggestSearchStartRadiusKm: params.suggestSearchStartRadiusKm,
    });

    const location = Location(params.ymapsPromise);

    const $addressInput = $element.find('.Bloko-Address-Input');
    const $resolveLocationButton = $element.find('.Bloko-Address-ResolveLocation');
    const $loader = $element.find('.Bloko-Address-Loader');

    const suggestInstance = Components.make(Suggest, $addressInput.get(0), {
        limit: params.suggestResultsLimit,
        dataProvider,
        suggestStartInputLength: 0,
    });

    const publicInterface = Events.extend({
        /**
         * Устанавливает готовый адрес без похода в Яндекс за геолокацией
         *
         * @param {Location} location
         */
        setLocation(location) {
            _setLocation(location);
        },

        /**
         * очищает поле ввода, при этом не снимает фокус с ввода адреса
         *
         */
        clearLocation() {
            _setLocation({
                fullAddress: '',
                coordinates: null,
                city: null,
                street: null,
                building: null,
            });
            suggestInstance.clear();
        },
    });

    $addressInput.on('selected.suggest autoselected.suggest', (e, item) => {
        // Необходимо для возможности передавать готовый адрес из саджеста без дополнительного похода в Яндекс
        if (item.location) {
            _setLocation(item.location);
            return;
        }
        location
            .resolveLocationByAddress(item.fullAddress)
            .then((location) => {
                _setLocation(location);
                publicInterface._trigger('geocode.address');
            })
            .catch(console.error);
    });

    $addressInput.on('focus', () => {
        publicInterface._trigger('focus');
    });

    $resolveLocationButton.on('click', () => {
        const loadingStartTimeout = setTimeout(() => {
            $resolveLocationButton.addClass('g-hidden');
            $loader.removeClass('g-hidden');
        }, 200);
        location
            .resolveCurrentLocation()
            .then((location) => {
                _setLocation(location);
                publicInterface._trigger('geolocation.address');
            })
            .catch(console.error)
            .finally(() => {
                clearTimeout(loadingStartTimeout);
                $resolveLocationButton.removeClass('g-hidden');
                $loader.addClass('g-hidden');
            });
    });

    function _setLocation(location) {
        $addressInput.val(location.fullAddress);
        publicInterface._trigger('change', location);
    }

    return publicInterface;
}

export default Components.build({
    defaults: {
        suggestSearchStartRadiusKm: 5,
        suggestResultsLimit: 10,
    },
    create: Address,
});
