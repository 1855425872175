import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const CircleScaleSmallAppearanceFilled = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle
                cx={8}
                cy={8}
                r={3.33333}
                fill="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                strokeWidth={1.33333}
            />
        </svg>
    ),
    'Circle'
);
export default CircleScaleSmallAppearanceFilled;
