import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const TwitterScaleSmallAppearanceColor = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.4315 5.69229C13.4483 5.75935 13.4567 5.86832 13.4567 6.01921C13.4567 7.22631 13.1622 8.3915 12.5733 9.51478C11.9675 10.7051 11.1178 11.6523 10.024 12.3565C8.8293 13.1445 7.45791 13.5384 5.90983 13.5384C4.42906 13.5384 3.0745 13.1445 1.84613 12.3565C2.03123 12.3733 2.24156 12.3816 2.47714 12.3816C3.70551 12.3816 4.80767 12.0044 5.78363 11.25C5.19469 11.25 4.67726 11.0781 4.23135 10.7344C3.78543 10.3908 3.47834 9.95905 3.31007 9.43933C3.47834 9.4561 3.6382 9.46448 3.78964 9.46448C4.02522 9.46448 4.26079 9.43933 4.49637 9.38904C3.8906 9.25491 3.38579 8.94475 2.98195 8.45856C2.5781 7.97237 2.37618 7.41911 2.37618 6.7988V6.7485C2.74637 6.96645 3.1418 7.08381 3.56248 7.10057C3.20911 6.84909 2.92305 6.53055 2.7043 6.14495C2.48555 5.75935 2.37618 5.33603 2.37618 4.87498C2.37618 4.41394 2.50238 3.97385 2.75478 3.55471C3.41103 4.37621 4.21452 5.03006 5.16524 5.51625C6.11596 6.00245 7.12978 6.27069 8.20671 6.32099C8.17305 6.1198 8.15623 5.91862 8.15623 5.71744C8.15623 5.24801 8.27401 4.80792 8.50959 4.39717C8.74517 3.98642 9.06488 3.66369 9.46873 3.42898C9.87257 3.19426 10.3101 3.0769 10.7812 3.0769C11.1682 3.0769 11.5258 3.15235 11.8539 3.30324C12.1821 3.45412 12.4723 3.65531 12.7247 3.90679C13.3305 3.78943 13.8942 3.57986 14.4158 3.27809C14.2139 3.8984 13.8269 4.3846 13.2548 4.73667C13.7596 4.66961 14.2644 4.5271 14.7692 4.30915C14.399 4.84564 13.9531 5.30669 13.4315 5.69229Z"
                fill="#01C0FF"
            />
        </svg>
    ),
    'Twitter'
);
export default TwitterScaleSmallAppearanceColor;
