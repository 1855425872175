import $ from 'jquery';

import SliderAbstract from 'bloko/blocks/slider/sliderAbstract';

const SliderRange = function (element, options) {
    this._init(element, options);
};

SliderRange.prototype = $.extend({}, SliderAbstract, {
    /**
     * Приводит аргументы к допустимому диапазону
     * @param values
     * @returns {*}
     * @private
     */
    _ensureValuesInRange(values) {
        const min = values[0] === null ? this.unitMin : values[0];
        const max = values[1] === null ? this.unitMax : values[1];

        return [
            this._inRange(min, [this.options.available.from, this.unitMax]),
            this._inRange(max, [this.unitMin, this.options.available.to]),
        ];
    },

    /**
     * Приводит аргумент, к виду допустимому для передачи в метод _set
     * @param values
     * @returns {*}
     * @private
     */
    _ensureValuesValid(values) {
        if (values === null || typeof values === 'undefined') {
            values = [null, null];
        }

        values.forEach((element, index) => {
            element = parseInt(element, 10);
            values[index] = isNaN(element) ? null : element;
        });

        return values;
    },

    /**
     * Возвращает текущее выбранное значение
     * @returns {Array} текущее выбранное значение
     */
    get() {
        return [this.unitMin, this.unitMax];
    },

    /**
     * Создает опции для объекта класса Movement
     * @param isForLeft - Флаг указывающий для какого из пинов предназначяются параметры перемещения
     * @returns {object} Опции для объекта класса Movement
     * @private
     */
    _createMovementOptions(isForLeft) {
        let options;
        if (isForLeft) {
            options = {
                element: this.$leftPin.get(0),
                percentsMin: 0,
                percentsMax: this.rightPinMetrics.left,
            };
        } else {
            options = {
                element: this.$rightPin.get(0),
                percentsMin: this.leftPinMetrics.left,
                percentsMax: this.scaleLineSize,
            };
        }

        return options;
    },

    /**
     * Обработчик события перетаскивания пина
     * @param {boolean} isForLeft - флаг, указывающий для какого из пинов начато перетаскивание
     * @param {integer} pxPos - координата, в которую пытаются передвинуть пин
     * @param {boolean} isFinished - флаг, указывающий окончательная ли это точка в текущем перемещении
     * @private
     */
    _pinMoved(isForLeft, pxPos, isFinished) {
        const unitPos = this._convertPxToUnits(pxPos);
        this._set(isForLeft ? [unitPos, null] : [null, unitPos], isFinished);
    },

    /**
     * Перемещает пин при клике мышью
     * @param clickPosition координата клика
     * @param unitPosition соответствующая клику координата в условных единицах
     * @private
     */
    _setPositionByClick(clickPosition, unitPosition) {
        const leftPinDelta = Math.abs(clickPosition - this.leftPinMetrics.left);
        const rightPinDelta = Math.abs(clickPosition - this.rightPinMetrics.left);

        if (leftPinDelta === rightPinDelta && clickPosition < this.leftPinMetrics.left) {
            this._set([unitPosition, null]);
        } else {
            this._set(leftPinDelta < rightPinDelta ? [unitPosition, null] : [null, unitPosition]);
        }
    },

    /**
     * Обработчик нажатий клавиш на компоненте
     * @param {boolean} isForLeft - флаг, указывающий на каком из пинов произошло нажатие
     * @param {Event} event - событие
     * @private
     */
    _keyboardControl(isForLeft, event) {
        let value = this.get();
        value = isForLeft ? value[0] : value[1];
        value = this._moveByKeyboard(event, value);
        this._set(isForLeft ? [value, null] : [null, value]);
    },
});

export default SliderRange;
