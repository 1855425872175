import $ from 'jquery';

import SliderAbstract from 'bloko/blocks/slider/sliderAbstract';

const SliderSingle = function (element, options) {
    this._init(element, options);
};

SliderSingle.prototype = $.extend({}, SliderAbstract, {
    /**
     * Приводит аргументы к допустимому диапазону
     * @param values
     * @returns {*}
     * @private
     */
    _ensureValuesInRange(values) {
        values = values === null ? this.unitMax : values;
        values = this._inRange(values, [this.options.available.from, this.options.available.to]);
        return [this.options.available.from, values];
    },

    /**
     * Приводит аргумент, к виду допустимому для передачи в метод _set
     * @param value
     * @returns {*}
     * @private
     */
    _ensureValuesValid(value) {
        value = parseInt(value, 10);
        return isNaN(value) ? null : value;
    },

    /**
     * Возвращает текущее выбранное значение
     * @returns {integer} текущее выбранное значение
     */
    get() {
        return this.unitMax;
    },

    /**
     * Создает опции для объекта класса Movement
     * @returns {object} Опции для объекта класса Movement
     * @private
     */
    _createMovementOptions() {
        return {
            element: this.$rightPin.get(0),
            percentsMin: 0,
            percentsMax: this.scaleLineSize,
        };
    },

    /**
     * Обработчик события перетаскивания пина
     * @param {boolean} isForLeft - флаг, указывающий для какого из пинов начато перетаскивание
     * @param {integer} pxPos - координата, в которую пытаются передвинуть пин
     * @param {boolean} isFinished - флаг, указывающий окончательная ли это точка в текущем перемещении
     * @private
     */
    _pinMoved(isForLeft, pxPos, isFinished) {
        const unitPos = this._convertPxToUnits(pxPos);
        this._set(unitPos, isFinished);
    },

    /**
     * Перемещает пин при клике мышью
     * @param clickPosition координата клика
     * @param unitPosition соответствующая клику координата в условных единицах
     * @private
     */
    _setPositionByClick(clickPosition, unitPosition) {
        this._set(unitPosition);
    },

    /**
     * Обработчик нажатий клавиш на компоненте
     * @param {boolean} isForLeft - флаг, указывающий на каком из пинов произошло нажатие
     * @param {Event} event - событие
     * @private
     */
    _keyboardControl(isForLeft, event) {
        let value = this.get();
        value = this._moveByKeyboard(event, value);
        this._set(value);
    },
});

export default SliderSingle;
