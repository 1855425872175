const toPx = (px = 0) => `${px}px`;
const pxToNumber = (dimensionString: string) => parseInt(dimensionString, 10) || 0;

// Суммарный отступ сверху и снизу
const getPaddingsHeight = (element: HTMLTextAreaElement): number =>
    pxToNumber(window.getComputedStyle(element).paddingTop) +
    pxToNumber(window.getComputedStyle(element).paddingBottom);

/**
 * Возвращает высоту, которую нужно _установить_ элементу, чтобы исключить overflow.
 * Чтобы получить минимально возможную высоту, можно перед использованием
 * уменьшить её до minHeight.
 * @private
 */
const getBoxHeight = (element: HTMLTextAreaElement): number => {
    if (window.getComputedStyle(element).boxSizing === 'border-box') {
        return (
            element.scrollHeight +
            pxToNumber(window.getComputedStyle(element).borderTopWidth) +
            pxToNumber(window.getComputedStyle(element).borderBottomWidth)
        );
    }
    return element.scrollHeight - getPaddingsHeight(element);
};

/**
 * Устанавливает размер textarea соответственно
 * содержимому в пределах заданных ограничений
 *
 * @param {HTMLTextAreaElement} textarea DOM элемент
 * @param {Number} minHeight Задает минимальную высоту для textarea. Если не установлено, будет использован min-height
 * @param {Number} maxHeight Задает максимальную высоту для textarea
 */
export default (textareaElement: HTMLTextAreaElement, minHeight?: number, maxHeight?: number): void => {
    const textareaMinHeight = minHeight || pxToNumber(window.getComputedStyle(textareaElement).minHeight);
    const defaultMarginBottom = pxToNumber(window.getComputedStyle(textareaElement).marginBottom);

    // Вначале компенсируем изменение в высоте добавлением 'margin-bottom'
    // В противном случае скроллбар окна будет скакать при ререндере
    // (который будет вызван изменением высоты textarea)
    textareaElement.style.marginBottom = toPx(textareaElement.offsetHeight - textareaMinHeight + defaultMarginBottom);
    textareaElement.style.height = toPx(textareaMinHeight);

    const computedHeight = Math.max(textareaMinHeight, getBoxHeight(textareaElement));
    const limitHeight = maxHeight && computedHeight > maxHeight;

    textareaElement.style.height = toPx(limitHeight ? maxHeight : computedHeight);
    textareaElement.style.overflow = limitHeight ? 'auto' : 'hidden';
    textareaElement.style.marginBottom = toPx();

    // При появлении скроллбара пэддинг не компенсируется и последняя строка
    // с курсором остаётся прижата к нижней границе элемента
    if (
        limitHeight &&
        Math.max(textareaElement.selectionStart, textareaElement.selectionEnd) === textareaElement.value.length
    ) {
        textareaElement.scrollTop = textareaElement.scrollHeight;
    }
};
