import { FC, useEffect, useRef } from 'react';

import { IconColor, LupaScaleSmallKindDefaultAppearanceOutlined } from 'bloko/blocks/icon';
import InputText from 'bloko/blocks/inputText';
import { Breakpoint, getBreakpoint } from 'bloko/common/media';

import styles from 'bloko/blocks/tabs/tabs.less';

interface TabsSearchFieldProps {
    filterQuery: string;
    setFilterQuery: (value: string) => void;
    placeholder?: string;
}

const TabsSearchField: FC<TabsSearchFieldProps> = ({ filterQuery, setFilterQuery, placeholder }) => {
    const searchInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (getBreakpoint() === Breakpoint.XS) {
            return;
        }
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, []);

    return (
        <div className={styles['bloko-tabs__search']}>
            <InputText
                data-qa="bloko-tabs-search"
                value={filterQuery}
                placeholder={placeholder}
                onChange={setFilterQuery}
                ref={searchInputRef}
                icon={<LupaScaleSmallKindDefaultAppearanceOutlined initial={IconColor.Gray40} />}
            />
        </div>
    );
};

export default TabsSearchField;
