import { forwardRef, PureComponent } from 'react';
import classnames from 'classnames';
import { Merge } from 'type-fest';

import Select, { SelectPropsWithRef } from 'bloko/blocks/select';

import styles from 'bloko/blocks/select/select.less';

interface SelectWithPlaceholderProps {
    /** Текст плейсхолдера @docgen-important */
    placeholder: string;
}
type SelectWithPlaceholderFullProps = Merge<
    SelectWithPlaceholderProps,
    Omit<SelectPropsWithRef, keyof SelectWithPlaceholderProps>
>;

class SelectWithPlaceholderRaw extends PureComponent<SelectWithPlaceholderFullProps, { value?: string }> {
    hasChanged = false;

    state = {
        value: '',
    };

    componentDidUpdate(prevProps: SelectWithPlaceholderFullProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({ value: this.props.value });
        }
    }

    onChange: SelectWithPlaceholderFullProps['onChange'] = (event) => {
        this.hasChanged = true;
        this.setState({ value: event.target.value });
        this.props.onChange && this.props.onChange(event);
    };

    showPlaceholder = () => {
        if (this.props.value || this.state.value) {
            return false;
        }
        if (this.props.defaultValue && !this.hasChanged) {
            return false;
        }
        return true;
    };

    render() {
        const { placeholder, ref, source, ...selectProps } = this.props;

        return (
            <div
                className={classnames(styles['bloko-select-wrapper'], {
                    [styles['bloko-select-wrapper_flexible']]: selectProps.flexible,
                })}
                source={source}
            >
                {this.showPlaceholder() && (
                    <div className={styles['bloko-select-placeholder']}>
                        <span className={styles['bloko-select-placeholder-text']}>{placeholder}</span>
                    </div>
                )}
                <Select {...selectProps} ref={ref} onChange={this.onChange} />
            </div>
        );
    }
}

const SelectWithPlaceholder = forwardRef<SelectWithPlaceholderRaw, SelectWithPlaceholderFullProps>((props, ref) => (
    <SelectWithPlaceholderRaw {...props} ref={ref} />
));

export default SelectWithPlaceholder;
