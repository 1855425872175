import { ComponentPropsWithoutRef, ComponentType, SVGProps } from 'react';
import classnames from 'classnames';

import IconColor from './colors';

import styles from './icon.less';

export interface IconProps extends ComponentPropsWithoutRef<'svg'> {
    initial?: IconColor;
    highlighted?: IconColor;
    source?: string;
}

type IconComponent = ComponentType<SVGProps<SVGSVGElement>>;
type IconComponentWrapped = ComponentType<IconProps>;

const LOADER_COMPONENT_NAME = 'Loader';

const hoc = (Icon: IconComponent, iconView: string): IconComponentWrapped => {
    const IconWrapper: IconComponentWrapped = ({ initial, highlighted, ...props }) => (
        <Icon
            {...props}
            className={classnames(styles['bloko-icon'], {
                [styles['bloko-icon_loader']]: iconView === LOADER_COMPONENT_NAME,
                [styles[`bloko-icon_initial-${initial}`]]: !!initial,
                [styles[`bloko-icon_highlighted-${highlighted}`]]: !!highlighted,
            })}
        />
    );

    return IconWrapper;
};

export default hoc;
