import classnames from 'classnames';

import { ComponentWithCustomElement } from 'bloko/common/helpers/types';

import styles from 'bloko/blocks/stepwise/stepwise.less';

interface StepwiseStepProps {
    /** Заголовок */
    title?: string;
    /** Текст под заголовком */
    info?: string;
    /** Запрет переноса текста */
    infoNoWrap?: boolean;
    /** Завершенный шаг */
    done?: boolean;
    /** Текущий шаг */
    current?: boolean;
    /** Шаг в виде ссылки, реагирует на наведение */
    link?: boolean;
    /** Только с иконкой */
    iconOnly?: boolean;
    /** Кастомный компонент функция или 'span', 'a' */
    Element?: 'span' | 'a';
    /** Указывает на строку с компонентом в исходном коде в режиме разработки. Генерируется babel-plugin-react-source */
    source?: string;
}

/**
 * Вспомогательный компонент для [Stepwise](#stepwise), рендерит один шаг.
 */
const StepwiseStep: ComponentWithCustomElement<StepwiseStepProps, 'span'> = ({
    title,
    info,
    infoNoWrap,
    done,
    current,
    link,
    iconOnly,
    Element = 'span',
    source,
    ...stepwiseStepProps
}) => {
    return (
        <Element
            className={classnames(styles['bloko-stepwise-step'], {
                [styles['bloko-stepwise-step_done']]: done,
                [styles['bloko-stepwise-step_current']]: current,
                [styles['bloko-stepwise-step_link']]: link,
                [styles['bloko-stepwise-step_icon-only']]: iconOnly,
            })}
            source={source}
            {...stepwiseStepProps}
        >
            {title && <span className={styles['bloko-stepwise-step__title']}>{title}</span>}
            {info && (
                <span
                    className={classnames(styles['bloko-stepwise-step__info'], {
                        [styles['bloko-stepwise-step__info_nowrap']]: infoNoWrap,
                    })}
                >
                    {info}
                </span>
            )}
        </Element>
    );
};

export default StepwiseStep;
