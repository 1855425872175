import { Fragment, FC } from 'react';
import classnames from 'classnames';

import { WEEKDAYS_ORDER } from 'bloko/blocks/calendar/constants';
import { toISO, ClickDate } from 'bloko/blocks/calendar/datesHelper';
import { EnrichIfPresentWithOptionName } from 'bloko/blocks/calendar/helper';
import { CalendarTranslator } from 'bloko/blocks/calendar/translations';

import styles from 'bloko/blocks/calendar/calendar.less';

export interface DayPickerProps {
    initialDates: EnrichIfPresentWithOptionName;
    translate: CalendarTranslator;
    onClick?: ClickDate;
    showInterval?: boolean;
}

const DayPicker: FC<DayPickerProps> = ({ initialDates, translate, onClick, showInterval }) => (
    <Fragment>
        <div className={styles['bloko-calendar__weekdays']}>
            {WEEKDAYS_ORDER.map((weekday) => (
                <span key={weekday} className={styles['bloko-calendar__weekday']}>
                    {translate.weekday[weekday]}
                </span>
            ))}
        </div>

        <div className={styles['bloko-calendar__days']}>
            {initialDates.map(({ date, isDisabled, isHighlighted, isWeekend, isToday, isBegin, isEnd }) => (
                <span
                    key={date.getTime()}
                    className={classnames(styles['bloko-calendar__day'], {
                        [styles['bloko-calendar__day_disabled']]: isDisabled || onClick === undefined,
                        [styles['bloko-calendar__day_weekend']]: isWeekend,
                        [styles['bloko-calendar__day_selected']]: isHighlighted && !showInterval,
                        [styles['bloko-calendar__day_begin']]: isBegin,
                        [styles['bloko-calendar__day_end']]: isEnd,
                        [styles['bloko-calendar__day_interval']]: showInterval && isHighlighted && !(isEnd || isBegin),
                        [styles['bloko-calendar__day_today']]: isToday,
                    })}
                    onClick={() => !isDisabled && onClick?.(toISO(date))}
                    data-qa={toISO(date)}
                >
                    {date.getDate()}
                </span>
            ))}
        </div>
    </Fragment>
);

export default DayPicker;
