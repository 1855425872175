import $ from 'jquery';

import { IconColor, ChevronScaleSmallKindDown, ChevronScaleSmallKindRight } from 'bloko/blocks/icon';

import dataQa from 'bloko/blocks/treeSelector/dataQa';

export default function (modelsHandler) {
    modelsHandler.on('change:selected', (model) => {
        model.view.input.checked = model.attributes.selected;
    });

    modelsHandler.on('change:expanded', (model) => {
        if (!model.view.$icon) {
            model.view.$icon = $(model.view.icon);
        }

        if (!model.view.$list) {
            model.view.$list = $(model.view.list);
        }
        cacheItemNode(model);

        if (model.attributes.expanded) {
            model.view.$el.append(model.view.$list);
        } else {
            model.view.$list.detach();
        }

        getVisibleDescendants(model).forEach((model) => {
            if (model.view.input) {
                model.view.input.disabled = !!model.attributes.disabled;
            }
        });

        model.view.iconComponent?.change?.({
            IconComponent: model.attributes.expanded ? ChevronScaleSmallKindDown : ChevronScaleSmallKindRight,
            iconProps: {
                initial: IconColor.Gray60,
                highlighted: IconColor.Gray50,
            },
        });

        model.view.$list.toggleClass('g-hidden');
        model.view.$el.attr(
            'data-qa',
            `${dataQa.item} ${dataQa.item}-${model.attributes.id} ${model.attributes.expanded ? dataQa.expanded : ''}`
        );
    });

    modelsHandler.on('change:indeterminate', (model) => {
        model.view.input.indeterminate = model.attributes.indeterminate;
    });

    modelsHandler.on('change:disabled', (model) => {
        if (model.view.input && isVisible(model)) {
            model.view.input.disabled = !!model.attributes.disabled;
        }
    });

    modelsHandler.on('change:hidden', (model) => {
        cacheItemNode(model);
        if (!model.attributes.parent) {
            model.view.$el.toggleClass('g-hidden', model.attributes.hidden);
            return;
        }

        if (model.attributes.hidden) {
            model.view.$el.detach();
        } else {
            $(model.attributes.parent.view.list).append(model.view.$el);
        }
    });

    function cacheItemNode(model) {
        if (!model.view.$el) {
            model.view.$el = $(model.view.el);
        }
    }

    function getVisibleDescendants(model) {
        const items = model.attributes.items;
        if (!model.attributes.expanded || !items || !items.length) {
            return [];
        }

        const nestedVisibleModels = items.reduce((result, model) => {
            const descendants = getVisibleDescendants(model);
            if (descendants.length) {
                Array.prototype.push.apply(result, descendants);
            }
            return result;
        }, []);

        return items.concat(nestedVisibleModels);
    }

    function isVisible(model) {
        let parent = model.attributes.parent;
        while (parent) {
            if (!parent.attributes.expanded) {
                return false;
            }
            parent = parent.attributes.parent;
        }
        return true;
    }
}
