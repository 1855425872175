import { ComponentWithCustomElement } from 'bloko/common/helpers/types';

import styles from 'bloko/blocks/form/form.less';

interface FormHintProps {
    /** Кастомный компонент или ('div', 'span', 'label')*/
    Element?: 'div' | 'span' | 'label';
}
const FormHint: ComponentWithCustomElement<FormHintProps, 'div'> = ({ Element = 'div', ...formHintProps }) => (
    <Element className={styles['bloko-form-hint']} {...formHintProps} />
);

export default FormHint;
