import { useEffect, useState, useCallback, useRef, memo } from 'react';

import TagList from 'bloko/blocks/tagList';
import Tag from 'bloko/blocks/tagList/tag';
import { filterTreeByIdsWithParents } from 'bloko/common/tree/treeCollectionHelper';
import { AdditionalDefault } from 'bloko/common/tree/types';

import { CompositeSelectionTagListProps, TagModel } from 'bloko/blocks/compositeSelection/types';

import { EMPTY_ARRAY } from './const';

interface CompositeSelectionTagListComponent {
    <A extends AdditionalDefault>(props: CompositeSelectionTagListProps<A>): JSX.Element;
}

const defaultCrossedOutValueTransformation = (text: string) => `- ${text}`;

const CompositeSelectionTagList: CompositeSelectionTagListComponent = ({
    value = EMPTY_ARRAY,
    crossedOutValue = EMPTY_ARRAY,
    collection,
    nested = false,
    stretched,
    removable = true,
    removeItems,
    renderNestedTag = ({ item: { text } }) => text,
    renderNestedTagHeader = ({ text }) => text,
    crossedOutValueNameTransformation = defaultCrossedOutValueTransformation,
    renderAfterTags,
}) => {
    const getTagsFromValue = useCallback(() => {
        if (!collection || !collection.getTopLevel().length) {
            return [];
        }
        const tags = nested ? filterTreeByIdsWithParents(collection, value) : collection.getExistModels(value);
        const crossedTags = collection.getExistModels(crossedOutValue).map((model) => ({
            ...model,
            text: crossedOutValueNameTransformation(model.text),
        }));

        return [...tags, ...crossedTags] as TagModel[];
    }, [value, collection, nested, crossedOutValue, crossedOutValueNameTransformation]);

    const [tags, setTags] = useState(getTagsFromValue());
    const firstRender = useRef(true);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        setTags(getTagsFromValue());
    }, [getTagsFromValue]);

    const handleTagToggle = useCallback(
        (id: string) => {
            setTags(tags.map((item) => (item.id !== id ? item : { ...item, collapsed: !item.collapsed })));
        },
        [tags]
    );

    if (nested)
        return (
            <TagList
                nested
                removable={removable}
                renderAfterTags={renderAfterTags}
                items={tags.map(({ id, text, collapsed, items = EMPTY_ARRAY }) => (
                    <Tag
                        key={id}
                        expanded={!collapsed}
                        onRemove={() => removeItems(items.map(({ id }) => id).concat([id]))}
                        onToggle={() => handleTagToggle(id)}
                        items={items.map((item) => (
                            <Tag key={item.id} expanded onRemove={() => removeItems([item.id])}>
                                {renderNestedTag({ id, item })}
                            </Tag>
                        ))}
                    >
                        {renderNestedTagHeader({ id, text })}
                    </Tag>
                ))}
            />
        );
    return (
        <TagList
            stretched={stretched}
            removable={removable}
            renderAfterTags={renderAfterTags}
            items={tags.map(({ id, text }) => (
                <Tag key={id} onRemove={() => removeItems([id])}>
                    {text}
                </Tag>
            ))}
        />
    );
};

export default memo(CompositeSelectionTagList);
