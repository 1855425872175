import { forwardRef, ReactNode, Ref } from 'react';
import classnames from 'classnames';

import styles from 'bloko/blocks/selectDropdownOption/selectDropdownOption.less';

interface SelectDropdownOptionProps {
    /** Содержимое опции */
    children?: ReactNode;
    /** Элемент списка выбран */
    selected?: boolean;
    /** Элемент списка заблокирован */
    disabled?: boolean;
    /** Элемент списка сфокусирован */
    focused?: boolean;
    /** Элемент предоставляет все стили focused по hover */
    focusableByHover?: boolean;
    /** Указывает на строку с компонентом в исходном коде в режиме разработки. Генерируется babel-plugin-react-source */
    source?: string;
    /** Атрибут role */
    role?: 'option';
    /** Функция для получения рутового DOM элемента */
    ref?: Ref<HTMLDivElement>;
    /** Обработчик клика */
    onClick?: () => void;
    /** Обработчик события мыши */
    onMouseEnter?: () => void;
}

const SelectDropdownOption = forwardRef<HTMLDivElement, SelectDropdownOptionProps>(
    ({ children, selected, disabled, focused, focusableByHover, source, ...otherProps }, ref) => (
        <div
            {...otherProps}
            ref={ref}
            className={classnames(styles['bloko-select-dropdown-option'], {
                [styles['bloko-select-dropdown-option_selected']]: selected,
                [styles['bloko-select-dropdown-option_disabled']]: disabled,
                [styles['bloko-select-dropdown-option_focused']]: focused,
                [styles['bloko-select-dropdown-option_focusable-by-hover']]: focusableByHover,
            })}
            source={source}
        >
            {children}
        </div>
    )
);

export default SelectDropdownOption;
