import Backbone from 'backbone';
import $ from 'jquery';
import _ from 'underscore';

import Swipe from 'bloko/blocks/swipe/swipe';
import Components from 'bloko/common/core/Components';

const Slide = Backbone.View.extend({
    /**
     * @param {Object} options
     * @param {Boolean} [options.lazyLoad=false]                Используется ли ленивая загрузка
     *                                                          контента для элементов слайдера
     * @param {Number} [options.transitionTiming=500]           Время анимации для возврата контента назад или
     *                                                          переключения контента
     * @param {function} [options.changeItemCallback=undefined] Функция для смены текущего элемента
     * @constructor
     */
    initialize(options) {
        this.swipe = Components.make(Swipe, this.$el[0], options.swipeOptions);
        this.$container = this.$('.Bloko-Slide-Container');
        this.$current = this.$('.Bloko-Slide-Current');
        this.lazyLoad = options.lazyLoad;
        this.changeItemCallback = options.changeItemCallback;
        this.transitionTiming = options.transitionTiming;

        this.swipe
            .on('bloko-swipe-move', (event) => {
                this.$current.css('transform', `translate3d( ${event.distance}px, 0, 0)`);
            })
            .on('bloko-swipe-end', this.swipeItem.bind(this))
            .on('bloko-swipe-prevent', this.setStartState.bind(this));
    },

    setStartState() {
        const releaseTransition = function () {
            this.$current.css('transition', '');
        }.bind(this);

        this.$current.css('transition', `all ${this.transitionTiming}ms ease`);
        this.$current.css('transform', 'translate3d(0, 0, 0)');
        this.$current.one('transitionend', releaseTransition);
    },

    // Механика смены слайда - слайд появляется в углу блока и переезжает в середину.
    animateSwipe(direction) {
        let offset = (this.$container.width() + this.$current.width()) / 2;

        if (direction < 0) {
            offset = -offset;
        }

        this.$current.css('transform', `translate3d(${offset}px, 0, 0)`);

        window.setTimeout(this.setStartState.bind(this), 0);
    },

    swipeItem(event) {
        const direction = event.direction;
        if (this.lazyLoad) {
            const result = this.changeItemCallback(direction);

            // Добавляем поддержку для thenable объектов
            // (если для получения следующего элемента, нужно сделать асинхронные действия)
            if (result.then) {
                result.then((data) => {
                    this.$current = $(data);
                    this.animateSwipe(direction);
                });
                return;
            }

            this.$current = $(result);
            this.animateSwipe(direction);
            return;
        }

        const $items = this.$('.Bloko-Slide-Item');
        let index = $items.index(this.$current) + direction;
        if (index >= $items.length) {
            index = 0;
        }
        if (index < 0) {
            index = $items.length - 1;
        }
        this.$current.removeClass('Bloko-Slide-Current');
        this.$current.addClass('g-hidden');
        this.$current = $items.eq(index);
        this.$current.removeClass('g-hidden');
        this.$current.addClass('Bloko-Slide-Current');

        this.animateSwipe(direction);
    },
});

export default Components.build({
    defaults: {
        lazyLoad: false,
        transitionTiming: 500,
        swipeOptions: undefined,
    },
    create(element, params) {
        new Slide(_.extend({ el: element }, params));
    },
});
