import { useCallback, useState, cloneElement, FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

import TreeSelector, { isValidTreeSelector } from 'bloko/blocks/treeSelector';
import TreeSelectorDummy from 'bloko/blocks/treeSelector/Dummy';

import dataQa from 'bloko/blocks/treeSelectorPopup/dataQa';

import styles from 'bloko/blocks/treeSelectorPopup/treeSelectorPopup.less';

interface PopupTreeSelectorProps {
    /** Обработчик изменения строки contentFilterQuery
     * В аргументах получает список подходящих под запрос id и сам запрос*/
    onChangeFilterQuery?: (ids: string[], query: string) => void;
    /** Обработчик изменения набора выбранных ID.
     * В аргументах получает `{String} id`, `{Boolean} isSelected`, `{Array} allSelected`. */
    onChange: (id: string, isSelected: boolean, selected?: string[]) => void;
    /** Обработчик изменения состояния раскрытия элементов
     * В аргументах получает список id раскрытых элементов `{Array} expanded` */
    onExpand: (expanded: string[]) => void;
    selected: string[];
    expanded: string[];
    controlledSelected: string[];
    contentFilterQuery: string;
    /** Перевод - сообщение о том, что по поисковому запросу ничего не найдено */
    trlNotFound?: string;
}

const PopupTreeSelector: FC<PopupTreeSelectorProps & PropsWithChildren> = ({
    onChangeFilterQuery,
    onChange,
    onExpand,
    selected,
    expanded,
    controlledSelected,
    contentFilterQuery,
    trlNotFound = '',
    children,
}) => {
    const [notFound, setNotFound] = useState(false);

    const handleChangeFilterQuery = useCallback(
        (visibleIds: string[], query: string) => {
            setNotFound(!visibleIds.length);
            onChangeFilterQuery?.(visibleIds, query);
        },
        [onChangeFilterQuery]
    );

    if (!isValidTreeSelector(children)) {
        throw new Error('Children must be TreeSelector or TreeSelectorDummy');
    }

    const showSelectedList = children.props.showSelectedList;

    return (
        <div
            className={classnames(styles['bloko-tree-selector-popup'], {
                [styles['bloko-tree-selector-popup__with-selected']]: showSelectedList,
            })}
        >
            {notFound && trlNotFound && (
                <div className={styles['bloko-tree-selector-popup-not-found']} data-qa={dataQa.notFound}>
                    {trlNotFound}
                </div>
            )}

            {isValidTreeSelector(children) &&
                cloneElement(children, {
                    onChange,
                    onExpand,
                    ...(children.type === TreeSelector && { initialSelected: selected }),
                    initialExpanded: expanded,
                    ...(children.type === TreeSelectorDummy && { selected: controlledSelected }),
                    contentFilterQuery,
                    onChangeFilterQuery: handleChangeFilterQuery,
                    wrapperTreeProps: {
                        className: classnames(styles['bloko-tree-selector-popup-content'], {
                            [styles['bloko-tree-selector-popup-content__with-selected']]: showSelectedList,
                        }),
                    },
                })}
        </div>
    );
};

export default PopupTreeSelector;
