import { forwardRef } from 'react';

function enhance(mockComponent, staticProps) {
    // удаляем внутренний свойства реакта, чтобы не перезаписать ими mockComponent
    delete staticProps.type;
    delete staticProps.$$typeof;
    delete staticProps.render;

    for (const prop in staticProps) {
        mockComponent[prop] = staticProps[prop];
    }
}

/**
 * Возвращает mock React компонента
 */
const mockComponent = (componentName, staticProps, { withChildren, innerDependence } = {}) => {
    // eslint-disable-next-line no-unused-vars
    const mockWrapper = forwardRef(({ children, ...props }, ref) => {
        return (
            <bloko-component-mock {...props} _component={componentName}>
                {innerDependence ? innerDependence(props, ref) : null}
                {withChildren ? children : null}
            </bloko-component-mock>
        );
    });
    staticProps && enhance(mockWrapper, staticProps);

    mockWrapper.displayName = 'bloko-ref';
    return mockWrapper;
};

/**
 * Возвращает mock React компонента, который рендерит переданный в него пропс render
 *
 * @param {String}              componentName               наименование компонента
 * @param {Object}              [staticProps]               объект со свойствами, которыми необходимо усилить mock
 * @param {Object}              [options]                   параметры мока
 * @param {boolean}             [options.withChildren]      флаг отвечает за рендеринг children компонента
 * @param {React.Component}     [options.innerDependence]   Реакт компонент, который рендерится внутри мока.
 *                                                          Необходим, когда mock компоненту требуется иметь
 *                                                           дополнительные элементы
 */
const mockComponentRenderProp = (
    componentName,
    staticProps,
    { withChildren, innerDependence, renderArguments } = { renderArguments: [] }
) => {
    const mockWrapper = ({ render, children, ...props }) => (
        <bloko-component-mock-render-prop {...props} _component={componentName}>
            {innerDependence ? innerDependence(props) : null}
            {withChildren ? children : null}
            {render?.(...(renderArguments || []))}
        </bloko-component-mock-render-prop>
    );
    staticProps && enhance(mockWrapper, staticProps);

    mockWrapper.displayName = 'bloko-component-mock-render-prop';
    return mockWrapper;
};

export { mockComponentRenderProp, mockComponent };
export default {};
