import { ComponentWithCustomElement } from 'bloko/common/helpers/types';

import styles from 'bloko/blocks/modal/modal.less';

interface ModalTitleProps {
    /** Кастомный компонент 'span', 'h1', 'h2', 'div' или функция */
    Element?: 'span' | 'h1' | 'h2' | 'div';
    /** Указывает на строку с компонентом в исходном коде в режиме разработки. Генерируется babel-plugin-react-source */
    source?: string;
}

/**
 * Надпись в заголовке модального окна
 */
const ModalTitle: ComponentWithCustomElement<ModalTitleProps, 'span'> = ({
    Element = 'span',
    source,
    ...modalTitleProps
}) => {
    return <Element {...modalTitleProps} className={styles['bloko-modal-title']} source={source} />;
};

export default ModalTitle;
