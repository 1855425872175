import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const BarsScaleSmall = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M3.33337 8H12.6667M3.33337 4H12.6667M3.33337 12H12.6667"
                stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                strokeWidth={1.33333}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ),
    'Bars'
);
export default BarsScaleSmall;
