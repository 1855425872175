import { useCallback, useMemo, ReactNode, HTMLAttributes, isValidElement, ReactElement } from 'react';
import classnames from 'classnames';

import DropBase from 'bloko/blocks/drop/Base';
import { defaultProps, BASE_CLASS_NAMES, BEHAVIOR } from 'bloko/blocks/drop/Menu/common';
import { DropLayer, DropPlacement } from 'bloko/blocks/drop/constants';
import useInputState from 'bloko/common/hooks/useInputState';

import CustomSelectOption, { CustomSelectOptionProps } from 'bloko/blocks/customSelect/CustomSelectOption';
import CustomSelectOptionSecondaryText from 'bloko/blocks/customSelect/CustomSelectOptionSecondaryText';
import { dataQa } from 'bloko/blocks/customSelect/constants';
import CustomSelectContext from 'bloko/blocks/customSelect/context';
import useDropResize from 'bloko/blocks/customSelect/hooks/useDropResize';
import useFilteredOptions, { AsyncFilterFunctionType } from 'bloko/blocks/customSelect/hooks/useFilteredOptions';
import useFocusInput from 'bloko/blocks/customSelect/hooks/useFocusInput';
import useFocusedValue from 'bloko/blocks/customSelect/hooks/useFocusedValue';
import useKeyboardControls from 'bloko/blocks/customSelect/hooks/useKeyboardControls';
import useScrollOptionList from 'bloko/blocks/customSelect/hooks/useScrollOptionList';
import useToggleExpanded from 'bloko/blocks/customSelect/hooks/useToggleExpanded';

import styles from 'bloko/blocks/customSelect/customSelect.less';

// Копируем поведение меню за исключением closeByClickOutside
const defaultMenuProps = {
    ...defaultProps,
    behavior: BEHAVIOR,
    baseClassNames: BASE_CLASS_NAMES,
    host: null,
    placement: DropPlacement.BottomStart,
};

const DEFAULT_THROTTLE_SEARCH_MS = 200;

type RenderPlaceholderProps<T extends CustomSelectValue> = Partial<CustomSelectProps<T>> & {
    filteredOptions: ReactElement<CustomSelectOptionProps>[];
};
const renderPlaceholder = <T extends CustomSelectValue>({
    placeholderTemplate,
    filteredOptions,
    value,
    emptyPlaceholder,
}: RenderPlaceholderProps<T>) => {
    if (!value && emptyPlaceholder) {
        return emptyPlaceholder;
    }
    if (value && placeholderTemplate) {
        return placeholderTemplate(value);
    }
    const option = filteredOptions.find((child) => isValidElement(child) && child.props.value === value);
    return isValidElement(option) && option.props.children;
};

export type CustomSelectValue = string | number;
export type CustomSelectOptionOrArray<T> =
    | ReactElement<CustomSelectOptionProps<T>>
    | ReactElement<CustomSelectOptionProps<T>>[];

export interface CustomSelectProps<T = CustomSelectValue> {
    /** Список [CustomSelectOption](/#customSelectOption) */
    children: CustomSelectOptionOrArray<T>;
    /** Значение выбранного option для controlled компонента*/
    value?: T;
    /** Атрибут name */
    name?: string;
    /** Значение выбранного option для uncontrolled компонента*/
    defaultValue?: T;
    /** Обработчик onChange, в качестве аргумента передает value */
    onChange?: (value: T) => void;
    /** Функция рендера плейсхолдера выбранного */
    placeholderTemplate?: (value: T) => ReactNode;
    /** Флаг неактивности */
    disabled?: boolean;
    /** Флаг невалидности */
    invalid?: boolean;
    /** Флаг отсутсвия рамок */
    light?: boolean;
    /** Флаг размера контрола, размер по контенту */
    flexible?: boolean;
    /** Флаг состояния селекта - закрыт/открыт */
    expanded?: boolean;
    /** Автоматическое закрытие при клике вне селекта */
    autoClose?: boolean;
    /** Колбек при открытии селекта */
    onOpen?: () => void;
    /** Колбек при закрытии селекта */
    onClose?: () => void;
    /** Колбек при фокусе */
    onFocus?: () => void;
    /** Колбек при потере фокуса */
    onBlur?: () => void;
    /** Свойства обертки CustomSelect */
    wrapperProps?: HTMLAttributes<HTMLDivElement>;
    /** z-index слой контента селекта */
    layer?: DropLayer;
    /** Поиск по вариантам выбора */
    search?: boolean;
    /** Плейсхолдер поиска */
    searchPlaceholder?: string;
    /** Функция для фильтрации */
    searchFilterFunction?: AsyncFilterFunctionType;
    /** Задержка для фильтрации */
    searchFilterThrottleMs?: number;
    /** Плейсхолдер пустого состояния */
    emptyPlaceholder?: string;
    /** Функция рендера блока после списка вариантов */
    afterOptionsTemplate?: () => ReactNode;
    /** Заголовок меню для XS. Отображается если передан */
    title?: string;
    /** Указывает на строку с компонентом в исходном коде в режиме разработки. Генерируется babel-plugin-react-source */
    source?: string;
}

const CustomSelect = <T extends CustomSelectValue = CustomSelectValue>({
    value: controlledValue,
    defaultValue,
    onChange: onChangeProp,
    wrapperProps,
    layer = DropLayer.AboveContent,
    disabled,
    invalid,
    light,
    flexible,
    expanded,
    autoClose = true,
    children,
    source,
    search,
    onOpen,
    onClose,
    onFocus,
    onBlur,
    searchPlaceholder,
    emptyPlaceholder,
    placeholderTemplate,
    afterOptionsTemplate,
    searchFilterFunction,
    searchFilterThrottleMs = DEFAULT_THROTTLE_SEARCH_MS,
    title,
    ...selectProps
}: CustomSelectProps<T>): ReactElement | null => {
    const { selectInputRef, dropStyle } = useDropResize();
    const { optionsListRef, scrollTo } = useScrollOptionList();
    const { isFocused, setInputFocus, setInputBlur } = useFocusInput({ onFocus, onBlur });
    const { value, onChange } = useInputState<T>({ controlledValue, defaultValue, onChangeProp });
    const { filteredOptions, renderSearchField, setFilterQuery } = useFilteredOptions<T>({
        children,
        search,
        searchPlaceholder,
        title,
        filterThrottleMs: searchFilterThrottleMs,
        asyncFilterFunction: searchFilterFunction,
    });
    const { focusedValue, setFocusedValue, moveFocusedValue, optionValues } = useFocusedValue<T>({ filteredOptions });
    const { isExpanded, toggleExpanded } = useToggleExpanded({
        expanded,
        disabled,
        optionValues,
        setFocusedValue,
        value,
        onOpen,
        onClose,
        selectInputRef,
    });

    const selectValue = useCallback(
        (value: T) => {
            onChange(value);
            setFilterQuery('');
            toggleExpanded(false);
        },
        [onChange, setFilterQuery, toggleExpanded]
    );

    useKeyboardControls<T>({
        focusedValue,
        moveFocusedValue,
        isExpanded,
        toggleExpanded,
        isFocused,
        selectValue,
    });

    const API = useMemo(
        () => ({
            focusedValue,
            selectedValue: value,
            selectValue,
            setFocusedValue,
            scrollTo,
        }),
        [focusedValue, scrollTo, selectValue, setFocusedValue, value]
    );

    const renderOptions = useCallback(
        () => (
            <div style={dropStyle}>
                {renderSearchField()}
                <div
                    className={styles['bloko-custom-select__option-list']}
                    ref={optionsListRef}
                    data-qa={dataQa.optionList}
                >
                    {filteredOptions}
                </div>
                {afterOptionsTemplate ? (
                    <div className={styles['bloko-custom-select__after-options']}>{afterOptionsTemplate()}</div>
                ) : null}
            </div>
        ),
        [afterOptionsTemplate, dropStyle, filteredOptions, optionsListRef, renderSearchField]
    );

    return (
        <CustomSelectContext.Provider value={API}>
            <div
                data-qa={dataQa.wrapper}
                {...wrapperProps}
                className={classnames(styles['bloko-custom-select'], {
                    [styles['bloko-custom-select_disabled']]: disabled,
                    [styles['bloko-custom-select_invalid']]: invalid,
                    [styles['bloko-custom-select_light']]: light,
                    [styles['bloko-custom-select_flexible']]: flexible,
                })}
                onFocus={setInputFocus}
                onBlur={setInputBlur}
                tabIndex={0}
                source={source}
                ref={selectInputRef}
            >
                <DropBase
                    {...defaultMenuProps}
                    show={isExpanded}
                    onClose={() => toggleExpanded(false)}
                    closeByClickOutside={autoClose}
                    layer={layer}
                    title={title}
                    render={renderOptions}
                    stretchOnXS={search}
                    flexible
                >
                    <div
                        className={classnames(styles['bloko-custom-select__select'], {
                            [styles['bloko-custom-select__select_focus']]: isFocused || isExpanded,
                        })}
                        data-qa={dataQa.select}
                        onClick={() => toggleExpanded()}
                    >
                        <div
                            className={classnames(styles['bloko-custom-select__placeholder'], {
                                [styles['bloko-custom-select__placeholder_empty']]: !value,
                            })}
                            suppressHydrationWarning
                        >
                            {renderPlaceholder({ placeholderTemplate, filteredOptions, value, emptyPlaceholder })}
                        </div>
                    </div>
                </DropBase>
                <input data-qa={dataQa.hiddenInput} {...selectProps} type="hidden" value={value || ''} readOnly />
            </div>
        </CustomSelectContext.Provider>
    );
};

export default CustomSelect;
export { CustomSelectOption, CustomSelectOptionSecondaryText, dataQa, DropLayer as CustomSelectLayer };
