import { ChangeEvent, FC, Ref, PropsWithChildren } from 'react';
import { Merge } from 'type-fest';

import styles from 'bloko/blocks/chips/chip.less';

export interface ChipProps {
    /** Обработчик onChange, в качестве аргумента передает event */
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    /** Функция для получения рутового DOM элемента компонента */
    innerRef?: Ref<HTMLLabelElement>;
    /** Свойства лейбла */
    labelProps?: JSX.IntrinsicElements['span'];
    /** Свойства обертки */
    wrapperProps?: JSX.IntrinsicElements['label'];
    /** Флаг checked контрола по умолчанию для uncontrolled */
    defaultChecked?: boolean;
    /** Флаг checked контрола */
    checked?: boolean;
    /** Значение по умолчанию для uncontrolled */
    defaultValue?: string;
    /** Значение по умолчанию */
    value?: string;
    /** Флаг disabled контрола */
    disabled?: boolean;
    /** Указывает на строку с компонентом в исходном коде в режиме разработки. Генерируется babel-plugin-react-source */
    source?: string;
}

const Chip: FC<Merge<JSX.IntrinsicElements['input'], ChipProps> & PropsWithChildren> = (props) => {
    const { children, innerRef, labelProps, wrapperProps, source, ...inputProps } = props;

    return (
        <label className={styles['bloko-chips-chip']} {...wrapperProps} ref={innerRef} source={source}>
            <input {...inputProps} type="radio" className={styles['bloko-chips-chip__input']} />
            <span className={styles['bloko-chips-chip__content']} {...labelProps}>
                {children}
            </span>
        </label>
    );
};

export default Chip;
