import { memo, KeyboardEvent, FC } from 'react';

import {
    IconDynamic,
    IconLink,
    IconColor,
    ChevronScaleSmallKindDown,
    ChevronScaleSmallKindRight,
} from 'bloko/blocks/icon';
import dataQa from 'bloko/blocks/treeSelector/dataQa';
import { TreeModel } from 'bloko/common/tree/types';

import styles from 'bloko/blocks/treeSelector/treeSelector.less';

interface ElementIconProps {
    /** ID элемента. */
    id: TreeModel['id'];
    /** Элемент с инпутом. */
    hasAction: boolean;
    /** Элемент открыт. */
    expanded: boolean;
    onKeyDown: (e: KeyboardEvent) => void;
    onClick: () => void;
}

const ElementIcon: FC<ElementIconProps> = ({ id, hasAction, expanded, onKeyDown, onClick }) => {
    const nonActionProps = hasAction ? {} : { tabIndex: 0, onKeyDown };

    return (
        <span className={styles['bloko-tree-selector-item-spacer']}>
            <IconDynamic>
                <IconLink
                    Element="span"
                    onClick={onClick}
                    data-qa={`${dataQa.toggle} ${dataQa.toggle}-${id}`}
                    {...nonActionProps}
                >
                    {expanded ? (
                        <ChevronScaleSmallKindDown initial={IconColor.Gray60} highlighted={IconColor.Gray50} />
                    ) : (
                        <ChevronScaleSmallKindRight initial={IconColor.Gray60} highlighted={IconColor.Gray50} />
                    )}
                </IconLink>
            </IconDynamic>
        </span>
    );
};

export default memo(ElementIcon);
