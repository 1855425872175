import { useEffect, useState, useRef, FC, PropsWithChildren } from 'react';

import VSpacing from 'bloko/blocks/vSpacing';

interface VersionCheckerProps {
    forceReload: (needReload: boolean) => void;
}

const VersionChecker: FC<VersionCheckerProps & PropsWithChildren> = ({ forceReload }) => {
    const blokoSSRRef = useRef<HTMLDivElement>(null);
    const [renderClient, setRenderClient] = useState(false);
    const BASE = 1;

    useEffect(() => {
        setRenderClient(true);
    }, []);

    return (
        <div style={{ display: 'none' }}>
            <VSpacing ref={blokoSSRRef} base={BASE} />
            {renderClient && (
                <VSpacing
                    base={BASE}
                    ref={(el: HTMLDivElement) => {
                        if (el && el?.className !== blokoSSRRef?.current?.className) {
                            forceReload(true);
                        }
                    }}
                />
            )}
        </div>
    );
};

export default VersionChecker;
