import { MONTHS, WEEKDAYS, MAX_DATES_IN_CALENDAR, WEEKDAYS_AMOUNT, WEEKENDS } from 'bloko/blocks/calendar/constants';
import {
    startOfMonth,
    subDays,
    addMonths,
    lastDayOfMonth,
    eachDay,
    toDate,
    toISO,
    YearMonthDayString,
    DateString,
    DateConstructorType,
} from 'bloko/blocks/calendar/datesHelper';
import { CalendarLanguage, CalendarTranslations, CalendarTranslator } from 'bloko/blocks/calendar/translations';

export enum CalendarPickerAndSwitcherKind {
    Day = 'day',
    Month = 'month',
    Year = 'year',
}

export const translator = (language: CalendarLanguage, translations?: CalendarTranslations): CalendarTranslator => ({
    month: translations?.months || MONTHS[language],
    weekday: translations?.weekdays || WEEKDAYS[language],
});

export enum CalendarEnrichOptionName {
    IsHighlighted = 'isHighlighted',
    IsDisabled = 'isDisabled',
    IsWeekend = 'isWeekend',
    IsToday = 'isToday',
    IsBegin = 'isBegin',
    IsEnd = 'isEnd',
}

type EnrichIfPresent<T extends CalendarEnrichOptionName> = {
    [key in T]: boolean;
} & { date: Date };
export type EnrichIfPresentWithOptionName = EnrichIfPresent<CalendarEnrichOptionName>[];

export const enrichIfPresent = <T extends CalendarEnrichOptionName>(
    calendarDates: { date: Date }[],
    dates: Date[],
    optionName: T
): EnrichIfPresent<T>[] => {
    const datesISO = dates.map((date) => toISO(date));
    return calendarDates.map(
        (date) => ({ ...date, [optionName]: datesISO.includes(toISO(date.date)) } as EnrichIfPresent<T>)
    );
};
export type DisabledWeekdays = (0 | 1 | 2 | 3 | 4 | 5 | 6)[];

export const calculateDisabledDates = <T extends CalendarEnrichOptionName>(
    calendarDates: EnrichIfPresent<T>[],
    disabledDates: Date[],
    isDisabledPartMonth: boolean,
    currentDate: Date,
    disabledWeekdays: DisabledWeekdays,
    disableDaysBeforeDate?: DateConstructorType,
    disableDaysAfterDate?: DateConstructorType
): Date[] => {
    const daysBeforeDate = disableDaysBeforeDate
        ? eachDay(calendarDates[0].date, toDate(toISO(new Date(disableDaysBeforeDate))))
              .slice(0, -1)
              .map((date) => toISO(date))
        : [];
    const daysAfterDate = disableDaysAfterDate
        ? eachDay(toDate(toISO(new Date(disableDaysAfterDate))), calendarDates[MAX_DATES_IN_CALENDAR - 1].date)
              .slice(1)
              .map((date) => toISO(date))
        : [];

    return disabledDates.concat(
        calendarDates
            .filter(
                ({ date }) =>
                    (isDisabledPartMonth && date.getMonth() !== currentDate.getMonth()) ||
                    disabledWeekdays.includes(date.getDay()) ||
                    daysBeforeDate.includes(toISO(date)) ||
                    daysAfterDate.includes(toISO(date))
            )
            .map(({ date }) => date)
    );
};

export const calculateWeekends = (dates: { date: Date }[]): Date[] =>
    dates.filter(({ date }) => WEEKENDS.includes(date.getDay())).map(({ date }) => date);

export const prepareCalendarDates = (
    date: Date,
    highlightedDates: Date[] = [],
    disabledDates: DateString[] = [],
    disabledWeekdays: DisabledWeekdays = [],
    disablePartMonth = true,
    disableDaysBeforeDate?: DateConstructorType,
    disableDaysAfterDate?: DateConstructorType,
    showInterval?: boolean
): EnrichIfPresent<CalendarEnrichOptionName>[] => {
    const daysAmount = (startOfMonth(date).getDay() || WEEKDAYS_AMOUNT) - 1;
    const startDate = subDays(startOfMonth(date), daysAmount);
    const endDate = addMonths(lastDayOfMonth(date), 1);

    let dates = eachDay(startDate, endDate)
        .slice(0, MAX_DATES_IN_CALENDAR)
        .map((date) => ({ date }));

    dates = enrichIfPresent(dates, highlightedDates, CalendarEnrichOptionName.IsHighlighted);
    dates = enrichIfPresent(
        dates,
        calculateDisabledDates(
            dates as EnrichIfPresent<CalendarEnrichOptionName>[],
            disabledDates.map((disabledDate) => toDate(disabledDate)),
            disablePartMonth,
            date,
            disabledWeekdays,
            disableDaysBeforeDate,
            disableDaysAfterDate
        ),
        CalendarEnrichOptionName.IsDisabled
    );
    dates = enrichIfPresent(dates, calculateWeekends(dates), CalendarEnrichOptionName.IsWeekend);
    dates = enrichIfPresent(dates, [new Date()], CalendarEnrichOptionName.IsToday);

    if (showInterval) {
        dates = enrichIfPresent(dates, [highlightedDates[0]], CalendarEnrichOptionName.IsBegin);
        dates = enrichIfPresent(dates, [highlightedDates[highlightedDates.length - 1]], CalendarEnrichOptionName.IsEnd);
    }

    return dates as EnrichIfPresent<CalendarEnrichOptionName>[];
};

export const makeHighlightedInterval = ({
    startString,
    endString,
}: {
    startString?: DateString;
    endString?: DateString;
}): YearMonthDayString[] => {
    if (!startString || !endString) {
        return [];
    }
    let start = toDate(startString);
    let end = toDate(endString);
    if (start > end) {
        const startTmp = start;
        start = end;
        end = startTmp;
    }
    return eachDay(start, end).map((date) => toISO(date));
};
