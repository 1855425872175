import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const VkScaleSmallAppearanceColor = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M8.70977 12.6666C3.59933 12.6666 0.684458 9.16314 0.563004 3.33331H3.1229C3.20698 7.61226 5.09415 9.42474 6.58898 9.79844V3.33331H8.99949V7.02367C10.4756 6.86484 12.0263 5.18316 12.5495 3.33331H14.96C14.5582 5.61293 12.8766 7.29461 11.6807 7.98597C12.8766 8.54653 14.7919 10.0133 15.5206 12.6666H12.8672C12.2973 10.8915 10.8774 9.51816 8.99949 9.33131V12.6666H8.70977Z"
                fill="#0077FF"
            />
        </svg>
    ),
    'Vk'
);
export default VkScaleSmallAppearanceColor;
