import { Fragment, useEffect, useRef, FC } from 'react';
import classnames from 'classnames';

import { UPPER_YEAR_LISTS_LIMITS, YEARS_IN_LIST } from 'bloko/blocks/calendar/constants';
import { setYear, PickDate } from 'bloko/blocks/calendar/datesHelper';

import styles from 'bloko/blocks/calendar/calendar.less';

const yearOffsetInList = [...Array(YEARS_IN_LIST).keys()];

export interface YearPickerProps {
    date: Date;
    onPick: PickDate;
}

const YearPicker: FC<YearPickerProps> = ({ date, onPick }) => {
    const yearsRef = useRef<HTMLDivElement>(null);
    const selectedYearRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        const maxYear = UPPER_YEAR_LISTS_LIMITS[0];
        const minYear = UPPER_YEAR_LISTS_LIMITS[UPPER_YEAR_LISTS_LIMITS.length - 1] - YEARS_IN_LIST + 1;

        if (date.getFullYear() > maxYear) {
            if (yearsRef.current) {
                yearsRef.current.scrollTop = 0;
            }
        } else if (date.getFullYear() < minYear) {
            if (yearsRef.current) {
                yearsRef.current.scrollTop = yearsRef.current.scrollHeight;
            }
        } else {
            if (!yearsRef.current || !selectedYearRef.current) {
                return;
            }
            yearsRef.current.scrollTop =
                selectedYearRef.current.offsetTop -
                yearsRef.current.offsetTop -
                yearsRef.current.getBoundingClientRect().height / 2;
        }
    }, [date]);

    return (
        <div ref={yearsRef} className={styles['bloko-calendar__years']}>
            {UPPER_YEAR_LISTS_LIMITS.map((upperLimit) => (
                <Fragment key={upperLimit}>
                    <div className={styles['bloko-calendar__years-range']}>
                        {upperLimit}
                        &#8201;&#8211;&#8201;
                        {upperLimit - YEARS_IN_LIST + 1}
                    </div>
                    <div className={styles['bloko-calendar__years-list']}>
                        {yearOffsetInList.map((offset) => {
                            const year = upperLimit - offset;
                            return (
                                <span
                                    key={year}
                                    ref={date.getFullYear() === year ? selectedYearRef : null}
                                    className={classnames(styles['bloko-calendar__years-list-item'], {
                                        [styles['bloko-calendar__years-list-item_selected']]:
                                            date.getFullYear() === year,
                                    })}
                                    onClick={() => onPick(year, setYear)}
                                >
                                    {year}
                                </span>
                            );
                        })}
                    </div>
                </Fragment>
            ))}
        </div>
    );
};

export default YearPicker;
