import { ReactNode } from 'react';
import classnames from 'classnames';

import { ComponentWithCustomElement } from 'bloko/common/helpers/types';

import SubHeader from 'bloko/blocks/header/SubHeader';

import styles from 'bloko/blocks/header/header.less';

enum HeaderTypes {
    Standard = 'standard',
    Promo = 'promo',
    Section = 'section',
}

type HeaderLevel = 1 | 2 | 3 | 4;

type HeaderProps = {
    /** Содержимое заголовка */
    children: ReactNode;
    /** Служебный проп, уровень заголовка, 4 уровень неприменим для lite=true */
    level?: HeaderLevel;
    /** Служебный проп, опциональный тип вместо стандартного: HeaderTypes.Promo или HeaderTypes.Section */
    type?: HeaderTypes;
    /** Переопределение тега вместо умолчания на основе уровня заголовка */
    Element?: 'span' | 'div' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
    /** Использовать lite версию заголовка */
    lite?: boolean;
    /** Указывает на строку с компонентом в исходном коде в режиме разработки. Генерируется babel-plugin-react-source */
    source?: string;
};

type HeaderComponentProps = Omit<HeaderProps, 'level' | 'type'>;
type HeaderComponent = ComponentWithCustomElement<HeaderComponentProps, 'h1'>;
type HeaderComponentLevel4 = ComponentWithCustomElement<Omit<HeaderComponentProps, 'lite'>, 'h1'>;

type HeaderClassName = `bloko-header-${`${HeaderTypes.Promo | HeaderTypes.Section}-` | ''}${HeaderLevel}`;

const Header: ComponentWithCustomElement<HeaderProps, 'h1'> = ({
    children,
    level = 1,
    type = HeaderTypes.Standard,
    lite,
    Element,
    source,
    ...headerProps
}) => {
    const Tag = Element || `h${level}`;

    const className = `bloko-header-${type !== HeaderTypes.Standard ? `${type}-` : ''}${level}` as HeaderClassName;
    return (
        <Tag
            data-qa={`bloko-header-${level}`}
            {...headerProps}
            className={classnames(styles[className], {
                [styles[`${className}_lite`]]: lite,
            })}
            source={source}
        >
            {children}
        </Tag>
    );
};

export const H1: HeaderComponent = (props) => <Header {...props} />;
export const H2: HeaderComponent = (props) => <Header {...props} level={2} />;
export const H3: HeaderComponent = (props) => <Header {...props} level={3} />;
export const H4: HeaderComponentLevel4 = (props) => <Header {...props} level={4} />;

export const H1Section: HeaderComponent = (props) => <Header {...props} type={HeaderTypes.Section} />;
export const H2Section: HeaderComponent = (props) => <Header {...props} type={HeaderTypes.Section} level={2} />;
export const H3Section: HeaderComponent = (props) => <Header {...props} type={HeaderTypes.Section} level={3} />;
export const H4Section: HeaderComponentLevel4 = (props) => <Header {...props} type={HeaderTypes.Section} level={4} />;

export const H1Promo: HeaderComponent = (props) => <Header {...props} type={HeaderTypes.Promo} />;
export const H2Promo: HeaderComponent = (props) => <Header {...props} type={HeaderTypes.Promo} level={2} />;
export const H3Promo: HeaderComponent = (props) => <Header {...props} type={HeaderTypes.Promo} level={3} />;
export const H4Promo: HeaderComponentLevel4 = (props) => <Header {...props} type={HeaderTypes.Promo} level={4} />;

export { SubHeader };
export default Header;
