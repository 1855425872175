import { TransitionGroup } from 'react-transition-group';

import Fade from 'bloko/blocks/animation/fade';
import Checkbox from 'bloko/blocks/checkbox';
import { FormSeparator } from 'bloko/blocks/form';
import { AdditionalDefault, TreeModel } from 'bloko/common/tree/types';

import styles from 'bloko/blocks/treeSelector/treeSelectorSelectedList.less';

const dataQa = {
    list: 'bloko-tree-selector-selected-list',
    item: 'bloko-tree-selector-selected-list-item',
};

interface ResultListProps<A extends AdditionalDefault> {
    items: TreeModel<A>[];
    onClick?: (id: string, checked: boolean) => void;
}

interface ResultListComponent {
    <A extends AdditionalDefault>(props: ResultListProps<A>): JSX.Element;
}

const ResultList: ResultListComponent = ({ items, onClick }) => {
    return (
        <Fade in={!!items.length} unmountOnExit>
            <div className={styles['bloko-tree-selector-selected']} data-qa={dataQa.list}>
                <div className={styles['bloko-tree-selector-selected-list']} data-qa={dataQa.list}>
                    <TransitionGroup component={null} exit={false}>
                        {items.map((item) => (
                            <Fade key={item.id}>
                                <div data-qa={`${dataQa.item} ${dataQa.item}-${item.id}`}>
                                    <Checkbox checked onChange={({ target }) => onClick?.(item.id, target.checked)}>
                                        {item.text}
                                    </Checkbox>
                                    <FormSeparator />
                                </div>
                            </Fade>
                        ))}
                    </TransitionGroup>
                </div>
            </div>
        </Fade>
    );
};

export default ResultList;
