import { FC, ComponentPropsWithoutRef, PropsWithChildren } from 'react';
import classnames from 'classnames';

import { DocumentedPropsWithChildren } from 'bloko/common/helpers/types';

import styles from 'bloko/blocks/modal/modal.less';

interface ModalErrorProps extends ComponentPropsWithoutRef<'div'> {
    /** Показывать ли ошибку */
    visible?: boolean;
}

/**
 *  Компонент, предназначенный для показа ошибки в модальных окнах
 */

const ModalError: FC<DocumentedPropsWithChildren<ModalErrorProps> & PropsWithChildren> = ({
    visible = false,
    ...modalErrorProps
}) => {
    return (
        <div
            {...modalErrorProps}
            className={classnames(styles['bloko-modal-error'], {
                [styles['bloko-modal-error_hidden']]: !visible,
            })}
        />
    );
};

export default ModalError;
