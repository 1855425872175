import classnames from 'classnames';

import { ComponentWithCustomElement } from 'bloko/common/helpers/types';

import styles from 'bloko/blocks/button/button.less';

interface ButtonGroupProps {
    /** Кастомный компонент*/
    Element: 'span' | 'div';
    /** На всю длину */
    stretched?: boolean;
}

const ButtonGroup: ComponentWithCustomElement<ButtonGroupProps, 'div'> = ({
    Element = 'div',
    stretched,
    ...buttonGroupProps
}) => (
    <Element
        className={classnames(styles['bloko-button-group'], {
            [styles['bloko-button-group_stretched']]: stretched,
        })}
        {...buttonGroupProps}
    />
);

export default ButtonGroup;
