import $ from 'jquery';

import { KeyCode } from 'bloko/common/constants/keyboard';
import Components from 'bloko/common/core/Components';
import Events from 'bloko/common/events';
import modalHelper from 'bloko/common/modalHelper';
import Transition from 'bloko/common/transition';

import modalTemplate from 'bloko/blocks/modal/modal.mustache';
import modalOverlayTemplate from 'bloko/blocks/modal/modalOverlay.mustache';

const BLOCK_NAME = 'modal';

const cssClasses = {
    overlay: {
        visible: 'bloko-modal-overlay_visible',
    },
    container: {
        visible: 'bloko-modal-container_visible',
    },
    closeButton: {
        hidden: 'bloko-modal-close-button_hidden',
    },
};

const $document = $(document);
const $body = $('body');

/**
 * @param {Element} element
 * @param {Object} options
 * @param {Boolean} [options.closeBy.closeButtonClick=true] Добавлять кнопку для закрытия модального окна
 * @param {Boolean} [options.closeBy.backgroundClick=true] Закрывать модальное окно при клике вне его
 * @param {Boolean} [options.closeBy.escapePress=true] Закрывать модальное окно при нажатии ESC
 * @constructor
 */
function Modal(element, options) {
    const $element = $(element);
    const $content = $element.contents();
    let $modalContainer;
    let $modalOverlay;
    let $modal;
    let $closeButton;
    let isClosable = true;

    const publicInterface = Events.extend({
        show,
        hide,
    });

    function isVisible() {
        return $modalContainer && $modalContainer.is(':visible');
    }

    /**
     * При отображенном модальном окне блокирует работу со скроллом
     * @private
     */
    function disableScroll() {
        modalHelper.disableScroll();
    }

    /**
     * Восстанавливает поведение скролла после закрытия модального окна
     * @private
     */
    function enableScroll() {
        modalHelper.enableScroll();

        $modal.off(`touchmove.${BLOCK_NAME}`);
        $content.off(`touchmove.${BLOCK_NAME}`);
    }

    function handleClick(event) {
        const target = event.target;

        const isAttached = $document.find(target).length > 0;
        const isInsideModal = $modal.find(target).length > 0;
        const isModal = $modal.is(target);

        const isModalCloseButton = $closeButton.is(target);
        const isCustomCloseButton = $(target).is(`[data-attach="${BLOCK_NAME}-hide"]`);

        if (!isAttached) {
            return;
        }

        if (options.closeBy.closeButtonClick && isModalCloseButton && isClosable) {
            hide();
            return;
        }

        if (options.closeBy.backgroundClick && !isInsideModal && !isModal && isClosable) {
            hide();
            return;
        }

        if (isCustomCloseButton) {
            hide();
        }

        isClosable = true;
    }

    function handleEscPress(event) {
        if (event.which === KeyCode.ESC) {
            hide();
        }
    }

    /**
     * Навешивает обработчики для всевозможных способов закрытия модального окна
     * @private
     */
    function attachHandlers() {
        $modalContainer.on(`click.${BLOCK_NAME}`, handleClick);

        if (options.closeBy.escapePress) {
            $document.on(`keydown.${BLOCK_NAME}`, handleEscPress);
        }

        $modal.on(`touchmove.${BLOCK_NAME}`, (e) => {
            e.stopPropagation();
        });
    }

    /**
     * Запрещает закрытие модального окна, для случаев, когда mouse events заканчиваются или начинаются в блоке контента
     * @private
     */
    function setNonClosable() {
        isClosable = false;
    }

    /**
     * Показать модальное окно
     * @fires showed
     * @public
     */
    function show() {
        if (isVisible()) {
            return;
        }

        $modalOverlay = $(modalOverlayTemplate.render());
        $modalOverlay.appendTo($body);
        $modalContainer = $(modalTemplate.render());

        $closeButton = $('.Bloko-Modal-CloseButton', $modalContainer);

        if (!options.closeBy.closeButtonClick) {
            $closeButton.addClass(cssClasses.closeButton.hidden);
        }

        $modal = $modalContainer.find('[data-attach="modal"]');

        attachHandlers();
        disableScroll();

        $modal.empty().append($content);

        $modalOverlay.addClass(cssClasses.overlay.visible);
        $modalContainer.appendTo($body);
        Transition($modalContainer.get(0), () => {
            /**
             * Модальное окно показано
             * @event showed
             */
            publicInterface._trigger('showed');
        });
        $modalContainer.addClass(cssClasses.container.visible);

        $modal.on('mouseup mousedown', setNonClosable);
    }

    /**
     * Скрыть модальное окно
     * @fires hid
     * @public
     */
    function hide() {
        if (!isVisible()) {
            return;
        }

        const callback = function () {
            $modalOverlay.detach();
            $modalContainer.detach();
            $element.append($content);
            /**
             * Модальное окно скрыто
             * @event hid
             */
            publicInterface._trigger('hid');
        };

        enableScroll();

        $document.off(`keydown.${BLOCK_NAME}`);
        $modal.off(`touchmove.${BLOCK_NAME}`);
        $modal.off('mouseup mousedown', setNonClosable);

        Transition($modalContainer.get(0), callback);
        $modalOverlay.removeClass(cssClasses.overlay.visible);
        $modalContainer.removeClass(cssClasses.container.visible);
    }

    return publicInterface;
}

export default Components.build({
    defaults: {
        closeBy: {
            closeButtonClick: true,
            backgroundClick: true,
            escapePress: true,
        },
    },
    create: Modal,
});
