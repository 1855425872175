import { Breakpoint, getBreakpoint } from 'bloko/common/media';
import Metrics, { Placement, PlacementDirection } from 'bloko/common/metrics';
import { BoundingClientRect, Directions, Direction } from 'bloko/common/types';

import { InfoTheme } from 'bloko/blocks/drop/Info';
import { TipTheme } from 'bloko/blocks/drop/Tip';
import calcAlign from 'bloko/blocks/drop/align';
import setArrow from 'bloko/blocks/drop/arrow';
import {
    GUARD_SIZE,
    DIRECTION_MAPPING,
    RESERVED_CSS_VALUES,
    FULLSCREEN_ON_XS_CLASS,
} from 'bloko/blocks/drop/constants';
import findDynamicPosition from 'bloko/blocks/drop/findDynamicPosition';
import calcPlacement from 'bloko/blocks/drop/placement';
import { Alignment, Dimension, PlacementRect } from 'bloko/blocks/drop/types';

import composedSelectors from 'bloko/blocks/drop/composed-selectors.less';
import styles from 'bloko/blocks/drop/drop.less';

type AlignDirection = 'start' | 'end' | 'center';

export interface Behavior {
    showArrow: boolean;
    arrowSize: number;
    placementOffset: number;
    alignToActivatorBorders: boolean;
    setupFullWidthOnXS: boolean;
    fullScreenOnXS: boolean;
}

interface UpdatePositionParams {
    host: HTMLElement | null;
    behavior: Behavior;
    activatorElement: HTMLElement;
    placement: Placement;
    dropElement: HTMLElement;
    sizeBox?: HTMLElement | null;
    classNames: string[];
    arrow: HTMLElement | null;
    arrowSize: number;
    placementSequence: Placement[];
}

interface GetRenderParamsParams {
    element: HTMLElement;
    placement: Placement;
    dropElement: HTMLElement;
    sizeBox?: HTMLElement | null;
    host: HTMLElement | null;
    arrowSize: number;
    behavior: Behavior;
    placementSequence: Placement[];
}

interface DropPlacementParams {
    behavior: Behavior;
    placement: Direction;
    align: AlignDirection;
    host: HTMLElement | null;
    dropElement: HTMLElement;
    elementMetrics: BoundingClientRect;
    dropElementMetrics: BoundingClientRect;
    viewportMetrics: BoundingClientRect;
    elementOffset: PlacementRect;
    arrowSize: number;
}

interface Sequence {
    align: AlignDirection;
    placement: Direction;
}

function hasDedicatedOppositeDirection(directions: Directions, dedicatedDimension: Dimension) {
    return DIRECTION_MAPPING[dedicatedDimension].every((direction) => directions.includes(direction as Direction));
}

export function getPlacementClass(placement: Placement): string[] {
    return composedSelectors[`bloko-drop_${placement.split('-')[0] as PlacementDirection}`].split(' ');
}

export function updateCSSMetrics(element: HTMLElement, metrics: Alignment | Record<string, never> | undefined): void {
    if (!element || !metrics) {
        return;
    }

    if (element.style.length > 0) {
        element.removeAttribute('style');
    }

    Object.keys(metrics as Alignment).forEach((item) => {
        element.style.setProperty(
            item,
            RESERVED_CSS_VALUES.includes(`${metrics[item]}`) ? `${metrics[item]}` : `${metrics[item]}px`
        );
    });
}

export function getThemeClass(theme: InfoTheme | TipTheme): string {
    return styles[`bloko-drop_theme-${theme}`];
}

export function calculateRectangle(
    placement: PlacementRect,
    tooltipMetrics: BoundingClientRect,
    elementOffset: PlacementRect
): BoundingClientRect {
    const top = placement.top + elementOffset.top;
    const left = placement.left + elementOffset.left;
    const bottom = top + tooltipMetrics.height;
    const right = left + tooltipMetrics.width;
    const width = tooltipMetrics.width;
    const height = tooltipMetrics.height;

    return { top, right, bottom, left, width, height };
}

function dropPlacement({
    behavior,
    placement,
    align,
    host,
    dropElement,
    elementMetrics,
    dropElementMetrics,
    viewportMetrics,
    elementOffset,
    arrowSize = 0,
}: DropPlacementParams) {
    // Если элемент находится внутри какого-то хоста, нужно найти разницу в offset, чтобы позиционировать корректно.
    let hostOffset = { left: 0, top: 0 };
    if (host !== document.body) {
        const relativeDropMetrics = Metrics.getRelativeMetrics(dropElement);
        const offset = Metrics.getMetrics(dropElement);

        hostOffset = {
            left: offset.left - relativeDropMetrics.left,
            top: offset.top - relativeDropMetrics.top,
        };
    }

    const calculatedPlacement = calcPlacement[placement]({
        offset: behavior.placementOffset,
        elementMetrics,
        dropElementMetrics,
    });
    const { possibleToChange } = calculatedPlacement;
    const metrics = {
        ...calculatedPlacement.metrics,
        ...calcAlign[possibleToChange][align]({
            elementMetrics,
            alignToActivatorBorders: behavior.alignToActivatorBorders,
            dropElementMetrics,
        }),
    } as BoundingClientRect;

    const scrollBarWidth = Metrics.getScrollbarWidth();
    const viewportMetricsWithGuardBorders = {
        left: viewportMetrics.left + GUARD_SIZE,
        right: viewportMetrics.right - GUARD_SIZE,
        width: viewportMetrics.width - 2 * GUARD_SIZE,
        height: viewportMetrics.height - 2 * GUARD_SIZE,
        top: viewportMetrics.top + GUARD_SIZE,
        bottom: viewportMetrics.bottom - GUARD_SIZE,
    };
    const viewportMetricsWithScrollBarOffset = {
        ...viewportMetrics,
        width: viewportMetrics.width - scrollBarWidth,
        right: viewportMetrics.right - scrollBarWidth,
    };

    const directions = Metrics.checkIfRectangleInRectangle(
        calculateRectangle(metrics, dropElementMetrics, elementOffset),
        viewportMetricsWithScrollBarOffset
    );

    const elementOffsetWithoutHost = {
        left: elementOffset.left - hostOffset.left,
        top: elementOffset.top - hostOffset.top,
    };

    if (directions.length === 0) {
        return {
            metrics,
            arrow:
                align === 'center'
                    ? undefined
                    : setArrow[possibleToChange][align]({
                          elementMetrics,
                          dropElementMetrics: calculateRectangle(metrics, dropElementMetrics, elementOffsetWithoutHost),
                          arrowSize,
                      }),
            success: true,
        };
    }

    // если не влезли с двух противоположных (слева и справа, сверху и снизу) сторон — выходим, не пытаемся вставить
    if (hasDedicatedOppositeDirection(directions, possibleToChange)) {
        return {
            metrics,
            arrow: setArrow[possibleToChange][align]({
                elementMetrics,
                dropElementMetrics: calculateRectangle(metrics, dropElementMetrics, elementOffsetWithoutHost),
                arrowSize,
            }),
            success: false,
        };
    }

    let result = findDynamicPosition[possibleToChange]({
        metrics: calculateRectangle(metrics, dropElementMetrics, elementOffset),
        viewport: viewportMetricsWithGuardBorders,
        elementMetrics,
    });

    if (!result.success) {
        result = findDynamicPosition[possibleToChange]({
            metrics: calculateRectangle(metrics, dropElementMetrics, elementOffset),
            viewport: viewportMetricsWithScrollBarOffset,
            elementMetrics,
        });
    }

    const metricsWithoutHostOffset = {
        top: result.metrics.top - hostOffset.top,
        right: result.metrics.right,
        left: result.metrics.left - hostOffset.left,
        bottom: result.metrics.bottom,
        width: result.metrics.width,
        height: result.metrics.height,
    };

    return {
        metrics: metricsWithoutHostOffset,
        arrow: setArrow[possibleToChange][align]({
            elementMetrics,
            dropElementMetrics: metricsWithoutHostOffset,
            arrowSize,
        }),
        success: result.success,
    };
}

function getPositionFullName(position: Sequence): Placement {
    if (position.align === 'center') {
        return position.placement;
    }
    return `${position.placement}-${position.align}` as Placement;
}

/**
 * Вычисляет расположение дроп-элемента в заданном направлении
 * @param {Object} options
 * @param {String} options.placement - положение дроп объекта
 * @param {Node} options.element - DOM нода элемента инициатора
 * @param {Node} options.dropElement - DOM нода drop element
 * @param {null | Node} options.host - DOM нода хоста или null
 * @param {Number} options.arrowSize - Размер стрелки
 * @param {Object} options.behavior - поведение компонента
 *
 * @param {Boolean} options.behavior.showArrow
 * @param {Number} options.behavior.arrowSize
 * @param {Boolean} options.behavior.setupFullWidthOnXS
 * @param {Number} options.behavior.placementOffset
 * @param {Boolean} options.behavior.alignToActivatorBorders
 * @param {Boolean} options.behavior.fullScreenOnXS
 *
 * @param {Array} options.placementSequence  - Список PlacementRect, куда дроп объект будет пытаться разместиться
 * @returns {{metrics: Object, placement: String, arrow: Object }} объект с полями метрик, позицией и
 * метриками стрелки
 * @public
 */
function getRenderParams({
    placement,
    element,
    behavior,
    dropElement,
    sizeBox,
    host,
    arrowSize,
    placementSequence,
}: GetRenderParamsParams) {
    const placementsOrder = [...placementSequence];
    const startIndex = placementsOrder.indexOf(placement);

    const sequence = placementsOrder
        .slice(startIndex)
        .concat(placementsOrder.slice(0, startIndex))
        .map((item) => {
            const [placement, align = 'center'] = item.split('-');
            return { placement, align } as Sequence;
        });

    if (behavior.fullScreenOnXS && getBreakpoint() === Breakpoint.XS) {
        return {
            placement: getPositionFullName(sequence[0]),
            metrics: {},
            arrow: undefined,
        };
    }

    const elementMetrics = host === document.body ? Metrics.getMetrics(element) : Metrics.getRelativeMetrics(element);
    const dropElementMetrics = Metrics.getMetrics(sizeBox || dropElement);
    const viewportMetrics = Metrics.getViewportMetrics();
    const offset = Metrics.getMetrics(element);

    const elementOffset = {
        left: offset.left - elementMetrics.left,
        top: offset.top - elementMetrics.top,
    };

    const defaultPlacement = dropPlacement({
        behavior,
        placement: sequence[0].placement,
        align: sequence[0].align,
        elementMetrics,
        dropElementMetrics,
        viewportMetrics,
        elementOffset,
        host,
        dropElement,
        arrowSize,
    });

    if (!defaultPlacement.success) {
        for (let i = 1; i < sequence.length; i++) {
            const result = dropPlacement({
                behavior,
                placement: sequence[i].placement,
                align: sequence[i].align,
                elementMetrics,
                host,
                dropElement,
                dropElementMetrics,
                viewportMetrics,
                elementOffset,
                arrowSize,
            });

            if (result.success) {
                return {
                    placement: getPositionFullName(sequence[i]),
                    metrics: result.metrics,
                    arrow: result.arrow,
                };
            }
        }
    }

    return {
        placement: getPositionFullName(sequence[0]),
        metrics: defaultPlacement.metrics,
        arrow: defaultPlacement.arrow,
    };
}

export function updatePosition({
    host,
    behavior,
    activatorElement,
    placement,
    dropElement,
    sizeBox,
    classNames,
    arrowSize,
    arrow,
    placementSequence,
}: UpdatePositionParams): { placement: Placement } {
    const renderState = getRenderParams({
        element: activatorElement,
        dropElement,
        sizeBox,
        placement,
        host,
        arrowSize,
        behavior,
        placementSequence,
    });

    updateCSSMetrics(dropElement, renderState.metrics);

    // если нет ref стрелки, не пытаемся с ней ничего делать
    if (arrow) {
        arrow.style.top = '';
        arrow.style.left = '';
        updateCSSMetrics(arrow, renderState.arrow);
    }

    [...dropElement.classList].forEach((className) => {
        if (!classNames.includes(className)) {
            dropElement.classList.remove(className);
        }
    });

    dropElement.classList.add(...getPlacementClass(renderState.placement));

    if (behavior.fullScreenOnXS) {
        dropElement.classList.add(...FULLSCREEN_ON_XS_CLASS.split(' '));
    }

    return {
        placement: renderState.placement,
    };
}

const START_OFFSET = -9999;

export function setInitialCSSMetrics(dropElement: HTMLElement): void {
    updateCSSMetrics(dropElement, {
        left: START_OFFSET,
        top: START_OFFSET,
    });
}

// если хост не боди, то мы должны вручную установить размеры для меню, с учетом скроллбаров
export function setupWidth(node: HTMLElement, host: HTMLElement): void {
    if (getBreakpoint() === Breakpoint.XS && host !== document.body && document.querySelector('.xs-friendly')) {
        node.style.width = '';
        node.style.width = `${window.innerWidth - Metrics.getScrollbarWidth()}px`;
    } else {
        node.style.width = '';
    }
}
