import { ComponentPropsWithoutRef, FC, PropsWithChildren } from 'react';

import { DocumentedPropsWithChildren } from 'bloko/common/helpers/types';

import styles from 'bloko/blocks/icon/icon.less';

const IconVerticalAlignment: FC<DocumentedPropsWithChildren<ComponentPropsWithoutRef<'span'>> & PropsWithChildren> = ({
    children,
    ...spanProps
}) => (
    <span className={styles['bloko-icon-vertical-alignment']} {...spanProps}>
        {children}
    </span>
);

export default IconVerticalAlignment;
