import { ComponentWithCustomElement } from 'bloko/common/helpers/types';

import styles from 'bloko/blocks/form/form.less';

interface FormOneColumnProps {
    /** Кастомный компонент или ('div', 'form') */
    Element?: 'div' | 'form';
}

const FormOneColumn: ComponentWithCustomElement<FormOneColumnProps, 'div'> = ({
    Element = 'div',
    children,
    ...additionalProps
}) => {
    return (
        <Element {...additionalProps} className={styles['bloko-form-one-column']}>
            {children}
        </Element>
    );
};

export default FormOneColumn;
