import { DropPlacement, DropLayer } from 'bloko/blocks/drop/constants';
import { ValueOf } from 'bloko/common/types';

import styles from 'bloko/blocks/drop/Down/down.less';

export const DownPlacement = DropPlacement;
export type DownPlacementValue = ValueOf<typeof DownPlacement>;

export { DropLayer as DownLayer };

export const defaultProps = {
    layer: DropLayer.AboveContent,
    onlySetPlacement: false,
    placement: DropPlacement.BottomStart,
    dataQa: 'bloko-drop-down',
    showCloseButton: false,
};

export const BASE_CLASS_NAMES = [styles['bloko-drop_down'], styles['bloko-drop_theme-light']];
export const PADDING_WRAPPER_CLASS_NAME = styles['bloko-drop__padding-wrapper_down'];

export const BEHAVIOR = {
    showArrow: false,
    arrowSize: 0,
    setupFullWidthOnXS: true,
    placementOffset: 0,
    alignToActivatorBorders: true,
    fullScreenOnXS: true,
};
