import { FC, PropsWithChildren } from 'react';

import styles from 'bloko/blocks/slide/styles.less';

const Item: FC<
    {
        width?: number;
    } & PropsWithChildren
> = ({ children, width }) => (
    <div
        style={{
            width: `${width}px`,
        }}
        className={styles['bloko-slide__item']}
    >
        {children}
    </div>
);

export default Item;
