import React, { ComponentPropsWithoutRef, FC, Fragment, ReactNode, ComponentType, PropsWithChildren } from 'react';
import classnames from 'classnames';
import { Merge } from 'type-fest';

import IconDynamic from 'bloko/blocks/icon/IconDynamic';
import { DocumentedPropsWithChildren } from 'bloko/common/helpers/types';

import styles from 'bloko/blocks/link/link.less';

export enum LinkKind {
    Success = 'success',
    Secondary = 'secondary',
    Tertiary = 'tertiary',
    Warning = 'warning',
    Inversed = 'inversed',
    InversedSecondary = 'inversed-secondary',
}

export enum LinkAppearance {
    Underlined = 'underlined',
    Pseudo = 'pseudo',
    Loading = 'loading',
}

export enum LinkIconPosition {
    Left = 'left',
    Right = 'right',
}

interface LinkProps<LA, CT> {
    /**
     * Тип ссылки
     */
    kind?: LinkKind;

    /**
     * Указывает на строку с компонентом в исходном коде в режиме разработки. Генерируется babel-plugin-react-source
     */
    source?: string;

    /**
     * Внешний вид, возможные варианты
     *  `Loading` - состояние загрузки,
     *  `Underlined` - ссылка с подчеркиваением,
     *  `Pseudo` - ссылка с подчеркиванием пунктиром
     */
    appearance?: LA;

    /** Иконка */
    icon?: ReactNode;

    /** Расположение иконки */
    iconPosition?: LinkIconPosition;

    /**
     * Отключить visited состояние
     */
    disableVisited?: boolean;

    /**
     * Кастомный компонент
     */
    Element?: CT;
}

type AnchorElementProps = ComponentPropsWithoutRef<'a'> & { href: string };

interface LinkAppearanceAdditionalProps {
    [LinkAppearance.Underlined]: AnchorElementProps;
    [LinkAppearance.Pseudo]: ComponentPropsWithoutRef<'button'>;
    [LinkAppearance.Loading]: ComponentPropsWithoutRef<'button'>;
}

type LinkWithDefaultAppearanceAdditionalProps = AnchorElementProps | ComponentPropsWithoutRef<'button'>;

export type LinkPropsHelper<LA, CT> = DocumentedPropsWithChildren<
    CT extends ComponentType<infer CP>
        ? Merge<CP, LinkProps<LA, CT>>
        : LA extends keyof LinkAppearanceAdditionalProps
        ? LinkAppearanceAdditionalProps[LA] & LinkProps<LA, CT>
        : LinkWithDefaultAppearanceAdditionalProps & LinkProps<LinkAppearance, ComponentType>
>;

const LinkRenderFunction = <LA, CT>(
    { icon, iconPosition, disableVisited, appearance, kind, Element, children, ...linkProps }: LinkPropsHelper<LA, CT>,
    ref: React.Ref<unknown>
): JSX.Element => {
    let childElement: ReactNode = children;
    let Wrapper: FC<PropsWithChildren> = Fragment;

    const isLinkType = (appearance === LinkAppearance.Underlined || appearance === undefined) && 'href' in linkProps;

    if (icon) {
        Wrapper = appearance !== LinkAppearance.Loading ? IconDynamic : Fragment;
        const content = <span className={styles['bloko-link__content']}>{children}</span>;
        childElement =
            iconPosition === LinkIconPosition.Left ? (
                <>
                    {icon} {content}
                </>
            ) : (
                <>
                    {content} {icon}
                </>
            );
    }

    const render = React.useCallback(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (CustomElement, propsForButtonElement = {}) => {
            return (
                <Wrapper>
                    <CustomElement
                        {...propsForButtonElement}
                        {...linkProps}
                        className={classnames(styles['bloko-link'], {
                            [styles[`bloko-link_kind-${kind as LinkKind}`]]: kind,
                            [styles['bloko-link_with-icon']]: icon,
                            [styles[`bloko-link_${appearance as LinkAppearance}`]]: appearance,
                            [styles['bloko-link_disable-visited']]: disableVisited,
                        })}
                        ref={ref}
                    >
                        {childElement}
                    </CustomElement>
                </Wrapper>
            );
        },
        [Wrapper, linkProps, kind, icon, appearance, disableVisited, ref, childElement]
    );

    if (Element === undefined) {
        return isLinkType ? render('a') : render('button', { type: 'button' });
    }
    return render(Element);
};

const Link = React.forwardRef(LinkRenderFunction);

export default Link;
