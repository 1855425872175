import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const AdjustmentsScaleSmall = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M3.33325 5.33337L8.66659 5.33337M8.66659 5.33337C8.66659 6.43794 9.56202 7.33337 10.6666 7.33337C11.7712 7.33337 12.6666 6.43794 12.6666 5.33337C12.6666 4.2288 11.7712 3.33337 10.6666 3.33337C9.56202 3.33337 8.66659 4.22881 8.66659 5.33337ZM7.33325 10.6667L12.6666 10.6667M7.33325 10.6667C7.33325 11.7713 6.43782 12.6667 5.33325 12.6667C4.22868 12.6667 3.33325 11.7713 3.33325 10.6667C3.33325 9.56214 4.22868 8.66671 5.33325 8.66671C6.43782 8.66671 7.33325 9.56214 7.33325 10.6667Z"
                stroke="var(--bloko-icon-color, var(--bloko-icon-color-default))"
                strokeWidth={1.33333}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    ),
    'Adjustments'
);
export default AdjustmentsScaleSmall;
