import { FC, PropsWithChildren } from 'react';

import { FormHint } from 'bloko/blocks/form';
import SelectDropdownOptionText from 'bloko/blocks/selectDropdownOption/SelectDropdownOptionText';

const CustomSelectOptionSecondaryText: FC<Parameters<typeof FormHint>[0] & PropsWithChildren> = ({
    children,
    ...props
}) => (
    <FormHint {...props}>
        <SelectDropdownOptionText>{children}</SelectDropdownOptionText>
    </FormHint>
);

export default CustomSelectOptionSecondaryText;
