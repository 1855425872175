import $ from 'jquery';

import Tip from 'bloko/blocks/drop/Tip/tip';
import { IconColor, CrossScaleSmallEnclosedFalse, PlusScaleSmallEnclosedFalse } from 'bloko/blocks/icon';
import IconReactRenderer from 'bloko/blocks/icon/IconReactRenderer';
import Components from 'bloko/common/core/Components';

import countSection from 'bloko/blocks/tagList/countSection.mustache';
import countableInlineTemplate from 'bloko/blocks/tagList/countableInline.mustache';
import decreaseSection from 'bloko/blocks/tagList/decreaseSection.mustache';
import hiddenSection from 'bloko/blocks/tagList/hiddenSection.mustache';
import increaseSection from 'bloko/blocks/tagList/increaseSection.mustache';
import InlineTagListView from 'bloko/blocks/tagList/inlineTagListView';
import removeSection from 'bloko/blocks/tagList/removeSection.mustache';
import textSection from 'bloko/blocks/tagList/textSection.mustache';
import textTemplate from 'bloko/blocks/tagList/textTemplate.mustache';

/**
 * Все события наследуются от [tagList](https://tech.hh.ru/bloko/?path=/docs/components-taglist-classic-taglist--page#events). Событие `Bloko-TagList-ToggleSelected` никогда не
 * произойдет, потому как Countable теги не могут быть `selectable`.
 *
 * @event События TagList
 * @type {Event}
 */

/**
 * Событие вызываемое при увеличении счетчика тега.
 *
 * @event Bloko-TagList-Updated
 * @type {Event}
 * @property {Number} tagId модели, которая изменилась.
 * @property {Boolean} состояние voted.
 */

export default InlineTagListView.extend({
    /**
     * Значения параметров компонента по-умолчанию
     * @property {Object} tooltipParams параметры блоко-тултипа.
     */
    _defaults: $.extend({}, InlineTagListView.prototype._defaults, {
        tooltipParams: {},
    }),

    /**
     * Функция-шаблонизатор, которая управляет рендером шаблонов и partial'ов
     * @param  {Object} data Данные которые используются в шаблоне
     * @return {String}      Готовый к вставке в DOM шаблон с данными
     */
    template(data) {
        return countableInlineTemplate.render(data, {
            textSection,
            textTemplate: this.options.textTemplate || textTemplate,
            hiddenSection,
            removeSection,
            increaseSection,
            decreaseSection,
            countSection,
        });
    },

    renderIcons(item) {
        const id = item.get('id');

        $('.Bloko-TagList-Tag', this.$el).each((_, element) => {
            if (id && id === element.dataset.tagId) {
                this.initDecreaseIcon(element);
                this.initIncreaseIcon(element);
                this.renderRemoveIcon(element);
            }
        });
    },

    events: $.extend({}, InlineTagListView.prototype.events, {
        'click .Bloko-TagList-Increase': 'increase',
        'click .Bloko-TagList-Decrease': 'decrease',
        'mouseover .Bloko-TagList-Count': 'showCountHint',
        'mouseout .Bloko-TagList-Count': 'hideCountHint',
    }),

    tips: {},

    /**
     * @inheritdoc
     */
    initTags() {
        const existingTags = [];

        $('.Bloko-TagList-Tag', this.$el).each((index, element) => {
            const $element = $(element);

            const id = $element.data('tag-id');
            const text = $('.Bloko-TagList-Text', element).text().trim();
            const $count = $('.Bloko-TagList-Count', $element);
            const count = parseInt($count.text(), 10) || 0;
            const countable = !!$('.Bloko-TagList-Increase, .Bloko-TagList-Decrease', element).length;

            if (countable) {
                this.initIncreaseIcon(element);
                this.initDecreaseIcon(element);
            }

            existingTags.push({
                id,
                text,
                hiddenValue: id,
                count,
                countable,
            });
        });

        this.setTags(existingTags);
    },

    initIncreaseIcon(element) {
        const iconElement = element.querySelector('.Bloko-TagList-IncreaseIcon');

        if (iconElement) {
            Components.make(IconReactRenderer, iconElement, {
                IconComponent: PlusScaleSmallEnclosedFalse,
                iconProps: {
                    initial: IconColor.White,
                    highlighted: IconColor.Gray60,
                },
            });
        }
    },

    initDecreaseIcon(element) {
        const iconElement = element.querySelector('.Bloko-TagList-DecreaseIcon');

        if (iconElement) {
            Components.make(IconReactRenderer, iconElement, {
                IconComponent: CrossScaleSmallEnclosedFalse,
                iconProps: {
                    initial: IconColor.Gray50,
                    highlighted: IconColor.Gray60,
                },
            });
        }
    },

    increase(event) {
        const tag = this.getTagFromEvent(event);

        this.trigger('Bloko-TagList-Updated', tag.get('id'), true);
    },

    decrease(event) {
        const tag = this.getTagFromEvent(event);

        this.trigger('Bloko-TagList-Updated', tag.get('id'), false);
    },

    showCountHint(event) {
        const tag = this.getTagFromEvent(event);

        if (this.options.tooltipParams.content) {
            this.getTipInstance(tag.get('id'), event.target).show();
        }
    },

    hideCountHint(event) {
        const tag = this.getTagFromEvent(event);

        if (this.options.tooltipParams.content) {
            this.getTipInstance(tag.get('id'), event.target).hide();
        }
    },

    getTipInstance(id, tipNode) {
        this.tips[id] =
            this.tips[id] ||
            Components.make(Tip, tipNode, { ...this.options.tooltipParams, html: this.options.tooltipParams.content });
        return this.tips[id].changeOptions({ ...this.options.tooltipParams, html: this.options.tooltipParams.content });
    },

    /**
     * Обновляет состояние увеличино или уменьшено, указывает значение счетчика,
     * @param {Number} tagId
     * @param {Object} options параметры для обновления
     * @param {String} options.voted меняет состояние voted
     * @param {String} options.count меняет значение счетчика
     * @returns {Object}
     */
    updateTag(tagId, options) {
        const tag = this.tags.get(tagId);
        tag.set(
            $.extend(
                {},
                {
                    voted: options.voted,
                    count: options.count,
                }
            )
        );
        return this;
    },

    /**
     * Возвращает значение счетчика.
     * @param {Number} tagId
     * @returns {Number}
     */
    getCount(tagId) {
        const tag = this.tags.get(tagId);
        return tag.get('count');
    },
});
