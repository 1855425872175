import { FC, PropsWithChildren } from 'react';

import { DocumentedPropsWithChildren } from 'bloko/common/helpers/types';

import styles from 'bloko/blocks/translateGuard/guard.less';

interface BlokoTranslateGuardProps {
    /** метка использовать ли span.
     * Его использовать можно только если у вас падает единичная текстовая нода.
     * В других случаях этот способ не поможет */
    useSpan?: boolean;
}

const BlokoTranslateGuard: FC<DocumentedPropsWithChildren<BlokoTranslateGuardProps> & PropsWithChildren> = ({
    useSpan,
    ...props
}) => {
    if (!props.children) {
        return null;
    }
    if (useSpan) {
        return <span {...props} />;
    }
    return <div className={styles['bloko-translate-guard']} {...props} />;
};

export default BlokoTranslateGuard;
