import { FC } from 'react';
import classnames from 'classnames';

import { setMonth, PickDate } from 'bloko/blocks/calendar/datesHelper';
import { CalendarTranslator } from 'bloko/blocks/calendar/translations';

import styles from 'bloko/blocks/calendar/calendar.less';

export interface MonthPickerProps {
    translate: CalendarTranslator;
    date: Date;
    onPick: PickDate;
}

const MonthPicker: FC<MonthPickerProps> = ({ translate, date, onPick }) => (
    <ol className={styles['bloko-calendar__months']}>
        {translate.month.map((month, index) => (
            <li
                key={month}
                className={classnames(styles['bloko-calendar__month'], {
                    [styles['bloko-calendar__month_selected']]: date.getMonth() === index,
                })}
                onClick={() => onPick(index, setMonth)}
            >
                <div
                    className={classnames(styles['bloko-calendar__month-number'], {
                        [styles['bloko-calendar__month-number_selected']]: date.getMonth() === index,
                    })}
                >
                    {index + 1}
                </div>
                <div className={styles['bloko-calendar__month-name']}>{month}</div>
            </li>
        ))}
    </ol>
);

export default MonthPicker;
