import Backbone from 'backbone';
import $ from 'jquery';

import TreeCollection from 'bloko/common/tree/collection';
import CountableTreeModel from 'bloko/common/tree/countableModel';

import CountableTagListView from 'bloko/blocks/tagList/countableTagListView';
import TagList from 'bloko/blocks/tagList/tagList';
/**
 * Публичный интерфейс для работы со списком тегов, возвращаемый {@link module:CountableTagList}.
 *
 * @interface СountableTagList/TagListInterface
 * @see {@link СountableTagList/TagListInterface}
 * @augments TagList/TagListInterface
 */

/**
 * Обновляет состояние увеличено или уменьшено, указывает дельту счетчика.
 * @method СountableTagList/TagListInterface#updateTag
 * @param {String} tagId id тега, который надо обновить
 * @returns {Object}
 */

/**
 * Возвращает значение счетчика.
 * @method СountableTagList/TagListInterface#getCount
 * @param {String} id id тега
 * @returns {Number}
 */

/**
 * Модуль создающий список тегов со счетчиком и инкриментом.
 * @module CountableTagList
 * @augments module:TagList
 */
export default $.extend({}, TagList, {
    /**
     * Создание публичного интерфейса.
     * @param tagListView
     * @returns {СountableTagList/TagListInterface}
     * @private
     */
    createPublicInterface(tagListView) {
        const publicInterface = TagList.createPublicInterface.call(this, tagListView);

        $.extend(
            publicInterface,
            {
                updateTag: tagListView.updateTag.bind(tagListView),
                getCount: tagListView.getCount.bind(tagListView),
            },
            Backbone.Events
        );

        tagListView.on('Bloko-TagList-Updated', (tagId, voted) => {
            publicInterface.trigger('Bloko-TagList-Updated', tagId, voted);
        });

        return publicInterface;
    },

    /**
     * Список тегов со счетчиком и кнопками увеличения и уменьшения счетчика.
     *
     * @param {Element} element DOM-элемент или css селектор, на котором будет инициализирован компонент
     * @param {Object} options Принимает все параметры [tagList](https://tech.hh.ru/bloko/?path=/docs/components-taglist-classic-taglist--page#params). Countable теги не могут
     * быть `selectable, editable, stretched`(они всегда занимают всю доступную ширину).
     * @param {Object} [options.tooltipParams={}] Проксирует все параметры [DropTip](https://tech.hh.ru/bloko/?path=/docs/components-drop-classic-tip--tip#params)
     * @constructor
     */
    createView(element, options) {
        const TagModel = CountableTreeModel.extend(this.getOptions(options));
        return new CountableTagListView({
            collection: new TreeCollection(null, {
                model: TagModel,
            }),
            el: element,
            options,
        });
    },
});
