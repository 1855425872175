import { useCallback, ReactNode, isValidElement, ReactElement } from 'react';

import { CustomSelectOptionOrArray } from 'bloko/blocks/customSelect';
import CustomSelectOption, { CustomSelectOptionProps } from 'bloko/blocks/customSelect/CustomSelectOption';
import useMenuOptionsFiltered from 'bloko/blocks/drop/Menu/useMenuOptionsFiltered';

import CustomSelectSearchField from 'bloko/blocks/customSelect/hooks/CustomSelectSearchField';

export interface AsyncFilterFunctionType {
    <T>(children: CustomSelectOptionOrArray<T>, filterQuery: string): Promise<
        ReactElement<CustomSelectOptionProps<T>>[]
    >;
}

interface UseFilteredOptionsProps<T> {
    children: CustomSelectOptionOrArray<T>;
    search?: boolean;
    searchPlaceholder?: string;
    title?: string;
    filterThrottleMs: number;
    asyncFilterFunction?: AsyncFilterFunctionType;
}

interface FilteredOptions<T> {
    filteredOptions: ReactElement<CustomSelectOptionProps<T>>[];
    setFilterQuery: (value: string) => void;
    renderSearchField: () => ReactNode | false;
}

const validateChild = (child: ReactNode): boolean => {
    return isValidElement(child) && child.type === CustomSelectOption;
};

const useFilteredOptions = <T,>({
    children,
    search,
    searchPlaceholder,
    title,
    filterThrottleMs,
    asyncFilterFunction,
}: UseFilteredOptionsProps<T>): FilteredOptions<T> => {
    const { filterQuery, setFilterQuery, filteredOptions } = useMenuOptionsFiltered<
        ReactElement<CustomSelectOptionProps<T>>[],
        CustomSelectOptionOrArray<T>
    >(children, validateChild, asyncFilterFunction, filterThrottleMs);

    const renderSearchField = useCallback(
        () =>
            search && (
                <CustomSelectSearchField
                    placeholder={searchPlaceholder}
                    value={filterQuery}
                    onChange={(value) => setFilterQuery(value)}
                    hasTitle={!!title}
                />
            ),
        [filterQuery, setFilterQuery, search, searchPlaceholder, title]
    );

    return {
        filteredOptions,
        setFilterQuery,
        renderSearchField,
    };
};

export default useFilteredOptions;
