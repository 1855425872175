import TreeCollection from 'bloko/common/tree/treeCollection';
import type { ModelData, WalkCallback, AdditionalDefault } from 'bloko/common/tree/types';
import { IdCollectionPredicate, TreeFilter, TreeModel } from 'bloko/common/tree/types';

/**
 * Рекурсивно проходит по списку моделей, применяет к каждой модели переданную функцию.
 */
const walk = <A extends AdditionalDefault>(
    /** Дерево для обработки. */
    tree: ModelData<A>[],
    /** Вызываемая функция. */
    callback: WalkCallback<A>,
    /** Массив моделей родителей от ближнего к дальнему. */
    parents?: ModelData<A>[]
): void => {
    const currentParents = parents ? parents.slice() : [];
    tree.forEach((item) => {
        callback(item, currentParents);
        if (item.items && item.items.length) {
            walk(item.items, callback, [item].concat(currentParents));
        }
    });
};

/**
 * Возвращает коллекцию, созданную из дерева.
 * @param {Array.<TreeItem>} tree
 * @param {WalkCallback} [callback] Функция, вызываемая на каждом элементе.
 * @returns {TreeCollection}
 */
const fromTree = <A extends AdditionalDefault = never>(
    tree: ModelData<A>[],
    callback?: WalkCallback<A>
): TreeCollection<A> => {
    const collection = new TreeCollection<A>();
    walk(tree, (item, parents): void => {
        if (typeof callback === 'function') {
            callback(item, parents);
        }
        collection.addModel(item, parents.length ? parents[0].id : undefined);
    });
    return collection;
};

/**
 * Возвращает элементы из списка, родители которых отсутствуют в этом же списке.
 * Полезно для определения минимального набора выбранных элементов (если выбран родитель
 * целиком, игнорируем его дочерние элементы).
 * @param {TreeCollection} collection
 * @param {Array.<String>} ids
 * @param {Array.<String>} [excluded]
 * @returns {Array.<String>}
 */
const getIdsWithNoParentsInSameList = <A extends AdditionalDefault>(
    collection: TreeCollection<A>,
    ids: string[],
    excluded: string[] = []
): string[] => {
    const hasChildrenHash = ids.reduce((result: Record<string, boolean>, id) => {
        result[id] = collection.getChildren(id).length > 0 && !excluded.includes(id);
        return result;
    }, {});

    return ids.filter((id) => {
        const parentIds = collection.getParentIdsDuplicates(id);
        return !parentIds.some((id) => hasChildrenHash.hasOwnProperty(id) && hasChildrenHash[id]);
    });
};

/**
 * Возвращает хэш, ключи в котором — ID из списка, имеющие потомков.
 * @param {TreeCollection} collection
 * @param {Array.<String>} ids
 * @returns {Object}
 */
const getParentsHashMap = <A extends AdditionalDefault>(
    collection: TreeCollection<A>,
    ids: string[]
): Record<string, boolean> => {
    return ids.reduce((result: Record<string, boolean>, id) => {
        collection.getParentIds(id).forEach((parentId) => {
            result[parentId] = true;
        });
        return result;
    }, {});
};

/**
 * Возвращает дерево, состоящее только из элементов с указанными id и всех их предков.
 * @param {TreeCollection} collection
 * @param {Array.<String>} ids
 * @returns {Array.<TreeItem>}
 */
const filterTreeByIdsWithParents = <A extends AdditionalDefault>(
    collection: TreeCollection<A>,
    ids: string[]
): ModelData<A>[] => {
    const idsWithParentsHash = getParentsHashMap(collection, ids);
    ids.forEach((id) => {
        idsWithParentsHash[id] = true;
    });
    return collection.toTree((model) => {
        return idsWithParentsHash.hasOwnProperty(model.id);
    });
};

/**
 * Возвращает только те ID, которые присутствуют в коллекции, и логирует ошибки.
 * @param {TreeCollection} collection
 * @param {Array.<String>} ids
 * @returns {Array}
 */
const filterMissingIds = <A extends AdditionalDefault>(collection: TreeCollection<A>, ids: string[]): string[] => {
    const filteredIds: string[] = [];
    ids.forEach((id) => {
        if (collection.getModel(id)) {
            filteredIds.push(id);
        }
    });
    return filteredIds;
};

/**
 * Возвращает ID только тех элементов, у которых нет потомков.
 * @param {TreeCollection} collection
 * @param {Array.<String>} ids
 * @returns {Array.<String>}
 */
const filterParents = <A extends AdditionalDefault>(collection: TreeCollection<A>, ids: string[]): string[] => {
    return ids.filter((id) => {
        return collection.getChildrenIds(id).length === 0;
    });
};

/**
 * Возвращает ID только тех элементов, которые можно выбрать в рамках опции singleCategory.
 * @param {TreeCollection} collection
 * @param {Array.<String>} selectedIds
 * @param {Array.<String>} newIds
 * @returns {Array.<String>}
 */
const filterSingleCategory = <A extends AdditionalDefault>(
    collection: TreeCollection<A>,
    selectedIds: Set<string>,
    newIds: string[]
): string[] => {
    const selectedValue = selectedIds.values().next().value as string;

    if (!selectedValue) return newIds;

    const selectedParent = collection.getParentId(selectedValue);

    if (!selectedParent) return newIds;

    return newIds.filter((id) => collection.getParentId(id) === selectedParent);
};

/**
 * Возвращает новую коллекцию, содержащую элементы, для которых `filterFunction` вернула true.
 * Если заматчился дочерний элемент, к результатам добавляются его родители до самого верха.
 * Если заматчился родитель, к результатам НЕ добавляются его дочерние элементы (кроме тех,
 * что тоже заматчились).
 */
const filterWithParents: TreeFilter = (collection, filterFunction) => {
    const filteredCollection: typeof collection = new (collection.constructor as typeof TreeCollection)();
    const ids = new Set<TreeModel['id']>();
    collection.walk((model, parents) => {
        if (filterFunction(model)) {
            ids.add(model.id);
            parents.forEach((parent) => {
                ids.add(parent.id);
            });
        }
    });
    ids.forEach((id) => {
        const model = collection.getModel(id);
        if (model) {
            // В модели с дубликатами у элемента может быть несколько родителей
            const parentIds = collection.getParentIdsDuplicates(model.id);
            if (parentIds.length) {
                parentIds.forEach((parendId) => {
                    filteredCollection.addModel({ ...model }, parendId);
                });
            } else {
                filteredCollection.addModel({ ...model });
            }
        }
    });
    return filteredCollection;
};

/**
 * Возвращает новую коллекцию, содержащую уникальные элементы самого нижнего уровня,
 * для которых `filterFunction` вернула true (плоский список).
 */
const filterUniqueLeavesOnly: TreeFilter = (collection, filterFunction) => {
    const filteredCollection: typeof collection = new TreeCollection();
    collection.walk((model) => {
        if (!filteredCollection.getModel(model.id) && !collection.hasChildren(model.id) && filterFunction(model)) {
            filteredCollection.addModel({ ...model });
        }
    });
    return filteredCollection;
};

const isNotLeaf: IdCollectionPredicate = (id, collection) => collection.hasChildren(id);

const removeExcludedFromSelected = (selected: string[], excluded: string[]): [string[], string[]] => [
    selected.filter((selectedId) => !excluded.includes(selectedId)),
    excluded,
];

const narrowDownExcludedFromChildrenToParents = <A extends AdditionalDefault>(
    collection: TreeCollection<A>,
    excluded: string[]
): string[] => {
    const childrenToSkip = new Map<string, Set<string>>();

    return excluded.reduce<string[]>((result, excludedId) => {
        const parentId = collection.getParentId(excludedId);
        const allParentsIds = collection.getParentIds(excludedId);

        if (
            result.includes(excludedId) ||
            (parentId && childrenToSkip.get(parentId)?.has(excludedId)) ||
            allParentsIds.some((someParentId) => excluded.includes(someParentId))
        ) {
            return result;
        }

        const childrenIds = collection.getChildrenIds(excludedId);

        if (childrenIds.length) childrenToSkip.set(excludedId, new Set(childrenIds));

        result.push(excludedId);

        return result;
    }, []);
};

const extendExcludedFromParentsToChildren = <A extends AdditionalDefault>(
    collection: TreeCollection<A>,
    selected: string[],
    excluded: string[]
): string[] => {
    if (!selected.length) return [];

    return excluded.reduce<string[]>((result, excludedId) => {
        if (result.includes(excludedId) || !collection.getModel(excludedId)) return result;

        collection.walkChildren(excludedId, (child) => !selected.includes(child.id) && result.push(child.id));

        result.push(excludedId);

        return result;
    }, []);
};

export {
    walk,
    fromTree,
    getParentsHashMap,
    getIdsWithNoParentsInSameList,
    filterTreeByIdsWithParents,
    filterMissingIds,
    filterParents,
    filterSingleCategory,
    filterWithParents,
    filterUniqueLeavesOnly,
    isNotLeaf,
    extendExcludedFromParentsToChildren,
    narrowDownExcludedFromChildrenToParents,
    removeExcludedFromSelected,
};
