import Backbone from 'backbone';
import $ from 'jquery';

export default function () {
    const publicInterface = $.extend(
        {
            set,
            toJSON,
        },
        Backbone.Events
    );

    function set(model, attributes) {
        if (typeof attributes === 'object') {
            for (const key in attributes) {
                if (!(key === 'expanded' && model.attributes.items.length === 0)) {
                    if (attributes[key] !== model.attributes[key]) {
                        model.attributes[key] = attributes[key];

                        if (key === 'selected') {
                            model.attributes.changed = true;
                        }

                        publicInterface.trigger(`change:${key}`, model);
                    }
                }
            }
        }
    }

    function toJSON(model) {
        if (!model) {
            return null;
        }

        const attributes = model.attributes;

        return {
            id: attributes.id,
            text: attributes.text,
            name: attributes.name,
            parent: attributes.parent ? attributes.parent.attributes.id : null,
            expanded: attributes.expanded,
            selected: attributes.selected,
            disabled: attributes.disabled,
            items: attributes.items.map((item) => {
                return item.attributes.id;
            }),
            additional: attributes.additional ? $.extend(true, {}, attributes.additional) : null,
        };
    }

    return publicInterface;
}
