import { ComponentWithCustomElement } from 'bloko/common/helpers/types';

import styles from 'bloko/blocks/form/form.less';

interface FormItemGroupProps {
    /** Кастомный компонент или ('div', 'span', 'section')*/
    Element?: 'div' | 'span' | 'section';
}

const FormItemGroup: ComponentWithCustomElement<FormItemGroupProps, 'div'> = ({
    Element = 'div',
    children,
    ...props
}) => {
    return (
        <Element {...props} className={styles['bloko-form-item-group']}>
            {children}
        </Element>
    );
};

export default FormItemGroup;
