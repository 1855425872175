import { RefObject, useCallback } from 'react';

import { SwipeEndHandler, SwipeMoveHandler, SwipePreventHandler } from '../swipe/swipeUtils';

export interface UseSwipeHandlersResult {
    onSwipeMove: SwipeMoveHandler;
    onSwipeEnd: SwipeEndHandler;
    onSwipePrevent: SwipePreventHandler;
}

export interface UseSwipeHandlers {
    setSlideOffset: (offset: number) => void;
    slideOnChange?: (slide: number) => void;
    slideContainerElRef: RefObject<HTMLDivElement>;
    activeItemIndex: number;
    lastItemIndex: number;
}

const useSwipeHandlers = ({
    setSlideOffset,
    slideOnChange,
    slideContainerElRef,
    activeItemIndex,
    lastItemIndex,
}: UseSwipeHandlers): UseSwipeHandlersResult => {
    const onSwipeMove: SwipeMoveHandler = useCallback(
        (event) => {
            if (slideContainerElRef.current) {
                slideContainerElRef.current.style.transitionProperty = 'none';
            }
            setSlideOffset(event.distance);
        },
        [setSlideOffset, slideContainerElRef]
    );

    const onSwipeEnd: SwipeEndHandler = useCallback(
        ({ direction }) => {
            const requestIndex = activeItemIndex + direction;

            if (requestIndex > lastItemIndex) {
                slideOnChange?.(0);
            } else if (requestIndex < 0) {
                slideOnChange?.(lastItemIndex);
            } else {
                slideOnChange?.(requestIndex);
            }

            if (slideContainerElRef.current) {
                slideContainerElRef.current.style.transitionProperty = 'transform';
            }
            setSlideOffset(0);
        },
        [activeItemIndex, lastItemIndex, setSlideOffset, slideContainerElRef, slideOnChange]
    );

    const onSwipePrevent: SwipePreventHandler = useCallback(() => {
        setSlideOffset(0);
    }, [setSlideOffset]);

    return {
        onSwipeMove,
        onSwipeEnd,
        onSwipePrevent,
    };
};

export default useSwipeHandlers;
