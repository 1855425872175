const prefixes = " \u02BC\u2019'`-";
const expression = '(?:[{prefix}]|^)[^{prefix}]*'.replace(/\{prefix\}/g, prefixes);

export default function capitalize(text = ''): string {
    return text.replace(new RegExp(expression, 'g'), (word) => {
        const expression = new RegExp('[{prefix}]'.replace('{prefix}', prefixes), 'g');
        const prefixLength = word.search(expression) === -1 ? 1 : 2;
        return word.slice(0, prefixLength).toUpperCase() + word.slice(prefixLength).toLowerCase();
    });
}
