import { BoundingClientRect } from 'bloko/common/types';

import { MIN_SIZE_TO_PLACE_DROP_AT_THE_START, OFFSET_TO_ARROW_X, OFFSET_TO_ARROW_Y } from 'bloko/blocks/drop/constants';
import { getIntersectionX, getIntersectionY } from 'bloko/blocks/drop/getIntersection';
import { Alignment } from 'bloko/blocks/drop/types';

interface ArrowPlacementParams {
    elementMetrics: BoundingClientRect;
    dropElementMetrics: BoundingClientRect;
    arrowSize: number;
}

export default {
    x: {
        start({ elementMetrics, dropElementMetrics }: ArrowPlacementParams): Alignment {
            const intersection = getIntersectionX(elementMetrics, dropElementMetrics);
            if (!intersection) {
                return {};
            }
            if (elementMetrics.width > MIN_SIZE_TO_PLACE_DROP_AT_THE_START) {
                return { left: intersection.start + OFFSET_TO_ARROW_X - dropElementMetrics.left };
            }
            return {
                left: (intersection.end - intersection.start) / 2 + intersection.start - dropElementMetrics.left,
            };
        },
        center({ elementMetrics, dropElementMetrics, arrowSize }: ArrowPlacementParams): Alignment {
            return {
                left: Math.min(
                    Math.max(elementMetrics.left - dropElementMetrics.left + elementMetrics.width / 2, arrowSize),
                    dropElementMetrics.width - 2 * arrowSize
                ),
            };
        },
        end({ elementMetrics, dropElementMetrics }: ArrowPlacementParams): Alignment {
            const intersection = getIntersectionX(elementMetrics, dropElementMetrics);
            if (!intersection) {
                return {};
            }
            if (elementMetrics.width > MIN_SIZE_TO_PLACE_DROP_AT_THE_START) {
                return { left: intersection.end - OFFSET_TO_ARROW_X - dropElementMetrics.left };
            }
            return {
                left: (intersection.end - intersection.start) / 2 + intersection.start - dropElementMetrics.left,
            };
        },
    },
    y: {
        start({ elementMetrics, dropElementMetrics }: ArrowPlacementParams): Alignment {
            const intersection = getIntersectionY(elementMetrics, dropElementMetrics);
            if (!intersection) {
                return {};
            }
            if (elementMetrics.height > MIN_SIZE_TO_PLACE_DROP_AT_THE_START) {
                return { top: intersection.start + OFFSET_TO_ARROW_Y - dropElementMetrics.top };
            }
            return {
                top: (intersection.end - intersection.start) / 2 + intersection.start - dropElementMetrics.top,
            };
        },
        center({ elementMetrics, dropElementMetrics, arrowSize }: ArrowPlacementParams): Alignment {
            return {
                top: Math.min(
                    Math.max(elementMetrics.top - dropElementMetrics.top + elementMetrics.height / 2, arrowSize),
                    dropElementMetrics.height - 2 * arrowSize
                ),
            };
        },
        end({ elementMetrics, dropElementMetrics }: ArrowPlacementParams): Alignment {
            const intersection = getIntersectionY(elementMetrics, dropElementMetrics);
            if (!intersection) {
                return {};
            }
            if (elementMetrics.height > MIN_SIZE_TO_PLACE_DROP_AT_THE_START) {
                return { top: intersection.start + OFFSET_TO_ARROW_Y - dropElementMetrics.top };
            }
            return {
                top: (intersection.end - intersection.start) / 2 + intersection.start - dropElementMetrics.top,
            };
        },
    },
};
