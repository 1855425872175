import { useCallback, FC } from 'react';
import classnames from 'classnames';

import { subMonths, addMonths, subYears, addYears, OnArrowClick } from 'bloko/blocks/calendar/datesHelper';
import { CalendarPickerAndSwitcherKind } from 'bloko/blocks/calendar/helper';

import CalendarArrow from 'bloko/blocks/calendar/Base/CalendarArrow';

import styles from 'bloko/blocks/calendar/calendar.less';

const dataQa = {
    month: {
        back: 'bloko-calendar-prev-month',
        next: 'bloko-calendar-next-month',
    },
    year: {
        back: 'bloko-calendar-prev-year',
        next: 'bloko-calendar-next-year',
    },
};

export interface CalendarSwitcherProps {
    onSwitch: (visiblePicker: CalendarPickerAndSwitcherKind) => void;
    onArrowClick: OnArrowClick;
    kind: CalendarPickerAndSwitcherKind.Month | CalendarPickerAndSwitcherKind.Year;
    visiblePicker: CalendarPickerAndSwitcherKind;
    children: string | number;
    disabled: boolean;
}

const CalendarSwitcher: FC<CalendarSwitcherProps> = ({
    onSwitch,
    onArrowClick,
    kind,
    visiblePicker,
    children,
    disabled,
}) => {
    const isArrowDisabled = visiblePicker !== CalendarPickerAndSwitcherKind.Day || disabled;
    const subDate = kind === CalendarPickerAndSwitcherKind.Month ? subMonths : subYears;
    const addDate = kind === CalendarPickerAndSwitcherKind.Month ? addMonths : addYears;

    const onSwitchHandler = useCallback(
        () => onSwitch(visiblePicker === kind ? CalendarPickerAndSwitcherKind.Day : kind),
        [kind, onSwitch, visiblePicker]
    );

    return (
        <span className={styles['bloko-calendar__switcher']}>
            <CalendarArrow
                kind="left"
                disabled={isArrowDisabled}
                onClick={() => onArrowClick(subDate)}
                data-qa={dataQa[kind].back}
            />
            <span
                className={classnames(styles['bloko-calendar__name'], {
                    [styles['bloko-calendar__name_months']]: kind === CalendarPickerAndSwitcherKind.Month,
                    [styles['bloko-calendar__name_years']]: kind === CalendarPickerAndSwitcherKind.Year,
                    [styles['bloko-calendar__name_disabled']]: disabled,
                })}
                onClick={onSwitchHandler}
            >
                {children}
            </span>
            <CalendarArrow
                kind="right"
                disabled={isArrowDisabled}
                onClick={() => onArrowClick(addDate)}
                data-qa={dataQa[kind].next}
            />
        </span>
    );
};

export default CalendarSwitcher;
