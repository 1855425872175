import { useState, useCallback, FC } from 'react';

import Calendar, { CalendarProps } from 'bloko/blocks/calendar/Base';
import { DateString, YearMonthDayString, ClickDate } from 'bloko/blocks/calendar/datesHelper';

export interface MultipleCalendarPickerProps extends Omit<CalendarProps, 'highlighted' | 'onDateClick'> {
    /** Дата инициализации календаря в формате 'YYYY-MM-DD' или ISO */
    initialDate: DateString;
    /** Выделенные даты */
    highlighted?: YearMonthDayString[];
    /** Обработчик клика по дате, принимает объект вида { date, highlighted }, где date - это текущая выбранная дата,
     * а highlighted - это все ранее выбранные даты, включая текущую
     */
    onDateClick: (props: { date?: YearMonthDayString; highlighted: YearMonthDayString[] }) => void;
}

const MultipleCalendarPicker: FC<MultipleCalendarPickerProps> = ({
    initialDate,
    highlighted = [],
    onDateClick,
    ...props
}) => {
    const [currentHighlighted, setCurrentHighlighted] = useState(highlighted);

    const onDateClickHandler: ClickDate = useCallback(
        (selectedDate) => {
            let highlighted;
            if (currentHighlighted.includes(selectedDate)) {
                highlighted = currentHighlighted.filter((date) => date !== selectedDate);
                onDateClick({ highlighted });
            } else {
                highlighted = [...currentHighlighted, selectedDate];
                onDateClick({ highlighted, date: selectedDate });
            }
            setCurrentHighlighted(highlighted);
        },
        [currentHighlighted, onDateClick]
    );

    return (
        <Calendar
            {...props}
            initialDate={initialDate}
            highlighted={currentHighlighted}
            onDateClick={onDateClickHandler}
        />
    );
};

export default MultipleCalendarPicker;
