import TreeCollection from 'bloko/common/tree/treeCollection';

export enum CoreField {
    Id = 'id',
    Text = 'text',
    Additional = 'additional',
}

export type AdditionalDefault = Record<never, unknown>;

export interface TreeModel<A extends AdditionalDefault = never> {
    [CoreField.Id]: string;
    [CoreField.Text]: string;
    [CoreField.Additional]?: A;
}

export interface ModelData<A extends AdditionalDefault = never> extends TreeModel<A> {
    items?: ModelData<A>[];
    [x: string]: unknown;
}

/**
 * Коллбек, вызываемый на каждой модели при обходе дерева.
 */
export interface WalkCallback<A extends AdditionalDefault> {
    (
        /** Модель текущего узла. */
        item: TreeModel<A>,
        /** Массив моделей родителей от ближнего к дальнему. */
        currentParents: TreeModel<A>[]
    ): void;
}

export interface ModelPredicate {
    <A extends AdditionalDefault>(item: TreeModel<A>): boolean;
}

export interface IdCollectionPredicate {
    <A extends AdditionalDefault>(id: string, collection: TreeCollection<A>): boolean;
}

export interface IdPredicate {
    (id: string): boolean;
}

export interface TreeFilter {
    <A extends AdditionalDefault>(collection: TreeCollection<A>, filterFunction: ModelPredicate): TreeCollection<A>;
}
