import $ from 'jquery';
import _ from 'underscore';

import CommonCssClasses from 'bloko/common/constants/commonCssClasses';
import Components from 'bloko/common/core/Components';
import requestAnimation from 'bloko/common/requestAnimation';

import createRemoteDataProvider from 'bloko/blocks/suggest/createRemoteDataProvider';
import createStaticDataProvider from 'bloko/blocks/suggest/createStaticDataProvider';
import cssClasses from 'bloko/blocks/suggest/cssClasses';
import Defaults from 'bloko/blocks/suggest/defaults';
import dropdownTemplate from 'bloko/blocks/suggest/suggest-dropdown.mustache';
import suggestItemTemplate from 'bloko/blocks/suggest/suggest-item.mustache';
import template from 'bloko/blocks/suggest/suggest.mustache';
import { normalizeText, createKeyboardHandler, setMetrics, updateHighlight } from 'bloko/blocks/suggest/utils';

const isTest = window.bloko && window.bloko.isTest;

const BLOCK_NAME = 'suggest';

const DROPDOWN_FADE_TIME = isTest ? 0 : 120;

const bindings = {
    item: '.Bloko-Suggest-Item',
    list: '.Bloko-Suggest-List',
    highlight: '.Bloko-Suggest-Highlight',
};

const $window = $(window);

/**
 * Поставщик данных, функция, возвращающая Promise, который разрешается массивом данных для саджеста
 *
 * @typedef {Function} DataProvider
 *
 * @param {String} query
 *
 * @returns {Promise<Array>}
 */

/**
 * Саджест показался
 *
 * @event showed.suggest
 */

/**
 * Дропдаун исчез
 *
 * @event hid.suggest
 */

/**
 * Выбран какой-то пункт
 *
 * @event selected.suggest
 */

/**
 * Выбран какой-то пункт автоматически
 *
 * @event autoselected.suggest
 */

/**
 * Отмена выбора
 *
 * @event unselected.suggest
 */

/**
 * Строка запроса менее suggestStartInputLength символов
 *
 * @event Bloko-Suggest-MinQueryLength.suggest
 */

/**
 * Не найдено совпадений или вернулись некорректные данные
 *
 * @event Bloko-Suggest-NotFound.suggest
 */

/**
 * Создает объект - саджест
 * @param {Element} element                     Элемент, на котором будет инициализирован компонент
 * @param {Object} options                      Параметры саджеста
 * @param {String} options.remote               URL, куда ходить за данными,
 *                                              несовместим с data и dataProvider
 * @param {Object} options.data                 Статический массив объектов ([формат](#/•%20Suggest?id=format--json)),
 *                                              несовместим с remote и dataProvider
 * @param {DataProvider} options.dataProvider   Провайдер данных, функция, возвращающая промис, возвращающий данные,
 *                                              аналогичные options.data,
 *                                              несовместим с data и remote
 * @param {String} [options.wildcard='%QUERY%'] Шаблон для подстановки пользовательского ввода
 * @param {Number} [options.limit=10]           Количество вариантов в дропдауне
 * @param {String} options.hidden               Имя для скрытого поля.
 *                                              Если атрибут не указан, скрытое поле не создается
 * @param {String} options.hiddenValue          Значение, которое будет проставлено при инициализации саджеста для
 *                                              скрытого инпута
 * @param {String} options.hiddenClasses        Класс или классы, которые будут навешаны на hidden поле.
 *                                              Формат строки: `{Class1} {Class2}`
 * @param {Boolean} [options.autoselect=false]  Включает функцию автовыбора. Если найдено всего одно совпадение и
 *                                              оно полностью совпадает с текстом, введенным в поле,
 *                                              происходит автоматический выбор этого пункта
 * @param {Boolean} [options.autoselectfirstsuggest=true] После отображения саджеста автоматически выделять
 *                                              первый пункт. По enter будет выбран этот вариант
 * @param {Boolean} [options.selectonblur=true] Выбор первого элемента из найденных при потере фокуса у поля
 * @param {Boolean} [options.selectExactMatchOnBlur=false] Выбор элемента с полностью совпадающим текстом (при
 *                                              наличии такого, без учета пробелов и регистра) при потере фокуса у
 *                                              поля
 * @param {Number} [options.rightpadding=0]     Дополнительный отступ с правого края в `px`
 * @param {Mustache} options.template           Прекомпилированный mustache шаблон
 * @param {String} [options.layer='above-content'] Модификатор, определяющий z-index для блока саджеста
 * @param {String} [options.field='text']       Поле возвращаемого JSON, по которому необходимо проверять
 *                                              ключевое слово в инпуте
 * @param {Number} [options.suggestStartInputLength=2] Минимальное количество символов, необходимое для начала
 *                                              показа подсказки
 * @constructor
 */
const Suggest = function (element, options) {
    const $element = $(element);
    const $input = $element;
    const hiddenFieldName = options.hidden;
    let _closed = true;
    let isSelectedDirectly;
    let previousInputValue;
    let prevReceivedDataJSON;
    let dataProvider;
    let suggestItems = [];
    let onInputTimer;
    let onFocusTimer;
    let isInFocus;
    let $dropdown;
    let $hidden;
    let selectedDatum = null;
    let clickOnDropdownStarted = false;
    const suggestStartInputLength = options.suggestStartInputLength;
    const keyboardHandler = createKeyboardHandler(_highlightItem, _hideDropdown, _showDropdown, _selectHighlightedItem);

    function _enableMouseMode() {
        const $suggestionsList = _getDropdown().find(bindings.list);
        if ($suggestionsList.hasClass(cssClasses.hover.enable)) {
            return;
        }
        $suggestionsList.addClass(cssClasses.hover.enable);

        $suggestionsList.find(bindings.item).removeClass(_.values(cssClasses.state).join(' '));
    }

    function _hideDropdown() {
        if (_closed) {
            return;
        }

        _closed = true;

        const removeDropdown = function () {
            if (_closed) {
                _getDropdown().detach();
                $element.trigger(`hid.${BLOCK_NAME}`);
                isSelectedDirectly = undefined;
            }
        };

        if (_getDropdown().hasClass(CommonCssClasses.AnimFade)) {
            _getDropdown().one('transitionend', removeDropdown);
        } else {
            setTimeout(removeDropdown, 0);
        }

        _getDropdown().removeClass(CommonCssClasses.AnimFadeIn);

        $window.off(`resize.${BLOCK_NAME}`);
    }

    /**
     * Выбирает вариант по совпадению id
     *
     * @param {Object} datum
     * @param {String} datum.id id, с которым саджест сравнивает возвращаемые параметры
     */
    function selectItem(datum) {
        clearTimeout(onInputTimer);
        setTimeout(_hideDropdown, DROPDOWN_FADE_TIME);

        if (!$.isPlainObject(datum)) {
            return;
        }

        prevReceivedDataJSON = null;
        if ($hidden) {
            $hidden.val(datum.id);
        }

        _setSelectedDatum(datum);
    }

    function _directSelectItem(datum) {
        isSelectedDirectly = true;
        selectItem(datum);
        const field = datum[options.field];
        $input.val(field);
        if (field) {
            previousInputValue = normalizeText(field);
        }
        $input.trigger('input');
        $element.trigger(`selected.${BLOCK_NAME}`, [datum]);
    }

    function _selectHighlightedItem() {
        _directSelectItem(_getDatum(_getHighlightedItem()));
    }

    function _getDatum(elem) {
        return suggestItems[$(elem).data('datum-id')];
    }

    function _getInputValue() {
        return $input.val().trim();
    }

    /**
     * Признак того,что значение инпута было изменено
     * @private
     */
    function _isValueChanged() {
        return normalizeText(_getInputValue()) !== previousInputValue;
    }

    function _onClickItem(event) {
        _directSelectItem(_getDatum(event.currentTarget));
    }

    /**
     * Получает дропдаун с саджестами
     * @returns {Suggest.$dropdown}
     * @private
     */
    function _getDropdown() {
        function createDropdown() {
            const $block = $(dropdownTemplate.render());
            $block
                .on(`mousedown.${BLOCK_NAME} touchstart.${BLOCK_NAME}`, () => {
                    clickOnDropdownStarted = true;
                })
                .on(`mousemove.${BLOCK_NAME}`, _enableMouseMode)
                .on(`click.${BLOCK_NAME}`, bindings.item, _onClickItem);
            return $block;
        }
        return $dropdown || ($dropdown = createDropdown());
    }

    function _setDropdownMetrics() {
        const $dropdown = _getDropdown();
        setMetrics($input.get(0), $dropdown.get(0), options.rightpadding);
        const layer = options.layer;
        $dropdown.removeClass(_.values(cssClasses.layer).join(' '));
        $dropdown.addClass(cssClasses.layer[layer]);
    }

    function _showDropdown() {
        if (!_closed) {
            return;
        }

        if (!isInFocus) {
            return;
        }

        if (!prevReceivedDataJSON || _getInputValue().length < suggestStartInputLength) {
            return;
        }

        _closed = false;

        _getDropdown().detach().addClass(CommonCssClasses.AnimFade).appendTo(document.body);

        _setDropdownMetrics();

        _getDropdown().addClass(CommonCssClasses.AnimFadeIn);

        $window.on(`resize.${BLOCK_NAME}`, requestAnimation(_setDropdownMetrics));

        $element.trigger(`showed.${BLOCK_NAME}`);
    }

    /**
     * Реакция на потерю фокуса, при необходимости выбор первого результата
     * @private
     */
    function _onLostFocus() {
        if (isSelectedDirectly || _closed) {
            return;
        }

        if (options.selectExactMatchOnBlur) {
            const normalizedInputValue = normalizeText(_getInputValue());
            const matchedItem = _.find(suggestItems, (datum) => {
                return normalizeText(datum[options.field]) === normalizedInputValue;
            });

            if (matchedItem) {
                _directSelectItem(matchedItem);
            }
        }

        if (options.selectonblur) {
            const $items = _getDropdown().find(bindings.item);

            if ($items.length) {
                _directSelectItem(_getDatum($items.first()));
            }
        }

        _hideDropdown();
    }

    /**
     * Очищает результаты выбранных элементов,
     * полученных от сервера данных,
     * предыдущего введеного в инпут значения
     *
     * @private
     */
    function _clearResults() {
        suggestItems = [];
        prevReceivedDataJSON = null;
        previousInputValue = null;
        _getDropdown().html('');
    }

    function _autoSelectItem(datum) {
        selectItem(datum);
        $element.trigger(`autoselected.${BLOCK_NAME}`, [datum]);
    }

    function _renderItems(dataJSON) {
        const itemTemplate = {
            item: options.template,
        };

        return template.render(dataJSON, itemTemplate);
    }

    /**
     * Выделяет элемент саджеста как текущий
     * @param index
     * @private
     */
    function _highlightItem(index) {
        const $dropdown = _getDropdown();
        const $items = $dropdown.find(bindings.item);
        const newIndex = updateHighlight($dropdown.get(0), index);

        $items.removeClass(cssClasses.highlightSelector);

        if (newIndex !== -1) {
            $items.eq(newIndex).addClass(cssClasses.highlightSelector);
        }
    }

    /**
     * Обработка пришедшего JSON с сервера. Разбор, отображение вариантов
     * @param {String} query - запрос, который был сделан
     * @param {JSON} suggestData - данные, пришедшие от провайдера
     * @private
     */
    function _onReceivedDataSuccess(query, suggestData) {
        if (_getInputValue() !== query) {
            // Значение инпута было изменено, результаты не актуальны
            return;
        }

        if (!$.isPlainObject(suggestData) || !Array.isArray(suggestData.items) || !suggestData.items.length) {
            if (!_closed) {
                $element.one(`hid.${BLOCK_NAME}`, _clearResults);
            }
            $element.trigger(`Bloko-Suggest-NotFound.${BLOCK_NAME}`);
            _hideDropdown();
            return;
        }

        const processedData = Object.assign({}, suggestData, {
            items: suggestData.items.slice(0, options.limit).map((datum, index) => {
                return Object.assign({}, datum, {
                    datumId: index,
                    id: String(datum.id),
                });
            }),
            delimiter: options.delimiter,
        });

        if (
            options.autoselect &&
            processedData.items.length === 1 &&
            processedData.items[0][options.field].toUpperCase() === query.toUpperCase()
        ) {
            _autoSelectItem(processedData.items[0]);
            return;
        }

        if (JSON.stringify(processedData) === prevReceivedDataJSON) {
            _showDropdown();
            return;
        }

        prevReceivedDataJSON = JSON.stringify(processedData);
        suggestItems = processedData.items;

        const $dropdown = _getDropdown();
        $dropdown.html(_renderItems(processedData));
        $dropdown.find(bindings.item).hover(
            (event) => {
                $(event.currentTarget).addClass(cssClasses.state.highlighted).addClass(cssClasses.highlightSelector);
            },
            (event) => {
                $(event.currentTarget)
                    .removeClass(cssClasses.state.highlighted)
                    .removeClass(cssClasses.highlightSelector);
            }
        );

        if (options.autoselectfirstsuggest) {
            _highlightItem(0);
        }

        if (_closed) {
            _showDropdown();
        } else {
            // Дропдаун был виден, но его содержимое
            // изменилось => нужно пересчитать высоту
            _setDropdownMetrics();
        }
    }

    function _onReceivedDataFail(query) {
        if (_getInputValue() !== query) {
            // Значение инпута было изменено, результаты не актуальны
            return;
        }
        $element.trigger(`Bloko-Suggest-NotFound.${BLOCK_NAME}`);
        _clearResults();
    }

    function _onChangeInputValue() {
        const query = _getInputValue();

        if (query.length < suggestStartInputLength) {
            $element.trigger(`Bloko-Suggest-MinQueryLength.${BLOCK_NAME}`);
            _hideDropdown();
            return;
        }

        dataProvider(query).then(_onReceivedDataSuccess.bind(null, query), _onReceivedDataFail.bind(null, query));
    }

    function _onFocus() {
        if (_isValueChanged()) {
            _onTypeInInput();
        } else {
            // По фокусу не всегда нужно сразу показывать дропдаун.
            // Например, поле сфокусировано для замены значения.
            clearTimeout(onFocusTimer);
            onFocusTimer = setTimeout(_showDropdown, options.throttle);
        }
    }

    /**
     * Реакция на изменение данных в инпуте
     * Обновление данных саджеста с учетом тротлинга
     * @private
     */
    function _onTypeInInput() {
        clearTimeout(onFocusTimer);
        previousInputValue = normalizeText(_getInputValue());
        if ($hidden) {
            $hidden.val('');
        }
        selectedDatum = null;
        $element.trigger(`unselected.${BLOCK_NAME}`);

        // the value has been changed, but maybe typing is not ended
        clearTimeout(onInputTimer);
        onInputTimer = setTimeout(_onChangeInputValue, options.throttle);
    }

    /**
     * определяет элемент саджеста, который выделен пользователем или программно
     * @returns {Block|*}
     * @private
     */
    function _getHighlightedItem() {
        return _getDropdown().find(bindings.item).filter(bindings.highlight);
    }

    function _enableKeyboardMode() {
        _getDropdown().find(bindings.list).removeClass(cssClasses.hover.enable);
    }

    /**
     * Обработка событий клавиатуры
     * 1) клавиши управления курсором
     * 2) выбор саджеста (Enter)
     * 3) отмена выбора (Esc)
     * @param {Event} event
     * @private
     */
    function _keyboardControl(event) {
        const $highlightedItem = _getHighlightedItem();
        const highlightedItemIndex = $highlightedItem.index();

        _enableKeyboardMode();

        keyboardHandler(_closed, event, highlightedItemIndex);
    }

    /**
     * Навешивает события на инпут
     * @listens focus
     * @listens blur
     * @listens change
     * @listens input
     * @listens keydown
     *
     * @private
     */
    function _addEventListeners() {
        $input
            .on(`focus.${BLOCK_NAME}`, () => {
                isInFocus = true;
                _onFocus();
            })
            .on(`blur.${BLOCK_NAME}`, () => {
                // Не скрываем саджест, когда окно теряет фокус. В этом случае activeElement будет равен input
                if (document.activeElement === this) {
                    return;
                }
                isInFocus = false;
                // Если фокус теряется вследствие клика внутри дропдауна,
                // запускать обработку потери фокуса не нужно
                if (!clickOnDropdownStarted) {
                    _onLostFocus();
                }
            })
            .on(`input.${BLOCK_NAME} change.${BLOCK_NAME}`, () => {
                if (_isValueChanged()) {
                    _onTypeInInput();
                }
            })
            .on(`keydown.${BLOCK_NAME}`, _keyboardControl);

        $(document).on(`mouseup.${BLOCK_NAME} touchend.${BLOCK_NAME}`, () => {
            clickOnDropdownStarted = false;
        });
    }

    /**
     * Выбирает от другого компонента пришедшие данные в инпут.
     * Присваивает данные инпуту и скрытому полю. Скрывает дропдаун.
     *
     * В отличие от `selectItem`, который ищет данные в полученном от сервера массиве,
     * этот метод устанавливает данные без поиска по массиву.
     * При использовании этого метода саджест не генерирует лишний поход на сервер.
     * @param {Object} datum
     * @param {String} datum.text Данные для отображения.
     *                            Имя поля должно быть `text` либо соответствовать параметру `field`
     * @param {String} datum.id   Будет записан в скрытое поле, если оно используется
     */
    function selectItemByData(datum) {
        clearTimeout(onInputTimer);
        setTimeout(_hideDropdown, DROPDOWN_FADE_TIME);

        if (!$.isPlainObject(datum)) {
            return;
        }

        prevReceivedDataJSON = null;

        const field = datum[options.field];
        if (field) {
            $input.val(field);
            previousInputValue = normalizeText(field);
            $input.trigger('input');

            if ($hidden) {
                $hidden.val(datum.id);
            }

            _setSelectedDatum(datum);

            $element.trigger(`selected.${BLOCK_NAME}`, [datum]);
        }
    }

    function _setSelectedDatum(datum) {
        selectedDatum = {
            id: datum.id,
        };
        selectedDatum[options.field] = datum[options.field];
    }

    function _setRemoteDataProvider(remote) {
        const remoteDataProvider = createRemoteDataProvider(remote, options.wildcard);
        setDataProvider(remoteDataProvider);
    }

    /**
     * Возвращает выбранный элемент.
     * Если сработало событие `unselected.suggest`, вернет `null`.
     * @returns {Object} Объект вида `{id: '1', text: 'Text'}`.
     * Вместо `text` может быть другое поле, соответствующее параметру `field`
     */
    function getSelected() {
        return selectedDatum;
    }

    /**
     * Очищает инпут и скрытое поле. Скрывает дропдаун.
     */
    function clear() {
        $input.val('').trigger('change');
        _hideDropdown();
    }

    /**
     * Заменяет url, по которому саджест ходит за вариантами
     *
     * @param {String} remote
     */
    function changeRemote(remote) {
        if (!options.dataProvider) {
            _setRemoteDataProvider(remote);
        }
    }

    /**
     * Обновляет источник данных для саджеста
     *
     * @param {DataProvider} newDataProvider
     */
    function setDataProvider(newDataProvider) {
        dataProvider = newDataProvider;
    }

    $input.prop('autocomplete', 'off');

    isInFocus = document.activeElement === $input[0];

    const initialValue = _getInputValue();
    if (initialValue) {
        previousInputValue = normalizeText(initialValue);
    }

    // Create a hidden input, if the required option is set
    if (hiddenFieldName) {
        $hidden =
            $input.next().attr('name') === hiddenFieldName
                ? $input.next()
                : $('<input>', {
                      type: 'hidden',
                      name: hiddenFieldName,
                      value: '',
                  }).insertAfter($input);

        $hidden.val(options.hiddenValue);
        $hidden.addClass(options.hiddenClasses);
    }

    if (options.dataProvider) {
        setDataProvider(options.dataProvider);
    } else if (options.remote) {
        _setRemoteDataProvider(options.remote);
    } else {
        setDataProvider(createStaticDataProvider(options.data.items));
    }

    // Add event listeners
    _addEventListeners();

    return {
        selectItem,
        selectItemByData,
        getSelected,
        clear,
        changeRemote,
    };
};

export default Components.build({
    defaults: {
        limit: Defaults.LIMIT,
        wildcard: Defaults.WILDCARD,
        throttle: isTest ? Defaults.TEST_THROTTLE : Defaults.THROTTLE,
        autoselect: Defaults.AUTOSELECT,
        selectonblur: Defaults.SELECTION_ON_BLUR,
        selectExactMatchOnBlur: Defaults.SELECT_EXACT_MATCH_ON_BLUR,
        autoselectfirstsuggest: Defaults.AUTO_SELECT_FIRST_SUGGEST,
        delimiter: Defaults.DELIMITER,
        rightpadding: 0,
        layer: 'above-content',
        field: Defaults.FIELD,
        template: suggestItemTemplate,
        suggestStartInputLength: Defaults.SUGGEST_START_INPUT_LENGTH,
    },
    create(element, params) {
        if (
            !params ||
            (typeof params.dataProvider !== 'function' &&
                typeof params.remote !== 'string' &&
                typeof params.data !== 'object')
        ) {
            throw new Error(
                `${BLOCK_NAME}: should be passed one of the following params: ` +
                    `{Function} dataProvider | {String} remote | {Object} data`
            );
        }
        return new Suggest(element, params);
    },
});
