import { isValidElement, Fragment, ReactNode, ReactElement } from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';

import { RenderTagFunc, TagProps } from './types';

interface RenderTransitionGroup {
    (
        animateNew: boolean,
        items: ReactNode[],
        render: RenderTagFunc,
        callback?: () => void,
        renderAfterTags?: () => ReactNode
    ): ReactNode;
}

const ANIMATION_TIMEOUT = 800;

const renderTransitionGroup: RenderTransitionGroup = (animateNew, items, render, callback, renderAfterTags) => {
    if (animateNew) {
        return (
            <TransitionGroup exit={false} component={null}>
                {items.map((item) =>
                    isValidElement<TagProps>(item) ? (
                        <Transition key={item.key} timeout={ANIMATION_TIMEOUT} onEntered={callback}>
                            {(status) => render(item, { isNew: status === 'entering' })}
                        </Transition>
                    ) : null
                )}

                {renderAfterTags && (
                    <Transition timeout={ANIMATION_TIMEOUT} onEntered={callback}>
                        {renderAfterTags}
                    </Transition>
                )}
            </TransitionGroup>
        );
    }

    return (
        <Fragment>
            {items.map((item) => (isValidElement(item) ? render(item as ReactElement<TagProps, string>) : null))}
            {renderAfterTags?.()}
        </Fragment>
    );
};

export default renderTransitionGroup;
