import { useState, useEffect } from 'react';

import TreeCollection from 'bloko/common/tree/treeCollection';
import { AdditionalDefault } from 'bloko/common/tree/types';
/*
    Кастомный хук для получения списка предраскрытых категорий в treeSelector
*/
interface UseExpandedHookProps<A extends AdditionalDefault> {
    value: string[];
    excludedValue: string[];
    collection: TreeCollection<A>;
    expandTreeOnShowPopup: boolean;
}

interface SetExpanded {
    (expanded: string[]): void;
}

type UseExpandedResult = [string[], SetExpanded];

interface UseExpandedHook {
    <A extends AdditionalDefault>(props: UseExpandedHookProps<A>): UseExpandedResult;
}

const useExpanded: UseExpandedHook = ({ value, excludedValue, collection, expandTreeOnShowPopup }) => {
    const [expanded, setExpanded] = useState<typeof value>([]);
    useEffect(() => {
        if (expandTreeOnShowPopup) {
            const parentIds = value
                .concat(excludedValue)
                .reduce<string[]>((result, id) => result.concat(collection.getParentIds(id)), []);
            setExpanded([...new Set(parentIds)]);
        }
    }, [value, collection, expandTreeOnShowPopup, excludedValue]);
    return [expanded, setExpanded];
};

export default useExpanded;
