/* eslint-disable no-console */
import Cookies from 'bloko/common/Cookies';

declare global {
    interface Window {
        jsDebug: typeof debug;
    }
}

const COOKIE_NAME = 'bloko_debug';

const variables = {
    viewMode: 1,
    timestamp: new Date().getTime(),
};

enum ViewMods {
    PRODUCTION = 0,
    TEST = 1,
    DEVELOP = 2,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DebugHandlerFunction = (...args: any[]) => void;
interface DebugHandler {
    viewMode: ViewMods;
    handler: DebugHandlerFunction;
}
const handlers: Partial<Record<string, DebugHandler>> = {};

interface DebugUtility {
    open: () => void;
    close: () => void;
}
const utils: Partial<Record<string, DebugUtility>> = {};

/**
 * Отладочный инструмент, передает события в зарегистрированые обработчики.
 * Имеет геттеры и сеттеры для отладочных утилит.
 * Доступен через глобальную переменную window.jsDebug <br><br>
 *
 * Организация: <br>
 *
 * В папку HHC/Debug складываются все регистрируемые обработчики,
 * и подтягиваются по зависимосям в HHC/Debug/Setup <br><br>
 *
 * Стандартные обработчики: <br>
 *
 * info - эквивалент console.log <br>
 * warn - эквивалент console.warn <br>
 * error - эквивалент console.error
 *
 * @type {Object}
 * @exports bloko/common/core/Debug
 */
const debug = {
    /**
     * Инициализирует Debug.
     * Если обработчики error, warn, info не определены ранее,
     * то регистрирует дефолтные, эквивалентные методам window.console.
     *
     * @member
     * @method
     */
    init(): void {
        const _console = function (type: 'error' | 'warn' | 'log', ...args: unknown[]) {
            console[type](...args);
        };

        if (handlers.error === undefined) {
            this.registerLog('error', this.viewMods.TEST, _console.bind(this, 'error'));
        }

        if (handlers.warn === undefined) {
            this.registerLog('warn', this.viewMods.TEST, _console.bind(this, 'warn'));
        }

        if (handlers.info === undefined) {
            this.registerLog('info', this.viewMods.DEVELOP, _console.bind(this, 'log'));
        }

        this.setMod(Cookies.get(COOKIE_NAME));

        window.jsDebug = this;
    },

    /**
     * Мультиплексирует набор переданных аргументов в указанные обработчики.
     *
     * @example
     * // Выстрелит 2 обработчика error и foo c одним и тем же аргументом.
     * debug.log('error foo', 'Send Error and Foo');
     *
     * @param {String} types  Ключи обработчиков через пробел
     * @param {...*}   [args] Значения, которые надо залогировать
     *
     * @member
     * @method
     */
    log(types: string, ...args: unknown[]): void {
        types.split(' ').forEach((type) => {
            const handler = handlers[type];

            if (handler && handler.viewMode <= variables.viewMode) {
                handler.handler(...args);
            }
        });
    },

    /**
     * Регистрирует новый обработчик отладочного события.
     *
     * @param {String}   type     Имя обработчика
     * @param {Number}   viewMode Уровень видимости логирования
     * @param {Function} handler  Функция обработчик
     *
     * @member
     * @method
     */
    registerLog(type: string, viewMode: ViewMods, handler: DebugHandlerFunction): void {
        handlers[type] = {
            viewMode,
            handler,
        };
    },

    /**
     * Регистрирует новую отладочную утилиту.
     *
     * @param {String} name    Имя утилиты
     * @param {Object} utility Интерфейс утилиты
     *
     * @member
     * @method
     */
    registerUtility(name: string, utility: DebugUtility): void {
        utils[name] = utility;
    },

    /**
     * Возвращает набор отладочных утилит.
     *
     * @returns {Object} Словарь отладочных утилит
     * @member
     */
    utility: utils,

    /**
     * Уровни логгирования.
     * 0 - прод, 1 - тестирование, 2 - отладка, разработка.
     */
    viewMods: ViewMods,

    /**
     * Устанавливает уровень логгирования.
     *
     * @param {Number} mode Уровень логгирования
     *
     * @member
     * @method
     */
    setMod(mode: ViewMods | string | null): void {
        const modeToSet = typeof mode === 'string' ? parseInt(mode, 10) : mode;

        if (modeToSet === null || isNaN(modeToSet)) {
            return;
        }

        if (modeToSet < this.viewMods.PRODUCTION || modeToSet > this.viewMods.DEVELOP) {
            return;
        }
        variables.viewMode = modeToSet;

        Cookies.set(COOKIE_NAME, modeToSet);
    },
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};
const stub = {
    init: noop,
    log: noop,
    registerLog: noop,
    registerUtility: noop,
    utility: {},
    viewMods: ViewMods,
    setMod: noop,
};

export default typeof window === 'undefined' ? stub : debug;
