import Backbone from 'backbone';
import $ from 'jquery';

import { KeyCode } from 'bloko/common/constants/keyboard';
import supports from 'bloko/common/supports';

export default Backbone.View.extend({
    initialize(options) {
        this.treeSelectorInterface = options.treeSelectorInterface;
        this.modelsHandler = options.modelsHandler;
        this.collection = options.collection;
        const isIE = supports.IE() || supports.IEMobile();

        if (options.type === 'checkbox' && isIE) {
            /* Из-за бага ie обрабатываем по другому
             * https://connect.microsoft.com/IE/Feedback/Details/998355
             */
            this.delegateEvents({
                'click .Bloko-TreeSelector-Chevron': 'toggleList',
                'click .Bloko-TreeSelector-Expanded': 'toggleList',
                'click .Bloko-TreeSelector-Input': 'changeByEvent',
                'keydown .Bloko-TreeSelector-Input': 'keyboardControlIE',
                'keydown .Bloko-TreeSelector-Action': 'keyboardControl',
            });
        } else {
            this.delegateEvents({
                'click .Bloko-TreeSelector-Chevron': 'toggleList',
                'click .Bloko-TreeSelector-Expanded': 'toggleList',
                'change .Bloko-TreeSelector-Action': 'changeByEvent',
                'keydown .Bloko-TreeSelector-Action': 'keyboardControl',
            });
        }
    },

    keyboardControl(event) {
        switch (event.keyCode) {
            case KeyCode.ArrowLeft:
                this.toggleList(event, false);
                event.preventDefault();
                break;
            case KeyCode.ArrowRight:
                this.toggleList(event, true);
                event.preventDefault();
                break;
        }
    },

    keyboardControlIE(event) {
        switch (event.keyCode) {
            case KeyCode.Space: {
                this.changeByEvent(event);
                event.preventDefault();
                break;
            }
        }
    },

    toggleList(event, state) {
        const id = this._getCurrentIdByEvent(event);
        const models = this.collection.getById(id);

        models.forEach((currentModel) => {
            if (currentModel.attributes.items) {
                this.modelsHandler.set(currentModel, {
                    expanded: state || !currentModel.attributes.expanded,
                });
            }
        });

        return false;
    },

    changeByEvent(event) {
        const id = this._getCurrentIdByEvent(event);
        this.treeSelectorInterface.change([id]);
    },

    _getCurrentIdByEvent(event) {
        return $(event.target).closest('.Bloko-TreeSelector-Element').attr('data-id');
    },
});
