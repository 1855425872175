import Backbone from 'backbone';
import _ from 'underscore';

import Components from 'bloko/common/core/Components';
import Events from 'bloko/common/events';

import { ACTION_STATES } from './constants';
import { swipeMove, swipeStart, swipeEnd } from './swipeUtils';

const Swipe = Backbone.View.extend({
    events: {
        'touchstart .Bloko-Swipe': 'swipeStartTouch',
        'touchmove .Bloko-Swipe': 'swipeMoveTouch',
        'touchend .Bloko-Swipe': 'swipeEndTouch',
        'touchleave .Bloko-Swipe': 'swipeEndTouch',
        'mousedown .Bloko-Swipe': 'swipeStartMouse',
        'mousemove .Bloko-Swipe': 'swipeMoveMouse',
        'mouseup .Bloko-Swipe': 'swipeEndMouse',
        'mouseleave .Bloko-Swipe': 'swipeEndMouse',
        'MSPointerDown .Bloko-Swipe': 'swipeStartMouse',
        'MSPointerMove .Bloko-Swipe': 'swipeMoveMouse',
        'MSPointerUp .Bloko-Swipe': 'swipeEndMouse',
    },

    /**
     * @param {Object} options                             Параметры компонента
     * @param {Number} [options.quickSwipePercent=6]       Расстояние для QuickSwipe
     *                                                     в процентах от размера контейнера
     * @param {Number} [options.minSwipePercent=20]        Минимальное расстояние для свайпа
     *                                                     в процентах от размера контейнера
     * @param {Number} [options.maxBorderOffsetPercent=20] Расстояние от границ контейнера (в процентах от ширины),
     *                                                     при клике или quick-tap на которых необходимо
     *                                                     осуществлять свайп
     * @constructor
     */
    initialize(options) {
        this.$container = this.$('.Bloko-Swipe');
        this.quickSwipePercent = options.quickSwipePercent;
        this.minSwipePercent = options.minSwipePercent;
        this.maxBorderOffsetPercent = options.maxBorderOffsetPercent;

        this.setSwipeDetails = this.setSwipeDetails.bind(this);
    },

    swipeStartTouch(event) {
        this.type = ACTION_STATES.TOUCH;
        const touch = event.originalEvent.changedTouches[0];
        this.moveStart(touch.pageX, touch.pageY);
    },

    swipeMoveTouch(event) {
        if (this.type === ACTION_STATES.TOUCH) {
            const touch = event.originalEvent.changedTouches[0];
            this.swipeMove(event, touch.pageX, touch.pageY);
            return;
        }

        event.preventDefault();
    },

    swipeEndTouch() {
        if (this.type === ACTION_STATES.TOUCH) {
            this.swipeEnd();
            this.type = ACTION_STATES.NONE;
        }
    },

    swipeStartMouse(event) {
        if (this.type === ACTION_STATES.TOUCH) {
            return;
        }
        this.type = ACTION_STATES.MOUSE;
        this.moveStart(event.pageX, event.pageY);
    },

    swipeMoveMouse(event) {
        if (this.type === ACTION_STATES.MOUSE) {
            this.swipeMove(event, event.pageX, event.pageY);
        }
    },

    swipeEndMouse() {
        if (this.type === ACTION_STATES.MOUSE) {
            this.swipeEnd();
            this.type = ACTION_STATES.NONE;
        }
    },

    setSwipeDetails(values) {
        Object.keys(values).forEach((key) => {
            this[key] = values[key];
        });
    },

    moveStart(clientX, clientY) {
        swipeStart(clientX, clientY, {
            getSwipeDetails: (key) => this[key],
            setSwipeDetails: this.setSwipeDetails,
        });
    },

    swipeMove(event, clientX, clientY) {
        swipeMove(event, clientX, clientY, {
            onSwipeMove: (eventData) => this.trigger('bloko-swipe-move', eventData),
            onSwipeStart: (eventData) => this.trigger('bloko-swipe-start', eventData),
            getSwipeDetails: (key) => this[key],
            setSwipeDetails: this.setSwipeDetails,
        });
    },

    swipeEnd() {
        swipeEnd(this.quickSwipePercent, this.minSwipePercent, this.maxBorderOffsetPercent, {
            onSwipePrevent: (eventData) => this.trigger('bloko-swipe-prevent', eventData),
            onSwipeEnd: (eventData) => this.trigger('bloko-swipe-end', eventData),
            getContainerBoundingClientRect: () => ({
                width: this.$container.width(),
                left: this.$container.offset().left,
            }),
            getSwipeDetails: (key) => this[key],
            setSwipeDetails: this.setSwipeDetails,
        });
    },
});

export default Components.build({
    defaults: {
        quickSwipePercent: 6,
        minSwipePercent: 20,
        maxBorderOffsetPercent: 20,
    },
    create(element, params) {
        const swipeControl = new Swipe(_.extend({ el: element }, params));
        const publicInterface = Events.extend({});

        swipeControl
            /**
             * Cвайп не был закончен, необходимо откатить состояние к начальному
             *
             * @event bloko-swipe-prevent
             * @property {String} type тип события
             * @property {Number} distance расстояние от начального нажатия
             */
            .on('bloko-swipe-prevent', (event) => {
                publicInterface._trigger('bloko-swipe-prevent', event);
            })
            /**
             * Пользователь начал работать со свайпом
             *
             * @event bloko-swipe-start
             * @property {String} type тип события
             * @property {Number} clientX место клика
             */
            .on('bloko-swipe-start', (event) => {
                publicInterface._trigger('bloko-swipe-start', event);
            })
            /**
             * Cвайп закончился корректно, можно провести кастомную логику
             *
             * @event bloko-swipe-end
             * @property {String} type тип события
             * @property {Number} clientX место клика
             * @property {Number} distance расстояние от начального нажатия
             * @property {Boolean} isQuickSwipe был ли совершен QuickSwipe
             * @property {String} direction направление свайпа (`-1` или `1`)
             */
            .on('bloko-swipe-end', (event) => {
                publicInterface._trigger('bloko-swipe-end', event);
            })
            /**
             * Пользователь двигает пальцем или мышкой
             *
             * @event bloko-swipe-move
             * @property {String} type тип события
             * @property {Number} clientX место клика
             * @property {Number} distance расстояние от начального нажатия
             */
            .on('bloko-swipe-move', (event) => {
                publicInterface._trigger('bloko-swipe-move', event);
            });

        return publicInterface;
    },
});
