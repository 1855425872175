import { FC } from 'react';

import styles from 'bloko/blocks/metroPin/metro-pin.less';

interface MetroPinProps {
    /** Цвет */
    color?: string;
    /** Указывает на строку с компонентом в исходном коде в режиме разработки. Генерируется babel-plugin-react-source */
    source?: string;
}

const MetroPin: FC<MetroPinProps> = ({ source, color = '#ffffff' }) => (
    <span className={styles['bloko-metro-pin']} source={source} style={{ backgroundColor: color }} />
);

export default MetroPin;
