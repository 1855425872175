const isEmpty = (value) => [null, undefined].includes(value);

const isParamValid = (value) => {
    return isEmpty(value) || Array.isArray(value) || !['function', 'object'].includes(typeof value);
};

function makeSearchParams(params) {
    if (!(params && Object.prototype.toString.call(params) === '[object Object]')) {
        throw new TypeError('GET параметры должны быть объектом.');
    }

    if (!Object.values(params).every(isParamValid)) {
        throw new TypeError('Значение параметра должено быть массивом или примитивом');
    }

    return Object.entries(params)
        .filter((entry) => !isEmpty(entry[1]))
        .reduce((acc, [key, value]) => {
            if (Array.isArray(value)) {
                acc.push(...value.map((item) => [key, item]));
            } else {
                acc.push([key, value]);
            }

            return acc;
        }, []);
}

function paramsToQueryString(input) {
    const params = makeSearchParams(input);
    return new URLSearchParams(params).toString();
}

function parse(query) {
    return [...new URLSearchParams(query).entries()].reduce((acc, [key, value]) => {
        if (!acc[key]) {
            acc[key] = [value];
            return acc;
        }

        acc[key].push(value);
        return acc;
    }, {});
}

const DUMMY_PROTOCOL = 'https:';
const DUMMY_SEPARATOR = '//';
export const DUMMY_HOST = 'dummy.dummy';
const DUMMY_BASE = `${DUMMY_PROTOCOL}${DUMMY_SEPARATOR}${DUMMY_HOST}`;

function urlParser(urlString) {
    let href = urlString;
    if (!href) {
        href = '';
    }
    const url = new URL(href, DUMMY_BASE);

    const object = {
        params: parse(url.search),
        protocol: url.protocol,
        hostname: url.hostname,
        host: url.host,
        port: url.port,
        pathname: url.pathname,
        search: url.search,
        hash: url.hash,
    };

    defineProperties(object, true);

    return object;

    function defineProperties(object, enumerable) {
        Object.defineProperty(object, 'href', {
            get() {
                url.search = paramsToQueryString(object.params);
                url.hash = object.hash;
                return url.href.replace(DUMMY_BASE, '');
            },
            set(value) {
                url.href = value;
                object.params = parse(url.search);
            },
            enumerable,
        });

        Object.defineProperty(object, 'search', {
            get() {
                url.search = paramsToQueryString(object.params);
                return url.search;
            },
            set(value) {
                url.search = value;
                object.params = parse(url.search);
            },
            enumerable,
        });

        Object.defineProperty(object, 'host', {
            get() {
                return url.hostname.replace(DUMMY_HOST, '') + (url.port === '' ? '' : `:${url.port}`);
            },
            set(value) {
                const [host, port] = value.split(':');
                url.hostname = host;
                if (port) {
                    object.port = port;
                } else {
                    url.port = '';
                }
            },
            enumerable,
        });

        Object.defineProperty(object, 'hostname', {
            get() {
                return url.hostname.replace(DUMMY_HOST, '');
            },
            set(value) {
                url.hostname = value;
            },
            enumerable,
        });

        Object.defineProperty(object, 'port', {
            get() {
                return url.port === '0' || url.port === '80' ? '' : url.port;
            },
            set(value) {
                if (isNaN(parseInt(value, 10))) {
                    throw new TypeError('Порт должен быть числом');
                }
                url.port = value;
            },
            enumerable,
        });

        Object.defineProperty(object, 'protocol', {
            get() {
                return url.protocol;
            },
            set(value) {
                url.protocol = value;
            },
        });

        Object.defineProperty(object, 'pathname', {
            get() {
                return url.pathname.indexOf('/') === 0 ? url.pathname : `/${aContainer.url}`;
            },
            set(value) {
                url.pathname = value;
            },
        });
    }
}

urlParser.stringify = (input) => {
    if (!input) {
        return '';
    }

    let params = input;
    if (!Array.isArray(params)) {
        params = makeSearchParams(params);
    }
    return new URLSearchParams(params).toString();
};

export default urlParser;
