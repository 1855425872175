const MANY_MIN_VALUE = 5;
const MANY_MAX_VALUE = 20;
const ONE_VALUE = 1;
const SOME_MIN_VALUE = 2;
const SOME_MAX_VALUE = 4;

interface GetConversionTypeArgs {
    one: string;
    some: string;
    many: string;
    value: number;
}

/**
 * получение строки перевода с необходимым склонением по переданному числу
 * @param {Object} args - объект с параметрами функции
 * @param {string} args.one - Строка перевода для числа, остаток от деления на 10 которого равен 1
 * @param {string} args.some - Строка перевода для числа, остаток от деления на 10 которого находится в промежутке от 2 до 4
 * @param {string} args.many - Строка перевода для дробного числа или такого числа, для которого (число mod 100) находится в промежутке
 * от 5 до 20 или не попавшего под прочие условия
 * @param {number} args.value - Число, которое нужно склонять
 * @returns {string}
 */

const getConversionType = ({ one, some, many, value }: GetConversionTypeArgs): string => {
    const isFractionalNumber = Math.round(value) !== value;
    const valueMod10 = value % 10;
    const valueMod100 = value % 100;
    let conversionType: string;

    if (isFractionalNumber || (valueMod100 >= MANY_MIN_VALUE && valueMod100 <= MANY_MAX_VALUE)) {
        conversionType = many;
    } else if (valueMod10 === ONE_VALUE) {
        conversionType = one;
    } else if (valueMod10 >= SOME_MIN_VALUE && valueMod10 <= SOME_MAX_VALUE) {
        conversionType = some;
    } else {
        conversionType = many;
    }

    return conversionType;
};

export default getConversionType;
