import hoc, { IconProps } from 'bloko/blocks/icon/hoc';

const SkypeScaleSmallAppearanceDefault = hoc(
    (props: IconProps) => (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M8.02212 12.0351C5.79254 12.0351 4.77708 10.9166 4.77708 10.0833C4.77708 9.66665 5.10821 9.35963 5.54971 9.35963C6.49894 9.35963 6.25611 10.7851 8.04419 10.7851C8.94927 10.7851 9.47907 10.2368 9.47907 9.73244C9.47907 9.42542 9.30247 9.07454 8.70644 8.94296L6.71969 8.43858C5.13028 8.04384 4.84331 7.14472 4.84331 6.33331C4.84331 4.64472 6.41064 4.03068 7.88966 4.03068C9.25832 4.03068 10.8919 4.7763 10.8919 5.807C10.8919 6.24559 10.5166 6.48682 10.0972 6.48682C9.28039 6.48682 9.41284 5.34647 7.77929 5.34647C6.96251 5.34647 6.54309 5.71928 6.54309 6.24559C6.54309 6.77191 7.18326 6.94735 7.73514 7.07893L9.19209 7.40787C10.8036 7.75875 11.223 8.70173 11.223 9.60086C11.223 10.9605 10.1413 12.0351 8.02212 12.0351ZM14.1811 9.09647C14.2473 8.74559 14.2694 8.37279 14.2694 7.99998C14.2694 4.53507 11.4658 1.72805 8.00004 1.72805C7.62476 1.72805 7.27156 1.74998 6.91836 1.81577C6.36649 1.50875 5.74838 1.33331 5.06406 1.33331C3.01108 1.33331 1.33337 2.99998 1.33337 5.06138C1.33337 5.74121 1.50997 6.35524 1.81903 6.90349C1.7528 7.25437 1.73072 7.62717 1.73072 7.99998C1.73072 11.4649 4.53426 14.2719 8.00004 14.2719C8.37532 14.2719 8.72852 14.25 9.08172 14.1842C9.63359 14.4912 10.2517 14.6666 10.936 14.6666C12.989 14.6666 14.6667 13 14.6667 10.9386C14.6667 10.2807 14.4901 9.64472 14.1811 9.09647Z"
                fill="var(--bloko-icon-color, var(--bloko-icon-color-default))"
            />
        </svg>
    ),
    'Skype'
);
export default SkypeScaleSmallAppearanceDefault;
