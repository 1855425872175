import $ from 'jquery';

import { Click, MenuPlacement } from 'bloko/blocks/drop/Menu/menu';
import { IconColor, BarsScaleSmall } from 'bloko/blocks/icon';
import IconReactRenderer from 'bloko/blocks/icon/IconReactRenderer';
import {
    getAdaptiveSettings,
    getActiveTabMarginLeft,
    getClientX,
    isScrollExceedsThreshold,
} from 'bloko/common/adaptiveTabsHelper';
import Components from 'bloko/common/core/Components';
import blokoEvent from 'bloko/common/event';
import RequestAnimation from 'bloko/common/requestAnimation';
import Transition from 'bloko/common/transition';

import Bindings from 'bloko/blocks/tabs/bindings';
import leftGlareTemplate from 'bloko/blocks/tabs/leftGlare.mustache';
import moreButtonTemplate from 'bloko/blocks/tabs/moreButton.mustache';
import rightGlareTemplate from 'bloko/blocks/tabs/rightGlare.mustache';
import Tabs from 'bloko/blocks/tabs/tabs';
import tabsItemsTemplate from 'bloko/blocks/tabs/tabsItems.mustache';

const DROPDOWN_ITEM_CLASSES = 'bloko-drop-menu-item bloko-drop-menu-item_selectable Bloko-Tabs-Dropdown-Item';

function activateTabInDropdown($dropdownItems, activeTabIndex, dropdownActiveTabClass) {
    $dropdownItems.removeClass(dropdownActiveTabClass);
    $dropdownItems.eq(activeTabIndex).addClass(dropdownActiveTabClass);
}

function repositionGlares($rightGlare, $leftGlare, { showLeftGlare, showRightGlare }) {
    $rightGlare.toggleClass('bloko-tabs-right-glare_showed', showRightGlare);
    $leftGlare.toggleClass('bloko-tabs-left-glare_showed', showLeftGlare);
}

function moveTabsIntoTabsContainer($tabsContainer, $tabsItemsContainer, $tabs) {
    $tabsContainer.append($tabsItemsContainer);
    $tabsItemsContainer.append($tabs);
}

function createDropdownButton($tabs, $tabsContainer, menuParams) {
    const dropdownTabs = cloneTabsToDropdown($tabs);
    const $moreButton = $(moreButtonTemplate.render({ items: dropdownTabs }));

    Components.make(IconReactRenderer, $('.Bloko-Tabs-More-Icon', $moreButton).get(0), {
        IconComponent: BarsScaleSmall,
        iconProps: {
            initial: IconColor.Gray50,
        },
    });

    $tabsContainer.append($moreButton);

    const $dropdown = $(Bindings.dropdownData, $moreButton);
    const $dropdownItems = $dropdown.children();

    const dropdownInstance = Components.make(Click, $moreButton.get(0), {
        ...menuParams,
        placement: MenuPlacement.BottomEnd,
        html: $moreButton.get(0).querySelector('.Bloko-Tabs-Dropdown-Data'),
    });

    return { $moreButton, $dropdownItems, $dropdown, dropdownInstance };
}

function addClickHandlerToDropdownButton(getTabs, $dropdown, dropdownInstance) {
    $dropdown.on('click', Bindings.dropdownItem, (event) => {
        const $tab = $(event.currentTarget);
        if ($tab[0].tagName === 'A') {
            return;
        }
        const index = $tab.index();
        getTabs().setActiveTab(index);
        dropdownInstance.hide();
    });
}

function cloneTabsToDropdown($tabs) {
    const dropdownTabs = [];
    $tabs.each((index, tab) => {
        const $tab = $(tab).clone().removeClass().addClass(DROPDOWN_ITEM_CLASSES);
        dropdownTabs.push($tab.get(0).outerHTML);
    });

    return dropdownTabs;
}

function changeTabsPosition($tabsContainer, tabsMarginLeft) {
    const currentMarginLeft = parseInt($tabsContainer.css('margin-left'), 10);

    if (currentMarginLeft !== tabsMarginLeft) {
        $tabsContainer[0].dispatchEvent(blokoEvent('Bloko-Tabs-Will-Move'));

        Transition($tabsContainer.get(0), () => {
            $tabsContainer[0].dispatchEvent(blokoEvent('Bloko-Tabs-Moved'));
        });
    }

    $tabsContainer.css({ 'margin-left': tabsMarginLeft });
}

/**
 * Срабатывает при начале смещения табов
 *
 * @event Bloko-Tabs-Will-Move
 */

/**
 * Управляет переключением и стилями табов
 * @param {Element} element Элемент, на котором создается компонент
 * @param {Object} params Параметры компонента (полностью проксируются в [Tabs](tabs.html#Параметры))
 * @param {String} [params.cssClasses.dropdownActiveTab='bloko-drop-menu-item_selected'] CSS класс,
 * добавляемый к заголовку активного таба в дропдауне
 * @param {Object} menuParams параметры [меню](dropMenu.html#Параметры)
 * @constructor
 */
function AdaptiveTabs(element, params) {
    const $element = $(element);
    const $tabsContainer = $(Bindings.container, $element);
    const $tabs = $tabsContainer.find(Bindings.tab);
    const $leftGlare = $(leftGlareTemplate.render());
    const $rightGlare = $(rightGlareTemplate.render());
    const $tabsItemsContainer = $(tabsItemsTemplate.render());
    let currentlyActiveElement = null;

    moveTabsIntoTabsContainer($tabsContainer, $tabsItemsContainer, $tabs);

    let tabs;

    const { $moreButton, $dropdownItems, $dropdown, dropdownInstance } = createDropdownButton(
        $tabs,
        $tabsContainer,
        params.menuParams
    );

    let initCompleted = false;

    const adaptiveHandler = (elementToActivate, force = false) => {
        if (initCompleted && (elementToActivate !== currentlyActiveElement || force)) {
            const tabsMarginLeft = getActiveTabMarginLeft({
                tabsWrapperElement: $element[0],
                tabsContainerElement: $tabsContainer[0],
                tabsItemsElement: $tabsItemsContainer[0],
                activeTabElement: elementToActivate,
            });
            const metrics = getAdaptiveSettings({
                tabsWrapperElement: $element[0],
                tabsItemsElement: $tabsItemsContainer[0],
                tabsMarginLeft,
            });
            changeTabsPosition($tabsContainer, metrics.tabsMarginLeft);
            repositionGlares($rightGlare, $leftGlare, metrics);

            $moreButton.toggleClass('bloko-tabs__icon-container_hidden', !metrics.isAdaptiveMode);

            if (!metrics.isAdaptiveMode || $element.is(':hidden')) {
                dropdownInstance.hide();
            }

            currentlyActiveElement = elementToActivate;
        }
    };

    $tabs.on('focus', (event) => adaptiveHandler(event.target));

    const scrollTabs = RequestAnimation((event, currentMarginLeft, startScrollX) => {
        const endScrollX = getClientX(event);

        const scrollMetrics = getAdaptiveSettings({
            tabsWrapperElement: $element[0],
            tabsItemsElement: $tabsItemsContainer[0],
            tabsMarginLeft: currentMarginLeft + endScrollX - startScrollX,
        });

        changeTabsPosition($tabsContainer, scrollMetrics.tabsMarginLeft);
        repositionGlares($rightGlare, $leftGlare, scrollMetrics);
    });

    function getTabs() {
        return tabs;
    }

    const onTabsInit = (data) => {
        $tabsContainer.append($leftGlare).append($rightGlare);

        addClickHandlerToDropdownButton(getTabs, $dropdown, dropdownInstance);
        activateTabInDropdown($dropdownItems, data.index, params.cssClasses.dropdownActiveTab);
        initCompleted = true;
        adaptiveHandler($tabs.eq(data.index).get(0));
    };

    const onTabsChanged = (data) => {
        activateTabInDropdown($dropdownItems, data.index, params.cssClasses.dropdownActiveTab);
        adaptiveHandler($tabs.eq(data.index).get(0));
    };

    const onScrollStart = (event) => {
        const startMarginLeft = parseInt($tabsContainer.css('margin-left'), 10);
        const startScrollX = getClientX(event);

        let scrollOnThreshold = true;

        const moveHandler = (event) => {
            if (!scrollOnThreshold) {
                scrollTabs(event, startMarginLeft, startScrollX);
                return;
            }

            $tabsContainer.addClass('bloko-tabs-scroll');
            if (isScrollExceedsThreshold(startScrollX, event)) {
                scrollOnThreshold = false;
                $tabsContainer.addClass('bloko-tabs-scroll_ongoing');
            }
        };

        const endHandler = () => {
            $tabsContainer.removeClass('bloko-tabs-scroll bloko-tabs-scroll_ongoing');
            $(document).off('touchmove mousemove', moveHandler);
            $(document).off('touchend mouseup', endHandler);
        };

        $(document).on('touchmove mousemove', moveHandler);
        $(document).on('touchend mouseup', endHandler);
    };

    element.addEventListener('Bloko-Tabs-Init', ({ detail }) => onTabsInit(detail));
    element.addEventListener('Bloko-Tabs-Changed', ({ detail }) => onTabsChanged(detail));

    $tabs.on('touchstart mousedown', onScrollStart);

    tabs = Components.make(Tabs, element, params);

    $(window).on(
        'resize',
        RequestAnimation(() => adaptiveHandler(currentlyActiveElement, true))
    );

    Transition($tabsContainer.get(0), () => {
        $tabsContainer[0].dispatchEvent(blokoEvent('Bloko-Tabs-Moved'));
    });

    $tabs.each((index, tab) => {
        $(tab).attr('draggable', false);
    });

    return {
        tabs,
    };
}

export default Components.build({
    defaults: {
        cssClasses: {
            dropdownActiveTab: 'bloko-drop-menu-item_selected',
        },
    },
    create: AdaptiveTabs,
});
