import supports from 'bloko/common/supports';

import Polyfill from 'bloko/common/storage/Polyfill';
import wrapper from 'bloko/common/storage/Wrapper';

/**
 * Возвращает объект, содержащий методы для работы
 * с localStorage: <br/>
 * — если localStorage поддерживается, то этот объект — обертка, <br/>
 * — если нет, то этот объект — полифил <br/>
 *
 * Методы те же, что и у нативного localStorage, за исключением
 * свойства `length` — вместо него используйте метод `getLength()`
 *
 * @see {@link bloko/common/storage/Wrapper} (Обертка)
 * @see {@link bloko/common/storage/Polyfill} (Полифил)
 *
 * @type {Object}
 * @exports bloko/common/storage/LocalStorageWrapper
 */
const localStorageWrapper = supports.localStorage() ? wrapper(window.localStorage) : new Polyfill();

export default localStorageWrapper;
