import Backbone from 'backbone';
import $ from 'jquery';
import _ from 'underscore';

import { IconColor, LupaScaleSmallKindDefaultAppearanceOutlined } from 'bloko/blocks/icon';
import IconReactRenderer from 'bloko/blocks/icon/IconReactRenderer';
import Keyboard, { KeyCode } from 'bloko/common/constants/keyboard';
import Components from 'bloko/common/core/Components';
import blokoEvent from 'bloko/common/event';
import Events from 'bloko/common/events';
import FuzzySearch from 'bloko/common/fuzzySearch';
import { Breakpoint, getBreakpoint } from 'bloko/common/media';
import Metrics from 'bloko/common/metrics';

import customSelectTemplate from 'bloko/blocks/customSelect/customSelect.mustache';
import customSelectOptionTemplate from 'bloko/blocks/customSelect/customSelectOption.mustache';
import customSelectOptionsTemplate from 'bloko/blocks/customSelect/customSelectOptions.mustache';
import customSelectPlaceholderTemplate from 'bloko/blocks/customSelect/customSelectPlaceholder.mustache';

const BLUR_TIMEOUT = 150;
const THROTTLE_SEARCH = 200;
const CURRENT_INDEX_EMPTY = -1;
const cssClasses = {
    hidden: 'g-hidden',
    focused: 'bloko-custom-select__select_focus',
    selected: 'bloko-select-dropdown-option_selected',
    optionFocused: 'bloko-select-dropdown-option_focused',
    disabled: 'bloko-custom-select_disabled',
    disabledOption: 'bloko-select-dropdown-option_disabled',
    customOptions: 'Bloko-CustomSelect-OptionItem',
    customSelect: 'Bloko-CustomSelect',
    disabledOptionBindings: 'Bloko-CustomSelect-OptionItemDisabled',
    layer: {
        floating: 'bloko-custom-select__content_layer-floating',
        overlay: 'bloko-custom-select__content_layer-overlay',
        topmost: 'bloko-custom-select__content_layer-topmost',
        'above-content': 'bloko-custom-select__content_layer-above-content',
        'overlay-content': 'bloko-custom-select__content_layer-overlay-content',
        'above-overlay-content': 'bloko-custom-select__content_layer-above-overlay-content',
    },
};
const bindings = {
    nativeSelect: '.Bloko-CustomSelect-NativeSelect',
    customSelect: '.Bloko-CustomSelect',
    customOptionsContainer: '.Bloko-CustomSelect-OptionsContainer',
    customOptionsList: '.Bloko-CustomSelect-Options',
    customOptions: '.Bloko-CustomSelect-OptionItem',
    customSelectPlaceholder: '.Bloko-CustomSelect-Selected',
    searchInput: '.Bloko-CustomSelect-Search',
    searchInputIcon: '.Bloko-CustomSelect-SearchIcon',
    searchInputContainer: '.Bloko-CustomSelect-SearchContainer',
};

let atLeastOneInstanceShowed = false;

const CustomSelect = Backbone.View.extend({
    events: {
        'click .Bloko-CustomSelect': 'show',
        'click .Bloko-CustomSelect-OptionItem': '_change',
        'mousemove .Bloko-CustomSelect-OptionItem': '_mouseMove',
        'focus .Bloko-CustomSelect-OptionsContainer': '_focusList',
        'blur .Bloko-CustomSelect-OptionsContainer': '_blurList',
        'keydown .Bloko-CustomSelect': '_keyboardControlOnSelect',
        'keydown .Bloko-CustomSelect-OptionsContainer': '_keyboardControlOnOptions',
        'keypress .Bloko-CustomSelect-OptionsContainer': '_nativeSearch',
        'keypress .Bloko-CustomSelect': '_nativeSearch',
        'input .Bloko-CustomSelect-Search': '_changeSearchInput',
        'change .Bloko-CustomSelect-Search': '_changeSearchInput',
    },

    initialize(options) {
        this._customSelectOptionTemplate = options.optionTemplate;
        this._customPlaceholderTemplate = options.placeholderTemplate;
        this._selectIndex = 0;
        this._isOpen = false;
        this._isDisabled = options.disabled;
        this._filterQuery = '';
        this._searchDateStart = new Date();
        this._isSearch = options.search;
        this._searchPlaceholder = options.searchPlaceholder;
        this._afterOptionsData = options.afterOptionsData;
        this._afterOptionsTemplate = options.afterOptionsTemplate;
        this.$select = this.$(bindings.nativeSelect);

        this._dataJSON = options.templateJSON.map((itemData, index) => {
            if (itemData.selected) {
                this._selectIndex = index;
            }

            return {
                index,
                data: itemData,
            };
        });

        this.$customSelectContainer = $(this.template(this._getTemplateJSON()));
        this.$customSelect = this.$customSelectContainer.filter(bindings.customSelect);
        this.$customOptionsContainer = this.$customSelectContainer.filter(bindings.customOptionsContainer);
        this.$customOptionsContainer.addClass(cssClasses.layer[options.layer]);
        this.$customOptions = $(bindings.customOptions, this.$customOptionsContainer);
        this.$customOptions.eq(this._selectIndex).addClass(cssClasses.selected);
        this.$customOptionsList = $(bindings.customOptionsList, this.$customOptionsContainer);
        this.$customSelectPlaceholder = $(bindings.customSelectPlaceholder, this.$customSelect);
        this.$searchInput = $(bindings.searchInput, this.$customOptionsContainer);
        const searchIcon = $(bindings.searchInputIcon, this.$customOptionsContainer).get(0);

        if (searchIcon) {
            Components.make(IconReactRenderer, searchIcon, {
                IconComponent: LupaScaleSmallKindDefaultAppearanceOutlined,
                iconProps: {
                    initial: IconColor.Gray80,
                },
            });
        }

        this._dataJSON = this._dataJSON.map((item, index) => {
            item.searchText = this.$customOptions.eq(index).text().toLowerCase().trim();
            return item;
        });

        this._createNativeOptions();
        this._checkDisabledAll(this._dataJSON);
        this.$el.append(this.$customSelectContainer);
        this.$select.addClass(cssClasses.hidden);
        this.$select.prop('disabled', false);

        if (this._isDisabled) {
            this.disable();
        }

        this._focusIndex = this._selectIndex;
    },

    _getTemplateJSON() {
        return $.extend(
            {
                options: this._dataJSON,
                placeholder: this._dataJSON[this._selectIndex] && this._dataJSON[this._selectIndex].data,
                search: this._isSearch,
                searchPlaceholder: this._searchPlaceholder,
            },
            this._afterOptionsData
        );
    },

    _createNativeOptions() {
        this.$select.html(this._prepareNativeOptions(this._dataJSON));
    },

    _addToNativeSelect(dataJSON) {
        this.$select.append(this._prepareNativeOptions(dataJSON));
    },

    _prepareNativeOptions(dataJSON) {
        return dataJSON.map((item, index) => {
            const $option = $('<option>').text(item.data.text).val(item.data.value);

            if (item.data.selected) {
                this._selectIndex = index;
                $option.prop('selected', true);
            }

            if (item.data.disabled) {
                $option.prop('disabled', true);
            }

            return $option;
        });
    },

    _keyboardControlOnSelect(event) {
        switch (event.keyCode) {
            case KeyCode.ArrowUp:
            case KeyCode.ArrowDown:
                this.show();
                event.preventDefault();
                break;
            case KeyCode.ESC:
                this.hide();
                break;
            case KeyCode.Enter:
                $(this.$select.prop('form')).submit();
                break;
        }
    },

    _checkDisabledAll(dataJSON) {
        this._isDisabledOptions = !dataJSON.some((item) => {
            return !item.data.disabled;
        });
    },

    _keyboardControlOnOptions(event) {
        switch (event.keyCode) {
            case KeyCode.ArrowUp:
                event.preventDefault();
                if (!this.$customOptions.length || this._isDisabledOptions) {
                    break;
                }

                this._keyUp();
                this._focusItems();
                this._scrollToFocus();
                break;
            case KeyCode.ArrowDown:
                event.preventDefault();
                if (!this.$customOptions.length || this._isDisabledOptions) {
                    break;
                }

                this._keyDown();
                this._focusItems();
                this._scrollToFocus();
                break;
            case KeyCode.Enter:
                event.preventDefault();
                if (!this.$customOptions.length) {
                    break;
                }

                this._change(event);
                break;
            case KeyCode.ESC:
                this.hide();
                break;
            case KeyCode.Tab:
                event.preventDefault();
                if (this._isOpen) {
                    break;
                }

                this.hide();
                break;
        }
    },

    _keyUp() {
        do {
            this._focusIndex = (this._focusIndex <= 0 ? this._dataJSON.length : this._focusIndex) - 1;
        } while (this.$customOptions.eq(this._focusIndex).hasClass(cssClasses.disabledOptionBindings));
    },

    _keyDown() {
        do {
            this._focusIndex = this._focusIndex === this._dataJSON.length - 1 ? 0 : this._focusIndex + 1;
        } while (this.$customOptions.eq(this._focusIndex).hasClass(cssClasses.disabledOptionBindings));
    },

    _changeSearchInput() {
        if (!this._isOpen) {
            return;
        }

        this.$customOptions.removeClass(cssClasses.hidden).removeClass(cssClasses.disabledOptionBindings);
        const searchResult = this._dataJSON.filter((item) => {
            const isIndexOf = FuzzySearch.match(this.$searchInput.val(), item.searchText);

            if (this._isSearch && !isIndexOf) {
                this.$customOptions
                    .eq(item.index)
                    .addClass(cssClasses.hidden)
                    .addClass(cssClasses.disabledOptionBindings);
            }

            if (item.disabled) {
                this.$customOptions.eq(item.index).addClass(cssClasses.disabledOptionBindings);
            }

            return isIndexOf;
        });

        this._checkDisabledAll(searchResult);

        if (this._isDisabledOptions) {
            this.$customOptions.removeClass(cssClasses.optionFocused);
            this._focusIndex = CURRENT_INDEX_EMPTY;
        } else {
            this._focusSearchItem(searchResult);
        }
    },

    _nativeSearch(e) {
        if (this._isSearch && this._isOpen) {
            return;
        }

        const char = Keyboard.getChar(e);

        if (!char) {
            return;
        }

        if (new Date() - this._searchDateStart > THROTTLE_SEARCH) {
            this._filterQuery = '';
        }

        this._searchDateStart = new Date();
        this._filterQuery += char;

        const filtered = this._dataJSON.filter((item) => {
            return FuzzySearch.match(this._filterQuery, item.searchText);
        });

        if (this._isOpen) {
            this._focusSearchItem(filtered);
        } else if (filtered.length && !filtered[0].disabled) {
            this._selectIndex = filtered[0].index;
            this._change();
        }
    },

    _focusSearchItem(filter) {
        if (filter.length && !filter[0].disabled) {
            this._focusIndex = filter[0].index;
            this._focusItems();
            this._scrollToFocus();
        } else {
            this.$customOptions.removeClass(cssClasses.optionFocused);
            this._focusIndex = CURRENT_INDEX_EMPTY;
        }
    },

    _scrollToFocus() {
        if (!this._isOpen) {
            return;
        }

        const metricsFocusedItem = Metrics.getRelativeMetrics(this.$customOptions.eq(this._focusIndex).get(0));
        const visibleHeight = this._metricsOptionsList.height - metricsFocusedItem.top - metricsFocusedItem.height;
        let scrollTopToOption = metricsFocusedItem.top + this.$customOptionsList.scrollTop();

        if (visibleHeight < 0) {
            this.$customOptionsList.scrollTop(scrollTopToOption);
        } else if (metricsFocusedItem.top < 0) {
            scrollTopToOption = scrollTopToOption + metricsFocusedItem.height - this._metricsOptionsList.height;
            this.$customOptionsList.scrollTop(scrollTopToOption);
        }
    },

    _mouseMove(event) {
        /* Проверка нужна,так как при скроле выпадающего списка (с помощью клавиатуры) тригирнутся события мыши*/
        if (this._mouseCoordsY === event.clientY) {
            return;
        }

        let $target = $(event.target);
        this._mouseCoordsY = event.clientY;

        while (!$target.hasClass(cssClasses.customOptions)) {
            $target = $target.parent();
        }

        if ($target.hasClass(cssClasses.disabledOptionBindings)) {
            return;
        }

        this._focusIndex = this.$customOptions.index($target);
        this._focusItems();
    },

    _focusItems() {
        this.$customOptions.removeClass(cssClasses.optionFocused);
        this.$customOptions.eq(this._focusIndex).addClass(cssClasses.optionFocused);
    },

    _blurList() {
        atLeastOneInstanceShowed = false;
        this._handlerBlur = window.setTimeout(() => {
            this.hide(true);
        }, BLUR_TIMEOUT);
    },

    _focusList() {
        clearTimeout(this._handlerBlur);
    },

    _change(event) {
        if (event && this._focusIndex === CURRENT_INDEX_EMPTY) {
            this.hide();
            return null;
        }

        if (event) {
            this._selectIndex = this._focusIndex;
        }

        const selectedItemData = this._dataJSON[this._selectIndex].data;

        this.$customOptions.removeClass(cssClasses.selected);
        this.$select.val(selectedItemData.value);
        this.$customOptions.eq(this._selectIndex).addClass(cssClasses.selected);
        this.$customSelectPlaceholder.html(this.templatePlaceholder(selectedItemData));
        /**
         * Триггерится на нативном select при изменении selected option.
         *
         * @event change
         */
        this.$select[0].dispatchEvent(blokoEvent('change'));
        this.trigger('Bloko-CustomSelect-Selected', selectedItemData);
        this.hide();
        return selectedItemData;
    },

    show() {
        if (this._isDisabled || this._isOpen) {
            return;
        }

        atLeastOneInstanceShowed = true;
        this.$customOptionsContainer.removeClass(cssClasses.hidden);
        this._metricsOptionsList = Metrics.getMetrics(this.$customOptionsList.get(0));
        this._isOpen = true;

        if (
            this._selectIndex >= 0 &&
            this._dataJSON[this._selectIndex] &&
            !this._dataJSON[this._selectIndex].data.disabled
        ) {
            this.$customOptions.eq(this._selectIndex).addClass(cssClasses.optionFocused);
        }

        if (this._isSearch && getBreakpoint() !== Breakpoint.XS) {
            this.$searchInput.focus();
        } else {
            this.$customSelect.addClass(cssClasses.focused);
            this.$customOptionsContainer.focus();
        }
    },

    hide(isBlurList) {
        if (!this._isOpen) {
            return;
        }

        this._isOpen = false;
        this.$customOptionsContainer.addClass(cssClasses.hidden);

        if (!atLeastOneInstanceShowed || !isBlurList) {
            this.$customSelect.focus();
        }

        this.$customSelect.removeClass(cssClasses.focused);
        this.$customOptions.removeClass(cssClasses.optionFocused);
        this._focusIndex = this._selectIndex;
    },

    change(value) {
        for (let i = 0; i < this._dataJSON.length; i++) {
            if (this._dataJSON[i].data.value === value) {
                this._selectIndex = i;
                return this._change();
            }
        }

        return null;
    },

    add(dataJSON) {
        const extendedDataJSON = $.extend(true, [], dataJSON).map((itemData) => {
            return { data: itemData };
        });
        const dataRender = {
            options: extendedDataJSON,
        };
        const $renderOption = $('<div>').append(this.templateOptions(dataRender)).children();
        this._addToNativeSelect(extendedDataJSON);

        extendedDataJSON.forEach((item, index) => {
            this._dataJSON.push({
                index: this._dataJSON.length,
                searchText: $renderOption.eq(index).text().trim().toLowerCase(),
                data: item.data,
            });

            if (item.data.selected) {
                this._selectIndex = this._dataJSON.length - 1;
            }
        });

        this._checkDisabledAll(this._dataJSON);
        this.$customOptionsList.append($renderOption);
        this.$customOptions = this.$(bindings.customOptions);
        this._change();
    },

    disable() {
        this.$customSelect.removeAttr('tabindex');
        this.$el.addClass(cssClasses.disabled);
        this._isDisabled = true;
        this.hide();
        this.$select.prop('disabled', true);
    },

    enable() {
        this.$customSelect.attr('tabindex', '0');
        this.$el.removeClass(cssClasses.disabled);
        this._isDisabled = false;
        this.$select.prop('disabled', false);
    },

    /**
     * Шаблонизатор, который управляет рендером шаблонов и partial'ов
     * кастомного Placeholder
     * @param  {Object} data Данные которые используются в шаблоне
     * @return {String} Готовый к вставке в DOM шаблон с данными
     * @private
     */
    templatePlaceholder(data) {
        return this._customPlaceholderTemplate.render(data);
    },

    /**
     * Шаблонизатор, который управляет рендером шаблонов и partial'ов
     * кастомных Option
     * @param  {Object} data Данные которые используются в шаблоне
     * @return {String} Готовый к вставке в DOM шаблон с данными
     * @private
     */
    templateOptions(data) {
        return customSelectOptionsTemplate.render(data, {
            customSelectOption: this._customSelectOptionTemplate,
        });
    },

    /**
     * Шаблонизатор, который управляет рендером шаблонов и partial'ов
     * всего customSelect
     * @param  {Object} data Данные которые используются в шаблоне
     * @return {String} Готовый к вставке в DOM шаблон с данными
     * @private
     */
    template(data) {
        const selectTemplate = {
            customSelectOptions: customSelectOptionsTemplate,
            customSelectOption: this._customSelectOptionTemplate,
            customSelectPlaceholder: this._customPlaceholderTemplate,
        };

        if (this._afterOptionsTemplate) {
            selectTemplate.customSelectAfterOptions = this._afterOptionsTemplate;
        }

        return customSelectTemplate.render(data, selectTemplate);
    },
});

export default Components.build({
    defaults: {
        disabled: false,
        search: false,
        templateJSON: [],
        layer: 'above-content',
        optionTemplate: customSelectOptionTemplate,
        placeholderTemplate: customSelectPlaceholderTemplate,
        afterOptionsTemplate: null,
        afterOptionsData: {},
    },

    /**
     * Триггерится при изменении selected option. Аргументом передается JSON представление Option.
     * Option становиться выбраным после события.
     * @event Bloko-CustomSelect-Selected
     */

    /**
     * Аргументом передается массив добавленных элементов
     *
     * @event Bloko-CustomSelect-Added
     */

    /**
     * Триггерится при изменении enabled select.
     * @event Bloko-CustomSelect-Enabled
     */

    /**
     * Триггерится при изменении disabled select.
     *
     * @event Bloko-CustomSelect-Disabled
     */

    /**
     * @exports bloko/blocks/customSelect/customSelect
     *
     * Создает кастомный select.
     *
     * @param {Element} element DOM элемент
     *
     * @param {Object} params Параметры
     *
     * @param {Boolean} [params.disabled=false] Флаг, заблокирован ли CustomSelect
     *
     * @param {Boolean} [params.search=false] Флаг, показывать строку ли поиска
     *
     * @param {String} [params.layer='above-content'] Слой, определяющий z-index для дропдауна. Доступные
     *                                                       значения: `'above-content'`, `'floating'`, `'overlay'`,
     *                                                       `'overlay-content'`, `'above-overlay-content'`,
     *                                                       `'topmost'`
     * @param {Object} [params.afterOptionsData={}] Объект входных данных для `afterOptionsTemplate`
     *
     * @param {Array} [params.templateJSON=[]] Массив входных данных, более подробное описание см. в
     * [формате данных](#Формат-данных)
     *
     * @param {Object} params.optionTemplate Кастомный шаблон для option,
     * подробнее о подключении шаблона см. [тут](#Кастомные-шаблоны). По умолчанию используется дефолтный шаблон
     *
     * @param {Object} params.placeholderTemplate Кастомный шаблон для placeholder,
     * подробнее о подключении шаблона см. [тут](#Кастомные-шаблоны). По умолчанию используется дефолтный шаблон
     *
     * @param {Object} [params.afterOptionsTemplate=null] Кастомный шаблон под списком option,
     *подробнее о подключении шаблона см. [тут](#Кастомные-шаблоны)
     *
     * @param {String|Null} [params.searchPlaceholder=null] Плейсхолдер поиска
     *
     * @constructor
     */
    create(element, params) {
        const customSelect = new CustomSelect(_.extend({ el: element }, params));

        const publicInterface = Events.extend({
            /**
             * Показывает список option
             */
            show() {
                customSelect.show();
            },
            /**
             * Скрывает список option
             */
            hide() {
                customSelect.hide();
            },
        });

        /**
         * Выбирает нужный селект
         * @param value value селекта
         */
        publicInterface.change = function (value) {
            customSelect.change(value);
        };

        /**
         * Динамически добавляет option в конец списка
         * @param {Array} dataJSON массив с списком добавляемых option.
         *                Формат соответствует формату входных данных,
         *                более подробное описание см. в [формате данных](#Формат-данных)
         * @fires Bloko-CustomSelect-Added
         */
        publicInterface.add = function (dataJSON) {
            customSelect.add(dataJSON);
            this._trigger('Bloko-CustomSelect-Added', $.extend(true, [], dataJSON));
        };

        /**
         * Устанавливает disabled состояние
         * @fires Bloko-CustomSelect-Disabled
         */
        publicInterface.disable = function () {
            customSelect.disable();
            this._trigger('Bloko-CustomSelect-Disabled');
        };

        /**
         * Снимает disabled состояние
         * @fires Bloko-CustomSelect-Enabled
         */
        publicInterface.enable = function () {
            customSelect.enable();
            this._trigger('Bloko-CustomSelect-Enabled');
        };

        customSelect.on('Bloko-CustomSelect-Selected', (item) => {
            publicInterface._trigger('Bloko-CustomSelect-Selected', item);
        });

        return publicInterface;
    },
});
