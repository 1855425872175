import { useRef, useState, useLayoutEffect, RefObject } from 'react';

import { DEBOUNCE_DELAY_MS } from 'bloko/blocks/drop/constants';
import debounce from 'bloko/common/debounce';

interface UseDropResize {
    selectInputRef: RefObject<HTMLDivElement>;
    dropStyle: { minWidth: string };
}

const useDropResize = (): UseDropResize => {
    const selectInputRef = useRef<HTMLDivElement>(null);
    const [dropStyle, setDropStyle] = useState({ minWidth: 'none' });
    useLayoutEffect(() => {
        const handleResize = () => {
            if (!selectInputRef.current) {
                return;
            }
            setDropStyle({ minWidth: `${selectInputRef.current.offsetWidth}px` });
        };
        handleResize();
        const debouncedHandleResize = debounce(handleResize, DEBOUNCE_DELAY_MS);
        window.addEventListener('resize', debouncedHandleResize);
        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
        };
    }, []);

    return { selectInputRef, dropStyle };
};

export default useDropResize;
