/* eslint-disable import/prefer-default-export */
import styles from 'bloko/common/styles/media-marker.less';

export enum Breakpoint {
    XS = 'xs',
    S = 's',
    M = 'm',
    L = 'l',
}

// XS взят потому, что это основной экран на мобильниках, а там всё гораздо
// хуже с интернетом, чем на десктопах, и иногда можно довольно долго ждать,
// пока доедет JS-бандл, сработает эффект и поставится правильный брейкпоинт.
export const DEFAULT_BREAKPOINT = Breakpoint.XS;

const detectBreakpointValue = function (style: CSSStyleDeclaration) {
    if (!style) {
        return DEFAULT_BREAKPOINT;
    }

    const value = style.getPropertyValue('font-family').replace(/['"]/g, '');

    if (!value) {
        return DEFAULT_BREAKPOINT;
    }

    return Object.values(Breakpoint).find((size) => size === value) || DEFAULT_BREAKPOINT;
};

const createMediaMarker = function () {
    const element = document.createElement('div');
    element.className = styles['bloko-media-marker'];
    document.body.appendChild(element);
    return element;
};

let mediaMarker: HTMLDivElement;

/**
 * Принудительное вычисление текущего значения media-выражения.
 * Более затратное, но и более точное, чем `getBreakpoint()`.
 */
export const calculateCurrentBreakpoint = (): Breakpoint => {
    if (!('getComputedStyle' in window)) {
        return DEFAULT_BREAKPOINT;
    }
    mediaMarker = mediaMarker || createMediaMarker();
    const style = window.getComputedStyle(mediaMarker);
    return detectBreakpointValue(style);
};

// Сюда сохраняется новое значение после каждого `resize`.
let storedBreakpoint: Breakpoint = DEFAULT_BREAKPOINT;
const updateStoredBreakpoint = () => {
    storedBreakpoint = calculateCurrentBreakpoint();
};

if (typeof window !== 'undefined') {
    // Нужно подписаться на `resize` как можно раньше, чтобы в другом коде
    // на момент выполнения обработчиков того же `resize` уже было
    // проставлено правильное значение брейкпоинта.
    window.addEventListener('resize', updateStoredBreakpoint);
    updateStoredBreakpoint();
}

/**
 * Возвращает значение media-выражения, сохранённое после последнего
 * `resize` или загрузки страницы. На сервере будет дефолтное значение,
 * на клиенте — измеренное.
 */
export const getBreakpoint = (): Breakpoint => storedBreakpoint;
