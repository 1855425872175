import Backbone from 'backbone';
import $ from 'jquery';

import {
    IconColor,
    CheckmarkScaleSmallKindSingleAppearanceOutlinedEnclosedFalse,
    CrossScaleSmallEnclosedFalse,
    PenSquareScaleSmall,
    TrashScaleSmall,
} from 'bloko/blocks/icon';
import IconReactRenderer from 'bloko/blocks/icon/IconReactRenderer';
import { KeyCode } from 'bloko/common/constants/keyboard';
import Components from 'bloko/common/core/Components';
import TextSelection from 'bloko/common/textSelection';

import editableAction from 'bloko/blocks/tagList/editableAction.mustache';
import editableSection from 'bloko/blocks/tagList/editableSection.mustache';
import hiddenSection from 'bloko/blocks/tagList/hiddenSection.mustache';
import inlineTemplate from 'bloko/blocks/tagList/inline.mustache';
import removeSection from 'bloko/blocks/tagList/removeSection.mustache';
import textSection from 'bloko/blocks/tagList/textSection.mustache';
import textTemplate from 'bloko/blocks/tagList/textTemplate.mustache';

const BLUR_TIMEOUT = 250;

/**
 * Список тегов. Может содержать инлайн-теги либо быть древовидным списком.
 *
 * @param {Element} element
 * @param {Object} options
 * @constructor
 */
export default Backbone.View.extend({
    tagName: 'span',
    className: 'Bloko-TagList',
    /**
     * Значения параметров компонента по-умолчанию
     *
     * @property {String} hiddenFieldName название скрытого поля для тегов
     * @property {Boolean} defaultSelected выполняется ли действие по-умолчанию
     * @property {Object} cssClasses список ccs классов, для внутреннего использования.
     *                                  На данный момент поддерживается только класс animated.
     * @property {Object} bindings названия классов компонентов
     */
    _defaults: {
        hiddenFieldName: '',
        defaultSelected: true,
        cssClasses: {
            animated: 'bloko-tag_animated',
        },
        bindings: {
            tag: '.Bloko-TagList-Tag',
        },
    },

    /**
     * Функция-шаблонизатор, которая управляет рендером шаблонов и partial'ов
     *
     * @param  {Object} data Данные которые используются в шаблоне
     * @return {String}      Готовый к вставке в DOM шаблон с данными
     */
    template(data) {
        return inlineTemplate
            .render(data, {
                textSection,
                textTemplate: this.options.textTemplate || textTemplate,
                hiddenSection,
                removeSection,
                editableSection,
                editableAction,
            })
            .trim();
    },

    makeTemplateData(item) {
        return {
            items: [].concat(item.toJSON()),
            hidden: this.options.hiddenFieldName,
            stretched: this.options.stretched,
        };
    },

    events: {
        'click .Bloko-TagList-Remove': 'removeTagOnClick',
        'click .Bloko-TagList-Selectable': 'selectTag',
        'click .Bloko-TagList-EditCancel': 'cancelEdit',
        'click .Bloko-TagList-EditAction': 'editAction',
        'click .Bloko-TagList-EditDone': 'editDone',
        'blur .Bloko-TagList-EditInput': 'blurEditInput',
        'keydown .Bloko-TagList-EditInput': 'keyDown',
    },

    initialize(params) {
        this.tags = this.collection;

        this.options = $.extend({}, this._defaults, params.options);
        // this.renderIcons?.();

        this.listenTo(this.tags, 'add', this.renderTagAdd);
        this.listenTo(this.tags, 'change', this.renderTagChange);
        this.listenTo(this.tags, 'remove', this.renderTagRemove);
    },

    /**
     * Инициализирует теги, которые уже были заданы в верстке на момент инициализации компонента
     */
    initTags() {
        const existingTags = [];
        $('.Bloko-TagList-Tag', this.$el).each((index, element) => {
            const $element = $(element);

            const id = $element.attr('data-tag-id');

            existingTags.push({
                id,
                text: $element.text().trim(),
                hiddenValue: id,
            });
        });

        this.setTags(existingTags);
    },

    /**
     * Возвращает jQuery объект с тегом с соответствующим id
     *
     * @param  {Number} tagId id тега, который надо найти
     * @return {Object}       jQuery Object для тега с переданным id
     */
    $nodeByTagId(tagId) {
        return this.$(`[data-tag-id="${this.escapeId(tagId)}"]`);
    },

    /**
     * Используется для экранирования символов " и \ при конкатенации со строкой.
     * Другие символы не экранируются так как только эти два могут нарушить конкатенацию строки,
     * что приводит к проблемам при попытке сгенерировать jQuery селектор и пр.
     *
     * @param  {String|Number} id значение которое нужно экранировать
     * @return {String} экранированное значение
     */
    escapeId(id) {
        return id.toString().replace(/\\/g, '\\\\').replace(/"/g, '\\"');
    },

    /**
     * Рендерит теги, которые были добавлены, после чего
     * для новых тегов добавляет css класс с анимацией
     *
     * @param {Object} item модель, которая была добавлена
     *
     * @fires Bloko-TagList-Added
     *
     */
    renderTagAdd(item) {
        const $rendered = this.$nodeByTagId(item.get('id'));
        if ($rendered.length === 0) {
            this.$el.append(this.template(this.makeTemplateData(item)));
            this.renderIcons?.(item);
        }
        /**
         * Событие вызываемое при добавлении нового тега в DOM
         *
         * @event Bloko-TagList-Added
         * @type {Object}
         * @property {Object} item модель, которая была добавлена в коллекцию
         */
        this.trigger('Bloko-TagList-Added', item.toJSON());

        return this;
    },

    /**
     * Рендерит тег, в модели которого произошли изменения
     *
     * @param  {Object} item модель в которой произошли изменения
     * @return {Object}      this для возможности чейнинга
     *
     * @fires Bloko-TagList-Changed
     */
    renderTagChange(item) {
        const tag = this.$nodeByTagId(item.get('id'));

        tag.replaceWith(this.template(this.makeTemplateData(item)));
        this.renderIcons?.(item);

        /**
         * Событие вызываемое при изменении тега в DOM
         *
         * @event Bloko-TagList-Changed
         * @type {Object}
         * @property {Object} item модель, которая была изменена
         */
        this.trigger('Bloko-TagList-Changed', item.toJSON());
        return this;
    },

    /**
     * Удаляет из DOM теги, которые были удалены
     *
     * @param {Object} item модель, которая была удалена
     *
     * @fires Bloko-TagList-Removed
     */
    renderTagRemove(item) {
        this.$nodeByTagId(item.get('id')).remove();

        /**
         * Событие вызываемое при удалении тега из DOM
         *
         * @event Bloko-TagList-Removed
         * @type {Object}
         * @property {Object} item модель, которая была добавлена в коллекцию
         */
        this.trigger('Bloko-TagList-Removed', item.toJSON());

        return this;
    },

    renderIcons(item) {
        const tagElement = this.$nodeByTagId(item.get('id')).get(0);

        if (tagElement) {
            this.renderRemoveIcon(tagElement);
            this.renderIcon(tagElement, '.Bloko-TagList-EditAction', PenSquareScaleSmall);
            this.renderIcon(tagElement, '.Bloko-TagList-EditCancel', CrossScaleSmallEnclosedFalse);
            this.renderIcon(
                tagElement,
                '.Bloko-TagList-EditDone',
                CheckmarkScaleSmallKindSingleAppearanceOutlinedEnclosedFalse,
                { initial: IconColor.Green60 }
            );
            this.renderIcon(tagElement, '.Bloko-TagList-EditAction', PenSquareScaleSmall);
        }
    },

    renderRemoveIcon(element) {
        this.renderIcon(element, '.Bloko-TagList-Remove', TrashScaleSmall);
    },

    renderIcon(element, className, IconComponent, iconProps = {}) {
        const iconElements = element.querySelectorAll(className);

        for (const iconElement of [...iconElements]) {
            Components.make(IconReactRenderer, iconElement, {
                IconComponent,
                iconProps: {
                    initial: IconColor.Gray50,
                    highlighted: IconColor.Gray60,
                    ...iconProps,
                },
            });
        }
    },

    /**
     * Вызвает событие выбора тега.
     *
     * @param {Event} event DOM-событие
     *
     * @fires Bloko-TagList-ToggleSelected
     */
    selectTag(event) {
        const tag = this.getTagFromEvent(event);

        if (this.options.defaultSelected) {
            this.toggleSelect(tag.get('id'), !tag.get('selected'));
        }

        /**
         * Событие вызываемое при выборе тега
         *
         * @event Bloko-TagList-ToggleSelected
         * @type {Object}
         * @property {Object} item модель, по которой было действие
         */
        this.trigger('Bloko-TagList-ToggleSelected', tag.toJSON());

        return this;
    },

    /**
     * Задаёт список тегов.
     *
     * @param {Array} tags список тегов
     */
    setTags(tags) {
        tags = [].concat(tags);
        this.tags.set(tags, { parse: true });

        return this;
    },

    /**
     * Возвращает тег по переданному tagId.
     *
     * @param {Number} tagId тега
     */
    getTag(id) {
        return this.tags.get(id).toJSON();
    },

    /**
     * Возвращает список тегов.
     */
    getTags() {
        return this.tags.toJSON();
    },

    /**
     * Добавляет тег(и).
     *
     * @param {Array} tags список тегов
     */
    addTag(tags) {
        tags = [].concat(tags);
        this.tags.add(tags, { parse: true });

        return this;
    },

    /**
     * Находит тег по переданному tagId и удаляет его.
     *
     *  @param {Number} tagId тега
     */
    removeTag(tagId) {
        this.tags.remove(String(tagId));

        return this;
    },

    /**
     * Устанавливает text тега.
     *
     * @param {Number} tagId id тега
     * @param {String} text текст тега
     */
    setTagText(tagId, text) {
        const tag = this.tags.get(tagId);

        if (tag) {
            if (text.length === 0) {
                this.removeTag(tagId);
                return;
            }

            tag.set({
                edited: false,
                text,
            });
        }
    },

    /**
     * Выбирает тег.
     *
     * @param {Number} tagId id тега
     * @param {Boolean} selected выбран ли тег
     */
    toggleSelect(tagId, selected) {
        const tag = this.tags.get(tagId);

        if (tag) {
            tag.set('selected', selected);
        }
        return this;
    },

    /**
     * Переключает disabled состояние тега
     *
     * @param {Number} tagId id тега
     * @param {Boolean} disabled задизейблен ли тег
     */
    toggleDisabled(tagId, disabled) {
        const tag = this.tags.get(tagId);

        if (tag) {
            tag.set('disabled', disabled);
        }
        return this;
    },

    /**
     * Получить tagId из события.
     *
     * @param {HTMLElement} element
     * @returns {Number}
     */
    getTagId(element) {
        return $(element).attr('data-tag-id') || $(element).closest('.Bloko-TagList-Tag').attr('data-tag-id');
    },

    /**
     * Получить тег из события.
     *
     * @param event
     * @returns {Object}
     */
    getTagFromEvent(event) {
        const tagId = this.getTagId(event.currentTarget);
        return this.tags.get(tagId);
    },

    /**
     * Обработка удаления тега при возникновении события
     *
     * @private
     * @param  {Event} event событие клика мыши
     */
    removeTagOnClick(event) {
        const tagId = this.getTagId(event.currentTarget);

        this.removeTag(tagId);
    },

    /**
     * Обработчик отмены редактирования тега
     *
     * @private
     * @param {Event} event события мыши или клавиатуры
     */
    cancelEdit(event) {
        clearTimeout(this.blurTimeout);
        const tag = this.getTagFromEvent(event);

        if (tag.get('edited')) {
            this.setTagText(tag, tag.previous('text'));
        }
    },

    /**
     * Обработчик нажатия клавиш
     *
     * @private
     * @param {Event} event события клавиатуры
     */
    keyDown(event) {
        if (event.keyCode === KeyCode.Enter) {
            event.preventDefault();
            this.editDone(event);
        } else if (event.keyCode === KeyCode.ESC) {
            event.preventDefault();
            $(event.currentTarget).blur();
            this.cancelEdit(event);
        }
    },

    /**
     * Обработчик потери фокуса с input при редактировании тега
     *
     * @private
     * @param {Event} event события мыши или клавиатуры
     */
    blurEditInput(event) {
        this.blurTimeout = setTimeout(() => {
            this.editDone(event);
        }, BLUR_TIMEOUT);
    },

    /**
     * Обработчик начала редактирования тега
     *
     * @private
     * @param {Event} event собыия мыши
     */
    editAction(event) {
        const currentModel = this.getTagFromEvent(event);
        const $currentTag = $(event.currentTarget).closest('.Bloko-TagList-Tag');
        const textSectionWidth = $('.Bloko-TagList-SectionText', $currentTag).outerWidth();
        currentModel.set('edited', true);
        const $rendered = this.$nodeByTagId(currentModel.get('id'));
        const $editableInput = $('.Bloko-TagList-EditInput', $rendered);
        const inputWidth = this.calculateInputWidth($rendered, textSectionWidth);
        $editableInput.outerWidth(inputWidth);
        TextSelection.setCaretPosition($editableInput[0], $editableInput.val().length);
    },

    /**
     * Обработчик подтверждения редактирования тега
     *
     * @private
     * @param {Event} event события мыши
     */
    editDone(event) {
        clearTimeout(this.blurTimeout);
        const currentModel = this.getTagFromEvent(event);
        const $currentTag = $(event.currentTarget).closest('.Bloko-TagList-Tag');
        const $editableInput = $('.Bloko-TagList-EditInput', $currentTag);
        this.setTagText(currentModel.id, $editableInput.val().trim());
    },

    /**
     * Высчитывает ширину инпута с учётом кнопок, чтобы тег не выходил за границы контейнера
     *
     * @private
     * @param {jQuery} $tag
     * @param {Number} estimatedInputWidth
     * @returns {Number}
     */
    calculateInputWidth($tag, estimatedInputWidth) {
        let diff;
        const parentWidth = this.$el.width();
        let resultTagWidth = estimatedInputWidth;
        $('.Bloko-TagList-EditControl', $tag).each((index, element) => {
            resultTagWidth += $(element).outerWidth();
        });
        if (resultTagWidth > parentWidth) {
            diff = resultTagWidth - parentWidth;
            estimatedInputWidth -= diff;
        }
        return estimatedInputWidth;
    },

    /**
     * Обновляет значение hidden input
     *
     * @param id
     * @param value
     */
    setHiddenValue(id, value) {
        if (this.options.hiddenFieldName) {
            $('.Bloko-TagList-Value', this.$nodeByTagId(id)).val(value);
        }
    },
});
