import React, { memo, ReactNode, Ref } from 'react';
import classnames from 'classnames';

import BlokoTranslateGuard from 'bloko/blocks/translateGuard';
import { ComponentWithCustomElement } from 'bloko/common/helpers/types';

import ButtonGroup from 'bloko/blocks/button/ButtonGroup';

import styles from 'bloko/blocks/button/button.less';

export enum ButtonKind {
    Primary = 'primary',
    Secondary = 'secondary',
    Warning = 'warning',
    Success = 'success',
    Inversed = 'inversed',
}

export enum ButtonIconPosition {
    Left = 'left',
    Right = 'right',
}

export enum ButtonScale {
    Small = 'small',
    Large = 'large',
}

export enum ButtonType {
    Submit = 'submit',
    Button = 'button',
}

export enum ButtonAppearance {
    Filled = 'filled',
    Outlined = 'outlined',
    Flat = 'flat',
}

export interface ButtonProps {
    /** Размеры кнопки */
    scale?: ButtonScale;
    /** Тип компонента по назначению возможные варианты [ButonKinds](#button-kind) */
    kind?: ButtonKind;
    /** Тип кнопки по назначению */
    type?: ButtonType;
    /** Заливка */
    appearance?: ButtonAppearance;
    /** Нажата ли кнопка */
    pressed?: boolean;
    /** Заблокирована ли кнопка */
    disabled?: boolean;
    /** Функция для получения DOM элемента кнопки */
    innerRef?: Ref<HTMLElement>;
    /** Кастомный компонент 'a', 'button', 'span' или функция */
    Element?: 'a' | 'button' | 'span';
    /** onClick callback */
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    /** На всю длину */
    stretched?: boolean;
    /** Текст внутри отображается с многоточием, если не помещается */
    collapsible?: boolean;
    /** Валидность */
    invalid?: boolean;
    /** Отображать индикацию загрузки */
    loading?: ReactNode;
    /** Иконка */
    icon?: ReactNode;
    /** Положение иконки */
    iconPosition?: ButtonIconPosition;
}

const renderIcon = (icon?: ReactNode, iconPosition?: ButtonIconPosition) =>
    icon && (
        <span
            key="icon"
            className={classnames(styles['bloko-button__icon'], {
                [styles[`bloko-button__icon_${iconPosition as ButtonIconPosition}`]]: iconPosition,
            })}
        >
            {icon}
        </span>
    );

const renderChildren = (children?: ReactNode) => {
    return (
        <BlokoTranslateGuard useSpan key="children">
            {children}
        </BlokoTranslateGuard>
    );
};

const renderContent = (
    children?: ReactNode,
    loading?: ReactNode,
    icon?: ReactNode,
    iconPosition?: ButtonIconPosition
) => {
    if (loading) {
        return (
            <BlokoTranslateGuard useSpan key="children">
                <span className={styles['bloko-button__content']}>{children}</span>
                <span className={styles['bloko-button__loading']}>{loading}</span>
            </BlokoTranslateGuard>
        );
    }

    if (icon && iconPosition === 'right') {
        return [renderChildren(children), renderIcon(icon, iconPosition)];
    }

    return [renderIcon(icon, iconPosition), renderChildren(children)];
};

/**
 * Кнопка общего назначения, используется на различных формах, для реализации элементов управления.
 */
const Button: ComponentWithCustomElement<ButtonProps, 'button'> = ({
    scale,
    kind,
    type = ButtonType.Button,
    children,
    pressed,
    innerRef,
    Element = 'button',
    icon,
    iconPosition,
    stretched,
    collapsible,
    loading,
    invalid,
    appearance = ButtonAppearance.Filled,
    ...buttonProps
}) => {
    const additionalAttributes: { type?: ButtonType; [x: string]: unknown } = {};

    if (Element === 'button' && type) {
        additionalAttributes.type = type;
    }

    if (innerRef) {
        /**
         * Element может быть простой функцией по этому напрямую
         * нельзя назначать ref
         * А может быть forwardRef, ClassComponent и простой тег,
         * по этому в обход типизации назначаем ref как unknown
         */
        additionalAttributes.ref = innerRef;
    }

    return (
        <Element
            className={classnames(styles['bloko-button'], {
                [styles[`bloko-button_kind-${kind as ButtonKind}`]]: kind,
                [styles[`bloko-button_icon-only`]]: icon && children === undefined && !scale,
                [styles[`bloko-button_icon-only-${scale as ButtonScale}`]]: icon && children === undefined && scale,
                [styles[`bloko-button_scale-${scale as ButtonScale}`]]: scale,
                [styles['bloko-button_pressed']]: pressed,
                [styles['bloko-button_stretched']]: stretched,
                [styles['bloko-button_collapsible']]: collapsible,
                [styles['bloko-button_loading']]: !!loading,
                [styles['bloko-button_invalid']]: invalid,
                [styles['bloko-button_appearance-outlined']]: appearance === ButtonAppearance.Outlined,
                [styles['bloko-button_appearance-flat']]: appearance === ButtonAppearance.Flat,
            })}
            {...additionalAttributes}
            {...buttonProps}
        >
            {renderContent(children, loading, icon, iconPosition)}
        </Element>
    );
};

export default memo(Button);
export { ButtonGroup };
